import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { RecruitAgency } from "../../interfaces/employee";
import { Common, EmployeeIcons } from "../../assets/images";

const ViewRecruitAgency = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const recruitData: RecruitAgency = location.state.data;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = recruitData.employeeRecruited.slice(
    startIndex,
    endIndex,
  );

  const totalPages = Math.ceil(recruitData.employeeRecruited.length / pageSize);

  const totalCount = recruitData.employeeRecruited.length;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Category",
      "Nationality",
      "Status",
    ],
    tableData: paginatedData,
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h1 className="heading mb-0">Recruiter Detail</h1>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Recruiter Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.recruiterId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Recruiter Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.recruiterName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.companyName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Phone
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.companyPhone}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  License Number
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.licenseNumber}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.contactPersonName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person Designation
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.contactPersonDesignation}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person Phone
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.contactPersonPhone}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Address
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.address}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Website
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {recruitData.website}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Emails</h1>
          </div>
        </Col>
        <Col sm="12">
          {recruitData.emails.length > 0 ? (
            <>
              {recruitData.emails.map((asset, index) => (
                <div className="row" key={index}>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Email {index + 1}
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {asset}
                      </h5>
                    </Form.Group>
                  </Col>
                </div>
              ))}
            </>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Emails Added
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Recruited Employees</h1>
          </div>
        </Col>
        <Col sm="12">
          {recruitData.employeeRecruited.length > 0 ? (
            <Table striped bordered responsive className=" text-center">
              <thead>
                <tr className="border-0">
                  {tableData.tableHeading.map((item, index) => (
                    <th className="border-0" key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.tableData.map((item, index) => (
                  <tr
                    key={index}
                    style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                  >
                    <td>
                      {totalCount > 0
                        ? (currentPage - 1) * pageSize + index + 1
                        : index + 1}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empId}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empName}
                    </td>
                    <td>{item.empCategory}</td>
                    <td>{item.empNationality}</td>
                    <td>{item.empStatus}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Recruited Employees
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        {paginatedData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Attachments</h1>
          </div>
        </Col>
        <Col sm="12">
          {recruitData.recruitmentAttachments.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Date</th>
                    <th>File Title</th>
                    <th>Uploader Emp Id</th>
                    <th>Uploader Emp Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {recruitData.recruitmentAttachments.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.date
                          ? new Date(item.date).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.title}</td>
                      <td>{item.empId}</td>
                      <td>{item.empName}</td>
                      <td>
                        <a href={item.link} download>
                          <img
                            src={Common.Download}
                            className="me-2"
                            width="24px"
                            height="24px"
                            alt="share"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Attachment Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewRecruitAgency;
