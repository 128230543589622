import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { LeaveRequest } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterLeaveRequestTotalPages,
  setLeaveRequestLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredLeaveRequestMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setLeaveRequests?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  leaveRequests: LeaveRequest[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  projectSupervisor: string;
  leaveCategory: string;
  leaveStatus: string;
  leaveFrom: string;
  leaveTo: string;
}

const LeaveRequestFilterModal = ({
  show,
  setShow,
  leaveRequests,
  setLeaveRequests,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getAllFilteredLeaveRequest] = useGetAllFilteredLeaveRequestMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLeaveRequestLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterLeaveRequestPageNo,
          limit: state.filterLeaveRequestPageSize,
          projectId: filterInput.projectId,
          projectSupervisor: filterInput.projectSupervisor,
          leaveCategory: filterInput.leaveCategory,
          leaveStatus: filterInput.leaveStatus,
          leaveFrom: filterInput.leaveFrom,
          leaveTo: filterInput.leaveTo,
        },
      };
      const response = await getAllFilteredLeaveRequest(data).unwrap();
      if (response) {
        setLeaveRequests(response.data.leaves[0].data);
        dispatch(
          setFilterLeaveRequestTotalPages(
            response.data.leaves[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setLeaveRequestLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLeaveRequestLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterLeaveRequestPageNo,
            limit: state.filterLeaveRequestPageSize,
            empId: query,
            projectId: filterInput.projectId,
            projectSupervisor: filterInput.projectSupervisor,
            leaveCategory: filterInput.leaveCategory,
            leaveStatus: filterInput.leaveStatus,
            leaveFrom: filterInput.leaveFrom,
            leaveTo: filterInput.leaveTo,
          },
        };
        const response = await getAllFilteredLeaveRequest(data).unwrap();
        if (response) {
          setLeaveRequests(response.data.leaves[0].data);
          dispatch(
            setFilterLeaveRequestTotalPages(
              response.data.leaves[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setLeaveRequestLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterLeaveRequestPageNo, state.filterLeaveRequestPageSize, query]);

  const handleReset = () => {
    setLeaveRequests(leaveRequests);
    setFilterInput({
      projectId: "",
      projectSupervisor: "",
      leaveCategory: "",
      leaveStatus: "",
      leaveFrom: "",
      leaveTo: "",
    });
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Leave Requests</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Supervisor
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectSupervisor"
                        options={supervisorOptions}
                        value={filterInput.projectSupervisor}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectSupervisor: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Supervisor"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave Category
                    </h4>
                    <Form.Select
                      name="leaveCategory"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          leaveCategory: e.target.value,
                        })
                      }
                      value={filterInput.leaveCategory}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Leave Category</option>
                      <option value="Emergency">Emergency</option>
                      <option value="Annual">Annual</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave Status
                    </h4>
                    <Form.Select
                      name="leaveStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          leaveStatus: e.target.value,
                        })
                      }
                      value={filterInput.leaveStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave From
                    </h4>
                    <Form.Control
                      type="date"
                      name="leaveFrom"
                      placeholder="Enter leave from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          leaveFrom: e.target.value,
                        })
                      }
                      value={filterInput.leaveFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave To
                    </h4>
                    <Form.Control
                      type="date"
                      name="leaveTo"
                      placeholder="Enter leave to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          leaveTo: e.target.value,
                        })
                      }
                      value={filterInput.leaveTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaveRequestFilterModal;
