import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { TransferTimeline, EmpDetail } from "../../../interfaces/employee";
import { Common } from "../../../assets/images";
import ProjectTimelineSet from "./project-timeline-set";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEditEvaluationDetails,
  setActiveTab,
  setEditTimeline,
  setEditIncrementTimeline,
} from "../../../store/slices/employeeSlice";
import { setEmployeeChange } from "../../../store/slices/commonSlice";
// API
import { useEditEmployeeTimelineMutation } from "../../../services/Employees";
import { LoaderWhite } from "../../Lotties";
import { ToastErrorComponent, ToastSuccessComponent } from "../../Toasts";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import { da } from "date-fns/locale";

interface Props {
  timelineData: TransferTimeline[];
  employeeID: number | null;
  empDetails: EmpDetail;
}

const EditProjectTimeline = ({
  timelineData,
  employeeID,
  empDetails,
}: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [loading, setLoading] = useState(false);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [projectId, setProjectId] = useState(empDetails.empProjectId);
  const project = projectIds.find((item) => item.projectId === projectId);
  const [projectName, setProjectName] = useState("");
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const [projectSupervisor, setProjectSupervisor] = useState(
    empDetails.empSupervisorName,
  );
  const [fromDate, setFromDate] = useState(empDetails.empProjectJoiningDate);
  const [category, setCategory] = useState(empDetails.empProjectCategory);
  const [workingHours, setWorkingHours] = useState(
    empDetails.empProjectWorkingHours,
  );
  const [price, setPrice] = useState(empDetails.empProjectPrice);
  const [overTime, setOverTime] = useState(empDetails.overTime);
  const [overTimePrice, setOverTimePrice] = useState(empDetails.overTimePrice);
  const [type, setType] = useState("Project");
  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      projectId: string;
      projectName: string;
      projectSupervisor: string;
      fromDate: Date;
      toDate: Date;
      attendendDays: number;
      trasferType: string;
      category: string;
      workingHours: number;
      price: number;
      overTime: number;
      overTimePrice: number;
      submittedBy: {
        empId: number;
        empName: string;
        remarks: string;
      };
    }[]
  >(timelineData || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        projectId: "",
        projectName: "",
        projectSupervisor: "",
        fromDate: new Date(),
        toDate: new Date(),
        attendendDays: 0,
        trasferType: "Transfer",
        category: "",
        workingHours: 0,
        price: 0,
        overTime: 0,
        overTimePrice: 0,
        submittedBy: {
          empId: 0,
          empName: "",
          remarks: "",
        },
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };

  const handleLanguageInputChange = (
    index: string,
    data: {
      projectId: string;
      projectName: string;
      projectSupervisor: string;
      fromDate: Date;
      toDate: Date;
      attendendDays: number;
      trasferType: string;
      category: string;
      workingHours: number;
      price: number;
      overTime: number;
      overTimePrice: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ProjectTimelineSet
        key={data.uId}
        data={data}
        index={index + 1}
        timelineData={languageData}
        nationality={empDetails.empNationality}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Filter data that has been changed from the original data
  const updatedData = languageData.filter((data) => {
    const originalData = timelineData.find((item) => item.uId === data.uId);
    if (originalData && JSON.stringify(originalData) !== JSON.stringify(data)) {
      return data;
    }
  });

  // Form Submit Function
  const [editEmployeeTimeline] = useEditEmployeeTimelineMutation();

  const handleSubmit = async () => {
    const date = new Date(fromDate);
    if (languageData.length > 0) {
      if (checkDateExist(date)) {
        setErrorContent("From date already exists");
        setShow(true);
        return;
      }
    }
    setLoading(true);
    const data = {
      accessToken: accessToken || "",
      userID: userID || "",
      permission: profileData.permissions.updateEmployee || false,
      payload: {
        empId: employeeID,
        type: "TransferTimeline",
        timeline: languageData,
        updatedTimeline: updatedData,
        currentProject: {
          type: type,
          projectId: projectId,
          projectName: project?.projectName,
          projectSupervisor: projectSupervisor,
          fromDate: fromDate,
          category: type === "Camp" ? "" : category,
          workingHours: type === "Camp" ? 0 : workingHours,
          price: type === "Camp" ? 0 : price,
          overTime: type === "Camp" ? 0 : overTime,
          overTimePrice: type === "Camp" ? 0 : overTimePrice,
        },
      },
    };
    try {
      await editEmployeeTimeline(data).unwrap();
      dispatch(setEmployeeChange(!employeeChange));
      window.scrollTo(0, 0);
      setLoading(false);
      setSuccess(true);
    } catch (e: any) {
      if (e) {
        // Scroll to top
        window.scrollTo(0, 0);
        setLoading(false);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    }
  };

  // Options
  const campOptions = campIds.map((item) => ({
    name: item.campId + " - " + item.campName,
    value: item.campId,
  }));

  const projectOptions = projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  useEffect(() => {
    if (project) {
      const selectedCategory = project.employeeRequired.find(
        (item) => item.category === category,
      );
      if (selectedCategory) {
        setWorkingHours(selectedCategory.workingHours);
        setPrice(selectedCategory.price);
        setOverTime(selectedCategory.overTime);
        setOverTimePrice(selectedCategory.overTimePrice);
      }
    }
  }, [project, category]);

  const categories = project?.employeeRequired.filter(
    (item) =>
      item.nationality === "" || item.nationality === empDetails.empNationality,
  );

  const options = type === "Camp" ? campOptions : projectOptions;

  const checkDateExist = (date: Date) => {
    let isExist = false;
    const transferOnDate = languageData[languageData.length - 1];
    const dateFormat = new Date(date);
    const toDate = new Date(transferOnDate.toDate);
    dateFormat.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
    if (dateFormat <= toDate) {
      isExist = true;
    }
    return isExist;
  };

  return (
    <>
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={success}
        setShow={setSuccess}
        content="Updated Successfully!"
      />
      <Row>
        <Col sm="12">
          <h3 className="sub-heading">Projects Timelines</h3>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Project Timeline
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">{languageSets}</div>
        </Col>
        <Col sm="12">
          <h3 className="sub-heading">Current Project</h3>
        </Col>
        {/*{empDetails.empWorking === "Idol" ? (*/}
        <Col sm="12">
          <Row className="align-items-end">
            <Col sm="12">
              <Row>
                <Col sm="3">
                  <Form.Group controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Type
                    </h4>
                    <Form.Select
                      name="type"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                      className="custom-input border-fill"
                    >
                      <option value="">Select type</option>
                      <option value="Project">Project</option>
                      <option value="Camp">Camp</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="3">
                  <Form.Group
                    className="d-flex align-items-center gap-4"
                    controlId="formBasicEmail"
                  >
                    <div className="w-100">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project/Camp ID
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                        <SelectSearch
                          name="emp"
                          options={options}
                          value={projectId}
                          onChange={(value: any) => setProjectId(value)}
                          search={true}
                          placeholder="Select Project Id"
                        />
                        <img src={Common.ArrowDown} alt="message" />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </Col>
                {type === "Project" && (
                  <Col sm="3">
                    <Form.Group controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Category
                      </h4>
                      <Form.Select
                        name="category"
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                        className="custom-input border-fill"
                      >
                        <option value="">Select category</option>
                        {project &&
                          categories?.map((item) => (
                            <option key={item.category} value={item.category}>
                              {item.category + " - " + item.nationality}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
                <Col sm="3">
                  <Form.Group controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                      <DatePicker
                        name="fromDate"
                        selected={fromDate ? new Date(fromDate) : null}
                        onChange={(e: any) => {
                          if (languageData.length > 0) {
                            if (checkDateExist(e)) {
                              setErrorContent("From date already exists");
                              setShow(true);
                              return;
                            }
                          }
                          setFromDate(e);
                        }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="Select from date"
                        className="w-100 custom-input"
                        dateFormat="yyyy-MM-d"
                      />
                      <img
                        src={Common.Calendar}
                        className="calendar-date-recur"
                        alt="calendarblack"
                      />
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/*) : (*/}
        {/*  <Col sm="12">*/}
        {/*    <h4 className="sub-heading">*/}
        {/*      Current project cannot be edited if employee is already in any*/}
        {/*      project*/}
        {/*    </h4>*/}
        {/*  </Col>*/}
        {/*)}*/}
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center justify-content-between">
            <button
              className="btn secondary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditEvaluationDetails(true));
                dispatch(setEditTimeline(false));
                dispatch(setActiveTab("Evaluation Details"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              <img src={Common.ArrowLeftBlue} alt="next" />
              Back
            </button>
            <div className="w-100">
              <button
                className="btn primary-button normal-font-weight me-3"
                onClick={handleSubmit}
                style={{ width: "15%" }}
              >
                {loading ? <LoaderWhite height={30} width={30} /> : "Update"}
              </button>
              <button
                className="btn primary-button normal-font-weight"
                onClick={() => {
                  dispatch(setEditTimeline(false));
                  dispatch(setEditIncrementTimeline(true));
                  dispatch(setActiveTab("Salary Timeline"));
                }}
                type="button"
                style={{ width: "15%" }}
              >
                Next <img src={Common.ArrowRightWhite} alt="next" />
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EditProjectTimeline;
