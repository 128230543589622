import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Iqama
import { useGetIqamaListQuery } from "../../services/Iqama";
import {
  setIqamaError,
  setIqamaLists,
  setIqamaLoading,
  setTotalRecords as IqamaTotalRecords,
} from "../../store/slices/iqamaSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const iqamaChange = useAppSelector((state) => state.common.iqamaChange);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const violationsChange = useAppSelector(
    (state) => state.common.violationsChange,
  );
  // States
  const state = useAppSelector((state) => state.iqama);

  // **************
  // **************
  // Get Iqama
  // **************
  // **************

  const {
    data: allIqama,
    isLoading: iqLoading,
    isError: iqError,
    refetch: iqamaRefetch,
  } = useGetIqamaListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showLegal || false,
    pageNo: state.pageNo,
    pageSize: state.pageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (iqLoading) {
      dispatch(setIqamaLoading(true));
    }
    if (iqError) {
      dispatch(setIqamaError("Something went wrong"));
      dispatch(setIqamaLoading(false));
    }
    if (allIqama) {
      dispatch(setIqamaLists(allIqama.data.iqamaLists[0].data || []));
      dispatch(
        IqamaTotalRecords(allIqama.data.iqamaLists[0].metadata[0]?.total || 0),
      );
      dispatch(setIqamaLoading(false));
    }
  }, [
    accessToken,
    userID,
    allIqama,
    profileData,
    state.pageNo,
    state.pageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    iqamaRefetch();
  }, [employeeChange, iqamaChange, violationsChange]);

  return <></>;
};
export default Content;
