import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { Formik } from "formik";
import { AttendanceIcons, Common, EmployeeIcons } from "../../assets/images";
import SelectSearch from "react-select-search";
import AttachmentSet from "../Employees/AddEmployee/attachments";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowAddEmployee,
  setShowAddProjectDetail,
  setAddProjectLoading,
} from "../../store/slices/projectSlice";
import { setProjectChange } from "../../store/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import {
  IdolEmployee,
  AllEmployees,
  TransferTimeline,
  SalaryTimeline,
} from "../../interfaces/employee";
// API Imports
import { useAddProjectMutation } from "../../services/Projects";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import CategoriesSet from "./categories";
import DatePicker from "react-datepicker";

interface FormProps {
  projectName: string;
  clientDetails: {
    clientId: string;
    clientName: string;
    companyName: string;
    contactPersonName: string;
    contactPersonDesignation: string;
    phone: string;
  };
  agreementFrom: string;
  agreementTo: string;
  projectSupervisor: string;
  projectLocation: string;
  projectStatus: string;
}

interface Employee {
  empId: number;
  empName: string;
}

const AddProject = () => {
  const dispatch = useAppDispatch();
  const [employeesToBeAdded, setEmployeesToBeAdded] = useState<number[]>([]); // Array of employee ids to be added to project
  const [categorySelected, setCategorySelected] = useState<{
    [key: string]: string;
  }>({});
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectChange = useAppSelector((state) => state.common.projectChange);
  const allClients = useAppSelector((state) => state.clients.clientIds);
  const employees = useAppSelector((state) => state.project.idolEmployees);
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const [query, setQuery] = useState("");
  const [clientId, setClientId] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState<AllEmployees[]>(
    [],
  );
  // Get list of employees whose empStatus is Idol
  const options = employees.map((employee) => ({
    name: employee.empId, // Use the actual property name for the project name
    value: employee.empId,
  }));
  const navigate = useNavigate();
  // Categories Sets
  const [categoriesSets, setCategoriesSets] = useState<React.ReactNode[]>([]);
  const [categoriesData, setCategoriesData] = useState<
    {
      uId: string;
      category: string;
      price: number;
      overTime: number;
      nationality: string;
      accomodation: string;
      transportation: string;
      food: string;
      workingHours: number;
      holidays: number;
      duration: number;
      overTimePrice: number;
    }[]
  >([]);

  const handleAddCategoriesSet = () => {
    setCategoriesData([
      ...categoriesData,
      {
        uId: Math.random().toString(36).substring(7),
        category: "",
        price: 0,
        overTime: 0,
        nationality: "",
        accomodation: "",
        transportation: "",
        food: "",
        workingHours: 0,
        holidays: 0,
        duration: 0,
        overTimePrice: 0,
      },
    ]);
  };

  const handleRemoveCategoriesSet = (index: string) => {
    const updatedInputData = categoriesData.filter(
      (item, i) => item.uId !== index,
    );
    setCategoriesData(updatedInputData);
  };
  const handleCategoriesInputChange = (
    index: string,
    data: {
      category: string;
      price: number;
      overTime: number;
      nationality: string;
      accomodation: string;
      transportation: string;
      food: string;
      workingHours: number;
      holidays: number;
      duration: number;
      overTimePrice: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = categoriesData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setCategoriesData(updatedInput);
  };

  useEffect(() => {
    const updatedCategoriesSets = categoriesData.map((data, index) => (
      <CategoriesSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveCategoriesSet(key)}
        onInputChange={(inputData) =>
          handleCategoriesInputChange(data.uId, inputData)
        }
      />
    ));
    setCategoriesSets(updatedCategoriesSets);
  }, [categoriesData]);

  // filter employees based on employeesToBeAdded
  const selectIdolEmployees = employees.filter((employee) =>
    employeesToBeAdded.includes(employee.empId),
  );
  // Merging based on category
  const mergedData = selectIdolEmployees.map((required) => {
    const employee = categoriesData.find((e) =>
      e.nationality === ""
        ? e.category + " " + e.nationality ===
          categorySelected[required.empId.toString()]
        : e.category + " " + e.nationality ===
            categorySelected[required.empId.toString()] &&
          e.nationality === required.empNationality,
    );

    if (employee) {
      return {
        empId: required.empId,
        category: employee.category,
        price: employee.price,
        workingHours: employee.workingHours,
        joiningDate: required.empProjectJoiningDate,
      };
    }

    return required;
  });

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredEmployees.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredEmployees.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };

  const selectedEmployees = employees.filter((employee) =>
    employeesToBeAdded.includes(employee.empId),
  );

  // // sort paginated data by employeesToBeAdded
  // const sortedPaginatedData = paginatedData.sort((a, b) => {
  //   if (employeesToBeAdded.includes(a.empId)) {
  //     return -1;
  //   } else {
  //     return 1;
  //   }
  // });

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Emp Category",
      "Nationality",
      "Place",
      "Working Status",
      "Select Category",
      "Select",
    ],
    tableData: query ? filteredEmployees : paginatedData,
  };

  const initialValues: FormProps = {
    projectName: "",
    clientDetails: {
      clientId: "",
      clientName: "",
      companyName: "",
      contactPersonName: "",
      contactPersonDesignation: "",
      phone: "",
    },
    agreementFrom: "",
    agreementTo: "",
    projectSupervisor:
      profileData.permissions.roleName === "Supervisor"
        ? profileData.employeeId
        : "",
    projectLocation: "",
    projectStatus: "",
  };
  // Handle Employees to be added to project
  const handleEmployeeSelection = (empId: number) => {
    const category = categoriesData.find(
      (item) =>
        item.category + " " + item.nationality ===
        categorySelected[empId.toString()],
    );
    const employee = employees.find((item) => item.empId === empId);
    if (categorySelected[empId.toString()] === undefined) {
      window.scrollTo(0, 0);
      setErrorContent("Please select category for the employee");
      setShow(true);
      return;
    }
    if (category?.nationality === "") {
      setEmployeesToBeAdded((prevEmployees) => {
        if (prevEmployees.includes(empId)) {
          return prevEmployees.filter((id) => id !== empId);
        } else {
          return [...prevEmployees, empId];
        }
      });
      return;
    }
    if (category?.nationality !== employee?.empNationality) {
      window.scrollTo(0, 0);
      setErrorContent(
        "Employee Nationality does not match with selected category",
      );
      setShow(true);
      return;
    }
    setEmployeesToBeAdded((prevEmployees) => {
      if (prevEmployees.includes(empId)) {
        return prevEmployees.filter((id) => id !== empId);
      } else {
        return [...prevEmployees, empId];
      }
    });
  };

  const [addProject] = useAddProjectMutation();

  const handleSubmit = async (values: FormProps) => {
    dispatch(setAddProjectLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          projectName: values.projectName,
          projectSupervisor: parseInt(values.projectSupervisor),
          projectLocation: values.projectLocation,
          projectStatus: values.projectStatus,
          clientDetails: {
            clientId: clientId,
            clientName: client?.clientName,
            companyName: client?.companyName,
            contactPersonName: client?.contactPersonName,
            contactPersonDesignation: client?.contactPersonDesignation,
            phone: client?.phone,
            clientMOL: client?.clientMOL,
          },
          agreementFrom: values.agreementFrom,
          agreementTo: values.agreementTo,
          projectEmployees: employeesToBeAdded,
          employeeRequired: categoriesData,
          employeesAdded: mergedData,
          link: "/projects",
        },
      };
      await addProject(data).unwrap();
      dispatch(setProjectChange(!projectChange));
      dispatch(setShowAddEmployee(false));
      dispatch(setShowAddProjectDetail(true));
      navigate("/projects");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddProjectLoading(false));
    }
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.employeeId +
        " - " +
        employee.firstName +
        " " +
        employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  const dummyEmployee = [
    {
      _id: "Dummy",
      empId: 0,
      empName: "Dummy",
      empProjectId: "Dummy",
      empWorking: "Dummy",
      empBasicSalary: 0,
      empSupervisorName: "Dummy",
      empCategory: "Dummy",
      empPlace: "Dummy",
      empNationality: "Dummy",
      empReligion: "Dummy",
      empPhone: "Dummy",
      empStatus: "Dummy",
      empIqamaNo: "Dummy",
      empIqamaExpiry: "Dummy",
      empProfessionOnIqama: "Dummy",
      empSponsorName: "Dummy",
      empBorderNo: 0,
      empPassportNo: "Dummy",
      empVisaNo: 0,
      empFoodAllowance: 0,
      empMobileAllowance: 0,
      empHra: 0,
      empTotalSalary: 0,
      entryStatus: true,
      exitStatus: true,
      empProjectCategory: "Dummy",
      empProjectPrice: 0,
      empProjectWorkingHours: 0,
      empIdNo: "Dummy",
      basicSalary: 0,
      dutyHours: 0,
      transferTimeline: [],
      salaryTimeline: [],
      empProjectJoiningDate: "",
      empIncrementDate: "",
      overTimePrice: 0,
      allowances: [],
    },
  ];

  useEffect(() => {
    if (query) {
      setFilteredEmployees(
        employees.filter((employee) =>
          employee.empId.toString().includes(query),
        ),
      );
    } else {
      setFilteredEmployees([...dummyEmployee, ...employees]);
    }
  }, [query, employees]);

  const client = allClients.find((item) => item.clientId === clientId);

  const isHour = categoriesData.every((item) => item.workingHours !== 0);
  const isPrice = categoriesData.every((item) => item.price !== 0);
  const isCategory = categoriesData.every((item) => item.category !== "");

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Add Project</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                {state.showAddProjectDetail && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Project Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="projectName"
                          placeholder="Enter project name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.projectName}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Project Location
                        </h4>
                        <Form.Control
                          type="text"
                          name="projectLocation"
                          placeholder="Enter project location"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.projectLocation}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>

                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Project Supervisor
                        </h4>
                        <Form.Group className="position-relative d-flex align-items-center">
                          <Form.Select
                            name="projectSupervisor"
                            onChange={handleChange}
                            value={values.projectSupervisor}
                            className="custom-input border-fill"
                          >
                            {profileData.permissions.roleName ===
                            "Supervisor" ? (
                              <option value={profileData.employeeId}>
                                {profileData.employeeId +
                                  " - " +
                                  profileData.firstName +
                                  " " +
                                  profileData.lastName}
                              </option>
                            ) : (
                              <option value="">Select Spervisor</option>
                            )}
                            {profileData.permissions.roleName !==
                              "Supervisor" && (
                              <>
                                {supervisors.map((item, index) => (
                                  <option key={index} value={item.employeeId}>
                                    {item.employeeId +
                                      " - " +
                                      item.firstName +
                                      " " +
                                      item.lastName}
                                  </option>
                                ))}
                              </>
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client Id
                        </h4>
                        <Form.Select
                          name="clientDetails.campId"
                          onChange={(e) => {
                            setClientId(e.target.value);
                          }}
                          value={clientId}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Client Id</option>
                          {allClients.map((item, index) => (
                            <option key={index} value={item.clientId}>
                              {item.clientId + " - " + item.clientName}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyName"
                          placeholder="Enter company name"
                          value={client?.companyName || "Select Client Id"}
                          disabled={true}
                          className="custom-input disabled-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonName"
                          placeholder="Enter contact person name"
                          value={
                            client?.contactPersonName || "Select Client Id"
                          }
                          disabled={true}
                          className="custom-input disabled-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Designation
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonDesignation"
                          placeholder="Enter contact person designation"
                          value={
                            client?.contactPersonDesignation ||
                            "Select Client Id"
                          }
                          disabled={true}
                          className="custom-input disabled-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Project Status
                        </h4>
                        <Form.Select
                          name="projectStatus"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.projectStatus}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Project Status</option>
                          <option value="In progress">In progress</option>
                          <option value="Completed">Completed</option>
                          <option value="Closed">Closed</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Agreement From
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="agreementFrom"
                            selected={
                              values.agreementFrom
                                ? new Date(values.agreementFrom)
                                : null
                            }
                            onChange={(date: any) =>
                              handleChange({
                                target: { name: "agreementFrom", value: date },
                              })
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="Enter Date"
                            className="w-100 custom-input"
                            dateFormat="yyyy-MM-d"
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Agreement To
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="dob"
                            selected={
                              values.agreementTo
                                ? new Date(values.agreementTo)
                                : null
                            }
                            onChange={(date: any) =>
                              handleChange({
                                target: { name: "agreementTo", value: date },
                              })
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="Enter Date"
                            className="w-100 custom-input"
                            dateFormat="yyyy-MM-d"
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">
                        <h4 className="sub-heading normal-font-weight mb-4">
                          Add Employee Category and their per unit price by
                          client...
                        </h4>
                        <button
                          className="btn primary-button normal-font-weight mb-4"
                          type="button"
                          onClick={handleAddCategoriesSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Category
                        </button>
                        {categoriesSets}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">
                        <button
                          className="btn primary-button normal-font-weight mb-4"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachment
                        </button>
                        {languageSets}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-5">
                        <button
                          className="btn primary-button w-25"
                          type="button"
                          onClick={() => {
                            if (values.projectName === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Please fill project name");
                              setShow(true);
                              return;
                            }
                            if (values.projectSupervisor === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Please fill project supervisor");
                              setShow(true);
                              return;
                            }
                            if (clientId === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Please fill client id");
                              setShow(true);
                              return;
                            }
                            if (!isCategory) {
                              window.scrollTo(0, 0);
                              setErrorContent(
                                "Please fill category for all categories",
                              );
                              setShow(true);
                              return;
                            }
                            if (!isHour) {
                              window.scrollTo(0, 0);
                              setErrorContent(
                                "Please fill working hours for all categories",
                              );
                              setShow(true);
                              return;
                            }
                            if (!isPrice) {
                              window.scrollTo(0, 0);
                              setErrorContent(
                                "Please fill price for all categories",
                              );
                              setShow(true);
                              return;
                            }
                            dispatch(setShowAddEmployee(true));
                            dispatch(setShowAddProjectDetail(false));
                          }}
                        >
                          Next{" "}
                          <img
                            src={Common.ArrowRightWhite}
                            className="ms-1"
                            alt="next"
                          />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {state.showAddEmployee && (
                  <Row>
                    <Col sm="12">
                      <div className="section-heading mb-4">
                        <h4 className="sub-heading mb-0">
                          Add Employee To Project
                        </h4>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="select-employee">
                        <h4 className="sub-heading">Selected Employees</h4>
                      </div>
                    </Col>
                    <Col sm="12">
                      {selectedEmployees.length > 0 ? (
                        <Table
                          striped
                          bordered
                          responsive
                          className=" text-center mt-2"
                        >
                          <thead>
                            <tr className="border-0">
                              {tableData.tableHeading.map((item, index) => (
                                <th className="border-0" key={index}>
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedEmployees.map((item, index) => (
                              <tr
                                key={index}
                                style={{
                                  borderColor: "rgba(32, 32, 32, 0.20)",
                                }}
                              >
                                <td>
                                  {employees.length > 0
                                    ? (currentPage - 1) * pageSize + index + 1
                                    : index + 1}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empId}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empName}
                                </td>
                                <td>{item.empPhone}</td>
                                <td>{item.empIqamaNo}</td>
                                <td>{item.empCategory}</td>
                                <td>{item.empNationality}</td>
                                <td>{item.empPlace}</td>
                                <td>{item.empWorking}</td>
                                <td>
                                  <Form.Select
                                    name="category"
                                    onChange={(e) => {
                                      setCategorySelected((prev) => ({
                                        ...prev,
                                        [item.empId.toString()]: e.target.value,
                                      }));
                                    }}
                                    value={
                                      categorySelected[item.empId.toString()]
                                    }
                                    disabled={
                                      employeesToBeAdded.includes(item.empId)
                                        ? true
                                        : false
                                    }
                                    className={
                                      employeesToBeAdded.includes(item.empId)
                                        ? "custom-input border-fill disabled-input text-white"
                                        : "custom-input border-fill"
                                    }
                                  >
                                    <option value="">Select Category</option>
                                    {categoriesData.map((item, index) => (
                                      <option
                                        key={index}
                                        value={
                                          item.category + " " + item.nationality
                                        }
                                      >
                                        {item.category +
                                          " - " +
                                          item.nationality}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </td>
                                <td>
                                  <button
                                    className="btn p-0 border-0 me-2"
                                    type="button"
                                    onClick={() => {
                                      handleEmployeeSelection(item.empId);
                                      setQuery("");
                                    }}
                                  >
                                    {employeesToBeAdded.includes(item.empId) ? (
                                      <img
                                        src={AttendanceIcons.FilledCircle}
                                        width="22px"
                                        height="22px"
                                        alt="edit-user"
                                      />
                                    ) : (
                                      <img
                                        src={AttendanceIcons.EmptyCircle}
                                        width="22px"
                                        height="22px"
                                        alt="edit-user"
                                      />
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center mt-5">
                          <h1 className="heading">No Employees Selected</h1>
                        </div>
                      )}
                    </Col>
                    <Col sm="12">
                      <div className="select-employee">
                        <h4 className="sub-heading">Select Idol Employees</h4>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="d-flex align-items-center gap-3 mt-4">
                        <Form.Group className="position-relative input-design d-flex align-items-center w-50">
                          <SelectSearch
                            name="emp"
                            options={options}
                            value={query}
                            onChange={(value: any) => {
                              setQuery(value);
                            }}
                            search={true}
                            placeholder="Select Employee"
                          />
                          <img src={Common.ArrowDown} alt="message" />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col sm="12">
                      {paginatedData.length > 0 ? (
                        <Table
                          striped
                          bordered
                          responsive
                          className=" text-center mt-5"
                        >
                          <thead>
                            <tr className="border-0">
                              {tableData.tableHeading.map((item, index) => (
                                <th className="border-0" key={index}>
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.tableData.map((item, index) => (
                              <tr
                                key={index}
                                style={{
                                  borderColor: "rgba(32, 32, 32, 0.20)",
                                }}
                              >
                                <td>
                                  {employees.length > 0
                                    ? (currentPage - 1) * pageSize + index + 1
                                    : index + 1}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empId}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empName}
                                </td>
                                <td>{item.empPhone}</td>
                                <td>{item.empIqamaNo}</td>
                                <td>{item.empCategory}</td>
                                <td>{item.empNationality}</td>
                                <td>{item.empPlace}</td>
                                <td>{item.empWorking}</td>
                                <td>
                                  {item._id === "Dummy" ? (
                                    "Dummy"
                                  ) : (
                                    <Form.Select
                                      name="category"
                                      onChange={(e) => {
                                        setCategorySelected((prev) => ({
                                          ...prev,
                                          [item.empId.toString()]:
                                            e.target.value,
                                        }));
                                      }}
                                      value={
                                        categorySelected[item.empId.toString()]
                                      }
                                      disabled={
                                        employeesToBeAdded.includes(item.empId)
                                          ? true
                                          : false
                                      }
                                      className={
                                        employeesToBeAdded.includes(item.empId)
                                          ? "custom-input border-fill disabled-input text-white"
                                          : "custom-input border-fill"
                                      }
                                    >
                                      <option value="">Select Category</option>
                                      {categoriesData.map((item, index) => (
                                        <option
                                          key={index}
                                          value={
                                            item.category +
                                            " " +
                                            item.nationality
                                          }
                                        >
                                          {item.category +
                                            " - " +
                                            item.nationality}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  )}
                                </td>
                                <td>
                                  {item._id === "Dummy" ? (
                                    "Dummy"
                                  ) : (
                                    <button
                                      className="btn p-0 border-0 me-2"
                                      type="button"
                                      onClick={() => {
                                        handleEmployeeSelection(item.empId);
                                        setQuery("");
                                      }}
                                    >
                                      {employeesToBeAdded.includes(
                                        item.empId,
                                      ) ? (
                                        <img
                                          src={AttendanceIcons.FilledCircle}
                                          width="22px"
                                          height="22px"
                                          alt="edit-user"
                                        />
                                      ) : (
                                        <img
                                          src={AttendanceIcons.EmptyCircle}
                                          width="22px"
                                          height="22px"
                                          alt="edit-user"
                                        />
                                      )}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center mt-5">
                          <h1 className="heading">No Idol Employees Found</h1>
                        </div>
                      )}
                    </Col>
                    {paginatedData.length > 0 && (
                      <Col sm="12">
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                          <Form.Group
                            className="d-flex align-items-center gap-2"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "14px", width: "230px" }}
                            >
                              Entries per page
                            </h4>
                            <Form.Select
                              name="pageSize"
                              onChange={handlePageSizeChange}
                              value={pageSize.toString()}
                              className="custom-input border-fill custom-height"
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="100">100</option>
                            </Form.Select>
                          </Form.Group>
                          <div className="pagination-custom">
                            <Pagination className="justify-content-end">
                              <Pagination.Item
                                onClick={() => handleGoToPreviousPages()}
                              >
                                <img src={Common.DoubleArrowLeft} alt="arrow" />
                              </Pagination.Item>
                              <Pagination.Item
                                onClick={() =>
                                  handlePageChange(
                                    currentPage > 1 ? currentPage - 1 : 1,
                                  )
                                }
                              >
                                <img
                                  src={EmployeeIcons.ArrowDownBlue}
                                  className="rotate-left"
                                  alt="arrow"
                                />
                              </Pagination.Item>

                              {pages.map((page, index) => (
                                <Pagination.Item
                                  key={index}
                                  onClick={() => handlePageChange(page + 1)}
                                  className={
                                    page + 1 === currentPage
                                      ? "active-page"
                                      : ""
                                  }
                                >
                                  {page + 1}
                                </Pagination.Item>
                              ))}

                              <Pagination.Item
                                onClick={() =>
                                  handlePageChange(
                                    currentPage < totalPages
                                      ? currentPage + 1
                                      : totalPages,
                                  )
                                }
                              >
                                <img
                                  src={EmployeeIcons.ArrowDownBlue}
                                  className="rotate-right"
                                  alt="arrow"
                                />
                              </Pagination.Item>
                              <Pagination.Item
                                onClick={() => {
                                  handleGoToNextPages();
                                }}
                              >
                                <img
                                  src={Common.DoubleArrowRight}
                                  alt="arrow"
                                />
                              </Pagination.Item>
                            </Pagination>
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col sm="12">
                      <div className="d-flex justify-content-between mt-5">
                        <button
                          className="btn secondary-button w-25"
                          type="button"
                          onClick={() => {
                            dispatch(setShowAddEmployee(false));
                            dispatch(setShowAddProjectDetail(true));
                          }}
                        >
                          <img
                            src={Common.ArrowLeftBlue}
                            className="me-1"
                            alt="next"
                          />{" "}
                          Back
                        </button>
                        <button
                          className="btn primary-button w-25"
                          type="submit"
                        >
                          {state.addProjectLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            <>
                              Submit
                              <img
                                src={Common.ArrowRightWhite}
                                className="ms-1"
                                alt="next"
                              />
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddProject;
