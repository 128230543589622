import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { EmployeePayroll, EmployeePayslip } from "../../interfaces/employee";

interface EmployeePayrollList {
  data: {
    empPayrollsData: {
      data: EmployeePayroll[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}
interface EmployeePayslipList {
  data: {
    filteredPayrolls: {
      data: EmployeePayslip[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const employeePayrollAPI = createApi({
  reducerPath: "/api/employee-payroll",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getCreatePayrollList: builder.query<
      EmployeePayrollList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
        empIds: string[];
      }
    >({
      query: (data) => ({
        url: `/get-payroll-data/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
          "emp-id": `${data.empIds}`,
        },
      }),
    }),
    getPayslipList: builder.query<
      EmployeePayslipList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
        empIds: string[];
        status: string;
      }
    >({
      query: (data) => ({
        url: `/get-filtered-payrolls/${data.status}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
          "search-id": `${data.empIds}`,
        },
      }),
    }),
    createPayroll: builder.mutation({
      query: (data) => ({
        url: `/generate-payrolls/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    paySalaries: builder.mutation({
      query: (data) => ({
        url: `/pay-salaries/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetCreatePayrollListQuery,
  useCreatePayrollMutation,
  useGetPayslipListQuery,
  usePaySalariesMutation,
} = employeePayrollAPI;
