import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { OfferLetterDetail } from "../../interfaces/letters";
import LetterOffer from "../Letters/offer-letter";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  setAddOfferLetterLoading,
  setOfferLetterLoading,
} from "../../store/slices/lettersSlice";
import {
  setOfferLetterChange,
  setEmployeeChange,
} from "../../store/slices/commonSlice";
import {
  useEditOfferLetterStatusMutation,
  useGetOfferLetterDetailMutation,
} from "../../services/Letters";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import { Common } from "../../assets/images";

export const initialDetail = {
  _id: "",
  reqRef: "",
  requestee: {
    name: "",
    empId: 0,
    withinCompany: false,
  },
  department: "",
  intervieweeId: "",
  role: "",
  intervieweeName: "",
  interviewDate: "",
  nationality: "",
  companyName: "",
  passportNumber: "",
  iqamaNumber: 0,
  borderNumber: 0,
  contact: "",
  dateOfBirth: "",
  totalSalary: 0,
  religion: "",
  interviewConducted: false,
  homeCountryContact: "",
  interviewerApprovalsStatus: "",
  offerLetterStatus: "",
  offerLetterRef: "",
  offerLetterEmployer: {
    empId: 0,
    name: "",
    date: "",
  },
  interviewerDetails: {
    empId: 0,
    empName: "",
    empRole: "",
  },
  positionOffered: "",
  dutyHours: 0,
  salaryPeriod: "",
  basicSalary: 0,
  allowances: [],
  instructions: [],
  interviewerApprovals: [],
  evaluationDetail: {
    obedient: "",
    behavior: "",
    appearance: "",
    remarks: "",
  },
  language: [],
  experience: [],
  skills: [],
};

const ViewInterviewer = () => {
  // Get interviewerId from params
  const { interviewerId } = useParams();
  const dispatch = useAppDispatch();
  const [showEvaluation, setShowEvaluation] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [statementData, setStatementData] =
    useState<OfferLetterDetail>(initialDetail);
  const state = useAppSelector((state) => state.letters);
  const [offerLetterStatus, setOfferLetterStatus] = useState("");
  const [campId, setCampId] = useState("");
  const [print, setPrint] = useState(false);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const offerLetterChange = useAppSelector(
    (state) => state.common.offerLetterChange,
  );
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const handlePrint = () => {
    setPrint(true);
  };

  const [getOfferLetterDetail] = useGetOfferLetterDetailMutation();

  const getDetail = async () => {
    dispatch(setOfferLetterLoading(true));
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData?.permissions.showEmployee || false,
      payload: {
        intervieweeId: interviewerId || "",
      },
    };
    try {
      const result = await getOfferLetterDetail(data).unwrap();
      if (result) {
        dispatch(setOfferLetterLoading(false));
        setStatementData(result.data.candidate);
      }
    } catch (e) {
      dispatch(setOfferLetterLoading(false));
    }
  };
  React.useEffect(() => {
    getDetail();
  }, [accessToken, userID, offerLetterChange, profileData]);

  const camp = campIds.find((item) => item.campId === campId);
  // API call for add salary increment
  const [editOfferLetterStatus] = useEditOfferLetterStatusMutation();
  const handleSubmit = async () => {
    dispatch(setAddOfferLetterLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          intervieweeId: interviewerId,
          offerLetterStatus: offerLetterStatus,
          campDetails: {
            campId: campId.includes("CMP") ? campId : "",
            campName: campId.includes("CMP") ? camp?.campName : campId,
            campCity: campId.includes("CMP") ? camp?.campCity : "",
          },
        },
      };
      await editOfferLetterStatus(data).unwrap();
      dispatch(setOfferLetterChange(!offerLetterChange));
      if (offerLetterStatus === "Accepted") {
        dispatch(setEmployeeChange(!employeeChange));
        navigate("/employees");
      }
      getDetail();
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddOfferLetterLoading(false));
    }
  };

  // check if all status are approved in approvals array
  const isApproved = statementData.interviewerApprovals.every(
    (item) =>
      item.status === "Approved" || item.status === "Approved by Super Admin",
  );

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      {state.offerLetterLoading ? (
        <LoaderBlue height={50} width={50} />
      ) : (
        <>
          {print ? (
            <div>
              <button
                className="btn primary-button"
                onClick={() => setPrint(false)}
              >
                Back
              </button>
              <LetterOffer offerData={statementData} />
            </div>
          ) : (
            <Row>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <div className="d-flex align-items-center gap-4">
                    <button
                      className="btn border-0 p-0"
                      onClick={() => {
                        navigate(-1);
                      }}
                      type="button"
                    >
                      {" "}
                      <img src={Common.ArrowLeftBlue} alt="next" />
                    </button>
                    <h1 className="heading mb-0">New Candidate Detail</h1>
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <Row>
                  {!showEvaluation && (
                    <>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Candidate Id
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.intervieweeId}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Candidate Name
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.intervieweeName}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Date of Birth
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.dateOfBirth
                              ? new Date(
                                  statementData.dateOfBirth,
                                ).toLocaleDateString()
                              : "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Iqama Number
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.iqamaNumber || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Passport Number
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.passportNumber || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Border Number
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.borderNumber || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Home Country Contact
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.homeCountryContact || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Nationality
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.nationality || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Religion
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.religion || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Interview Date
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewDate
                              ? new Date(
                                  statementData.interviewDate,
                                ).toLocaleDateString()
                              : "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Role
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.role || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Department
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.department || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Contact
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.contact || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Interview Conducted
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewConducted ? "Yes" : "No"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Hiring Status
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewerApprovalsStatus || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Offer Letter Status
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.offerLetterStatus || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <h4 className="sub-heading mb-2">Requestee Detail</h4>
                      </Col>
                      {statementData.requestee.withinCompany && (
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Id
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {statementData.requestee.empId || "N/A"}
                            </h5>
                          </Form.Group>
                        </Col>
                      )}
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Name
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.requestee.name || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <h4 className="sub-heading mb-2">Interviewer Detail</h4>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Emp Id
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewerDetails.empId || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Emp Name
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewerDetails.empName || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-5">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Emp Role
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.interviewerDetails.empRole || "N/A"}
                          </h5>
                        </Form.Group>
                      </Col>
                      {statementData.offerLetterStatus !== "Not Issued" && (
                        <>
                          <Col sm="12">
                            <h4 className="sub-heading mb-2">Offer Detail</h4>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Offer Letter Date
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.offerLetterEmployer.date
                                  ? new Date(
                                      statementData.offerLetterEmployer.date,
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Offer Letter Ref
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.offerLetterRef || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Offer Letter Employer Id
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.offerLetterEmployer.empId ||
                                  "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Offer Letter Employer
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.offerLetterEmployer.name ||
                                  "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Offer Letter Status
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.offerLetterStatus || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>

                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Position Offered
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.positionOffered || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Duty Hours
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.dutyHours || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Salary Period
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.salaryPeriod || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Basic Salary
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.basicSalary || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group className="mb-5">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Total Salary
                              </h4>
                              <h5
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {statementData.totalSalary || "N/A"}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Allowances
                            </h4>

                            {statementData.allowances.length > 0 && (
                              <>
                                <div className="table-responsive mb-4">
                                  <table className="table text-center table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Sr.</th>
                                        <th>Title</th>
                                        <th>Category</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {statementData.allowances.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.title}</td>
                                            <td>{item.category}</td>
                                            <td>{item.amount}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                          </Col>
                          <Col sm="12">
                            <div className="d-flex align-items-end gap-2 mb-3">
                              <Form.Group
                                className="w-50"
                                controlId="formBasicEmail"
                              >
                                <h4
                                  className="sub-heading normal-font-weight textBlack"
                                  style={{ fontSize: "18px" }}
                                >
                                  Offer Letter Status
                                </h4>
                                <Form.Select
                                  name="offerLetterStatus"
                                  onChange={(e) =>
                                    setOfferLetterStatus(e.target.value)
                                  }
                                  value={offerLetterStatus}
                                  required={true}
                                  className="custom-input border-fill"
                                >
                                  <option value="">
                                    Select Offer Letter Status
                                  </option>
                                  <option value="Accepted">Accepted</option>
                                  <option value="Rejected">Rejected</option>
                                </Form.Select>
                              </Form.Group>
                              {offerLetterStatus === "Accepted" && (
                                <Form.Group
                                  className="w-50"
                                  controlId="formBasicEmail"
                                >
                                  <h4
                                    className="sub-heading normal-font-weight textBlack"
                                    style={{ fontSize: "18px" }}
                                  >
                                    Camp Id
                                  </h4>
                                  <Form.Select
                                    name="offerLetterStatus"
                                    onChange={(e) => setCampId(e.target.value)}
                                    value={campId}
                                    className="custom-input border-fill"
                                  >
                                    <option value="">Select Camp Id</option>
                                    <option value="Out of Camp">
                                      Out of Camp
                                    </option>
                                    <option value="Clients Camp">
                                      Clients Camp
                                    </option>
                                    {campIds.map((item, index) => (
                                      <option key={index} value={item.campId}>
                                        {item.campId + " - " + item.campName}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              )}
                              <button
                                className="btn primary-button"
                                onClick={handleSubmit}
                              >
                                {state.addOfferLetterLoading ? (
                                  <LoaderWhite height={30} width={30} />
                                ) : (
                                  "Update"
                                )}
                              </button>
                            </div>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Col>
              {showEvaluation && (
                <>
                  <Col sm="12">
                    <h4 className="sub-heading mb-2">Evaluation Detail</h4>
                  </Col>
                  <Col sm="12">
                    <Row>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Obedient
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.evaluationDetail.obedient}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Behavior
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.evaluationDetail.behavior}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Appearance
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.evaluationDetail.appearance}
                          </h5>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Remarks
                          </h4>
                          <h5
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {statementData.evaluationDetail.remarks}
                          </h5>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  {statementData.language.length > 0 && (
                    <>
                      <Col sm="12">
                        <h3 className="sub-heading mb-4">Languages</h3>
                      </Col>
                      <Col sm="12">
                        <div className="table-responsive mb-4">
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Language</th>
                                <th>Language Proficiency</th>
                                <th>Writing Skills</th>
                              </tr>
                            </thead>
                            <tbody>
                              {statementData.language.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.selectedLanguage}</td>
                                  <td>{item.languageProficiency}</td>
                                  <td>{item.writingSkills}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </>
                  )}
                  {statementData.experience.length > 0 && (
                    <>
                      <Col sm="12">
                        <h3 className="sub-heading mb-4">Experience:</h3>
                      </Col>
                      <Col sm="12">
                        <div className="table-responsive mb-4">
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Region</th>
                                <th>Basic Salary</th>
                                <th>Experience Proficiency</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {statementData.experience.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.region}</td>
                                  <td>{item.basicSalary}</td>
                                  <td>{item.experienceProficiency}</td>
                                  <td>
                                    {item.startDate
                                      ? new Date(
                                          item.startDate,
                                        ).toLocaleDateString()
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {item.endDate
                                      ? new Date(
                                          item.endDate,
                                        ).toLocaleDateString()
                                      : "N/A"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </>
                  )}
                  {statementData.skills.length > 0 && (
                    <>
                      <Col sm="12">
                        <h3 className="sub-heading mb-4">Extra Skills:</h3>
                      </Col>
                      <Col sm="12">
                        <div className="table-responsive mb-4">
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Skill Name</th>
                                <th>Skill Proficiency</th>
                                <th>Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              {statementData.skills.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.skillName}</td>
                                  <td>{item.skillProficiency}</td>
                                  <td>{item.notes}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </>
                  )}
                </>
              )}
              {statementData.interviewConducted && (
                <Col sm="12">
                  <div>
                    <h2 className="sub-heading mb-4">Interview Approvals:</h2>
                    <Row>
                      {statementData.interviewerApprovals.length > 0 ? (
                        <>
                          {statementData.interviewerApprovals.map(
                            (item, index) => (
                              <div className="col-sm-4" key={index}>
                                <div className="emp-detail-item mb-4">
                                  <h4
                                    className="sub-heading normal-font-weight textBlack"
                                    style={{ fontSize: "18px" }}
                                  >
                                    {item.name} ({item.role})
                                  </h4>
                                  <p
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {item.status}{" "}
                                    {item.status !== "Pending"
                                      ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            ),
                          )}
                        </>
                      ) : (
                        <div>
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            No Approvals
                          </h4>
                        </div>
                      )}
                    </Row>
                  </div>
                </Col>
              )}
              <Col sm="12">
                <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
                  {statementData.interviewerApprovalsStatus === "Approved" && (
                    <button
                      className="btn primary-button"
                      onClick={() =>
                        navigate(
                          `/initial-evaluation/create-offer-letter/${statementData.intervieweeId}`,
                          { state: { data: statementData } },
                        )
                      }
                    >
                      {statementData.offerLetterStatus !== "Not Issued"
                        ? "Edit Offer"
                        : "Create Offer"}
                    </button>
                  )}
                  {statementData.offerLetterStatus !== "Not Issued" && (
                    <button
                      className="btn primary-button"
                      onClick={handlePrint}
                    >
                      Print Offer Letter
                    </button>
                  )}
                  {statementData.interviewConducted && (
                    <button
                      className="btn primary-button"
                      onClick={() => setShowEvaluation(!showEvaluation)}
                    >
                      {showEvaluation
                        ? "Show Candidate Detail"
                        : "Show Evaluation Detail"}
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};
export default ViewInterviewer;
