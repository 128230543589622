import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Legal } from "../../interfaces/iqama";
import { Common } from "../../assets/images";

const ViewLegalDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const expenseData: Legal = location.state.data;

  const fileExtension = (path: string) => {
    // Using the URL API to extract the pathname
    const pathname = path ? new URL(path).pathname : "";

    // Extracting the file name with extension from the pathname
    const fileNameWithExtension = pathname.split("/").pop();

    return fileNameWithExtension;
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Legal Detail</h1>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Emp Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.empId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Emp Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.empName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.category}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Eligibility
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.eligibility}
                </h5>
              </Form.Group>
            </Col>
            {expenseData.category === "Ajeer" && (
              <>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {expenseData.clientName}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client MOL
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {expenseData.clientMOL}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Id Number
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {expenseData.idNumber}
                    </h5>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Valid From
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.validFrom
                    ? new Date(expenseData.validFrom).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Valid To
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.validTo
                    ? new Date(expenseData.validTo).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Cost
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.cost}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Status
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {expenseData.status}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Update On</h1>
          </div>
        </Col>
        <Col sm="12">
          {expenseData.updatedOn.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Updation Date</th>
                    <th>Updater Id.</th>
                    <th>Updater Name</th>
                    <th>Cost</th>
                    <th>Attachment</th>
                    <th>Remarks</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {expenseData.updatedOn.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.updationDate
                          ? new Date(item.updationDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.updaterId}</td>
                      <td>{item.updaterName}</td>
                      <td>{item.cost}</td>
                      <td>
                        {item.attachemnt
                          ? fileExtension(item.attachemnt)
                          : "N/A"}
                      </td>
                      <td>{item.remarks}</td>
                      <td>
                        {item.attachemnt ? (
                          <a href={item.attachemnt} download>
                            <img
                              src={Common.Download}
                              className="me-2"
                              width="24px"
                              height="24px"
                              alt="share"
                            />
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No updations yet...
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewLegalDetail;
