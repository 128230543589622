import React from "react";
import InternalLayout from "../components/InternalLayout";
import AcknowledgementComponent from "../components/Acknowledgement";
import withAuth from "../config/private-route";

const Acknowledgement = () => {
  return (
    <InternalLayout>
      <AcknowledgementComponent />
    </InternalLayout>
  );
};
export default withAuth(Acknowledgement);
