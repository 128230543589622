import { createSlice } from "@reduxjs/toolkit";
import { Iqama, Legal } from "../../interfaces/iqama";

interface Index {
  showExportDropdown: boolean;
  showFilterDropdown: boolean;
  iqamaLoading: boolean;
  iqamaError: string;
  requestRenewLoading: boolean;
  updateIqamaLoading: boolean;
  iqamaHandoverRequestLoading: boolean;
  iqamaHandoverLoading: boolean;
  iqamaLists: Iqama[];
  pageNo: number;
  pageSize: number;
  totalRecords: number;
  filterPageNo: number;
  filterPageSize: number;
  filterTotalRecords: number;
  //   Legal
  showLegalExportDropdown: boolean;
  addLegalLoading: boolean;
  legalLoading: boolean;
  legalError: string;
  legalLists: Legal[];
  legalPageNo: number;
  legalPageSize: number;
  legalTotalRecords: number;
  filterLegalPageNo: number;
  filterLegalPageSize: number;
  filterLegalTotalRecords: number;
}

const initialState: Index = {
  showFilterDropdown: false,
  showExportDropdown: false,
  requestRenewLoading: false,
  updateIqamaLoading: false,
  iqamaHandoverRequestLoading: false,
  iqamaHandoverLoading: false,
  iqamaLists: [],
  iqamaLoading: false,
  iqamaError: "",
  pageNo: 1,
  pageSize: 10,
  totalRecords: 0,
  filterPageNo: 1,
  filterPageSize: 10,
  filterTotalRecords: 0,
  //   Legal
  showLegalExportDropdown: false,
  addLegalLoading: false,
  legalLoading: false,
  legalError: "",
  legalLists: [],
  legalPageNo: 1,
  legalPageSize: 10,
  legalTotalRecords: 0,
  filterLegalPageNo: 1,
  filterLegalPageSize: 10,
  filterLegalTotalRecords: 0,
};

const iqamaSlice = createSlice({
  name: "iqama",
  initialState: initialState,
  reducers: {
    setShowFilterDropdown: (state, action) => {
      state.showFilterDropdown = action.payload;
    },
    setShowExportDropdown: (state, action) => {
      state.showExportDropdown = action.payload;
    },
    setIqamaLists: (state, action) => {
      state.iqamaLists = action.payload;
    },
    setIqamaLoading: (state, action) => {
      state.iqamaLoading = action.payload;
    },
    setIqamaError: (state, action) => {
      state.iqamaError = action.payload;
    },
    setRequestRenewLoading: (state, action) => {
      state.requestRenewLoading = action.payload;
    },
    setUpdateIqamaLoading: (state, action) => {
      state.updateIqamaLoading = action.payload;
    },
    setIqamaHandoverRequestLoading: (state, action) => {
      state.iqamaHandoverRequestLoading = action.payload;
    },
    setIqamaHandoverLoading: (state, action) => {
      state.iqamaHandoverLoading = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setFilterPageNo: (state, action) => {
      state.filterPageNo = action.payload;
    },
    setFilterPageSize: (state, action) => {
      state.filterPageSize = action.payload;
    },
    setFilterTotalRecords: (state, action) => {
      state.filterTotalRecords = action.payload;
    },
    // Legal
    setShowLegalExportDropdown: (state, action) => {
      state.showLegalExportDropdown = action.payload;
    },
    setLegalLists: (state, action) => {
      state.legalLists = action.payload;
    },
    setLegalLoading: (state, action) => {
      state.legalLoading = action.payload;
    },
    setLegalError: (state, action) => {
      state.legalError = action.payload;
    },
    setAddLegalLoading: (state, action) => {
      state.addLegalLoading = action.payload;
    },
    setLegalPageNo: (state, action) => {
      state.legalPageNo = action.payload;
    },
    setLegalPageSize: (state, action) => {
      state.legalPageSize = action.payload;
    },
    setLegalTotalRecords: (state, action) => {
      state.legalTotalRecords = action.payload;
    },
    setFilterLegalPageNo: (state, action) => {
      state.filterLegalPageNo = action.payload;
    },
    setFilterLegalPageSize: (state, action) => {
      state.filterLegalPageSize = action.payload;
    },
    setFilterLegalTotalRecords: (state, action) => {
      state.filterLegalTotalRecords = action.payload;
    },
  },
});

export const {
  setShowFilterDropdown,
  setIqamaHandoverLoading,
  setIqamaHandoverRequestLoading,
  setUpdateIqamaLoading,
  setRequestRenewLoading,
  setIqamaLoading,
  setShowExportDropdown,
  setIqamaError,
  setIqamaLists,
  setFilterTotalRecords,
  setFilterPageSize,
  setFilterPageNo,
  setTotalRecords,
  setPageSize,
  setPageNo,
  setShowLegalExportDropdown,
  setLegalLists,
  setLegalLoading,
  setLegalError,
  setAddLegalLoading,
  setLegalPageNo,
  setLegalPageSize,
  setLegalTotalRecords,
  setFilterLegalPageNo,
  setFilterLegalPageSize,
  setFilterLegalTotalRecords,
} = iqamaSlice.actions;
export default iqamaSlice.reducer;
