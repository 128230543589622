import React from "react";
import InternalLayout from "../components/InternalLayout";
import LegalComponent from "../components/Legals";
import withAuth from "../config/private-route";
import Content from "../components/Legals/get-legal-data-api";

const Legal = () => {
  return (
    <InternalLayout>
      <Content />
      <LegalComponent />
    </InternalLayout>
  );
};
export default withAuth(Legal);
