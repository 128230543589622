import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setProbationLoading,
  setFilterProbationTotalRecords,
} from "../../store/slices/employeeSlice";
import SelectSearch from "react-select-search";
import { ProbationEmployee } from "../../interfaces/employee";
// API imports
import { useGetAllFilterProbationEmployeesMutation } from "../../services/Employees";

interface Props {
  show?: boolean;
  setShow?: any;
  setProbation?: any;
  setFilterSearch: any;
  query: string;
  probationEmployees: ProbationEmployee[];
  filterInput: FormProps;
  setFilterInput: any;
  filterSearch: boolean;
}

export interface FormProps {
  probationFrom: string;
  probationTo: string;
  probationStatus: string;
  probationDuration: string;
}

const ProbationFilterModal = ({
  show,
  setShow,
  probationEmployees,
  setProbation,
  setFilterSearch,
  filterSearch,
  query,
  filterInput,
  setFilterInput,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [getAllFilterProbationEmployees] =
    useGetAllFilterProbationEmployeesMutation();
  // Handle Submit Function to filter probationEmployees based on the form values
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setProbationLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter probationEmployees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterProbationPageNo,
          limit: state.filterProbationPageSize,
          probationFrom: filterInput.probationFrom,
          probationTo: filterInput.probationTo,
          probationStatus: filterInput.probationStatus,
          probationDuration: filterInput.probationDuration,
        },
      };
      const response = await getAllFilterProbationEmployees(data).unwrap();
      if (response) {
        setProbation(response.data.probation[0].data);
        dispatch(
          setFilterProbationTotalRecords(
            response.data.probation[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setProbationLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setProbationLoading(true));
      // Filter probationEmployees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterProbationPageNo,
            limit: state.filterProbationPageSize,
            empId: parseInt(query),
            probationFrom: filterInput.probationFrom,
            probationTo: filterInput.probationTo,
            probationStatus: filterInput.probationStatus,
            probationDuration: filterInput.probationDuration,
          },
        };
        const response = await getAllFilterProbationEmployees(data).unwrap();
        if (response) {
          setProbation(response.data.probation[0].data);
          dispatch(
            setFilterProbationTotalRecords(
              response.data.probation[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setProbationLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterProbationPageNo, state.filterProbationPageSize, query]);

  const handleReset = () => {
    setProbation(probationEmployees);
    setFilterInput({
      probationFrom: "",
      probationTo: "",
      probationStatus: "",
      probationDuration: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Probation Employees</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Probation Duration
                    </h4>
                    <Form.Control
                      type="number"
                      name="probationDuration"
                      placeholder="Enter probation duration"
                      onChange={(e) => {
                        setFilterInput({
                          ...filterInput,
                          probationDuration: parseInt(e.target.value),
                        });
                      }}
                      value={filterInput.probationDuration || undefined}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Probation Status
                    </h4>
                    <Form.Select
                      name="probationStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          probationStatus: e.target.value,
                        })
                      }
                      value={filterInput.probationStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Probation From
                    </h4>
                    <Form.Control
                      type="date"
                      name="probationFrom"
                      placeholder="Enter probation from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          probationFrom: e.target.value,
                        })
                      }
                      value={filterInput.probationFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Probation To
                    </h4>
                    <Form.Control
                      type="date"
                      name="probationTo"
                      placeholder="Enter probation to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          probationTo: e.target.value,
                        })
                      }
                      value={filterInput.probationTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProbationFilterModal;
