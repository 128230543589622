import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";

interface AllowanceProps {
  data: {
    item: string;
    count: number;
    estimatedValue: number;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    item: string;
    count: number;
    estimatedValue: number;
  }) => void;
  uniqueId: string;
}

const CampAssetsSet: React.FC<AllowanceProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [item, setItem] = useState(data.item);
  const [count, setCount] = useState(data.count);
  const [estimatedValue, setEstimatedValue] = useState(data.estimatedValue);

  const handleInputChange = () => {
    onInputChange({
      item,
      count,
      estimatedValue,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [item, count, estimatedValue]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Item Name
                </h4>
                <Form.Control
                  type="text"
                  name="item"
                  placeholder="Enter item name"
                  onChange={(e) => setItem(e.target.value)}
                  value={item}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Item Count
                </h4>
                <Form.Control
                  type="number"
                  name="count"
                  placeholder="Enter item count"
                  onChange={(e) => setCount(Number(e.target.value))}
                  value={count}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Estimated Value
                </h4>
                <Form.Control
                  type="number"
                  name="estimatedValue"
                  placeholder="Enter estimated value"
                  onChange={(e) => setEstimatedValue(Number(e.target.value))}
                  value={estimatedValue}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CampAssetsSet;
