import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Common } from "../../../assets/images";

interface ExperienceProps {
  data: {
    month: string;
    amount: number;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: { month: string; amount: number }) => void;
  uniqueId: string;
  installments: {
    uId: string;
    month: string;
    amount: number;
    status: string;
  }[];
}

const returnLastDateOfMonth = (dates: string) => {
  const date = new Date(dates);
  // Get the first day of the next month
  date.setMonth(date.getMonth() + 1);
  return date;
};

const InstallmentsSet: React.FC<ExperienceProps> = ({
  onRemove,
  onInputChange,
  uniqueId,
  data,
  installments,
}) => {
  const [month, setMonth] = useState(data.month);
  const [amount, setAmount] = useState(data.amount.toString());
  const handleInputChange = () => {
    onInputChange({
      month: new Date(month).toLocaleDateString(),
      amount: parseFloat(amount),
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [month, amount]);

  const secondLastInstallment = installments[installments.length - 2];

  // minDate={
  //                       secondLastInstallment
  //                         ? returnLastDateOfMonth(secondLastInstallment.month)
  //                         : new Date()
  //                     }

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group className="mt-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Month
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="month"
                    selected={month ? new Date(month) : null}
                    onChange={(e: any) => setMonth(e + 1)}
                    showMonthYearPicker
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select start date"
                    className="w-100 custom-input"
                    dateFormat="MMMM"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mt-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Amount
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter amount"
                  name="amount"
                  onChange={(e) => {
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setAmount(e.target.value);
                    }
                  }}
                  value={amount}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => onRemove(uniqueId)}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InstallmentsSet;
