import React from "react";
// Package Imports
import { Col, Form, Row, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
// Redux Toolkit Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setUserIdentity,
  setUserInfo,
  setUserPassword,
  setShowDropDown,
  setCountryCode,
    setCountry
} from "../../store/slices/authenticationSlice";
// Files Imports
import { Common } from "../../assets/images";

interface Props {
  handleChange: any;
  handleBlur: any;
  values: any;
  touched: any;
  errors: any;
}

const UserInfo = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
}: Props) => {
  const dispatch = useAppDispatch();
  const { showDropDown, countryCode, country } = useAppSelector(
    (state) => state.authentication
  );

  const handleNextBtn = () => {
    dispatch(setUserInfo(false));
    dispatch(setUserPassword(true));
  };

  const handleBackBtn = () => {
    dispatch(setUserIdentity(true));
    dispatch(setUserInfo(false));
  };

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
  ];

  return (
    <Row>
      <Col lg="6">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
            <img src={Common.UserTie} alt="message" />
            <Form.Control
              type="text"
              name="role"
              placeholder="Role"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.role}
              disabled={true}
              className={touched.role && errors.role ? "is-invalid" : ""}
            />
          </Form.Group>

          {touched.role && errors.role ? (
            <div className="invalid-feedback">{errors.role}</div>
          ) : null}
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <Dropdown
              show={showDropDown}
              onToggle={() => dispatch(setShowDropDown(!showDropDown))}
              className="ms-auto task-detail-menu"
            >
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="p-0 border-0"
              >
                <img
                  src={
                    countryCode === "+966"
                      ? Common.SA
                      : countryCode === "+971"
                      ? Common.UAE
                      : countryCode === "+92"
                      ? Common.PK
                      : countryCode === "+91"
                      ? Common.IN
                      : countryCode === "+98"
                      ? Common.IR
                      : countryCode === "+884"
                      ? Common.BD
                      : countryCode === "+93"
                      ? Common.AF
                      : Common.UAE
                  }
                  height="18px"
                  width="26px"
                  className="me-2"
                  alt="uae"
                />
                <img src={Common.ArrowDown} alt="toggle" />
              </Dropdown.Toggle>
              <Dropdown.Menu show={showDropDown} className="px-3">
                <ul className="countries-list">
                  {countryCodes.map((item, index) => (
                    <li key={index}>
                      <button
                        className="btn p-0 border-0 d-flex align-items-center"
                        type="button"
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          dispatch(setCountryCode(item.dial_code));
                          dispatch(setCountry(item.name));
                          dispatch(setShowDropDown(false));
                        }}
                      >
                        <p className="mb-0 para-small">{item.name}</p>
                      </button>
                      <hr className="my-2" />
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <p className="mb-0 country-code ms-2">{countryCode}</p>
            <Form.Control
              type="text"
              name="phNo"
              placeholder="Phone number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phNo}
              className={touched.phNo && errors.phNo ? "is-invalid" : ""}
            />
          </Form.Group>
          {touched.location && errors.location ? (
            <div className="invalid-feedback">{errors.location}</div>
          ) : null}
          {touched.phNo && errors.phNo ? (
            <div className="invalid-feedback">{errors.phNo}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col lg="6">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
            <img src={Common.BuildingWhite} alt="message" />
            <Form.Control
              type="text"
              name="department"
              placeholder="Department"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.department}
              disabled={true}
              className={
                touched.department && errors.department ? "is-invalid" : ""
              }
            />
          </Form.Group>
          {touched.department && errors.department ? (
            <div className="invalid-feedback">{errors.department}</div>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
            <DatePicker
              name="dob"
              selected={values.dob ? new Date(values.dob) : null}
              onChange={(date: any) =>
                handleChange({
                  target: { name: "dob", value: date },
                })
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              maxDate={new Date()}
              placeholderText="Date of birth"
              className={
                errors.dob && touched.dob ? "is-invalid w-100" : "w-100"
              }
              dateFormat="yyyy-MM-d"
            />
          </Form.Group>
          {touched.dob && errors.dob ? (
            <div className="invalid-feedback">{errors.dob}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col lg="12">
        <div className="mt-4 d-flex justify-content-between">
          <button
            type="button"
            onClick={handleBackBtn}
            className="btn secondary-button nunito-font bg-blacks hover-effect text-white d-flex align-items-center w-25 justify-content-center"
          >
            <img
              src={Common.ArrowLeftWhite}
              className="me-2"
              alt="arrow-blue"
            />
            <span>Back</span>
          </button>
          <button
            type="button"
            onClick={handleNextBtn}
            className="btn secondary-button nunito-font d-flex align-items-center w-25 justify-content-center"
          >
            <span>Next</span>{" "}
            <img
              src={Common.ArrowRightBlue}
              className="ms-2"
              alt="arrow-blue"
            />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default UserInfo;
