import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters
import { useGetAllAbscondedEmployeesQuery } from "../../../services/Employees";
import {
  setAbscondedLoading,
  setAbscondedError,
  setAbscondedEmployees,
  setAbscondedTotalRecords,
} from "../../../store/slices/employeeSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const abscondedChange = useAppSelector(
    (state) => state.common.abscondedChange,
  );
  // States
  const employeeState = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Absconding Employees
  // **************
  // **************
  const {
    data: allAbscondedEmployees,
    isLoading: abscondedLoading,
    isError: abscondedError,
    refetch: abscondedRefetch,
  } = useGetAllAbscondedEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    pageNo: employeeState.abscondedPageNo,
    pageSize: employeeState.abscondedPageSize,
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (abscondedLoading) {
      dispatch(setAbscondedLoading(true));
    }
    if (abscondedError) {
      dispatch(setAbscondedError("Something went wrong"));
      dispatch(setAbscondedLoading(false));
    }
    if (allAbscondedEmployees) {
      dispatch(
        setAbscondedEmployees(
          allAbscondedEmployees.data.absconds[0].data || [],
        ),
      );
      dispatch(
        setAbscondedTotalRecords(
          allAbscondedEmployees.data.absconds[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setAbscondedLoading(false));
    }
  }, [
    accessToken,
    userID,
    allAbscondedEmployees,
    profileData,
    employeeState.abscondedPageNo,
    employeeState.abscondedPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    abscondedRefetch();
  }, [abscondedChange]);

  return <></>;
};
export default Content;
