import { createSlice } from "@reduxjs/toolkit";
import {
  BehaviourStatement,
  TransferEmployee,
  LeaveRequest,
  SalaryIncrement,
  FinaLExitEmployee,
  OfferLetter,
  Certificate,
  Expense,
} from "../../interfaces/letters";

interface Index {
  // Behaviour Statements
  showBSFilterDropdown: boolean;
  showBSExportDropdown: boolean;
  addBehaviourStatementLoading: boolean;
  behaviourStatementsLoading: boolean;
  behaviourStatementsError: string;
  behaviourStatements: BehaviourStatement[];
  bsPageNo: number;
  bsPageSize: number;
  bsTotalPages: number;
  filterBsPageNo: number;
  filterBsPageSize: number;
  filterBsTotalPages: number;
  // Expenses
  showExpensesFilterDropdown: boolean;
  showExpensesExportDropdown: boolean;
  addExpensesLoading: boolean;
  expensesLoading: boolean;
  expenses: Expense[];
  expensesError: string;
  expensesPageNo: number;
  expensesPageSize: number;
  expensesTotalPages: number;
  filterExpensesPageNo: number;
  filterExpensesPageSize: number;
  filterExpensesTotalPages: number;
  //   Transfer Employee
  showTransferEmployeeExportDropdown: boolean;
  addTransferEmployeeLoading: boolean;
  transferEmployeeLoading: boolean;
  transferEmployeeError: string;
  transferEmployee: TransferEmployee[];
  transferEmployeePageNo: number;
  transferEmployeePageSize: number;
  transferEmployeeTotalPages: number;
  filterTransferEmployeePageNo: number;
  filterTransferEmployeePageSize: number;
  filterTransferEmployeeTotalPages: number;
  tabList: string[];
  activeTab: string;
  showTransferRequests: boolean;
  showNewJoiners: boolean;
  // New Joiner
  showNewJoinerExportDropdown: boolean;
  addNewJoinerLoading: boolean;
  newJoinersLoading: boolean;
  newJoinersError: string;
  newJoiners: TransferEmployee[];
  newJoinerPageNo: number;
  newJoinerPageSize: number;
  newJoinerTotalPages: number;
  filterNewJoinerPageNo: number;
  filterNewJoinerPageSize: number;
  filterNewJoinerTotalPages: number;
  // Leave Request
  showLeaveRequestExportDropdown: boolean;
  addLeaveRequestLoading: boolean;
  leaveRequestLoading: boolean;
  leaveRequestError: string;
  leaveRequests: LeaveRequest[];
  leaveRequestPageNo: number;
  leaveRequestPageSize: number;
  leaveRequestTotalPages: number;
  filterLeaveRequestPageNo: number;
  filterLeaveRequestPageSize: number;
  filterLeaveRequestTotalPages: number;
  //   Salary Increment
  showSalaryIncrementExportDropdown: boolean;
  addSalaryIncrementLoading: boolean;
  salaryIncrementLoading: boolean;
  salaryIncrementError: string;
  salaryIncrements: SalaryIncrement[];
  salaryIncrementPageNo: number;
  salaryIncrementPageSize: number;
  salaryIncrementTotalPages: number;
  filterSalaryIncrementPageNo: number;
  filterSalaryIncrementPageSize: number;
  filterSalaryIncrementTotalPages: number;
  //   Final Exit
  showFinalExitExportDropdown: boolean;
  addFinalExitLoading: boolean;
  finalExitLoading: boolean;
  finalExitError: string;
  finalExits: FinaLExitEmployee[];
  finalExitPageNo: number;
  finalExitPageSize: number;
  finalExitTotalPages: number;
  filterFinalExitPageNo: number;
  filterFinalExitPageSize: number;
  filterFinalExitTotalPages: number;
  //   Offer Letter
  countryCode: string;
  showCountryCodeDropdown: boolean;
  offerLetter: OfferLetter[];
  offerLetterLoading: boolean;
  offerLetterError: string;
  offerLetterPageNo: number;
  offerLetterPageSize: number;
  offerLetterTotalPages: number;
  filterOfferLetterPageNo: number;
  filterOfferLetterPageSize: number;
  filterOfferLetterTotalPages: number;
  showOfferLetterExportDropdown: boolean;
  addOfferLetterLoading: boolean;
  //   Certificates
  addCertificateLoading: boolean;
  certificateLoading: boolean;
  certificateError: string;
  certificates: Certificate[];
  showCertificateExportDropdown: boolean;
  certificatePageNo: number;
  certificatePageSize: number;
  certificateTotalPages: number;
  filterCertificatePageNo: number;
  filterCertificatePageSize: number;
  filterCertificateTotalPages: number;
}

const initialState: Index = {
  // Behaviour Statements
  addBehaviourStatementLoading: false,
  behaviourStatementsLoading: false,
  behaviourStatementsError: "",
  behaviourStatements: [],
  showBSFilterDropdown: false,
  showBSExportDropdown: false,
  bsPageNo: 1,
  bsPageSize: 10,
  bsTotalPages: 0,
  filterBsPageNo: 1,
  filterBsPageSize: 10,
  filterBsTotalPages: 0,
  // Expenses
  addExpensesLoading: false,
  expensesLoading: false,
  expenses: [],
  showExpensesFilterDropdown: false,
  showExpensesExportDropdown: false,
  expensesError: "",
  expensesPageNo: 1,
  expensesPageSize: 10,
  expensesTotalPages: 0,
  filterExpensesPageNo: 1,
  filterExpensesPageSize: 10,
  filterExpensesTotalPages: 0,
  // Transfer Employee
  addTransferEmployeeLoading: false,
  transferEmployeeLoading: false,
  transferEmployeeError: "",
  transferEmployee: [],
  showTransferEmployeeExportDropdown: false,
  transferEmployeePageNo: 1,
  transferEmployeePageSize: 10,
  transferEmployeeTotalPages: 0,
  filterTransferEmployeePageNo: 1,
  filterTransferEmployeePageSize: 10,
  filterTransferEmployeeTotalPages: 0,
  tabList: ["Transfer Employees Requests", "New Joiners Requests"],
  activeTab: "Transfer Employees Requests",
  showTransferRequests: true,
  showNewJoiners: false,
  // New Joiner
  addNewJoinerLoading: false,
  newJoinersLoading: false,
  newJoinersError: "",
  newJoiners: [],
  showNewJoinerExportDropdown: false,
  newJoinerPageNo: 1,
  newJoinerPageSize: 10,
  newJoinerTotalPages: 0,
  filterNewJoinerPageNo: 1,
  filterNewJoinerPageSize: 10,
  filterNewJoinerTotalPages: 0,
  //   Leave Request
  addLeaveRequestLoading: false,
  leaveRequestLoading: false,
  leaveRequestError: "",
  leaveRequests: [],
  showLeaveRequestExportDropdown: false,
  leaveRequestPageNo: 1,
  leaveRequestPageSize: 10,
  leaveRequestTotalPages: 0,
  filterLeaveRequestPageNo: 1,
  filterLeaveRequestPageSize: 10,
  filterLeaveRequestTotalPages: 0,
  //   Salary Increment
  addSalaryIncrementLoading: false,
  salaryIncrementLoading: false,
  salaryIncrementError: "",
  salaryIncrements: [],
  showSalaryIncrementExportDropdown: false,
  salaryIncrementPageNo: 1,
  salaryIncrementPageSize: 10,
  salaryIncrementTotalPages: 0,
  filterSalaryIncrementPageNo: 1,
  filterSalaryIncrementPageSize: 10,
  filterSalaryIncrementTotalPages: 0,
  // Final Exit
  showFinalExitExportDropdown: false,
  addFinalExitLoading: false,
  finalExitLoading: false,
  finalExitError: "",
  finalExits: [],
  finalExitPageNo: 1,
  finalExitPageSize: 10,
  finalExitTotalPages: 0,
  filterFinalExitPageNo: 1,
  filterFinalExitPageSize: 10,
  filterFinalExitTotalPages: 0,
  // Offer Letter
  countryCode: "+966",
  showCountryCodeDropdown: false,
  offerLetter: [],
  offerLetterLoading: false,
  offerLetterError: "",
  offerLetterPageNo: 1,
  offerLetterPageSize: 10,
  offerLetterTotalPages: 0,
  filterOfferLetterPageNo: 1,
  filterOfferLetterPageSize: 10,
  filterOfferLetterTotalPages: 0,
  showOfferLetterExportDropdown: false,
  addOfferLetterLoading: false,
  //   Certificates
  addCertificateLoading: false,
  certificateLoading: false,
  certificateError: "",
  certificates: [],
  showCertificateExportDropdown: false,
  certificatePageNo: 1,
  certificatePageSize: 10,
  certificateTotalPages: 0,
  filterCertificatePageNo: 1,
  filterCertificatePageSize: 10,
  filterCertificateTotalPages: 0,
};

const lettersSlice = createSlice({
  name: "letters",
  initialState: initialState,
  reducers: {
    // Behaviour Statements
    setAddBehaviourStatementLoading: (state, action) => {
      state.addBehaviourStatementLoading = action.payload;
    },
    setBehaviourStatementsLoading: (state, action) => {
      state.behaviourStatementsLoading = action.payload;
    },
    setBehaviourStatementsError: (state, action) => {
      state.behaviourStatementsError = action.payload;
    },
    setBehaviourStatements: (state, action) => {
      state.behaviourStatements = action.payload;
    },
    setShowBSFilterDropdown: (state, action) => {
      state.showBSFilterDropdown = action.payload;
    },
    setShowBSExportDropdown: (state, action) => {
      state.showBSExportDropdown = action.payload;
    },
    setBsPageNo: (state, action) => {
      state.bsPageNo = action.payload;
    },
    setBsPageSize: (state, action) => {
      state.bsPageSize = action.payload;
    },
    setBsTotalPages: (state, action) => {
      state.bsTotalPages = action.payload;
    },
    setFilterBsPageNo: (state, action) => {
      state.filterBsPageNo = action.payload;
    },
    setFilterBsPageSize: (state, action) => {
      state.filterBsPageSize = action.payload;
    },
    setFilterBsTotalPages: (state, action) => {
      state.filterBsTotalPages = action.payload;
    },
    // Expenses
    setAddExpensesLoading: (state, action) => {
      state.addExpensesLoading = action.payload;
    },
    setExpensesLoading: (state, action) => {
      state.expensesLoading = action.payload;
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    setShowExpensesFilterDropdown: (state, action) => {
      state.showExpensesFilterDropdown = action.payload;
    },
    setShowExpensesExportDropdown: (state, action) => {
      state.showExpensesExportDropdown = action.payload;
    },
    setExpensesError: (state, action) => {
      state.expensesError = action.payload;
    },
    setExpensesPageNo: (state, action) => {
      state.expensesPageNo = action.payload;
    },
    setExpensesPageSize: (state, action) => {
      state.expensesPageSize = action.payload;
    },
    setExpensesTotalPages: (state, action) => {
      state.expensesTotalPages = action.payload;
    },
    setFilterExpensesPageNo: (state, action) => {
      state.filterExpensesPageNo = action.payload;
    },
    setFilterExpensesPageSize: (state, action) => {
      state.filterExpensesPageSize = action.payload;
    },
    setFilterExpensesTotalPages: (state, action) => {
      state.filterExpensesTotalPages = action.payload;
    },
    // Transfer Employee
    setAddTransferEmployeeLoading: (state, action) => {
      state.addTransferEmployeeLoading = action.payload;
    },
    setTransferEmployeeLoading: (state, action) => {
      state.transferEmployeeLoading = action.payload;
    },
    setTransferEmployeeError: (state, action) => {
      state.transferEmployeeError = action.payload;
    },
    setTransferEmployee: (state, action) => {
      state.transferEmployee = action.payload;
    },
    setShowTransferEmployeeExportDropdown: (state, action) => {
      state.showTransferEmployeeExportDropdown = action.payload;
    },
    setTransferEmployeePageNo: (state, action) => {
      state.transferEmployeePageNo = action.payload;
    },
    setTransferEmployeePageSize: (state, action) => {
      state.transferEmployeePageSize = action.payload;
    },
    setTransferEmployeeTotalPages: (state, action) => {
      state.transferEmployeeTotalPages = action.payload;
    },
    setFilterTransferEmployeePageNo: (state, action) => {
      state.filterTransferEmployeePageNo = action.payload;
    },
    setFilterTransferEmployeePageSize: (state, action) => {
      state.filterTransferEmployeePageSize = action.payload;
    },
    setFilterTransferEmployeeTotalPages: (state, action) => {
      state.filterTransferEmployeeTotalPages = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setShowTransferRequests: (state, action) => {
      state.showTransferRequests = action.payload;
    },
    setShowNewJoiners: (state, action) => {
      state.showNewJoiners = action.payload;
    },
    // New Joiner
    setAddNewJoinerLoading: (state, action) => {
      state.addNewJoinerLoading = action.payload;
    },
    setNewJoinersLoading: (state, action) => {
      state.newJoinersLoading = action.payload;
    },
    setNewJoinersError: (state, action) => {
      state.newJoinersError = action.payload;
    },
    setNewJoiners: (state, action) => {
      state.newJoiners = action.payload;
    },
    setShowNewJoinerExportDropdown: (state, action) => {
      state.showNewJoinerExportDropdown = action.payload;
    },
    setNewJoinerPageNo: (state, action) => {
      state.newJoinerPageNo = action.payload;
    },
    setNewJoinerPageSize: (state, action) => {
      state.newJoinerPageSize = action.payload;
    },
    setNewJoinerTotalPages: (state, action) => {
      state.newJoinerTotalPages = action.payload;
    },
    setFilterNewJoinerPageNo: (state, action) => {
      state.filterNewJoinerPageNo = action.payload;
    },
    setFilterNewJoinerPageSize: (state, action) => {
      state.filterNewJoinerPageSize = action.payload;
    },
    setFilterNewJoinerTotalPages: (state, action) => {
      state.filterNewJoinerTotalPages = action.payload;
    },
    //   Leave Request
    setAddLeaveRequestLoading: (state, action) => {
      state.addLeaveRequestLoading = action.payload;
    },
    setLeaveRequestLoading: (state, action) => {
      state.leaveRequestLoading = action.payload;
    },
    setLeaveRequestError: (state, action) => {
      state.leaveRequestError = action.payload;
    },
    setLeaveRequests: (state, action) => {
      state.leaveRequests = action.payload;
    },
    setShowLeaveRequestExportDropdown: (state, action) => {
      state.showLeaveRequestExportDropdown = action.payload;
    },
    setLeaveRequestPageNo: (state, action) => {
      state.leaveRequestPageNo = action.payload;
    },
    setLeaveRequestPageSize: (state, action) => {
      state.leaveRequestPageSize = action.payload;
    },
    setLeaveRequestTotalPages: (state, action) => {
      state.leaveRequestTotalPages = action.payload;
    },
    setFilterLeaveRequestPageNo: (state, action) => {
      state.filterLeaveRequestPageNo = action.payload;
    },
    setFilterLeaveRequestPageSize: (state, action) => {
      state.filterLeaveRequestPageSize = action.payload;
    },
    setFilterLeaveRequestTotalPages: (state, action) => {
      state.filterLeaveRequestTotalPages = action.payload;
    },
    //   Salary Increment
    setAddSalaryIncrementLoading: (state, action) => {
      state.addSalaryIncrementLoading = action.payload;
    },
    setSalaryIncrementLoading: (state, action) => {
      state.salaryIncrementLoading = action.payload;
    },
    setSalaryIncrementError: (state, action) => {
      state.salaryIncrementError = action.payload;
    },
    setSalaryIncrements: (state, action) => {
      state.salaryIncrements = action.payload;
    },
    setShowSalaryIncrementExportDropdown: (state, action) => {
      state.showSalaryIncrementExportDropdown = action.payload;
    },
    setSalaryIncrementPageNo: (state, action) => {
      state.salaryIncrementPageNo = action.payload;
    },
    setSalaryIncrementPageSize: (state, action) => {
      state.salaryIncrementPageSize = action.payload;
    },
    setSalaryIncrementTotalPages: (state, action) => {
      state.salaryIncrementTotalPages = action.payload;
    },
    setFilterSalaryIncrementPageNo: (state, action) => {
      state.filterSalaryIncrementPageNo = action.payload;
    },
    setFilterSalaryIncrementPageSize: (state, action) => {
      state.filterSalaryIncrementPageSize = action.payload;
    },
    setFilterSalaryIncrementTotalPages: (state, action) => {
      state.filterSalaryIncrementTotalPages = action.payload;
    },
    // Final Exit
    setAddFinalExitLoading: (state, action) => {
      state.addFinalExitLoading = action.payload;
    },
    setFinalExitLoading: (state, action) => {
      state.finalExitLoading = action.payload;
    },
    setFinalExitError: (state, action) => {
      state.finalExitError = action.payload;
    },
    setFinalExits: (state, action) => {
      state.finalExits = action.payload;
    },
    setShowFinalExitExportDropdown: (state, action) => {
      state.showFinalExitExportDropdown = action.payload;
    },
    setFinalExitPageNo: (state, action) => {
      state.finalExitPageNo = action.payload;
    },
    setFinalExitPageSize: (state, action) => {
      state.finalExitPageSize = action.payload;
    },
    setFinalExitTotalPages: (state, action) => {
      state.finalExitTotalPages = action.payload;
    },
    setFilterFinalExitPageNo: (state, action) => {
      state.filterFinalExitPageNo = action.payload;
    },
    setFilterFinalExitPageSize: (state, action) => {
      state.filterFinalExitPageSize = action.payload;
    },
    setFilterFinalExitTotalPages: (state, action) => {
      state.filterFinalExitTotalPages = action.payload;
    },
    // Offer Letter
    setOfferLetter: (state, action) => {
      state.offerLetter = action.payload;
    },
    setOfferLetterLoading: (state, action) => {
      state.offerLetterLoading = action.payload;
    },
    setOfferLetterError: (state, action) => {
      state.offerLetterError = action.payload;
    },
    setOfferLetterPageNo: (state, action) => {
      state.offerLetterPageNo = action.payload;
    },
    setOfferLetterPageSize: (state, action) => {
      state.offerLetterPageSize = action.payload;
    },
    setOfferLetterTotalPages: (state, action) => {
      state.offerLetterTotalPages = action.payload;
    },
    setFilterOfferLetterPageNo: (state, action) => {
      state.filterOfferLetterPageNo = action.payload;
    },
    setFilterOfferLetterPageSize: (state, action) => {
      state.filterOfferLetterPageSize = action.payload;
    },
    setFilterOfferLetterTotalPages: (state, action) => {
      state.filterOfferLetterTotalPages = action.payload;
    },
    setShowOfferLetterExportDropdown: (state, action) => {
      state.showOfferLetterExportDropdown = action.payload;
    },
    setAddOfferLetterLoading: (state, action) => {
      state.addOfferLetterLoading = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setCountryCodeDropdown: (state, action) => {
      state.showCountryCodeDropdown = action.payload;
    },
    //   Certificates
    setAddCertificateLoading: (state, action) => {
      state.addCertificateLoading = action.payload;
    },
    setCertificateLoading: (state, action) => {
      state.certificateLoading = action.payload;
    },
    setCertificateError: (state, action) => {
      state.certificateError = action.payload;
    },
    setCertificates: (state, action) => {
      state.certificates = action.payload;
    },
    setShowCertificateExportDropdown: (state, action) => {
      state.showCertificateExportDropdown = action.payload;
    },
    setCertificatePageNo: (state, action) => {
      state.certificatePageNo = action.payload;
    },
    setCertificatePageSize: (state, action) => {
      state.certificatePageSize = action.payload;
    },
    setCertificateTotalPages: (state, action) => {
      state.certificateTotalPages = action.payload;
    },
    setFilterCertificatePageNo: (state, action) => {
      state.filterCertificatePageNo = action.payload;
    },
    setFilterCertificatePageSize: (state, action) => {
      state.filterCertificatePageSize = action.payload;
    },
    setFilterCertificateTotalPages: (state, action) => {
      state.filterCertificateTotalPages = action.payload;
    },
  },
});

export const {
  setAddBehaviourStatementLoading,
  setShowExpensesExportDropdown,
  setShowExpensesFilterDropdown,
  setExpenses,
  setAddExpensesLoading,
  setExpensesLoading,
  setExpensesError,
  setExpensesPageNo,
  setExpensesPageSize,
  setExpensesTotalPages,
  setFilterExpensesPageNo,
  setFilterExpensesPageSize,
  setFilterExpensesTotalPages,
  setShowBSExportDropdown,
  setShowBSFilterDropdown,
  setBehaviourStatementsError,
  setBehaviourStatementsLoading,
  setBehaviourStatements,
  setBsTotalPages,
  setBsPageSize,
  setBsPageNo,
  setFilterBsPageNo,
  setFilterBsPageSize,
  setFilterBsTotalPages,
  setAddTransferEmployeeLoading,
  setTransferEmployeeLoading,
  setTransferEmployeeError,
  setTransferEmployee,
  setShowTransferEmployeeExportDropdown,
  setTransferEmployeePageNo,
  setTransferEmployeePageSize,
  setTransferEmployeeTotalPages,
  setFilterTransferEmployeePageNo,
  setFilterTransferEmployeePageSize,
  setFilterTransferEmployeeTotalPages,
  setFilterLeaveRequestPageSize,
  setFilterLeaveRequestTotalPages,
  setAddLeaveRequestLoading,
  setFilterLeaveRequestPageNo,
  setLeaveRequestTotalPages,
  setShowLeaveRequestExportDropdown,
  setLeaveRequestPageSize,
  setLeaveRequestLoading,
  setLeaveRequestError,
  setLeaveRequestPageNo,
  setLeaveRequests,
  setAddSalaryIncrementLoading,
  setFilterSalaryIncrementPageNo,
  setFilterSalaryIncrementPageSize,
  setFilterSalaryIncrementTotalPages,
  setSalaryIncrementError,
  setSalaryIncrementLoading,
  setSalaryIncrementPageNo,
  setSalaryIncrementPageSize,
  setSalaryIncrementTotalPages,
  setShowSalaryIncrementExportDropdown,
  setSalaryIncrements,
  setActiveTab,
  setShowTransferRequests,
  setShowNewJoiners,
  setAddFinalExitLoading,
  setFilterFinalExitPageNo,
  setFilterFinalExitPageSize,
  setFilterFinalExitTotalPages,
  setFinalExitError,
  setFinalExitLoading,
  setFinalExitPageNo,
  setFinalExitPageSize,
  setFinalExitTotalPages,
  setShowFinalExitExportDropdown,
  setFinalExits,
  setFilterOfferLetterPageNo,
  setFilterOfferLetterPageSize,
  setFilterOfferLetterTotalPages,
  setOfferLetter,
  setOfferLetterLoading,
  setOfferLetterError,
  setOfferLetterPageNo,
  setOfferLetterPageSize,
  setOfferLetterTotalPages,
  setShowOfferLetterExportDropdown,
  setAddOfferLetterLoading,
  setAddCertificateLoading,
  setCertificateLoading,
  setCertificateError,
  setCertificates,
  setShowCertificateExportDropdown,
  setCertificatePageNo,
  setCertificatePageSize,
  setCertificateTotalPages,
  setFilterCertificatePageNo,
  setFilterCertificatePageSize,
  setFilterCertificateTotalPages,
  setAddNewJoinerLoading,
  setFilterNewJoinerPageNo,
  setFilterNewJoinerPageSize,
  setFilterNewJoinerTotalPages,
  setNewJoinerPageNo,
  setNewJoinerPageSize,
  setNewJoiners,
  setNewJoinersError,
  setShowNewJoinerExportDropdown,
  setNewJoinersLoading,
  setNewJoinerTotalPages,
  setCountryCodeDropdown,
  setCountryCode,
} = lettersSlice.actions;
export default lettersSlice.reducer;
