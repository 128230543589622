import React, { useEffect, useRef } from "react";
import { ClientId } from "../../interfaces/project";
import { SponsorId } from "../../interfaces/sponsor";
import { Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import InvoiceFormat from "./invoice-format";
import { Buffer } from "buffer";
import { Invoice } from "@axenda/zatca";
global.Buffer = Buffer;

interface Props {
  type: string;
  client: ClientId;
  company: SponsorId;
  bankDetails: {
    bankName: string;
    iban: string;
    accountNumber: number;
    swiftCode: string;
  };
  invoiceNumber: string;
  subject: string;
  tax: string;
  removeLetterHead: boolean;
  invoiceDate: string;
  categories: {
    uId: string;
    discount: number;
    amount: number;
    category: string;
    quantity: number;
    categoryPrice: number;
    discountType: string;
  }[];
  creditNoteData: {
    creditNoteNumber: string;
    transactionType: string;
    creditNoteDate: string;
    totalAmountToBeReceived: number;
  };
}

const Invoices = ({
  categories,
  client,
  invoiceNumber,
  subject,
  tax,
  type,
  removeLetterHead,
  creditNoteData,
  company,
  bankDetails,
  invoiceDate,
}: Props) => {
  const offerLetterRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => offerLetterRef.current,
  });

  const [image, setImage] = React.useState("");

  const finalTax = parseFloat(tax) / 100;

  const totalAmount = categories.reduce(
    (acc, category) => acc + category.amount,
    0,
  );

  const totalAmountWithoutDiscount = categories.reduce(
    (acc, category) => acc + category.categoryPrice * category.quantity,
    0,
  );

  const totalVAT = categories.reduce(
    (acc, category) => acc + category.amount * finalTax,
    0,
  );

  const totalAmountDue = totalAmount + totalVAT;

  const amountToBeReceived = creditNoteData.totalAmountToBeReceived
    ? creditNoteData.totalAmountToBeReceived
    : 0;

  const invoice = new Invoice({
    sellerName: company.companyNameArabic,
    vatRegistrationNumber: company.numberVAT.toString(),
    invoiceTimestamp:
      type === "Invoice"
        ? new Date(invoiceDate).toISOString()
        : new Date(creditNoteData.creditNoteDate).toISOString(),
    invoiceTotal: totalAmountDue.toString(),
    invoiceVatTotal: totalVAT.toString(),
  });

  const renderImage = async () => {
    const imageData = await invoice.render();
    setImage(imageData);
  };

  useEffect(() => {
    renderImage();
  }, []);

  useEffect(() => {
    if (image) {
      handlePrint();
    }
  }, [image]);

  return (
    <div className="letter-container position-relative" ref={offerLetterRef}>
      {removeLetterHead ? (
        <div className="px-4">
          <h4
            className="heading text-center text-uppercase mt-4"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            {type === "Invoice" ? "فاتورة ضريبية" : "الإشعارالدائن"}
          </h4>
          <h4
            className="heading text-center text-uppercase mb-4"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            {type === "Invoice" ? "Tax Invoice" : "Credit Note"}
          </h4>
          <div className="d-flex align-items-center justify-content-between mb-2 position-relative">
            <div>
              <div className="table-container-letter mb-3">
                <div className="table-content-letter">
                  <Table
                    responsive
                    borderless
                    className="text-center mb-0 emp-detail-table"
                  >
                    <tbody>
                      {type === "Credit Note" && (
                        <>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Credit Note#:</td>
                            <td>{creditNoteData.creditNoteNumber}</td>
                            <td className="text-end">
                              {":"}رقم الاشعار الدائن
                            </td>
                          </tr>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Credit Note Date:</td>
                            <td>
                              {" "}
                              {new Date(
                                creditNoteData.creditNoteDate,
                              ).toDateString()}{" "}
                            </td>
                            <td className="text-end">{":"}تاریخ الائتمان</td>
                          </tr>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Transaction Type:</td>
                            <td> {creditNoteData.transactionType} </td>
                            <td className="text-end">{":"}نوع المعاملة</td>
                          </tr>
                        </>
                      )}
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Invoice#:</td>
                        <td>{invoiceNumber}</td>
                        <td className="text-end">{":"}رقم الفاتورة</td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Invoice Date:</td>
                        <td> {new Date(invoiceDate).toDateString()} </td>
                        <td className="text-end">{":"}تاريخ إصدار الفاتورة</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div>
              <img
                src={image}
                style={{
                  position: "absolute",
                  top: "-36px",
                  right: "0",
                  width: "90px",
                  height: "90px",
                }}
                alt="qr-code"
              />
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                    <td
                      className="d-flex align-items-center justify-content-between"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Seller
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        المورد
                      </h4>
                    </td>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Buyer
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        العمیل
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">Name:</td>
                    <td className="text-start">
                      {" "}
                      {company.companyName + " " + company.companyNameArabic}
                    </td>
                    <td className="text-end justify-content-end">
                      {":"} الاسم
                    </td>
                    <td className="text-start">Name:</td>
                    <td className="text-start">
                      {" "}
                      {client.clientName + " " + client.clientNameArabic}
                    </td>
                    <td className="text-end justify-content-end">
                      {":"} الاسم
                    </td>
                  </tr>
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">Address:</td>
                    <td className="text-start">
                      {" "}
                      {company.companyAddress +
                        " - " +
                        company.companyAddressArabic}
                    </td>
                    <td className="text-end justify-content-end">العنوان</td>
                    <td className="text-start">Address:</td>
                    <td className="text-start">
                      {client.clientAddress1 +
                        " - " +
                        client.clientAddressArabic}
                    </td>
                    <td className="text-end justify-content-end">العنوان</td>
                  </tr>
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">City:</td>*/}
                  {/*  <td className="text-start"> Jeddah</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}مدینة</td>*/}
                  {/*  <td className="text-start">City:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}مدینة</td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Country:</td>*/}
                  {/*  <td className="text-start">*/}
                  {/*    {" "}*/}
                  {/*    Saudi Arabia/المملکة العربیة السعودیة*/}
                  {/*  </td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}دولة</td>*/}
                  {/*  <td className="text-start">Country:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}دولة</td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Zip/Postal Code:</td>*/}
                  {/*  <td className="text-start"> 23345</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}الرمزﺍالکود البریدی*/}
                  {/*  </td>*/}
                  {/*  <td className="text-start">Zip/Postal Code:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}الرمزﺍالکود البریدی*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Phone:</td>*/}
                  {/*  <td className="text-start"> 0126171956</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}ھاتف</td>*/}
                  {/*  <td className="text-start">Phone:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}ھاتف</td>*/}
                  {/*</tr>*/}
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">VAT Number:</td>
                    <td className="text-start"> {company.numberVAT}</td>
                    <td className="text-end justify-content-end">
                      {":"}رقم تسجیل ضريبة القیمة المضافة
                    </td>
                    <td className="text-start">VAT Number:</td>
                    <td className="text-start"> {client.numberVAT}</td>
                    <td className="text-end justify-content-end">
                      {":"}رقم تسجیل ضريبة القیمة المضافة
                    </td>
                  </tr>
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Other Seller ID:</td>*/}
                  {/*  <td className="text-start"> Company ID: 4030226923</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}معرف آخر*/}
                  {/*  </td>*/}
                  {/*  <td className="text-start">Other Seller ID:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}معرف آخر*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="mb-3">
            <h4
              className="sub-heading text-black"
              style={{ fontSize: "9px", fontWeight: "400" }}
            >
              Subject (الموضوع) : {subject}
            </h4>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Line Items:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        عناصرالبند
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 5fr 2fr 2fr 2fr 3fr 3fr 3fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">
                        # <span className="d-block">رقم</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Description <span className="d-block">التفاصیل</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Unit Price <span className="d-block">سعر الوحدة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Quantity <span className="d-block">کمیة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      {type === "Invoice" ? (
                        <span className="w-100">
                          Discount <span className="d-block">تخفيض</span>
                        </span>
                      ) : (
                        <span className="w-100">
                          Tax <span className="d-block">ضريبة</span>
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Total Amount{" "}
                        <span className="d-block">المبلغ الخاضع للضريبة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Tax <span className="d-block">ضريبة</span>
                      </span>
                    </td>
                    <td className="text-center ms-0">
                      <span className="w-100" style={{ fontSize: "7px" }}>
                        Item Subtotal (Including VAT){" "}
                        <span className="d-block" style={{ fontSize: "7px" }}>
                          المجموع (شامل ضريبة القیمة المضافة)
                        </span>
                      </span>
                    </td>
                  </tr>
                  {categories.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 5fr 2fr 2fr 2fr 3fr 3fr 3fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.category}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.categoryPrice} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.quantity}
                      </td>
                      <td className="text-center justify-content-center">
                        {type === "Invoice" ? category.discount : tax}{" "}
                        {type === "Invoice"
                          ? category.discountType === "percent"
                            ? "%"
                            : "SAR"
                          : "%"}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount * finalTax} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount + category.amount * finalTax} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Total Amount:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        المبلغ الإجمالی
                      </h4>
                    </td>
                  </tr>
                  {type === "Invoice" && (
                    <>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Taxable Amount(Excluding VAT)
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الاجمالیی الخاضع للضربیة ﴿غیر شامل ضربیة القیمة
                          المضافة﴾
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">Total Discount</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          إجمالي الخصم
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmountWithoutDiscount - totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total VAT</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          مجموع ضربیة القیمة المضافة
                        </td>
                        <td className="text-center justify-content-end">
                          {totalVAT} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total Amount Due</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الاجمالیی المبلغ المستحق
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmountDue} SAR
                        </td>
                      </tr>
                    </>
                  )}
                  {type === "Credit Note" && (
                    <>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Returned(Excluding VAT)
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          إجمالي العائد (باستثناء ضريبة القيمة المضافة)
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total VAT</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          مجموع ضريبة القیمة المضافة
                        </td>
                        <td className="text-center justify-content-end">
                          {totalVAT} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Amount to be Recieved
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          المبلغ الإجمالي الذي سيتم الاستلام
                        </td>
                        <td className="text-center justify-content-end">
                          {amountToBeReceived} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Credits Remaining</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الائتمانات المتبقية
                        </td>
                        <td className="text-center justify-content-end">
                          {amountToBeReceived > 0
                            ? amountToBeReceived - totalAmountDue
                            : 0}{" "}
                          SAR
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          {/*<div className="table-container-letter invoice-container-letter w-100 mb-3">*/}
          {/*  <div className="table-content-letter">*/}
          {/*    <Table*/}
          {/*      responsive*/}
          {/*      borderless*/}
          {/*      className="text-center mb-0 emp-detail-table"*/}
          {/*    >*/}
          {/*      <tbody>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center" style={{ background: "#e5e5e5" }}>*/}
          {/*            Tax Details*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Taxable Amount (SAR)*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Tax Amount (SAR)*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end ms-0"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Total Amount (SAR)*/}
          {/*          </td>*/}
          {/*        </tr>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center">Standard Rate (15%)</td>*/}
          {/*          <td className="text-center justify-content-end">12,500</td>*/}
          {/*          <td className="text-center justify-content-end">1875</td>*/}
          {/*          <td className="text-center justify-content-end">14375</td>*/}
          {/*        </tr>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center">Total</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 12500</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 1875</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 14375</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </Table>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {type === "Invoice" && (
            <div className="table-container-letter invoice-container-letter w-100 mb-3">
              <div className="table-content-letter">
                <Table
                  responsive
                  borderless
                  className="text-center mb-0 emp-detail-table"
                >
                  <tbody>
                    <tr style={{ gridTemplateColumns: "none" }}>
                      <td
                        className="d-flex align-items-center justify-content-between ms-0"
                        style={{ background: "#e5e5e5" }}
                      >
                        <h4
                          className="mb-0"
                          style={{ fontSize: "10px", padding: "1px" }}
                        >
                          Bank Details:
                        </h4>
                        <h4
                          className="mb-0"
                          style={{ fontSize: "10px", padding: "1px" }}
                        >
                          تفاصيل البنك
                        </h4>
                      </td>
                    </tr>
                    <tr
                      style={{
                        gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      }}
                    >
                      <td className="text-center">
                        <span className="w-100">Bank Name</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">Account Number</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">IBAN</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">Swift Code</span>
                      </td>
                    </tr>

                    <tr
                      style={{
                        gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      }}
                    >
                      <td className="text-center justify-content-center">
                        {bankDetails.bankName}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.accountNumber}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.iban}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.swiftCode}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <InvoiceFormat type={type}>
          <div className="d-flex align-items-center justify-content-between mb-2 position-relative">
            <div>
              <div className="table-container-letter mb-3">
                <div className="table-content-letter">
                  <Table
                    responsive
                    borderless
                    className="text-center mb-0 emp-detail-table"
                  >
                    <tbody>
                      {type === "Credit Note" && (
                        <>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Credit Note#:</td>
                            <td>{creditNoteData.creditNoteNumber}</td>
                            <td className="text-end">
                              {":"}رقم الاشعار الدائن
                            </td>
                          </tr>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Credit Note Date:</td>
                            <td>
                              {" "}
                              {new Date(
                                creditNoteData.creditNoteDate,
                              ).toDateString()}{" "}
                            </td>
                            <td className="text-end">{":"}تاریخ الائتمان</td>
                          </tr>
                          <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                            <td>Transaction Type:</td>
                            <td> {creditNoteData.transactionType} </td>
                            <td className="text-end">{":"}نوع المعاملة</td>
                          </tr>
                        </>
                      )}
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Invoice#:</td>
                        <td>{invoiceNumber}</td>
                        <td className="text-end">{":"}رقم الفاتورة</td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Invoice Date:</td>
                        <td> {new Date(invoiceDate).toDateString()} </td>
                        <td className="text-end">{":"}تاريخ إصدار الفاتورة</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div>
              <img
                src={image}
                style={{
                  position: "absolute",
                  top: "-36px",
                  right: "0",
                  width: "90px",
                  height: "90px",
                }}
                alt="qr-code"
              />
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                    <td
                      className="d-flex align-items-center justify-content-between"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Seller
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        المورد
                      </h4>
                    </td>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Buyer
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        العمیل
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">Name:</td>
                    <td className="text-start">
                      {" "}
                      {company.companyName + " " + company.companyNameArabic}
                    </td>
                    <td className="text-end justify-content-end">
                      {":"} الاسم
                    </td>
                    <td className="text-start">Name:</td>
                    <td className="text-start">
                      {" "}
                      {client.clientName + " " + client.clientNameArabic}
                    </td>
                    <td className="text-end justify-content-end">
                      {":"} الاسم
                    </td>
                  </tr>
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">Address:</td>
                    <td className="text-start">
                      {" "}
                      {company.companyAddress +
                        " - " +
                        company.companyAddressArabic}
                    </td>
                    <td className="text-end justify-content-end">العنوان</td>
                    <td className="text-start">Address:</td>
                    <td className="text-start">
                      {client.clientAddress1 +
                        " - " +
                        client.clientAddressArabic}
                    </td>
                    <td className="text-end justify-content-end">العنوان</td>
                  </tr>
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">City:</td>*/}
                  {/*  <td className="text-start"> Jeddah</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}مدینة</td>*/}
                  {/*  <td className="text-start">City:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}مدینة</td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Country:</td>*/}
                  {/*  <td className="text-start">*/}
                  {/*    {" "}*/}
                  {/*    Saudi Arabia/المملکة العربیة السعودیة*/}
                  {/*  </td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}دولة</td>*/}
                  {/*  <td className="text-start">Country:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}دولة</td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Zip/Postal Code:</td>*/}
                  {/*  <td className="text-start"> 23345</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}الرمزﺍالکود البریدی*/}
                  {/*  </td>*/}
                  {/*  <td className="text-start">Zip/Postal Code:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}الرمزﺍالکود البریدی*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Phone:</td>*/}
                  {/*  <td className="text-start"> 0126171956</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}ھاتف</td>*/}
                  {/*  <td className="text-start">Phone:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">{":"}ھاتف</td>*/}
                  {/*</tr>*/}
                  <tr
                    style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}
                  >
                    <td className="text-start">VAT Number:</td>
                    <td className="text-start"> {company.numberVAT}</td>
                    <td className="text-end justify-content-end">
                      {":"}رقم تسجیل ضريبة القیمة المضافة
                    </td>
                    <td className="text-start">VAT Number:</td>
                    <td className="text-start"> {client.numberVAT}</td>
                    <td className="text-end justify-content-end">
                      {":"}رقم تسجیل ضريبة القیمة المضافة
                    </td>
                  </tr>
                  {/*<tr style={{ gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr 2fr" }}>*/}
                  {/*  <td className="text-start">Other Seller ID:</td>*/}
                  {/*  <td className="text-start"> Company ID: 4030226923</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}معرف آخر*/}
                  {/*  </td>*/}
                  {/*  <td className="text-start">Other Seller ID:</td>*/}
                  {/*  <td className="text-start"> Senyar</td>*/}
                  {/*  <td className="text-end justify-content-end">*/}
                  {/*    {":"}معرف آخر*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="mb-3">
            <h4
              className="sub-heading text-black"
              style={{ fontSize: "9px", fontWeight: "400" }}
            >
              Subject (الموضوع) : {subject}
            </h4>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Line Items:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        عناصرالبند
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 5fr 2fr 2fr 2fr 3fr 3fr 3fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">
                        # <span className="d-block">رقم</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Description <span className="d-block">التفاصیل</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Unit Price <span className="d-block">سعر الوحدة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Quantity <span className="d-block">کمیة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      {type === "Invoice" ? (
                        <span className="w-100">
                          Discount <span className="d-block">تخفيض</span>
                        </span>
                      ) : (
                        <span className="w-100">
                          Tax <span className="d-block">ضريبة</span>
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Total Amount{" "}
                        <span className="d-block">المبلغ الخاضع للضريبة</span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="w-100">
                        Tax <span className="d-block">ضريبة</span>
                      </span>
                    </td>
                    <td className="text-center ms-0">
                      <span className="w-100" style={{ fontSize: "7px" }}>
                        Item Subtotal (Including VAT){" "}
                        <span className="d-block" style={{ fontSize: "7px" }}>
                          المجموع (شامل ضريبة القیمة المضافة)
                        </span>
                      </span>
                    </td>
                  </tr>
                  {categories.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 5fr 2fr 2fr 2fr 3fr 3fr 3fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.category}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.categoryPrice} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.quantity}
                      </td>
                      <td className="text-center justify-content-center">
                        {type === "Invoice" ? category.discount : tax}{" "}
                        {type === "Invoice"
                          ? category.discountType === "percent"
                            ? "%"
                            : "SAR"
                          : "%"}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount * finalTax} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount + category.amount * finalTax} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        Total Amount:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "9px", padding: "1px" }}
                      >
                        المبلغ الإجمالی
                      </h4>
                    </td>
                  </tr>
                  {type === "Invoice" && (
                    <>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Taxable Amount(Excluding VAT)
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الاجمالیی الخاضع للضربیة ﴿غیر شامل ضربیة القیمة
                          المضافة﴾
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">Total Discount</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          إجمالي الخصم
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmountWithoutDiscount - totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total VAT</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          مجموع ضربیة القیمة المضافة
                        </td>
                        <td className="text-center justify-content-end">
                          {totalVAT} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total Amount Due</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الاجمالیی المبلغ المستحق
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmountDue} SAR
                        </td>
                      </tr>
                    </>
                  )}
                  {type === "Credit Note" && (
                    <>
                      <tr style={{ gridTemplateColumns: " 4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Returned(Excluding VAT)
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          إجمالي العائد (باستثناء ضريبة القيمة المضافة)
                        </td>
                        <td className="text-center justify-content-end">
                          {totalAmount} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Total VAT</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          مجموع ضريبة القیمة المضافة
                        </td>
                        <td className="text-center justify-content-end">
                          {totalVAT} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">
                          Total Amount to be Recieved
                        </td>
                        <td className="text-center justify-content-end">
                          {" "}
                          المبلغ الإجمالي الذي سيتم الاستلام
                        </td>
                        <td className="text-center justify-content-end">
                          {amountToBeReceived} SAR
                        </td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "4fr 4fr 3fr" }}>
                        <td className="text-center">Credits Remaining</td>
                        <td className="text-center justify-content-end">
                          {" "}
                          الائتمانات المتبقية
                        </td>
                        <td className="text-center justify-content-end">
                          {amountToBeReceived > 0
                            ? amountToBeReceived - totalAmountDue
                            : 0}{" "}
                          SAR
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          {/*<div className="table-container-letter invoice-container-letter w-100 mb-3">*/}
          {/*  <div className="table-content-letter">*/}
          {/*    <Table*/}
          {/*      responsive*/}
          {/*      borderless*/}
          {/*      className="text-center mb-0 emp-detail-table"*/}
          {/*    >*/}
          {/*      <tbody>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center" style={{ background: "#e5e5e5" }}>*/}
          {/*            Tax Details*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Taxable Amount (SAR)*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Tax Amount (SAR)*/}
          {/*          </td>*/}
          {/*          <td*/}
          {/*            className="text-center justify-content-end ms-0"*/}
          {/*            style={{ background: "#e5e5e5" }}*/}
          {/*          >*/}
          {/*            Total Amount (SAR)*/}
          {/*          </td>*/}
          {/*        </tr>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center">Standard Rate (15%)</td>*/}
          {/*          <td className="text-center justify-content-end">12,500</td>*/}
          {/*          <td className="text-center justify-content-end">1875</td>*/}
          {/*          <td className="text-center justify-content-end">14375</td>*/}
          {/*        </tr>*/}
          {/*        <tr style={{ gridTemplateColumns: "3fr 2fr 2fr 2fr" }}>*/}
          {/*          <td className="text-center">Total</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 12500</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 1875</td>*/}
          {/*          <td className="text-center justify-content-end">SAR 14375</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </Table>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {type === "Invoice" && (
            <div className="table-container-letter invoice-container-letter w-100 mb-3">
              <div className="table-content-letter">
                <Table
                  responsive
                  borderless
                  className="text-center mb-0 emp-detail-table"
                >
                  <tbody>
                    <tr style={{ gridTemplateColumns: "none" }}>
                      <td
                        className="d-flex align-items-center justify-content-between ms-0"
                        style={{ background: "#e5e5e5" }}
                      >
                        <h4
                          className="mb-0"
                          style={{ fontSize: "10px", padding: "1px" }}
                        >
                          Bank Details:
                        </h4>
                        <h4
                          className="mb-0"
                          style={{ fontSize: "10px", padding: "1px" }}
                        >
                          تفاصيل البنك
                        </h4>
                      </td>
                    </tr>
                    <tr
                      style={{
                        gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      }}
                    >
                      <td className="text-center">
                        <span className="w-100">Bank Name</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">Account Number</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">IBAN</span>
                      </td>
                      <td className="text-center">
                        <span className="w-100">Swift Code</span>
                      </td>
                    </tr>

                    <tr
                      style={{
                        gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      }}
                    >
                      <td className="text-center justify-content-center">
                        {bankDetails.bankName}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.accountNumber}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.iban}
                      </td>
                      <td className="text-center justify-content-center">
                        {bankDetails.swiftCode}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </InvoiceFormat>
      )}
    </div>
  );
};
export default Invoices;
