import React from "react";
import ResetPasswordComponent from "../components/ResetPassword";

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordComponent />
    </>
  );
};

export default ResetPassword;
