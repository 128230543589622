import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../../assets/images";
import { useUploadImageMutation } from "../../../services/images-upload-service";
import { useAppSelector } from "../../../store/hooks";
import { LoaderBlue } from "../../Lotties";
import DatePicker from "react-datepicker";

interface SkillsProps {
  data: {
    title: string;
    link: string;
    date: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: { title: string; link: string; date: string }) => void;
  uniqueId: string;
}

const AttachmentSet: React.FC<SkillsProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [title, setTitle] = useState(data.title);
  const [link, setLink] = useState(data.link);
  const [date, setDate] = useState(data.date);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState(data.link);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const handleInputChange = () => {
    onInputChange({
      title,
      link,
      date: new Date(date).toLocaleDateString(),
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [title, link, date]);

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setLink(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  // Using the URL API to extract the pathname
  const pathname = imageUploaded ? new URL(imageUploaded).pathname : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split(".").pop();

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Title
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter file title"
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="date"
                    selected={date ? new Date(date) : null}
                    onChange={(date: any) => setDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Enter Date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="8">
              <Form.Group>
                {imageUploaded ? null : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Attachment
                    </h4>
                  </div>
                )}
                {imageLoading ? (
                  <LoaderBlue height={50} width={50} />
                ) : (
                  <>
                    {imageUploaded ? (
                      <div className="d-flex">
                        <div className="text-center">
                          <img
                            src={
                              fileNameWithExtension === "jpg" ||
                              fileNameWithExtension === "png" ||
                              fileNameWithExtension === "jpeg"
                                ? Common.ImageIcon
                                : Common.FileIcon
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                            alt="employee-image"
                          />
                          <p className="para-medium textBlack text-center">
                            {title}.{fileNameWithExtension}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <Form.Label
                        className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center"
                        style={{ height: "auto", padding: "8px 24px" }}
                      >
                        <Form.Control
                          type="file"
                          name="profileImage"
                          onChange={handleUploadImage}
                          accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, docx, .xlsx, .xls"
                          className="d-none"
                          multiple={false}
                        />
                        <div className="text-center d-flex align-items-center gap-3">
                          <div
                            className="add-profile-image d-flex align-items-center justify-content-center mx-auto"
                            style={{ width: "30px", height: "30px" }}
                          >
                            <img
                              src={EmployeeIcons.Plus}
                              width="25px"
                              alt="plus"
                            />
                          </div>
                          <p
                            className="upload-para mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            Upload PNG, JPG, PDF, CSV, DOCS, XLSX File only
                          </p>
                        </div>
                      </Form.Label>
                    )}
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => onRemove(uniqueId)}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AttachmentSet;
