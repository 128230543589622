import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Client } from "../../interfaces/project";
import { Common } from "../../assets/images";

interface Props {
  clientsData: Client;
}

const ViewClient = ({ clientsData }: Props) => {
  const navigate = useNavigate();

  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                Attachments
              </h4>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {clientsData.clientAttachments.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Date</th>
                    <th>File Title</th>
                    <th>Uploader Emp Id</th>
                    <th>Uploader Emp Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsData.clientAttachments.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.date
                          ? new Date(item.date).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.title}</td>
                      <td>{item.empId}</td>
                      <td>{item.empName}</td>
                      <td>
                        <a href={item.link} download>
                          <img
                            src={Common.Download}
                            className="me-2"
                            width="24px"
                            height="24px"
                            alt="share"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Attachment Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default ViewClient;
