import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { AddEmployeeProps } from "./add-employee-container";
import { Common } from "../../../assets/images";
import OfferLetterAllowanceSet from "./offer-letter-allowances";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setOtherDetails,
  setLegalInfo,
  setAgreementDetails,
} from "../../../store/slices/employeeSlice";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setErrorMessage: any;
  setShowError: any;
}

const OtherDetails = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShowError,
  setErrorMessage,
}: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >([]);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  useEffect(() => {
    values.allowances = experienceData;
  }, [experienceData]);
  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Other Details</h3>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Basic Salary
          </h4>
          <Form.Control
            type="text"
            name="basicSalary"
            placeholder="Enter basic salary"
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.basicSalary}
            className={
              touched.basicSalary && errors.basicSalary
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.basicSalary && errors.basicSalary ? (
            <div className="invalid-feedback">{errors.basicSalary}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Duty Hours
          </h4>
          <Form.Control
            type="text"
            name="dutyHours"
            placeholder="Enter duty hours"
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.dutyHours}
            className={
              touched.dutyHours && errors.dutyHours
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.dutyHours && errors.dutyHours ? (
            <div className="invalid-feedback">{errors.dutyHours}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Salary Period
          </h4>
          <Form.Select
            name="salaryPeriod"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.salaryPeriod}
            className={
              touched.salaryPeriod && errors.salaryPeriod
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          >
            <option value="">Select salary period</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>
          </Form.Select>

          {touched.salaryPeriod && errors.salaryPeriod ? (
            <div className="invalid-feedback">{errors.salaryPeriod}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center gap-4">
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={handleAddExperienceSet}
            style={{ width: "15%" }}
          >
            <img
              src={Common.Plus}
              width="20px"
              height="20px"
              className="me-2"
              alt="next"
            />{" "}
            Allowance
          </button>
        </div>
      </Col>
      <Col sm="12">
        <div className="extra-detail my-4">
          {experienceSets.length > 0 && (
            <h4 className="sub-heading normal-font-weight mb-4">Allowances</h4>
          )}
          {experienceSets}
        </div>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setLegalInfo(true));
              dispatch(setOtherDetails(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            onClick={() => {
              if (values.basicSalary === "") {
                window.scrollTo(0, 0);
                setShowError(true);
                setErrorMessage("Please enter basic salary");
                return;
              }
              if (values.dutyHours === "") {
                window.scrollTo(0, 0);
                setShowError(true);
                setErrorMessage("Please enter duty hours");
                return;
              }
              dispatch(setOtherDetails(false));
              dispatch(setAgreementDetails(true));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            Next <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default OtherDetails;
