import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import { Iqama } from "../../interfaces/iqama";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterTotalRecords,
  setIqamaLoading,
} from "../../store/slices/iqamaSlice";
// API imports
import { useGetFilterIqamaListMutation } from "../../services/Iqama";

interface Props {
  show?: boolean;
  setShow?: any;
  setIqama?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  iqama: Iqama[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  status: string;
  sponsorName: string;
  professionOnIqama: string;
  fromDate: string;
  toDate: string;
  workPermitExpiry: string;
}

const IqamaFilterModal = ({
  show,
  setShow,
  iqama,
  setIqama,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.iqama);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [getFilterIqamaList] = useGetFilterIqamaListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setIqamaLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showLegal || false,
        payload: {
          page: state.filterPageNo,
          limit: state.filterPageSize,
          status: filterInput.status,
          workPermitExpiry: filterInput.workPermitExpiry,
          sponsorName: filterInput.sponsorName,
          professionOnIqama: filterInput.professionOnIqama,
          fromDate: filterInput.fromDate,
          toDate: filterInput.toDate,
        },
      };
      const response = await getFilterIqamaList(data).unwrap();
      if (response) {
        setIqama(response.data.iqamaLists[0].data);
        dispatch(
          setFilterTotalRecords(response.data.iqamaLists[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setIqamaLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIqamaLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showLegal || false,
          payload: {
            page: state.filterPageNo,
            limit: state.filterPageSize,
            empId: parseInt(query),
            status: filterInput.status,
            workPermitExpiry: filterInput.workPermitExpiry,
            sponsorName: filterInput.sponsorName,
            professionOnIqama: filterInput.professionOnIqama,
            fromDate: filterInput.fromDate,
            toDate: filterInput.toDate,
          },
        };
        const response = await getFilterIqamaList(data).unwrap();
        if (response) {
          setIqama(response.data.iqamaLists[0].data);
          dispatch(
            setFilterTotalRecords(
              response.data.iqamaLists[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setIqamaLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterPageNo, state.filterPageSize, query]);

  const handleReset = () => {
    setIqama(iqama);
    setFilterInput({
      status: "",
      sponsorName: "",
      professionOnIqama: "",
      fromDate: "",
      toDate: "",
      workPermitExpiry: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Employee Iqama List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Iqama Status
                    </h4>
                    <Form.Select
                      name="status"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          status: e.target.value,
                        })
                      }
                      value={filterInput.status}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Valid">Valid</option>
                      <option value="Expired">Expired</option>
                      <option value="Renewd">Renewd</option>
                      <option value="Handovered">Handovered</option>
                      <option value="Processing">Processing</option>
                      <option value="Fine Clearance Pending">
                        Fine Clearance Pending
                      </option>
                      <option value="Approvals Pending">
                        Approvals Pending
                      </option>
                      <option value="Handover Approvals Pending">
                        Handover Approvals Pending
                      </option>
                      <option value="Handover Approved">
                        Handover Approved
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Work Permit Expiry
                    </h4>
                    <Form.Control
                      type="text"
                      name="workPermitExpiry"
                      placeholder="Work Expiry Text"
                      onClick={() =>
                        setFilterInput({
                          ...filterInput,
                          workPermitExpiry: "",
                        })
                      }
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          workPermitExpiry: e.target.value,
                        })
                      }
                      value={filterInput.workPermitExpiry}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Work Permit Expiry
                    </h4>
                    <Form.Control
                      type="date"
                      name="workPermitExpiry"
                      placeholder="Work Permit Expiry"
                      onClick={() =>
                        setFilterInput({
                          ...filterInput,
                          workPermitExpiry: "",
                        })
                      }
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          workPermitExpiry: e.target.value,
                        })
                      }
                      value={filterInput.workPermitExpiry}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Sponsor Name
                    </h4>
                    <Form.Control
                      type="text"
                      name="sponsorName"
                      placeholder="Sponsor Name"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          sponsorName: e.target.value,
                        })
                      }
                      value={filterInput.sponsorName}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Profession on Iqama
                    </h4>
                    <Form.Control
                      type="text"
                      name="professionOnIqama"
                      placeholder="Profession on Iqama"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          professionOnIqama: e.target.value,
                        })
                      }
                      value={filterInput.professionOnIqama}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      placeholder="From date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          fromDate: e.target.value,
                        })
                      }
                      value={filterInput.fromDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="toDate"
                      placeholder="To date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          toDate: e.target.value,
                        })
                      }
                      value={filterInput.toDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IqamaFilterModal;
