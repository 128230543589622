import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { DashboardData } from "../../interfaces/dashboard";

interface Dashboard {
  data: DashboardData;
}

export const dashboardAPI = createApi({
  reducerPath: "/api/dashboard",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getDashboard: builder.query<
      Dashboard,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/super-admin-dashboard/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardAPI;
