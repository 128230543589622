import React from "react";

interface Props {
  children?: React.ReactNode;
}

const CommonBGLayout = ({ children }: Props) => {
  return <div className="common-bg-layout py-4">{children}</div>;
};

export default CommonBGLayout;
