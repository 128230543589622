import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Dropdown, Form, Pagination, Table } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowExportDropdown,
  setShowFilterDropdown,
  setLedgerLoading,
  setFilterDate,
} from "../../store/slices/pettyCashSlice";
import { LoaderBlue } from "../Lotties";
import LedgerFilterModal from "../Modals/ledger-filter-modal";
import { PettyCash } from "../../interfaces/petty-cash";
import { exportToCsv, exportToPDF } from "../../config";
// API imoprts
import { useGetFilterLedgerMutation } from "../../services/PettyCash";
import { useReactToPrint } from "react-to-print";

const PettyCashLedger = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.pettyCash);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const [filterSearch, setFilterSearch] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState<PettyCash[]>([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredData.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };

  useEffect(() => {
    setFilteredData(state.ledger);
  }, [state.ledger]);

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Date",
      "Transaction ID",
      "Type",
      "Method",
      "Debit (SAR)",
      "Credit (SAR)",
    ],
    tableData: paginatedData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Transaction Date": item.transactionDate
      ? new Date(item.transactionDate).toLocaleDateString()
      : "",
    "Transaction ID": item.transactionId,
    "Transaction Type": item.transactionType,
    "Transaction Method": item.transactionMethode,
    "Debit (SAR)":
      item.transactionType === "Debit" ? item.transactionAmount : "",
    "Credit (SAR)":
      item.transactionType === "Credit" ? item.transactionAmount : "",
  }));

  const totalbalance = state.totalCredit - state.totalDebit;
  // check if last month balance is negative
  const finalBalance =
    state.lastMonthBalance < 0
      ? totalbalance - Math.abs(state.lastMonthBalance)
      : totalbalance + state.lastMonthBalance;

  const handleReset = () => {
    setQuery("");
    setFilteredData(state.ledger);
    setFilterSearch(false);
    dispatch(setFilterDate(new Date().toLocaleDateString()));
  };

  const [getFilterLedger] = useGetFilterLedgerMutation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLedgerLoading(true));
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.showPettyCash || false,
        payload: {
          transactionId: query,
        },
      };
      const response = await getFilterLedger(data).unwrap();
      if (response) {
        setFilteredData(response.data.ledger);
      }
    } catch (e: any) {
    } finally {
      dispatch(setLedgerLoading(false));
    }
  };
  return (
    <div className="user-list mt-2 section px-4 py-4">
      <LedgerFilterModal
        show={showFilterModal}
        setShow={setShowFilterModal}
        setLedger={setFilteredData}
        setFilterSearch={setFilterSearch}
        ledger={state.ledger}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Petty Cash Ledger</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="w-100" controlId="formBasicEmail">
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <Form.Control
                        type="text"
                        name="search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="custom-input"
                        placeholder="Search by transaction id"
                      />
                      <button type="submit" className="btn p-0 border-0">
                        <img src={Common.Search} alt="search" />
                      </button>
                    </Form.Group>
                  </Form.Group>
                </Form>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/petty-cash/debit")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  Debit
                </button>
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/petty-cash/credit")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  Credit
                </button>
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Filter{" "}
                </button>
                <Dropdown
                  show={state.showExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(setShowFilterDropdown(false));
                      dispatch(
                        setShowExportDropdown(!state.showExportDropdown),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "petty-cash-ledger");
                        dispatch(setShowExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="mt-5">
            <div className="d-flex justify-content-between mb-4">
              <div className="">
                <h4 className="sub-heading mb-0">Last month balance:</h4>
                <h4 className="sub-heading mt-0" style={{ fontSize: "20px" }}>
                  {state.lastMonthBalance}
                </h4>
              </div>
              <div className="">
                <h4 className="sub-heading mb-2">Current balance:</h4>
                <h4
                  className="sub-heading mt-0"
                  style={{ fontSize: "60px", lineHeight: "46px" }}
                >
                  {finalBalance}
                </h4>
              </div>
            </div>
            {state.ledgerLoading ? (
              <LoaderBlue height={70} width={70} />
            ) : profileData.permissions.showPettyCash ? (
              <>
                {tableData.tableData.length > 0 ? (
                  <div className="p-3" ref={tableRef}>
                    {print && (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <img src={Common.Vision} width="110px" alt="logo" />
                          </div>
                          <div className="letter-logo text-center gap-2">
                            <img src={Common.LogoSVG} width="80px" alt="logo" />
                            <div className="mt-1">
                              <h3
                                className="logo-text mb-0"
                                style={{
                                  color: "#202020",
                                  fontSize: "10px",
                                  fontWeight: "700",
                                }}
                              >
                                Senyar Arabian Trading Co. LTD
                              </h3>
                              <h3
                                className="logo-text logo-arabic mb-0"
                                style={{
                                  color: "#202020",
                                  fontSize: "13px",
                                  fontWeight: "700",
                                }}
                              >
                                شركة سنيار العربية التجارية المحدودة
                              </h3>
                            </div>
                          </div>
                        </div>
                        <h1 className="sub-heading text-center mt-3">
                          Petty Cash Ledger
                        </h1>
                      </>
                    )}
                    <Table
                      striped
                      bordered
                      responsive
                      className={
                        print ? "text-center print-table" : "text-center"
                      }
                    >
                      <thead>
                        <tr className="border-0">
                          {tableData.tableHeading.map((item, index) => (
                            <th className="border-0" key={index}>
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.tableData.map((item, index) => (
                          <tr
                            key={index}
                            style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                          >
                            <td>
                              {" "}
                              {filteredData.length > 0
                                ? (currentPage - 1) * pageSize + index + 1
                                : index + 1}
                            </td>
                            <td
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/petty-cash/detail/${item.transactionId}`,
                                  { state: { data: item } },
                                )
                              }
                            >
                              {item.transactionDate
                                ? new Date(
                                    item.transactionDate,
                                  ).toLocaleDateString()
                                : ""}
                            </td>
                            <td
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/petty-cash/detail/${item.transactionId}`,
                                  { state: { data: item } },
                                )
                              }
                            >
                              {item.transactionId}
                            </td>
                            <td>{item.expenseType}</td>
                            <td>{item.transactionMethode}</td>
                            <td>
                              {item.transactionType === "Debit"
                                ? item.transactionAmount
                                : ""}
                            </td>
                            <td>
                              {item.transactionType === "Credit"
                                ? item.transactionAmount
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5} className="text-end">
                            Total
                          </td>
                          <td>{state.totalDebit}</td>
                          <td>{state.totalCredit}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <h1 className="heading">No ledger data found</h1>
                  </div>
                )}
              </>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">Unauthorized Access</h1>
              </div>
            )}
          </div>
        </Col>
        {paginatedData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default PettyCashLedger;
