import { createSlice } from "@reduxjs/toolkit";

interface Index {
  activeTab: string;
  tabList: Array<string>;
  countryCode: string;
  showDropDown: boolean;
  showPasswordUpdateModal: boolean;
  passwordError: string;
  emailError: string;
  visiblePasswords: boolean;
  visibleRepeatPasswords: boolean;
  visibleOldPasswords: boolean;
  oldPasswordError: string;
  loading: boolean;
  country: string;
  phone: string;
  responseError: string;
  profileData: {
    _id: string;
    employeeId: string;
    img: string;
    firstName: string;
    lastName: string;
    email: string;
    designation: string;
    department: string;
    dateOfBirth: string;
    country: string;
    phone: string;
    permissions: {
      roleName: string;
      addUser: boolean;
      updateUser: boolean;
      deleteUser: boolean;
      showUser: boolean;
      addEmployee: boolean;
      updateEmployee: boolean;
      deleteEmployee: boolean;
      showEmployee: boolean;
      addProject: boolean;
      updateProject: boolean;
      deleteProject: boolean;
      showProject: boolean;
      addAttendance: boolean;
      updateAttendance: boolean;
      deleteAttendance: boolean;
      showAttendance: boolean;
      addLegal: boolean;
      updateLegal: boolean;
      deleteLegal: boolean;
      showLegal: boolean;
      addPettyCash: boolean;
      updatePettyCash: boolean;
      deletePettyCash: boolean;
      showPettyCash: boolean;
      isUser: boolean;
    };
  };
  roles: {
    _id: string;
    roleName: string;
    roleUsers: number;
    addUser: number;
    updateUser: number;
    deleteUser: number;
    showUser: number;
    addEmployee: number;
    updateEmployee: number;
    deleteEmployee: number;
    showEmployee: number;
    addProject: number;
    updateProject: number;
    deleteProject: number;
    showProject: number;
    addAttendance: number;
    updateAttendance: number;
    deleteAttendance: number;
    showAttendance: number;
    addLegal: number;
    updateLegal: number;
    deleteLegal: number;
    showLegal: number;
    addPettyCash: number;
    updatePettyCash: number;
    deletePettyCash: number;
    showPettyCash: number;
  }[];
  allUsers: {
    _id: string;
    employeeId: number;
    firstName: string;
    lastName: string;
    role: string;
    email: string;
  }[];
  allDesignations: {
    _id: string;
    roleName: string;
  }[];
}

const initialState: Index = {
  loading: false,
  activeTab: "User Profile",
  tabList: ["User Profile", "Users", "Designations"],
  countryCode: "+966",
  showDropDown: false,
  showPasswordUpdateModal: false,
  passwordError: "",
  visiblePasswords: false,
  visibleRepeatPasswords: false,
  oldPasswordError: "",
  visibleOldPasswords: false,
  emailError: "",
  country: "Saudi Arabia",
  phone: "",
  profileData: {
    _id: "",
    employeeId: "",
    img: "",
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    department: "",
    dateOfBirth: "",
    country: "",
    phone: "",
    permissions: {
      roleName: "",
      addUser: false,
      updateUser: false,
      deleteUser: false,
      showUser: false,
      addEmployee: false,
      updateEmployee: false,
      deleteEmployee: false,
      showEmployee: false,
      addProject: false,
      updateProject: false,
      deleteProject: false,
      showProject: false,
      addAttendance: false,
      updateAttendance: false,
      deleteAttendance: false,
      showAttendance: false,
      addLegal: false,
      updateLegal: false,
      deleteLegal: false,
      showLegal: false,
      addPettyCash: false,
      updatePettyCash: false,
      deletePettyCash: false,
      showPettyCash: false,
      isUser: false,
    },
  },
  roles: [],
  responseError: "",
  allUsers: [],
  allDesignations: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setShowDropDown: (state, action) => {
      state.showDropDown = action.payload;
    },
    setShowPasswordUpdateModal: (state, action) => {
      state.showPasswordUpdateModal = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    setOldPasswordError: (state, action) => {
      state.oldPasswordError = action.payload;
    },
    setVisiblePassword: (state, action) => {
      state.visiblePasswords = action.payload;
    },
    setVisibleRepeatPassword: (state, action) => {
      state.visibleRepeatPasswords = action.payload;
    },
    setVisibleOldPassword: (state, action) => {
      state.visibleOldPasswords = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setResponseError: (state, action) => {
      state.responseError = action.payload;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setAllDesignations: (state, action) => {
      state.allDesignations = action.payload;
    },
  },
});

export const {
  setActiveTab,
  setCountryCode,
  setShowDropDown,
  setShowPasswordUpdateModal,
  setVisiblePassword,
  setVisibleRepeatPassword,
  setPasswordError,
  setLoading,
  setOldPasswordError,
  setVisibleOldPassword,
  setEmailError,
  setCountry,
  setPhone,
  setProfileData,
  setRoles,
  setResponseError,
  setAllUsers,
  setAllDesignations,
} = settingsSlice.actions;
export default settingsSlice.reducer;
