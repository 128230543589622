import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Letters
import {
  useGetApprovalsQuery,
  useGetNotificationsQuery,
} from "../../services/Notifications";
import {
  setApprovals,
  setApprovalsError,
  setApprovalsLoading,
  setNotificationError,
  setNotifications,
  setNotificationsLoading,
  setTotalApprovals,
  setTotalNotifications,
} from "../../store/slices/notificationsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const notificationChange = useAppSelector(
    (state) => state.common.notificationChange,
  );
  const approvalChange = useAppSelector((state) => state.common.approvalChange);
  // States
  const state = useAppSelector((state) => state.notification);

  // **************
  // **************
  // Get All Notifications
  // **************
  // **************
  const {
    data: allNotifications,
    isLoading: notificationsLoading,
    isError: notificationsError,
    refetch: notificationsRefetch,
  } = useGetNotificationsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: 1,
    pageSize: state.notificationPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (notificationsLoading) {
      dispatch(setNotificationsLoading(true));
    }
    if (notificationsError) {
      dispatch(setNotificationError("Something went wrong"));
      dispatch(setNotificationsLoading(false));
    }
    if (allNotifications) {
      dispatch(
        setNotifications(allNotifications.data.notifications[0].data || []),
      );
      dispatch(
        setTotalNotifications(
          allNotifications.data.notifications[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setNotificationsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allNotifications,
    profileData,
    state.notificationPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    notificationsRefetch();
  }, [notificationChange]);
  // **************
  // **************
  // Get All Approvals
  // **************
  // **************
  const {
    data: allApprovals,
    isLoading: approvalsLoading,
    isError: approvalsError,
    refetch: approvalsRefetch,
  } = useGetApprovalsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: 1,
    pageSize: state.approvalPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (approvalsLoading) {
      dispatch(setApprovalsLoading(true));
    }
    if (approvalsError) {
      dispatch(setApprovalsError("Something went wrong"));
      dispatch(setApprovalsLoading(false));
    }
    if (allApprovals) {
      dispatch(setApprovals(allApprovals.data.approvals[0].data || []));
      dispatch(
        setTotalApprovals(
          allApprovals.data.approvals[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setApprovalsLoading(false));
    }
  }, [accessToken, userID, allApprovals, profileData, state.approvalPageSize]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    approvalsRefetch();
  }, [approvalChange]);
  return <></>;
};
export default Content;
