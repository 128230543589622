import { createSlice } from "@reduxjs/toolkit";
import { DashboardData } from "../../interfaces/dashboard";

interface Index {
  dashboardData: DashboardData;
  dashboardDataLoading: boolean;
  dashboardDataError: string;
}

const initialState: Index = {
  dashboardData: {
    attendanceData: {
      totalEmployees: 0,
      presentEmployees: 0,
      absentEmployees: 0,
      leaveEmployees: 0,
    },
    projectsData: [],
    lastWeekPresentRatio: [],
    lastWeekAbsentRatio: [],
    currentYearEmployment: [],
    currentYearFinalExit: [],
    currentYearAbsconding: [],
  },
  dashboardDataLoading: false,
  dashboardDataError: "",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setDashboardDataLoading: (state, action) => {
      state.dashboardDataLoading = action.payload;
    },
    setDashboardDataError: (state, action) => {
      state.dashboardDataError = action.payload;
    },
  },
});

export const {
  setDashboardData,
  setDashboardDataLoading,
  setDashboardDataError,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
