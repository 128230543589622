import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { Project } from "../../interfaces/project";
// Redux
import { useAppSelector } from "../../store/hooks";
import Invoice from "../Letters/invoice";

const ProjectDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data: Project = location.state.data;
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const selectedEmployees = employees.filter((employee) => {
    return data.projectEmployees.includes(employee.empId);
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = selectedEmployees.slice(startIndex, endIndex);

  const totalPages = Math.ceil(selectedEmployees.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Place",
      "Status",
    ],
    tableData: paginatedData,
  };

  let count = 1;
  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Project Detail</h1>
            <div className="mt-4">
              <div className="d-flex align-items-center gap-3 justify-content-end">
                <h4
                  className="sub-heading textBlack mb-0"
                  style={{ fontSize: "20px" }}
                >
                  Project Status:
                </h4>
                <div className="d-flex align-items-center gap-2">
                  <img src={Common.Dot} width="10px" height="10px" alt="dot" />
                  <p className="mb-0">{data.projectStatus}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.projectId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.projectName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project Location
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.projectLocation}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project Supervisor
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.projectSupervisor}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Client Id.
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.clientId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Client Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.clientName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.companyName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.contactPersonName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person Designation
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.contactPersonDesignation}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact Person No.
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.clientDetails.phone}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project Status
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.projectStatus}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Agreement From
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.agreementFrom
                    ? new Date(data.agreementFrom).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Agreement To
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.agreementTo
                    ? new Date(data.agreementTo).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <h4 className="sub-heading textBlack">Categories</h4>
          {data.employeeRequired ? (
            <>
              <Table striped bordered responsive className=" text-center">
                <thead>
                  <tr className="border-0">
                    <th className="border-0">Sr. No.</th>
                    <th className="border-0">Category</th>
                    <th className="border-0">Nationality</th>
                    <th className="border-0">Accomodation</th>
                    <th className="border-0">Transportation</th>
                    <th className="border-0">Food</th>
                    <th className="border-0">Over Time</th>
                    <th className="border-0">Over Time Price</th>
                    <th className="border-0">Holidays</th>
                    <th className="border-0">Duration</th>
                    <th className="border-0">Working Hours</th>
                    <th className="border-0">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {data.employeeRequired.map((item, index) => (
                    <tr
                      key={index}
                      style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.category}</td>
                      <td>{item.nationality}</td>
                      <td>{item.accomodation}</td>
                      <td>{item.transportation}</td>
                      <td>{item.food}</td>
                      <td>{item.overTime}</td>
                      <td>{item.overTimePrice}</td>
                      <td>{item.holidays}</td>
                      <td>{item.duration}</td>
                      <td>{item.workingHours}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <div className="text-center mt-5">
              <h4 className="sub-heading textBlack">No Categories Added</h4>
            </div>
          )}
        </Col>
        <Col sm="12">
          <div className="selected-employees mt-4">
            <h4 className="sub-heading">Selected Employees</h4>
            {tableData.tableData.length > 0 ? (
              <Table striped bordered responsive className=" text-center">
                <thead>
                  <tr className="border-0">
                    {tableData.tableHeading.map((item, index) => (
                      <th className="border-0" key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.tableData.map((item, index) => (
                    <tr
                      key={index}
                      style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                    >
                      <td>{count++}</td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/employees/employee-detail/${item.empId}`, {
                            state: { empId: item.empId },
                          })
                        }
                      >
                        {item.empId}
                      </td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/employees/employee-detail/${item.empId}`, {
                            state: { empId: item.empId },
                          })
                        }
                      >
                        {item.empName}
                      </td>
                      <td>{item.empPhone}</td>
                      <td>{item.empIqamaNo}</td>
                      <td>{item.empPlace}</td>
                      <td>{item.empStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">No Employees Selected</h1>
              </div>
            )}
          </div>
        </Col>
        {paginatedData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn secondary-button w-25"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              className="btn primary-button w-25"
              onClick={() =>
                navigate(`/projects/edit-project/${data.projectId}`, {
                  state: { data: data },
                })
              }
            >
              Edit Detail
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ProjectDetail;
