import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAddLeaveRequestLoading } from "../../../store/slices/lettersSlice";
import { setLeaveRequestChange } from "../../../store/slices/commonSlice";
// API imports
import { useEditLeaveRequestMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";

const EditLeaveRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const leaveData = location.state.data;
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState(leaveData.empId || "");
  const [lastSalaryStatus, setLastSalaryStatus] = React.useState(
    leaveData.lastSalaryStatus || "",
  );
  const [leaveType, setLeaveType] = React.useState(
    leaveData.leaveCategory || "",
  );
  const [leaveFrom, setLeaveFrom] = React.useState(leaveData.leaveFrom || "");
  const [leaveTo, setLeaveTo] = React.useState(leaveData.leaveTo || "");
  const [showGuaranters, setShowGuaranters] = React.useState(false); // [false, false
  const g1 = leaveData.guaranters
    ? leaveData.guaranters[0]?.empId.toString()
    : "";
  const g2 = leaveData.guaranters
    ? leaveData.guaranters[1]
      ? leaveData.guaranters[1]?.empId.toString()
      : ""
    : "";
  const [guaranter1, setGuaranter1] = React.useState(g1);
  const [guaranter2, setGuaranter2] = React.useState(g2);
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const leaveRequestChange = useAppSelector(
    (state) => state.common.leaveRequestChange,
  );
  // API Calls
  const [editLeaveRequest] = useEditLeaveRequestMutation();

  const handleSubmit = async () => {
    dispatch(setAddLeaveRequestLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          empId: empID,
          leaveId: leaveData._id || "",
          leaveCategory: leaveType,
          leaveFrom: leaveFrom,
          leaveTo: leaveTo,
          lastSalaryStatus: lastSalaryStatus,
          link: `/hr/leave-requests`,
          guaranters: showGuaranters
            ? guaranter1 && !guaranter2
              ? [parseInt(guaranter1)]
              : guaranter2 && !guaranter1
                ? [parseInt(guaranter2)]
                : [parseInt(guaranter1), parseInt(guaranter2)]
            : [],
        },
      };
      await editLeaveRequest(data).unwrap();
      dispatch(setLeaveRequestChange(!leaveRequestChange));
      navigate("/hr/leave-requests");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddLeaveRequestLoading(false));
    }
  };
  const options = employees.map((employee) => ({
    name: employee.empId, // Use the actual property name for the employee name
    value: employee.empId,
  }));
  React.useEffect(() => {
    setShowGuaranters(leaveData.guaranters.length > 0 ? true : false);
  }, []);
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Edit Employee Leave Request</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-4">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Emp Id
            </h4>
            <h5
              className="sub-heading normal-font-weight"
              style={{ fontSize: "16px" }}
            >
              {leaveData.empId}
            </h5>
          </Form.Group>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-4">
            <h4 className="sub-heading mb-4">Financial Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Last Salary Status
                  </h4>
                  <Form.Select
                    name="leaveType"
                    value={lastSalaryStatus}
                    onChange={(e) => setLastSalaryStatus(e.target.value)}
                    className="custom-input border-fill"
                  >
                    <option value="">Select Last Salary Status</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-4">
            <h4 className="sub-heading mb-4">Leave Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave Type
                  </h4>
                  <Form.Select
                    name="leaveType"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                    className="custom-input border-fill"
                  >
                    <option value="">Select Leave Type</option>
                    <option value="Emergency">Emergency</option>
                    <option value="Annual">Annual</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave From
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveFrom"
                    placeholder="Enter passport expiry from"
                    onChange={(e) => setLeaveFrom(e.target.value)}
                    value={leaveFrom}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave To
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveTo"
                    placeholder="Enter passport expiry to"
                    onChange={(e) => setLeaveTo(e.target.value)}
                    value={leaveTo}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="emp-detail-print mt-5 mb-5">
                  <button
                    className="btn primary-button w-25"
                    onClick={() => setShowGuaranters(!showGuaranters)}
                  >
                    {showGuaranters ? "Remove Guaranters" : "Add Guaranters"}
                  </button>
                </div>
              </Col>
            </Row>
            {showGuaranters && (
              <Row>
                <Col sm="4">
                  <div className="search-emp mb-4">
                    <h4 className="sub-heading normal-font-weight textBlack">
                      Guarantor One:
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        value={guaranter1}
                        onChange={(e: any) => setGuaranter1(e)}
                        search={true}
                        placeholder="Select Employee by Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="search-emp mb-4">
                    <h4 className="sub-heading normal-font-weight textBlack">
                      Guarantor Two:
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        value={guaranter2}
                        onChange={(e: any) => setGuaranter2(e)}
                        search={true}
                        placeholder="Select Employee by Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addLeaveRequestLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default EditLeaveRequest;
