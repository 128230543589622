import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { Iqama, Legal, IqamaDetailProps } from "../../interfaces/iqama";

interface IqamaList {
  data: {
    iqamaLists: {
      data: Iqama[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface LegalList {
  data: {
    legal: {
      data: Legal[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export interface IqamaDetail {
  data: {
    employees: IqamaDetailProps[];
  };
}

export const iqamaAPI = createApi({
  reducerPath: "/api/iqama",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getIqamaList: builder.query<
      IqamaList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/iqama-lists/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    requestIqamaRenew: builder.mutation({
      query: (data) => ({
        url: `/iqama-renew-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getIqamaDetail: builder.mutation<
      IqamaDetail,
      { userID: string; accessToken: string; payload: any; permission: boolean }
    >({
      query: (data) => ({
        url: `/get-iqama-details/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editIqama: builder.mutation({
      query: (data) => ({
        url: `/edit-iqama-details/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addIqamaProcess: builder.mutation({
      query: (data) => ({
        url: `/add-iqama-processes/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editIqamaProcess: builder.mutation({
      query: (data) => ({
        url: `/update-iqama-processes/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editIqamaProcessStatus: builder.mutation({
      query: (data) => ({
        url: `/close-iqama-processes/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    iqamaHandoverRequest: builder.mutation({
      query: (data) => ({
        url: `/iqama-handover-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    iqamaHandover: builder.mutation({
      query: (data) => ({
        url: `/iqama-handover/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterIqamaList: builder.mutation<
      IqamaList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/iqama-filtered-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Legal
    getLegalList: builder.query<
      LegalList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-legal/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getFilterLegalList: builder.mutation<
      LegalList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-legal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addLegal: builder.mutation({
      query: (data) => ({
        url: `/add-legal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editLegal: builder.mutation({
      query: (data) => ({
        url: `/edit-legal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteLegal: builder.mutation({
      query: (data) => ({
        url: `/delete-legal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetIqamaListQuery,
  useIqamaHandoverRequestMutation,
  useEditIqamaMutation,
  useRequestIqamaRenewMutation,
  useIqamaHandoverMutation,
  useGetFilterIqamaListMutation,
  useGetLegalListQuery,
  useGetFilterLegalListMutation,
  useAddLegalMutation,
  useEditLegalMutation,
  useDeleteLegalMutation,
  useAddIqamaProcessMutation,
  useEditIqamaProcessMutation,
  useEditIqamaProcessStatusMutation,
  useGetIqamaDetailMutation,
} = iqamaAPI;
