import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowNewJoinerExportDropdown,
  setNewJoinerPageNo,
  setNewJoinerPageSize,
  setFilterNewJoinerPageNo,
  setFilterNewJoinerPageSize,
} from "../../store/slices/lettersSlice";
import { LoaderBlue } from "../Lotties";
import { setProjectChange } from "../../store/slices/commonSlice";
// API imports
import { useDeleteTransferRequestMutation } from "../../services/Letters";
import { TransferEmployee } from "../../interfaces/letters";
import { exportToCsv, exportToPDF } from "../../config";
import TransferRequestFilterModal, {
  FormProps,
} from "../Modals/transfer-request-filter-model";
import { useReactToPrint } from "react-to-print";
import Content from "./get-transfer-new-joiners-request";
import SelectSearch from "react-select-search";

const NewJoinersRequests = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const projectChange = useAppSelector((state) => state.common.projectChange);

  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  // Options
  const options = employees.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId,
  }));
  // Pagination && Filter
  const [filterInput, setFilterInput] = useState<FormProps>({
    projectId: "",
    projectSupervisor: "",
    newProjectId: "",
    transferStatus: "",
    transferCategory: "",
  });
  const [filterSearch, setFilterSearch] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<TransferEmployee[]>([]);
  const totalPages = Math.ceil(
    state.newJoinerTotalPages / state.newJoinerPageSize,
  );
  const totalCount = filterSearch
    ? state.filterNewJoinerTotalPages
    : state.newJoinerTotalPages;

  const filterTotalPages = Math.ceil(
    state.filterNewJoinerTotalPages / state.filterNewJoinerPageSize,
  );

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.newJoinerPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  const filterStartPage =
    Math.floor((state.filterNewJoinerPageNo - 1) / showPages) * showPages;
  const filterEndPage = filterStartPage + showPages;
  const filterPages = [...Array(filterTotalPages).keys()].slice(
    filterStartPage,
    filterEndPage,
  );
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.newJoinerPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setNewJoinerPageNo(state.newJoinerPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.newJoinerPageNo <= 5) {
      return;
    }
    dispatch(setNewJoinerPageNo(state.newJoinerPageNo - showPages));
  };

  const handleFilterGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.filterNewJoinerPageNo > filterTotalPages - showPages) {
      return;
    }
    dispatch(setFilterNewJoinerPageNo(state.filterNewJoinerPageNo + showPages));
  };

  const handleFilterGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.filterNewJoinerPageNo <= 5) {
      return;
    }
    dispatch(setFilterNewJoinerPageNo(state.filterNewJoinerPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setNewJoinerPageNo(page));
  };

  const handleFilterPageChange = (page: any) => {
    dispatch(setFilterNewJoinerPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    if (filterSearch) {
      dispatch(setFilterNewJoinerPageSize(selectedPageSize));
    } else {
      dispatch(setNewJoinerPageSize(selectedPageSize));
    }
  };
  useEffect(() => {
    setFilteredData(state.newJoiners);
  }, [state.newJoiners]);

  // Delete Behaviour Statement

  const [deleteTransferRequest] = useDeleteTransferRequestMutation();

  const handleDelete = async (transferId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [transferId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.deleteEmployee || false,
        payload: {
          transferId: transferId,
        },
      };
      await deleteTransferRequest(data).unwrap();
      dispatch(setProjectChange(!projectChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [transferId]: false }));
    }
  };

  // Table Data
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Employee ID",
      "Employee Name",
      "Project Id.",
      "Project Name",
      "Supervisor Name",
      "Transfer Category",
      "Status",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Employee ID": item.empId,
    "Employee Name": item.empName,
    "Project Id.": item.newProjectId,
    "Project Name": item.projectName,
    "Supervisor Name": item.projectSupervisor,
    "Transfer Category": item.transferCategory,
    Status: item.status,
  }));

  const handleReset = () => {
    setQuery("");
    setSearch("");
    setFilteredData(state.transferEmployee);
    setFilterSearch(false);
    setFilterInput({
      projectId: "",
      projectSupervisor: "",
      newProjectId: "",
      transferStatus: "",
      transferCategory: "",
    });
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <TransferRequestFilterModal
        setFilterSearch={setFilterSearch}
        transferRequests={state.transferEmployee}
        query={query}
        filterSearch={filterSearch}
        setTransferRequests={setFilteredData}
        show={showFilterModal}
        setShow={setShowFilterModal}
        filterInput={filterInput}
        newJoiner={true}
        setFilterInput={setFilterInput}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        setQuery(value);
                        setFilterSearch(true);
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/projects/transfer-employee")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  New
                </button>
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Filter{" "}
                </button>
                <Dropdown
                  show={state.showNewJoinerExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowNewJoinerExportDropdown(
                          !state.showNewJoinerExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showNewJoinerExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "new-joiners-requests");
                        dispatch(setShowNewJoinerExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowNewJoinerExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.behaviourStatementsLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Project New Joiners List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? filterSearch
                                ? (state.filterNewJoinerPageNo - 1) *
                                    state.filterNewJoinerPageSize +
                                  index +
                                  1
                                : (state.newJoinerPageNo - 1) *
                                    state.newJoinerPageSize +
                                  index +
                                  1
                              : index + 1}
                          </td>

                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/projects/newjoiner-request-detail/${item.empId}`,
                                { state: { data: item } },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/projects/newjoiner-request-detail/${item.empId}`,
                                { state: { data: item } },
                              )
                            }
                          >
                            {item.empName}
                          </td>
                          <td>{item.newProjectId}</td>
                          <td>{item.projectName}</td>
                          <td>{item.projectSupervisor}</td>
                          <td>{item.transferCategory}</td>
                          <td>{item.status}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/projects/newjoiner-request-detail/${item.empId}`,
                                    { state: { data: item } },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              {item.status === "Pending" ||
                              item.status === "Rejected" ? (
                                <>
                                  {deleteLoadingMap[item._id] ? (
                                    <LoaderBlue height={20} width={20} />
                                  ) : (
                                    <button
                                      className="btn p-0 border-0"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <img
                                        src={SettingsIcons.Delete}
                                        width="24px"
                                        height="24px"
                                        alt="edit-user"
                                      />
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No new joiners requests found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={
                    filterSearch
                      ? state.filterNewJoinerPageSize.toString()
                      : state.newJoinerPageSize.toString()
                  }
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterGoToPreviousPages()
                        : handleGoToPreviousPages()
                    }
                  >
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterNewJoinerPageNo > 1
                              ? state.filterNewJoinerPageNo - 1
                              : 1,
                          )
                        : handlePageChange(
                            state.newJoinerPageNo > 1
                              ? state.newJoinerPageNo - 1
                              : 1,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  {filterSearch ? (
                    <>
                      {/* render filter pages here */}
                      {filterPages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handleFilterPageChange(page + 1)}
                          className={
                            page + 1 === state.filterNewJoinerPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* render pages here */}
                      {pages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(page + 1)}
                          className={
                            page + 1 === state.newJoinerPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  )}
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterNewJoinerPageNo < filterTotalPages
                              ? state.filterNewJoinerPageNo + 1
                              : filterTotalPages,
                          )
                        : handlePageChange(
                            state.newJoinerPageNo < totalPages
                              ? state.newJoinerPageNo + 1
                              : totalPages,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      filterSearch
                        ? handleFilterGoToNextPages()
                        : handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default NewJoinersRequests;
