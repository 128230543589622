import React, { useRef, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import InvoiceFormat from "./invoice-format";

interface Props {
  removeLetterHead: boolean;
  payload: {
    payrollId: string;
    payrollDate: string;
    empId: number;
    empName: string;
    empPhone: string;
    status: string;
    grossSalary: number;
    totalDeductions: number;
    netSalary: number;
    workedSalary: number;
    earnings: {
      title: string;
      description: string;
      type: string;
      hours: number;
      rate: number;
      amount: number;
    }[];
    deductions: {
      title: string;
      description: string;
      amount: number;
    }[];
    salaryIds: string[];
    expenseIds: string[];
  };
}

const EmployeePayslip = ({ removeLetterHead, payload }: Props) => {
  const offerLetterRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => offerLetterRef.current,
  });

  useEffect(() => {
    handlePrint();
  });

  return (
    <div
      className="letter-container payslip position-relative"
      ref={offerLetterRef}
    >
      {removeLetterHead ? (
        <div className="px-4">
          <h4
            className="heading text-center text-uppercase mt-4"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            Payslip
          </h4>
          <div className="d-flex align-items-center justify-content-between mb-2 position-relative mt-4">
            <div className="d-flex align-items-center gap-4">
              <h4
                className="sub-heading text-black mb-0"
                style={{ fontSize: "14px" }}
              >
                Status:
              </h4>
              <h4
                className="sub-heading text-black mb-0"
                style={{ fontSize: "14px" }}
              >
                {payload.status}
              </h4>
            </div>
            <div>
              <div className="table-container-letter">
                <div className="table-content-letter">
                  <Table
                    responsive
                    borderless
                    className="text-center mb-0 emp-detail-table"
                  >
                    <tbody>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Payslip#:</td>
                        <td>{payload.payrollId}</td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Payslip Date:</td>
                        <td>
                          {" "}
                          {new Date(payload.payrollDate).toDateString()}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Employee Information:
                      </h4>
                    </td>
                  </tr>

                  <tr style={{ gridTemplateColumns: " 2fr 4fr" }}>
                    <td className="text-center">Emp Id</td>
                    <td className="text-center justify-content-end">
                      {payload.empId}
                    </td>
                  </tr>
                  <tr style={{ gridTemplateColumns: "2fr 4fr" }}>
                    <td className="text-center">Emp Name</td>
                    <td className="text-center justify-content-end">
                      {payload.empName}
                    </td>
                  </tr>
                  <tr style={{ gridTemplateColumns: "2fr 4fr" }}>
                    <td className="text-center">Emp Phone</td>
                    <td className="text-center justify-content-end">
                      {payload.empPhone}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Earnings:
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 2fr 5fr 2fr 2fr 2fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">#</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Title</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Description</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Hours</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Rate</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Total Amount</span>
                    </td>
                  </tr>
                  {payload.earnings.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 2fr 5fr 2fr 2fr 2fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.title}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.description}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.hours}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.rate.toFixed(2)} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount.toFixed(2)} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Gross Salary:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.grossSalary.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Deductions:
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 2fr 5fr 2fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">#</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Title</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Description</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Total Amount</span>
                    </td>
                  </tr>
                  {payload.deductions.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 2fr 5fr 2fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.title}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.description}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount.toFixed(2)} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Total Deductions:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.totalDeductions.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Net Salary:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.netSalary.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      ) : (
        <InvoiceFormat type="payslip">
          <div className="d-flex align-items-center justify-content-between mb-2 position-relative mt-4">
            <div className="d-flex align-items-center gap-4">
              <h4
                className="sub-heading text-black mb-0"
                style={{ fontSize: "14px" }}
              >
                Status:
              </h4>
              <h4
                className="sub-heading text-black mb-0"
                style={{ fontSize: "14px" }}
              >
                {payload.status}
              </h4>
            </div>
            <div>
              <div className="table-container-letter">
                <div className="table-content-letter">
                  <Table
                    responsive
                    borderless
                    className="text-center mb-0 emp-detail-table"
                  >
                    <tbody>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Payslip#:</td>
                        <td>{payload.payrollId}</td>
                      </tr>
                      <tr style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <td>Payslip Date:</td>
                        <td>
                          {" "}
                          {new Date(payload.payrollDate).toDateString()}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Employee Information:
                      </h4>
                    </td>
                  </tr>

                  <tr style={{ gridTemplateColumns: " 2fr 4fr" }}>
                    <td className="text-center">Emp Id</td>
                    <td className="text-center justify-content-end">
                      {payload.empId}
                    </td>
                  </tr>
                  <tr style={{ gridTemplateColumns: "2fr 4fr" }}>
                    <td className="text-center">Emp Name</td>
                    <td className="text-center justify-content-end">
                      {payload.empName}
                    </td>
                  </tr>
                  <tr style={{ gridTemplateColumns: "2fr 4fr" }}>
                    <td className="text-center">Emp Phone</td>
                    <td className="text-center justify-content-end">
                      {payload.empPhone}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Earnings:
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 2fr 5fr 2fr 2fr 2fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">#</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Title</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Description</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Hours</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Rate</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Total Amount</span>
                    </td>
                  </tr>
                  {payload.earnings.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 2fr 5fr 2fr 2fr 2fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.title}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.description}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.hours}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.rate.toFixed(2)} SAR
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount.toFixed(2)} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Gross Salary:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.grossSalary.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3 mt-4">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-between ms-0"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Deductions:
                      </h4>
                    </td>
                  </tr>
                  <tr
                    style={{
                      gridTemplateColumns: "1fr 2fr 5fr 2fr",
                    }}
                  >
                    <td className="text-center">
                      <span className="w-100">#</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Title</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Description</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span className="w-100">Total Amount</span>
                    </td>
                  </tr>
                  {payload.deductions.map((category, index) => (
                    <tr
                      style={{
                        gridTemplateColumns: "1fr 2fr 5fr 2fr",
                      }}
                      key={index}
                    >
                      <td className="text-center justify-content-center">
                        {index + 1}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.title}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.description}
                      </td>
                      <td className="text-center justify-content-center">
                        {category.amount.toFixed(2)} SAR
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Total Deductions:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.totalDeductions.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="table-container-letter invoice-container-letter w-100 mb-3">
            <div className="table-content-letter">
              <Table
                responsive
                borderless
                className="text-center mb-0 emp-detail-table"
              >
                <tbody>
                  <tr style={{ gridTemplateColumns: "none" }}>
                    <td
                      className="d-flex align-items-center justify-content-end ms-0 gap-5"
                      style={{ background: "#e5e5e5" }}
                    >
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        Net Salary:
                      </h4>
                      <h4
                        className="mb-0"
                        style={{ fontSize: "12px", padding: "1px" }}
                      >
                        {payload.netSalary.toFixed(2)} SAR
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </InvoiceFormat>
      )}
    </div>
  );
};
export default EmployeePayslip;
