import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import * as Yup from "yup";
// API
import { useEditUserMutation } from "../../services/Settings";
import { setSettingsChange } from "../../store/slices/commonSlice";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";

interface FormProps {
  email: string;
  designation: string;
  department: string;
}

const EditUser = () => {
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);
  const navigate = useNavigate();
  const location = useLocation();

  const [editUser, { isLoading: editUserLoading }] = useEditUserMutation();
  //   Initial Values
  const initialValues: FormProps = {
    email: location?.state?.data.email,
    designation: location?.state?.data.role,
    department: location?.state?.data.department,
  };
  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    designation: Yup.string().required("Select Designation"),
    department: Yup.string().required("Select Department"),
  });
  const handleSubmit = async (values: FormProps) => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      payload: {
        userId: location?.state?.data._id,
        email: values.email,
        role: values.designation,
        department: values.department,
        myRole: state.profileData.designation || "",
        link: `/settings/view-user/${location?.state?.data._id}`,
      },
    };
    try {
      await editUser(data).unwrap();
      dispatch(setSettingsChange(!settingsChange));
      navigate("/settings/users");
    } catch (e: any) {
      if (e.data) {
        window.scrollTo(0, 0);
        setShow(true);
        setErrorContent(e.data.message);
      }
    }
  };
  return (
    <div className="add-user mt-2">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">Edit User</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter employee email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={true}
                        className="custom-input disabled-input border-1"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Select
                        name="designation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.designation}
                        className={
                          touched.designation && errors.designation
                            ? "is-invalid custom-input"
                            : "custom-input"
                        }
                      >
                        {state.loading ? (
                          <option value="" disabled={true}>
                            Loading...
                          </option>
                        ) : (
                          <>
                            {state.allDesignations.length > 0 ? (
                              <>
                                <option value="">Select Designation</option>
                                {state.allDesignations.map((role, index) => (
                                  <option key={index} value={role.roleName}>
                                    {role.roleName}
                                  </option>
                                ))}{" "}
                              </>
                            ) : (
                              <option disabled={true} value="">
                                No Roles Added
                              </option>
                            )}
                          </>
                        )}
                      </Form.Select>

                      {touched.designation && errors.designation ? (
                        <div className="invalid-feedback">
                          {errors.designation}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Select
                        name="department"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.department}
                        className={
                          touched.department && errors.department
                            ? "is-invalid custom-input"
                            : "custom-input"
                        }
                      >
                        <option value="">Select Department</option>
                        <option value="General">General</option>
                        <option value="Operations">Operations</option>
                        <option value="Accounts">Accounts</option>
                        <option value="Finance">Finance</option>
                        <option value="HR">HR</option>
                      </Form.Select>

                      {touched.department && errors.department ? (
                        <div className="invalid-feedback">
                          {errors.department}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn primary-button normal-font-weight"
                      >
                        {editUserLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditUser;
