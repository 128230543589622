import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Projects
import { useGetClientReviewsQuery } from "../../services/Projects";
import {
  setClientReviewsError,
  setClientReviewsLoading,
  setClientTotalRecords,
  setReviews,
} from "../../store/slices/projectSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientReviewChange = useAppSelector(
    (state) => state.common.clientReviewChange,
  );
  // States
  const state = useAppSelector((state) => state.project);

  // **************
  // **************
  // Get Client Review
  // **************
  // **************
  const {
    data: allReviews,
    isLoading: reviewLoading,
    isError: reviewError,
    refetch: reviewRefetch,
  } = useGetClientReviewsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    page: state.clientPageNo,
    limit: state.clientPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (reviewLoading) {
      dispatch(setClientReviewsLoading(true));
    }
    if (reviewError) {
      dispatch(setClientReviewsError("Something went wrong"));
      dispatch(setClientReviewsLoading(false));
    }
    if (allReviews) {
      dispatch(setReviews(allReviews.data.reviews[0].data || []));
      dispatch(
        setClientTotalRecords(
          allReviews.data.reviews[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setClientReviewsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allReviews,
    profileData,
    state.clientPageNo,
    state.clientPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    reviewRefetch();
  }, [clientReviewChange]);
  return <></>;
};
export default Content;
