import { createSlice } from "@reduxjs/toolkit";
import {
  AllEmployees,
  Employees,
  ProbationEmployee,
  AbscondedEmployee,
  DeletedEmployees,
  Statement,
  EmployeePayslip,
} from "../../interfaces/employee";

interface Index {
  empLoading: boolean;
  showExportDropdown: boolean;
  showFilterDropdown: boolean;
  countryCode: string;
  showCountryCodeDropdown: boolean;
  basicInfo: boolean;
  legalInfo: boolean;
  otherDetails: boolean;
  evaluationDetails: boolean;
  agreementDetails: boolean;
  attachments: boolean;
  editBasicInfo: boolean;
  editLegalInfo: boolean;
  editOtherDetails: boolean;
  editEvaluationDetails: boolean;
  editAgreementDetails: boolean;
  editAttachments: boolean;
  editTimeline: boolean;
  editIncrementTimeline: boolean;
  activeTab: string;
  tabList: string[];
  detailPageBasicInfo: boolean;
  detailPageLegalInfo: boolean;
  detailPageOtherDetails: boolean;
  detailPageEvaluationDetails: boolean;
  detailPageAgreementDetails: boolean;
  detailPageAttachments: boolean;
  detailPageAttendanceLog: boolean;
  detailPageTimeline: boolean;
  detailPageIncrementTimeline: boolean;
  detailPageStatement: boolean;
  detailPagePayslips: boolean;
  detailTabList: string[];
  detailActiveTab: string;
  empResponseError: string;
  employees: Employees[];
  pageNo: number;
  pageSize: number;
  totalRecords: number;
  filterPageNo: number;
  filterPageSize: number;
  filterTotalRecords: number;
  allEmployeesList: AllEmployees[];
  allEmployeesListLoading: boolean;
  activeTabEmpList: string;
  tabEmpList: string[];
  employeesList: boolean;
  probationEmployeesList: boolean;
  abscondedEmployeesList: boolean;
  terminatedEmployeesList: boolean;
  certificatesList: boolean;
  offerLettersList: boolean;
  finalExitEmployeesList: boolean;
  // Active Employees
  activeEmpResponseError: string;
  activeEmployees: Employees[];
  activePageNo: number;
  activePageSize: number;
  activeTotalRecords: number;
  filterActivePageNo: number;
  filterActivePageSize: number;
  filterActiveTotalRecords: number;
  activeEmpLoading: boolean;
  showActiveExportDropdown: boolean;
  //   Probation Employees
  showProbationExportDropdown: boolean;
  probationEmployees: ProbationEmployee[];
  probationPageNo: number;
  probationPageSize: number;
  probationTotalRecords: number;
  filterProbationPageNo: number;
  filterProbationPageSize: number;
  filterProbationTotalRecords: number;
  probationLoading: boolean;
  probationError: string;
  addProbationLoading: boolean;
  // Absconded Employees
  showAbscondedExportDropdown: boolean;
  abscondedEmployees: AbscondedEmployee[];
  abscondedPageNo: number;
  abscondedPageSize: number;
  abscondedTotalRecords: number;
  filterAbscondedPageNo: number;
  filterAbscondedPageSize: number;
  filterAbscondedTotalRecords: number;
  abscondedLoading: boolean;
  abscondedError: string;
  addAbscondedLoading: boolean;
  //   Deleted Employees
  showDeletedExportDropdown: boolean;
  deletedEmployees: DeletedEmployees[];
  deletedPageNo: number;
  deletedPageSize: number;
  deletedTotalRecords: number;
  deletedLoading: boolean;
  deletedError: string;
  //   Statement
  statementIds: Statement;
  statementIdsLoading: boolean;
  statementIdsError: string;
  statementFrom: string;
  statementTo: string;
  //   Payslips
  showPayslipExportDropdown: boolean;
  payslips: EmployeePayslip[];
  payslipPageNo: number;
  payslipPageSize: number;
  payslipTotalRecords: number;
  payslipLoading: boolean;
  payslipError: string;
  payStartFrom: string;
  payEndTo: string;
}

const getFirstDateOfYear = () => {
  const year = new Date().getFullYear(); // Get the year of the provided date
  const firstDateOfYear = new Date(year, 0, 1); // Create a new date object with the first day of the year

  return firstDateOfYear;
};

const returnLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const initialState: Index = {
  empLoading: false,
  showExportDropdown: false,
  showFilterDropdown: false,
  countryCode: "+966",
  showCountryCodeDropdown: false,
  basicInfo: true,
  legalInfo: false,
  otherDetails: false,
  evaluationDetails: false,
  agreementDetails: false,
  attachments: false,
  editBasicInfo: true,
  editLegalInfo: false,
  editOtherDetails: false,
  editEvaluationDetails: false,
  editAgreementDetails: false,
  editAttachments: false,
  editTimeline: false,
  editIncrementTimeline: false,
  activeTab: "Basic Information",
  tabList: [
    "Basic Information",
    "Legal Details",
    "Other Details",
    "Agreement Details",
    "Attachments",
    "Evaluation Details",
    "Projects Timeline",
    "Salary Timeline",
  ],
  detailPageBasicInfo: true,
  detailPageLegalInfo: false,
  detailPageOtherDetails: false,
  detailPageEvaluationDetails: false,
  detailPageAgreementDetails: false,
  detailPageAttachments: false,
  detailPageAttendanceLog: false,
  detailPageTimeline: false,
  detailPageIncrementTimeline: false,
  detailPageStatement: false,
  detailPagePayslips: false,
  detailTabList: [
    "Basic Information",
    "Legal Details",
    "Other Details",
    "Agreement Details",
    "Attachments",
    "Evaluation Details",
    "Attendance Log",
    "Projects Timeline",
    "Salary Timeline",
    "Payslips",
    "Statement",
  ],
  detailActiveTab: "Basic Information",
  empResponseError: "",
  employees: [],
  pageNo: 1,
  pageSize: 10,
  totalRecords: 0,
  filterPageNo: 1,
  filterPageSize: 10,
  filterTotalRecords: 0,
  allEmployeesList: [],
  allEmployeesListLoading: false,
  activeTabEmpList: "Employees",
  tabEmpList: [
    "Employees",
    "Probation Employees",
    "Absconded Employees",
    "Final Exit Employees",
    "Terminated Employees",
  ],
  employeesList: true,
  probationEmployeesList: false,
  abscondedEmployeesList: false,
  terminatedEmployeesList: false,
  certificatesList: false,
  offerLettersList: false,
  finalExitEmployeesList: false,
  // Active Employees
  activeEmpResponseError: "",
  activeEmployees: [],
  activePageNo: 1,
  activePageSize: 10,
  activeTotalRecords: 0,
  filterActivePageNo: 1,
  filterActivePageSize: 10,
  filterActiveTotalRecords: 0,
  activeEmpLoading: false,
  showActiveExportDropdown: false,
  //   Probation Employees
  showProbationExportDropdown: false,
  probationEmployees: [],
  probationPageNo: 1,
  probationPageSize: 10,
  probationTotalRecords: 0,
  filterProbationPageNo: 1,
  filterProbationPageSize: 10,
  filterProbationTotalRecords: 0,
  probationLoading: false,
  probationError: "",
  addProbationLoading: false,
  // Absconded Employees
  showAbscondedExportDropdown: false,
  abscondedEmployees: [],
  abscondedPageNo: 1,
  abscondedPageSize: 10,
  abscondedTotalRecords: 0,
  filterAbscondedPageNo: 1,
  filterAbscondedPageSize: 10,
  filterAbscondedTotalRecords: 0,
  abscondedLoading: false,
  abscondedError: "",
  addAbscondedLoading: false,
  //   Deleted Employees
  showDeletedExportDropdown: false,
  deletedEmployees: [],
  deletedPageNo: 1,
  deletedPageSize: 10,
  deletedTotalRecords: 0,
  deletedLoading: false,
  deletedError: "",
  //   Statement
  statementIds: {
    statements: [],
    openingBalance: 0,
    totalCredit: 0,
    totalDebit: 0,
    balanceDue: 0,
  },
  statementIdsLoading: false,
  statementIdsError: "",
  statementFrom: getFirstDateOfYear().toLocaleDateString(),
  statementTo: returnLastDateOfMonth().toLocaleDateString(),
  //   Payslips
  showPayslipExportDropdown: false,
  payslips: [],
  payslipPageNo: 1,
  payslipPageSize: 10,
  payslipTotalRecords: 0,
  payslipLoading: false,
  payslipError: "",
  payStartFrom: getFirstDateOfYear().toLocaleDateString(),
  payEndTo: returnLastDateOfMonth().toLocaleDateString(),
};

const employeeSlice = createSlice({
  name: "employee",
  initialState: initialState,
  reducers: {
    setEmpLoading: (state, action) => {
      state.empLoading = action.payload;
    },
    setShowExportDropdown: (state, action) => {
      state.showExportDropdown = action.payload;
    },
    setShowFilterDropdown: (state, action) => {
      state.showFilterDropdown = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setShowCountryCodeDropdown: (state, action) => {
      state.showCountryCodeDropdown = action.payload;
    },
    setBasicInfo: (state, action) => {
      state.basicInfo = action.payload;
    },
    setLegalInfo: (state, action) => {
      state.legalInfo = action.payload;
    },
    setOtherDetails: (state, action) => {
      state.otherDetails = action.payload;
    },
    setEvaluationDetails: (state, action) => {
      state.evaluationDetails = action.payload;
    },
    setAgreementDetails: (state, action) => {
      state.agreementDetails = action.payload;
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload;
    },
    setEditBasicInfo: (state, action) => {
      state.editBasicInfo = action.payload;
    },
    setEditLegalInfo: (state, action) => {
      state.editLegalInfo = action.payload;
    },
    setEditOtherDetails: (state, action) => {
      state.editOtherDetails = action.payload;
    },
    setEditEvaluationDetails: (state, action) => {
      state.editEvaluationDetails = action.payload;
    },
    setEditAgreementDetails: (state, action) => {
      state.editAgreementDetails = action.payload;
    },
    setEditAttachments: (state, action) => {
      state.editAttachments = action.payload;
    },
    setEditTimeline: (state, action) => {
      state.editTimeline = action.payload;
    },
    setEditIncrementTimeline: (state, action) => {
      state.editIncrementTimeline = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setDetailPageBasicInfo: (state, action) => {
      state.detailPageBasicInfo = action.payload;
    },
    setDetailPageLegalInfo: (state, action) => {
      state.detailPageLegalInfo = action.payload;
    },
    setDetailPageOtherDetails: (state, action) => {
      state.detailPageOtherDetails = action.payload;
    },
    setDetailPageEvaluationDetails: (state, action) => {
      state.detailPageEvaluationDetails = action.payload;
    },
    setDetailPageAgreementDetails: (state, action) => {
      state.detailPageAgreementDetails = action.payload;
    },
    setDetailPageAttachments: (state, action) => {
      state.detailPageAttachments = action.payload;
    },
    setDetailPageAttendanceLog: (state, action) => {
      state.detailPageAttendanceLog = action.payload;
    },
    setDetailPageTimeline: (state, action) => {
      state.detailPageTimeline = action.payload;
    },
    setDetailPageIncrementTimeline: (state, action) => {
      state.detailPageIncrementTimeline = action.payload;
    },
    setDetailPageStatement: (state, action) => {
      state.detailPageStatement = action.payload;
    },
    setDetailPagePayslips: (state, action) => {
      state.detailPagePayslips = action.payload;
    },
    setDetailActiveTab: (state, action) => {
      state.detailActiveTab = action.payload;
    },
    setEmpResponseError: (state, action) => {
      state.empResponseError = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setFilterPageNo: (state, action) => {
      state.filterPageNo = action.payload;
    },
    setFilterPageSize: (state, action) => {
      state.filterPageSize = action.payload;
    },
    setFilterTotalRecords: (state, action) => {
      state.filterTotalRecords = action.payload;
    },
    setAllEmployeesList: (state, action) => {
      state.allEmployeesList = action.payload;
    },
    setAllEmployeesListLoading: (state, action) => {
      state.allEmployeesListLoading = action.payload;
    },
    setActiveTabEmpList: (state, action) => {
      state.activeTabEmpList = action.payload;
    },
    setEmployeesList: (state, action) => {
      state.employeesList = action.payload;
    },
    setProbationEmployeesList: (state, action) => {
      state.probationEmployeesList = action.payload;
    },
    setAbscondedEmployeesList: (state, action) => {
      state.abscondedEmployeesList = action.payload;
    },
    setTerminatedEmployeesList: (state, action) => {
      state.terminatedEmployeesList = action.payload;
    },
    setCertificatesList: (state, action) => {
      state.certificatesList = action.payload;
    },
    setOfferLettersList: (state, action) => {
      state.offerLettersList = action.payload;
    },
    setFinalExitEmployeesList: (state, action) => {
      state.finalExitEmployeesList = action.payload;
    },
    // Active Employees
    setActiveEmpResponseError: (state, action) => {
      state.activeEmpResponseError = action.payload;
    },
    setActiveEmployees: (state, action) => {
      state.activeEmployees = action.payload;
    },
    setActivePageNo: (state, action) => {
      state.activePageNo = action.payload;
    },
    setActivePageSize: (state, action) => {
      state.activePageSize = action.payload;
    },
    setActiveTotalRecords: (state, action) => {
      state.activeTotalRecords = action.payload;
    },
    setFilterActivePageNo: (state, action) => {
      state.filterActivePageNo = action.payload;
    },
    setFilterActivePageSize: (state, action) => {
      state.filterActivePageSize = action.payload;
    },
    setFilterActiveTotalRecords: (state, action) => {
      state.filterActiveTotalRecords = action.payload;
    },
    setActiveEmpLoading: (state, action) => {
      state.activeEmpLoading = action.payload;
    },
    setShowActiveExportDropdown: (state, action) => {
      state.showActiveExportDropdown = action.payload;
    },
    // Probation Employees
    setProbationEmployees: (state, action) => {
      state.probationEmployees = action.payload;
    },
    setProbationPageNo: (state, action) => {
      state.probationPageNo = action.payload;
    },
    setProbationPageSize: (state, action) => {
      state.probationPageSize = action.payload;
    },
    setProbationTotalRecords: (state, action) => {
      state.probationTotalRecords = action.payload;
    },
    setFilterProbationPageNo: (state, action) => {
      state.filterProbationPageNo = action.payload;
    },
    setFilterProbationPageSize: (state, action) => {
      state.filterProbationPageSize = action.payload;
    },
    setFilterProbationTotalRecords: (state, action) => {
      state.filterProbationTotalRecords = action.payload;
    },
    setProbationLoading: (state, action) => {
      state.probationLoading = action.payload;
    },
    setProbationError: (state, action) => {
      state.probationError = action.payload;
    },
    setAddProbationLoading: (state, action) => {
      state.addProbationLoading = action.payload;
    },
    setShowProbationExportDropdown: (state, action) => {
      state.showProbationExportDropdown = action.payload;
    },
    // Absconded Employees
    setAbscondedEmployees: (state, action) => {
      state.abscondedEmployees = action.payload;
    },
    setAbscondedPageNo: (state, action) => {
      state.abscondedPageNo = action.payload;
    },
    setAbscondedPageSize: (state, action) => {
      state.abscondedPageSize = action.payload;
    },
    setAbscondedTotalRecords: (state, action) => {
      state.abscondedTotalRecords = action.payload;
    },
    setFilterAbscondedPageNo: (state, action) => {
      state.filterAbscondedPageNo = action.payload;
    },
    setFilterAbscondedPageSize: (state, action) => {
      state.filterAbscondedPageSize = action.payload;
    },
    setFilterAbscondedTotalRecords: (state, action) => {
      state.filterAbscondedTotalRecords = action.payload;
    },
    setAbscondedLoading: (state, action) => {
      state.abscondedLoading = action.payload;
    },
    setAbscondedError: (state, action) => {
      state.abscondedError = action.payload;
    },
    setAddAbscondedLoading: (state, action) => {
      state.addAbscondedLoading = action.payload;
    },
    setShowAbscondedExportDropdown: (state, action) => {
      state.showAbscondedExportDropdown = action.payload;
    },
    // Deleted Employees
    setShowDeletedExportDropdown: (state, action) => {
      state.showDeletedExportDropdown = action.payload;
    },
    setDeletedEmployees: (state, action) => {
      state.deletedEmployees = action.payload;
    },
    setDeletedPageNo: (state, action) => {
      state.deletedPageNo = action.payload;
    },
    setDeletedPageSize: (state, action) => {
      state.deletedPageSize = action.payload;
    },
    setDeletedTotalRecords: (state, action) => {
      state.deletedTotalRecords = action.payload;
    },
    setDeletedLoading: (state, action) => {
      state.deletedLoading = action.payload;
    },
    setDeletedError: (state, action) => {
      state.deletedError = action.payload;
    },
    //   Statement
    setStatementIds: (state, action) => {
      state.statementIds = action.payload;
    },
    setStatementIdsLoading: (state, action) => {
      state.statementIdsLoading = action.payload;
    },
    setStatementIdsError: (state, action) => {
      state.statementIdsError = action.payload;
    },
    setStatementFrom: (state, action) => {
      state.statementFrom = action.payload;
    },
    setStatementTo: (state, action) => {
      state.statementTo = action.payload;
    },
    //   Payslips
    setPayslips: (state, action) => {
      state.payslips = action.payload;
    },
    setPayslipPageNo: (state, action) => {
      state.payslipPageNo = action.payload;
    },
    setPayslipPageSize: (state, action) => {
      state.payslipPageSize = action.payload;
    },
    setPayslipTotalRecords: (state, action) => {
      state.payslipTotalRecords = action.payload;
    },
    setPayslipLoading: (state, action) => {
      state.payslipLoading = action.payload;
    },
    setPayslipError: (state, action) => {
      state.payslipError = action.payload;
    },
    setPayStartFrom: (state, action) => {
      state.payStartFrom = action.payload;
    },
    setPayEndTo: (state, action) => {
      state.payEndTo = action.payload;
    },
    setShowPayslipExportDropdown: (state, action) => {
      state.showPayslipExportDropdown = action.payload;
    },
  },
});

export const {
  setEmpLoading,
  setShowExportDropdown,
  setShowFilterDropdown,
  setCountryCode,
  setShowCountryCodeDropdown,
  setBasicInfo,
  setLegalInfo,
  setOtherDetails,
  setEvaluationDetails,
  setEditBasicInfo,
  setEditLegalInfo,
  setEditOtherDetails,
  setEditEvaluationDetails,
  setActiveTab,
  setDetailPageBasicInfo,
  setDetailPageLegalInfo,
  setDetailPageOtherDetails,
  setDetailPageEvaluationDetails,
  setDetailPageStatement,
  setDetailActiveTab,
  setEmpResponseError,
  setEmployees,
  setPageNo,
  setPageSize,
  setTotalRecords,
  setFilterPageNo,
  setFilterPageSize,
  setFilterTotalRecords,
  setActiveEmpResponseError,
  setActiveEmployees,
  setActivePageNo,
  setActivePageSize,
  setActiveTotalRecords,
  setFilterActivePageNo,
  setFilterActivePageSize,
  setFilterActiveTotalRecords,
  setActiveEmpLoading,
  setShowActiveExportDropdown,
  setAllEmployeesList,
  setAllEmployeesListLoading,
  setActiveTabEmpList,
  setAbscondedEmployeesList,
  setProbationEmployeesList,
  setTerminatedEmployeesList,
  setEmployeesList,
  setAbscondedEmployees,
  setAbscondedError,
  setAbscondedLoading,
  setAbscondedPageNo,
  setAbscondedPageSize,
  setAbscondedTotalRecords,
  setAddAbscondedLoading,
  setProbationEmployees,
  setProbationError,
  setProbationLoading,
  setProbationPageNo,
  setProbationPageSize,
  setProbationTotalRecords,
  setAddProbationLoading,
  setShowAbscondedExportDropdown,
  setShowProbationExportDropdown,
  setShowDeletedExportDropdown,
  setDeletedEmployees,
  setDeletedPageNo,
  setDeletedPageSize,
  setDeletedTotalRecords,
  setDeletedLoading,
  setDeletedError,
  setCertificatesList,
  setFinalExitEmployeesList,
  setOfferLettersList,
  setAgreementDetails,
  setEditAgreementDetails,
  setAttachments,
  setEditAttachments,
  setDetailPageAgreementDetails,
  setDetailPageAttachments,
  setFilterProbationPageNo,
  setFilterAbscondedPageNo,
  setFilterAbscondedPageSize,
  setFilterAbscondedTotalRecords,
  setFilterProbationPageSize,
  setFilterProbationTotalRecords,
  setDetailPageAttendanceLog,
  setDetailPageTimeline,
  setDetailPageIncrementTimeline,
  setEditTimeline,
  setEditIncrementTimeline,
  setStatementIds,
  setStatementIdsLoading,
  setStatementIdsError,
  setStatementFrom,
  setStatementTo,
  setDetailPagePayslips,
  setPayslips,
  setPayslipPageNo,
  setPayslipPageSize,
  setPayslipTotalRecords,
  setPayslipLoading,
  setPayslipError,
  setPayStartFrom,
  setPayEndTo,
  setShowPayslipExportDropdown,
} = employeeSlice.actions;
export default employeeSlice.reducer;
