import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import DatePicker from "react-datepicker";
import { PettyCash } from "../../interfaces/petty-cash";
import { setFilterDate } from "../../store/slices/pettyCashSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface Props {
  show?: boolean;
  setShow?: any;
  setLedger?: any;
  setFilterSearch: any;
  ledger: PettyCash[];
}

interface FormProps {
  monthYear: string;
}

const LedgerFilterModal = ({
  show,
  setShow,
  ledger,
  setLedger,
  setFilterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const pettyCash = useAppSelector((state) => state.pettyCash);
  // Initial Values
  const initialValues: FormProps = {
    monthYear: pettyCash.filterDate,
  };

  const renderMonthContent = (
    month: number,
    shortMonth: string,
    longMonth: string,
  ) => {
    const fullYear = new Date(month).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  // Handle Submit Function to filter employees based on the form values
  const handleSubmit = (values: FormProps) => {
    // Filter employees based on the form values
    dispatch(setFilterDate(values.monthYear));
    setFilterSearch(true);
    setShow(false);
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
    dispatch(setFilterDate(new Date().toLocaleDateString()));
    setFilterSearch(false);
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Petty Cash Ledger</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              {({ values, handleChange, handleSubmit, resetForm }) => (
                <Form onSubmit={handleSubmit} className="text-end">
                  <Row>
                    <Col sm="12">
                      <Form.Group
                        className="mb-4 text-start"
                        controlId="formBasicEmail"
                      >
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Filter by month
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="monthYear"
                            selected={
                              values.monthYear
                                ? new Date(values.monthYear)
                                : null
                            }
                            onChange={(date: any) =>
                              handleChange({
                                target: { name: "monthYear", value: date },
                              })
                            }
                            autoComplete="off"
                            renderMonthContent={renderMonthContent}
                            showMonthYearPicker
                            placeholderText="Select iqama expiry date"
                            className="w-100 custom-input"
                            dateFormat="MM/yyyy"
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <button
                      className="btn primary-button text-center w-25 me-2"
                      onClick={() => {
                        handleReset(resetForm);
                      }}
                      type="button"
                    >
                      Reset
                    </button>
                    <button
                      className="btn primary-button text-center w-25"
                      type="submit"
                    >
                      Filter
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LedgerFilterModal;
