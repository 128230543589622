import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { Payment } from "../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterPaymentTotalPages,
  setPaymentListLoading,
} from "../../store/slices/clientsSlice";
// API imports
import { useGetFilterTransactionListMutation } from "../../services/Clients";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setPayments?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  payments: Payment[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  depositTo: string;
  fromDate: string;
  toDate: string;
}

const PaymentsFilterModal = ({
  show,
  setShow,
  payments,
  setPayments,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.clients);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterTransactionList] = useGetFilterTransactionListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setPaymentListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterPaymentPageNo,
          limit: state.filterPaymentPageSize,
          clientId: state.clientIdNumber,
          depositTo: filterInput.depositTo,
          fromDate: filterInput.fromDate,
          toDate: filterInput.toDate,
        },
      };
      const response = await getFilterTransactionList(data).unwrap();
      if (response) {
        setPayments(response.data.payments[0].data);
        dispatch(
          setFilterPaymentTotalPages(
            response.data.payments[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setPaymentListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setPaymentListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterPaymentPageNo,
            limit: state.filterPaymentPageSize,
            clientId: state.clientIdNumber,
            paymentId: query,
            depositTo: filterInput.depositTo,
            fromDate: filterInput.fromDate,
            toDate: filterInput.toDate,
          },
        };
        const response = await getFilterTransactionList(data).unwrap();
        if (response) {
          setPayments(response.data.payments[0].data);
          dispatch(
            setFilterPaymentTotalPages(
              response.data.payments[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setPaymentListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterPaymentPageNo, state.filterPaymentPageSize, query]);

  const handleReset = () => {
    setPayments(payments);
    setFilterInput({
      depositTo: "",
      fromDate: "",
      toDate: "",
    });
  };

  const accountChartsIds = useAppSelector(
    (state) => state.accountCharts.accountChartsIds,
  );
  // Options
  const companyOptions = accountChartsIds.map((item) => ({
    name: item.accountId + " - " + item.accountName,
    value: item.accountName,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Payment List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Deposit To
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="depositTo"
                        options={companyOptions}
                        value={filterInput.depositTo}
                        onChange={(value: any) =>
                          setFilterInput({
                            ...filterInput,
                            depositTo: value,
                          })
                        }
                        search={true}
                        placeholder="Select Deposit To"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      placeholder="From date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          fromDate: e.target.value,
                        })
                      }
                      value={filterInput.fromDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="toDate"
                      placeholder="To date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          toDate: e.target.value,
                        })
                      }
                      value={filterInput.toDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentsFilterModal;
