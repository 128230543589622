import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../../assets/images";
import AttachmentSet from "../AddEmployee/attachments";
// Redux
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { setAddExpensesLoading } from "../../../store/slices/lettersSlice";
import { setViolationsChange } from "../../../store/slices/commonSlice";
// API imports
import {
  useAddExpenseMutation,
  useGetExpenseDetailMutation,
} from "../../../services/Letters";
import { ExpenseDetail } from "../../../interfaces/letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderBlue, LoaderWhite } from "../../Lotties";
import DatePicker from "react-datepicker";
import InstallmentsSet from "./installments";

const FineClearance = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  // Adding Violations and hitting API
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const violationsChange = useAppSelector(
    (state) => state.common.violationsChange,
  );
  const [empId, setEmpId] = useState("");
  const [empData, setEmpData] = useState<ExpenseDetail>();
  const [empDataLoading, setEmpDataLoading] = useState(false);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const accountsChartsIds = useAppSelector(
    (state) => state.accountCharts.accountChartsIds,
  );
  const employee = employees.find(
    (employee) => employee.empId === parseInt(empId),
  );
  const [payAbleBy, setPayAbleBy] = useState("");
  const [expenseType, setExpenseType] = useState("");
  const [otherExpenseType, setOtherExpenseType] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseDetail, setExpenseDetail] = useState("");
  const [expenseStatus, setExpenseStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [category, setCategory] = useState("");
  const [supplier, setSupplier] = useState("");

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  // Attachments
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Installments
  const [installmentsSets, setInstallmentsSets] = useState<React.ReactNode[]>(
    [],
  );
  const [installmentsData, setInstallmentsData] = useState<
    {
      uId: string;
      month: string;
      amount: number;
      status: string;
    }[]
  >([]);

  const handleAddInstallmentsSet = () => {
    setInstallmentsData([
      ...installmentsData,
      {
        uId: Math.random().toString(36).substring(7),
        month: "",
        amount: 0,
        status: "Pending",
      },
    ]);
  };

  const handleRemoveInstallmentsSet = (index: string) => {
    const updatedInputData = installmentsData.filter(
      (item, i) => item.uId !== index,
    );
    setInstallmentsData(updatedInputData);
  };
  const handleInstallmentsInputChange = (
    index: string,
    data: {
      month: string;
      amount: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = installmentsData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setInstallmentsData(updatedInput);
  };

  useEffect(() => {
    const updatedInstallmentsSets = installmentsData.map((data, index) => (
      <InstallmentsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        installments={installmentsData}
        onRemove={(key: string) => handleRemoveInstallmentsSet(key)}
        onInputChange={(inputData) =>
          handleInstallmentsInputChange(data.uId, inputData)
        }
      />
    ));
    setInstallmentsSets(updatedInstallmentsSets);
  }, [installmentsData]);

  // useEffect(() => {
  //   //  set the amount for each installment
  //   const amount = parseInt(expenseAmount) / installmentsData.length;
  //   const updatedInstallments = installmentsData.map((item) => ({
  //     ...item,
  //     amount: amount,
  //   }));
  //   setInstallmentsData(updatedInstallments);
  // }, [installmentsData, expenseAmount]);

  const sumOfInstallments = installmentsData.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  // check if all fields are filled in installments Data
  const checkInstallmentsData = () => {
    let isFilled = true;
    installmentsData.forEach((item) => {
      if (
        item.amount === 0 ||
        item.month === "" ||
        item.month === undefined ||
        item.month === "Invalid Date"
      ) {
        isFilled = false;
      }
    });
    return isFilled;
  };

  const [addExpense] = useAddExpenseMutation();

  const handleSave = async () => {
    if (!empId) {
      window.scrollTo(0, 0);
      setErrorContent("Please select an employee");
      setShow(true);
      return;
    }
    if (!expenseDate) {
      window.scrollTo(0, 0);
      setErrorContent("Please select an expense date");
      setShow(true);
      return;
    }
    if (!expenseAmount) {
      window.scrollTo(0, 0);
      setErrorContent("Please enter expense amount");
      setShow(true);
      return;
    }
    if (!payAbleBy) {
      window.scrollTo(0, 0);
      setErrorContent("Please select payable by");
      setShow(true);
      return;
    }
    if (!expenseType) {
      window.scrollTo(0, 0);
      setErrorContent("Please select expense type");
      setShow(true);
      return;
    }
    if (expenseType === "Others" && !otherExpenseType) {
      window.scrollTo(0, 0);
      setErrorContent("Please enter other expense type");
      setShow(true);
      return;
    }
    if (!category) {
      window.scrollTo(0, 0);
      setErrorContent("Please select category");
      setShow(true);
      return;
    }
    if (!checkInstallmentsData()) {
      window.scrollTo(0, 0);
      setErrorContent("Please fill all fields in installments");
      setShow(true);
      return;
    }
    if (
      sumOfInstallments !== parseInt(expenseAmount) &&
      installmentsData.length > 0
    ) {
      window.scrollTo(0, 0);
      setErrorContent("Sum of installments should be equal to expense amount");
      setShow(true);
      return;
    }
    dispatch(setAddExpensesLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: parseInt(empId),
          empName: employee?.empName || "",
          expenseDate: expenseDate,
          expenseAmount: expenseAmount,
          projectId: employee?.empProjectId,
          expenseDetail: expenseDetail,
          expenseStatus: expenseStatus,
          payAbleBy: payAbleBy,
          expenseType:
            expenseType === "Others" ? otherExpenseType : expenseType,
          link: `/hr/expenses`,
          expensesAttachments: languageData,
          installments: installmentsData,
          paymentMethod: paymentMethod,
          category: category,
          supplier: supplier,
        },
      };
      await addExpense(data).unwrap();
      dispatch(setViolationsChange(!violationsChange));
      navigate("/hr/expenses");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddExpensesLoading(false));
    }
  };

  const [getExpenseDetail] = useGetExpenseDetailMutation();

  const getEmpData = async () => {
    setEmpDataLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.showEmployee || false,
        payload: {
          empId: parseInt(empId),
        },
      };
      const response = await getExpenseDetail(data).unwrap();
      if (response) {
        setEmpData(response.data.pendingExpenses);
      }
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      setEmpDataLoading(false);
    }
  };

  useEffect(() => {
    if (empId) {
      getEmpData();
    }
  }, [empId]);

  const transactionMethods: string[] = [
    "Cash",
    "Credit/Debit Cards",
    "Bank Transfers",
    "Checks",
    "Mobile Payments",
    "Online Banking",
    "Wire Transfers",
    "Cryptocurrency",
    "Money Orders",
    "Electronic Funds Transfer (EFT)",
    "PayPal and Other Online Payment Services",
    "Automatic Clearing House (ACH) Payments",
    "Contactless Payments",
    "Smart Cards",
    "Point of Sale (POS) Systems",
    "Barter",
    "Installment Plans",
    "Direct Debit",
  ];

  // get full month name from date
  const getMonthName = (date: string) => {
    const month = new Date(date).getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month];
  };

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Expenses</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="search-emp w-25 mb-3">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Employee Id:
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={options}
                value={empId}
                onChange={(e: any) => setEmpId(e)}
                search={true}
                placeholder="Select Employee"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        {empDataLoading ? (
          <Col sm="12">
            <LoaderBlue height={50} width={50} />
          </Col>
        ) : empId ? (
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <h4 className="sub-heading mb-0">Previous Installments</h4>
                </div>
              </Col>
              <Col sm="12">
                {empData?.installments ? (
                  empData?.installments.length === 0 ? (
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        No Previous Installments
                      </h4>
                    </Form.Group>
                  ) : (
                    <div className="table-responsive mb-3">
                      <table className="table text-center table-bordered">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {empData?.installments.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.month ? getMonthName(item.month) : "N/A"}
                              </td>
                              <td>{item.amount}</td>
                              <td>{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )
                ) : (
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Previous Installments
                    </h4>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
        ) : null}
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Date
            </h4>
            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
              <DatePicker
                name="date"
                selected={expenseDate ? new Date(expenseDate) : null}
                onChange={(e: any) => setExpenseDate(e)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                placeholderText="Select expense date"
                className="w-100 custom-input"
                dateFormat="yyyy-MM-d"
              />
              <img
                src={Common.Calendar}
                className="calendar-date-recur"
                alt="calendarblack"
              />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Category
            </h4>
            <Form.Select
              name="category"
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              className="custom-input border-fill"
            >
              <option value="">Select Category</option>
              <option value="Addition">Addition</option>
              <option value="Deduction">Deduction</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Type
            </h4>
            <Form.Select
              name="selectedLanguage"
              onChange={(e) => setExpenseType(e.target.value)}
              value={expenseType}
              className="custom-input border-fill"
            >
              <option value="">Select Type</option>
              <option value="Food">Food</option>
              <option value="Accomodation">Accomodation</option>
              <option value="Transport">Transport</option>
              <option value="Traffic Violation">Traffic Violation</option>
              <option value="Fine">Fine</option>
              <option value="Others">Others</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {expenseType === "Others" && (
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Other Category
              </h4>
              <Form.Control
                type="text"
                name="otherCategory"
                placeholder="Enter other category name"
                onChange={(e) => setOtherExpenseType(e.target.value)}
                value={otherExpenseType}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
        )}
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Amount
            </h4>
            <Form.Control
              type="text"
              placeholder="Enter expense amount"
              name="expenseAmount"
              onChange={(e) => {
                // Accept numbers and float values
                const re = /^[0-9\b]*(\.\d{0,2})?$/;

                if (e.target.value === "" || re.test(e.target.value)) {
                  setExpenseAmount(e.target.value);
                }
              }}
              value={expenseAmount}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Payable By
            </h4>
            <Form.Select
              name="selectedLanguage"
              onChange={(e) => setPayAbleBy(e.target.value)}
              value={payAbleBy}
              className="custom-input border-fill"
            >
              <option value="">Select Payable By</option>
              <option value="Employee">Employee</option>
              <option value="Company">Company</option>
              <option value="Client">Client</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Select Status
            </h4>
            <Form.Select
              name="selectedLanguage"
              onChange={(e) => setExpenseStatus(e.target.value)}
              value={expenseStatus}
              className="custom-input border-fill"
            >
              <option value="">Select expense status</option>
              <option value="Pending">Pending</option>
              <option value="Cleared">Cleared</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Payment Method
            </h4>
            <Form.Select
              name="paymentMethod"
              onChange={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
              className="custom-input border-fill"
            >
              <option value="">Select payment method</option>
              {transactionMethods.map((method, index) => (
                <option key={index} value={method}>
                  {method}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Supplier
            </h4>
            <Form.Select
              name="supplier"
              onChange={(e) => setSupplier(e.target.value)}
              value={supplier}
              className="custom-input border-fill"
            >
              <option value="">Select Supplier</option>
              {accountsChartsIds.map((item, index) => (
                <option key={index} value={item.accountName}>
                  {item.accountId + " - " + item.accountName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="12">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Description:
            </h4>
            <textarea
              name="description"
              placeholder="Leave a comment here..."
              value={expenseDetail}
              onChange={(e) => setExpenseDetail(e.target.value)}
              className="border-fill w-100"
              rows={4}
              style={{ resize: "none" }}
            />
          </Form.Group>
        </Col>
        <Col sm="4">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            {expenseStatus !== "Cleared" && (
              <button
                className="btn primary-button normal-font-weight"
                type="button"
                onClick={handleAddInstallmentsSet}
              >
                <img
                  src={Common.Plus}
                  width="20px"
                  height="20px"
                  className="me-2"
                  alt="next"
                />{" "}
                Installments
              </button>
            )}
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Attachments
            </button>
          </div>
        </Col>
        <Col sm="12">
          {installmentsSets.length > 0 && (
            <div className="d-flex align-items-center gap-5">
              <h4
                className="sub-heading normal-font-weight textBlack mt-4"
                style={{ fontSize: "18px" }}
              >
                Installments
              </h4>
              <h4
                className="sub-heading normal-font-weight textBlack mt-4"
                style={{ fontSize: "18px" }}
              >
                Per Month:{" "}
                {installmentsData.length > 0
                  ? parseInt(expenseAmount) / installmentsData.length
                  : expenseAmount}
              </h4>
            </div>
          )}
          <div className="extra-detail mb-4 mt-0">{installmentsSets}</div>
          {languageSets.length > 0 && (
            <h4
              className="sub-heading normal-font-weight textBlack mt-4"
              style={{ fontSize: "18px" }}
            >
              Attachments
            </h4>
          )}
          <div className="extra-detail my-4">{languageSets}</div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSave}>
              {state.addExpensesLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default FineClearance;
