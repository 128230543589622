import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAddSalaryIncrementLoading } from "../../../store/slices/lettersSlice";
import { setSalaryIncrementChange } from "../../../store/slices/commonSlice";
// API imports
import { useEditSalaryIncrementMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";
import OfferLetterAllowanceSet from "../AddEmployee/offer-letter-allowances";
import { Common } from "../../../assets/images";

const EditSalaryIncrement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const salaryData = location.state.data;
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState(salaryData.empId || "");
  const [incrementType, setIncrementType] = React.useState(
    salaryData.incrementType || "",
  );
  const [incrementAmount, setIncrementAmount] = React.useState(
    salaryData.incrementAmount.toString() || "",
  );
  const [incrementPercentage, setIncrementPercentage] = React.useState(
    salaryData.incrementPercentage || "",
  );
  const [incrementFrom, setIncrementFrom] = React.useState(
    salaryData.incrementFrom || "",
  );
  const [incrementTo, setIncrementTo] = React.useState(
    salaryData.incrementTo || "",
  );
  const [newSalary, setNewSalary] = React.useState(
    salaryData.updatedSalary || "",
  );
  const [dutyHours, setDutyHours] = React.useState(
    salaryData.dutyHours.toString || "",
  );
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const salaryIncrementChange = useAppSelector(
    (state) => state.common.salaryIncrementChange,
  );

  const selectEmployee = employees.find(
    (employee) => employee.empId === parseInt(empID),
  );

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >(salaryData.allowances || []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  // API call for add salary increment
  const [editSalaryIncrement] = useEditSalaryIncrementMutation();
  const handleSubmit = async () => {
    dispatch(setAddSalaryIncrementLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          incrementId: salaryData._id,
          incrementAmount: parseInt(incrementAmount),
          incrementFrom: incrementFrom,
          updatedSalary: parseInt(newSalary),
          dutyHours: parseInt(dutyHours),
          allowances: experienceData,
          link: `/hr/salary-increments`,
        },
      };
      await editSalaryIncrement(data).unwrap();
      dispatch(setSalaryIncrementChange(!salaryIncrementChange));
      navigate("/hr/salary-increments");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddSalaryIncrementLoading(false));
    }
  };

  React.useEffect(() => {
    // calculate increment amount and new salary from percentage and current basic Salary
    if (selectEmployee) {
      const currentSalary = selectEmployee.basicSalary;
      const newSalary = currentSalary + incrementAmount;
      setNewSalary(newSalary.toString());
    }
  }, [incrementAmount, selectEmployee]);

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Edit Salary Increment</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Increment Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Increment Amount
                  </h4>
                  <Form.Control
                    type="number"
                    name="incrementAmount"
                    value={incrementAmount}
                    onChange={(e) => setIncrementAmount(e.target.value)}
                    placeholder="Enter increment amount"
                    className="custom-input border-fill no-spinner"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    New Salary
                  </h4>
                  <Form.Control
                    type="number"
                    name="newSalary"
                    value={newSalary}
                    onChange={(e) => setNewSalary(e.target.value)}
                    placeholder="Enter new salary"
                    disabled={true}
                    className="custom-input disabled-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Increment From
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveFrom"
                    placeholder="Enter increment from"
                    onChange={(e) => setIncrementFrom(e.target.value)}
                    value={incrementFrom}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Duty Hours
                  </h4>
                  <Form.Control
                    type="text"
                    name="dutyHours"
                    placeholder="Enter duty hours"
                    onChange={(e) => {
                      //   accept only numbers
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setDutyHours(e.target.value);
                      }
                    }}
                    value={dutyHours}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="button"
              onClick={handleAddExperienceSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Allowance
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">
            {experienceSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Allowances
              </h4>
            )}
            {experienceSets}
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addSalaryIncrementLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default EditSalaryIncrement;
