import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../assets/images";
import AcknwoledgeLetter from "../Letters/acknwoledge-letter";
import { useAppSelector } from "../../store/hooks";

const AcknowledgementComponent = () => {
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const [empID, setEmpID] = useState("");
  const [remarks, setRemarks] = useState("");
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const options = employees.map((employee) => ({
    name: employee.empId, // Use the actual property name for the employee name
    value: employee.empId,
  }));
  const employee = employees.find(
    (employee) => employee.empId === parseInt(empID),
  );

  return (
    <div className="acknowledgment section px-4 py-4">
      <Container fluid>
        {print ? (
          <div>
            <button
              className="btn primary-button"
              onClick={() => setPrint(false)}
            >
              Back
            </button>
            <AcknwoledgeLetter
              empName={employee?.empName || "Select Employee Id"}
              remarks={remarks}
            />
          </div>
        ) : (
          <Row>
            <Col sm="12">
              <h2 className="heading">Acknowledgment</h2>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <div className="ref-no d-flex align-items-center gap-2">
                  <h4 className="sub-heading normal-font-weight textBlack mb-0">
                    Date:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight mb-0"
                    style={{ fontSize: "16px", color: "#29406C" }}
                  >
                    {currentDate}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="search-emp">
                <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
                  Select Employee by Id.
                </h4>
                <Form.Group className="position-relative input-design d-flex align-items-center">
                  <SelectSearch
                    options={options}
                    value={empID}
                    onChange={(value: any) => setEmpID(value)}
                    search={true}
                    placeholder="Select Employee by Id"
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </div>
            </Col>
            <Col sm="12">
              <div className="emp-detail mt-5">
                <h4 className="sub-heading mb-4">Employee Detail</h4>
                <Row>
                  <Col sm="4">
                    <div className="emp-detail-item">
                      <h4
                        className="sub-heading normal-font-weight textBlack mb-2"
                        style={{ fontSize: "18px" }}
                      >
                        Emp ID:
                      </h4>
                      <p
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {employee?.empId || "Select Employee Id"}
                      </p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="emp-detail-item">
                      <h4
                        className="sub-heading normal-font-weight textBlack mb-2"
                        style={{ fontSize: "18px" }}
                      >
                        Emp Name:
                      </h4>
                      <p
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {employee?.empName || "Select Employee Id"}
                      </p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="emp-detail-item">
                      <h4
                        className="sub-heading normal-font-weight textBlack mb-2"
                        style={{ fontSize: "18px" }}
                      >
                        Emp Status:
                      </h4>
                      <p
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {employee?.empStatus || "Select Employee Id"}
                      </p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="emp-detail-item">
                      <h4
                        className="sub-heading normal-font-weight textBlack mb-2"
                        style={{ fontSize: "18px" }}
                      >
                        Passport No.
                      </h4>
                      <p
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {employee?.empPassportNo || "Select Employee Id"}
                      </p>
                    </div>
                  </Col>
                  <Col sm="12">
                    <Row>
                      <Col sm="6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Remarks:
                          </h4>
                          <textarea
                            name="remarks"
                            placeholder="Leave a comment here..."
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className="border-fill w-100"
                            rows={6}
                            style={{ resize: "none" }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12">
                    <div className="emp-detail-print text-end">
                      <button
                        className="btn primary-button w-25 mt-4"
                        onClick={handlePrint}
                      >
                        Print
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default AcknowledgementComponent;
