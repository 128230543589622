import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Common, LoginIcons, SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
import * as Yup from "yup";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setLoading,
  setShowPasswordUpdateModal,
  setVisibleOldPassword,
  setVisiblePassword,
  setVisibleRepeatPassword,
} from "../../store/slices/settingsSlice";
import { LoaderWhite } from "../Lotties";
// API
import {useChangePasswordMutation} from "../../services/Settings"
import {ToastErrorComponent, ToastSuccessComponent} from "../Toasts";

interface Props {
  show?: boolean;
  setShow?: any;
}

interface FormProps{
    password: string;
    oldPassword: string;
    confirmPassword: string;
}

const ChangePasswordModal = ({ show, setShow }: Props) => {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const accessToken = useAppSelector((state) => state.common.accessToken);
    const userID = useAppSelector((state) => state.common.userID);
  const [changePassword, {isLoading}] = useChangePasswordMutation();
  // Initial Values
    const initialValues: FormProps = {
        password: "",
        oldPassword: "",
        confirmPassword: "",
    };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character"
        ),
    oldPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
  });
  // Form Submit Function
  const handleSubmit = async (values: FormProps) => {
    const data = {
        accessToken: accessToken || "",
        userID: userID || "",
        payload: {
            password: values.oldPassword,
            new_password: values.password,
        },
        };
    try {
      // Initiate the mutation
      await changePassword(data).unwrap();
      dispatch(setShowPasswordUpdateModal(false));
      setShowError(false)
      setShowSuccess(true);
    } catch (err: any) {
      // Handle error
      if(err.data){
        dispatch(setLoading(false));
        setShowSuccess(false);
        setShowError(true);
        setErrorContent(err.data?.message);
      }
    }
  };

  return (
      <>
        <ToastErrorComponent show={showError} setShow={setShowError} content={errorContent} />
        <ToastSuccessComponent show={showSuccess} setShow={setShowSuccess} content="Successfully Updated!" />
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            className="general-modal"
        >
          <Modal.Body className="text-center px-5">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="sub-heading normal-font-weight">Update Password</h4>
              <button
                  className="btn p-0 border-0"
                  onClick={() => dispatch(setShow(false))}
              >
                <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
              </button>
            </div>
            <div className="change-password">
              <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit} className="text-end">
                      <Form.Group className="mb-4 text-start">
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <img src={LoginIcons.Password} alt="message" />
                          <Form.Control
                              type={state.visibleOldPasswords ? "text" : "password"}
                              name="oldPassword"
                              onChange={handleChange}
                              placeholder="Enter old password"
                              onBlur={handleBlur}
                              value={values.oldPassword}
                              className={
                                touched.oldPassword && errors.oldPassword
                                    ? "is-invalid custom-input"
                                    : "custom-input"
                              }
                          />
                          <button
                              className="btn p-0 border-0 show-password-icon"
                              onClick={() =>
                                  dispatch(
                                      setVisibleOldPassword(!state.visibleOldPasswords)
                                  )
                              }
                              type="button"
                          >
                            {!state.visibleOldPasswords ? (
                                <img src={Common.EyeClose} alt="eye-open" />
                            ) : (
                                <img src={Common.EyeOpen} alt="eye-open" />
                            )}
                          </button>
                        </Form.Group>
                        {state.oldPasswordError ? (
                            <div className="invalid-feedback">
                              {state.oldPasswordError}
                            </div>
                        ) : (
                            <>
                              {touched.oldPassword && errors.oldPassword ? (
                                  <div className="invalid-feedback">
                                    {errors.oldPassword}
                                  </div>
                              ) : null}
                            </>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-4 text-start">
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <img src={LoginIcons.Password} alt="message" />
                          <Form.Control
                              type={state.visiblePasswords ? "text" : "password"}
                              name="password"
                              onChange={handleChange}
                              placeholder="Enter new password"
                              onBlur={handleBlur}
                              value={values.password}
                              className={
                                touched.password && errors.password
                                    ? "is-invalid custom-input"
                                    : "custom-input"
                              }
                          />
                          <button
                              className="btn p-0 border-0 show-password-icon"
                              onClick={() =>
                                  dispatch(setVisiblePassword(!state.visiblePasswords))
                              }
                              type="button"
                          >
                            {!state.visiblePasswords ? (
                                <img src={Common.EyeClose} alt="eye-open" />
                            ) : (
                                <img src={Common.EyeOpen} alt="eye-open" />
                            )}
                          </button>
                        </Form.Group>
                        {state.passwordError ? (
                            <div className="invalid-feedback">
                              {state.passwordError}
                            </div>
                        ) : (
                            <>
                              {touched.password && errors.password ? (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                              ) : null}
                            </>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-4 text-start">
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <img src={LoginIcons.Password} alt="message" />
                          <Form.Control
                              type={state.visibleRepeatPasswords ? "text" : "password"}
                              name="confirmPassword"
                              onChange={handleChange}
                              placeholder="Re-enter password"
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              className={
                                touched.confirmPassword && errors.confirmPassword
                                    ? "is-invalid custom-input"
                                    : "custom-input"
                              }
                          />
                          <button
                              className="btn p-0 border-0 show-password-icon"
                              onClick={() =>
                                  dispatch(
                                      setVisibleRepeatPassword(
                                          !state.visibleRepeatPasswords
                                      )
                                  )
                              }
                              type="button"
                          >
                            {!state.visibleRepeatPasswords ? (
                                <img src={Common.EyeClose} alt="eye-open" />
                            ) : (
                                <img src={Common.EyeOpen} alt="eye-open" />
                            )}
                          </button>
                        </Form.Group>

                        {touched.confirmPassword && errors.confirmPassword ? (
                            <div className="invalid-feedback">
                              {errors.confirmPassword}
                            </div>
                        ) : null}
                      </Form.Group>
                      <button className="btn primary-button text-end" type="submit">
                        {isLoading ? (
                            <LoaderWhite height={30} width={30} />
                        ) : (
                            "Update"
                        )}
                      </button>
                    </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </>
  );
};

export default ChangePasswordModal;
