import React, {useState} from "react";
// Package Imports
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// Redux Toolkit Imports
import {  useAppSelector } from "../../store/hooks";
// Files Imports
import { Common } from "../../assets/images";
import CommonBGLayout from "../CommonBGLayout";
import AlreadyCompletedProfileModal from "../Modals/already-completed-profile-modal";
import CompletedProfileModal from "../Modals/profile-completed-modal";
import UserIdentity from "./user-identity";
import UserInfo from "./user-info";
import UserPassword from "./user-password";
// API
import {useCompleteProfileMutation} from "../../services/Authentication"

interface FormProps{
    profileImage: string;
    firstName: string;
    lastName: string;
    email: string;
    setPassword: string;
    retypePassword: string;
    role: string;
    department: string;
    phNo: string;
    dob: Date;
    terms: boolean;
    location: string;
}
const ProfileCompleteionComponent = () => {
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
  const { userIdentity, userInfo, userPassword, countryCode, country } = useAppSelector(
    (state) => state.authentication
  );
    const [completeProfile, { isLoading }] = useCompleteProfileMutation();
  const navigate = useNavigate();
  const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get("email");
    const role = query.get("role");
    const department = query.get("department");
  // Initial Values
    const initialValues: FormProps = {
        firstName: "",
        lastName: "",
        email: email ? email : "",
        setPassword: "",
        retypePassword: "",
        role: role ? role : "",
        department: department ? department : "",
        phNo: "",
        dob: new Date(),
        terms: false,
        location: countryCode,
        profileImage: ""
    }

    // Validation Schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dob: Yup.date()
      .required("Date of birth is required")
      .test("age", "You must be at least 18 years old", function (value) {
        const currentDate = new Date();
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
        return value <= eighteenYearsAgo;
      }),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    setPassword: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character"
        ),
    retypePassword: Yup.string()
      .oneOf([Yup.ref("setPassword"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
    terms: Yup.boolean()
      .oneOf([true], "Please agree to terms and conditions")
      .required("Please agree to terms and conditions"),
    phNo: Yup.string()
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Phone no required"),
    location: Yup.string().required("Select country"),
  });

    // Form Submit Function
    const handleSubmit = async (values: FormProps) => {
        try {
            const data = {
                img: values.profileImage,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.setPassword,
                phone: countryCode + " " + values.phNo,
                dateOfBirth: values.dob,
                country: country,
            }
            await completeProfile(data).unwrap();
            setShow(false)
            setShowSuccess(true)
            navigate("/login");
        } catch (err: any) {
            if(err.data.data.alreadyCompleted){
                setShowSuccess(false);
setShow(true);
            }
        }

    };
  return (
    <CommonBGLayout>
        <CompletedProfileModal
            show={showSuccess}
            setShow={setShowSuccess}
        />
        <AlreadyCompletedProfileModal show={show} setShow={setShow} />
      <div className="profile-completion">
        <Container>
          <Row>
            <Col>
              <div className="profile-completion-card mx-auto">
                <div className="profile-completion-card-header text-center mb-4">
                  <img
                    src={Common.Logo}
                    width="130px"
                    height="101px"
                    alt="logo"
                  />
                  <h3 className="mt-2 mb-0">Complete Registration</h3>
                  <p className="para-big mb-0">Welcome to Dashboard</p>
                </div>
                <div className="profile-completion-card-body text-start">
                  <div className="common-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          {userIdentity ? (
                            <UserIdentity
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                            />
                          ) : userInfo ? (
                            <UserInfo
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                            />
                          ) : userPassword ? (
                            <UserPassword
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleSubmit={handleSubmit}
                              isLoading={isLoading}
                            />
                          ) : null}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CommonBGLayout>
  );
};

export default ProfileCompleteionComponent;
