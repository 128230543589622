import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Camps
import { useGetPayslipsQuery } from "../../../services/Employees";
import {
  setPayslips,
  setPayslipError,
  setPayslipLoading,
  setPayslipTotalRecords,
} from "../../../store/slices/employeeSlice";

interface Props {
  empId: number;
}

const Content = ({ empId }: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  // States
  const state = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allPayslipIds,
    isLoading: payslipIdsLoading,
    isError: payslipIdsError,
    refetch: payslipIdsRefetch,
  } = useGetPayslipsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    empID: empId.toString(),
    fromDate: new Date(state.payStartFrom).toLocaleDateString(),
    toDate: new Date(state.payEndTo).toLocaleDateString(),
    pageNo: state.payslipPageNo,
    pageSize: state.payslipPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (payslipIdsLoading) {
      dispatch(setPayslipLoading(true));
    }
    if (payslipIdsError) {
      dispatch(setPayslipError("Something went wrong"));
      dispatch(setPayslipLoading(false));
    }
    if (allPayslipIds) {
      dispatch(setPayslips(allPayslipIds.data.empPayrolls[0].data || []));
      dispatch(
        setPayslipTotalRecords(
          allPayslipIds.data.empPayrolls[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setPayslipLoading(false));
    }
  }, [
    accessToken,
    userID,
    allPayslipIds,
    profileData,
    empId,
    state.payStartFrom,
    state.payEndTo,
    state.payslipPageNo,
    state.payslipPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    payslipIdsRefetch();
  }, [
    employeeChange,
    empId,
    state.payStartFrom,
    state.payEndTo,
    state.payslipPageNo,
    state.payslipPageSize,
  ]);
  return <></>;
};
export default Content;
