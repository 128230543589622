import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddLegalLoading } from "../../store/slices/iqamaSlice";
import { setLegalChange } from "../../store/slices/commonSlice";
// API
import { useAddLegalMutation } from "../../services/Iqama";
import { ToastErrorComponent } from "../Toasts";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import { useUploadImageMutation } from "../../services/images-upload-service";

export interface AddLegals {
  empId: string;
  empName: string;
  category: string;
  eligibility: string;
  validFrom: string;
  validTo: string;
  cost: string;
  otherCategory: string;
  status: string;
  remarks: string;
  clientName: string;
  clientMol: string;
  idNo: string;
  updatedOn: {
    updationDate: string;
    cost: number;
    attachemnt: string;
    updaterId: number;
    updaterName: string;
    remarks: string;
    _id: string;
  }[];
}

const initialValues: AddLegals = {
  empId: "",
  empName: "",
  category: "",
  eligibility: "",
  validFrom: "",
  otherCategory: "",
  validTo: "",
  cost: "",
  status: "",
  remarks: "",
  clientName: "",
  clientMol: "",
  idNo: "",
  updatedOn: [],
};

const AddLegal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState("");
  const [empID, setEmpID] = useState("");
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.iqama);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const legalChange = useAppSelector((state) => state.common.legalChange);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const [category, setCategory] = useState("");

  // Options
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  const selectEmployee = employees.find(
    (employee) => employee.empId === parseInt(empID),
  );

  const project = projectIds.find(
    (project) => project.projectId === selectEmployee?.empProjectId,
  );

  const [addLegal] = useAddLegalMutation();
  const handleSubmit = async (values: AddLegals) => {
    dispatch(setAddLegalLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addLegal || false,
        payload: {
          legal:
            category === "Ajeer"
              ? {
                  empId: parseInt(empID),
                  empName: selectEmployee?.empName,
                  category: category,
                  eligibility: values.eligibility,
                  validFrom: values.validFrom,
                  validTo: values.validTo,
                  cost: parseInt(values.cost),
                  clientName: values.clientName,
                  clientMOL: values.clientMol,
                  idNumber: values.idNo,
                  status: values.status,
                  link: `/legal`,
                  updatedOn: [
                    {
                      updationDate: new Date().toLocaleDateString(),
                      cost: parseInt(values.cost),
                      attachemnt: imageUploaded || "",
                      updaterId: profileData.employeeId,
                      updaterName:
                        profileData.firstName + " " + profileData.lastName,
                      remarks: values.remarks,
                    },
                  ],
                }
              : {
                  empId: parseInt(empID),
                  empName: selectEmployee?.empName,
                  category:
                    category === "Other" ? values.otherCategory : category,
                  eligibility: values.eligibility,
                  validFrom: values.validFrom,
                  validTo: values.validTo,
                  cost: parseInt(values.cost),
                  status: values.status,
                  link: `/legal`,
                  updatedOn: [
                    {
                      updationDate: new Date().toLocaleDateString(),
                      cost: parseInt(values.cost),
                      attachemnt: imageUploaded || "",
                      updaterId: profileData.employeeId,
                      updaterName:
                        profileData.firstName + " " + profileData.lastName,
                      remarks: values.remarks,
                    },
                  ],
                },
        },
      };
      await addLegal(data).unwrap();
      dispatch(setLegalChange(!legalChange));
      navigate("/legal");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddLegalLoading(false));
    }
  };

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (category === "Other") {
      setShowOtherCategory(true);
    } else {
      setShowOtherCategory(false);
    }
  }, [category]);

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Add Legal </h1>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Select Employee by Id
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center mb-5">
              <SelectSearch
                options={options}
                value={empID}
                onChange={(e: any) => setEmpID(e)}
                search={true}
                placeholder="Select Employee"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Emp Name
                      </h4>
                      <Form.Control
                        type="text"
                        name="empName"
                        value={selectEmployee?.empName || "Select Emp Id."}
                        placeholder="Enter new salary"
                        disabled={true}
                        className="custom-input disabled-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Category
                      </h4>
                      <Form.Select
                        name="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Category</option>
                        <option value="Driver Card">Driver Card</option>
                        <option value="Municipality card">
                          Municipality card
                        </option>
                        <option value="Ajeer">Ajeer</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {showOtherCategory && (
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Other Category
                        </h4>
                        <Form.Control
                          type="text"
                          name="otherCategory"
                          placeholder="Enter other category name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.otherCategory}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Eligibility
                      </h4>
                      <Form.Select
                        name="eligibility"
                        value={values.eligibility}
                        onChange={handleChange}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Eligibility</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Cost
                      </h4>
                      <Form.Control
                        type="text"
                        name="cost"
                        placeholder="Enter cost"
                        onChange={(e) => {
                          //     accept only numbers
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        value={values.cost}
                        required={true}
                        className="custom-input border-fill"
                      />
                      {touched.cost && errors.cost ? (
                        <div className="invalid-feedback">{errors.cost}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  {category === "Ajeer" && (
                    <>
                      <Col sm="4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Client Name
                          </h4>
                          <Form.Control
                            type="text"
                            name="empName"
                            value={project?.clientName || "Select Emp Id."}
                            placeholder="Enter new salary"
                            disabled={true}
                            className="custom-input disabled-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Client MOL
                          </h4>
                          <Form.Control
                            type="text"
                            name="empName"
                            value={project?.clientMOL || "Select Emp Id."}
                            placeholder="Enter new salary"
                            disabled={true}
                            className="custom-input disabled-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            ID Number
                          </h4>
                          <Form.Control
                            type="text"
                            name="empName"
                            value={selectEmployee?.empIdNo || "Select Emp Id."}
                            placeholder="Enter new salary"
                            disabled={true}
                            className="custom-input disabled-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Valid From
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="validFrom"
                          selected={
                            values.validFrom ? new Date(values.validFrom) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: {
                                name: "validFrom",
                                value: date,
                              },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Contract From Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Valid To
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="validTo"
                          selected={
                            values.validTo ? new Date(values.validTo) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: {
                                name: "validTo",
                                value: date,
                              },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Contract To Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Status
                      </h4>
                      <Form.Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Status</option>
                        <option value="Valid">Valid</option>
                        <option value="Expired">Expired</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group>
                      <div className="d-flex align-items-center mb-2">
                        <h4
                          className="sub-heading normal-font-weight textBlack mb-0 me-2"
                          style={{ fontSize: "18px" }}
                        >
                          Attachment
                        </h4>
                        {imageUploaded ? (
                          <label className="d-flex align-items-center justify-content-center cursor-pointer">
                            <input
                              type="file"
                              name="profileImage"
                              onChange={handleUploadImage}
                              accept=".png, .jpg, .jpeg"
                              className="d-none"
                              multiple={false}
                            />
                            <div className="edit-icon d-flex align-items-center justify-content-center">
                              <img
                                src={SettingsIcons.EditIcon}
                                width="18px"
                                height="18px"
                                alt="edit"
                              />
                            </div>
                          </label>
                        ) : null}
                      </div>
                      {imageLoading ? (
                        <LoaderBlue height={50} width={50} />
                      ) : (
                        <>
                          {imageUploaded ? (
                            <img
                              src={Common.FileIcon}
                              style={{
                                width: "242px",
                                height: "242px",
                                objectFit: "contain",
                              }}
                              alt="employee-image"
                            />
                          ) : (
                            <Form.Label className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center">
                              <Form.Control
                                type="file"
                                name="profileImage"
                                onChange={handleUploadImage}
                                accept=".png, .jpg, .jpeg"
                                className="d-none"
                                multiple={false}
                              />
                              <div className="text-center">
                                <div className="add-profile-image d-flex align-items-center justify-content-center mx-auto">
                                  <img src={EmployeeIcons.Plus} alt="plus" />
                                </div>
                                <p
                                  className="upload-para mt-2 mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  Upload PNG & JPG File only
                                </p>
                                <p className="upload-para mt-0 mb-0">
                                  Max file size is 5MB
                                </p>
                              </div>
                            </Form.Label>
                          )}
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Remarks
                      </h4>
                      <Form.Control
                        type="text"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        placeholder="Enter remarks"
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.addLegalLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Add Legal"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddLegal;
