import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
// Redux actions
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setAddProbationLoading } from "../../store/slices/employeeSlice";
import { setProbationChange } from "../../store/slices/commonSlice";
// API Imoprts
import { useEditProbationEmployeeMutation } from "../../services/Employees";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

const ProbationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const probationData = location.state.data;
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState(probationData.empId || "");
  const [probationFrom, setProbationFrom] = React.useState(
    probationData.probationFrom || "",
  );
  const [probationTo, setProbationTo] = React.useState(
    probationData.probationTo || "",
  );
  const [probationDuration, setProbationDuration] = React.useState(
    probationData.probationDuration || "",
  );
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const probationChange = useAppSelector(
    (state) => state.common.probationChange,
  );

  // API call for add salary increment
  const [editProbationEmployee] = useEditProbationEmployeeMutation();
  const handleSubmit = async () => {
    dispatch(setAddProbationLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          empId: empID,
          probationFrom: probationFrom,
          probationTo: probationTo,
          probationDuration: parseInt(probationDuration),
          link: `/employees/probation-employees`,
        },
      };
      await editProbationEmployee(data).unwrap();
      dispatch(setProbationChange(!probationChange));
      navigate("/employees/probation-employees");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddProbationLoading(false));
    }
  };

  React.useEffect(() => {
    if (probationFrom && probationTo) {
      const date1 = new Date(probationFrom);
      const date2 = new Date(probationTo);
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setProbationDuration(diffDays.toString());
    }
  }, [probationFrom, probationTo]);

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Edit Probation Form</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Probation Details</h4>
            <Row>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation From
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveFrom"
                    placeholder="Enter increment from"
                    onChange={(e) => setProbationFrom(e.target.value)}
                    value={probationFrom}
                    required={true}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation To
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveTo"
                    placeholder="Enter increment to"
                    onChange={(e) => setProbationTo(e.target.value)}
                    value={probationTo}
                    required={true}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Probation Duration
                  </h4>
                  <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                    <Form.Control
                      type="text"
                      name="probation"
                      className="custom-input"
                      disabled={true}
                      value={probationDuration}
                    />
                    <span className="text-white">Days</span>
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addProbationLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ProbationForm;
