import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Common, NotificationsIcons } from "../../assets/images";
import TabComponent from "../TabComponent";
// Redux import
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setNotificationPageSize } from "../../store/slices/notificationsSlice";
import { LoaderBlue } from "../Lotties";
import { setNotificationChange } from "../../store/slices/commonSlice";
// API imports
import {
  useDeleteNotificationMutation,
  useReadNotificationMutation,
} from "../../services/Notifications";

const NotificationsList = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.notification);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const [readLoadingMap, setReadLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const notificationChange = useAppSelector(
    (state) => state.common.notificationChange,
  );
  // Edit Notification
  const [readNotification] = useReadNotificationMutation();

  const handleRead = async (empId: string) => {
    setReadLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: true,
        payload: {
          notificationId: empId,
        },
      };
      await readNotification(data).unwrap();
      dispatch(setNotificationChange(!notificationChange));
    } catch (e) {
      console.log(e);
    } finally {
      setReadLoadingMap((prevMap) => ({ ...prevMap, [empId]: false }));
    }
  };
  // Delete Notification
  const [deleteNotification] = useDeleteNotificationMutation();

  const handleDelete = async (empId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: true,
        payload: {
          notificationId: empId,
        },
      };
      await deleteNotification(data).unwrap();
      dispatch(setNotificationChange(!notificationChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: false }));
    }
  };
  return (
    <div className="notifications mt-2">
      <Container fluid>
        <Row>
          <Col
            xxl="8"
            xl="10"
            lg="12"
            md="12"
            sm="12"
            className="mx-auto section"
          >
            <div className="notification-card">
              <div className="notification-list mt-4">
                <ul>
                  {state.notificationsLoading ? (
                    <LoaderBlue height={60} width={60} />
                  ) : (
                    <>
                      {state.notifications.length > 0 ? (
                        <>
                          {state.notifications.map((notification, index) => (
                            <li key={index}>
                              <div
                                className={
                                  notification.status === "Un-read"
                                    ? "notification-summary-list-item unread-notification d-flex"
                                    : "notification-summary-list-item d-flex"
                                }
                              >
                                <div className="notification-summary-list-item-content">
                                  <h5
                                    className="mb-0 text-decoration-none"
                                    style={{
                                      width: "fit-content",
                                      color: "#5E657B",
                                    }}
                                  >
                                    {notification.title}
                                  </h5>
                                  <p className="service-name mb-0 text-decoration-none">
                                    {notification.message}
                                  </p>
                                </div>
                                <div className="notification-time text-end ms-auto">
                                  <div className="mb-1 d-flex align-items-end justify-content-end">
                                    {readLoadingMap[notification._id] ? (
                                      <LoaderBlue height={20} width={20} />
                                    ) : (
                                      <>
                                        {notification.status === "Un-read" ? (
                                          <button
                                            className="btn p-0 border-0"
                                            onClick={() =>
                                              handleRead(notification._id)
                                            }
                                          >
                                            <img
                                              src={NotificationsIcons.Unreaded}
                                              className="me-2"
                                              alt="status"
                                            />
                                          </button>
                                        ) : (
                                          <img
                                            src={NotificationsIcons.Readed}
                                            className="me-2"
                                            alt="status"
                                          />
                                        )}
                                      </>
                                    )}
                                    {deleteLoadingMap[notification._id] ? (
                                      <LoaderBlue height={20} width={20} />
                                    ) : (
                                      <button
                                        className="btn p-0 border-0 bg-transparent"
                                        onClick={() =>
                                          handleDelete(notification._id)
                                        }
                                      >
                                        <img
                                          src={NotificationsIcons.Delete}
                                          alt="delete"
                                        />
                                      </button>
                                    )}
                                  </div>
                                  <p className="mt-1 mb-0 text-capitalize">
                                    {notification.createdAt}
                                  </p>
                                </div>
                              </div>
                              <hr />
                            </li>
                          ))}
                          {state.totalNotifications >
                            state.notificationPageSize && (
                            <div className="text-center mt-2">
                              <button
                                className="btn p-0 border-0 sub-heading text-decoration-underline"
                                onClick={() =>
                                  dispatch(
                                    setNotificationPageSize(
                                      state.notificationPageSize + 10,
                                    ),
                                  )
                                }
                              >
                                See more notifications
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="text-center mt-5">
                          <h1 className="heading">No Notifications Found</h1>
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationsList;
