import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { Owner } from "../../interfaces/sponsor";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterOwnerTotalPages,
  setOwnerListLoading,
} from "../../store/slices/ownersSlice";
// API imports
import { useGetFilterOwnerListMutation } from "../../services/Sponsor";

interface Props {
  show?: boolean;
  setShow?: any;
  setOwners?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  owners: Owner[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  iqamaExpiryFrom: string;
  iqamaExpiryTo: string;
  ownerType: string;
  ownerStatus: string;
}

const OwnerFilterModal = ({
  show,
  setShow,
  owners,
  setOwners,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.owners);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterOwnerList] = useGetFilterOwnerListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setOwnerListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterOwnerPageNo,
          limit: state.filterOwnerPageSize,
          iqamaExpiryFrom: filterInput.iqamaExpiryFrom,
          iqamaExpiryTo: filterInput.iqamaExpiryTo,
          ownerType: filterInput.ownerType,
          ownerStatus: filterInput.ownerStatus,
        },
      };
      const response = await getFilterOwnerList(data).unwrap();
      if (response) {
        setOwners(response.data.owners[0].data);
        dispatch(
          setFilterOwnerTotalPages(response.data.owners[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setOwnerListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setOwnerListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterOwnerPageNo,
            limit: state.filterOwnerPageSize,
            ownerId: query,
            iqamaExpiryFrom: filterInput.iqamaExpiryFrom,
            iqamaExpiryTo: filterInput.iqamaExpiryTo,
            ownerType: filterInput.ownerType,
            ownerStatus: filterInput.ownerStatus,
          },
        };
        const response = await getFilterOwnerList(data).unwrap();
        if (response) {
          setOwners(response.data.owners[0].data);
          dispatch(
            setFilterOwnerTotalPages(response.data.owners[0].metadata[0].total),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setOwnerListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterOwnerPageNo, state.filterOwnerPageSize, query]);

  const handleReset = () => {
    setOwners(owners);
    setFilterInput({
      iqamaExpiryFrom: "",
      iqamaExpiryTo: "",
      ownerType: "",
      ownerStatus: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Employee List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Employee Status
                    </h4>
                    <Form.Select
                      name="ownerStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          ownerStatus: e.target.value,
                        })
                      }
                      value={filterInput.ownerStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="In-active">In-active</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OwnerFilterModal;
