import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Timesheet
import { useGetProjectedTimeQuery } from "../../services/Projects";
import {
  setActualSalarySheetTotalRecords,
  setActualTimesheet,
  setProjectTimesheetError,
  setProjectTimesheetLoading,
} from "../../store/slices/projectSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const state = useAppSelector((state) => state.project);

  const actualSheetChange = useAppSelector(
    (state) => state.common.actualSheetChange,
  );

  const attendanceChange = useAppSelector(
    (state) => state.common.attendanceChange,
  );

  // **************
  // **************
  // Get Projected Timesheet Data
  // **************
  // **************
  const {
    data: projectedTimesheet,
    isLoading: projectedTimesheetLoading,
    isError: projectedTimesheetError,
    refetch: projectedTimesheetRefetch,
  } = useGetProjectedTimeQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    projectId: state.actualSalarySheet,
    page: state.actualSalarySheetPageNo,
    limit: state.actualSalarySheetPageSize,
    date: new Date(state.actualSheetDate).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (projectedTimesheetLoading) {
      dispatch(setProjectTimesheetLoading(true));
    }
    if (projectedTimesheetError) {
      dispatch(setProjectTimesheetError("Something went wrong"));
      dispatch(setProjectTimesheetLoading(false));
    }
    if (projectedTimesheet) {
      dispatch(
        setActualTimesheet(
          projectedTimesheet.data.salarySheetData[0].data || [],
        ),
      );
      dispatch(
        setActualSalarySheetTotalRecords(
          projectedTimesheet.data.salarySheetData[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setProjectTimesheetLoading(false));
    }
  }, [
    accessToken,
    userID,
    projectedTimesheet,
    profileData,
    state.actualSalarySheetPageNo,
    state.actualSalarySheetPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    projectedTimesheetRefetch();
  }, [state.actualSalarySheet, actualSheetChange, attendanceChange]);
  return <></>;
};
export default Content;
