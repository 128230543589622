import React from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";

interface Props {
  children?: React.ReactNode;
}

const SidebarContainer = ({ children }: Props) => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div
        style={{
          flex: 1,
          width: "calc(100% - 300px)",
        }}
        className="content-container"
      >
        <Navbar />
        <div
          style={{
            padding: "40px 28px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarContainer;
