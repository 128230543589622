import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setProjectedSalarySheetPageNo,
  setProjectedSalarySheetPageSize,
  setProjectedSalarySheet,
  setShowProjectedSalarySheetExportDropdown,
  setProjectedSheetDate,
  setProjectedType,
  setActualSalarySheet,
  setActualType,
} from "../../store/slices/projectSlice";
import SelectSearch from "react-select-search";
import Content from "./get-projected-timesheet-data";
import { Timesheet } from "../../interfaces/timesheet";
import { exportToCsv } from "../../config";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
import { ToastErrorComponent } from "../Toasts";

interface DayAccumulator {
  [key: string]: string | number;
}

const SalarySheet = () => {
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.employee);
  const projectState = useAppSelector((state) => state.project);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const getlastDayOfMonth = () => {
    const actualSheetDate = new Date(projectState.projectedSheetDate);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const actualYear = actualSheetDate.getFullYear();
    const actualMonth = actualSheetDate.getMonth();

    if (actualYear === currentYear && actualMonth === currentMonth) {
      // If the actualSheetDate is within the current month, return the current date
      return new Date();
    } else {
      // If the actualSheetDate is not in the current month, calculate the last date of the month
      return new Date(actualYear, actualMonth + 1, 0);
    }
  };
  const currentDate = getlastDayOfMonth();
  const monthYearString = new Date(
    projectState.projectedSheetDate,
  ).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
  const profileData = useAppSelector((state) => state.settings.profileData);
  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<Timesheet[]>([]);
  const totalPages = Math.ceil(
    projectState.projectedSalarySheetTotalRecords /
      projectState.projectedSalarySheetPageSize,
  );
  const totalCount = projectState.projectedSalarySheetTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((projectState.projectedSalarySheetPageNo - 1) / showPages) *
    showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (projectState.projectedSalarySheetPageNo > totalPages - showPages) {
      return;
    }
    dispatch(
      setProjectedSalarySheetPageNo(
        projectState.projectedSalarySheetPageNo + showPages,
      ),
    );
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (projectState.projectedSalarySheetPageNo <= 5) {
      return;
    }
    dispatch(
      setProjectedSalarySheetPageNo(
        projectState.projectedSalarySheetPageNo - showPages,
      ),
    );
  };

  const handlePageChange = (page: any) => {
    dispatch(setProjectedSalarySheetPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setProjectedSalarySheetPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(projectState.projectTimesheet);
  }, [projectState.projectTimesheet]);

  const generateDaysOfWeek = () => {
    const daysInMonth = currentDate.getDate();
    const daysOfWeek = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day,
      );
      const dayOfMonth = date.getDate();
      const dayOfWeek = date.toLocaleString("default", { weekday: "short" });

      daysOfWeek.push(
        <React.Fragment key={day}>
          {dayOfMonth}
          <br />
          {dayOfWeek}
        </React.Fragment>,
      );
    }
    return daysOfWeek;
  };

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Emp Name",
      "Category",
      "Basic Salary",
      "Project Id.",
      ...generateDaysOfWeek(),
      "Total Hours",
      "Total Overtime",
      "Gross Salary",
    ],
    tableData: filteredData,
  };
  const projectOptions = projectState.projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  // Add custom options for "management" and "idol employee"
  projectOptions.splice(0, 0, { name: "All", value: "All" });
  profileData.permissions.roleName === "Super Admin" &&
    projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  profileData.permissions.roleName === "General Manager" &&
    projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  projectOptions.splice(2, 0, { name: "Idol Employees", value: "Idol" });

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const employeeOptions = state.allEmployeesList.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  // Calculate current month days
  const d = projectState.projectedSheetDate
    ? new Date(projectState.projectedSheetDate)
    : new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const totalDaysInMonth = new Date(year, month, 0).getDate();

  const basicSalaryTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.salaryTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.projectedSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.basicSalary;
    } else {
      return mEmployee ? mEmployee.basicSalary : 0;
    }
  };

  const dutyHoursTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.salaryTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.projectedSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.dutyHours;
    } else {
      return mEmployee ? mEmployee.dutyHours : 0;
    }
  };

  const projectIdTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.transferTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.projectedSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      const project = projectState.projectIds.find(
        (item) => item.projectId === timeLine.projectId,
      );
      return project?.projectName;
    } else {
      const project = projectState.projectIds.find(
        (item) => item.projectId === mEmployee?.empProjectId,
      );
      return mEmployee ? project?.projectName : "";
    }
  };

  const categoryTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.transferTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.projectedSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.category;
    } else {
      return mEmployee ? mEmployee.empProjectCategory : "";
    }
  };

  const renderTableData = () => {
    const daysInMonth = currentDate.getDate();
    const daysOfMonth = Array.from(
      { length: daysInMonth },
      (_, index) => index + 1,
    );
    return tableData.tableData.map((item, index) => {
      const rowData = daysOfMonth.map((dayOfMonth, dayIndex) => {
        const attendance = item.attendances.find(
          (attendance) => new Date(attendance.date).getDate() === dayOfMonth,
        );
        const date = attendance
          ? new Date(attendance.date).toLocaleString("default", {
              weekday: "short",
            })
          : "";
        const fridayHours = attendance
          ? attendance.hours - dutyHoursTimelineFunction(item.empId)
          : "-";
        // If friday hours is 0, then show '-'
        const fridayHoursZero = fridayHours === 0 ? "-" : fridayHours;
        const hours = attendance
          ? date === "Fri"
            ? fridayHoursZero
            : attendance.hours
          : "-"; // Default to 8 if date is missing
        return <td key={dayIndex}>{hours}</td>;
      });

      const totalHours = item.attendances.reduce((acc, curr) => {
        return acc + curr.hours;
      }, 0);

      const totalOvertime = item.attendances.reduce((acc, curr) => {
        return acc + curr.overTime;
      }, 0);

      const totalSalary = item.attendances.map((attendance) => {
        const salaryCalculation = (hours: number) => {
          const salary = basicSalaryTimelineFunction(item.empId);
          const perHourSalary =
            salary / totalDaysInMonth / dutyHoursTimelineFunction(item.empId);
          return perHourSalary * hours;
        };

        return salaryCalculation(attendance.hours);
      });

      const grossSalary = totalSalary.reduce((acc, curr) => {
        return acc + curr;
      }, 0);

      return (
        <tr key={index} style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}>
          <td>
            {totalCount > 0
              ? (projectState.projectedSalarySheetPageNo - 1) *
                  projectState.projectedSalarySheetPageSize +
                index +
                1
              : index + 1}
          </td>
          <td>{item.empId}</td>
          <td>{print ? twoWords(item.empName) : item.empName}</td>
          <td>{categoryTimelineFunction(item.empId)}</td>
          <td>{basicSalaryTimelineFunction(item.empId)}</td>
          <td>{projectIdTimelineFunction(item.empId)}</td>
          {rowData}
          {/* Add Total working hours of an employee */}
          <td>{totalHours - totalOvertime}</td>
          <td>{totalOvertime}</td>
          <td>{grossSalary.toFixed(2)}</td>
        </tr>
      );
    });
  };

  const tableFooterData = () => {
    // Sum of all employess working hours of all days
    const totalHoursWorked = filteredData.reduce((total, employee) => {
      // Iterate through each attendance record of the employee and sum up the working hours
      const employeeHours = employee.attendances.reduce((acc, attendance) => {
        return acc + attendance.hours;
      }, 0);
      // Add the total working hours of the employee to the total
      return total + employeeHours;
    }, 0);

    const totalOverTime = filteredData.reduce((total, employee) => {
      // Iterate through each attendance record of the employee and sum up the working hours
      const employeeHours = employee.attendances.reduce((acc, attendance) => {
        return acc + attendance.overTime;
      }, 0);
      // Add the total working hours of the employee to the total
      return total + employeeHours;
    }, 0);

    // Sum of all employees salaries
    const totalSalaryOfAnEmployee = filteredData.map((employee) => {
      const totalSalary = employee.attendances.map((attendance) => {
        const salaryCalculation = (hours: number) => {
          const salary = basicSalaryTimelineFunction(employee.empId);
          const perHourSalary =
            salary /
            totalDaysInMonth /
            dutyHoursTimelineFunction(employee.empId);
          return perHourSalary * hours;
        };

        return salaryCalculation(attendance.hours);
      });

      return totalSalary.reduce((acc, curr) => {
        return acc + curr;
      }, 0);
    });

    const totalGrossSalary = totalSalaryOfAnEmployee.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return (
      <tr>
        <td colSpan={6} className="text-end">
          Total
        </td>
        {/* Sum of all employees working hours of one day */}
        {generateDaysOfWeek().map((item, index) => (
          <td key={index}>
            {filteredData.reduce((acc, curr) => {
              const attendance = curr.attendances.find(
                (attendance) =>
                  new Date(attendance.date).getDate() === index + 1,
              );
              return acc + (attendance ? attendance.hours : 0);
            }, 0)}
          </td>
        ))}
        <td>{totalHoursWorked - totalOverTime}</td>
        <td>{totalOverTime}</td>
        <td>{totalGrossSalary.toFixed(2)}</td>
      </tr>
    );
  };

  const daysInMonth = currentDate.getDate();
  const daysOfMonth = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1,
  );

  const csvData = tableData.tableData.map((item, index) => {
    const dayAccumulator: DayAccumulator = {};

    const rowData = daysOfMonth.map((dayOfMonth, dayIndex) => {
      const attendance = item.attendances.find(
        (attendance) => new Date(attendance.date).getDate() === dayOfMonth,
      );

      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        dayOfMonth,
      );
      const dayOfWeek = date.toLocaleString("default", { weekday: "short" });

      const hours = attendance ? attendance.hours : "-"; // Default to 8 if date is missing
      dayAccumulator[`${dayOfMonth + " " + dayOfWeek} `] = hours;
      return hours;
    });

    const totalHours = item.attendances.reduce((acc, curr) => {
      return acc + curr.hours;
    }, 0);

    const totalOvertime = item.attendances.reduce((acc, curr) => {
      return acc + curr.overTime;
    }, 0);

    const totalSalary = item.attendances.map((attendance) => {
      const salaryCalculation = (hours: number) => {
        const salary = basicSalaryTimelineFunction(item.empId);
        const perHourSalary =
          salary / totalDaysInMonth / dutyHoursTimelineFunction(item.empId);
        return perHourSalary * hours;
      };

      return salaryCalculation(attendance.hours);
    });

    const grossSalary = totalSalary.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return {
      "Sr. No.": index + 1,
      "Emp Code": item.empId,
      "Emp Name": item.empName,
      Category: item.empProjectCategory,
      "Total Salary": item.basicSalary,
      "Project Id.": item.empProjectId,
      // Place dayAccumulator here between "Project Id." and "Total Hours"
      ...dayAccumulator,
      "Total Hours": totalHours - totalOvertime,
      "Total Overtime": totalOvertime,
      "Gross Salary": grossSalary.toFixed(2),
    };
  });

  const handleProjectId = (value: any) => {
    const oldArray =
      projectState.projectedType === "All"
        ? []
        : projectState.projectedType === "Management"
          ? []
          : projectState.projectedType === "Idol"
            ? []
            : projectState.projectedType === "Employee"
              ? []
              : projectState.projectedSalarySheet;
    if (value === "All") {
      dispatch(setProjectedSalarySheet([value]));
      dispatch(setProjectedType("All"));
      return;
    }
    if (value === "Management") {
      dispatch(setProjectedSalarySheet([value]));
      dispatch(setProjectedType("Management"));
      return;
    }
    if (value === "Idol") {
      dispatch(setProjectedSalarySheet([value]));
      dispatch(setProjectedType("Idol"));
      return;
    }
    if (projectState.projectedSalarySheet.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Project ID already selected");
      setShow(true);
      return;
    }
    dispatch(setProjectedSalarySheet([...oldArray, value]));
    dispatch(setProjectedType("Project"));
  };

  const handleEmployeeId = (value: any) => {
    const oldArray =
      projectState.projectedType !== "Employee"
        ? []
        : projectState.projectedSalarySheet;
    if (projectState.projectedSalarySheet.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Employee ID already selected");
      setShow(true);
      return;
    }
    dispatch(setProjectedSalarySheet([...oldArray, value]));
    dispatch(setProjectedType("Employee"));
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      {profileData.permissions.showPettyCash ? (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">
                Projected Salary Sheet for the month of {monthYearString}
              </h1>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center gap-4 w-50">
                  <Form.Group className=" w-75" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project ID/Type
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="emp"
                        options={projectOptions}
                        value={projectState.projectedSalarySheet}
                        onChange={(value: any) => handleProjectId(value)}
                        search={true}
                        placeholder="Select Project Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Or
                    </h4>
                  </Form.Group>
                  <Form.Group className=" w-75" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Employee ID
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="emp"
                        options={employeeOptions}
                        value={projectState.projectedSalarySheet}
                        onChange={(value: any) => handleEmployeeId(value)}
                        search={true}
                        placeholder="Select Emp Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <Dropdown
                    show={projectState.showProjectedSalarySheetExportDropdown}
                    className="position-relative"
                  >
                    <button
                      id="dropdown-basic"
                      onClick={() => {
                        dispatch(
                          setShowProjectedSalarySheetExportDropdown(
                            !projectState.showProjectedSalarySheetExportDropdown,
                          ),
                        );
                      }}
                      className=" bg-transparent dropdown-toggle-white"
                    >
                      Export{" "}
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="ms-2"
                        alt="down-arrow"
                      />
                    </button>
                    <Dropdown.Menu
                      className="mt-1 custom-scrollbar"
                      show={projectState.showProjectedSalarySheetExportDropdown}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          exportToCsv(csvData, "projected-revenue-sheet");
                          dispatch(
                            setShowProjectedSalarySheetExportDropdown(false),
                          );
                        }}
                      >
                        CSV
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handlePrinted}>PDF</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="3">
            <Form.Group className="mt-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Select Month
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={
                    projectState.projectedSheetDate
                      ? new Date(projectState.projectedSheetDate)
                      : null
                  }
                  onChange={(e: any) =>
                    dispatch(setProjectedSheetDate(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select start date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
          </Col>
          {projectState.projectedType === "" ? null : (
            <Col sm="12">
              <Form.Group
                className="mt-5 d-flex align-items-center gap-4"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack mb-0"
                  style={{ fontSize: "18px" }}
                >
                  Selected {projectState.projectedType}:
                </h4>

                <div className="d-flex align-items-center gap-2 overflow-auto">
                  {projectState.projectedSalarySheet.map((item, index) => (
                    <div
                      key={index}
                      className="selected-projects primary-button d-flex align-items-center gap-3 py-0 px-4"
                      style={{
                        height: "40px",
                        borderRadius: "20px",
                        boxShadow: "none",
                      }}
                    >
                      <h4 className="mb-0" style={{ fontSize: "16px" }}>
                        {item}
                      </h4>
                      <button
                        className="btn p-0 border-0"
                        onClick={() => {
                          const newProjects =
                            projectState.projectedSalarySheet.filter(
                              (project) => project !== item,
                            );
                          dispatch(setProjectedSalarySheet(newProjects));
                          if (projectState.projectedSalarySheet.length === 1) {
                            dispatch(setProjectedType(""));
                          }
                        }}
                      >
                        <img
                          src={Common.Cross}
                          style={{ width: "22px", height: "22px" }}
                          alt="close"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Col>
          )}
          <Col sm="12">
            <div className="p-3" ref={tableRef}>
              {print && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Common.Vision} width="110px" alt="logo" />
                    </div>
                    <div className="letter-logo text-center gap-2">
                      <img src={Common.LogoSVG} width="80px" alt="logo" />
                      <div className="mt-1">
                        <h3
                          className="logo-text mb-0"
                          style={{
                            color: "#202020",
                            fontSize: "10px",
                            fontWeight: "700",
                          }}
                        >
                          Senyar Arabian Trading Co. LTD
                        </h3>
                        <h3
                          className="logo-text logo-arabic mb-0"
                          style={{
                            color: "#202020",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          شركة سنيار العربية التجارية المحدودة
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h1 className="sub-heading text-center">
                    Projected Salary Sheet
                  </h1>
                  <h1 className="sub-heading text-center mt-0">
                    {monthYearString}
                  </h1>
                </>
              )}
              <Table
                striped
                bordered
                responsive
                className={
                  print ? "mt-4 text-center print-sheet" : "mt-2 text-center"
                }
              >
                <thead>
                  <tr className="border-0">
                    {tableData.tableHeading.map((item, index) => (
                      <th className="border-0" key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
                <tfoot>{tableFooterData()}</tfoot>
              </Table>
            </div>
          </Col>
          {filteredData.length > 0 && (
            <Col sm="12">
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <Form.Group
                  className="d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "14px", width: "230px" }}
                  >
                    Entries per page
                  </h4>
                  <Form.Select
                    name="pageSize"
                    onChange={handlePageSizeChange}
                    value={projectState.projectedSalarySheetPageSize.toString()}
                    className="custom-input border-fill custom-height"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                    <option value="10000">All</option>
                  </Form.Select>
                </Form.Group>
                <div className="pagination-custom">
                  <Pagination className="justify-content-end">
                    <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                      <img src={Common.DoubleArrowLeft} alt="arrow" />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          projectState.projectedSalarySheetPageNo > 1
                            ? projectState.projectedSalarySheetPageNo - 1
                            : 1,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-left"
                        alt="arrow"
                      />
                    </Pagination.Item>

                    {pages.map((page, index) => (
                      <Pagination.Item
                        key={index}
                        onClick={() => handlePageChange(page + 1)}
                        className={
                          page + 1 === projectState.projectedSalarySheetPageNo
                            ? "active-page"
                            : ""
                        }
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          projectState.projectedSalarySheetPageNo < totalPages
                            ? projectState.projectedSalarySheetPageNo + 1
                            : totalPages,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-right"
                        alt="arrow"
                      />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        handleGoToNextPages();
                      }}
                    >
                      <img src={Common.DoubleArrowRight} alt="arrow" />
                    </Pagination.Item>
                  </Pagination>
                </div>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">Unauthorized Access</h1>
        </div>
      )}
    </div>
  );
};
export default SalarySheet;
