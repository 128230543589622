import React from "react";
// Packages Imports
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// Redux Toolkit Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  emailErrorState,
  passwordErrorState,
  visiblePasswordState,
  loginSuccess,
  setRememberMe,
  loadingState,
} from "../../store/slices/authenticationSlice";
// Files Imports
import { LoaderWhite } from "../Lotties";
import CommonBGLayout from "../CommonBGLayout";
import { Common, LoginIcons } from "../../assets/images";
// API
import { useLoginMutation } from "../../services/Authentication";

interface FormProps {
  email: string;
  password: string;
  staySignedIn: boolean;
}

const LoginComponent = () => {
  const dispatch = useAppDispatch();
  const { visiblePasswords, loading, emailError, passwordError, rememberMe } =
    useAppSelector((state) => state.authentication);

  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  // Initial Values
  const initialValues: FormProps = {
    email: "",
    password: "",
    staySignedIn: rememberMe,
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Form Submit Function
  const handleSubmit = async (values: FormProps) => {
    dispatch(loadingState(true));
    dispatch(emailErrorState(""));
    dispatch(passwordErrorState(""));
    try {
      const { email, password, staySignedIn } = values;
      const registrationTokens = localStorage.getItem("firebaseToken") || "";
      const result = await login({
        email,
        password,
        registrationTokens,
      }).unwrap();
      dispatch(loginSuccess(result));
      dispatch(setRememberMe(staySignedIn));
      dispatch(loadingState(false));
      window.location.href = "/dashboard";
    } catch (err: any) {
      dispatch(loadingState(false));
      window.scrollTo(0, 0);
      if (err?.data?.data?.email) {
        dispatch(emailErrorState(err.data.data.email));
      }
      if (err?.data?.data?.password) {
        dispatch(passwordErrorState(err.data.data.password));
      }
    }
  };

  return (
    <CommonBGLayout>
      <div className="login">
        <Container>
          <Row>
            <Col>
              <div className="login-card text-center mx-auto">
                <div className="login-overlay"></div>
                <div className="login-card-header mb-4">
                  <img
                    src={Common.Logo}
                    width="130px"
                    height="101px"
                    alt="logo"
                  />
                  <h3 className="mt-2 mb-0">Login</h3>
                  <p className="para-big mb-0">Welcome to Dashboard</p>
                </div>
                <div className="login-card-body text-start">
                  <div className="common-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <Form.Group className="position-relative input-design d-flex align-items-center">
                              <img src={LoginIcons.Message} alt="message" />
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                            </Form.Group>
                            {emailError ? (
                              <div className="invalid-feedback">
                                {emailError}
                              </div>
                            ) : (
                              <>
                                {touched.email && errors.email ? (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Group className="position-relative input-design d-flex align-items-center">
                              <img src={LoginIcons.Password} alt="message" />
                              <Form.Control
                                type={visiblePasswords ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                placeholder="Enter password"
                                autoComplete="off"
                                onBlur={handleBlur}
                                value={values.password}
                                className={
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              <button
                                className="btn p-0 border-0 show-password-icon"
                                onClick={() =>
                                  dispatch(
                                    visiblePasswordState(!visiblePasswords),
                                  )
                                }
                                type="button"
                              >
                                {!visiblePasswords ? (
                                  <img src={Common.EyeClose} alt="eye-open" />
                                ) : (
                                  <img src={Common.EyeOpen} alt="eye-open" />
                                )}
                              </button>
                            </Form.Group>
                            {passwordError ? (
                              <div className="invalid-feedback">
                                {passwordError}
                              </div>
                            ) : (
                              <>
                                {touched.password && errors.password ? (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Form.Group>
                          <div className="d-flex mt-4">
                            {/* Stay Signed in Checkbox */}
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                label="Remember me"
                                name="staySignedIn"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.staySignedIn}
                              />
                            </Form.Group>
                            {/* Forgot Password */}
                            <a
                              href="/forgot-password"
                              className="ms-auto forget-password"
                            >
                              Forgot Password?
                            </a>
                          </div>
                          <button
                            className="btn primary-button mt-5 w-100"
                            type="submit"
                          >
                            {loading ? (
                              <LoaderWhite height={30} width={30} />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CommonBGLayout>
  );
};

export default LoginComponent;
