import { createSlice } from "@reduxjs/toolkit";
import { PettyCash } from "../../interfaces/petty-cash";

interface Index {
  showExportDropdown: boolean;
  showFilterDropdown: boolean;
  addDebitLoading: boolean;
  addCreditLoading: boolean;
  ledgerLoading: boolean;
  ledger: PettyCash[];
  totalCredit: number;
  totalDebit: number;
  lastMonthBalance: number;
  pettyCashError: string;
  filterDate: string;
}

const initialState: Index = {
  showExportDropdown: false,
  showFilterDropdown: false,
  addCreditLoading: false,
  addDebitLoading: false,
  ledgerLoading: false,
  ledger: [],
  totalCredit: 0,
  totalDebit: 0,
  lastMonthBalance: 0,
  pettyCashError: "",
  filterDate: new Date().toLocaleDateString(),
};

const pettyCashSlice = createSlice({
  name: "pettyCash",
  initialState: initialState,
  reducers: {
    setShowExportDropdown: (state, action) => {
      state.showExportDropdown = action.payload;
    },
    setShowFilterDropdown: (state, action) => {
      state.showFilterDropdown = action.payload;
    },
    setAddCreditLoading: (state, action) => {
      state.addCreditLoading = action.payload;
    },
    setAddDebitLoading: (state, action) => {
      state.addDebitLoading = action.payload;
    },
    setLedgerLoading: (state, action) => {
      state.ledgerLoading = action.payload;
    },
    setLedger: (state, action) => {
      state.ledger = action.payload;
    },
    setTotalCredit: (state, action) => {
      state.totalCredit = action.payload;
    },
    setTotalDebit: (state, action) => {
      state.totalDebit = action.payload;
    },
    setLastMonthBalance: (state, action) => {
      state.lastMonthBalance = action.payload;
    },
    setPettyCashError: (state, action) => {
      state.pettyCashError = action.payload;
    },
    setFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
  },
});

export const {
  setShowExportDropdown,
  setPettyCashError,
  setShowFilterDropdown,
  setLedgerLoading,
  setAddDebitLoading,
  setAddCreditLoading,
  setTotalDebit,
  setTotalCredit,
  setLedger,
  setLastMonthBalance,
  setFilterDate,
} = pettyCashSlice.actions;
export default pettyCashSlice.reducer;
