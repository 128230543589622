import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { ManualJournal } from "../../interfaces/account-charts";

interface ManualJournalList {
  data: {
    allJournals: {
      data: ManualJournal[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export const manualJournalAPI = createApi({
  reducerPath: "/api/manual-journal",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getManualJournalList: builder.query<
      ManualJournalList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        type: string;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-journals/${data.type}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addManualJournal: builder.mutation({
      query: (data) => ({
        url: `/add-journal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editManualJournal: builder.mutation({
      query: (data) => ({
        url: `/edit-journal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteManualJournal: builder.mutation({
      query: (data) => ({
        url: `/delete-journal/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetManualJournalListQuery,
  useAddManualJournalMutation,
  useEditManualJournalMutation,
  useDeleteManualJournalMutation,
} = manualJournalAPI;
