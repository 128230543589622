import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { AddEmployeeProps } from "../AddEmployee/add-employee-container";
import { Common } from "../../../assets/images";
import LanguageSet from "./language";
import ExperienceSet from "./experience";
import SkillsSet from "./skills";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEditOtherDetails,
  setEditEvaluationDetails,
  setEditAttachments,
  setActiveTab,
  setEditTimeline,
  setEditIncrementTimeline,
} from "../../../store/slices/employeeSlice";
import { LoaderWhite } from "../../Lotties";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const EditEvaluationDetail = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.employee.empLoading);
  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      selectedLanguage: string;
      languageProficiency: string;
      writingSkills: string;
    }[]
  >(values.language);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        selectedLanguage: "",
        languageProficiency: "",
        writingSkills: "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      selectedLanguage: string;
      languageProficiency: string;
      writingSkills: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <LanguageSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  useEffect(() => {
    values.language = languageData;
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      region: string;
      basicSalary: string;
      experienceProficiency: string;
      startDate: any;
      endDate: any;
    }[]
  >(values.experience);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        region: "",
        basicSalary: "",
        experienceProficiency: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      region: string;
      basicSalary: string;
      experienceProficiency: string;
      startDate: any;
      endDate: any;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <ExperienceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  useEffect(() => {
    values.experience = experienceData;
  }, [experienceData]);

  // Skills Sets
  const [skillsSets, setSkillsSets] = useState<React.ReactNode[]>([]);
  const [skillsData, setSkillsData] = useState<
    {
      uId: string;
      skillName: string;
      skillProficiency: string;
      notes: any;
    }[]
  >(values.skills);

  const handleAddSkillsSet = () => {
    setSkillsData([
      ...skillsData,
      {
        uId: Math.random().toString(36).substring(7),
        skillName: "",
        skillProficiency: "",
        notes: "",
      },
    ]);
  };

  const handleRemoveSkillsSet = (index: string) => {
    const updatedInputData = skillsData.filter((item, i) => item.uId !== index);
    setSkillsData(updatedInputData);
  };

  const handleSkillsInputChange = (
    index: string,
    data: {
      skillName: string;
      skillProficiency: string;
      notes: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = skillsData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setSkillsData(updatedInput);
  };

  useEffect(() => {
    const updatedSkillsSets = skillsData.map((data, index) => (
      <SkillsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveSkillsSet(key)}
        onInputChange={(inputData) =>
          handleSkillsInputChange(data.uId, inputData)
        }
      />
    ));
    setSkillsSets(updatedSkillsSets);
  }, [skillsData]);

  useEffect(() => {
    values.skills = skillsData;
  }, [skillsData]);

  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Evaluation Details</h3>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center gap-4">
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={handleAddLanguageSet}
            style={{ width: "15%" }}
          >
            <img
              src={Common.Plus}
              width="20px"
              height="20px"
              className="me-2"
              alt="next"
            />{" "}
            Language
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={handleAddExperienceSet}
            style={{ width: "15%" }}
          >
            <img
              src={Common.Plus}
              width="20px"
              height="20px"
              className="me-2"
              alt="next"
            />{" "}
            Experience
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={handleAddSkillsSet}
            style={{ width: "15%" }}
          >
            <img
              src={Common.Plus}
              width="20px"
              height="20px"
              className="me-2"
              alt="next"
            />{" "}
            Extra Skills
          </button>
        </div>
      </Col>
      <Col sm="12">
        <div className="extra-detail my-4">
          {languageSets.length > 0 && (
            <h4 className="sub-heading normal-font-weight mb-4">Languages</h4>
          )}
          {languageSets}
          {experienceSets.length > 0 && (
            <h4 className="sub-heading normal-font-weight mb-4">Experiences</h4>
          )}
          {experienceSets}
          {skillsSets.length > 0 && (
            <h4 className="sub-heading normal-font-weight mb-4">Skills</h4>
          )}
          {skillsSets}
        </div>
      </Col>
      <Col sm="12">
        <Row>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Obedient
              </h4>
              <Form.Select
                name="evaluationDetail.obedient"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.evaluationDetail.obedient}
                className={
                  touched.obedient && errors.obedient
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>

              {touched.obedient && errors.obedient ? (
                <div className="invalid-feedback">{errors.obedient}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Behavior
              </h4>
              <Form.Select
                name="evaluationDetail.behavior"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.evaluationDetail.behavior}
                className={
                  touched.behavior && errors.behavior
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Option</option>
                <option value="Bad">Bad</option>
                <option value="Average">Average</option>
                <option value="Satisfactory">Satisfactory</option>
                <option value="Good">Good</option>
                <option value="Outstanding">Outstanding</option>
              </Form.Select>

              {touched.behavior && errors.behavior ? (
                <div className="invalid-feedback">{errors.behavior}</div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col sm="12">
        <Row>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Appearance
              </h4>
              <textarea
                name="evaluationDetail.appearance"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.evaluationDetail.appearance}
                placeholder="Leave a comment here..."
                className={
                  touched.appearance && errors.appearance
                    ? "is-invalid border-fill w-100"
                    : "border-fill w-100"
                }
                rows={10}
                style={{ resize: "none" }}
              />
              {touched.appearance && errors.appearance ? (
                <div className="invalid-feedback">{errors.appearance}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Remarks
              </h4>
              <textarea
                name="evaluationDetail.remarks"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.evaluationDetail.remarks}
                placeholder="Leave your remarks here..."
                className={
                  touched.remarks && errors.remarks
                    ? "is-invalid border-fill w-100"
                    : "border-fill w-100"
                }
                rows={10}
                style={{ resize: "none" }}
              />
              {touched.remarks && errors.remarks ? (
                <div className="invalid-feedback">{errors.remarks}</div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setEditAttachments(true));
              dispatch(setEditEvaluationDetails(false));
              dispatch(setActiveTab("Attachments"));
            }}
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <div className="w-100">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="submit"
              style={{ width: "15%" }}
            >
              {loading ? <LoaderWhite height={30} width={30} /> : "Update"}
            </button>
            <button
              className="btn primary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditTimeline(true));
                dispatch(setEditEvaluationDetails(false));
                dispatch(setActiveTab("Projects Timeline"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              Next <img src={Common.ArrowRightWhite} alt="next" />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default EditEvaluationDetail;
