import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters
import { useGetFinalExitQuery } from "../../../services/Letters";
import {
  setFinalExitLoading,
  setFinalExitError,
  setFinalExits,
  setFinalExitTotalPages,
} from "../../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const finalExitChange = useAppSelector(
    (state) => state.common.finalExitChange,
  );

  const letterState = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Final Exits
  // **************
  // **************
  const {
    data: allFinalExits,
    isLoading: finalExitsLoading,
    isError: finalExitsError,
    refetch: finalExitsRefetch,
  } = useGetFinalExitQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: letterState.finalExitPageNo,
    limit: letterState.finalExitPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (finalExitsLoading) {
      dispatch(setFinalExitLoading(true));
    }
    if (finalExitsError) {
      dispatch(setFinalExitError("Something went wrong"));
      dispatch(setFinalExitLoading(false));
    }
    if (allFinalExits) {
      dispatch(setFinalExits(allFinalExits.data.finalExit[0].data || []));
      dispatch(
        setFinalExitTotalPages(
          allFinalExits.data.finalExit[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setFinalExitLoading(false));
    }
  }, [
    accessToken,
    userID,
    allFinalExits,
    profileData,
    letterState.finalExitPageNo,
    letterState.finalExitPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    finalExitsRefetch();
  }, [finalExitChange]);
  return <></>;
};
export default Content;
