import React from "react";
import InternalLayout from "../components/InternalLayout";
import InterviewComponent from "../components/Interviews";
import withAuth from "../config/private-route";

const Interview = () => {
  return (
    <InternalLayout>
      <InterviewComponent />
    </InternalLayout>
  );
};
export default withAuth(Interview);
