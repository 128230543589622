import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";

interface JournalProps {
  data: {
    // account: string;
    quantity: number;
    rate: number;
    description: string;
    amount: number;
    taxPercentage: number;
    discountType: string;
    discount: number;
  };
  setLength: number;
  onRemove: (key: string) => void;
  onInputChange: (data: {
    // account: string;
    quantity: number;
    rate: number;
    description: string;
    amount: number;
    taxPercentage: number;
    discountType: string;
    discount: number;
  }) => void;
  uniqueId: string;
}

const BillingSet: React.FC<JournalProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
  setLength,
}) => {
  // const accountOptions = useAppSelector(
  //   (state) => state.accountCharts.accountChartsIds,
  // );
  // const [account, setAccount] = useState(data.account);
  const [quantity, setQuantity] = useState(data.quantity);
  const [rate, setRate] = useState(data.rate);
  const [description, setDescription] = useState(data.description);
  const [amount, setAmount] = useState(data.amount);
  const [taxPercentage, setTaxPercentage] = useState(
    data.taxPercentage.toString(),
  );
  const [discount, setDiscount] = useState(data.discount.toString());
  const [discountType, setDiscountType] = useState(data.discountType);
  const handleInputChange = () => {
    onInputChange({
      // account,
      quantity,
      rate,
      description,
      amount,
      taxPercentage: parseFloat(taxPercentage),
      discountType,
      discount: parseFloat(discount),
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [quantity, rate, description, amount, discount, discountType]);

  useEffect(() => {
    const totalAmounts = rate * quantity;
    // const discountValue =
    //   discountType === "percent"
    //     ? (totalAmounts / 100) * parseFloat(discount)
    //     : parseFloat(discount);
    const discountAmount =
      discountType === "percent"
        ? (totalAmounts / 100) * parseFloat(discount)
        : parseFloat(discount);
    const taxAmount =
      (totalAmounts - discountAmount) * (parseFloat(taxPercentage) / 100);
    const totalAmount = totalAmounts - discountAmount + taxAmount;
    setAmount(totalAmount);
  }, [rate, discount, discountType, taxPercentage, quantity]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Item Description
                </h4>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            {/*<Col sm="2">*/}
            {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
            {/*    <h4*/}
            {/*      className="sub-heading normal-font-weight textBlack"*/}
            {/*      style={{ fontSize: "18px" }}*/}
            {/*    >*/}
            {/*      Select Account*/}
            {/*    </h4>*/}
            {/*    <Form.Select*/}
            {/*      name="account"*/}
            {/*      onChange={(e) => setAccount(e.target.value)}*/}
            {/*      value={account}*/}
            {/*      className="custom-input border-fill"*/}
            {/*    >*/}
            {/*      <option value="">Select Account</option>*/}
            {/*      {accountOptions.map((item, index: number) => (*/}
            {/*        <option key={index} value={item.accountName}>*/}
            {/*          {item.accountId + " - " + item.accountName}*/}
            {/*        </option>*/}
            {/*      ))}*/}
            {/*    </Form.Select>*/}
            {/*  </Form.Group>*/}
            {/*</Col>*/}
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Quantity
                </h4>
                <Form.Control
                  type="text"
                  name="quantity"
                  placeholder="Enter quantity"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setQuantity(Number(e.target.value));
                    }
                  }}
                  value={quantity}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>

            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Rate
                </h4>
                <Form.Control
                  type="text"
                  name="rate"
                  placeholder="Enter rate"
                  onChange={(e) => {
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setRate(Number(e.target.value));
                    }
                  }}
                  value={rate}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Discount
                </h4>
                <Form.Control
                  type="text"
                  name="discount"
                  placeholder="Enter discount"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setDiscount(e.target.value);
                    }
                  }}
                  value={discount}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Type
                </h4>
                <Form.Select
                  name="discountType"
                  onChange={(e) => setDiscountType(e.target.value)}
                  value={discountType}
                  className="custom-input border-fill"
                >
                  <option value="percent">%</option>
                  <option value="sar">SAR</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Tax %
                </h4>
                <Form.Control
                  type="text"
                  name="taxPercentage"
                  placeholder="Enter tax percentage"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setTaxPercentage(e.target.value);
                    }
                  }}
                  value={taxPercentage}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Amount
                </h4>
                <Form.Control
                  type="text"
                  name="rate"
                  placeholder="Enter rate"
                  disabled={true}
                  value={amount}
                  className="custom-input diabled-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        {setLength > 1 && (
          <Col sm="1">
            <div className="d-flex gap-2 align-items-center">
              <button
                onClick={() => {
                  onRemove(uniqueId);
                }}
                className="btn primary-button"
                type="button"
              >
                <img
                  src={Common.Cross}
                  height="20px"
                  width="20px"
                  alt="cross"
                />
              </button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BillingSet;
