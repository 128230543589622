import React from "react";
import { Row, Col } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageEvaluationDetails,
  setDetailActiveTab,
  setDetailPageAgreementDetails,
  setDetailPageAttachments,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";

interface Props {
  empDetail: EmpDetail;
}

const Attachments = ({ empDetail }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Row>
      {empDetail.attachments.length > 0 ? (
        <>
          <div className="table-responsive mb-3">
            <table className="table text-center table-bordered">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Date</th>
                  <th>File Title</th>
                  <th>Uploader Emp Id</th>
                  <th>Uploader Emp Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {empDetail.attachments.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item.date
                        ? new Date(item.date).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{item.title}</td>
                    <td>{item.empId}</td>
                    <td>{item.empName}</td>
                    <td>
                      <a href={item.link} download>
                        <img
                          src={Common.Download}
                          className="me-2"
                          width="24px"
                          height="24px"
                          alt="share"
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">No Attachments</h1>
        </div>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Agreement Details"));
              dispatch(setDetailPageAgreementDetails(true));
              dispatch(setDetailPageAttachments(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Evaluation Details"));
              dispatch(setDetailPageEvaluationDetails(true));
              dispatch(setDetailPageAttachments(false));
            }}
            style={{ width: "15%" }}
          >
            Next
            <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default Attachments;
