import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowAccountChartsExportDropdown,
  setAccountChartsListPageSize,
  setAccountChartsListPageNo,
  setShowAccountChartsFilterDropdown,
  setType,
} from "../../store/slices/accountChartsSlice";
import { setAccountChartsChange } from "../../store/slices/commonSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { useDeleteAccountChartMutation } from "../../services/AccountCharts";
import { AccountChart } from "../../interfaces/account-charts";
import { exportToCsv } from "../../config";
import { useReactToPrint } from "react-to-print";
import SelectSearch from "react-select-search";

const AllAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.accountCharts);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const accountChartsChange = useAppSelector(
    (state) => state.common.accountChartsChange,
  );
  // Options
  const options = state.accountChartsIds.map((item) => ({
    name: item.accountId + " - " + item.accountName,
    value: item.accountId,
  }));
  // Pagination && Filter
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState<AccountChart[]>([]);
  const totalPages = Math.ceil(
    state.accountChartsListTotalPages / state.accountChartsListPageSize,
  );
  const totalCount = state.accountChartsListTotalPages;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.accountChartsListPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.accountChartsListPageNo > totalPages - showPages) {
      return;
    }
    dispatch(
      setAccountChartsListPageNo(state.accountChartsListPageNo + showPages),
    );
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.accountChartsListPageNo <= 5) {
      return;
    }
    dispatch(
      setAccountChartsListPageNo(state.accountChartsListPageNo - showPages),
    );
  };

  const handlePageChange = (page: any) => {
    dispatch(setAccountChartsListPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setAccountChartsListPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.accountChartsList);
  }, [state.accountChartsList]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Account Id.",
      "Account Name",
      "Account Category",
      "Account Type",
      "Description",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Account Id.": item.accountId,
    "Account Name": item.accountName,
    "Account Category": item.accountCategory,
    "Account Type": item.accountType,
    Description: item.description,
  }));

  const [deleteAccountChart] = useDeleteAccountChartMutation();

  const handleDelete = async (empId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.deletePettyCash || false,
        payload: {
          accountId: empId,
          link: "/account-charts",
        },
      };
      await deleteAccountChart(data).unwrap();
      dispatch(setAccountChartsChange(!accountChartsChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: false }));
    }
  };

  const handleReset = () => {
    setQuery("");
    dispatch(setType("All"));
    setFilteredData(state.accountChartsList);
  };

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">All Accounts</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        setQuery(value);
                        dispatch(setType(value));
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Account by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/account-charts/add-account")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  New
                </button>
                <Dropdown
                  show={state.showAccountChartsFilterDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(setShowAccountChartsExportDropdown(false));
                      dispatch(
                        setShowAccountChartsFilterDropdown(
                          !state.showAccountChartsFilterDropdown,
                        ),
                      );
                    }}
                    className="dropdown-toggle-blue dropdown-toggle-white"
                  >
                    {state.type}{" "}
                    <img
                      src={EmployeeIcons.ArrowDownWhite}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showAccountChartsFilterDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("All"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("Assets"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      Assets
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("Liability"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      Liability
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("Equity"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      Equity
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("Income"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      Income
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setType("Expense"));
                        dispatch(setShowAccountChartsFilterDropdown(false));
                      }}
                    >
                      Expense
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  show={state.showAccountChartsExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowAccountChartsExportDropdown(
                          !state.showAccountChartsExportDropdown,
                        ),
                      );
                      dispatch(setShowAccountChartsFilterDropdown(false));
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showAccountChartsExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "accounts-list");
                        dispatch(setShowAccountChartsExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowAccountChartsExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.accountChartsListLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showPettyCash ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Accounts List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? (state.accountChartsListPageNo - 1) *
                                  state.accountChartsListPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/account-charts/view-account/${item.accountId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {item.accountId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/account-charts/view-account/${item.accountId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {print
                              ? twoWords(item.accountName)
                              : item.accountName}
                          </td>
                          <td>{item.accountCategory}</td>
                          <td>{item.accountType}</td>
                          <td>{item.description}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/account-charts/view-account/${item.accountId}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/account-charts/edit-account/${item.accountId}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={SettingsIcons.EditUser}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              {deleteLoadingMap[item.accountId] ? (
                                <LoaderBlue height={20} width={20} />
                              ) : (
                                <button
                                  className="btn p-0 border-0"
                                  onClick={() => handleDelete(item.accountId)}
                                >
                                  <img
                                    src={SettingsIcons.Delete}
                                    width="24px"
                                    height="24px"
                                    alt="edit-user"
                                  />
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No accounts found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={state.accountChartsListPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.accountChartsListPageNo > 1
                          ? state.accountChartsListPageNo - 1
                          : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.accountChartsListPageNo
                          ? "active-page"
                          : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.accountChartsListPageNo < totalPages
                          ? state.accountChartsListPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default AllAccounts;
