import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, SettingsIcons } from "../../assets/images";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddVisaLoading } from "../../store/slices/campsSlice";
import { setVisaChange } from "../../store/slices/commonSlice";
// API
import { useEditVisaMutation } from "../../services/Camps";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { Visa } from "../../interfaces/employee";

export interface AddVisa {
  unifiedNumber: string;
  visaNumber: string;
  requestNumber: string;
}

const EditVisa = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const visaData: Visa = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.camps);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const recruiterIds = useAppSelector((state) => state.camps.recruitAgencyIds);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [requestedWorkVisa, setRequestedWorkVisa] = useState<{
    borderNumber: number;
    nationality: string;
    occupation: string;
    gender: string;
    embassy: string;
    religion: string;
    recruiterId: string;
    status: string;
  }>({
    borderNumber: 0,
    nationality: "",
    occupation: "",
    gender: "",
    embassy: "",
    religion: "",
    recruiterId: "",
    status: "Unused",
  });
  const [selectedEmails, setSelectedEmails] = useState<
    {
      borderNumber: number;
      nationality: string;
      occupation: string;
      gender: string;
      embassy: string;
      religion: string;
      recruiterId: string;
      status: string;
    }[]
  >(visaData.requestedVisas || []);

  const initialValues: AddVisa = {
    unifiedNumber: visaData?.unifiedNumber?.toString() || "",
    visaNumber: visaData?.visaNumber?.toString() || "",
    requestNumber: visaData?.requestNumber?.toString() || "",
  };
  const sponsorIds = useAppSelector((state) => state.sponsor.sponsorIds);
  const alreadySelectedCompany = sponsorIds.find(
    (item) => item.unifiedNumber === visaData.establishmentNumber,
  );
  const [company, setCompany] = useState<string>(
    alreadySelectedCompany ? alreadySelectedCompany.companyId : "",
  );
  const companySelected = sponsorIds.find((item) => item.companyId === company);
  const visaChange = useAppSelector((state) => state.common.visaChange);

  const [editVisa] = useEditVisaMutation();
  const handleSubmit = async (values: AddVisa) => {
    dispatch(setAddVisaLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addLegal || false,
        payload: {
          visaId: visaData._id,
          unifiedNumber: parseInt(values.unifiedNumber),
          visaNumber: parseInt(values.visaNumber),
          establishmentName: companySelected?.companyName || "",
          establishmentNumber: companySelected?.unifiedNumber || 0,
          requestNumber: parseInt(values.requestNumber),
          requestedVisas: selectedEmails,
          link: `/recruit-agency/visa`,
        },
      };
      await editVisa(data).unwrap();
      dispatch(setVisaChange(!visaChange));
      navigate("/recruit-agency/visa");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddVisaLoading(false));
    }
  };

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
  ];

  const handleRequestWorkVisa = () => {
    if (
      requestedWorkVisa.borderNumber === 0 ||
      requestedWorkVisa.gender === "" ||
      requestedWorkVisa.nationality === "" ||
      requestedWorkVisa.occupation === "" ||
      requestedWorkVisa.religion === "" ||
      requestedWorkVisa.recruiterId === "" ||
      requestedWorkVisa.embassy === ""
    ) {
      window.scrollTo(0, 0);
      setErrorContent("Please fill all the fields");
      setShow(true);
      return;
    }
    const findBorderNumber = selectedEmails.find(
      (email) => email.borderNumber === requestedWorkVisa.borderNumber,
    );
    if (findBorderNumber) {
      window.scrollTo(0, 0);
      setErrorContent("Border number already added");
      setShow(true);
      return;
    }
    setSelectedEmails([...selectedEmails, requestedWorkVisa]);
    setRequestedWorkVisa({
      borderNumber: 0,
      gender: "",
      nationality: "",
      occupation: "",
      embassy: "",
      religion: "",
      recruiterId: "",
      status: "Unused",
    });
  };

  const updateRequestWorkVisa = () => {
    if (
      requestedWorkVisa.borderNumber === 0 ||
      requestedWorkVisa.gender === "" ||
      requestedWorkVisa.nationality === "" ||
      requestedWorkVisa.occupation === "" ||
      requestedWorkVisa.religion === "" ||
      requestedWorkVisa.recruiterId === "" ||
      requestedWorkVisa.embassy === ""
    ) {
      window.scrollTo(0, 0);
      setErrorContent("Please fill all the fields");
      setShow(true);
      return;
    }
    const findBorderNumber = selectedEmails.find(
      (email) => email.borderNumber === requestedWorkVisa.borderNumber,
    );
    if (!findBorderNumber) {
      window.scrollTo(0, 0);
      setErrorContent("Border number not found in the list");
      setShow(true);
      return;
    }
    const updatedEmails = selectedEmails.map((email) => {
      if (email.borderNumber === requestedWorkVisa.borderNumber) {
        return requestedWorkVisa;
      }
      return email;
    });
    setSelectedEmails(updatedEmails);
    setRequestedWorkVisa({
      borderNumber: 0,
      gender: "",
      nationality: "",
      occupation: "",
      embassy: "",
      religion: "",
      recruiterId: "",
      status: "Unused",
    });
    setIsEdited(false);
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Visa </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Establishment *
                      </h4>
                      <Form.Select
                        name="company"
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                        value={company}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Establishment</option>
                        {sponsorIds.map((item, index: number) => (
                          <option key={index} value={item.companyId}>
                            {item.companyId + " - " + item.companyName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Visa Number
                      </h4>
                      <Form.Control
                        type="text"
                        name="visaNumber"
                        placeholder="Enter visa number"
                        onChange={(e) => {
                          //     accept only numbers
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        value={values.visaNumber}
                        className="custom-input border-fill"
                      />
                      {touched.visaNumber && errors.visaNumber ? (
                        <div className="invalid-feedback">
                          {errors.visaNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Unified Number
                      </h4>
                      <Form.Control
                        type="text"
                        name="unifiedNumber"
                        placeholder="Enter unified number"
                        onChange={(e) => {
                          //     accept only numbers
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        value={values.unifiedNumber}
                        className="custom-input border-fill"
                      />
                      {touched.unifiedNumber && errors.unifiedNumber ? (
                        <div className="invalid-feedback">
                          {errors.unifiedNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Request Number
                      </h4>
                      <Form.Control
                        type="text"
                        name="requestNumber"
                        placeholder="Enter request number"
                        onChange={(e) => {
                          //     accept only numbers
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        value={values.requestNumber}
                        className="custom-input border-fill"
                      />
                      {touched.requestNumber && errors.requestNumber ? (
                        <div className="invalid-feedback">
                          {errors.requestNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "24px" }}
                      >
                        Requested Work Visa
                      </h4>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Border Number
                      </h4>
                      <div className="d-flex align-items-center gap-2">
                        <Form.Control
                          type="text"
                          name="borderNumber"
                          placeholder="Enter border number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setRequestedWorkVisa({
                                ...requestedWorkVisa,
                                borderNumber: parseInt(e.target.value),
                              });
                            }
                          }}
                          value={requestedWorkVisa.borderNumber}
                          className="custom-input border-fill"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group
                      className="mb-3 text-start"
                      controlId="formBasicEmail"
                    >
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Nationality
                      </h4>
                      <Form.Select
                        name="nationality"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            nationality: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.nationality}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Nationality</option>
                        {countryCodes.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Religion
                      </h4>
                      <Form.Select
                        name="religion"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            religion: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.religion}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Religion</option>
                        <option value="Islam">Islam</option>
                        <option value="Christianity">Chritianity</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Jew">Jew</option>
                        <option value="Atheist">Atheist</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Occupation
                      </h4>
                      <Form.Control
                        type="text"
                        name="occupations"
                        placeholder="Enter occupation"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            occupation: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.occupation}
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Embassy
                      </h4>
                      <Form.Control
                        type="text"
                        name="embassy"
                        placeholder="Enter embassy"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            embassy: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.embassy}
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Gender
                      </h4>
                      <Form.Select
                        name="gender"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            gender: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.gender}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Recruiter Id. *
                      </h4>
                      <Form.Select
                        name="recruiterId"
                        onChange={(e) => {
                          setRequestedWorkVisa({
                            ...requestedWorkVisa,
                            recruiterId: e.target.value,
                          });
                        }}
                        value={requestedWorkVisa.recruiterId}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Recruiter Id.</option>
                        {recruiterIds.map((item, index: number) => (
                          <option key={index} value={item.recruiterId}>
                            {item.recruiterId + " - " + item.recruiterName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      {isEdited ? "Update" : "Add"} Request
                    </h4>
                    {isEdited ? (
                      <button
                        className="btn primary-button normal-font-weight w-100"
                        type="button"
                        onClick={() => {
                          updateRequestWorkVisa();
                        }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Update filled request
                      </button>
                    ) : (
                      <button
                        className="btn primary-button normal-font-weight w-100"
                        type="button"
                        onClick={() => {
                          handleRequestWorkVisa();
                        }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Add filled request
                      </button>
                    )}
                  </Col>
                  <Col sm="12">
                    <div className="table-responsive my-4">
                      {selectedEmails.length > 0 ? (
                        <table className="table text-center table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>Border No.</th>
                              <th>Nationality</th>
                              <th>Occupation</th>
                              <th>Embassy</th>
                              <th>Gender</th>
                              <th>Religion</th>
                              <th>Recruiter Id</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedEmails.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.borderNumber}</td>
                                <td>{item.nationality}</td>
                                <td>{item.occupation}</td>
                                <td>{item.embassy}</td>
                                <td>{item.gender}</td>
                                <td>{item.religion}</td>
                                <td>{item.recruiterId}</td>
                                <td>{item.status}</td>
                                <td>
                                  <button
                                    className="btn p-0 border-0 me-2"
                                    onClick={() => {
                                      setIsEdited(true);
                                      setRequestedWorkVisa({
                                        borderNumber: item.borderNumber,
                                        gender: item.gender,
                                        nationality: item.nationality,
                                        occupation: item.occupation,
                                        embassy: item.embassy,
                                        religion: item.religion,
                                        recruiterId: item.recruiterId,
                                        status: item.status,
                                      });
                                    }}
                                  >
                                    <img
                                      src={SettingsIcons.EditUser}
                                      width="30px"
                                      height="30px"
                                      alt="edit-user"
                                    />
                                  </button>
                                  <button
                                    className="btn p-0 border-0"
                                    type="button"
                                    onClick={() => {
                                      const updatedEmails =
                                        selectedEmails.filter(
                                          (email) =>
                                            email.borderNumber !==
                                            item.borderNumber,
                                        );
                                      setSelectedEmails(updatedEmails);
                                    }}
                                  >
                                    <img
                                      src={SettingsIcons.Delete}
                                      width="24px"
                                      height="24px"
                                      alt="edit-user"
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center">
                          <h4 className="sub-heading">
                            {" "}
                            No requests for work visa added yet.
                          </h4>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.addVisaLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Edit Visa"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditVisa;
