import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import LetterFormatA from "./letter-format-a";

interface Props {
  remarks: string;
  empName: string;
}

const AcknwoledgeLetter = ({ remarks, empName }: Props) => {
  const probationRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => probationRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, []);
  return (
    <div className="letter-container position-relative" ref={probationRef}>
      <LetterFormatA heading="Acknowledgment Letter">
        <div className="my-6">
          <p
            className="para-medium text-black mb-0"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {remarks}
          </p>
        </div>
        <div className="signature-authority d-flex justify-content-between">
          <div className="authority">
            <h4 className="sub-heading normal-font-weight text-black">
              Accepted By:
            </h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Mr. {empName}
            </p>
          </div>
          <div className="signature">
            <h4 className="sub-heading normal-font-weight text-black">
              Signature:
            </h4>
          </div>
        </div>
      </LetterFormatA>
    </div>
  );
};
export default AcknwoledgeLetter;
