import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { AbscondedEmployee } from "../../interfaces/employee";
import AbscondLetter from "../Letters/abscond-letter";
import { Common } from "../../assets/images";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useEditAbscondedEmployeeMutation } from "../../services/Employees";
import {
  setAbscondedEmployeesList,
  setActiveTabEmpList,
  setCertificatesList,
  setEmployeesList,
  setFinalExitEmployeesList,
  setOfferLettersList,
  setProbationEmployeesList,
  setTerminatedEmployeesList,
} from "../../store/slices/employeeSlice";
import { setAbscondedChange } from "../../store/slices/commonSlice";

const AbscondDetail = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: AbscondedEmployee = location.state.data;
  const [returnCost, setReturnCost] = useState(0);
  const [showReturn, setShowReturn] = useState(false);
  const [reactivateLoading, setReactivateLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const abscondedChange = useAppSelector(
    (state) => state.common.abscondedChange,
  );

  // API call for add salary increment
  const [editAbscondedEmployee] = useEditAbscondedEmployeeMutation();
  const handleSubmit = async () => {
    setReactivateLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: statementData.empId,
          amountRecovered: returnCost,
          profileStatus: true,
          link: `/employees`,
        },
      };
      await editAbscondedEmployee(data).unwrap();
      dispatch(setAbscondedChange(!abscondedChange));
      dispatch(setActiveTabEmpList("Absconded Employees"));
      dispatch(setEmployeesList(false));
      dispatch(setProbationEmployeesList(false));
      dispatch(setAbscondedEmployeesList(true));
      dispatch(setTerminatedEmployeesList(false));
      dispatch(setFinalExitEmployeesList(false));
      dispatch(setOfferLettersList(false));
      dispatch(setCertificatesList(false));
      navigate("/employees");
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      setReactivateLoading(false);
    }
  };

  // check if all status are approved in approvals array
  const isApproved = statementData.abscondApprovals.every(
    (item) =>
      item.status === "Approved" || item.status === "Approved by Super Admin",
  );

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <AbscondLetter item={statementData} />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Absconded Employee Detail</h1>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Abscond Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondDate
                      ? new Date(statementData.abscondDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Abscond Location
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondLocation}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Abscond Project
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondProject}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Reason Of Abscond
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.reasonOfAbscond}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Remaining Balance
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.remaining
                      ? statementData?.remaining[0]?.totalBalance
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Last Salary Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData?.lastSalaryStatus ? "Paid" : "Unpaid"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Abscond Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondApprovalsStatus}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="12">
                <Form.Group className="mb-5">
                  <h4 className="sub-heading" style={{ fontSize: "20px" }}>
                    Submitted By:
                  </h4>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondSubmittedBy.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.abscondSubmittedBy.empName}
                  </h5>
                </Form.Group>
              </Col>
              {statementData.abscondSubmittedBy.remarks && (
                <Col sm="12">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Remarks
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.abscondSubmittedBy.remarks}
                    </h5>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">
                Absconded Employee Approvals:
              </h2>
              <Row>
                {statementData.abscondApprovals.length > 0 ? (
                  <>
                    {statementData.abscondApprovals.map((item, index) => (
                      <div className="col-sm-4" key={index}>
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            {item.name} ({item.role})
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {item.status}{" "}
                            {item.status !== "Pending"
                              ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                              : null}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Approvals
                    </h4>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="mb-4">
              <button
                className="btn primary-button"
                onClick={() => setShowReturn(!showReturn)}
              >
                Re-activate Profile
              </button>
            </div>
          </Col>
          {showReturn && (
            <Col sm="12">
              <Row>
                <Col sm="12">
                  <Form.Group className="mb-0">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Amount Retrieved
                    </h4>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      name="returnCost"
                      onChange={(e) => setReturnCost(Number(e.target.value))}
                      placeholder="Enter return cost"
                      value={returnCost}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <div>
                    <button
                      className="btn primary-button"
                      onClick={handleSubmit}
                    >
                      {reactivateLoading ? (
                        <LoaderWhite height={30} width={30} />
                      ) : (
                        "Re-activate"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              {isApproved && (
                <button
                  className="btn primary-button w-25"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default AbscondDetail;
