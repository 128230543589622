import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddAccountChartsLoading } from "../../store/slices/accountChartsSlice";
import { setAccountChartsChange } from "../../store/slices/commonSlice";
// API
import { useEditAccountChartMutation } from "../../services/AccountCharts";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

export interface AddAccount {
  accountName: string;
  description: string;
}

const EditAccountChart = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accountData = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.accountCharts);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const accountChartsChange = useAppSelector(
    (state) => state.common.accountChartsChange,
  );

  const initialValues: AddAccount = {
    accountName: accountData.accountName || "",
    description: accountData.description || "",
  };

  const [editAccountChart] = useEditAccountChartMutation();
  const handleSubmit = async (values: AddAccount) => {
    dispatch(setAddAccountChartsLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updatePettyCash || false,
        payload: {
          link: `/account-charts`,
          accountId: accountData.accountId,
          accountName: values.accountName,
          description: values.description,
        },
      };
      await editAccountChart(data).unwrap();
      dispatch(setAccountChartsChange(!accountChartsChange));
      navigate("/account-charts");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddAccountChartsLoading(false));
    }
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Account </h1>
          </div>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Account Id
            </h4>
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              {accountData.accountId}
            </h4>
          </Form.Group>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Account Name
                      </h4>
                      <Form.Control
                        type="text"
                        name="accountName"
                        onChange={handleChange}
                        placeholder="Enter account name"
                        value={values.accountName}
                        required={true}
                        className="custom-input border-fill"
                      />
                      {touched.accountName && errors.accountName ? (
                        <div className="invalid-feedback">
                          {errors.accountName}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>

                  <Col sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Description
                      </h4>
                      <textarea
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        required={true}
                        placeholder="Add description here"
                        className={
                          touched.description && errors.description
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.description && errors.description ? (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.addAccountChartsLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Edit Account"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditAccountChart;
