import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";

interface UserProfile {
  data: {
    user: {
      _id: string;
      employeeId: string;
      img: string;
      firstName: string;
      lastName: string;
      email: string;
      designation: string;
      department: string;
      dateOfBirth: string;
      country: string;
      phone: string;
      permissions: {
        roleName: string;
        addUser: boolean;
        updateUser: boolean;
        deleteUser: boolean;
        showUser: boolean;
        addEmployee: boolean;
        updateEmployee: boolean;
        deleteEmployee: boolean;
        showEmployee: boolean;
        addProject: boolean;
        updateProject: boolean;
        deleteProject: boolean;
        showProject: boolean;
        addAttendance: boolean;
        updateAttendance: boolean;
        deleteAttendance: boolean;
        showAttendance: boolean;
        addLegal: boolean;
        updateLegal: boolean;
        deleteLegal: boolean;
        showLegal: boolean;
        addPettyCash: boolean;
        updatePettyCash: boolean;
        deletePettyCash: boolean;
        showPettyCash: boolean;
        isUser: boolean;
      };
    };
  };
}

interface UserRoles {
  data: {
    roles: {
      _id: string;
      roleName: string;
      roleUsers: number;
      addUser: number;
      updateUser: number;
      deleteUser: number;
      showUser: number;
      addEmployee: number;
      updateEmployee: number;
      deleteEmployee: number;
      showEmployee: number;
      addProject: number;
      updateProject: number;
      deleteProject: number;
      showProject: number;
      addAttendance: number;
      updateAttendance: number;
      deleteAttendance: number;
      showAttendance: number;
      addLegal: number;
      updateLegal: number;
      deleteLegal: number;
      showLegal: number;
      addPettyCash: number;
      updatePettyCash: number;
      deletePettyCash: number;
      showPettyCash: number;
    }[];
  };
}

interface Designations {
  data: {
    roles: {
      _id: string;
      roleName: string;
    }[];
  };
}

interface UserList {
  data: {
    users: {
      _id: string;
      employeeId: number;
      firstName: string;
      lastName: string;
      role: string;
      email: string;
    }[];
  };
}

export const settingsAPI = createApi({
  reducerPath: "/api/settings",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `/update-user/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/update-password/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getProfile: builder.query<
      UserProfile,
      { userID: string; accessToken: string }
    >({
      query: (data) => ({
        url: `/get-profile-data/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllRoles: builder.mutation<
      UserRoles,
      { userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-roles/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllDesignations: builder.mutation<
      Designations,
      { userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-designations/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllUsers: builder.mutation<
      UserList,
      { userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-users/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: `/add-user/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: `/update-other-user/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addRole: builder.mutation({
      query: (data) => ({
        url: `/add-role/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editRole: builder.mutation({
      query: (data) => ({
        url: `/update-role/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useGetProfileQuery,
  useGetAllRolesMutation,
  useAddUserMutation,
  useEditUserMutation,
  useGetAllUsersMutation,
  useGetAllDesignationsMutation,
  useAddRoleMutation,
  useEditRoleMutation,
} = settingsAPI;
