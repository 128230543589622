import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import AddBasicInfo from "./add-basic-info";
import AddLegalInfo from "./add-legal-info";
import OtherDetails from "./other-details";
import EvaluationDetail from "./evaluation-detail";
import AddAgreementDetail from "./agreement-detail";
import AddAttachments from "./add-attachments";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  setAbscondedEmployeesList,
  setBasicInfo,
  setCertificatesList,
  setEmpLoading,
  setEmployeesList,
  setEvaluationDetails,
  setFinalExitEmployeesList,
  setLegalInfo,
  setOfferLettersList,
  setOtherDetails,
  setProbationEmployeesList,
  setTerminatedEmployeesList,
} from "../../../store/slices/employeeSlice";
// API Imports
import { useAddEmployeeMutation } from "../../../services/Employees";
import { setEmployeeChange } from "../../../store/slices/commonSlice";
import { ToastErrorComponent } from "../../Toasts";

export interface AddEmployeeProps {
  profileImage: string;
  empCode: string;
  empName: string;
  category: string;
  place: string;
  nationality: string;
  religion: string;
  companyName: string;
  mobileNo: string;
  dob: string;
  empBankDetails: {
    empBankName: string;
    empBankAccountNo: string;
    empBankAccountTitle: string;
  }[];
  department: string;
  homeCountryNumber: string;
  iqamaNo: string;
  iqamaExpiry: string;
  professionOnIqama: string;
  sponsorName: string;
  borderNo: string;
  iqamaPrinted: string;
  handOverToEmployee: string;
  passportWithCompany: string;
  passportNo: string;
  otherCategory: string;
  passportExpiry: string;
  visaNo: string;
  dateOfEmpInSA: string;
  visaExpiry: string;
  idNo: string;
  recruiterDetails: {
    recruiterId: string;
    recruiterName: string;
    companyName: string;
  };
  insuranceCompany: string;
  insuranceExpiry: string;
  basicSalary: string;
  dutyHours: string;
  empInsuranceCost: string;
  empInsuranceAttachment: string;
  workPermitExpiry: string;
  salaryPeriod: string;
  campDetails: {
    campId: string;
    campName: string;
    campCity: string;
  };
  agreement: {
    issueDate: string;
    expiryDate: string;
    attachment: string;
  };
  attachment: {
    uId: string;
    title: string;
    link: string;
    date: string;
    empId: number;
    empName: string;
  }[];
  evaluationDetail: {
    obedient: string;
    behavior: string;
    appearance: string;
    remarks: string;
  };
  allowances: {
    uId: string;
    title: string;
    category: string;
    amount: number;
  }[];
  language: {
    uId: string;
    selectedLanguage: string;
    languageProficiency: string;
    writingSkills: string;
  }[];
  experience: {
    uId: string;
    region: string;
    basicSalary: string;
    experienceProficiency: string;
    startDate: string;
    endDate: string;
  }[];
  skills: {
    uId: string;
    skillName: string;
    skillProficiency: string;
    notes: string;
  }[];
}

export const initialValues: AddEmployeeProps = {
  profileImage: "",
  empCode: "",
  empName: "",
  category: "",
  place: "",
  nationality: "",
  companyName: "",
  religion: "",
  mobileNo: "",
  dob: "",
  empBankDetails: [],
  otherCategory: "",
  iqamaNo: "",
  iqamaExpiry: "",
  professionOnIqama: "",
  sponsorName: "",
  department: "",
  homeCountryNumber: "",
  borderNo: "",
  iqamaPrinted: "",
  workPermitExpiry: "",
  handOverToEmployee: "",
  passportWithCompany: "",
  empInsuranceCost: "",
  empInsuranceAttachment: "",
  passportNo: "",
  passportExpiry: "",
  visaNo: "",
  campDetails: {
    campId: "",
    campName: "",
    campCity: "",
  },
  dateOfEmpInSA: "",
  visaExpiry: "",
  idNo: "",
  recruiterDetails: {
    recruiterId: "",
    recruiterName: "",
    companyName: "",
  },
  insuranceCompany: "",
  insuranceExpiry: "",
  basicSalary: "",
  dutyHours: "",
  salaryPeriod: "",
  evaluationDetail: {
    obedient: "",
    behavior: "",
    appearance: "",
    remarks: "",
  },
  allowances: [],
  agreement: {
    issueDate: "",
    expiryDate: "",
    attachment: "",
  },
  attachment: [],
  language: [],
  experience: [],
  skills: [],
};

const AddEmployeeContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.employee);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [addEmployee] = useAddEmployeeMutation();
  // Form Submit Function
  const handleSubmit = async (values: AddEmployeeProps) => {
    const camp = campIds.find(
      (camp) => camp.campId === values.campDetails.campId,
    );
    dispatch(setEmpLoading(true));
    const data = {
      accessToken: accessToken || "",
      userID: userID || "",
      permission: profileData.permissions.addEmployee || false,
      payload: {
        empImage: values.profileImage,
        empName: values.empName,
        empCategory: values.category,
        empPlace: values.place,
        empNationality: values.nationality,
        empReligion: values.religion,
        empCompanyName: values.companyName,
        empPhone: state.countryCode + " " + values.mobileNo,
        empDob: values.dob,
        empBankDetails: values.empBankDetails,
        empIqamaNo: values.iqamaNo,
        empIqamaExpiry: values.iqamaExpiry,
        empProfessionOnIqama: values.professionOnIqama,
        empDepartment: values.department,
        workPermitExpiry: values.workPermitExpiry,
        empHomeCountryNumber: values.homeCountryNumber,
        campDetails: {
          campId: values.campDetails.campId.includes("CMP")
            ? values.campDetails.campId
            : "",
          campName: values.campDetails.campId.includes("CMP")
            ? camp?.campName
            : values.campDetails.campId,
          campCity: values.campDetails.campId.includes("CMP")
            ? camp?.campCity
            : "",
        },
        dutyHours: values.dutyHours,
        salaryPeriod: values.salaryPeriod,
        empSponsorName: values.sponsorName,
        empInsuranceCost: parseInt(values.empInsuranceCost),
        insuranceHistroy: [
          {
            updatedOn: new Date(),
            cost: parseInt(values.empInsuranceCost),
            updatedBy: profileData.employeeId,
            updaterName: profileData.firstName + " " + profileData.lastName,
            remarks: "",
            attachemnt: values.empInsuranceAttachment,
          },
        ],
        empBorderNo: values.borderNo,
        empIqamaPrinted: values.iqamaPrinted,
        iqamaHandOverToEmployee: values.handOverToEmployee,
        empPassportWithCompany: values.passportWithCompany,
        empPassportNo: values.passportNo,
        empPassportExpiry: values.passportExpiry,
        empVisaNo: values.visaNo,
        dateOfEmpInSA: values.dateOfEmpInSA,
        empVisaExpiry: values.visaExpiry,
        empIdNo: values.idNo,
        recruiterDetails: {
          recruiterId: values.recruiterDetails.recruiterId,
          recruiterName: values.recruiterDetails.recruiterName,
          companyName: values.recruiterDetails.companyName,
        },
        empInsuranceCompany: values.insuranceCompany,
        empInsuranceExpiry: values.insuranceExpiry,
        basicSalary: parseInt(values.basicSalary),
        evaluationDetail: {
          obedient: values.evaluationDetail.obedient,
          behavior: values.evaluationDetail.behavior,
          appearance: values.evaluationDetail.appearance,
          remarks: values.evaluationDetail.remarks,
        },
        language: values.language,
        experience: values.experience,
        skills: values.skills,
        agreement: {
          issueDate: values.agreement.issueDate,
          expiryDate: values.agreement.expiryDate,
          attachment: values.agreement.attachment,
        },
        attachments: values.attachment,
        allowances: values.allowances,
        link: "/employees",
      },
    };
    try {
      await addEmployee(data).unwrap();
      dispatch(setEmployeeChange(!employeeChange));
      dispatch(setEmpLoading(false));
      dispatch(setEmployeesList(true));
      dispatch(setProbationEmployeesList(false));
      dispatch(setAbscondedEmployeesList(false));
      dispatch(setTerminatedEmployeesList(false));
      dispatch(setFinalExitEmployeesList(false));
      dispatch(setOfferLettersList(false));
      dispatch(setCertificatesList(false));
      dispatch(setEvaluationDetails(false));
      dispatch(setOtherDetails(false));
      dispatch(setLegalInfo(false));
      dispatch(setBasicInfo(true));
      navigate("/employees");
    } catch (e: any) {
      if (e) {
        // Scroll to top
        window.scrollTo(0, 0);
        dispatch(setEmpLoading(false));
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    }
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({});

  return (
    <div className="add-employee section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Employee</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="employee-form mt-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {state.basicInfo ? (
                    <AddBasicInfo
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setErrorMessage={setErrorContent}
                      setShowError={setShow}
                    />
                  ) : state.legalInfo ? (
                    <AddLegalInfo
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setShowError={setShow}
                      setErrorMessage={setErrorContent}
                    />
                  ) : state.otherDetails ? (
                    <OtherDetails
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setErrorMessage={setErrorContent}
                      setShowError={setShow}
                    />
                  ) : state.evaluationDetails ? (
                    <EvaluationDetail
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setShow={setShow}
                      setErrorContent={setErrorContent}
                      errors={errors}
                      touched={touched}
                    />
                  ) : state.agreementDetails ? (
                    <AddAgreementDetail
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setShow={setShow}
                      setErrorContent={setErrorContent}
                      errors={errors}
                      touched={touched}
                    />
                  ) : state.attachments ? (
                    <AddAttachments
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setShow={setShow}
                      setErrorContent={setErrorContent}
                      errors={errors}
                      touched={touched}
                    />
                  ) : null}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AddEmployeeContainer;
