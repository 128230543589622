import React from "react";
import { Common, LettersIcons } from "../../assets/images";

interface Props {
  heading: string;
  children: React.ReactNode;
}

const LetterFormatA = ({ heading, children }: Props) => {
  return (
    <div className="letter-format-a ">
      <div className="letter-right-bg"></div>
      <div className="letter-logo d-flex align-items-center gap-2">
        <img src={Common.Logo} width="130px" alt="logo" />
        <div>
          <h3
            className="logo-text mb-0 mt-4"
            style={{ color: "#202020", fontSize: "24px", fontWeight: "700" }}
          >
            Senyar Arabian Trading Co. LTD
          </h3>
          <h3
            className="logo-text logo-arabic mb-0"
            style={{ color: "#202020", fontSize: "24px", fontWeight: "700" }}
          >
            شركة سنيار العربية التجارية المحدودة
          </h3>
        </div>
      </div>
      <h4 className="heading text-end mt-4" style={{ textAlign: "center" }}>
        {heading}
      </h4>
      <div className="letter-content mt-2">
        <div className="letter-head text-end">
          <div className="mt-4">
            <div className="ref-no d-flex align-items-center gap-2 justify-content-end">
              <h4 className="sub-heading textBlack mb-0">Date:</h4>
              <p
                className="sub-heading normal-font-weight text-black mb-0"
                style={{ fontSize: "16px", color: "#29406C" }}
              >
                {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
        <div className="letter-body">{children}</div>
      </div>
      <div
        className="compnay-detail d-flex justify-content-end"
        style={{ position: "absolute", bottom: "10px", right: "60px" }}
      >
        <ul>
          <li className="d-flex align-items-center gap-2 mb-3">
            <img src={LettersIcons.Phone} width="30" height="30" alt="phone" />
            <h4 className="sub-heading normal-font-weight text-black mb-0">
              123-456-789
            </h4>
          </li>
          <li className="d-flex align-items-center gap-2 mb-3">
            <img src={LettersIcons.Mail} width="30" height="30" alt="phone" />
            <h4 className="sub-heading normal-font-weight text-black mb-0">
              contact@company.com
            </h4>
          </li>
          <li className="d-flex align-items-center gap-2 mb-3">
            <img
              src={LettersIcons.Location}
              width="30"
              height="30"
              alt="phone"
            />
            <h4 className="sub-heading normal-font-weight text-black mb-0">
              123, anywhere in Dubai, UAE
            </h4>
          </li>
        </ul>
      </div>
      <div className="letter-left-bg"></div>
    </div>
  );
};
export default LetterFormatA;
