import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { AttendanceIcons, Common } from "../../assets/images";
import SelectSearch from "react-select-search";
import { useNavigate } from "react-router-dom";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setAddAttendanceEmployeeLoading,
  setAddAttendanceLoading,
} from "../../store/slices/attendanceSlice";
import { setAttendanceChange } from "../../store/slices/commonSlice";
import { LoaderBlue, LoaderWhite } from "../Lotties";
// API imports
import { useAddAttendanceMutation } from "../../services/Attendance";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import DatePicker from "react-datepicker";
import { AllEmployees } from "../../interfaces/employee";

const AttendanceForm = () => {
  const dispatch = useAppDispatch();
  const [selectDate, setSelectDate] = useState(new Date());
  const currentDate = selectDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [projectId, setProjectId] = useState("");
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const idolEmployees = useAppSelector((state) => state.project.idolEmployees);
  const state = useAppSelector((state) => state.attendance);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const attendanceChange = useAppSelector(
    (state) => state.common.attendanceChange,
  );
  const [workingHours, setWorkingHours] = useState<{ [key: string]: string }>(
    {},
  );
  const [projectEmployees, setProjectEmployees] = useState<AllEmployees[]>([]);
  const [idolEmployeeData, setIdolEmployeeData] = useState<AllEmployees[]>([]);
  const [status, setStatus] = useState<{ [key: string]: string }>({}); // absent, present, leave
  const [statusChanged, setStatusChanged] = useState(false); // absent, present, leave
  const [allStatusChanged, setAllStatusChanged] = useState(false); // absent, present, leave
  const [attendanceData, setAttendanceData] = useState([
    {
      empId: 0,
      empName: "",
      projectId: "",
      date: selectDate.toLocaleDateString(),
      projectedStatus: "",
      actualStatus: "",
      fromDate: "",
      toDate: "",
      hours: 9,
      actualHours: 0,
      overTime: 0,
      actualOverTime: 0,
      workingHours: 0,
    },
  ]);
  //   filter project with projectId
  const project = projectId.includes("PR")
    ? projectIds.filter((project) => project.projectId === projectId)
    : [];

  const managementEmployees = projectId
    ? employees.filter(
        (employee) =>
          employee.empPlace === "Management" && !employee.exitStatus,
      )
    : [];

  //   filter employees with projectEmployees array
  useEffect(() => {
    if (
      selectDate &&
      projectId &&
      projectId.includes("PR") &&
      projectIds.length > 0
    ) {
      const projectTimelineEmployees = employees.filter((employee) => {
        const transferOnDate = employee.transferTimeline.find(
          (timelineItem) => {
            const fromDate = new Date(timelineItem.fromDate);
            const toDate = new Date(timelineItem.toDate);
            const currentDate = new Date(selectDate);
            // set current date time to 00:00:00
            currentDate.setHours(0, 0, 0, 0);
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);
            return currentDate >= fromDate && currentDate <= toDate;
          },
        );
        return (
          transferOnDate &&
          transferOnDate.projectId === projectId &&
          !employee.exitStatus
        );
      });
      const projectEmployees = employees.filter(
        (employee) =>
          employee.empProjectId === projectId &&
          new Date(selectDate) >= new Date(employee.empProjectJoiningDate) &&
          !employee.exitStatus,
      );
      setProjectEmployees([...projectTimelineEmployees, ...projectEmployees]);
    }
  }, [selectDate, projectId, employees]);

  useEffect(() => {
    if (selectDate && projectId && projectId === "Idol Employees") {
      const filteredEmployees = idolEmployees.filter((employee) => {
        // Check if the employee's timeline contains the selectDate
        const transferOnDate = employee.transferTimeline.find(
          (timelineItem) => {
            const fromDate = new Date(timelineItem.fromDate);
            const toDate = new Date(timelineItem.toDate);
            const currentDate = new Date(selectDate);
            currentDate.setHours(0, 0, 0, 0);
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);
            return currentDate >= fromDate && currentDate <= toDate;
          },
        );

        // Include the employee if either condition is met:
        // - The selectDate is not found in their timeline
        // - They were not assigned to a project on that day
        return !transferOnDate || !transferOnDate.projectId;
      });
      setIdolEmployeeData([...filteredEmployees]);
    }
  }, [selectDate, projectId, idolEmployees]);

  const projectTimeLineFunction = (index: number) => {
    if (projectId === "Idol Employees") {
      const timeLine =
        idolEmployeeData.length > 0
          ? idolEmployeeData[index].transferTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.projectId.toString();
      } else {
        return projectId.toString();
      }
    } else {
      const timeLine =
        projectEmployees.length > 0
          ? projectEmployees[index].transferTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.projectId.toString();
      } else {
        return projectId.toString();
      }
    }
  };

  const workingHoursTimeLineFunction = (index: number) => {
    const employee = projectEmployees[index];
    const timeLine =
      projectEmployees.length > 0
        ? employee.transferTimeline.find((item) => {
            const fromDate = new Date(item.fromDate);
            const toDate = new Date(item.toDate);
            const selectDate1 = new Date(selectDate);
            selectDate1.setHours(0, 0, 0, 0);
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);
            return (
              new Date(selectDate1) >= new Date(fromDate) &&
              new Date(selectDate1) <= new Date(toDate)
            );
          })
        : null;

    if (timeLine) {
      return timeLine.workingHours;
    } else {
      return employee.empProjectWorkingHours;
    }
  };

  const dutyHoursTimelineFunction = (index: number) => {
    if (projectId === "Management") {
      const mEmployee = managementEmployees[index];
      const timeLine =
        managementEmployees.length > 0
          ? mEmployee.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return mEmployee.dutyHours;
      }
    } else if (projectId === "Idol Employees") {
      const iEmployee = idolEmployeeData[index];
      const timeLine =
        idolEmployeeData.length > 0
          ? iEmployee.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return iEmployee.dutyHours;
      }
    } else {
      const projectEmployee = projectEmployees[index];
      const timeLine =
        projectEmployees.length > 0
          ? projectEmployee.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return projectEmployee.dutyHours;
      }
    }
  };

  const dutyHouTimelineFunction = (index: string) => {
    if (projectId === "Management") {
      const mEmployee = managementEmployees.find((emp) => emp._id === index);
      const timeLine =
        managementEmployees.length > 0
          ? mEmployee?.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return mEmployee ? mEmployee.dutyHours : 0;
      }
    } else if (projectId === "Idol Employees") {
      const iEmployee = idolEmployeeData.find((emp) => emp._id === index);
      const timeLine =
        idolEmployeeData.length > 0
          ? iEmployee?.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return iEmployee ? iEmployee.dutyHours : 0;
      }
    } else {
      const projectEmployee = projectEmployees.find((emp) => emp._id === index);
      const timeLine =
        projectEmployees.length > 0
          ? projectEmployee?.salaryTimeline.find((item) => {
              const fromDate = new Date(item.fromDate);
              const toDate = new Date(item.toDate);
              const selectDate1 = new Date(selectDate);
              selectDate1.setHours(0, 0, 0, 0);
              fromDate.setHours(0, 0, 0, 0);
              toDate.setHours(0, 0, 0, 0);
              return (
                new Date(selectDate1) >= new Date(fromDate) &&
                new Date(selectDate1) <= new Date(toDate)
              );
            })
          : null;

      if (timeLine) {
        return timeLine.dutyHours;
      } else {
        return projectEmployee ? projectEmployee.dutyHours : 0;
      }
    }
  };

  const [checkinTime, setCheckinTime] = useState<{ [key: string]: string }>({});
  const [checkoutTime, setCheckoutTime] = useState<{ [key: string]: string }>(
    {},
  );

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Location",
      "Checkin Time",
      "Checkout Time",
      "Working Hours",
      "Leave",
      "Absent",
      "Present",
      "Paid Holiday",
    ],
    tableData:
      projectId === "Management"
        ? managementEmployees
        : projectId === "Idol Employees"
          ? idolEmployeeData
          : projectEmployees,
  };
  const options = projectIds.map((project) => ({
    name: project.projectId + " - " + project.projectName, // Use the actual property name for the project name
    value: project.projectId,
  }));
  // Add custom options for "management" and "idol employee"
  profileData.permissions.roleName === "Super Admin" &&
    options.splice(0, 0, { name: "Management", value: "Management" });
  profileData.permissions.roleName === "General Manager" &&
    options.splice(0, 0, { name: "Management", value: "Management" });

  options.splice(1, 0, { name: "Idol Employees", value: "Idol Employees" });
  // Handle Status
  const handleStatus = (id: string, status: string) => {
    setStatus((prevState) => ({
      ...prevState,
      [id]: prevState[id] === status ? "" : status, // Toggle the status
    }));
    setStatusChanged(!statusChanged);
    if (status === "Paid Holiday") {
      setCheckinTime((prevState) => ({
        ...prevState,
        [id]: "00:00",
      }));
      setCheckoutTime((prevState) => ({
        ...prevState,
        [id]: "00:00",
      }));
      return;
    } else {
      if (projectId !== "Management" && projectId !== "Idol Employees") {
        // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
        setCheckinTime((prevState) => ({
          ...prevState,
          [id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id) + 9}:00`,
        }));
        //   set working hours for all employees

        setWorkingHours((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id)}`,
        }));
      }
      if (projectId === "Management") {
        // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
        setCheckinTime((prevState) => ({
          ...prevState,
          [id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id) + 9}:00`,
        }));
        //   set working hours for all employees

        setWorkingHours((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id)}`,
        }));
      }
      if (projectId === "Idol Employees") {
        // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
        setCheckinTime((prevState) => ({
          ...prevState,
          [id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id) + 9}:00`,
        }));
        //   set working hours for all employees

        setWorkingHours((prevState) => ({
          ...prevState,
          [id]: `${dutyHouTimelineFunction(id)}`,
        }));
      }
    }
  };
  const calculateWorkingHours = (checkin: string, checkout: string) => {
    const checkinHours = parseInt(checkin.split(":")[0]);
    const checkoutHours = parseInt(checkout.split(":")[0]);
    const hours = checkoutHours - checkinHours;
    return hours;
  };

  //   Handle Attendance Data
  const handleAttendanceData = () => {
    // Initialize check-in and check-out times with default values
    const defaultCheckin = "09:00";
    const defaultCheckout = "18:00";

    const getHours = (empId: string) => {
      const hours =
        status[empId] === "Absent"
          ? 0
          : calculateWorkingHours(
              checkinTime[empId] || defaultCheckin,
              checkoutTime[empId] || defaultCheckout,
            );

      return hours;
    };

    const newData =
      projectId === "Management"
        ? managementEmployees.map((employee, index) => ({
            empId: employee.empId,
            empName: employee.empName,
            projectId: projectId,
            date: selectDate.toLocaleDateString(),
            projectedStatus: status[employee._id] || "", // Default to "Absent" if not set
            actualStatus: status[employee._id] || "", // Default to "Absent" if not set
            fromDate: checkinTime[employee._id] || defaultCheckin,
            toDate: checkoutTime[employee._id] || defaultCheckout,
            hours:
              status[employee._id] === "Paid Holiday"
                ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                : getHours(employee._id),
            actualHours:
              status[employee._id] === "Paid Holiday"
                ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                : getHours(employee._id),
            overTime:
              getHours(employee._id) - dutyHoursTimelineFunction(index) > 0
                ? getHours(employee._id) - dutyHoursTimelineFunction(index)
                : 0,
            actualOverTime:
              getHours(employee._id) - dutyHoursTimelineFunction(index) > 0
                ? getHours(employee._id) - dutyHoursTimelineFunction(index)
                : 0,
            workingHours: dutyHoursTimelineFunction(index),
          }))
        : projectId === "Idol Employees"
          ? idolEmployeeData.map((employee, index) => ({
              empId: employee.empId,
              empName: employee.empName,
              projectId: projectTimeLineFunction(index),
              date: selectDate.toLocaleDateString(),
              projectedStatus: status[employee._id] || "", // Default to "Absent" if not set
              actualStatus: status[employee._id] || "", // Default to "Absent" if not set
              fromDate: checkinTime[employee._id] || defaultCheckin,
              toDate: checkoutTime[employee._id] || defaultCheckout,
              hours:
                status[employee._id] === "Paid Holiday"
                  ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                  : getHours(employee._id),
              actualHours:
                status[employee._id] === "Paid Holiday"
                  ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                  : getHours(employee._id),
              overTime:
                getHours(employee._id) - dutyHoursTimelineFunction(index) > 0
                  ? getHours(employee._id) - dutyHoursTimelineFunction(index)
                  : 0,
              actualOverTime:
                getHours(employee._id) - dutyHoursTimelineFunction(index) > 0
                  ? getHours(employee._id) - dutyHoursTimelineFunction(index)
                  : 0,
              workingHours: dutyHoursTimelineFunction(index),
            }))
          : projectEmployees.map((employee, index) => ({
              empId: employee.empId,
              empName: employee.empName,
              projectId: projectTimeLineFunction(index),
              date: selectDate.toLocaleDateString(),
              projectedStatus: status[employee._id] || "", // Default to "Absent" if not set
              actualStatus: status[employee._id] || "", // Default to "Absent" if not set
              fromDate: checkinTime[employee._id] || defaultCheckin,
              toDate: checkoutTime[employee._id] || defaultCheckout,
              hours:
                status[employee._id] === "Paid Holiday"
                  ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                  : getHours(employee._id),
              actualHours:
                status[employee._id] === "Paid Holiday"
                  ? dutyHoursTimelineFunction(index) + getHours(employee._id)
                  : getHours(employee._id),
              overTime:
                getHours(employee._id) - workingHoursTimeLineFunction(index) > 0
                  ? getHours(employee._id) - workingHoursTimeLineFunction(index)
                  : 0,
              actualOverTime:
                getHours(employee._id) - workingHoursTimeLineFunction(index) > 0
                  ? getHours(employee._id) - workingHoursTimeLineFunction(index)
                  : 0,
              workingHours: workingHoursTimeLineFunction(index),
            }));

    setAttendanceData(newData);
  };

  const handleWorkingHours = () => {
    // Initialize check-in and check-out times with default values
    const defaultCheckin = "09:00";
    const defaultCheckout = "18:00";
    // Calculate working hours from checkin and checkout time
    projectId === "Management"
      ? managementEmployees.map((employee) => {
          const checkin = checkinTime[employee._id] || defaultCheckin;
          const checkout = checkoutTime[employee._id] || defaultCheckout;
          const checkinHours = parseInt(checkin.split(":")[0]);
          const checkoutHours = parseInt(checkout.split(":")[0]);
          const hours = checkoutHours - checkinHours;
          setWorkingHours((prevState) => ({
            ...prevState,
            [employee._id]: `${hours}`,
          }));
        })
      : projectId === "Idol Employees"
        ? idolEmployeeData.map((employee) => {
            const checkin = checkinTime[employee._id] || defaultCheckin;
            const checkout = checkoutTime[employee._id] || defaultCheckout;
            const checkinHours = parseInt(checkin.split(":")[0]);
            const checkoutHours = parseInt(checkout.split(":")[0]);
            const hours = checkoutHours - checkinHours;
            setWorkingHours((prevState) => ({
              ...prevState,
              [employee._id]: `${hours}`,
            }));
          })
        : projectEmployees.map((employee) => {
            const checkin = checkinTime[employee._id] || defaultCheckin;
            const checkout = checkoutTime[employee._id] || defaultCheckout;
            const checkinHours = parseInt(checkin.split(":")[0]);
            const checkoutHours = parseInt(checkout.split(":")[0]);
            const hours = checkoutHours - checkinHours;
            setWorkingHours((prevState) => ({
              ...prevState,
              [employee._id]: `${hours}`,
            }));
          });
  };

  useEffect(() => {
    handleWorkingHours();
    handleAttendanceData();
  }, [projectId, statusChanged]);

  const allPresent =
    projectId === "Management"
      ? managementEmployees.every(
          (employee) => status[employee._id] === "Present",
        )
      : projectId === "Idol Employees"
        ? idolEmployeeData.every(
            (employee) => status[employee._id] === "Present",
          )
        : projectEmployees.every(
            (employee) => status[employee._id] === "Present",
          );
  const allAbsent =
    projectId === "Management"
      ? managementEmployees.every(
          (employee) => status[employee._id] === "Absent",
        )
      : projectId === "Idol Employees"
        ? idolEmployeeData.every(
            (employee) => status[employee._id] === "Absent",
          )
        : projectEmployees.every(
            (employee) => status[employee._id] === "Absent",
          );
  const allPaidHoliday =
    projectId === "Management"
      ? managementEmployees.every(
          (employee) => status[employee._id] === "Paid Holiday",
        )
      : projectId === "Idol Employees"
        ? idolEmployeeData.every(
            (employee) => status[employee._id] === "Paid Holiday",
          )
        : projectEmployees.every(
            (employee) => status[employee._id] === "Paid Holiday",
          );
  const handleSelectAllPresent = () => {
    const newStatus =
      projectId === "Management"
        ? managementEmployees.reduce((acc: any, employee) => {
            acc[employee._id] = allPresent ? "" : "Present";
            return acc;
          }, {})
        : projectId === "Idol Employees"
          ? idolEmployeeData.reduce((acc: any, employee) => {
              acc[employee._id] = allPresent ? "" : "Present";
              return acc;
            }, {})
          : projectEmployees.reduce((acc: any, employee) => {
              acc[employee._id] = allPresent ? "" : "Present";
              return acc;
            }, {});

    setStatus((prevState) => ({
      ...prevState,
      ...newStatus,
    }));
    setAllStatusChanged(!allStatusChanged);
    setStatusChanged(!statusChanged);
  };

  const handleSelectAllAbsent = () => {
    const newStatus =
      projectId === "Management"
        ? managementEmployees.reduce((acc: any, employee) => {
            acc[employee._id] = allAbsent ? "" : "Absent";
            return acc;
          }, {})
        : projectId === "Idol Employees"
          ? idolEmployeeData.reduce((acc: any, employee) => {
              acc[employee._id] = allAbsent ? "" : "Absent";
              return acc;
            }, {})
          : projectEmployees.reduce((acc: any, employee) => {
              acc[employee._id] = allAbsent ? "" : "Absent";
              return acc;
            }, {});

    setStatus((prevState) => ({
      ...prevState,
      ...newStatus,
    }));
    setAllStatusChanged(!allStatusChanged);
    setStatusChanged(!statusChanged);
  };

  const handleSelectAllPaidHoliday = () => {
    const newStatus =
      projectId === "Management"
        ? managementEmployees.reduce((acc: any, employee) => {
            acc[employee._id] = allPaidHoliday ? "" : "Paid Holiday";
            return acc;
          }, {})
        : projectId === "Idol Employees"
          ? idolEmployeeData.reduce((acc: any, employee) => {
              acc[employee._id] = allPaidHoliday ? "" : "Paid Holiday";
              return acc;
            }, {})
          : projectEmployees.reduce((acc: any, employee) => {
              acc[employee._id] = allPaidHoliday ? "" : "Paid Holiday";
              return acc;
            }, {});

    setStatus((prevState) => ({
      ...prevState,
      ...newStatus,
    }));
    setStatusChanged(!statusChanged);
    // set checkin and checkout time to 00:00 for paid holiday
    if (projectId === "Management") {
      managementEmployees.forEach((employee) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
      });
    }
    if (projectId === "Idol Employees") {
      idolEmployeeData.forEach((employee) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
      });
    }
    if (projectId !== "Management" && projectId !== "Idol Employees") {
      projectEmployees.forEach((employee) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: "00:00",
        }));
      });
    }
  };

  // API
  const [addAttendance] = useAddAttendanceMutation();

  const handleAddAttendance = async () => {
    dispatch(setAddAttendanceLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addAttendance || false,
        payload: {
          type: projectId,
          attendences: attendanceData.filter(
            (item) =>
              item.projectedStatus === "Present" ||
              item.projectedStatus === "Absent" ||
              item.projectedStatus === "Leave" ||
              item.projectedStatus === "Paid Holiday",
          ),
          link: "/attendance/attendance-log",
        },
      };
      await addAttendance(data).unwrap();
      dispatch(setAttendanceChange(!attendanceChange));
      window.scrollTo(0, 0);
      setStatus({});
      setStatusChanged(!statusChanged);
      setSuccessShow(true);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddAttendanceLoading(false));
    }
  };

  // set default checkin and checkout time for the project employees from empProjectWorkingHours
  useEffect(() => {
    if (projectId !== "Management" && projectId !== "Idol Employees") {
      // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
      projectEmployees.forEach((employee, index) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index) + 9}:00`,
        }));
      });
      //   set working hours for all employees
      projectEmployees.forEach((employee, index) => {
        setWorkingHours((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index)}`,
        }));
      });
    }
  }, [projectId, allStatusChanged, selectDate]);

  useEffect(() => {
    if (projectId === "Management") {
      // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
      managementEmployees.forEach((employee, index) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index) + 9}:00`,
        }));
      });
      //   set working hours for all employees
      managementEmployees.forEach((employee, index) => {
        setWorkingHours((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index)}`,
        }));
      });
    }
  }, [projectId, allStatusChanged, selectDate]);

  useEffect(() => {
    if (projectId === "Idol Employees") {
      // Set default checkin time to 09:00 and checkout time according to empProjectWorkingHours
      idolEmployeeData.forEach((employee, index) => {
        setCheckinTime((prevState) => ({
          ...prevState,
          [employee._id]: "09:00",
        }));
        setCheckoutTime((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index) + 9}:00`,
        }));
      });
      //   set working hours for all employees
      idolEmployeeData.forEach((employee, index) => {
        setWorkingHours((prevState) => ({
          ...prevState,
          [employee._id]: `${dutyHoursTimelineFunction(index)}`,
        }));
      });
    }
  }, [projectId, allStatusChanged, selectDate]);

  useEffect(() => {
    setAllStatusChanged(!allStatusChanged);
  }, [selectDate, projectId]);

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={successShow}
        setShow={setSuccessShow}
        content={`Attendance for this ${projectId} has been updated successfull!`}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Attendance Form</h1>
          </div>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Select Project/Category
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
              <SelectSearch
                name="projectId"
                options={options}
                value={projectId}
                onChange={(value: any) => {
                  setProjectId(value);
                }}
                search={true}
                placeholder="Select Project Id"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Select Date
            </h4>
            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
              <DatePicker
                name="startDate"
                selected={selectDate ? new Date(selectDate) : null}
                onChange={(e: any) => {
                  setSelectDate(e);
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                maxDate={new Date()}
                placeholderText="Select date"
                className="w-100 custom-input"
                dateFormat="yyyy-MM-d"
              />
              <img
                src={Common.Calendar}
                className="calendar-date-recur"
                alt="calendarblack"
              />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="12">
          {state.addAttendanceEmployeeLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : (
            <>
              {tableData.tableData.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <div className="settings d-flex align-items-center gap-3">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          onClick={handleSelectAllPresent}
                          checked={allPresent}
                          label="Present All"
                          readOnly={true}
                          name="userManagement.edit"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          onClick={handleSelectAllAbsent}
                          label="Absent All"
                          checked={allAbsent}
                          readOnly={true}
                          name="userManagement.edit"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          onClick={handleSelectAllPaidHoliday}
                          label="All Paid Holiday"
                          checked={allPaidHoliday}
                          readOnly={true}
                          name="userManagement.edit"
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <h5 className="sub-heading mb-0">{currentDate}</h5>
                    </div>
                  </div>
                  <Table
                    striped
                    bordered
                    responsive
                    className="mt-5 text-center"
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th className="border-0" key={index}>
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>{index + 1}</td>
                          <td className="cursor-pointer">{item.empId}</td>
                          <td className="cursor-pointer">{item.empName}</td>
                          <td>
                            {projectId === "Management"
                              ? "Management"
                              : projectTimeLineFunction(index)}
                          </td>
                          <td>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Control
                                type="time"
                                name="checkinTime"
                                value={checkinTime[item._id] || "09:00"}
                                onChange={(e) => {
                                  setCheckinTime((prevState) => ({
                                    ...prevState,
                                    [item._id]: e.target.value,
                                  }));
                                  setStatusChanged(!statusChanged);
                                }}
                                placeholder="Enter checkin time"
                                className="custom-input border-fill"
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Control
                                type="time"
                                name="checkoutTime"
                                value={checkoutTime[item._id] || "18:00"}
                                onChange={(e) => {
                                  setCheckoutTime((prevState) => ({
                                    ...prevState,
                                    [item._id]: e.target.value,
                                  }));
                                  setStatusChanged(!statusChanged);
                                }}
                                placeholder="Enter checkout time"
                                className="custom-input border-fill"
                              />
                            </Form.Group>
                          </td>
                          {/* Calculate working hours accroding to checkin and checkout time */}
                          <td>{workingHours[item._id] || 9}</td>
                          <td>
                            <button
                              className="btn p-0 border-0 me-2"
                              onClick={() => handleStatus(item._id, "Leave")}
                            >
                              {status[item._id] === "Leave" ? (
                                <img
                                  src={AttendanceIcons.FilledCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              ) : (
                                <img
                                  src={AttendanceIcons.EmptyCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              )}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn p-0 border-0 me-2"
                              onClick={() => handleStatus(item._id, "Absent")}
                            >
                              {status[item._id] === "Absent" ? (
                                <img
                                  src={AttendanceIcons.FilledCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              ) : (
                                <img
                                  src={AttendanceIcons.EmptyCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              )}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn p-0 border-0 me-2"
                              onClick={() => handleStatus(item._id, "Present")}
                            >
                              {status[item._id] === "Present" ? (
                                <img
                                  src={AttendanceIcons.FilledCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              ) : (
                                <img
                                  src={AttendanceIcons.EmptyCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              )}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn p-0 border-0 me-2"
                              onClick={() =>
                                handleStatus(item._id, "Paid Holiday")
                              }
                            >
                              {status[item._id] === "Paid Holiday" ? (
                                <img
                                  src={AttendanceIcons.FilledCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              ) : (
                                <img
                                  src={AttendanceIcons.EmptyCircle}
                                  width="22px"
                                  height="22px"
                                  alt="edit-user"
                                />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="mt-4 text-end">
                    <button
                      className="btn primary-button w-25"
                      onClick={handleAddAttendance}
                    >
                      {state.addAttendanceLoading ? (
                        <LoaderWhite height={30} width={30} />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No employee found in this project</h1>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default AttendanceForm;
