import React, { useEffect } from "react";
import CommonBGLayout from "../components/CommonBGLayout";
import { Common } from "../assets/images";

const PreLoader = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/login";
    }, 2000);
  }, []);
  return (
    <CommonBGLayout>
      <div className="preloader text-center">
        <img src={Common.Logo} alt="pre-loader" />
        <h3 className="logo-text mb-0 mt-4">Senyar Arabian Trading Co. LTD</h3>
        <h3 className="logo-text logo-arabic mb-0">
          شركة سنيار العربية التجارية المحدودة
        </h3>
      </div>
    </CommonBGLayout>
  );
};

export default PreLoader;
