import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";

interface CategoriesProps {
  data: {
    category: string;
    price: number;
    overTime: number;
    nationality: string;
    accomodation: string;
    transportation: string;
    food: string;
    workingHours: number;
    holidays: number;
    duration: number;
    overTimePrice: number;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    category: string;
    price: number;
    overTime: number;
    nationality: string;
    accomodation: string;
    transportation: string;
    food: string;
    workingHours: number;
    holidays: number;
    duration: number;
    overTimePrice: number;
  }) => void;
  uniqueId: string;
}

const CategoriesSet: React.FC<CategoriesProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const categories = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Heavy Driver",
    "Other",
  ];
  const [category, setCategory] = useState(
    data.category
      ? categories.includes(data.category)
        ? data.category
        : "Other"
      : "",
  );
  const [price, setPrice] = useState(data.price);
  const [overTime, setOverTime] = useState(data.overTime);
  const [nationality, setNationality] = useState(data.nationality);
  const [accomodation, setAccomodation] = useState(data.accomodation);
  const [transportation, setTransportation] = useState(data.transportation);
  const [food, setFood] = useState(data.food);
  const [workingHours, setWorkingHours] = useState(data.workingHours);
  const [holidays, setHolidays] = useState(data.holidays);
  const [duration, setDuration] = useState(data.duration);
  const [overTimePrice, setOverTimePrice] = useState(data.overTimePrice);
  const [otherCategory, setOtherCategory] = useState(
    categories.includes(data.category) ? "" : data.category,
  );

  const handleInputChange = () => {
    onInputChange({
      category: category === "Other" ? otherCategory : category,
      price,
      overTime,
      nationality,
      accomodation,
      transportation,
      food,
      workingHours,
      holidays,
      duration,
      overTimePrice,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [
    category,
    price,
    overTime,
    nationality,
    accomodation,
    transportation,
    food,
    workingHours,
    holidays,
    duration,
    otherCategory,
    overTimePrice,
  ]);

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
  ];

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  className="custom-input border-fill"
                >
                  <option value="">Select Category</option>
                  {categories.map((item: string, index: number) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {category === "Other" && (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Category Name
                  </h4>
                  <Form.Control
                    type="text"
                    name="category"
                    placeholder="Enter category name"
                    onChange={(e) => setOtherCategory(e.target.value)}
                    value={otherCategory}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            )}
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Nationality
                </h4>
                <Form.Select
                  name="nationality"
                  onChange={(e) => setNationality(e.target.value)}
                  value={nationality}
                  className="custom-input border-fill"
                >
                  <option value="">Select Nationality</option>
                  {countryCodes.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Transportation
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setTransportation(e.target.value)}
                  value={transportation}
                  className="custom-input border-fill"
                >
                  <option value="">Select Transportation</option>
                  <option value="Provided by Client">Provided by Client</option>
                  <option value="Provided by Company">
                    Provided by Company
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Accomodation
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setAccomodation(e.target.value)}
                  value={accomodation}
                  className="custom-input border-fill"
                >
                  <option value="">Select Accomodation</option>
                  <option value="Provided by Client">Provided by Client</option>
                  <option value="Provided by Company">
                    Provided by Company
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Food
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setFood(e.target.value)}
                  value={food}
                  className="custom-input border-fill"
                >
                  <option value="">Select Food</option>
                  <option value="Provided by Client">Provided by Client</option>
                  <option value="Provided by Company">
                    Provided by Company
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Working Hours
                </h4>
                <Form.Control
                  type="text"
                  name="workingHours"
                  placeholder="Enter working hours"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setWorkingHours(Number(e.target.value));
                    }
                  }}
                  value={workingHours}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Holidays
                </h4>
                <Form.Control
                  type="text"
                  name="holidays"
                  placeholder="Enter holidays"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setHolidays(Number(e.target.value));
                    }
                  }}
                  value={holidays}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Duration
                </h4>
                <Form.Control
                  type="text"
                  name="duration"
                  placeholder="Enter duration"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setDuration(Number(e.target.value));
                    }
                  }}
                  value={duration}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Overtime
                </h4>
                <Form.Control
                  type="text"
                  name="overTime"
                  placeholder="Enter overtime"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setOverTime(Number(e.target.value));
                    }
                  }}
                  value={overTime}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Over Time Price
                </h4>
                <Form.Control
                  type="text"
                  name="overTimePrice"
                  placeholder="Enter over time price"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setOverTimePrice(Number(e.target.value));
                    }
                  }}
                  value={overTimePrice}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Price per unit
                </h4>
                <Form.Control
                  type="text"
                  name="price"
                  placeholder="Enter price per unit"
                  onChange={(e) => {
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPrice(Number(e.target.value));
                    }
                  }}
                  value={price}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CategoriesSet;
