import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowPayslipExportDropdown,
  setPayslipPageSize,
  setPayslipPageNo,
  setPayStartFrom,
  setPayEndTo,
  setSearchIds,
  setEmpIds,
} from "../../store/slices/employeePayrollSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { EmployeePayslip } from "../../interfaces/employee";
import { exportToCsv } from "../../config";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import SelectSearch from "react-select-search";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import PaySalariesModal from "../Modals/pay-salaries-modal";
import Content from "./get-payrolls-api";

const Payslips = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.employeePayroll);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const payrollChange = useAppSelector((state) => state.common.payrollChange);
  const [showPaySalaryModal, setShowPaySalaryModal] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [status, setStatus] = React.useState("");
  const [checked, setChecked] = useState<{
    [key: number]: boolean;
  }>({});
  // Options
  const options = employees.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId.toString(),
  }));
  const projectOptions = projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  // Add custom options for "management" and "idol employee"
  projectOptions.splice(0, 0, { name: "All", value: "All" });
  projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<EmployeePayslip[]>([]);
  const totalPages = Math.ceil(
    state.payslipTotalRecords / state.payslipPageSize,
  );
  const totalCount = state.payslipTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.payslipPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);

  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.payslipPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setPayslipPageNo(state.payslipPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.payslipPageNo <= 5) {
      return;
    }
    dispatch(setPayslipPageNo(state.payslipPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setPayslipPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number

    dispatch(setPayslipPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.payslips);
  }, [state.payslips]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Due Date",
      "Emp Id.",
      "Emp Name",
      "Nationality",
      "Gross Salary (SAR)",
      "Deduction (SAR)",
      "Net Salary (SAR)",
      "Status",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => {
    return {
      "Sr. No.": index + 1,
      "Due Date": item.payrollDate
        ? new Date(item.payrollDate).toLocaleDateString()
        : "",
      "Emp Id.": item.empId,
      "Emp Name": item.empName,
      Nationality: item.empNationality,
      "Gross Salary (SAR)": item.grossSalary,
      "Deduction (SAR)": item.totalDeductions,
      "Net Salary (SAR)": item.netSalary,
      Status: item.status,
    };
  });

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const handleProjectId = (value: any) => {
    const oldArray =
      state.searchIds[0] === "All"
        ? []
        : state.searchIds[0] === "Management"
          ? []
          : !state.searchIds[0].includes("PR")
            ? []
            : state.searchIds;
    if (value === "All") {
      dispatch(setSearchIds([value]));
      return;
    }
    if (value === "Management") {
      dispatch(setSearchIds([value]));
      return;
    }
    if (state.searchIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Project Id already added");
      setShow(true);
      return;
    }
    dispatch(setSearchIds([...oldArray, value]));
  };

  const handleEmployeeId = (value: any) => {
    const oldArray = state.searchIds[0].includes("All")
      ? []
      : state.searchIds[0].includes("Management")
        ? []
        : state.searchIds[0].includes("PR")
          ? []
          : state.searchIds;
    if (state.searchIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Employee already added");
      setShow(true);
      return;
    }
    dispatch(setSearchIds([...oldArray, value]));
  };

  const handleSelectAll = () => {
    const allEmpIds = tableData.tableData.map((item) => item.empId);
    const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);
    if (allEmpIdsChecked) {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = false;
      });
      setChecked(newChecked);
    } else {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = true;
      });
      setChecked(newChecked);
    }
  };

  const handleSingleSelect = (empId: number) => {
    setChecked({
      ...checked,
      [empId]: !checked[empId],
    });
  };

  const allEmpIds = tableData.tableData.map((item) => item.empId);

  const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);

  const selectedPayload = tableData.tableData.filter(
    (item) => checked[item.empId],
  );

  const payrollIds = selectedPayload.map((item) => item._id);

  const allexpensesIds = selectedPayload.map((item) => item.expenseIds.flat());

  const allSalaryIds = selectedPayload.map((item) => item.salaryIds.flat());

  const totalAmount = selectedPayload.reduce(
    (acc, item) => acc + item.netSalary,
    0,
  );

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      <PaySalariesModal
        show={showPaySalaryModal}
        setShow={setShowPaySalaryModal}
        salaryIds={allSalaryIds.flat()}
        expenseIds={allexpensesIds.flat()}
        payrollIds={payrollIds}
        createPayslipLoading={state.addEmployeePayrollLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        payslipChange={payrollChange}
        totalAmount={totalAmount}
        status={status}
      />
      <Content status="Pending" />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Due Employee Payrolls</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="w-100 d-flex align-items-center gap-3">
                <Form.Group className=" w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project ID/Type
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                    <SelectSearch
                      name="emp"
                      options={projectOptions}
                      value={state.searchIds}
                      onChange={(value: any) => handleProjectId(value)}
                      search={true}
                      placeholder="Select Project Id"
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Or
                  </h4>
                </Form.Group>
                <Form.Group className="w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Select Employee
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        handleEmployeeId(value);
                      }}
                      value={state.searchIds}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Dropdown show={statusDropdown} className="position-relative">
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      setStatusDropdown(!statusDropdown);
                    }}
                    className="dropdown-toggle-white  dropdown-toggle-blue"
                    style={{ width: "max-content" }}
                  >
                    Actions
                    <img
                      src={EmployeeIcons.ArrowDownWhite}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={statusDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Paid");
                        setShowPaySalaryModal(true);
                        setStatusDropdown(false);
                      }}
                    >
                      Pay
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Hold");
                        setStatusDropdown(false);
                        setShowPaySalaryModal(true);
                      }}
                    >
                      Hold
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Cancelled");
                        setStatusDropdown(false);
                        setShowPaySalaryModal(true);
                      }}
                    >
                      Cancel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  show={state.showPayslipExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowPayslipExportDropdown(
                          !state.showPayslipExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                    style={{ width: "max-content" }}
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showPayslipExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "employeePayroll-list");
                        dispatch(setShowPayslipExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowPayslipExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="w-100 d-flex align-items-center gap-3 mt-4">
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                From Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.payStartFrom)}
                  onChange={(e: any) =>
                    dispatch(setPayStartFrom(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select From Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                To Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.payEndTo)}
                  onChange={(e: any) =>
                    dispatch(setPayEndTo(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select To Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <Form.Group
            className="mt-5 d-flex align-items-center gap-4"
            controlId="formBasicEmail"
          >
            <h4
              className="sub-heading normal-font-weight textBlack mb-0"
              style={{ fontSize: "18px" }}
            >
              Selected Projects/Employee Ids:
            </h4>

            <div className="d-flex align-items-center gap-2 overflow-auto">
              {state.searchIds.map((item, index) => (
                <div
                  key={index}
                  className="selected-projects primary-button d-flex align-items-center gap-3 py-0 px-4"
                  style={{
                    height: "40px",
                    borderRadius: "20px",
                    boxShadow: "none",
                  }}
                >
                  <h4 className="mb-0" style={{ fontSize: "16px" }}>
                    {item}
                  </h4>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => {
                      if (state.searchIds.length === 1) {
                        dispatch(setSearchIds(["All"]));
                        return;
                      }
                      const newProjects = state.searchIds.filter(
                        (project) => project !== item,
                      );
                      dispatch(setSearchIds(newProjects));
                    }}
                  >
                    <img
                      src={Common.Cross}
                      style={{ width: "22px", height: "22px" }}
                      alt="close"
                    />
                  </button>
                </div>
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col sm="12">
          {state.payslipLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Payslips List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-3 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {print ? null : (
                          <th className="border-0">
                            <Form.Check
                              type="checkbox"
                              id="tableCheckbox"
                              className="table-checkbox justify-content-center"
                              name="hideLetterHead"
                              checked={
                                //   if all the checkboxes are checked then return true
                                allEmpIdsChecked
                              }
                              onChange={() => handleSelectAll()}
                            />
                          </th>
                        )}
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          {print ? null : (
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="tableCheckbox" // Add an id to target the checkbox
                                className="table-checkbox justify-content-center"
                                name="hideLetterHead"
                                checked={checked[item.empId]}
                                onChange={() => handleSingleSelect(item.empId)}
                              />
                            </td>
                          )}
                          <td>
                            {totalCount > 0
                              ? (state.payslipPageNo - 1) *
                                  state.payslipPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td>
                            {item.payrollDate
                              ? new Date(item.payrollDate).toLocaleDateString()
                              : ""}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-payslip/${item.empId}`,
                                {
                                  state: { data: item, type: "paySalary" },
                                },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-payslip/${item.empId}`,
                                {
                                  state: { data: item, type: "paySalary" },
                                },
                              )
                            }
                          >
                            {print ? twoWords(item.empName) : item.empName}
                          </td>
                          <td>{item.empNationality}</td>
                          <td>{item.grossSalary.toFixed(2)}</td>
                          <td>{item.totalDeductions.toFixed(2)}</td>
                          <td>{item.netSalary.toFixed(2)}</td>
                          <td>{item.status}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/employees-payroll/view-payslip/${item.empId}`,
                                    {
                                      state: { data: item, type: "paySalary" },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No payslips found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={state.payslipPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.payslipPageNo > 1 ? state.payslipPageNo - 1 : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {/* render pages here */}
                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.payslipPageNo ? "active-page" : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.payslipPageNo < totalPages
                          ? state.payslipPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default Payslips;
