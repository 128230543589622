import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  BehaviourStatement,
  TransferEmployee,
  LeaveRequest,
  SalaryIncrement,
  FinaLExitEmployee,
  OfferLetter,
  Certificate,
  OfferLetterDetail,
  Expense,
  ExpenseDetail,
} from "../../interfaces/letters";

interface EmployeeWarning {
  data: {
    statements: {
      data: BehaviourStatement[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface TransferEmployeeList {
  data: {
    transfers: {
      data: TransferEmployee[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface NewJoinersList {
  data: {
    joiners: {
      data: TransferEmployee[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface LeaveRequestList {
  data: {
    leaves: {
      data: LeaveRequest[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface SalaryIncrementList {
  data: {
    allIncrements: {
      data: SalaryIncrement[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface ExpensesList {
  data: {
    expenses: {
      data: Expense[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface FinaLExitEmployeeList {
  data: {
    finalExit: {
      data: FinaLExitEmployee[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface OfferLetterList {
  data: {
    candidates: {
      data: OfferLetter[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface OfferLetterDetails {
  data: {
    candidate: OfferLetterDetail;
  };
}

interface ExpenseDetails {
  data: {
    pendingExpenses: ExpenseDetail;
  };
}

interface CertificateList {
  data: {
    certificates: {
      data: Certificate[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export const lettersAPI = createApi({
  reducerPath: "/api/letters",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    // Employee Warning
    getBehaviourStatements: builder.query<
      EmployeeWarning,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/employee-statements-list/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredBehaviourStatements: builder.mutation<
      EmployeeWarning,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/filtered-employee-statements-list/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addBehaviourStatement: builder.mutation({
      query: (data) => ({
        url: `/add-employee-statement/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editBehaviourStatement: builder.mutation({
      query: (data) => ({
        url: `/edit-employee-statement/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteBehaviourStatement: builder.mutation({
      query: (data) => ({
        url: `/delete-employee-statement/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //     Expenses
    getAllExpenses: builder.query<
      ExpensesList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-expenses/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredExpenses: builder.mutation<
      ExpensesList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-expenses/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getExpenseDetail: builder.mutation<
      ExpenseDetails,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/expense-detail/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addExpense: builder.mutation({
      query: (data) => ({
        url: `/add-expense/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editExpense: builder.mutation({
      query: (data) => ({
        url: `/edit-expense/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteExpense: builder.mutation({
      query: (data) => ({
        url: `/delete-expense/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Transfer Employee
    addTransferRequest: builder.mutation({
      query: (data) => ({
        url: `/add-transfer-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getTransfersEmployeeList: builder.query<
      TransferEmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-transfers/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getNewJoinersList: builder.query<
      NewJoinersList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-joiners/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    deleteTransferRequest: builder.mutation({
      query: (data) => ({
        url: `/delete-transfer/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllFilteredTransferNewJoiners: builder.mutation<
      NewJoinersList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-transfers/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Leave Request
    addLeaveRequest: builder.mutation({
      query: (data) => ({
        url: `/add-leave/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editLeaveRequest: builder.mutation({
      query: (data) => ({
        url: `/edit-leave/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteLeaveRequest: builder.mutation({
      query: (data) => ({
        url: `/delete-leave/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getLeavesRequests: builder.query<
      LeaveRequestList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/all-leaves-lists/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredLeaveRequest: builder.mutation<
      LeaveRequestList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/all-filtered-leaves-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    // Salary Increment
    addSalaryIncrement: builder.mutation({
      query: (data) => ({
        url: `/add-increment-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editSalaryIncrement: builder.mutation({
      query: (data) => ({
        url: `/edit-increment-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteSalaryIncrement: builder.mutation({
      query: (data) => ({
        url: `/delete-increment-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getSalaryIncrements: builder.query<
      SalaryIncrementList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/all-increments-lists/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredIncrements: builder.mutation<
      SalaryIncrementList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/all-filtered-increment-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Final Exit
    getFinalExit: builder.query<
      FinaLExitEmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/all-final-exits-lists/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredFinalExitEmployees: builder.mutation<
      FinaLExitEmployeeList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/all-final-exits-filtered-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addFinalExit: builder.mutation({
      query: (data) => ({
        url: `/add-final-exit-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editFinalExit: builder.mutation({
      query: (data) => ({
        url: `/edit-final-exit-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteFinalExit: builder.mutation({
      query: (data) => ({
        url: `/delete-final-exit-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    // Offer Letter
    getOfferLetters: builder.query<
      OfferLetterList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/all-interviewees-list/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getFilteredOfferLetters: builder.mutation({
      query: (data) => ({
        url: `/all-filtered-interviewees-list/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addOfferLetter: builder.mutation({
      query: (data) => ({
        url: `/print-offer-letter/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getOfferLetterDetail: builder.mutation<
      OfferLetterDetails,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/interviewee-detail/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editOfferLetterStatus: builder.mutation({
      query: (data) => ({
        url: `/add-intervieweed-employee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addInterviewerLetter: builder.mutation({
      query: (data) => ({
        url: `/add-interviewee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editInterviewerLetter: builder.mutation({
      query: (data) => ({
        url: `/edit-interviewee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteOfferLetter: builder.mutation({
      query: (data) => ({
        url: `/delete-interviewee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Certificates
    getCertificates: builder.query<
      CertificateList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-certificates/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllFilteredCertificates: builder.mutation<
      CertificateList,
      { permission: boolean; userID: string; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-certificates/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addCertificate: builder.mutation({
      query: (data) => ({
        url: `/add-certificate-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteCertificate: builder.mutation({
      query: (data) => ({
        url: `/delete-certificate/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useAddBehaviourStatementMutation,
  useEditBehaviourStatementMutation,
  useDeleteBehaviourStatementMutation,
  useGetBehaviourStatementsQuery,
  useAddExpenseMutation,
  useEditExpenseMutation,
  useDeleteExpenseMutation,
  useGetAllExpensesQuery,
  useGetAllFilteredExpensesMutation,
  useAddTransferRequestMutation,
  useGetTransfersEmployeeListQuery,
  useDeleteTransferRequestMutation,
  useAddLeaveRequestMutation,
  useEditLeaveRequestMutation,
  useDeleteLeaveRequestMutation,
  useGetLeavesRequestsQuery,
  useAddSalaryIncrementMutation,
  useEditSalaryIncrementMutation,
  useDeleteSalaryIncrementMutation,
  useGetSalaryIncrementsQuery,
  useGetFinalExitQuery,
  useAddFinalExitMutation,
  useEditFinalExitMutation,
  useDeleteFinalExitMutation,
  useGetOfferLettersQuery,
  useAddOfferLetterMutation,
  useDeleteOfferLetterMutation,
  useGetCertificatesQuery,
  useAddCertificateMutation,
  useDeleteCertificateMutation,
  useGetNewJoinersListQuery,
  useAddInterviewerLetterMutation,
  useEditInterviewerLetterMutation,
  useEditOfferLetterStatusMutation,
  useGetFilteredOfferLettersMutation,
  useGetOfferLetterDetailMutation,
  useGetAllFilteredBehaviourStatementsMutation,
  useGetAllFilteredLeaveRequestMutation,
  useGetAllFilteredIncrementsMutation,
  useGetAllFilteredTransferNewJoinersMutation,
  useGetAllFilteredFinalExitEmployeesMutation,
  useGetAllFilteredCertificatesMutation,
  useGetExpenseDetailMutation,
} = lettersAPI;
