import { createSlice } from "@reduxjs/toolkit";
import { Owner, OwnerId } from "../../interfaces/sponsor";

interface Index {
  ownerList: Owner[];
  ownerListLoading: boolean;
  ownerListError: string;
  ownerListPageNo: number;
  ownerListPageSize: number;
  ownerListTotalPages: number;
  filterOwnerPageNo: number;
  filterOwnerPageSize: number;
  filterOwnerTotalPages: number;
  showOwnerExportDropdown: boolean;
  addOwnerLoading: boolean;
  ownerIds: OwnerId[];
  ownerIdsLoading: boolean;
  ownerIdsError: string;
}

const initialState: Index = {
  ownerList: [],
  ownerListLoading: false,
  ownerListError: "",
  ownerListPageNo: 1,
  ownerListPageSize: 10,
  ownerListTotalPages: 0,
  filterOwnerPageNo: 1,
  filterOwnerPageSize: 10,
  filterOwnerTotalPages: 0,
  showOwnerExportDropdown: false,
  addOwnerLoading: false,
  ownerIds: [],
  ownerIdsLoading: false,
  ownerIdsError: "",
};

const ownerSlice = createSlice({
  name: "owner",
  initialState: initialState,
  reducers: {
    setOwnerListLoading: (state, action) => {
      state.ownerListLoading = action.payload;
    },
    setOwnerListError: (state, action) => {
      state.ownerListError = action.payload;
    },
    setOwnerList: (state, action) => {
      state.ownerList = action.payload;
    },
    setOwnerListPageNo: (state, action) => {
      state.ownerListPageNo = action.payload;
    },
    setOwnerListPageSize: (state, action) => {
      state.ownerListPageSize = action.payload;
    },
    setOwnerListTotalPages: (state, action) => {
      state.ownerListTotalPages = action.payload;
    },
    setFilterOwnerPageNo: (state, action) => {
      state.filterOwnerPageNo = action.payload;
    },
    setFilterOwnerPageSize: (state, action) => {
      state.filterOwnerPageSize = action.payload;
    },
    setFilterOwnerTotalPages: (state, action) => {
      state.filterOwnerTotalPages = action.payload;
    },
    setShowOwnerExportDropdown: (state, action) => {
      state.showOwnerExportDropdown = action.payload;
    },
    setAddOwnerLoading: (state, action) => {
      state.addOwnerLoading = action.payload;
    },
    setOwnersIds: (state, action) => {
      state.ownerIds = action.payload;
    },
    setOwnersIdsLoading: (state, action) => {
      state.ownerIdsLoading = action.payload;
    },
    setOwnersIdsError: (state, action) => {
      state.ownerIdsError = action.payload;
    },
  },
});

export const {
  setOwnerListLoading,
  setOwnerListError,
  setOwnerList,
  setOwnerListPageNo,
  setOwnerListPageSize,
  setOwnerListTotalPages,
  setFilterOwnerPageNo,
  setFilterOwnerPageSize,
  setFilterOwnerTotalPages,
  setShowOwnerExportDropdown,
  setAddOwnerLoading,
  setOwnersIds,
  setOwnersIdsLoading,
  setOwnersIdsError,
} = ownerSlice.actions;
export default ownerSlice.reducer;
