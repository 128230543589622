import React from "react";
import InternalLayout from "../components/InternalLayout";
import ClientsComponent from "../components/Clients";
import Content from "../components/Clients/get-api-call";
import withAuth from "../config/private-route";

const Clients = () => {
  return (
    <InternalLayout>
      <Content />
      <ClientsComponent />
    </InternalLayout>
  );
};
export default withAuth(Clients);
