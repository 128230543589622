import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";

interface AllowanceProps {
  data: {
    title: string;
    amount: number;
    category: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    title: string;
    amount: number;
    category: string;
  }) => void;
  uniqueId: string;
}

const OfferLetterAllowanceSet: React.FC<AllowanceProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [title, setAllowanceTitle] = useState(data.title);
  const [amount, setAllowanceValue] = useState(data.amount);
  const [category, setAllowanceType] = useState(data.category);

  const handleInputChange = () => {
    onInputChange({
      title,
      amount,
      category,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [title, amount, category]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Allowance Title
                </h4>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter allowance title"
                  onChange={(e) => setAllowanceTitle(e.target.value)}
                  value={title}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setAllowanceType(e.target.value)}
                  value={category}
                  className="custom-input border-fill"
                >
                  <option value="">Select category</option>
                  <option value="By Company">By Company</option>
                  <option value="By Self">By Self</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Allowance Value
                </h4>
                <Form.Control
                  type="number"
                  name="amount"
                  placeholder="Enter allowance value"
                  onChange={(e) => setAllowanceValue(Number(e.target.value))}
                  value={amount}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OfferLetterAllowanceSet;
