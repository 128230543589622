import React from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Common } from "../../assets/images";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEditAbscondedEmployeeMutation } from "../../services/Employees";
import { setAddAbscondedLoading } from "../../store/slices/employeeSlice";
import { setAbscondedChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

const EditEmployeeAbsconding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const abscondData = location.state.data;
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState(abscondData.empId || "");
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [abscondedDate, setAbscondedDate] = React.useState(
    abscondData.abscondDate || "",
  );
  const [abscondedLocation, setAbscondedLocation] = React.useState(
    abscondData.abscondLocation || "",
  );
  const [reasonOfAbscond, setReasonOfAbscond] = React.useState(
    abscondData.reasonOfAbscond || "",
  );
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const abscondedChange = useAppSelector(
    (state) => state.common.abscondedChange,
  );

  // API call for add salary increment
  const [editAbscondedEmployee] = useEditAbscondedEmployeeMutation();
  const handleSubmit = async () => {
    dispatch(setAddAbscondedLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          empId: empID,
          abscondDate: abscondedDate,
          abscondLocation: abscondedLocation,
          reasonOfAbscond: reasonOfAbscond,
          link: `/employees/absconded-employees`,
        },
      };
      await editAbscondedEmployee(data).unwrap();
      dispatch(setAbscondedChange(!abscondedChange));
      navigate("/employees/absconded-employees");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddAbscondedLoading(false));
    }
  };

  return (
    <div className="acknowledgment section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <h2 className="heading">Employee Absconding</h2>
          </Col>
          <Col sm="12">
            <div className="emp-detail mt-5">
              <h4 className="sub-heading mb-4">Employee Detail</h4>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      JDSFJ34567654
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Ali Asif
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Status:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Probation
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Nationality:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Pakistani
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Position:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Labour
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Joining Date:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      10, March 2023
                    </p>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="emp-detail-item mb-4 add-user mt-5">
                    <h4 className="sub-heading mb-4">Absconding Detail</h4>
                    <Row>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Date:
                          </h4>
                          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                            <DatePicker
                              name="returnDate"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                              placeholderText="Date of birth"
                              className="w-100 custom-input"
                              dateFormat="yyyy-MM-d"
                              selected={
                                abscondedDate
                                  ? new Date(abscondedDate)
                                  : new Date()
                              }
                              onChange={(date: any) => {
                                setAbscondedDate(date);
                              }}
                            />
                            <img
                              src={Common.Calendar}
                              className="calendar-date-recur"
                              alt="calendarblack"
                            />
                          </Form.Group>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Absconding Location
                          </h4>
                          <Form.Control
                            type="text"
                            name="abscondingLocation"
                            onChange={(e) =>
                              setAbscondedLocation(e.target.value)
                            }
                            placeholder="Enter absconding location"
                            value={abscondedLocation}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Reason of Abscond
                          </h4>
                          <Form.Control
                            type="text"
                            name="reasonOfAbscond"
                            placeholder="Enter reason of abscond"
                            value={reasonOfAbscond}
                            onChange={(e) => setReasonOfAbscond(e.target.value)}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="emp-detail-print text-end mt-5">
                    <button
                      className="btn primary-button w-25"
                      onClick={handleSubmit}
                    >
                      {state.addAbscondedLoading ? (
                        <LoaderWhite height={30} width={30} />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default EditEmployeeAbsconding;
