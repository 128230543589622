import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as Yup from "yup";
// API
import { useEditRoleMutation } from "../../services/Settings";
import { setSettingsChange } from "../../store/slices/commonSlice";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";

interface FormProps {
  roleName: string;
}

const EditRole = () => {
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: {
      read: boolean;
      write: boolean;
      edit: boolean;
      delete: boolean;
    };
  }>({
    userManagement: {
      read: data.showUser,
      write: data.addUser,
      edit: data.updateUser,
      delete: data.deleteUser,
    },
    employeeManagement: {
      read: data.showEmployee,
      write: data.addEmployee,
      edit: data.updateEmployee,
      delete: data.deleteEmployee,
    },
    projectManagement: {
      read: data.showProject,
      write: data.addProject,
      edit: data.updateProject,
      delete: data.deleteProject,
    },
    attendanceManagement: {
      read: data.showAttendance,
      write: data.addAttendance,
      edit: data.updateAttendance,
      delete: data.deleteAttendance,
    },
    legalManagement: {
      read: data.showLegal,
      write: data.addLegal,
      edit: data.updateLegal,
      delete: data.deleteLegal,
    },
    pettyCashManagement: {
      read: data.showPettyCash,
      write: data.addPettyCash,
      edit: data.updatePettyCash,
      delete: data.deletePettyCash,
    },
  });

  const [editRole, { isLoading: editRoleLoading }] = useEditRoleMutation();
  // Initail Values
  const initialValues: FormProps = {
    roleName: data.roleName,
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Enter Role Name"),
  });
  // Handle Submit
  const handleSubmit = async (values: FormProps) => {
    const dataPayload = {
      accessToken: accessToken || "",
      userID: userID || "",
      payload: {
        myRole: state.profileData.designation || "",
        roleId: data._id,
        link: `/settings/designations`,
        addUser: checkboxes.userManagement.write,
        updateUser: checkboxes.userManagement.edit,
        deleteUser: checkboxes.userManagement.delete,
        showUser: checkboxes.userManagement.read,
        addEmployee: checkboxes.employeeManagement.write,
        updateEmployee: checkboxes.employeeManagement.edit,
        deleteEmployee: checkboxes.employeeManagement.delete,
        showEmployee: checkboxes.employeeManagement.read,
        addProject: checkboxes.projectManagement.write,
        updateProject: checkboxes.projectManagement.edit,
        deleteProject: checkboxes.projectManagement.delete,
        showProject: checkboxes.projectManagement.read,
        addAttendance: checkboxes.attendanceManagement.write,
        updateAttendance: checkboxes.attendanceManagement.edit,
        deleteAttendance: checkboxes.attendanceManagement.delete,
        showAttendance: checkboxes.attendanceManagement.read,
        addLegal: checkboxes.legalManagement.write,
        updateLegal: checkboxes.legalManagement.edit,
        deleteLegal: checkboxes.legalManagement.delete,
        showLegal: checkboxes.legalManagement.read,
        addPettyCash: checkboxes.pettyCashManagement.write,
        updatePettyCash: checkboxes.pettyCashManagement.edit,
        deletePettyCash: checkboxes.pettyCashManagement.delete,
        showPettyCash: checkboxes.pettyCashManagement.read,
      },
    };
    try {
      await editRole(dataPayload).unwrap();
      dispatch(setSettingsChange(!settingsChange));
      navigate("/settings/designations");
    } catch (e: any) {
      if (e.data) {
        window.scrollTo(0, 0);
        setShow(true);
        setErrorContent(e.data.message);
      }
    }
  };

  // make select all checkbox checked
  const selectAll = () => {
    const newCheckboxes = { ...checkboxes };
    Object.keys(newCheckboxes).map((key) => {
      newCheckboxes[key].read = true;
      newCheckboxes[key].write = true;
      newCheckboxes[key].edit = true;
      newCheckboxes[key].delete = true;
    });
    setCheckboxes(newCheckboxes);
  };

  const deselectAll = () => {
    const newCheckboxes = { ...checkboxes };
    Object.keys(newCheckboxes).map((key) => {
      newCheckboxes[key].read = false;
      newCheckboxes[key].write = false;
      newCheckboxes[key].edit = false;
      newCheckboxes[key].delete = false;
    });
    setCheckboxes(newCheckboxes);
  };

  // add space between camelCase and make first letter capital
  const addSpace = (str: string) => {
    const newStr = str.replace(/([A-Z])/g, " $1");
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  };

  return (
    <div className="add-user mt-2">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">Edit Role</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        name="roleName"
                        placeholder="Enter role"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.roleName}
                        disabled={true}
                        className={
                          touched.roleName && errors.roleName
                            ? "is-invalid custom-input disabled-input border-1"
                            : "custom-input disabled-input border-1"
                        }
                      />
                      {touched.roleName && errors.roleName ? (
                        <div className="invalid-feedback">
                          {errors.roleName}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn primary-button normal-font-weight"
                        onClick={() => selectAll()}
                      >
                        Select All
                      </button>
                      <button
                        type="button"
                        className="btn primary-button normal-font-weight ms-3"
                        onClick={() => deselectAll()}
                      >
                        Deselect All
                      </button>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="grant-permissions">
                      <h3 className="sub-heading mb-4">Grant Permissions</h3>
                      <div className="permission-list">
                        {Object.keys(checkboxes).map((key) => (
                          <div key={key} className="permission-item mb-4">
                            <div className="permission-item-heading">
                              <h4 className="sub-heading normal-font-weight textBlack">
                                {addSpace(key)}
                              </h4>
                            </div>
                            <div className="permission-item-body mt-2 d-flex align-items-center gap-5">
                              <div className="checkbox-item">
                                <Form.Check
                                  type="checkbox"
                                  label="Show"
                                  name={`${key}.read`}
                                  checked={checkboxes[key].read}
                                  onChange={(e) => {
                                    const newCheckboxes = { ...checkboxes };
                                    newCheckboxes[key].read = e.target.checked;
                                    setCheckboxes(newCheckboxes);
                                  }}
                                />
                              </div>
                              <div className="checkbox-item">
                                <Form.Check
                                  type="checkbox"
                                  label="Add "
                                  name={`${key}.write`}
                                  checked={checkboxes[key].write}
                                  onChange={(e) => {
                                    const newCheckboxes = { ...checkboxes };
                                    newCheckboxes[key].write = e.target.checked;
                                    setCheckboxes(newCheckboxes);
                                  }}
                                />
                              </div>
                              <div className="checkbox-item">
                                <Form.Check
                                  type="checkbox"
                                  label="Update"
                                  name={`${key}.edit`}
                                  checked={checkboxes[key].edit}
                                  onChange={(e) => {
                                    const newCheckboxes = { ...checkboxes };
                                    newCheckboxes[key].edit = e.target.checked;
                                    setCheckboxes(newCheckboxes);
                                  }}
                                />
                              </div>
                              <div className="checkbox-item">
                                <Form.Check
                                  type="checkbox"
                                  label="Delete"
                                  name={`${key}.delete`}
                                  checked={checkboxes[key].delete}
                                  onChange={(e) => {
                                    const newCheckboxes = { ...checkboxes };
                                    newCheckboxes[key].delete =
                                      e.target.checked;
                                    setCheckboxes(newCheckboxes);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn primary-button normal-font-weight"
                      >
                        {editRoleLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditRole;
