import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setBillChange } from "../../store/slices/commonSlice";
import { setAddBillLoading } from "../../store/slices/vendorsSlice";
// API Imports
import { useAddBillingMutation } from "../../services/AccountCharts";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import { useNavigate } from "react-router-dom";
import AttachmentSet from "../Employees/AddEmployee/attachments";
import BillingSet from "./billingSet";
import SelectSearch from "react-select-search";
import ApprovalsSet from "../Employees/approvals-set";

interface AddBilling {
  billDate: string;
  dueDate: string;
  vendor: string;
  billNo: string;
  orderNumber: string;
  subject: string;
  note: string;
  repeatEvery: string;
  repeatOn: string;
  repeatEnds: string;
}

const initialValues: AddBilling = {
  billDate: "",
  dueDate: "",
  vendor: "",
  billNo: "",
  orderNumber: "",
  subject: "",
  note: "",
  repeatEvery: "",
  repeatOn: "",
  repeatEnds: "",
};

const AddBilling = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const billChange = useAppSelector((state) => state.common.billChange);
  const billState = useAppSelector((state) => state.vendors);
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [neverExpires, setNeverExpires] = useState(false);
  const vendorIds = useAppSelector((state) => state.vendors.vendorIds);
  // Options
  const options = vendorIds.map((item) => ({
    name: item.vendorId + " - " + item.vendorName,
    value: item.vendorId,
  }));
  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      // account: string;
      quantity: number;
      rate: number;
      description: string;
      amount: number;
      taxPercentage: number;
      discountType: string;
      discount: number;
    }[]
  >([
    {
      uId: Math.random().toString(36).substring(7),
      // account: "",
      quantity: 0,
      rate: 0,
      description: "",
      amount: 0,
      taxPercentage: 0,
      discountType: "percent",
      discount: 0,
    },
  ]);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        // account: "",
        quantity: 0,
        rate: 0,
        description: "",
        amount: 0,
        taxPercentage: 0,
        discountType: "percent",
        discount: 0,
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      // account: string;
      quantity: number;
      rate: number;
      description: string;
      amount: number;
      taxPercentage: number;
      discountType: string;
      discount: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <BillingSet
        key={data.uId}
        data={data}
        setLength={experienceData.length}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  // Languages Sets
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");
  const [approvalSets, setApprovalSets] = useState<React.ReactNode[]>([]);
  const [approvalData, setApprovalData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddApprovalSet = () => {
    setApprovalData([
      ...approvalData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveApprovalSet = (index: string) => {
    const updatedInputData = approvalData.filter(
      (item, i) => item.uId !== index,
    );
    setApprovalData(updatedInputData);
  };
  const handleApprovalInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = approvalData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setApprovalData(updatedInput);
  };

  useEffect(() => {
    const updatedApprovalSets = approvalData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveApprovalSet(key)}
        onInputChange={(inputData) =>
          handleApprovalInputChange(data.uId, inputData)
        }
      />
    ));
    setApprovalSets(updatedApprovalSets);
  }, [approvalData]);

  // get all userId from approvalData array
  const userIds =
    approvalData.length > 0 ? approvalData.map((data) => data.userId) : [];

  const totalAmount = experienceData.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  const [addBilling] = useAddBillingMutation();

  const handleSubmit = async (values: AddBilling) => {
    if (!values.vendor) {
      window.scrollTo(0, 0);
      setErrorContent("Vendor is required");
      setShow(true);
      return;
    }
    if (!values.billNo) {
      window.scrollTo(0, 0);
      setErrorContent("Bill number is required");
      setShow(true);
      return;
    }
    if (!values.billDate) {
      window.scrollTo(0, 0);
      setErrorContent("Bill date is required");
      setShow(true);
      return;
    }
    if (isRecurring && !values.repeatEvery) {
      window.scrollTo(0, 0);
      setErrorContent("Repeat Every is required");
      setShow(true);
      return;
    }
    dispatch(setAddBillLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          billDate: values.billDate,
          dueDate: values.dueDate,
          vendor: values.vendor,
          billNo: values.billNo,
          orderNumber: values.orderNumber,
          subject: values.subject,
          note: values.note,
          isRecurring: isRecurring,
          repeatEvery: isRecurring ? values.repeatEvery : "",
          repeatOn: isRecurring ? values.repeatOn : "",
          repeatEnds: isRecurring
            ? neverExpires
              ? ""
              : values.repeatEnds
            : "",
          neverExpires: isRecurring ? neverExpires : false,
          listItems: experienceData,
          attachments: languageData,
          totalAmount: totalAmount,
          approvals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...approvalData,
          ],
          ids: [superadmin?._id || "", ...userIds],
          link: "/billing",
        },
      };
      await addBilling(data).unwrap();
      dispatch(setBillChange(!billChange));
      navigate("/billing");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddBillLoading(false));
    }
  };

  const validationSchema = Yup.object().shape({});

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">Create Bill</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Vendor *
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center">
                        <SelectSearch
                          options={options}
                          name="vendor"
                          onChange={(value: any) => {
                            handleChange({
                              target: { name: "vendor", value: value },
                            });
                          }}
                          value={values.vendor}
                          search={true}
                          placeholder="Search Vendor by Id/Name "
                        />
                        <img src={Common.ArrowDown} alt="message" />
                      </Form.Group>
                      {touched.vendor && errors.vendor ? (
                        <div className="invalid-feedback">{errors.vendor}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Bill # *
                      </h4>
                      <Form.Control
                        type="text"
                        name="billNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.billNo}
                        placeholder="Bill number"
                        className={
                          touched.billNo && errors.billNo
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      />
                      {touched.billNo && errors.billNo ? (
                        <div className="invalid-feedback">{errors.billNo}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Bill Date *
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="billDate"
                          selected={
                            values.billDate ? new Date(values.billDate) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "billDate", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Bill Date"
                          className={
                            errors.billDate && touched.billDate
                              ? "is-invalid w-100 custom-input"
                              : "w-100 custom-input"
                          }
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                      {touched.billDate && errors.billDate ? (
                        <div className="invalid-feedback">
                          {errors.billDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Due Date
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="dueDate"
                          selected={
                            values.dueDate ? new Date(values.dueDate) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "dueDate", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Due Date"
                          className={
                            errors.dueDate && touched.dueDate
                              ? "is-invalid w-100 custom-input"
                              : "w-100 custom-input"
                          }
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                      {touched.dueDate && errors.dueDate ? (
                        <div className="invalid-feedback">{errors.dueDate}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Order Number
                      </h4>
                      <Form.Control
                        type="text"
                        name="orderNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.orderNumber}
                        placeholder="Order number"
                        className={
                          touched.orderNumber && errors.orderNumber
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      />
                      {touched.orderNumber && errors.orderNumber ? (
                        <div className="invalid-feedback">
                          {errors.orderNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Subject
                      </h4>
                      <Form.Control
                        type="text"
                        name="subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subject}
                        placeholder="Enter Subject"
                        className={
                          touched.subject && errors.subject
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      />
                      {touched.subject && errors.subject ? (
                        <div className="invalid-feedback">{errors.subject}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Check
                      type="checkbox"
                      id="hideLetterHeadCheckbox" // Add an id to target the checkbox
                      label="Enable Recurring"
                      className="hide-letter-head mb-3"
                      name="isRecurring"
                      checked={isRecurring}
                      onChange={(e) => setIsRecurring(e.target.checked)}
                    />
                  </Col>
                  {isRecurring && (
                    <Col sm="12">
                      <Row className="align-items-center">
                        <Col sm="3">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Repeat Every
                            </h4>
                            <Form.Select
                              name="repeatEvery"
                              onChange={handleChange}
                              value={values.repeatEvery}
                              className="custom-input border-fill"
                            >
                              <option value="">Repeat Every</option>
                              <option value="Day">Day</option>
                              <option value="Week">Week</option>
                              <option value="Month">Month</option>
                              <option value="Year">Year</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col sm="3">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Starts on
                            </h4>
                            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                              <DatePicker
                                name="repeatOn"
                                selected={
                                  values.repeatOn
                                    ? new Date(values.repeatOn)
                                    : null
                                }
                                onChange={(date: any) =>
                                  handleChange({
                                    target: { name: "repeatOn", value: date },
                                  })
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                placeholderText="Starts on"
                                className={
                                  errors.dueDate && touched.dueDate
                                    ? "is-invalid w-100 custom-input"
                                    : "w-100 custom-input"
                                }
                                dateFormat="yyyy-MM-d"
                              />
                              <img
                                src={Common.Calendar}
                                className="calendar-date-recur"
                                alt="calendarblack"
                              />
                            </Form.Group>
                            {touched.dueDate && errors.dueDate ? (
                              <div className="invalid-feedback">
                                {errors.dueDate}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm="3">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Ends on
                            </h4>
                            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                              <DatePicker
                                name="repeatEnds"
                                selected={
                                  values.repeatEnds
                                    ? new Date(values.repeatEnds)
                                    : null
                                }
                                onChange={(date: any) =>
                                  handleChange({
                                    target: { name: "repeatEnds", value: date },
                                  })
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                disabled={neverExpires}
                                placeholderText="Ends on"
                                className={
                                  errors.dueDate && touched.dueDate
                                    ? "is-invalid w-100 custom-input"
                                    : "w-100 custom-input"
                                }
                                dateFormat="yyyy-MM-d"
                              />
                              <img
                                src={Common.Calendar}
                                className="calendar-date-recur"
                                alt="calendarblack"
                              />
                            </Form.Group>
                            {touched.dueDate && errors.dueDate ? (
                              <div className="invalid-feedback">
                                {errors.dueDate}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm="3">
                          <Form.Check
                            type="checkbox"
                            id="hideLetterHeadCheckbox" // Add an id to target the checkbox
                            label="Never Expires"
                            className="hide-letter-head mb-3"
                            name="isRecurring"
                            checked={neverExpires}
                            onChange={(e) => setNeverExpires(e.target.checked)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col sm="8">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Note *
                      </h4>
                      <textarea
                        name="note"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                        placeholder="Leave a comment here..."
                        required={true}
                        className={
                          touched.note && errors.note
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.note && errors.note ? (
                        <div className="invalid-feedback">{errors.note}</div>
                      ) : null}
                    </Form.Group>
                  </Col>

                  <Col sm="12">
                    {experienceSets.length > 0 && (
                      <h4 className="sub-heading mb-4">Items List</h4>
                    )}
                    <div className="extra-detail my-4">{experienceSets}</div>
                  </Col>
                  <Col sm="12">
                    <Row>
                      <Col sm="4" className="m-auto">
                        <div
                          className="sub-total p-4"
                          style={{
                            background: "#f9f9fb",
                            borderRadius: "12px",
                          }}
                        >
                          <div className="d-flex justify-content-end gap-5">
                            <h4 className="sub-heading normal-font-weight me-5">
                              Total:
                            </h4>
                            <div style={{ width: "100px" }}>
                              <h4 className="sub-heading normal-font-weight text-end">
                                {totalAmount}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12">
                    <div className="text-end mt-4 d-flex align-items-center gap-4 mb-4">
                      <button
                        className="btn primary-button normal-font-weight"
                        type="button"
                        onClick={handleAddExperienceSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Item
                      </button>
                      <button
                        className="btn primary-button normal-font-weight"
                        type="button"
                        onClick={handleAddLanguageSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Attachments
                      </button>
                      <button
                        className="btn primary-button normal-font-weight"
                        type="button"
                        onClick={handleAddApprovalSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Approvals
                      </button>
                    </div>
                  </Col>
                  <Col sm="12">
                    {languageSets.length > 0 && (
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Attachments
                      </h4>
                    )}
                    <div className="extra-detail my-4">{languageSets}</div>
                  </Col>
                  <Col sm="12">
                    <div className="extra-detail my-4">
                      {approvalSets.length > 0 && (
                        <h4 className="sub-heading normal-font-weight mb-4">
                          Approval will be sent to these users:
                        </h4>
                      )}
                      {approvalSets}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {billState.addBillLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Create Bill"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddBilling;
