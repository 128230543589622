import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Legal } from "../../interfaces/iqama";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddLegalLoading } from "../../store/slices/iqamaSlice";
import { setLegalChange } from "../../store/slices/commonSlice";
// API
import { useEditLegalMutation } from "../../services/Iqama";
import { ToastErrorComponent } from "../Toasts";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import DatePicker from "react-datepicker";
import { useUploadImageMutation } from "../../services/images-upload-service";

export interface AddLegals {
  eligibility: string;
  validFrom: string;
  validTo: string;
  cost: string;
  status: string;
  remarks: string;
}

const EditLegal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const legalData: Legal = location.state.data;
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState("");
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.iqama);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const legalChange = useAppSelector((state) => state.common.legalChange);

  const initialValues: AddLegals = {
    eligibility: legalData.eligibility || "",
    validFrom: legalData.validFrom || "",
    validTo: legalData.validTo || "",
    cost: legalData.cost.toString() || "",
    status: legalData.status || "",
    remarks: "",
  };

  const [editLegal] = useEditLegalMutation();
  const handleSubmit = async (values: AddLegals) => {
    dispatch(setAddLegalLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: legalData.empId,
          category: legalData.category,
          eligibility: values.eligibility,
          validFrom: values.validFrom,
          validTo: values.validTo,
          cost: parseInt(values.cost),
          status: values.status,
          attachemnt: imageUploaded || "",
          remarks: values.remarks,
          link: `/legal`,
        },
      };
      await editLegal(data).unwrap();
      dispatch(setLegalChange(!legalChange));
      navigate("/legal");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddLegalLoading(false));
    }
  };

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Legal </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Eligibility
                      </h4>
                      <Form.Select
                        name="eligibility"
                        value={values.eligibility}
                        onChange={handleChange}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Eligibility</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Cost
                      </h4>
                      <Form.Control
                        type="text"
                        name="cost"
                        placeholder="Enter cost"
                        onChange={(e) => {
                          //     accept only numbers
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        value={values.cost}
                        required={true}
                        className="custom-input border-fill"
                      />
                      {touched.cost && errors.cost ? (
                        <div className="invalid-feedback">{errors.cost}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Valid From
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="validFrom"
                          selected={
                            values.validFrom ? new Date(values.validFrom) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: {
                                name: "validFrom",
                                value: date,
                              },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Contract From Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Valid To
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="validTo"
                          selected={
                            values.validTo ? new Date(values.validTo) : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: {
                                name: "validTo",
                                value: date,
                              },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Contract To Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Status
                      </h4>
                      <Form.Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Status</option>
                        <option value="Valid">Valid</option>
                        <option value="Expired">Expired</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group>
                      <div className="d-flex align-items-center mb-2">
                        <h4
                          className="sub-heading normal-font-weight textBlack mb-0 me-2"
                          style={{ fontSize: "18px" }}
                        >
                          Attachment
                        </h4>
                        {imageUploaded ? (
                          <label className="d-flex align-items-center justify-content-center cursor-pointer">
                            <input
                              type="file"
                              name="profileImage"
                              onChange={handleUploadImage}
                              accept=".png, .jpg, .jpeg"
                              className="d-none"
                              multiple={false}
                            />
                            <div className="edit-icon d-flex align-items-center justify-content-center">
                              <img
                                src={SettingsIcons.EditIcon}
                                width="18px"
                                height="18px"
                                alt="edit"
                              />
                            </div>
                          </label>
                        ) : null}
                      </div>
                      {imageLoading ? (
                        <LoaderBlue height={50} width={50} />
                      ) : (
                        <>
                          {imageUploaded ? (
                            <img
                              src={Common.FileIcon}
                              style={{
                                width: "242px",
                                height: "242px",
                                objectFit: "contain",
                              }}
                              alt="employee-image"
                            />
                          ) : (
                            <Form.Label className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center">
                              <Form.Control
                                type="file"
                                name="profileImage"
                                onChange={handleUploadImage}
                                accept=".png, .jpg, .jpeg"
                                className="d-none"
                                multiple={false}
                              />
                              <div className="text-center">
                                <div className="add-profile-image d-flex align-items-center justify-content-center mx-auto">
                                  <img src={EmployeeIcons.Plus} alt="plus" />
                                </div>
                                <p
                                  className="upload-para mt-2 mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  Upload PNG & JPG File only
                                </p>
                                <p className="upload-para mt-0 mb-0">
                                  Max file size is 5MB
                                </p>
                              </div>
                            </Form.Label>
                          )}
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Remarks
                      </h4>
                      <Form.Control
                        type="text"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        placeholder="Enter remarks"
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.addLegalLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Edit Legal"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditLegal;
