import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Form, Row, Table } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setRequestRenewLoading,
  setUpdateIqamaLoading,
  setIqamaHandoverRequestLoading,
  setIqamaHandoverLoading,
} from "../../store/slices/iqamaSlice";
import {
  setEmployeeChange,
  setIqamaChange,
} from "../../store/slices/commonSlice";
// API Imports
import {
  useRequestIqamaRenewMutation,
  useEditIqamaMutation,
  useIqamaHandoverRequestMutation,
  useIqamaHandoverMutation,
  useAddIqamaProcessMutation,
  useEditIqamaProcessMutation,
  useGetIqamaDetailMutation,
} from "../../services/Iqama";
import { ToastErrorComponent } from "../Toasts";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import DatePicker from "react-datepicker";
import { IqamaDetailProps } from "../../interfaces/iqama";
import IqamaProcessesSet from "./iqama-processes-set";
import { useUploadImageMutation } from "../../services/images-upload-service";
import ConfirmInitiateIqamaProcessModal from "../Modals/initiate-iqama-process-modal";
import ApprovalsSet from "../Employees/approvals-set";
import ConfirmInitiateIqamaRenewProcessModal from "../Modals/initiate-iqama-renew-request-modal";

interface IqamaProcessProps {
  uId: string;
  name: string;
  status: string;
  attachement: string;
  validFrom: string;
  validTo: string;
  charges: number;
}

const initalIqamaData: IqamaDetailProps = {
  _id: "",
  empId: 0,
  empName: "",
  empNationality: "",
  empPhone: "",
  dateOfEmpInSA: "",
  empStatus: "",
  empIqamaNo: "",
  empIqamaExpiry: "",
  empProfessionOnIqama: "",
  empSponsorName: "",
  empBorderNo: 0,
  empPassportNo: "",
  empPassportExpiry: "",
  empVisaNo: 0,
  empVisaExpiry: "",
  iqamaApprovalsStatus: "",
  empIdNo: 0,
  empInsuranceCompany: "",
  empInsuranceExpiry: "",
  empInsuranceStatus: "",
  empWorking: "",
  empIqamaCost: 0,
  processesStatus: false,
  iqamaProcess: [],
  iqamaApprovals: [],
  iqamaHandoverApprovals: [],
  expenses: [],
  iqamaSubmittedBy: {
    empId: 0,
    empName: "",
    remarks: "",
    date: "",
  },
  iqamaHandoverSubmittedBy: {
    empId: 0,
    empName: "",
    remarks: "",
    date: "",
  },
};

const IqamaDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateEmpId = location.state.data;
  const [iqamaData, setIqamaData] = useState<IqamaDetailProps>(initalIqamaData);
  const dispatch = useAppDispatch();
  const [remarks, setRemarks] = useState("");
  const [iqamaExpiry, setIqamaExpiry] = useState(
    iqamaData.empIqamaExpiry || "",
  );
  const [iqamaDetailLoading, setIqamaDetailLoading] = useState(false);
  const [iqamaCost, setIqamaCost] = useState(iqamaData.empIqamaCost || 0);
  const [requestForIqamaRenew, setRequestForIqamaRenew] = useState(false);
  const [showUpdateIqamaDetails, setShowUpdateIqamaDetails] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.iqama);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const iqamaChange = useAppSelector((state) => state.common.iqamaChange);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const [allStatusCompleted, setAllStatusCompleted] = useState(false);
  const [showInitiateModal, setShowInitiateModal] = useState(false);
  const [iqamaProcess, setIqamaProcess] = useState<IqamaProcessProps[]>(
    iqamaData.iqamaProcess || [],
  );
  const [showIqamaRenewRequestModal, setShowIqamaRenewRequestModal] =
    useState(false);

  const [imageLoading, setImageLoading] = useState<{
    [key: number]: boolean;
  }>({});

  const [updateLoading, setUpdateLoading] = useState<{
    [key: number]: boolean;
  }>({});

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  const [getIqamaDetail] = useGetIqamaDetailMutation();

  const getDetail = async () => {
    setIqamaDetailLoading(true);
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData?.permissions.showLegal || false,
      payload: {
        empId: navigateEmpId || "",
      },
    };
    try {
      const result = await getIqamaDetail(data).unwrap();
      if (result) {
        setIqamaDetailLoading(false);
        setIqamaData(result.data.employees[0]);
        setIqamaProcess(result.data.employees[0].iqamaProcess);
        const allCompleted = iqamaData.iqamaProcess.every(
          (process) => process.status === "Completed",
        );
        if (allCompleted) {
          setAllStatusCompleted(true);
        } else {
          setAllStatusCompleted(false);
        }
      }
    } catch (e) {
      setIqamaDetailLoading(false);
    }
  };
  React.useEffect(() => {
    getDetail();
  }, [accessToken, userID, iqamaChange, profileData]);

  // Languages Sets
  const [approvalsSets, setApprovalsSets] = useState<React.ReactNode[]>([]);
  const [approvalsData, setApprovalsData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddApprovalsSet = () => {
    setApprovalsData([
      ...approvalsData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveApprovalsSet = (index: string) => {
    const updatedInputData = approvalsData.filter(
      (item, i) => item.uId !== index,
    );
    setApprovalsData(updatedInputData);
  };
  const handleApprovalsInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = approvalsData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setApprovalsData(updatedInput);
  };

  useEffect(() => {
    const updatedApprovalsSets = approvalsData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveApprovalsSet(key)}
        onInputChange={(inputData) =>
          handleApprovalsInputChange(data.uId, inputData)
        }
      />
    ));
    setApprovalsSets(updatedApprovalsSets);
  }, [approvalsData]);

  // get all userId from approvalsData array
  const userIds =
    approvalsData.length > 0 ? approvalsData.map((data) => data.userId) : [];

  const [addProcessLoading, setAddProcessLoading] = useState(false);

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Violation Date",
      "Violation Type",
      "Violation Amount",
      "Violation Status",
      "Violation Detail",
    ],
    tableData: iqamaData.expenses,
  };

  const [requestIqamaRenew] = useRequestIqamaRenewMutation();

  const renewRequestPayload = {
    empId: iqamaData.empId,
    iqamaApprovals: [
      {
        uId: Math.random().toString(36).substring(7),
        userId: superadmin?._id || "",
        date: "",
        name: superadmin?.firstName + " " + superadmin?.lastName || "",
        role: superadmin?.role || "",
        status: "Pending",
      },
      ...approvalsData,
    ],
    ids: [superadmin?._id || "", ...userIds],
    link: `/iqama`,
    iqamaRequesteeDetails: {
      empId: profileData.employeeId,
      empName: profileData.firstName + " " + profileData.lastName,
      remarks: remarks,
    },
  };

  const handleRenewIqamaRequest = async () => {
    dispatch(setRequestRenewLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: renewRequestPayload,
      };
      await requestIqamaRenew(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      dispatch(setEmployeeChange(!employeeChange));
      window.location.reload();
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setRequestRenewLoading(false));
    }
  };

  const [editIqama] = useEditIqamaMutation();

  const handleUpdateIqamaDetails = async () => {
    dispatch(setUpdateIqamaLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: iqamaData.empId,
          empIqamaExpiry: iqamaExpiry,
          empIqamaCost: iqamaCost,
        },
      };
      await editIqama(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      dispatch(setEmployeeChange(!employeeChange));
      window.location.reload();
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setUpdateIqamaLoading(false));
    }
  };

  const [iqamaHandoverRequest] = useIqamaHandoverRequestMutation();

  const handleIqamaHandoverRequest = async () => {
    dispatch(setIqamaHandoverRequestLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: iqamaData.empId,
          iqamaHandoverApprovals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...approvalsData,
          ],
          ids: [superadmin?._id || "", ...userIds],
          link: `/iqama`,
        },
      };
      await iqamaHandoverRequest(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      dispatch(setEmployeeChange(!employeeChange));
      window.location.reload();
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setIqamaHandoverRequestLoading(false));
    }
  };

  const [iqamaHandover] = useIqamaHandoverMutation();

  const handleIqamaHandover = async () => {
    dispatch(setIqamaHandoverLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: iqamaData.empId,
        },
      };
      await iqamaHandover(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      dispatch(setEmployeeChange(!employeeChange));
      window.location.reload();
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setIqamaHandoverLoading(false));
    }
  };

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      name: string;
      status: string;
      attachement: string;
      charges: number;
      validFrom: string;
      validTo: string;
    }[]
  >(iqamaData.iqamaProcess || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        name: "",
        status: "",
        attachement: "",
        validFrom: "",
        validTo: "",
        charges: 0,
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      name: string;
      status: string;
      attachement: string;
      charges: number;
      validFrom: string;
      validTo: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <IqamaProcessesSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  const handleChargesChange = (index: number, value: string) => {
    setIqamaProcess((prevProcess) => {
      const updatedIqamaProcess = [...prevProcess];
      updatedIqamaProcess[index] = {
        ...updatedIqamaProcess[index],
        charges: Number(value),
      };
      return updatedIqamaProcess;
    });
  };

  const handleValidFromChange = (index: number, value: string) => {
    setIqamaProcess((prevProcess) => {
      const updatedIqamaProcess = [...prevProcess];
      updatedIqamaProcess[index] = {
        ...updatedIqamaProcess[index],
        validFrom: value,
      };
      return updatedIqamaProcess;
    });
  };

  const handleValidToChange = (index: number, value: string) => {
    setIqamaProcess((prevProcess) => {
      const updatedIqamaProcess = [...prevProcess];
      updatedIqamaProcess[index] = {
        ...updatedIqamaProcess[index],
        validTo: value,
      };
      return updatedIqamaProcess;
    });
  };

  const handleStatusChange = (index: number, value: string) => {
    setIqamaProcess((prevProcess) => {
      const updatedIqamaProcess = [...prevProcess];
      updatedIqamaProcess[index] = {
        ...updatedIqamaProcess[index],
        status: value,
      };
      return updatedIqamaProcess;
    });
  };

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading((prev) => {
        return {
          ...prev,
          [index]: true,
        };
      });
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setIqamaProcess((prevProcess) => {
          const updatedIqamaProcess = [...prevProcess];
          // Assuming you want to store the file information
          updatedIqamaProcess[index] = {
            ...updatedIqamaProcess[index],
            attachement: result.data.Profileimage,
          };
          return updatedIqamaProcess;
        });
        setImageLoading((prev) => {
          return {
            ...prev,
            [index]: false,
          };
        });
      } catch (e: any) {
        setImageLoading((prev) => {
          return {
            ...prev,
            [index]: false,
          };
        });
      }
    }
  };

  const [editIqamaProcess] = useEditIqamaProcessMutation();

  const handleUpdateRow = async (index: number, uId: string) => {
    setUpdateLoading((prev) => {
      return {
        ...prev,
        [index]: true,
      };
    });
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: iqamaData.empId,
          iqamaProcess: iqamaProcess,
        },
      };
      await editIqamaProcess(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      setUpdateLoading((prev) => {
        return {
          ...prev,
          [index]: false,
        };
      });
    }
  };

  const fileName = (imageUrl: string) => {
    // Using the URL API to extract the pathname
    const pathname = imageUrl ? new URL(imageUrl).pathname : "";

    // Extracting the file name with extension from the pathname
    const fileNameWithExtension = pathname.split("/").pop();
    return fileNameWithExtension;
  };

  const [addIqamaProcess] = useAddIqamaProcessMutation();

  const handleAddIqamaProcess = async () => {
    setAddProcessLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: iqamaData.empId,
          iqamaProcess: languageData,
        },
      };
      await addIqamaProcess(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      setAddProcessLoading(false);
    }
  };

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ConfirmInitiateIqamaProcessModal
        empId={iqamaData.empId}
        show={showInitiateModal}
        setShow={setShowInitiateModal}
      />
      <ConfirmInitiateIqamaRenewProcessModal
        empId={iqamaData.empId}
        show={showIqamaRenewRequestModal}
        setShow={setShowIqamaRenewRequestModal}
        payload={renewRequestPayload}
        expensesLength={iqamaData.expenses ? iqamaData.expenses.length : 0}
        insuranceStatus={iqamaData.empInsuranceStatus}
      />
      {iqamaDetailLoading ? (
        <LoaderBlue height={50} width={50} />
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading ">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Iqama Detail</h1>
              </div>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-0"
                  style={{ fontSize: "18px" }}
                >
                  Iqama Status:
                </h4>
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  {iqamaData.iqamaApprovalsStatus === "Valid" ||
                  iqamaData.iqamaApprovalsStatus === "Handovered" ? (
                    <img
                      src={Common.GreenDot}
                      className="me-2"
                      width="12px"
                      height="12px"
                      alt="red dot"
                    />
                  ) : iqamaData.iqamaApprovalsStatus === "Expired" ||
                    iqamaData.iqamaApprovalsStatus === "Not Issued" ? (
                    <img
                      src={Common.RedDot}
                      className="me-2"
                      width="12px"
                      height="12px"
                      alt="red dot"
                    />
                  ) : iqamaData.iqamaApprovalsStatus === "Processing" ||
                    iqamaData.iqamaApprovalsStatus === "Handover Approved" ? (
                    <img
                      src={Common.BlueDot}
                      className="me-2"
                      width="12px"
                      height="12px"
                      alt="red dot"
                    />
                  ) : (
                    <img
                      src={Common.OrangeDot}
                      className="me-2"
                      width="12px"
                      height="12px"
                      alt="red dot"
                    />
                  )}
                  {iqamaData.iqamaApprovalsStatus}
                </h4>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <div className="emp-detail mt-5">
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empId}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empName}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Status:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empStatus}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Working Status:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empWorking}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Phone:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empPhone}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Country:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empNationality}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Iqama No.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empIqamaNo}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Iqama Expiry:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empIqamaExpiry
                        ? new Date(
                            iqamaData.empIqamaExpiry,
                          ).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Profession on Iqama:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empProfessionOnIqama}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Sponsor Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empSponsorName}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Border No.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empBorderNo}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Passport No.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empPassportNo}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Passport Expiry.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empPassportExpiry
                        ? new Date(
                            iqamaData.empPassportExpiry,
                          ).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Visa No.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empVisaNo}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Visa Expiry:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empVisaExpiry
                        ? new Date(iqamaData.empVisaExpiry).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      ID No.
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empIdNo}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Insurance Company:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empInsuranceCompany}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Insurance Expiry:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.empInsuranceExpiry
                        ? new Date(
                            iqamaData.empInsuranceExpiry,
                          ).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Date of Employment in Saudi Arabia:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {iqamaData.dateOfEmpInSA
                        ? new Date(iqamaData.dateOfEmpInSA).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="emp-detail-item mb-4">
                    <h2 className="sub-heading mb-4">Violation Details:</h2>
                    {iqamaData.expenses && iqamaData.expenses.length > 0 ? (
                      <Table
                        striped
                        bordered
                        responsive
                        className="text-center"
                      >
                        <thead>
                          <tr className="border-0">
                            {tableData.tableHeading.map((item, index) => (
                              <th className="border-0" key={index}>
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.tableData.map((item: any, index: any) => (
                            <tr
                              key={index}
                              style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                            >
                              <td>{index + 1}</td>
                              <td>
                                {item.violationDate
                                  ? new Date(
                                      item.violationDate,
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </td>
                              <td>{item.expenseType}</td>
                              <td>{item.expenseAmount}</td>
                              <td>
                                <p
                                  className="mb-0 py-1 px-4 text-white mx-auto"
                                  style={{
                                    background:
                                      item.expenseStatus === "Cleared"
                                        ? "rgba(57, 179, 89, 1)"
                                        : "rgba(239, 148, 0, 1)",
                                    width: "fit-content",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {item.expenseStatus}
                                </p>
                              </td>
                              <td>{item.expenseDetail}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div className="text-center mt-5">
                        <h1 className="heading">No Violations Found</h1>
                      </div>
                    )}
                  </div>
                </Col>
                {iqamaData.iqamaSubmittedBy ? (
                  <Col sm="12">
                    <div>
                      <h2 className="sub-heading mb-4">Requestee Details</h2>
                      <Row>
                        <Col sm="4">
                          <div className="emp-detail-item mb-4">
                            <h4
                              className="sub-heading normal-font-weight textBlack mb-2"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Code:
                            </h4>
                            <p
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {iqamaData.iqamaSubmittedBy.empId}
                            </p>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="emp-detail-item mb-4">
                            <h4
                              className="sub-heading normal-font-weight textBlack mb-2"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Name
                            </h4>
                            <p
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {iqamaData.iqamaSubmittedBy.empName}
                            </p>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="emp-detail-item mb-4">
                            <h4
                              className="sub-heading normal-font-weight textBlack mb-2"
                              style={{ fontSize: "18px" }}
                            >
                              Remarks:
                            </h4>
                            <p
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {iqamaData.iqamaSubmittedBy.remarks}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : null}
                {iqamaData.iqamaApprovalsStatus === "Expired" ||
                iqamaData.iqamaApprovalsStatus === "Not Issued" ||
                iqamaData.iqamaApprovalsStatus === "Valid" ? null : (
                  <Col sm="12">
                    <div>
                      <h2 className="sub-heading mb-4">
                        Iqama Renewal Approvals:
                      </h2>
                      {iqamaData.iqamaApprovalsStatus ===
                      "Fine Clearance Pending" ? (
                        <div className="emp-detail-item mb-4">
                          <h4 className="sub-heading normal-font-weight textBlack">
                            Employee have pending fines to be cleared...
                          </h4>
                        </div>
                      ) : (
                        <Row>
                          {iqamaData.iqamaApprovals.length > 0 ? (
                            <>
                              {iqamaData.iqamaApprovals.map((item, index) => (
                                <div className="col-sm-4" key={index}>
                                  <div className="emp-detail-item mb-4">
                                    <h4
                                      className="sub-heading normal-font-weight textBlack"
                                      style={{ fontSize: "18px" }}
                                    >
                                      {item.name} ({item.role})
                                    </h4>
                                    <p
                                      className="sub-heading normal-font-weight"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {item.status}{" "}
                                      {item.status !== "Pending"
                                        ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <div>
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                No Approvals
                              </h4>
                            </div>
                          )}
                        </Row>
                      )}
                    </div>
                  </Col>
                )}
                {iqamaData.iqamaProcess.length > 0 && (
                  <Col sm="12">
                    <div className="extra-detail my-4">
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Iqama Processes
                      </h4>
                      <div className="table-responsive mb-3">
                        <table className="table text-center table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>Type</th>
                              <th>Charges</th>
                              <th>Valid From</th>
                              <th>Valid To</th>
                              <th>Status</th>
                              <th>Attachment</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {iqamaProcess.map((item, index) => (
                              <tr key={index}>
                                <td style={{ verticalAlign: "middle" }}>
                                  {index + 1}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {item.name}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                      type="number"
                                      name="charges"
                                      onChange={(e) =>
                                        handleChargesChange(
                                          index,
                                          e.target.value,
                                        )
                                      }
                                      placeholder="Enter charges"
                                      disabled={
                                        iqamaData.iqamaProcess[index]
                                          ?.status === "Completed"
                                          ? true
                                          : false
                                      }
                                      value={item.charges}
                                      className="custom-input border-fill"
                                    />
                                  </Form.Group>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                                    <DatePicker
                                      name="validFrom"
                                      selected={
                                        item.validFrom
                                          ? new Date(item.validFrom)
                                          : null
                                      }
                                      onChange={(e: any) =>
                                        handleValidFromChange(index, e)
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      autoComplete="off"
                                      disabled={
                                        iqamaData.iqamaProcess[index]
                                          ?.status === "Completed"
                                          ? true
                                          : false
                                      }
                                      placeholderText="Select valid from"
                                      className="w-100 custom-input"
                                      dateFormat="yyyy-MM-d"
                                    />
                                    <img
                                      src={Common.Calendar}
                                      className="calendar-date-recur"
                                      alt="calendarblack"
                                    />
                                  </Form.Group>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                                    <DatePicker
                                      name="validFrom"
                                      selected={
                                        item.validTo
                                          ? new Date(item.validTo)
                                          : null
                                      }
                                      onChange={(e: any) =>
                                        handleValidToChange(index, e)
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      autoComplete="off"
                                      disabled={
                                        iqamaData.iqamaProcess[index]
                                          ?.status === "Completed"
                                          ? true
                                          : false
                                      }
                                      placeholderText="Select valid to"
                                      className="w-100 custom-input"
                                      dateFormat="yyyy-MM-d"
                                    />
                                    <img
                                      src={Common.Calendar}
                                      className="calendar-date-recur"
                                      alt="calendarblack"
                                    />
                                  </Form.Group>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {" "}
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Select
                                      name="status"
                                      value={item.status}
                                      onChange={(e) =>
                                        handleStatusChange(
                                          index,
                                          e.target.value,
                                        )
                                      }
                                      disabled={
                                        iqamaData.iqamaProcess[index]
                                          ?.status === "Completed"
                                          ? true
                                          : false
                                      }
                                      className="custom-input border-fill"
                                    >
                                      <option value="">Select Status</option>
                                      <option value="In-process">
                                        In-process
                                      </option>
                                      <option value="Completed">
                                        Completed
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {imageLoading[index] ? (
                                    <LoaderBlue height={22} width={22} />
                                  ) : item.attachement ? (
                                    <div className="d-flex align-items-center gap-2">
                                      <a
                                        className="upload-para mb-0 text-decoration-underline"
                                        style={{
                                          fontSize: "14px",
                                          margin: "auto",
                                        }}
                                        target="_blank"
                                        href={item.attachement}
                                        download
                                      >
                                        {fileName(item.attachement)}
                                      </a>
                                      {iqamaData.iqamaProcess[index]?.status ===
                                      "Completed" ? null : (
                                        <Form.Label
                                          className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center mx-auto"
                                          style={{
                                            height: "auto",
                                            padding: "0",
                                            border: "none",
                                            width: "fit-content",
                                            margin: "0",
                                          }}
                                        >
                                          <Form.Control
                                            type="file"
                                            name="profileImage"
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>,
                                            ) => handleUploadImage(e, index)}
                                            accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, docx, .xlsx, .xls"
                                            className="d-none"
                                            multiple={false}
                                          />
                                          <div className="text-center d-flex align-items-center gap-3">
                                            <p
                                              className="upload-para mb-0 text-decoration-underline"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Change
                                            </p>
                                          </div>
                                        </Form.Label>
                                      )}
                                    </div>
                                  ) : (
                                    <Form.Label
                                      className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center mx-auto"
                                      style={{
                                        height: "auto",
                                        padding: "0",
                                        border: "none",
                                      }}
                                    >
                                      <Form.Control
                                        type="file"
                                        name="profileImage"
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>,
                                        ) => handleUploadImage(e, index)}
                                        accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, docx, .xlsx, .xls"
                                        className="d-none"
                                        multiple={false}
                                      />
                                      <div className="text-center d-flex align-items-center gap-3">
                                        <p
                                          className="upload-para mb-0 text-decoration-underline"
                                          style={{ fontSize: "14px" }}
                                        >
                                          Upload Attachment
                                        </p>
                                      </div>
                                    </Form.Label>
                                  )}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {iqamaData.iqamaProcess[index]?.status ===
                                  "Completed" ? (
                                    "N/A"
                                  ) : index === 0 ||
                                    (index > 0 &&
                                      iqamaData.iqamaProcess[index - 1]
                                        ?.status === "Completed") ? (
                                    <button
                                      className="btn primary-button"
                                      onClick={() =>
                                        handleUpdateRow(index, item.uId)
                                      }
                                    >
                                      {updateLoading[index] ? (
                                        <LoaderWhite height={24} width={24} />
                                      ) : (
                                        "Update"
                                      )}
                                    </button>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                )}
                {iqamaData.iqamaApprovalsStatus ===
                  "Handover Approvals Pending" ||
                iqamaData.iqamaApprovalsStatus === "Handovered" ||
                iqamaData.iqamaApprovalsStatus === "Handover Approved" ? (
                  <>
                    <Col sm="12">
                      <div>
                        <h2 className="sub-heading mb-4">
                          Handover Requestee Details
                        </h2>
                        <Row>
                          <Col sm="4">
                            <div className="emp-detail-item mb-4">
                              <h4
                                className="sub-heading normal-font-weight textBlack mb-2"
                                style={{ fontSize: "18px" }}
                              >
                                Emp Code:
                              </h4>
                              <p
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {iqamaData?.iqamaHandoverSubmittedBy?.empId}
                              </p>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="emp-detail-item mb-4">
                              <h4
                                className="sub-heading normal-font-weight textBlack mb-2"
                                style={{ fontSize: "18px" }}
                              >
                                Emp Name
                              </h4>
                              <p
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {iqamaData?.iqamaHandoverSubmittedBy?.empName}
                              </p>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="emp-detail-item mb-4">
                              <h4
                                className="sub-heading normal-font-weight textBlack mb-2"
                                style={{ fontSize: "18px" }}
                              >
                                Remarks:
                              </h4>
                              <p
                                className="sub-heading normal-font-weight"
                                style={{ fontSize: "16px" }}
                              >
                                {iqamaData?.iqamaHandoverSubmittedBy?.remarks}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div>
                        <h2 className="sub-heading mb-4">
                          Iqama Handover Approvals:
                        </h2>
                        <Row>
                          {iqamaData.iqamaHandoverApprovals.length > 0 ? (
                            <>
                              {iqamaData.iqamaHandoverApprovals.map(
                                (item, index) => (
                                  <div className="col-sm-4" key={index}>
                                    <div className="emp-detail-item mb-4">
                                      <h4
                                        className="sub-heading normal-font-weight textBlack"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {item.name} ({item.role})
                                      </h4>
                                      <p
                                        className="sub-heading normal-font-weight"
                                        style={{ fontSize: "16px" }}
                                      >
                                        {item.status}{" "}
                                        {item.status !== "Pending"
                                          ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                ),
                              )}
                            </>
                          ) : (
                            <div>
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                No Approvals
                              </h4>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </>
                ) : null}
                {iqamaData.iqamaApprovalsStatus === "Processing" && (
                  <>
                    {iqamaData.processesStatus && (
                      <>
                        <Col sm="12">
                          <div className="text-end mt-4 d-flex align-items-center gap-4">
                            <button
                              className="btn primary-button normal-font-weight"
                              type="button"
                              onClick={handleAddLanguageSet}
                              style={{ width: "15%" }}
                            >
                              <img
                                src={Common.Plus}
                                width="20px"
                                height="20px"
                                className="me-2"
                                alt="next"
                              />{" "}
                              Process
                            </button>
                          </div>
                        </Col>
                        <Col sm="12">
                          <div className="extra-detail my-4">
                            {languageSets.length > 0 && (
                              <>
                                <h4 className="sub-heading normal-font-weight mb-4">
                                  Iqama Processes
                                </h4>
                                {languageSets}
                                <button
                                  className="btn primary-button mt-4"
                                  onClick={handleAddIqamaProcess}
                                >
                                  {addProcessLoading ? (
                                    <LoaderWhite height={30} width={30} />
                                  ) : (
                                    "Add Iqama Processes"
                                  )}
                                </button>
                              </>
                            )}
                          </div>
                        </Col>
                      </>
                    )}
                    <Col sm="12">
                      <div className="emp-detail-print text-end mt-5 mb-5">
                        {iqamaData.processesStatus && (
                          <button
                            className="btn primary-button"
                            onClick={() => setShowInitiateModal(true)}
                          >
                            Initiate Iqama process
                          </button>
                        )}
                        {allStatusCompleted && !iqamaData.processesStatus && (
                          <button
                            className="btn primary-button"
                            onClick={() =>
                              setShowUpdateIqamaDetails(!showUpdateIqamaDetails)
                            }
                          >
                            {showUpdateIqamaDetails
                              ? "Cancel Update Iqama details"
                              : "Update Iqama details"}{" "}
                            <img src={Common.ArrowRightWhite} alt="right" />
                          </button>
                        )}
                      </div>
                    </Col>
                    {showUpdateIqamaDetails && (
                      <Col sm="12">
                        <Row>
                          <Col sm="4">
                            <Form.Group
                              className="mb-4"
                              controlId="formBasicEmail"
                            >
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Iqama Expiry Date
                              </h4>
                              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                                <DatePicker
                                  name="iqamaExpiry"
                                  selected={
                                    iqamaExpiry ? new Date(iqamaExpiry) : null
                                  }
                                  onChange={(date: any) => setIqamaExpiry(date)}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  autoComplete="off"
                                  placeholderText="Select iqama expiry date"
                                  className="w-100 custom-input"
                                  dateFormat="yyyy-MM-d"
                                />
                                <img
                                  src={Common.Calendar}
                                  className="calendar-date-recur"
                                  alt="calendarblack"
                                />
                              </Form.Group>
                            </Form.Group>
                          </Col>
                          <Col sm="4">
                            <Form.Group controlId="formBasicEmail">
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Iqama Cost
                              </h4>
                              <Form.Control
                                type="number"
                                name="charges"
                                onChange={(e) =>
                                  setIqamaCost(Number(e.target.value))
                                }
                                placeholder="Enter charges"
                                value={iqamaCost}
                                className="custom-input border-fill"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="emp-detail-print text-end mt-5">
                          <button
                            className="btn primary-button w-25"
                            onClick={handleUpdateIqamaDetails}
                          >
                            {state.updateIqamaLoading ? (
                              <LoaderWhite height={30} width={30} />
                            ) : (
                              <>
                                Update{" "}
                                <img src={Common.ArrowRightWhite} alt="right" />
                              </>
                            )}
                          </button>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </div>
          </Col>
          {iqamaData.iqamaApprovalsStatus === "Valid" && (
            <>
              <Col sm="12">
                <div className="extra-detail my-4">
                  <h4 className="sub-heading normal-font-weight mb-4">
                    Before creating Iqama Handover Request, please add approval
                    users:
                  </h4>
                </div>
              </Col>
              <Col sm="12">
                <div className="text-end mt-4 d-flex align-items-center gap-4">
                  <button
                    className="btn primary-button normal-font-weight"
                    type="button"
                    onClick={handleAddApprovalsSet}
                    style={{ width: "15%" }}
                  >
                    <img
                      src={Common.Plus}
                      width="20px"
                      height="20px"
                      className="me-2"
                      alt="next"
                    />{" "}
                    Approval user
                  </button>
                </div>
              </Col>
              <Col sm="12">
                <div className="extra-detail my-4">
                  {approvalsSets.length > 0 && (
                    <h4 className="sub-heading normal-font-weight mb-4">
                      Approval will be sent to these users:
                    </h4>
                  )}
                  {approvalsSets}
                </div>
              </Col>
              <Col sm="12">
                <div className="emp-detail-print text-end mt-5">
                  <button
                    className="btn primary-button w-25"
                    onClick={handleIqamaHandoverRequest}
                  >
                    {state.iqamaHandoverRequestLoading ? (
                      <LoaderWhite height={30} width={30} />
                    ) : (
                      <>
                        Create Iqama Handover Request{" "}
                        <img src={Common.ArrowRightWhite} alt="right" />
                      </>
                    )}
                  </button>
                </div>
              </Col>
            </>
          )}
          {iqamaData.iqamaApprovalsStatus === "Handover Approved" && (
            <>
              <Col sm="12">
                <div className="emp-detail-print text-end mt-5">
                  <button
                    className="btn primary-button w-25"
                    onClick={handleIqamaHandover}
                  >
                    {state.iqamaHandoverLoading ? (
                      <LoaderWhite height={30} width={30} />
                    ) : (
                      "Print"
                    )}
                  </button>
                </div>
              </Col>
            </>
          )}
          {iqamaData.iqamaApprovalsStatus === "Expired" ||
          iqamaData.iqamaApprovalsStatus === "Not Issued" ? (
            <>
              <Col sm="12">
                <div className="emp-detail-print text-end mt-5">
                  <button
                    className="btn primary-button w-25"
                    onClick={() =>
                      setRequestForIqamaRenew(!requestForIqamaRenew)
                    }
                  >
                    {requestForIqamaRenew
                      ? "Cancel Request"
                      : "Request for Iqama Renewal"}{" "}
                    <img src={Common.ArrowRightWhite} alt="right" />
                  </button>
                </div>
              </Col>
              {requestForIqamaRenew && (
                <Col sm="12">
                  <div className="emp-detail mt-5">
                    <Row>
                      <Col sm="12">
                        <h2 className="sub-heading mb-4">Requestee Details</h2>
                      </Col>
                      <Col sm="4">
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack mb-2"
                            style={{ fontSize: "18px" }}
                          >
                            Emp ID:
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {profileData.employeeId}
                          </p>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack mb-2"
                            style={{ fontSize: "18px" }}
                          >
                            Emp Name:
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {profileData.firstName + " " + profileData.lastName}
                          </p>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="emp-detail-item mb-4">
                          <Form.Group controlId="formBasicEmail">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Remarks
                            </h4>
                            <Form.Select
                              name="remarks"
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                              className="custom-input border-fill"
                            >
                              <option value="">Select Remark Type</option>
                              <option value="Urgent">Urgent</option>
                              <option value="Normal">Normal</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="text-end mt-4 d-flex align-items-center gap-4">
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            onClick={handleAddApprovalsSet}
                            style={{ width: "15%" }}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Approval user
                          </button>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="extra-detail my-4">
                          {approvalsSets.length > 0 && (
                            <h4 className="sub-heading normal-font-weight mb-4">
                              Approval will be sent to these users:
                            </h4>
                          )}
                          {approvalsSets}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="emp-detail-print text-end mt-5">
                    <button
                      className="btn primary-button w-25"
                      onClick={() => {
                        iqamaData.empInsuranceStatus
                          ? iqamaData.empInsuranceStatus === "Expired"
                            ? iqamaData?.expenses.length > 0
                              ? setShowIqamaRenewRequestModal(true)
                              : handleRenewIqamaRequest()
                            : setShowIqamaRenewRequestModal(true)
                          : setShowIqamaRenewRequestModal(true);
                      }}
                    >
                      {state.requestRenewLoading ? (
                        <LoaderWhite height={30} width={30} />
                      ) : (
                        <>
                          Renew <img src={Common.ArrowRightWhite} alt="right" />
                        </>
                      )}
                    </button>
                  </div>
                </Col>
              )}
            </>
          ) : null}
        </Row>
      )}
    </div>
  );
};
export default IqamaDetail;
