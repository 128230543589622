import React from "react";
import InternalLayout from "../components/InternalLayout";
import TimeSheetComponent from "../components/Timesheets";
import withAuth from "../config/private-route";

const Timesheet = () => {
    return (
        <InternalLayout>
            <TimeSheetComponent />
        </InternalLayout>
    );
};
export default withAuth(Timesheet);
