import { createSlice } from "@reduxjs/toolkit";
import {
  Camp,
  CampId,
  RecruitAgency,
  RecruitAgencyId,
  Visa,
} from "../../interfaces/employee";

interface Index {
  campsList: Camp[];
  campsListLoading: boolean;
  campsListError: string;
  campsListPageNo: number;
  campsListPageSize: number;
  campsListTotalPages: number;
  filterCampPageNo: number;
  filterCampPageSize: number;
  filterCampTotalPages: number;
  showCampExportDropdown: boolean;
  addCampLoading: boolean;
  campsIds: CampId[];
  campsIdsLoading: boolean;
  campsIdsError: string;
  //   RecruitAgency
  recruitAgencyList: RecruitAgency[];
  recruitAgencyListLoading: boolean;
  recruitAgencyListError: string;
  recruitAgencyListPageNo: number;
  recruitAgencyListPageSize: number;
  recruitAgencyListTotalPages: number;
  filterRecruitAgencyPageNo: number;
  filterRecruitAgencyPageSize: number;
  filterRecruitAgencyTotalPages: number;
  showRecruitAgencyExportDropdown: boolean;
  addRecruitAgencyLoading: boolean;
  recruitAgencyIds: RecruitAgencyId[];
  recruitAgencyIdsLoading: boolean;
  recruitAgencyIdsError: string;
  //   Visa
  visaList: Visa[];
  visaListLoading: boolean;
  visaListError: string;
  visaListPageNo: number;
  visaListPageSize: number;
  visaListTotalPages: number;
  filterVisaPageNo: number;
  filterVisaPageSize: number;
  filterVisaTotalPages: number;
  showVisaExportDropdown: boolean;
  addVisaLoading: boolean;
}

const initialState: Index = {
  campsList: [],
  campsListLoading: false,
  campsListError: "",
  campsListPageNo: 1,
  campsListPageSize: 10,
  campsListTotalPages: 0,
  filterCampPageNo: 1,
  filterCampPageSize: 10,
  filterCampTotalPages: 0,
  showCampExportDropdown: false,
  addCampLoading: false,
  campsIds: [],
  campsIdsLoading: false,
  campsIdsError: "",
  //   RecruitAgency
  recruitAgencyList: [],
  recruitAgencyListLoading: false,
  recruitAgencyListError: "",
  recruitAgencyListPageNo: 1,
  recruitAgencyListPageSize: 10,
  recruitAgencyListTotalPages: 0,
  filterRecruitAgencyPageNo: 1,
  filterRecruitAgencyPageSize: 10,
  filterRecruitAgencyTotalPages: 0,
  showRecruitAgencyExportDropdown: false,
  addRecruitAgencyLoading: false,
  recruitAgencyIds: [],
  recruitAgencyIdsLoading: false,
  recruitAgencyIdsError: "",
  //   Visa
  visaList: [],
  visaListLoading: false,
  visaListError: "",
  visaListPageNo: 1,
  visaListPageSize: 10,
  visaListTotalPages: 0,
  filterVisaPageNo: 1,
  filterVisaPageSize: 10,
  filterVisaTotalPages: 0,
  showVisaExportDropdown: false,
  addVisaLoading: false,
};

const campsSlice = createSlice({
  name: "camps",
  initialState: initialState,
  reducers: {
    setCampListLoading: (state, action) => {
      state.campsListLoading = action.payload;
    },
    setCampListError: (state, action) => {
      state.campsListError = action.payload;
    },
    setCampList: (state, action) => {
      state.campsList = action.payload;
    },
    setCampListPageNo: (state, action) => {
      state.campsListPageNo = action.payload;
    },
    setCampListPageSize: (state, action) => {
      state.campsListPageSize = action.payload;
    },
    setCampListTotalPages: (state, action) => {
      state.campsListTotalPages = action.payload;
    },
    setFilterCampPageNo: (state, action) => {
      state.filterCampPageNo = action.payload;
    },
    setFilterCampPageSize: (state, action) => {
      state.filterCampPageSize = action.payload;
    },
    setFilterCampTotalPages: (state, action) => {
      state.filterCampTotalPages = action.payload;
    },
    setShowCampExportDropdown: (state, action) => {
      state.showCampExportDropdown = action.payload;
    },
    setAddCampLoading: (state, action) => {
      state.addCampLoading = action.payload;
    },
    setCampsIds: (state, action) => {
      state.campsIds = action.payload;
    },
    setCampsIdsLoading: (state, action) => {
      state.campsIdsLoading = action.payload;
    },
    setCampsIdsError: (state, action) => {
      state.campsIdsError = action.payload;
    },
    // RecruitAgency
    setRecruitAgencyListLoading: (state, action) => {
      state.recruitAgencyListLoading = action.payload;
    },
    setRecruitAgencyListError: (state, action) => {
      state.recruitAgencyListError = action.payload;
    },
    setRecruitAgencyList: (state, action) => {
      state.recruitAgencyList = action.payload;
    },
    setRecruitAgencyListPageNo: (state, action) => {
      state.recruitAgencyListPageNo = action.payload;
    },
    setRecruitAgencyListPageSize: (state, action) => {
      state.recruitAgencyListPageSize = action.payload;
    },
    setRecruitAgencyListTotalPages: (state, action) => {
      state.recruitAgencyListTotalPages = action.payload;
    },
    setFilterRecruitAgencyPageNo: (state, action) => {
      state.filterRecruitAgencyPageNo = action.payload;
    },
    setFilterRecruitAgencyPageSize: (state, action) => {
      state.filterRecruitAgencyPageSize = action.payload;
    },
    setFilterRecruitAgencyTotalPages: (state, action) => {
      state.filterRecruitAgencyTotalPages = action.payload;
    },
    setShowRecruitAgencyExportDropdown: (state, action) => {
      state.showRecruitAgencyExportDropdown = action.payload;
    },
    setAddRecruitAgencyLoading: (state, action) => {
      state.addRecruitAgencyLoading = action.payload;
    },
    setRecruitAgencyIds: (state, action) => {
      state.recruitAgencyIds = action.payload;
    },
    setRecruitAgencyIdsLoading: (state, action) => {
      state.recruitAgencyIdsLoading = action.payload;
    },
    setRecruitAgencyIdsError: (state, action) => {
      state.recruitAgencyIdsError = action.payload;
    },
    // Visa
    setVisaListLoading: (state, action) => {
      state.visaListLoading = action.payload;
    },
    setVisaListError: (state, action) => {
      state.visaListError = action.payload;
    },
    setVisaList: (state, action) => {
      state.visaList = action.payload;
    },
    setVisaListPageNo: (state, action) => {
      state.visaListPageNo = action.payload;
    },
    setVisaListPageSize: (state, action) => {
      state.visaListPageSize = action.payload;
    },
    setVisaListTotalPages: (state, action) => {
      state.visaListTotalPages = action.payload;
    },
    setFilterVisaPageNo: (state, action) => {
      state.filterVisaPageNo = action.payload;
    },
    setFilterVisaPageSize: (state, action) => {
      state.filterVisaPageSize = action.payload;
    },
    setFilterVisaTotalPages: (state, action) => {
      state.filterVisaTotalPages = action.payload;
    },
    setShowVisaExportDropdown: (state, action) => {
      state.showVisaExportDropdown = action.payload;
    },
    setAddVisaLoading: (state, action) => {
      state.addVisaLoading = action.payload;
    },
  },
});

export const {
  setCampListLoading,
  setCampListError,
  setCampList,
  setCampListPageNo,
  setCampListPageSize,
  setCampListTotalPages,
  setFilterCampPageNo,
  setFilterCampPageSize,
  setFilterCampTotalPages,
  setShowCampExportDropdown,
  setAddCampLoading,
  setCampsIds,
  setCampsIdsLoading,
  setCampsIdsError,
  // RecruitAgency
  setRecruitAgencyListLoading,
  setRecruitAgencyListError,
  setRecruitAgencyList,
  setRecruitAgencyListPageNo,
  setRecruitAgencyListPageSize,
  setRecruitAgencyListTotalPages,
  setFilterRecruitAgencyPageNo,
  setFilterRecruitAgencyPageSize,
  setFilterRecruitAgencyTotalPages,
  setShowRecruitAgencyExportDropdown,
  setAddRecruitAgencyLoading,
  setRecruitAgencyIds,
  setRecruitAgencyIdsLoading,
  setRecruitAgencyIdsError,
  // Visa
  setVisaListLoading,
  setVisaListError,
  setVisaList,
  setVisaListPageNo,
  setVisaListPageSize,
  setVisaListTotalPages,
  setFilterVisaPageNo,
  setFilterVisaPageSize,
  setFilterVisaTotalPages,
  setShowVisaExportDropdown,
  setAddVisaLoading,
} = campsSlice.actions;
export default campsSlice.reducer;
