import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useRequestIqamaRenewMutation } from "../../services/Iqama";
import {
  setEmployeeChange,
  setIqamaChange,
} from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

interface Props {
  show?: boolean;
  setShow?: any;
  empId: number;
  expensesLength: number;
  insuranceStatus: string;
  payload: any;
}

const ConfirmInitiateIqamaRenewProcessModal = ({
  show,
  setShow,
  empId,
  expensesLength,
  insuranceStatus,
  payload,
}: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const iqamaChange = useAppSelector((state) => state.common.iqamaChange);
  const [requestIqamaRenew] = useRequestIqamaRenewMutation();
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const navigate = useNavigate();

  const handleInitiateProcess = async () => {
    setLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: payload,
      };
      await requestIqamaRenew(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      dispatch(setEmployeeChange(!employeeChange));
      window.location.reload();
      setShow(false);
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShowError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={setShow}
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          {insuranceStatus !== "Expired" && expensesLength > 0 && (
            <div className="mb-4 text-center">
              <h1 className="heading">Warning</h1>
              <h4 className="sub-heading normal-font-weight text-center">
                Employee have pending violations to be cleared!
              </h4>
              <h4 className="sub-heading normal-font-weight text-center">
                Are you sure you want to initiate the iqama renewal process?
              </h4>
              <div className="d-flex align-items-center justify-content-center gap-2">
                <button
                  className="btn primary-button border-0 mt-4"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn primary-button border-0 mt-4"
                  onClick={handleInitiateProcess}
                >
                  {loading ? (
                    <LoaderWhite height={30} width={30} />
                  ) : (
                    "Initiate"
                  )}
                </button>
              </div>
            </div>
          )}
          {insuranceStatus ? (
            insuranceStatus === "Expired" ? (
              <div className="mb-4 text-center">
                <h1 className="heading">Error!</h1>
                <h4 className="sub-heading normal-font-weight text-center">
                  Employee's insurance is expired. Please renew the insurance...
                </h4>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <button
                    className="btn primary-button border-0 mt-4"
                    onClick={() =>
                      navigate(`/employees/edit-employee/${empId}`, {
                        state: { empId: empId },
                      })
                    }
                  >
                    Update Insurance
                  </button>
                </div>
              </div>
            ) : null
          ) : (
            <div className="mb-4 text-center">
              <h1 className="heading">Error!</h1>
              <h4 className="sub-heading normal-font-weight text-center">
                Employee's insurance is expired. Please renew the insurance...
              </h4>
              <div className="d-flex align-items-center justify-content-center gap-2">
                <button
                  className="btn primary-button border-0 mt-4"
                  onClick={() =>
                    navigate(`/employees/edit-employee/${empId}`, {
                      state: { empId: empId },
                    })
                  }
                >
                  Update Insurance
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmInitiateIqamaRenewProcessModal;
