import { createSlice } from "@reduxjs/toolkit";
import {
  Vendor,
  VendorId,
  Billing,
  BillingId,
  VendorStatement,
  VendorBillFinance,
} from "../../interfaces/account-charts";

interface Index {
  vendorList: Vendor[];
  vendorListLoading: boolean;
  vendorListError: string;
  vendorListPageNo: number;
  vendorListPageSize: number;
  vendorListTotalPages: number;
  filterVendorPageNo: number;
  filterVendorPageSize: number;
  filterVendorTotalPages: number;
  showVendorExportDropdown: boolean;
  addVendorLoading: boolean;
  vendorIds: VendorId[];
  vendorIdsLoading: boolean;
  vendorIdsError: string;
  vendorIdNo: string;
  statementFrom: string;
  statementTo: string;
  vendorStatement: VendorStatement;
  vendorStatementLoading: boolean;
  vendorStatementError: string;
  showVendorBillExportDropdown: boolean;
  vendorBillPageSize: number;
  vendorBillPageNo: number;
  vendorBillTotalRecords: number;
  vendorBillLoading: boolean;
  vendorBillError: string;
  vendorBill: VendorBillFinance[];
  billStartFrom: string;
  billEndTo: string;
  searchIds: string[];
  payBillLoading: boolean;
  //   Billing
  billList: Billing[];
  billListLoading: boolean;
  billListError: string;
  billListPageNo: number;
  billListPageSize: number;
  billListTotalPages: number;
  filterBillPageNo: number;
  filterBillPageSize: number;
  filterBillTotalPages: number;
  showBillExportDropdown: boolean;
  addBillLoading: boolean;
  billIds: BillingId[];
  billIdsLoading: boolean;
  billIdsError: string;
}

const getFirstDateOfYear = () => {
  const year = new Date().getFullYear(); // Get the year of the provided date
  const firstDateOfYear = new Date(year, 0, 1); // Create a new date object with the first day of the year

  return firstDateOfYear;
};

const returnLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const initialState: Index = {
  vendorList: [],
  vendorListLoading: false,
  vendorListError: "",
  vendorListPageNo: 1,
  vendorListPageSize: 10,
  vendorListTotalPages: 0,
  filterVendorPageNo: 1,
  filterVendorPageSize: 10,
  filterVendorTotalPages: 0,
  showVendorExportDropdown: false,
  addVendorLoading: false,
  vendorIds: [],
  vendorIdsLoading: false,
  vendorIdsError: "",
  vendorIdNo: "",
  statementFrom: getFirstDateOfYear().toLocaleDateString(),
  statementTo: returnLastDateOfMonth().toLocaleDateString(),
  vendorStatement: {
    statements: [],
    openingBalance: 0,
    balanceDue: 0,
    billedAmount: 0,
    amountPaid: 0,
  },
  vendorStatementLoading: false,
  vendorStatementError: "",
  showVendorBillExportDropdown: false,
  vendorBillPageSize: 10,
  vendorBillPageNo: 1,
  vendorBillTotalRecords: 0,
  vendorBillLoading: false,
  vendorBillError: "",
  vendorBill: [],
  billStartFrom: getFirstDateOfYear().toLocaleDateString(),
  billEndTo: returnLastDateOfMonth().toLocaleDateString(),
  searchIds: ["All"],
  payBillLoading: false,
  //   Billing
  billList: [],
  billListLoading: false,
  billListError: "",
  billListPageNo: 1,
  billListPageSize: 10,
  billListTotalPages: 0,
  filterBillPageNo: 1,
  filterBillPageSize: 10,
  filterBillTotalPages: 0,
  showBillExportDropdown: false,
  addBillLoading: false,
  billIds: [],
  billIdsLoading: false,
  billIdsError: "",
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState: initialState,
  reducers: {
    setVendorListLoading: (state, action) => {
      state.vendorListLoading = action.payload;
    },
    setVendorListError: (state, action) => {
      state.vendorListError = action.payload;
    },
    setVendorList: (state, action) => {
      state.vendorList = action.payload;
    },
    setVendorListPageNo: (state, action) => {
      state.vendorListPageNo = action.payload;
    },
    setVendorListPageSize: (state, action) => {
      state.vendorListPageSize = action.payload;
    },
    setVendorListTotalPages: (state, action) => {
      state.vendorListTotalPages = action.payload;
    },
    setFilterVendorPageNo: (state, action) => {
      state.filterVendorPageNo = action.payload;
    },
    setFilterVendorPageSize: (state, action) => {
      state.filterVendorPageSize = action.payload;
    },
    setFilterVendorTotalPages: (state, action) => {
      state.filterVendorTotalPages = action.payload;
    },
    setShowVendorExportDropdown: (state, action) => {
      state.showVendorExportDropdown = action.payload;
    },
    setAddVendorLoading: (state, action) => {
      state.addVendorLoading = action.payload;
    },
    setVendorsIds: (state, action) => {
      state.vendorIds = action.payload;
    },
    setVendorsIdsLoading: (state, action) => {
      state.vendorIdsLoading = action.payload;
    },
    setVendorsIdsError: (state, action) => {
      state.vendorIdsError = action.payload;
    },
    setVendorIdNo: (state, action) => {
      state.vendorIdNo = action.payload;
    },
    setStatementFrom: (state, action) => {
      state.statementFrom = action.payload;
    },
    setStatementTo: (state, action) => {
      state.statementTo = action.payload;
    },
    setVendorStatement: (state, action) => {
      state.vendorStatement = action.payload;
    },
    setVendorStatementLoading: (state, action) => {
      state.vendorStatementLoading = action.payload;
    },
    setVendorStatementError: (state, action) => {
      state.vendorStatementError = action.payload;
    },
    setShowVendorBillExportDropdown: (state, action) => {
      state.showVendorBillExportDropdown = action.payload;
    },
    setVendorBillPageSize: (state, action) => {
      state.vendorBillPageSize = action.payload;
    },
    setVendorBillPageNo: (state, action) => {
      state.vendorBillPageNo = action.payload;
    },
    setVendorBillTotalRecords: (state, action) => {
      state.vendorBillTotalRecords = action.payload;
    },
    setVendorBillLoading: (state, action) => {
      state.vendorBillLoading = action.payload;
    },
    setVendorBillError: (state, action) => {
      state.vendorBillError = action.payload;
    },
    setVendorBill: (state, action) => {
      state.vendorBill = action.payload;
    },
    setBillStartFrom: (state, action) => {
      state.billStartFrom = action.payload;
    },
    setBillEndTo: (state, action) => {
      state.billEndTo = action.payload;
    },
    setSearchIds: (state, action) => {
      state.searchIds = action.payload;
    },
    setPayBillLoading: (state, action) => {
      state.payBillLoading = action.payload;
    },
    //   Billing
    setBillListLoading: (state, action) => {
      state.billListLoading = action.payload;
    },
    setBillListError: (state, action) => {
      state.billListError = action.payload;
    },
    setBillList: (state, action) => {
      state.billList = action.payload;
    },
    setBillListPageNo: (state, action) => {
      state.billListPageNo = action.payload;
    },
    setBillListPageSize: (state, action) => {
      state.billListPageSize = action.payload;
    },
    setBillListTotalPages: (state, action) => {
      state.billListTotalPages = action.payload;
    },
    setFilterBillPageNo: (state, action) => {
      state.filterBillPageNo = action.payload;
    },
    setFilterBillPageSize: (state, action) => {
      state.filterBillPageSize = action.payload;
    },
    setFilterBillTotalPages: (state, action) => {
      state.filterBillTotalPages = action.payload;
    },
    setShowBillExportDropdown: (state, action) => {
      state.showBillExportDropdown = action.payload;
    },
    setAddBillLoading: (state, action) => {
      state.addBillLoading = action.payload;
    },
    setBillIds: (state, action) => {
      state.billIds = action.payload;
    },
    setBillIdsLoading: (state, action) => {
      state.billIdsLoading = action.payload;
    },
    setBillIdsError: (state, action) => {
      state.billIdsError = action.payload;
    },
  },
});

export const {
  setVendorListLoading,
  setVendorListError,
  setVendorList,
  setVendorListPageNo,
  setVendorListPageSize,
  setVendorListTotalPages,
  setFilterVendorPageNo,
  setFilterVendorPageSize,
  setFilterVendorTotalPages,
  setShowVendorExportDropdown,
  setAddVendorLoading,
  setVendorsIds,
  setVendorsIdsLoading,
  setVendorsIdsError,
  setVendorIdNo,
  setStatementFrom,
  setStatementTo,
  setVendorStatement,
  setVendorStatementLoading,
  setVendorStatementError,
  setShowVendorBillExportDropdown,
  setVendorBillPageSize,
  setVendorBillPageNo,
  setVendorBillTotalRecords,
  setVendorBillLoading,
  setVendorBillError,
  setVendorBill,
  setBillStartFrom,
  setBillEndTo,
  setSearchIds,
  setPayBillLoading,
  //   Billing
  setBillListLoading,
  setBillListError,
  setBillList,
  setBillListPageNo,
  setBillListPageSize,
  setBillListTotalPages,
  setFilterBillPageNo,
  setFilterBillPageSize,
  setFilterBillTotalPages,
  setShowBillExportDropdown,
  setAddBillLoading,
  setBillIds,
  setBillIdsLoading,
  setBillIdsError,
} = vendorSlice.actions;
export default vendorSlice.reducer;
