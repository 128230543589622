import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";
import SelectSearch from "react-select-search";
import LogData from "./log-data";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  setAttendanceLogLoading,
  setAttendances,
  setPageNo,
  setPageSize,
  setTotalRecords,
} from "../../store/slices/attendanceSlice";
import { setAttendanceChange } from "../../store/slices/commonSlice";
// API Imports
import {
  useGetFilteredAttendanceMutation,
  useDeleteAttendanceMutation,
} from "../../services/Attendance";
import Content from "./get-todays-attendance-api";
import { AttendanceLog as AttendanceInterface } from "../../interfaces/attendance";

const AttendanceLog = () => {
  const dispatch = useAppDispatch();
  const [filterSearch, setFilterSearch] = React.useState(false);
  const projects = useAppSelector((state) => state.project.projectIds);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [attendancess, setAttendancess] = React.useState<AttendanceInterface[]>(
    [],
  );
  const [totalRecordss, setTotalRecordss] = React.useState(0);
  const [formValues, setFormValues] = React.useState({
    emp: "",
    project: "",
    from: "",
    to: "",
  });
  const attendanceChange = useAppSelector(
    (state) => state.common.attendanceChange,
  );
  const attendanceState = useAppSelector((state) => state.attendance);
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const employeeOptions = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));
  const projectOptions = projects.map((project) => ({
    name: project.projectId + " - " + project.projectName, // Use the actual property name for the project name
    value: project.projectId,
  }));
  const [getFilteredAttendance] = useGetFilteredAttendanceMutation();

  const handleSubmit = async () => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData.permissions.showAttendance || false,
      payload: {
        page: attendanceState.pageNo,
        limit: attendanceState.pageSize,
        empId: formValues.emp,
        projectId: formValues.project,
        fromDate: formValues.from,
        toDate: formValues.to,
      },
    };

    dispatch(setAttendanceLogLoading(true));

    try {
      const result = await getFilteredAttendance(data).unwrap();
      if (result) {
        setFilterSearch(true);
        setAttendancess(result.data.attendances[0].data);
        setTotalRecordss(result.data.attendances[0].metadata[0].total);
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAttendanceLogLoading(false));
    }
  };

  useEffect(() => {
    if (filterSearch) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [
    formValues.emp,
    formValues.project,
    formValues.from,
    formValues.to,
    attendanceState.pageNo,
    attendanceState.pageSize,
  ]);

  const getUnderscoreIds = attendanceState.attendances.map((item) => item._id);

  const [deleteAttendance] = useDeleteAttendanceMutation();

  const handleDeleteAll = async () => {
    setDeleteLoading(true);
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData.permissions.deleteAttendance || false,
      payload: {
        docId: "",
        type: "All",
        ids: getUnderscoreIds,
      },
    };
    try {
      await deleteAttendance(data).unwrap();
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <Content />
      <div className=" mt-2 section px-4 py-4">
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">Attendance Log</h1>
            </div>
          </Col>
          <Col sm="12">
            <Formik
              initialValues={{
                emp: "",
                project: "",
                from: "",
                to: "",
              }}
              onSubmit={(values) => handleSubmit()}
            >
              {({ values, handleChange, resetForm, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm="4">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Employee
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center">
                        <SelectSearch
                          name="emp"
                          options={employeeOptions}
                          value={formValues.emp}
                          onChange={(value: any) => {
                            setFormValues({
                              ...formValues,
                              emp: value,
                            });
                          }}
                          search={true}
                          placeholder="Select Employee"
                        />
                        <img src={Common.ArrowDown} alt="message" />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Project
                        </h4>
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <SelectSearch
                            name="project"
                            options={projectOptions}
                            value={formValues.project}
                            onChange={(value: any) => {
                              setFormValues({
                                ...formValues,
                                project: value,
                              });
                            }}
                            search={true}
                            placeholder="Select Project"
                          />
                          <img src={Common.ArrowDown} alt="message" />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          From
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="from"
                            selected={
                              formValues.from ? new Date(formValues.from) : null
                            }
                            onChange={(date: any) =>
                              setFormValues({
                                ...formValues,
                                from: date.toLocaleDateString(),
                              })
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="From date"
                            className="w-100 custom-input"
                            dateFormat="yyyy-MM-d"
                            required={formValues.to ? true : false}
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          To
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="to"
                            selected={
                              formValues.to ? new Date(formValues.to) : null
                            }
                            onChange={(date: any) =>
                              setFormValues({
                                ...formValues,
                                to: date.toLocaleDateString(),
                              })
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="To date"
                            className="w-100 custom-input"
                            dateFormat="yyyy-MM-d"
                            required={formValues.from ? true : false}
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="text-end">
                        {filterSearch && (
                          <button
                            type="button"
                            onClick={() => {
                              setFilterSearch(false);
                              setFormValues({
                                emp: "",
                                project: "",
                                from: "",
                                to: "",
                              });
                              handleDeleteAll();
                            }}
                            style={{ background: "#ff2b43" }}
                            className="btn primary-button px-4 me-2"
                          >
                            Delete All
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            setFilterSearch(false);
                            dispatch(setPageNo(1));
                            dispatch(setPageSize(10));
                            setAttendancess([]);
                            setTotalRecordss(0);
                            setFormValues({
                              emp: "",
                              project: "",
                              from: "",
                              to: "",
                            });
                            dispatch(setAttendanceChange(!attendanceChange));
                          }}
                          className="btn primary-button px-4 me-2"
                        >
                          Reset
                        </button>

                        <button
                          type="submit"
                          className="btn primary-button px-4"
                        >
                          Search
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
      <LogData attendancess={attendancess} totalRecordss={totalRecordss} />
    </>
  );
};
export default AttendanceLog;
