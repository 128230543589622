import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageOtherDetails,
  setDetailPageLegalInfo,
  setDetailActiveTab,
  setDetailPageAgreementDetails,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";

interface Props {
  empDetail: EmpDetail;
}

const OtherDetails = ({ empDetail }: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  return (
    <Row>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Duty Hours
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.dutyHours}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Salary Period
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.salaryPeriod}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Basic Salary
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            SAR {empDetail.basicSalary}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Total Salary
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            SAR {empDetail.empTotalSalary}
          </h5>
        </Form.Group>
      </Col>
      {empDetail.allowances.length > 0 && (
        <>
          <Col sm="12">
            <h3 className="sub-heading mb-4">Allowances</h3>
          </Col>
          <Col sm="12">
            {empDetail.allowances.map((item, index) => (
              <div key={index}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Title
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {item.title}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Category
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {item.category}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Amount
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {item.amount}
                      </h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        </>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailPageLegalInfo(true));
              dispatch(setDetailPageOtherDetails(false));
              dispatch(setDetailActiveTab("Legal Details"));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Agreement Details"));
              dispatch(setDetailPageOtherDetails(false));
              dispatch(setDetailPageAgreementDetails(true));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            Next <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default OtherDetails;
