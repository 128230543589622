import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

interface Props {
  show?: boolean;
  setShow?: any;
}

const AlreadyCompletedProfileModal = ({ show, setShow }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="general-modal"
    >
      <Modal.Body className="text-center px-5">
        <div className="mb-4 text-center">
          <h4 className="sub-heading normal-font-weight">
            Profile Already Completed!
          </h4>
          <button
            className="btn primary-button p-0 border-0 mt-4 w-25"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AlreadyCompletedProfileModal;
