import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { OfferLetter } from "../../interfaces/letters";
import LetterOffer from "../Letters/offer-letter";
import { Common } from "../../assets/images";

const OfferLetterDetail = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const statementData = location.state.data;

  return (
    <div className=" mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Offer Letter Detail</h1>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empTotalSalary}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Printed
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.offerLetterPrinted ? "Yes" : "No"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.offerLetterStatus}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">Offer Letter Approvals:</h2>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor Coordinator
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.offerLetterApprovals.supervisorCordinator}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item mb-4">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Finance
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.offerLetterApprovals.finance}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              {statementData.offerLetterApprovals.finance === "Accepted" &&
                statementData.offerLetterApprovals.supervisorCordinator ===
                  "Accepted" && (
                  <button
                    className="btn primary-button w-25"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                )}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default OfferLetterDetail;
