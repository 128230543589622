import { createSlice } from "@reduxjs/toolkit";
import { AttendanceLog } from "../../interfaces/attendance";

interface Index {
  addAttendanceEmployeeLoading: boolean;
  addAttendanceLoading: boolean;
  attendanceLogLoading: boolean;
  attendanceLogError: string;
  attendances: AttendanceLog[];
  editAttendanceLoading: boolean;
  pageNo: number;
  pageSize: number;
  totalRecords: number;
  filterPageNo: number;
  filterPageSize: number;
  filterTotalRecords: number;
}

const initialState: Index = {
  addAttendanceEmployeeLoading: false,
  addAttendanceLoading: false,
  attendanceLogLoading: false,
  attendanceLogError: "",
  attendances: [],
  editAttendanceLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRecords: 0,
  filterPageNo: 1,
  filterPageSize: 10,
  filterTotalRecords: 0,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {
    setAddAttendanceEmployeeLoading: (state, action) => {
      state.addAttendanceEmployeeLoading = action.payload;
    },
    setAddAttendanceLoading: (state, action) => {
      state.addAttendanceLoading = action.payload;
    },
    setAttendanceLogLoading: (state, action) => {
      state.attendanceLogLoading = action.payload;
    },
    setAttendanceLogError: (state, action) => {
      state.attendanceLogError = action.payload;
    },
    setAttendances: (state, action) => {
      state.attendances = action.payload;
    },
    setEditAttendanceLoading: (state, action) => {
      state.editAttendanceLoading = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setFilterPageSize: (state, action) => {
      state.filterPageSize = action.payload;
    },
    setFilterPageNo: (state, action) => {
      state.filterPageNo = action.payload;
    },
    setFilterTotalRecords: (state, action) => {
      state.filterTotalRecords = action.payload;
    },
  },
});

export const {
  setAddAttendanceEmployeeLoading,
  setAttendances,
  setEditAttendanceLoading,
  setAttendanceLogLoading,
  setAttendanceLogError,
  setAddAttendanceLoading,
  setFilterPageSize,
  setFilterTotalRecords,
  setFilterPageNo,
  setTotalRecords,
  setPageNo,
  setPageSize,
} = attendanceSlice.actions;
export default attendanceSlice.reducer;
