import React from "react";
import OtpVerificationComponent from "../components/OtpVerification";

const OtpVerification = () => {
  return (
    <>
      <OtpVerificationComponent />
    </>
  );
};

export default OtpVerification;
