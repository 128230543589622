import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Dashboard
import { useGetDashboardQuery } from "../../services/Dashboard";
import {
  setDashboardDataError,
  setDashboardDataLoading,
  setDashboardData,
} from "../../store/slices/dashboardSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  // **************
  // **************
  // Get Dashboard Data
  // **************
  // **************
  const {
    data: allDashboardData,
    isLoading: dashboardDataLoading,
    isError: dashboardDataError,
  } = useGetDashboardQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (dashboardDataLoading) {
      dispatch(setDashboardDataLoading(true));
    }
    if (dashboardDataError) {
      dispatch(setDashboardDataError("Something went wrong"));
      dispatch(setDashboardDataLoading(false));
    }
    if (allDashboardData) {
      dispatch(setDashboardData(allDashboardData.data));
      dispatch(setDashboardDataLoading(false));
    }
  }, [accessToken, userID, allDashboardData, profileData]);
  return <></>;
};
export default Content;
