import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { OfferLetter } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterOfferLetterTotalPages,
  setOfferLetterLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetFilteredOfferLettersMutation } from "../../services/Letters";

interface Props {
  show?: boolean;
  setShow?: any;
  setCandidates?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  candidates: OfferLetter[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  hiringStatus: string;
  department: string;
  fromDate: string;
  interviewConducted: string;
  toDate: string;
  role: string;
  nationality: string;
}

const InterviewedEmployeesFilterModal = ({
  show,
  setShow,
  candidates,
  setCandidates,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const settings = useAppSelector((state) => state.settings);
  const [getFilteredOfferLetters] = useGetFilteredOfferLettersMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setOfferLetterLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterOfferLetterPageNo,
          limit: state.filterOfferLetterPageSize,
          hiringStatus: filterInput.hiringStatus,
          department: filterInput.department,
          interviewDateFrom: filterInput.fromDate,
          interviewConducted: filterInput.interviewConducted,
          interviewDateTo: filterInput.toDate,
          role: filterInput.role,
          nationality: filterInput.nationality,
        },
      };
      const response = await getFilteredOfferLetters(data).unwrap();
      if (response) {
        setCandidates(response.data.candidates[0].data);
        dispatch(
          setFilterOfferLetterTotalPages(
            response.data.candidates[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setOfferLetterLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setOfferLetterLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterOfferLetterPageNo,
            limit: state.filterOfferLetterPageSize,
            intervieweeId: query,
            hiringStatus: filterInput.hiringStatus,
            department: filterInput.department,
            interviewDateFrom: filterInput.fromDate,
            interviewConducted:
              filterInput.interviewConducted === "Yes" ? true : false,
            interviewDateTo: filterInput.toDate,
            role: filterInput.role,
            nationality: filterInput.nationality,
          },
        };
        const response = await getFilteredOfferLetters(data).unwrap();
        if (response) {
          setCandidates(response.data.candidates[0].data);
          dispatch(
            setFilterOfferLetterTotalPages(
              response.data.candidates[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setOfferLetterLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterOfferLetterPageNo, state.filterOfferLetterPageSize, query]);

  const handleReset = () => {
    setCandidates(candidates);
    setFilterInput({
      hiringStatus: "",
      department: "",
      fromDate: "",
      interviewConducted: "",
      toDate: "",
      role: "",
      nationality: "",
    });
  };

  // get rolename from setting.roles as array of string
  const roles = settings.roles.map((item) => item.roleName);

  const categoriesList = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Other",
  ];

  const categories = [...roles, ...categoriesList];

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
  ];

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter New Candidates</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Nationality
                    </h4>
                    <Form.Select
                      name="nationality"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          nationality: e.target.value,
                        })
                      }
                      value={filterInput.nationality}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Nationality</option>
                      {countryCodes.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Hiring Status
                    </h4>
                    <Form.Select
                      name="hiringStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          hiringStatus: e.target.value,
                        })
                      }
                      value={filterInput.hiringStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Department
                    </h4>
                    <Form.Select
                      name="department"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          department: e.target.value,
                        })
                      }
                      value={filterInput.department}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Department</option>
                      <option value="General">General</option>
                      <option value="Operations">Operations</option>
                      <option value="Accounts">Accounts</option>
                      <option value="Finance">Finance</option>
                      <option value="HR">HR</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Role
                    </h4>
                    <Form.Select
                      name="role"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          role: e.target.value,
                        })
                      }
                      value={filterInput.role}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Role</option>
                      {categories.map((item: string, index: number) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Interview Conducted:
                    </h4>
                    <Form.Select
                      name="interviewConducted"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          interviewConducted: e.target.value,
                        })
                      }
                      value={filterInput.interviewConducted}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      placeholder="From date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          fromDate: e.target.value,
                        })
                      }
                      value={filterInput.fromDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="toDate"
                      placeholder="To date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          toDate: e.target.value,
                        })
                      }
                      value={filterInput.toDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InterviewedEmployeesFilterModal;
