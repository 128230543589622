import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ToastErrorComponent } from "../Toasts";
import { setClientsChange } from "../../store/slices/commonSlice";
import InvoiceCategoriesSet from "./invoice-categories-set";
import { Common, EmployeeIcons } from "../../assets/images";
import { ClientId, Invoice } from "../../interfaces/project";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";
import Invoices from "../Letters/invoice";
import { SponsorId } from "../../interfaces/sponsor";
import DatePicker from "react-datepicker";
import { useUpdateInvoiceMutation } from "../../services/Clients";
import {
  setAddInvoiceLoading,
  setClientIdNumber,
} from "../../store/slices/clientsSlice";

const EditInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceData: Invoice = location.state.data;
  const type = invoiceData.invoiceType;
  const dispatch = useAppDispatch();
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.clients);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  const [creditNoteNumber, setCreditNoteNumber] = useState<string>(
    invoiceData.creditNoteNumber || "",
  );
  const [transactionType, setTransactionType] = useState<string>(
    invoiceData.transactionType || "",
  );
  const [creditNoteDate, setCreditNoteDate] = useState<string>(
    invoiceData.creditNoteDate || new Date().toLocaleDateString(),
  );
  const [totalAmountToBeReceived, setTotalAmountToBeReceived] =
    useState<string>("");
  const [invoiceDate, setInvoiceDate] = useState<string>(
    invoiceData.invoiceDate || new Date().toLocaleDateString(),
  );
  const [companyId, setCompanyId] = useState<string>(
    invoiceData.companyId || "",
  );
  const [client, setClient] = useState<string>(invoiceData.clientId || "");
  const [invoiceNumber, setInvoiceNumber] = useState<string>(
    invoiceData.invoiceId || "",
  );
  const [subject, setSubject] = useState<string>(invoiceData.subject || "");
  const [taxPercentage, setTaxPercentage] = useState<string>(
    invoiceData.taxApplied.toString() || "",
  );
  const [banckAccount, setBankAccount] = useState<string>(
    invoiceData.bankDetails.accountNumber.toString() || "",
  );
  const [hideLetterHead, setHideLetterHead] = useState(false);
  const [bankDetails, setBankDetails] = useState<{
    bankName: string;
    iban: string;
    accountNumber: number;
    swiftCode: string;
  }>(
    invoiceData.bankDetails || {
      bankName: "",
      iban: "",
      accountNumber: 0,
      swiftCode: "",
    },
  );
  const clientIds = useAppSelector((state) => state.clients.clientIds);
  // Options
  const clientOptions = clientIds.map((item) => ({
    name: item.clientId + " - " + item.clientName,
    value: item.clientId,
  }));
  const sponsorIds = useAppSelector((state) => state.sponsor.sponsorIds);
  // Options
  const companyOptions = sponsorIds.map((item) => ({
    name: item.companyId + " - " + item.companyName,
    value: item.companyId,
  }));
  const companySelected = sponsorIds.find(
    (item) => item.companyId === companyId,
  );
  const bankOptions = companySelected
    ? companySelected.bankDetails.map((item) => ({
        name: item.bankName,
        value: item.accountNumber,
      }))
    : [
        {
          name: "Select Company First",
          value: "",
        },
      ];
  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      discount: number;
      amount: number;
      category: string;
      quantity: number;
      categoryPrice: number;
      discountType: string;
    }[]
  >(invoiceData.invoiceItem || []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        discount: 0,
        amount: 0,
        category: "",
        quantity: 0,
        categoryPrice: 0,
        discountType: "percent",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      discount: number;
      amount: number;
      category: string;
      quantity: number;
      categoryPrice: number;
      discountType: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <InvoiceCategoriesSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  const finalTax = parseFloat(taxPercentage) / 100;

  const totalAmount = experienceData.reduce(
    (acc, category) => acc + category.amount,
    0,
  );

  const totalVAT = experienceData.reduce(
    (acc, category) => acc + category.amount * finalTax,
    0,
  );

  // API call for add salary increment
  const [updateInvoice] = useUpdateInvoiceMutation();
  const handleSubmit = async () => {
    if (!companyId) {
      window.scrollTo(0, 0);
      setErrorContent("Please select company");
      setShow(true);
      return;
    }
    if (!client) {
      window.scrollTo(0, 0);
      setErrorContent("Please select client");
      setShow(true);
      return;
    }
    dispatch(setAddInvoiceLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          invoiceType: type,
          creditNoteNumber: creditNoteNumber,
          creditNoteDate: creditNoteDate
            ? new Date(creditNoteDate).toLocaleDateString()
            : "",
          transactionType: transactionType,
          totalAmountToBeReceived: totalAmountToBeReceived,
          invoiceId: invoiceNumber,
          invoiceDate: invoiceDate
            ? new Date(invoiceDate).toLocaleDateString()
            : "",
          subject: subject,
          taxApplied: parseFloat(taxPercentage),
          clientId: client,
          companyId: companyId,
          bankDetails: bankDetails,
          invoiceItem: experienceData,
          totalAmount: totalAmount,
          totalVAT: totalVAT,
          link: `/clients`,
        },
      };
      await updateInvoice(data).unwrap();
      dispatch(setClientsChange(!clientsChange));
      navigate(`/clients`);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddInvoiceLoading(false));
    }
  };

  const clientData = clientIds.find((item) => item.clientId === client);

  const dummyClient: ClientId = {
    _id: "",
    clientId: "",
    clientName: "",
    companyName: "",
    contactPersonName: "",
    contactPersonDesignation: "",
    phone: "",
    clientMOL: "",
    clientAddress1: "",
    clientAddressArabic: "",
    numberVAT: 0,
    clientNameArabic: "",
  };

  const dummyCompany: SponsorId = {
    _id: "",
    companyId: "",
    companyName: "",
    companyNameArabic: "",
    companyAddress: "",
    companyAddressArabic: "",
    numberVAT: 0,
    bankDetails: [],
    unifiedNumber: 0,
  };

  const bankSelected = companySelected?.bankDetails.find(
    (item) => item.accountNumber === parseInt(banckAccount),
  );

  useEffect(() => {
    if (companySelected && bankSelected) {
      setBankDetails({
        bankName: bankSelected.bankName,
        iban: bankSelected.iban,
        accountNumber: bankSelected.accountNumber,
        swiftCode: bankSelected.swiftCode,
      });
    }
  }, [companyId, banckAccount]);

  const creditNoteData = {
    creditNoteNumber,
    transactionType,
    creditNoteDate,
    totalAmountToBeReceived: parseFloat(totalAmountToBeReceived),
  };

  const invoiceIds = useAppSelector((state) => state.clients.invoiceIds);

  const invoiceOtions = invoiceIds
    .filter((item) => item.invoiceType === "Invoice" && item.status !== "Paid")
    .map((item) => ({
      name: item.invoiceId + " - " + item.invoiceType,
      value: item.invoiceId,
    }));

  const invoice = invoiceIds
    .filter((item) => item.invoiceType === "Invoice")
    .find((item) => item.invoiceId === invoiceNumber);

  useEffect(() => {
    if (type === "Credit Note" && invoice) {
      setInvoiceDate(invoice.invoiceDate);
      setTotalAmountToBeReceived(invoice.remainingAmount.toString());
    }
  }, [invoiceNumber, type]);

  return (
    <div className="mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <Invoices
            client={clientData ? clientData : dummyClient}
            company={companySelected ? companySelected : dummyCompany}
            bankDetails={bankDetails}
            subject={subject}
            type={type}
            invoiceDate={invoiceDate}
            creditNoteData={creditNoteData}
            tax={taxPercentage}
            removeLetterHead={hideLetterHead}
            invoiceNumber={invoiceNumber}
            categories={experienceData}
          />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                  Edit
                  {invoiceData.invoiceType === "Invoice"
                    ? " Invoice"
                    : " Credit Note"}
                </h4>
              </div>
            </div>
          </Col>
          {type === "Credit Note" && (
            <>
              <Col sm="3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Credit Note #
                  </h4>
                  <Form.Control
                    type="text"
                    name="creditNoteNumber"
                    placeholder="Enter credit note number"
                    onChange={(e) => setCreditNoteNumber(e.target.value)}
                    value={creditNoteNumber}
                    disabled={true}
                    className="custom-input disabled-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Credit Note Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="creditNoteDate"
                      selected={
                        creditNoteDate ? new Date(creditNoteDate) : null
                      }
                      onChange={(e: any) => setCreditNoteDate(e)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select credit note date"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Transaction Type
                  </h4>
                  <Form.Control
                    type="text"
                    name="transactionType"
                    placeholder="Enter transaction type"
                    onChange={(e) => setTransactionType(e.target.value)}
                    value={transactionType}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            </>
          )}

          <Col sm="3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Select Company
              </h4>
              <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                <SelectSearch
                  name="companyId"
                  options={companyOptions}
                  value={companyId}
                  onChange={(value: any) => setCompanyId(value)}
                  search={true}
                  placeholder="Select Company"
                />
                <img src={Common.ArrowDown} alt="message" />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Select Client
              </h4>
              <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                <SelectSearch
                  name="client"
                  options={clientOptions}
                  value={client}
                  onChange={(value: any) => {
                    setClient(value);
                    dispatch(setClientIdNumber(value));
                  }}
                  search={true}
                  placeholder="Select Client"
                />
                <img src={Common.ArrowDown} alt="message" />
              </Form.Group>
            </Form.Group>
          </Col>
          {type === "Invoice" ? (
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Invoice #
                </h4>
                <Form.Control
                  type="text"
                  name="invoiceNumber"
                  placeholder="Enter invoice number"
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  value={invoiceNumber}
                  disabled={true}
                  className="custom-input disabled-input border-fill"
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Invoice Id
                </h4>
                <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                  <SelectSearch
                    name="invoiceNumber"
                    options={invoiceOtions}
                    value={invoiceNumber}
                    onChange={(value: any) => setInvoiceNumber(value)}
                    search={true}
                    placeholder="Select Invoice Id"
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </Form.Group>
            </Col>
          )}
          <Col sm="3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Invoice Date
              </h4>
              <Form.Group
                className={
                  type === "Credit Note"
                    ? "d-flex align-items-center input-design disabled-input position-relative w-100"
                    : "d-flex align-items-center input-design position-relative w-100"
                }
              >
                <DatePicker
                  name="invoiceDate"
                  selected={invoiceDate ? new Date(invoiceDate) : null}
                  onChange={(e: any) => setInvoiceDate(e)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  disabled={type === "Credit Note"}
                  placeholderText="Select invoice date"
                  className={
                    type === "Credit Note"
                      ? "w-100 custom-input disabled-input"
                      : "w-100 custom-input"
                  }
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
          </Col>
          {type === "Credit Note" && (
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Total Amount To Be Received
                </h4>
                <Form.Control
                  type="text"
                  name="totalAmountToBeReceived"
                  placeholder="Enter total amount to be received"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setTotalAmountToBeReceived(e.target.value);
                    }
                  }}
                  disabled={type === "Credit Note"}
                  value={totalAmountToBeReceived}
                  className={
                    type === "Credit Note"
                      ? "custom-input disabled-input border-fill"
                      : "custom-input border-fill"
                  }
                />
              </Form.Group>
            </Col>
          )}
          <Col sm="3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Subject
              </h4>
              <Form.Control
                type="text"
                name="subject"
                placeholder="Enter subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Tax %
              </h4>
              <Form.Control
                type="text"
                name="taxPercentage"
                placeholder="Enter tax percentage"
                onChange={(e) => {
                  //     accept only numbers
                  const re = /^[0-9\b]*(\.\d{0,2})?$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setTaxPercentage(e.target.value);
                  }
                }}
                value={taxPercentage}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
          {type === "Invoice" && (
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Bank Account
                </h4>
                <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                  <SelectSearch
                    name="banckAccount"
                    options={bankOptions}
                    value={banckAccount}
                    onChange={(value: any) => setBankAccount(value)}
                    search={true}
                    placeholder="Select Bank Account"
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </Form.Group>
            </Col>
          )}
          <Col sm="12">
            <Form.Check
              type="checkbox"
              id="hideLetterHeadCheckbox" // Add an id to target the checkbox
              label="Hide Senyar Letter Head"
              className="hide-letter-head mt-4"
              name="hideLetterHead"
              checked={hideLetterHead}
              onChange={(e) => {
                setHideLetterHead(e.target.checked);
              }}
            />
          </Col>
          <Col sm="12">
            <div className="text-end mt-4 d-flex align-items-center gap-4">
              <button
                className="btn primary-button normal-font-weight"
                type="button"
                onClick={handleAddExperienceSet}
              >
                <img
                  src={Common.Plus}
                  width="20px"
                  height="20px"
                  className="me-2"
                  alt="next"
                />{" "}
                Goods & Services
              </button>
            </div>
          </Col>
          <Col sm="12">
            <div className="extra-detail my-4">
              {experienceSets.length > 0 && (
                <h4 className="sub-heading normal-font-weight mb-4">
                  Goods & Services
                </h4>
              )}
              {experienceSets}
            </div>
            <div className="text-end">
              <button
                className="btn primary-button w-25 me-3"
                onClick={handlePrint}
              >
                Preview {type === "Invoice" ? "Invoice" : "Credit Note"}
              </button>
              <button
                className="btn primary-button w-25"
                onClick={handleSubmit}
              >
                {state.addInvoiceLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : type === "Invoice" ? (
                  "Update Invoice"
                ) : (
                  "Update Credit Note"
                )}
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EditInvoice;
