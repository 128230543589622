import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import * as Yup from "yup";
// API
import { useAddUserMutation } from "../../services/Settings";
import { setSettingsChange } from "../../store/slices/commonSlice";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import SelectSearch from "react-select-search";
import { Common } from "../../assets/images";

interface FormProps {
  email: string;
  department: string;
}

const AddUser = () => {
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const [empId, setEmpId] = useState("");
  const navigate = useNavigate();

  const [addUser, { isLoading: addUserLoading }] = useAddUserMutation();

  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    department: Yup.string().required("Select Department"),
  });
  const handleSubmit = async (values: FormProps) => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      payload: {
        employeeId: empId,
        email: values.email,
        role: selectedEmployee?.empCategory || "",
        department: values.department,
        myRole: state.profileData.designation || "",
        link: "/settings/view-user",
      },
    };
    try {
      await addUser(data).unwrap();
      dispatch(setSettingsChange(!settingsChange));
      navigate("/settings/users");
    } catch (e: any) {
      if (e.data) {
        window.scrollTo(0, 0);
        setShow(true);
        setErrorContent(e.data.message);
      }
    }
  };

  const filteredEmployees = employees.filter(
    (item) => item.empPlace === "Management",
  );
  const options = filteredEmployees.map((employee) => ({
    name: employee.empId + " " + employee.empName, // Use the actual property name for the project name
    value: employee.empId,
  }));
  const selectedEmployee = filteredEmployees.find(
    (item) => item.empId === parseInt(empId),
  );
  //   Initial Values
  const initialValues: FormProps = {
    email: "",
    department: "",
  };
  return (
    <div className="add-user mt-2">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">Add New User</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        value={empId}
                        onChange={(e: any) => {
                          setEmpId(e);
                        }}
                        search={true}
                        placeholder="Select Employee"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter employee email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={
                          touched.email && errors.email
                            ? "is-invalid custom-input border-1"
                            : "custom-input border-1"
                        }
                      />

                      {state.emailError ? (
                        <div className="invalid-feedback">
                          {state.emailError}
                        </div>
                      ) : (
                        <>
                          {touched.email && errors.email ? (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          ) : null}
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Select
                        name="designation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          selectedEmployee?.empCategory || "Select Emp Id."
                        }
                        disabled={true}
                        className="custom-input disabled-input"
                      >
                        {state.loading ? (
                          <option value="" disabled={true}>
                            Loading...
                          </option>
                        ) : (
                          <>
                            {state.allDesignations.length > 0 ? (
                              <>
                                <option value="">Select Designation</option>
                                {state.allDesignations.map((role, index) => (
                                  <option key={index} value={role.roleName}>
                                    {role.roleName}
                                  </option>
                                ))}{" "}
                              </>
                            ) : (
                              <option disabled={true} value="">
                                No Roles Added
                              </option>
                            )}
                          </>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Select
                        name="department"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.department}
                        className={
                          touched.department && errors.department
                            ? "is-invalid custom-input"
                            : "custom-input"
                        }
                      >
                        <option value="">Select Department</option>
                        <option value="General">General</option>
                        <option value="Operations">Operations</option>
                        <option value="Accounts">Accounts</option>
                        <option value="Finance">Finance</option>
                        <option value="HR">HR</option>
                        <option value="Marketing">Marketing</option>
                      </Form.Select>

                      {touched.department && errors.department ? (
                        <div className="invalid-feedback">
                          {errors.department}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn primary-button normal-font-weight"
                      >
                        {addUserLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddUser;
