import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Dashboard
import { useGetPaymentsRecievedQuery } from "../../../services/Reports";
import {
  getPaymentsRecievedRequest,
  getPaymentsRecievedFailure,
  getPaymentsRecievedSuccess,
} from "../../../store/slices/reportsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  // State
  const state = useAppSelector((state) => state.reports);

  // **************
  // **************
  // Get Dashboard Data
  // **************
  // **************
  const {
    data: allPaymentsRecieved,
    isLoading: paymentsRecievedLoading,
    isError: paymentsRecievedError,
  } = useGetPaymentsRecievedQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: state.paymentsRecievedPageNo,
    pageSize: state.paymentsRecievedPageSize,
    fromDate: new Date(state.paymentsRecievedFromDate).toLocaleDateString(),
    toDate: new Date(state.paymentsRecievedToDate).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (paymentsRecievedLoading) {
      dispatch(getPaymentsRecievedRequest());
    }
    if (paymentsRecievedError) {
      dispatch(getPaymentsRecievedFailure(paymentsRecievedError));
    }
    if (allPaymentsRecieved) {
      dispatch(
        getPaymentsRecievedSuccess({
          data: allPaymentsRecieved.data.paymentsReceived[0].data || [],
          totalPages:
            allPaymentsRecieved.data.paymentsReceived[0].metadata[0]?.total ||
            0,
        }),
      );
    }
  }, [
    accessToken,
    userID,
    allPaymentsRecieved,
    profileData,
    state.paymentsRecievedFromDate,
    state.paymentsRecievedToDate,
    state.paymentsRecievedPageNo,
    state.paymentsRecievedPageSize,
  ]);
  return <></>;
};
export default Content;
