import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  Camp,
  CampId,
  RecruitAgency,
  RecruitAgencyId,
  Visa,
} from "../../interfaces/employee";

interface CampList {
  data: {
    camps: {
      data: Camp[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface RecruitAgencyList {
  data: {
    recruiters: {
      data: RecruitAgency[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface VisaList {
  data: {
    visa: {
      data: Visa[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface VisaListWithoutMetadata {
  data: {
    visa: Visa[];
  };
}

interface CampIds {
  data: {
    campIds: CampId[];
  };
}

interface RecruitAgencyIds {
  data: {
    recruiterIds: RecruitAgencyId[];
  };
}

export const campsAPI = createApi({
  reducerPath: "/api/camps",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getCampList: builder.query<
      CampList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-camps/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getCampIds: builder.query<
      CampIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-camp-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addCamp: builder.mutation({
      query: (data) => ({
        url: `/add-camp/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editCamp: builder.mutation({
      query: (data) => ({
        url: `/edit-camp/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteCamp: builder.mutation({
      query: (data) => ({
        url: `/delete-camp/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterCampList: builder.mutation<
      CampList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-camps/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Recruit Agency
    getRecruitAgencyList: builder.query<
      RecruitAgencyList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-recruiters/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getRecruitAgencyIds: builder.query<
      RecruitAgencyIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-recruiter-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addRecruitAgency: builder.mutation({
      query: (data) => ({
        url: `/add-recruiter/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editRecruitAgency: builder.mutation({
      query: (data) => ({
        url: `/edit-recruiter/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteRecruitAgency: builder.mutation({
      query: (data) => ({
        url: `/delete-recruiter/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterRecruitAgencyList: builder.mutation<
      RecruitAgencyList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-recruiters/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Visa
    getVisaList: builder.query<
      VisaListWithoutMetadata,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-visas/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addVisa: builder.mutation({
      query: (data) => ({
        url: `/add-visa/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editVisa: builder.mutation({
      query: (data) => ({
        url: `/edit-visa/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteVisa: builder.mutation({
      query: (data) => ({
        url: `/delete-visa/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterVisaList: builder.mutation<
      VisaList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-visas/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetCampListQuery,
  useGetCampIdsQuery,
  useAddCampMutation,
  useEditCampMutation,
  useDeleteCampMutation,
  useGetFilterCampListMutation,
  useGetRecruitAgencyListQuery,
  useGetRecruitAgencyIdsQuery,
  useAddRecruitAgencyMutation,
  useEditRecruitAgencyMutation,
  useDeleteRecruitAgencyMutation,
  useGetFilterRecruitAgencyListMutation,
  useGetVisaListQuery,
  useAddVisaMutation,
  useDeleteVisaMutation,
  useGetFilterVisaListMutation,
  useEditVisaMutation,
} = campsAPI;
