import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { Sponsor } from "../../interfaces/sponsor";
import { Common, EmployeeIcons } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";

const ViewSponsor = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const companysData: Sponsor = location.state.data;

  const [showOwners, setShowOwners] = React.useState(false);
  const [showSaudiEmployees, setShowSaudiEmployees] = React.useState(false);

  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const selectedEmployees = employees.filter((employee) => {
    return companysData.registeredSaudis.includes(employee.empId);
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = selectedEmployees.slice(startIndex, endIndex);

  const totalPages = Math.ceil(selectedEmployees.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Place",
      "Status",
    ],
    tableData: paginatedData,
  };

  let count = 1;

  const selectedOwners = employees.filter((employee) => {
    return companysData.companyOwners.includes(employee.empId);
  });

  const ownersData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Place",
      "Status",
    ],
    tableData: selectedOwners,
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h1 className="heading mb-0">Company Detail</h1>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.companyId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.companyName +
                    " " +
                    companysData.companyNameArabic}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company's Contact No
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.phone}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Unified Number
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.unifiedNumber}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company MOL
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.companyMOL}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Postal Code
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.postalCode}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  VAT Number
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.numberVAT}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  CR Number
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.numberCR}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  CR Expiry Date
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.crExpiry
                    ? new Date(companysData.crExpiry).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Status
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.companyStatus}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Company Address
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {companysData.companyAddress +
                    " " +
                    companysData.companyAddressArabic}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="table-responsive mb-3">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Email List
            </h4>
            {companysData.emails.length > 0 ? (
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {companysData.emails.map((item: string, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.toLocaleLowerCase()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center">No emails added</div>
            )}
          </div>
        </Col>

        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Bank Details</h1>
          </div>
        </Col>
        <Col sm="12">
          {companysData.bankDetails.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Bank Name</th>
                    <th>IBAN</th>
                    <th>Account Number</th>
                    <th>Swift Code</th>
                  </tr>
                </thead>
                <tbody>
                  {companysData.bankDetails.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.bankName}</td>
                      <td>{item.iban}</td>
                      <td>{item.accountNumber}</td>
                      <td>{item.swiftCode}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Bank Details Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Subscriptions</h1>
          </div>
        </Col>
        <Col sm="12">
          {companysData.subscriptions.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Category</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Cost</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {companysData.subscriptions.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.category}</td>
                      <td>
                        {item.fromDate
                          ? new Date(item.fromDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        {item.toDate
                          ? new Date(item.toDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.cost}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No subscriptions found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Attachments</h1>
          </div>
        </Col>
        <Col sm="12">
          {companysData.companyAttachments.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Date</th>
                    <th>File Title</th>
                    <th>Uploader Emp Id</th>
                    <th>Uploader Emp Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companysData.companyAttachments.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.date
                          ? new Date(item.date).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.title}</td>
                      <td>{item.empId}</td>
                      <td>{item.empName}</td>
                      <td>
                        <a href={item.link} download>
                          <img
                            src={Common.Download}
                            className="me-2"
                            width="24px"
                            height="24px"
                            alt="share"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Attachment Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => setShowOwners(!showOwners)}
            >
              Show Company Owners
            </button>
            <button
              className="btn primary-button w-25"
              onClick={() => setShowSaudiEmployees(!showSaudiEmployees)}
            >
              Show Saudi Employees
            </button>
          </div>
        </Col>
        {showOwners && (
          <>
            <Col sm="12">
              <div className="selected-employees mt-4">
                <h4 className="sub-heading">Company Owners</h4>
                {tableData.tableData.length > 0 ? (
                  <Table striped bordered responsive className=" text-center">
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th className="border-0" key={index}>
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>{count++}</td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                {
                                  state: { empId: item.empId },
                                },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                {
                                  state: { empId: item.empId },
                                },
                              )
                            }
                          >
                            {item.empName}
                          </td>
                          <td>{item.empPhone}</td>
                          <td>{item.empIqamaNo}</td>
                          <td>{item.empPlace}</td>
                          <td>{item.empStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center mt-5">
                    <h1 className="heading">No Owners</h1>
                  </div>
                )}
              </div>
            </Col>
          </>
        )}
        {showSaudiEmployees && (
          <>
            <Col sm="12">
              <div className="selected-employees mt-4">
                <h4 className="sub-heading">Saudi Employees</h4>
                {tableData.tableData.length > 0 ? (
                  <Table striped bordered responsive className=" text-center">
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th className="border-0" key={index}>
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>{count++}</td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                {
                                  state: { empId: item.empId },
                                },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                {
                                  state: { empId: item.empId },
                                },
                              )
                            }
                          >
                            {item.empName}
                          </td>
                          <td>{item.empPhone}</td>
                          <td>{item.empIqamaNo}</td>
                          <td>{item.empPlace}</td>
                          <td>{item.empStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center mt-5">
                    <h1 className="heading">No Saudi Employees</h1>
                  </div>
                )}
              </div>
            </Col>
            {paginatedData.length > 0 && (
              <Col sm="12">
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  <Form.Group
                    className="d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "14px", width: "230px" }}
                    >
                      Entries per page
                    </h4>
                    <Form.Select
                      name="pageSize"
                      onChange={handlePageSizeChange}
                      value={pageSize.toString()}
                      className="custom-input border-fill custom-height"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="100">100</option>
                    </Form.Select>
                  </Form.Group>
                  <div className="pagination-custom">
                    <Pagination className="justify-content-end">
                      <Pagination.Item
                        onClick={() => handleGoToPreviousPages()}
                      >
                        <img src={Common.DoubleArrowLeft} alt="arrow" />
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1,
                          )
                        }
                      >
                        <img
                          src={EmployeeIcons.ArrowDownBlue}
                          className="rotate-left"
                          alt="arrow"
                        />
                      </Pagination.Item>

                      {pages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(page + 1)}
                          className={
                            page + 1 === currentPage ? "active-page" : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}

                      <Pagination.Item
                        onClick={() =>
                          handlePageChange(
                            currentPage < totalPages
                              ? currentPage + 1
                              : totalPages,
                          )
                        }
                      >
                        <img
                          src={EmployeeIcons.ArrowDownBlue}
                          className="rotate-right"
                          alt="arrow"
                        />
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() => {
                          handleGoToNextPages();
                        }}
                      >
                        <img src={Common.DoubleArrowRight} alt="arrow" />
                      </Pagination.Item>
                    </Pagination>
                  </div>
                </div>
              </Col>
            )}
          </>
        )}
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewSponsor;
