import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";

interface JournalProps {
  data: {
    account: string;
    debit: number;
    credit: number;
    description: string;
    contact: string;
  };
  setLength: number;
  onRemove: (key: string) => void;
  onInputChange: (data: {
    account: string;
    debit: number;
    credit: number;
    description: string;
    contact: string;
  }) => void;
  uniqueId: string;
}

const JournalSet: React.FC<JournalProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
  setLength,
}) => {
  const accountOptions = useAppSelector(
    (state) => state.accountCharts.accountChartsIds,
  );
  const [account, setAccount] = useState(data.account);
  const [debit, setDebit] = useState(data.debit);
  const [credit, setCredit] = useState(data.credit);
  const [description, setDescription] = useState(data.description);
  const [contact, setContact] = useState(data.contact);
  const vendorIds = useAppSelector((state) => state.vendors.vendorIds);
  const handleInputChange = () => {
    onInputChange({
      account,
      debit,
      credit,
      description,
      contact,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [account, debit, credit, description, contact]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Account
                </h4>
                <Form.Select
                  name="account"
                  onChange={(e) => setAccount(e.target.value)}
                  value={account}
                  className="custom-input border-fill"
                >
                  <option value="">Select Account</option>
                  {accountOptions.map((item, index: number) => (
                    <option key={index} value={item.accountName}>
                      {item.accountId + " - " + item.accountName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Description
                </h4>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Contact (SAR)
                </h4>
                <Form.Select
                  name="contact"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  className="custom-input border-fill"
                >
                  <option value="">Select Contact</option>
                  {vendorIds.map((item, index: number) => (
                    <option key={index} value={item.vendorId}>
                      {item.vendorId + " - " + item.vendorName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Debit
                </h4>
                <Form.Control
                  type="text"
                  name="debit"
                  placeholder="Enter debit"
                  onChange={(e) => {
                    setCredit(0);
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setDebit(Number(e.target.value));
                    }
                  }}
                  value={debit}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>

            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Credit
                </h4>
                <Form.Control
                  type="text"
                  name="credit"
                  placeholder="Enter credit"
                  onChange={(e) => {
                    setDebit(0);
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCredit(Number(e.target.value));
                    }
                  }}
                  value={credit}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        {setLength > 2 && (
          <Col sm="1">
            <div className="d-flex gap-2 align-items-center">
              <button
                onClick={() => {
                  onRemove(uniqueId);
                }}
                className="btn primary-button"
                type="button"
              >
                <img
                  src={Common.Cross}
                  height="20px"
                  width="20px"
                  alt="cross"
                />
              </button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default JournalSet;
