import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { FinaLExitEmployee } from "../../interfaces/letters";
import FinalExitLetter from "../Letters/final-exit-letter";
import { Common } from "../../assets/images";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { LoaderWhite } from "../Lotties";
import { useEditFinalExitMutation } from "../../services/Letters";
import { setAddFinalExitLoading } from "../../store/slices/lettersSlice";
import { setFinalExitChange } from "../../store/slices/commonSlice";
import {
  setAbscondedEmployeesList,
  setActiveTabEmpList,
  setCertificatesList,
  setEmployeesList,
  setFinalExitEmployeesList,
  setOfferLettersList,
  setProbationEmployeesList,
  setTerminatedEmployeesList,
} from "../../store/slices/employeeSlice";
import { ToastErrorComponent } from "../Toasts";

const FinalExitDetail = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: FinaLExitEmployee = location.state.data;
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountReceived, setAmountReceived] = useState(0);
  const [showReturn, setShowReturn] = useState(false);
  const [reactivateLoading, setReactivateLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const finalExitChange = useAppSelector(
    (state) => state.common.finalExitChange,
  );

  // check if all status are approved in approvals array
  const isApproved = statementData.finalExitApprovals.every(
    (item) =>
      item.status === "Approved" || item.status === "Approved by Super Admin",
  );

  const [editFinalExit] = useEditFinalExitMutation();
  const handleSubmit = async () => {
    dispatch(setAddFinalExitLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: statementData.empId,
          amountPaid: amountPaid,
          amountReceived: amountReceived,
          profileStatus: false,
          link: `/employees`,
        },
      };
      await editFinalExit(data).unwrap();
      dispatch(setFinalExitChange(!finalExitChange));
      dispatch(setActiveTabEmpList("Final Exit Employees"));
      dispatch(setEmployeesList(false));
      dispatch(setProbationEmployeesList(false));
      dispatch(setAbscondedEmployeesList(false));
      dispatch(setTerminatedEmployeesList(false));
      dispatch(setFinalExitEmployeesList(true));
      dispatch(setOfferLettersList(false));
      dispatch(setCertificatesList(false));
      navigate("/employees");
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddFinalExitLoading(false));
    }
  };

  const handleReactivateEmp = async () => {
    dispatch(setAddFinalExitLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: statementData.empId,
          profileStatus: true,
          link: `/employees`,
        },
      };
      await editFinalExit(data).unwrap();
      dispatch(setFinalExitChange(!finalExitChange));
      dispatch(setActiveTabEmpList("Final Exit Employees"));
      dispatch(setEmployeesList(false));
      dispatch(setProbationEmployeesList(false));
      dispatch(setAbscondedEmployeesList(false));
      dispatch(setTerminatedEmployeesList(false));
      dispatch(setFinalExitEmployeesList(true));
      dispatch(setOfferLettersList(false));
      dispatch(setCertificatesList(false));
      navigate("/employees");
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddFinalExitLoading(false));
    }
  };

  const balance = statementData.remaining
    ? statementData.remaining[0].totalBalance
    : "N/A";

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <FinalExitLetter exitData={statementData} />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Final Exit Detail</h1>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Id.
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empProjectId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Supervisor Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empSupervisorName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Final Exit Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.finalExitDate
                      ? new Date(
                          statementData.finalExitDate,
                        ).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Remaining Balance
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {balance}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Last Salary Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData?.lastSalaryStatus ? "Paid" : "Unpaid"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Final Exit Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.finalExitApprovalsStatus}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="12">
                <Form.Group className="mb-5">
                  <h4 className="sub-heading" style={{ fontSize: "20px" }}>
                    Submitted By:
                  </h4>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.finalExitSubmittedBy.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.finalExitSubmittedBy.empName}
                  </h5>
                </Form.Group>
              </Col>
              {statementData.finalExitSubmittedBy.remarks && (
                <Col sm="12">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Remarks
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.finalExitSubmittedBy.remarks}
                    </h5>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">
                Employee Final Exit Approvals:
              </h2>
              <Row>
                {statementData.finalExitApprovals.length > 0 ? (
                  <>
                    {statementData.finalExitApprovals.map((item, index) => (
                      <div className="col-sm-4" key={index}>
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            {item.name} ({item.role})
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {item.status}{" "}
                            {item.status !== "Pending"
                              ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                              : null}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Approvals
                    </h4>
                  </div>
                )}
              </Row>
            </div>
          </Col>

          {!statementData.lastSalaryStatus ? (
            <>
              <Col sm="12">
                <div className="mb-4">
                  <button
                    className="btn primary-button"
                    onClick={() => setShowReturn(!showReturn)}
                  >
                    Clear Balance
                  </button>
                </div>
              </Col>
              {showReturn && (
                <Col sm="12">
                  <Row>
                    <Col sm="4">
                      <Form.Group controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Amount Received
                        </h4>
                        <Form.Control
                          type="number"
                          name="amountReceived"
                          onChange={(e) =>
                            setAmountReceived(Number(e.target.value))
                          }
                          placeholder="Enter amount received"
                          value={amountReceived}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Amount Paid
                        </h4>
                        <Form.Control
                          type="number"
                          name="amountPaid"
                          onChange={(e) =>
                            setAmountPaid(Number(e.target.value))
                          }
                          placeholder="Enter amount paid"
                          value={amountPaid}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <div>
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Action
                        </h4>
                        <button
                          className="btn primary-button"
                          onClick={handleSubmit}
                        >
                          {reactivateLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Clear Balance"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          ) : null}
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-5">
              <button
                className="btn primary-button"
                onClick={handleReactivateEmp}
              >
                {reactivateLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : (
                  "Re-activate Employee"
                )}
              </button>
              {isApproved && (
                <button
                  className="btn primary-button w-25"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default FinalExitDetail;
