import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { setAddExpensesLoading } from "../../../store/slices/lettersSlice";
import { setViolationsChange } from "../../../store/slices/commonSlice";
// API imports
import { useEditExpenseMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";
import DatePicker from "react-datepicker";
import AttachmentSet from "../AddEmployee/attachments";
import InstallmentsSet from "./installments";

const EditFineClearance = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fcData = location.state.data;
  // Violations Sets
  const [payAbleBy, setPayAbleBy] = useState(fcData.payAbleBy);
  const [expenseStatus, setExpenseStatus] = useState(fcData.expenseStatus);
  const [expenseDate, setExpenseDate] = useState(fcData.expenseDate);
  const [expenseAmount, setExpenseAmount] = useState(fcData.expenseAmount);
  const [expenseDetail, setExpenseDetail] = useState(fcData.expenseDetail);
  const [paymentMethod, setPaymentMethod] = useState(fcData.paymentMethod);

  // Adding Violations and hitting API
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const violationsChange = useAppSelector(
    (state) => state.common.violationsChange,
  );

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >(fcData.expensesAttachments || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: "",
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Installments
  const [installmentsSets, setInstallmentsSets] = useState<React.ReactNode[]>(
    [],
  );
  const [installmentsData, setInstallmentsData] = useState<
    {
      uId: string;
      month: string;
      amount: number;
      status: string;
    }[]
  >(fcData.installments || []);

  const handleAddInstallmentsSet = () => {
    setInstallmentsData([
      ...installmentsData,
      {
        uId: Math.random().toString(36).substring(7),
        month: "",
        amount: 0,
        status: "Pending",
      },
    ]);
  };

  const handleRemoveInstallmentsSet = (index: string) => {
    const updatedInputData = installmentsData.filter(
      (item, i) => item.uId !== index,
    );
    setInstallmentsData(updatedInputData);
  };
  const handleInstallmentsInputChange = (
    index: string,
    data: {
      month: string;
      amount: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = installmentsData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setInstallmentsData(updatedInput);
  };

  useEffect(() => {
    const updatedInstallmentsSets = installmentsData.map((data, index) => (
      <InstallmentsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        installments={installmentsData}
        onRemove={(key: string) => handleRemoveInstallmentsSet(key)}
        onInputChange={(inputData) =>
          handleInstallmentsInputChange(data.uId, inputData)
        }
      />
    ));
    setInstallmentsSets(updatedInstallmentsSets);
  }, [installmentsData]);

  const sumOfInstallments = installmentsData.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  // check if all fields are filled in installments Data
  const checkInstallmentsData = () => {
    let isFilled = true;
    installmentsData.forEach((item) => {
      if (
        item.amount === 0 ||
        item.month === "" ||
        item.month === undefined ||
        item.month === "Invalid Date"
      ) {
        isFilled = false;
      }
    });
    return isFilled;
  };

  const [editExpense] = useEditExpenseMutation();

  const handleSave = async () => {
    if (!expenseDate) {
      window.scrollTo(0, 0);
      setErrorContent("Please select an expense date");
      setShow(true);
      return;
    }
    if (!expenseAmount) {
      window.scrollTo(0, 0);
      setErrorContent("Please enter expense amount");
      setShow(true);
      return;
    }
    if (!payAbleBy) {
      window.scrollTo(0, 0);
      setErrorContent("Please select payable by");
      setShow(true);
      return;
    }
    if (!checkInstallmentsData()) {
      window.scrollTo(0, 0);
      setErrorContent("Please fill all fields in installments");
      setShow(true);
      return;
    }
    if (
      sumOfInstallments !== parseInt(expenseAmount) &&
      installmentsData.length > 0
    ) {
      window.scrollTo(0, 0);
      setErrorContent("Sum of installments should be equal to expense amount");
      setShow(true);
      return;
    }
    dispatch(setAddExpensesLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          expenseId: fcData._id,
          expense: {
            expenseDate: expenseDate,
            expenseStatus:
              fcData.expenseStatus !== "Pending"
                ? expenseStatus
                : fcData.expenseStatus,
            expenseAmount: parseInt(expenseAmount),
            expenseDetail: expenseDetail,
            payAbleBy: payAbleBy,
            installments: installmentsData,
            paymentMethod: paymentMethod,
            expensesAttachments: languageData,
            link: `/hr/expenses`,
            submittedBy: {
              empId: profileData.employeeId,
              empName: profileData.firstName + " " + profileData.lastName,
              remarks: "",
            },
          },
        },
      };
      await editExpense(data).unwrap();
      dispatch(setViolationsChange(!violationsChange));
      navigate("/hr/expenses");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddExpensesLoading(false));
    }
  };

  const transactionMethods: string[] = [
    "Cash",
    "Credit/Debit Cards",
    "Bank Transfers",
    "Checks",
    "Mobile Payments",
    "Online Banking",
    "Wire Transfers",
    "Cryptocurrency",
    "Money Orders",
    "Electronic Funds Transfer (EFT)",
    "PayPal and Other Online Payment Services",
    "Automatic Clearing House (ACH) Payments",
    "Contactless Payments",
    "Smart Cards",
    "Point of Sale (POS) Systems",
    "Barter",
    "Installment Plans",
    "Direct Debit",
  ];

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Edit Expense</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="date"
                      selected={expenseDate ? new Date(expenseDate) : null}
                      onChange={(e: any) => setExpenseDate(e)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select expense date"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
              {expenseStatus !== "Pending" && (
                <Col sm="4">
                  <Form.Group controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Expense Status
                    </h4>
                    <Form.Select
                      name="selectedLanguage"
                      onChange={(e) => setExpenseStatus(e.target.value)}
                      value={expenseStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Expense Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Cleared">Cleared</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Expense Amount
                  </h4>
                  <Form.Control
                    type="text"
                    placeholder="Enter expense amount"
                    name="expenseAmount"
                    onChange={(e) => setExpenseAmount(Number(e.target.value))}
                    value={expenseAmount}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Payable By
                  </h4>
                  <Form.Select
                    name="selectedLanguage"
                    onChange={(e) => setPayAbleBy(e.target.value)}
                    value={payAbleBy}
                    className="custom-input border-fill"
                  >
                    <option value="">Select Payable By</option>
                    <option value="Pending">Pending</option>
                    <option value="Cleared">Cleared</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Payment Method
                  </h4>
                  <Form.Select
                    name="paymentMethod"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    value={paymentMethod}
                    className="custom-input border-fill"
                  >
                    <option value="">Select payment method</option>
                    {transactionMethods.map((method, index) => (
                      <option key={index} value={method}>
                        {method}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mt-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Description:
                  </h4>
                  <textarea
                    name="description"
                    placeholder="Leave a comment here..."
                    value={expenseDetail}
                    onChange={(e) => setExpenseDetail(e.target.value)}
                    className="border-fill w-100"
                    rows={4}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>
              <Col sm="12">
                <div className="text-end mt-4 d-flex align-items-center gap-4">
                  <button
                    className="btn primary-button normal-font-weight"
                    type="button"
                    onClick={handleAddInstallmentsSet}
                  >
                    <img
                      src={Common.Plus}
                      width="20px"
                      height="20px"
                      className="me-2"
                      alt="next"
                    />{" "}
                    Installments
                  </button>
                  <button
                    className="btn primary-button normal-font-weight"
                    type="button"
                    onClick={handleAddLanguageSet}
                  >
                    <img
                      src={Common.Plus}
                      width="20px"
                      height="20px"
                      className="me-2"
                      alt="next"
                    />{" "}
                    Attachments
                  </button>
                </div>
              </Col>
              <Col sm="12">
                {expenseStatus === "Pending" && (
                  <>
                    {installmentsSets.length > 0 && (
                      <h4
                        className="sub-heading normal-font-weight textBlack mt-4"
                        style={{ fontSize: "18px" }}
                      >
                        Installments
                      </h4>
                    )}
                    <div className="extra-detail my-4">{installmentsSets}</div>
                  </>
                )}
                {languageSets.length > 0 && (
                  <h4
                    className="sub-heading normal-font-weight textBlack mt-4"
                    style={{ fontSize: "18px" }}
                  >
                    Attachments
                  </h4>
                )}
                <div className="extra-detail my-4">{languageSets}</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSave}>
              {state.addExpensesLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default EditFineClearance;
