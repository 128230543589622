import { createSlice } from "@reduxjs/toolkit";
import { ManualJournal } from "../../interfaces/account-charts";

interface Index {
  manualJournalList: ManualJournal[];
  manualJournalListLoading: boolean;
  manualJournalListError: string;
  manualJournalListPageNo: number;
  manualJournalListPageSize: number;
  manualJournalListTotalPages: number;
  type: string;
  showManualJournalExportDropdown: boolean;
  showManualJournalFilterDropdown: boolean;
  addManualJournalLoading: boolean;
}

const initialState: Index = {
  manualJournalList: [],
  manualJournalListLoading: false,
  manualJournalListError: "",
  manualJournalListPageNo: 1,
  manualJournalListPageSize: 10,
  manualJournalListTotalPages: 0,
  type: "All",
  showManualJournalExportDropdown: false,
  showManualJournalFilterDropdown: false,
  addManualJournalLoading: false,
};

const manualJournalSlice = createSlice({
  name: "manualJournal",
  initialState: initialState,
  reducers: {
    setManualJournalList: (state, action) => {
      state.manualJournalList = action.payload;
    },
    setManualJournalListLoading: (state, action) => {
      state.manualJournalListLoading = action.payload;
    },
    setManualJournalListError: (state, action) => {
      state.manualJournalListError = action.payload;
    },
    setManualJournalListPageNo: (state, action) => {
      state.manualJournalListPageNo = action.payload;
    },
    setManualJournalListPageSize: (state, action) => {
      state.manualJournalListPageSize = action.payload;
    },
    setManualJournalListTotalPages: (state, action) => {
      state.manualJournalListTotalPages = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setShowManualJournalExportDropdown: (state, action) => {
      state.showManualJournalExportDropdown = action.payload;
    },
    setShowManualJournalFilterDropdown: (state, action) => {
      state.showManualJournalFilterDropdown = action.payload;
    },
    setAddManualJournalLoading: (state, action) => {
      state.addManualJournalLoading = action.payload;
    },
  },
});

export const {
  setManualJournalList,
  setManualJournalListLoading,
  setManualJournalListError,
  setManualJournalListPageNo,
  setManualJournalListPageSize,
  setManualJournalListTotalPages,
  setType,
  setShowManualJournalExportDropdown,
  setShowManualJournalFilterDropdown,
  setAddManualJournalLoading,
} = manualJournalSlice.actions;
export default manualJournalSlice.reducer;
