import { createSlice } from "@reduxjs/toolkit";
import { EmployeePayroll, EmployeePayslip } from "../../interfaces/employee";

interface Index {
  employeePayrollList: EmployeePayroll[];
  employeePayrollListLoading: boolean;
  employeePayrollListError: string;
  employeePayrollListPageNo: number;
  employeePayrollListPageSize: number;
  employeePayrollListTotalPages: number;
  showEmployeePayrollExportDropdown: boolean;
  addEmployeePayrollLoading: boolean;
  fromDate: string;
  toDate: string;
  empIds: string[];
  // Payslips
  showPayslipExportDropdown: boolean;
  payslips: EmployeePayslip[];
  payslipPageNo: number;
  payslipPageSize: number;
  payslipTotalRecords: number;
  payslipLoading: boolean;
  payslipError: string;
  payStartFrom: string;
  payEndTo: string;
  searchIds: string[];
}

const returnLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const returnFirstDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const initialState: Index = {
  employeePayrollList: [],
  employeePayrollListLoading: false,
  employeePayrollListError: "",
  employeePayrollListPageNo: 1,
  employeePayrollListPageSize: 10,
  employeePayrollListTotalPages: 0,
  showEmployeePayrollExportDropdown: false,
  addEmployeePayrollLoading: false,
  fromDate: returnFirstDateOfMonth().toLocaleDateString(),
  toDate: returnLastDateOfMonth().toLocaleDateString(),
  empIds: ["All"],
  //   Payslips
  showPayslipExportDropdown: false,
  payslips: [],
  payslipPageNo: 1,
  payslipPageSize: 10,
  payslipTotalRecords: 0,
  payslipLoading: false,
  payslipError: "",
  payStartFrom: returnFirstDateOfMonth().toLocaleDateString(),
  payEndTo: returnLastDateOfMonth().toLocaleDateString(),
  searchIds: ["All"],
};

const employeePayrollSlice = createSlice({
  name: "employeePayroll",
  initialState: initialState,
  reducers: {
    setEmployeePayrollListLoading: (state, action) => {
      state.employeePayrollListLoading = action.payload;
    },
    setEmployeePayrollListError: (state, action) => {
      state.employeePayrollListError = action.payload;
    },
    setEmployeePayrollList: (state, action) => {
      state.employeePayrollList = action.payload;
    },
    setEmployeePayrollListPageNo: (state, action) => {
      state.employeePayrollListPageNo = action.payload;
    },
    setEmployeePayrollListPageSize: (state, action) => {
      state.employeePayrollListPageSize = action.payload;
    },
    setEmployeePayrollListTotalPages: (state, action) => {
      state.employeePayrollListTotalPages = action.payload;
    },
    setShowEmployeePayrollExportDropdown: (state, action) => {
      state.showEmployeePayrollExportDropdown = action.payload;
    },
    setAddEmployeePayrollLoading: (state, action) => {
      state.addEmployeePayrollLoading = action.payload;
    },
    setFromDate: (state, action) => {
      state.fromDate = action.payload;
    },
    setToDate: (state, action) => {
      state.toDate = action.payload;
    },
    setEmpIds: (state, action) => {
      state.empIds = action.payload;
    },
    //   Payslips
    setPayslips: (state, action) => {
      state.payslips = action.payload;
    },
    setPayslipPageNo: (state, action) => {
      state.payslipPageNo = action.payload;
    },
    setPayslipPageSize: (state, action) => {
      state.payslipPageSize = action.payload;
    },
    setPayslipTotalRecords: (state, action) => {
      state.payslipTotalRecords = action.payload;
    },
    setPayslipLoading: (state, action) => {
      state.payslipLoading = action.payload;
    },
    setPayslipError: (state, action) => {
      state.payslipError = action.payload;
    },
    setPayStartFrom: (state, action) => {
      state.payStartFrom = action.payload;
    },
    setPayEndTo: (state, action) => {
      state.payEndTo = action.payload;
    },
    setShowPayslipExportDropdown: (state, action) => {
      state.showPayslipExportDropdown = action.payload;
    },
    setSearchIds: (state, action) => {
      state.searchIds = action.payload;
    },
  },
});

export const {
  setEmployeePayrollListLoading,
  setEmployeePayrollListError,
  setEmployeePayrollList,
  setEmployeePayrollListPageNo,
  setEmployeePayrollListPageSize,
  setEmployeePayrollListTotalPages,
  setShowEmployeePayrollExportDropdown,
  setAddEmployeePayrollLoading,
  setEmpIds,
  setFromDate,
  setToDate,
  setPayslips,
  setPayslipPageNo,
  setPayslipPageSize,
  setPayslipTotalRecords,
  setPayslipLoading,
  setPayslipError,
  setPayStartFrom,
  setPayEndTo,
  setShowPayslipExportDropdown,
  setSearchIds,
} = employeePayrollSlice.actions;
export default employeePayrollSlice.reducer;
