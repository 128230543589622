import { createSlice } from "@reduxjs/toolkit";
import {
  CustomerBalance,
  PaymentsReceived,
  Sales,
  CreditNoteDetails,
} from "../../interfaces/reports";

interface Index {
  // Customer Balance Sheet
  customerBalance: CustomerBalance[];
  customerBalanceLoading: boolean;
  customerBalanceError: string;
  customerBalancePageNo: number;
  customerBalancePageSize: number;
  customerBalanceTotalPages: number;
  customerBalanceFromDate: string;
  customerBalanceToDate: string;
  //   Payments Recieved
  paymentsRecieved: PaymentsReceived[];
  paymentsRecievedLoading: boolean;
  paymentsRecievedError: string;
  paymentsRecievedPageNo: number;
  paymentsRecievedPageSize: number;
  paymentsRecievedTotalPages: number;
  paymentsRecievedFromDate: string;
  paymentsRecievedToDate: string;
  //   Sales
  sales: Sales[];
  salesLoading: boolean;
  salesError: string;
  salesPageNo: number;
  salesPageSize: number;
  salesTotalPages: number;
  salesFromDate: string;
  salesToDate: string;
  //   Credit Note Details
  creditNoteDetails: CreditNoteDetails[];
  creditNoteDetailsLoading: boolean;
  creditNoteDetailsError: string;
  creditNoteDetailsPageNo: number;
  creditNoteDetailsPageSize: number;
  creditNoteDetailsTotalPages: number;
  creditNoteDetailsFromDate: string;
  creditNoteDetailsToDate: string;
}

// const getFirstDateOfYear = () => {
//   const year = new Date().getFullYear(); // Get the year of the provided date
//   const firstDateOfYear = new Date(year, 0, 1); // Create a new date object with the first day of the year
//
//   return firstDateOfYear;
// };

const returnFirstDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const returnLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const initialState: Index = {
  // Customer Balance Sheet
  customerBalance: [],
  customerBalanceLoading: false,
  customerBalanceError: "",
  customerBalancePageNo: 1,
  customerBalancePageSize: 10,
  customerBalanceTotalPages: 0,
  customerBalanceFromDate: returnFirstDateOfMonth().toLocaleDateString(),
  customerBalanceToDate: returnLastDateOfMonth().toLocaleDateString(),
  //   Payments Recieved
  paymentsRecieved: [],
  paymentsRecievedLoading: false,
  paymentsRecievedError: "",
  paymentsRecievedPageNo: 1,
  paymentsRecievedPageSize: 10,
  paymentsRecievedTotalPages: 0,
  paymentsRecievedFromDate: returnFirstDateOfMonth().toLocaleDateString(),
  paymentsRecievedToDate: returnLastDateOfMonth().toLocaleDateString(),
  //   Sales
  sales: [],
  salesLoading: false,
  salesError: "",
  salesPageNo: 1,
  salesPageSize: 10,
  salesTotalPages: 0,
  salesFromDate: returnFirstDateOfMonth().toLocaleDateString(),
  salesToDate: returnLastDateOfMonth().toLocaleDateString(),
  //   Credit Note Details
  creditNoteDetails: [],
  creditNoteDetailsLoading: false,
  creditNoteDetailsError: "",
  creditNoteDetailsPageNo: 1,
  creditNoteDetailsPageSize: 10,
  creditNoteDetailsTotalPages: 0,
  creditNoteDetailsFromDate: returnFirstDateOfMonth().toLocaleDateString(),
  creditNoteDetailsToDate: returnLastDateOfMonth().toLocaleDateString(),
};

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    // Customer Balance Sheet
    getCustomerBalanceRequest: (state) => {
      state.customerBalanceLoading = true;
      state.customerBalanceError = "";
    },
    getCustomerBalanceSuccess: (state, action) => {
      state.customerBalance = action.payload.data;
      state.customerBalanceLoading = false;
      state.customerBalanceError = "";
      state.customerBalanceTotalPages = action.payload.totalPages;
    },
    getCustomerBalanceFailure: (state, action) => {
      state.customerBalanceLoading = false;
      state.customerBalanceError = action.payload;
    },
    setCustomerBalancePageNo: (state, action) => {
      state.customerBalancePageNo = action.payload;
    },
    setCustomerBalancePageSize: (state, action) => {
      state.customerBalancePageSize = action.payload;
    },
    setCustomerBalanceFromDate: (state, action) => {
      state.customerBalanceFromDate = action.payload;
    },
    setCustomerBalanceToDate: (state, action) => {
      state.customerBalanceToDate = action.payload;
    },
    // Payments Recieved
    getPaymentsRecievedRequest: (state) => {
      state.paymentsRecievedLoading = true;
      state.paymentsRecievedError = "";
    },
    getPaymentsRecievedSuccess: (state, action) => {
      state.paymentsRecieved = action.payload.data;
      state.paymentsRecievedLoading = false;
      state.paymentsRecievedError = "";
      state.paymentsRecievedTotalPages = action.payload.totalPages;
    },
    getPaymentsRecievedFailure: (state, action) => {
      state.paymentsRecievedLoading = false;
      state.paymentsRecievedError = action.payload;
    },
    setPaymentsRecievedPageNo: (state, action) => {
      state.paymentsRecievedPageNo = action.payload;
    },
    setPaymentsRecievedPageSize: (state, action) => {
      state.paymentsRecievedPageSize = action.payload;
    },
    setPaymentsRecievedFromDate: (state, action) => {
      state.paymentsRecievedFromDate = action.payload;
    },
    setPaymentsRecievedToDate: (state, action) => {
      state.paymentsRecievedToDate = action.payload;
    },
    // Sales
    getSalesRequest: (state) => {
      state.salesLoading = true;
      state.salesError = "";
    },
    getSalesSuccess: (state, action) => {
      state.sales = action.payload.data;
      state.salesLoading = false;
      state.salesError = "";
      state.salesTotalPages = action.payload.totalPages;
    },
    getSalesFailure: (state, action) => {
      state.salesLoading = false;
      state.salesError = action.payload;
    },
    setSalesPageNo: (state, action) => {
      state.salesPageNo = action.payload;
    },
    setSalesPageSize: (state, action) => {
      state.salesPageSize = action.payload;
    },
    setSalesFromDate: (state, action) => {
      state.salesFromDate = action.payload;
    },
    setSalesToDate: (state, action) => {
      state.salesToDate = action.payload;
    },
    // Credit Note Details
    getCreditNoteDetailsRequest: (state) => {
      state.creditNoteDetailsLoading = true;
      state.creditNoteDetailsError = "";
    },
    getCreditNoteDetailsSuccess: (state, action) => {
      state.creditNoteDetails = action.payload.data;
      state.creditNoteDetailsLoading = false;
      state.creditNoteDetailsError = "";
      state.creditNoteDetailsTotalPages = action.payload.totalPages;
    },
    getCreditNoteDetailsFailure: (state, action) => {
      state.creditNoteDetailsLoading = false;
      state.creditNoteDetailsError = action.payload;
    },
    setCreditNoteDetailsPageNo: (state, action) => {
      state.creditNoteDetailsPageNo = action.payload;
    },
    setCreditNoteDetailsPageSize: (state, action) => {
      state.creditNoteDetailsPageSize = action.payload;
    },
    setCreditNoteDetailsFromDate: (state, action) => {
      state.creditNoteDetailsFromDate = action.payload;
    },
    setCreditNoteDetailsToDate: (state, action) => {
      state.creditNoteDetailsToDate = action.payload;
    },
  },
});

export const {
  // Customer Balance Sheet
  getCustomerBalanceRequest,
  getCustomerBalanceSuccess,
  getCustomerBalanceFailure,
  setCustomerBalancePageNo,
  setCustomerBalancePageSize,
  setCustomerBalanceFromDate,
  setCustomerBalanceToDate,
  // Payments Recieved
  getPaymentsRecievedRequest,
  getPaymentsRecievedSuccess,
  getPaymentsRecievedFailure,
  setPaymentsRecievedPageNo,
  setPaymentsRecievedPageSize,
  setPaymentsRecievedFromDate,
  setPaymentsRecievedToDate,
  // Sales
  getSalesRequest,
  getSalesSuccess,
  getSalesFailure,
  setSalesPageNo,
  setSalesPageSize,
  setSalesFromDate,
  setSalesToDate,
  // Credit Note Details
  getCreditNoteDetailsRequest,
  getCreditNoteDetailsSuccess,
  getCreditNoteDetailsFailure,
  setCreditNoteDetailsPageNo,
  setCreditNoteDetailsPageSize,
  setCreditNoteDetailsFromDate,
  setCreditNoteDetailsToDate,
} = reportsSlice.actions;
export default reportsSlice.reducer;
