import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetManualJournalListQuery } from "../../services/ManualJournal";
import {
  setManualJournalList,
  setManualJournalListLoading,
  setManualJournalListError,
  setManualJournalListTotalPages,
} from "../../store/slices/manualJournalSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const manualJournalChange = useAppSelector(
    (state) => state.common.manualJournalChange,
  );
  // States
  const state = useAppSelector((state) => state.manualJournal);

  // **************
  // **************
  // Get All Camps
  // **************
  // **************
  const {
    data: allManualJournal,
    isLoading: manualJournalListLoading,
    isError: manualJournalListError,
    refetch: manualJournalListRefetch,
  } = useGetManualJournalListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    pageNo: state.manualJournalListPageNo,
    pageSize: state.manualJournalListPageSize,
    type: state.type,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (manualJournalListLoading) {
      dispatch(setManualJournalListLoading(true));
    }
    if (manualJournalListError) {
      dispatch(setManualJournalListError("Something went wrong"));
      dispatch(setManualJournalListLoading(false));
    }
    if (allManualJournal) {
      dispatch(
        setManualJournalList(allManualJournal.data.allJournals[0].data || []),
      );
      dispatch(
        setManualJournalListTotalPages(
          allManualJournal.data.allJournals[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setManualJournalListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allManualJournal,
    profileData,
    state.manualJournalListPageNo,
    state.manualJournalListPageSize,
    state.type,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    manualJournalListRefetch();
  }, [manualJournalChange]);
  return <></>;
};
export default Content;
