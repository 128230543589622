import React, { useEffect, useState } from "react";
// Packages
import { BrowserRouter as Router } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getCommonValues } from "./store/slices/commonSlice";
// Functions/Components
import AllRoutes from "./pages/routes";
import Content from "./content";
import NotificationComponent from "./firebaseNotifications/Notification";
// Css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import "react-select-search/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const isTablet = (): boolean => {
  const { userAgent, platform } = window.navigator;

  // Check for common tablet keywords in user agent string
  const tabletKeywords = ["iPad", "Android", "tablet"];
  const isTabletUserAgent = tabletKeywords.some((keyword) =>
    userAgent.includes(keyword),
  );

  // Check if the platform is a known tablet platform
  const tabletPlatforms = ["iPad", "Android"];
  const isTabletPlatform = tabletPlatforms.includes(platform);

  // Determine if it's likely a tablet based on user agent and platform
  return isTabletUserAgent || isTabletPlatform;
};

function App() {
  const [isTabletDevice, setIsTabletDevice] = useState<boolean | null>(null);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  // requestForToken();

  useEffect(() => {
    setIsTabletDevice(isTablet());
  }, []);
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  useEffect(() => {
    // Detect whether the user is on a mobile device
    const isMobileDevice = /Mobi/.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCommonValues());
  }, []);
  return (
    <Router>
      <div className="App">
        {isTabletDevice ? (
          <h1>Device not supported</h1>
        ) : isMobile ? (
          <h1>Device not supported</h1>
        ) : (
          <>
            <NotificationComponent />
            {accessToken && userID && <Content />}
            <AllRoutes />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
