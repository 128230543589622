import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Common } from "../../../assets/images";
import { ToastErrorComponent } from "../../Toasts";
import OfferLetterAllowanceSet from "../AddEmployee/offer-letter-allowances";

interface ExperienceProps {
  data: {
    basicSalary: number;
    fromDate: Date;
    toDate: Date;
    dutyHours: number;
    allowances: {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[];
  };
  onInputChange: (data: {
    basicSalary: number;
    fromDate: Date;
    toDate: Date;
    dutyHours: number;
    allowances: {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[];
  }) => void;
  onRemove: (key: string) => void;
  uniqueId: string;
  index: number;
  timelineData: {
    uId: string;
    basicSalary: number;
    fromDate: Date;
    toDate: Date;
    dutyHours: number;
    submittedBy: {
      empId: number;
      empName: string;
      remarks: string;
    };
  }[];
}

const SalaryTimelineSet: React.FC<ExperienceProps> = ({
  onInputChange,
  data,
  onRemove,
  index,
  uniqueId,
  timelineData,
}) => {
  const [basicSalary, setBasicSalary] = useState(data.basicSalary);
  const [fromDate, setFromDate] = useState(data.fromDate);
  const [toDate, setToDate] = useState(data.toDate);
  const [dutyHours, setDutyHours] = useState(data.dutyHours);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >(data.allowances || []);

  const handleInputChange = () => {
    onInputChange({
      basicSalary,
      fromDate,
      toDate,
      dutyHours,
      allowances: experienceData,
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [basicSalary, fromDate, toDate, dutyHours, experienceData]);

  const checkDateExist = (date: Date) => {
    let isExist = false;
    const transferOnDate = timelineData[timelineData.length - 2];
    const dateFormat = new Date(date);
    const toDate = new Date(transferOnDate.toDate);
    dateFormat.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
    if (dateFormat <= toDate) {
      isExist = true;
    }
    return isExist;
  };

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  return (
    <div className="mb-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Basic Salary
                </h4>
                <Form.Control
                  type="text"
                  name="basicSalary"
                  placeholder="Enter basic salary"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setBasicSalary(Number(e.target.value));
                    }
                  }}
                  value={basicSalary}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Duty Hours
                </h4>
                <Form.Control
                  type="text"
                  name="dutyHours"
                  placeholder="Enter duty hours"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setDutyHours(Number(e.target.value));
                    }
                  }}
                  value={dutyHours}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  From Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="fromDate"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={(e: any) => {
                      if (index === 1) {
                        setFromDate(e);
                        return;
                      }
                      if (checkDateExist(e)) {
                        window.scrollTo(0, 0);
                        setErrorContent("From date already exist in timeline");
                        setShow(true);
                        return;
                      }
                      setFromDate(e);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select from date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  To Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="toDate"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={(e: any) => setToDate(e)}
                    showMonthDropdown
                    showYearDropdown
                    minDate={fromDate}
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select to date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="12">
              <div className="text-end mt-4 d-flex align-items-center gap-4">
                <button
                  className="btn primary-button normal-font-weight"
                  type="button"
                  onClick={handleAddExperienceSet}
                  style={{ width: "15%" }}
                >
                  <img
                    src={Common.Plus}
                    width="20px"
                    height="20px"
                    className="me-2"
                    alt="next"
                  />{" "}
                  Allowances
                </button>
              </div>
            </Col>
            <Col sm="12">
              <div className="extra-detail my-4">{experienceSets}</div>
            </Col>
          </Row>
        </Col>
        <Col sm="1">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SalaryTimelineSet;
