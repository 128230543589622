import React, { useEffect, useRef } from "react";
import LetterFormatB from "./letter-format-b";
import { OfferLetterDetail } from "../../interfaces/letters";
import { Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

interface Props {
  offerData: OfferLetterDetail;
}

const LetterOffer = ({ offerData }: Props) => {
  const offerLetterRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => offerLetterRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, []);
  return (
    <div className="letter-container position-relative" ref={offerLetterRef}>
      <LetterFormatB heading="Offer Letter" data="hello">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h4
              className="sub-heading text-black mb-0 text-decoration-underline"
              style={{ fontSize: "12px" }}
            >
              Senyar Arabian Trading Co. Ltd.
            </h4>
            <ul>
              <li className="d-flex align-items-center gap-2 mb-0">
                <h4
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  P.O Box 8931 Jeddah 23345
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <h4
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  Kingdom of Saudi Arabia
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <span
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  Website:
                </span>
                <h4
                  className="sub-heading normal-font-weight text-black mb-0 ms-3"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                  }}
                >
                  www.senyarsa.com
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <span
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  E-mail:
                </span>
                <h4
                  className="sub-heading normal-font-weight text-black mb-0 ms-3"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                  }}
                >
                  hr@senyarsa.com
                </h4>
              </li>
            </ul>
          </div>
          <div>
            <div className="ref-no d-flex align-items-center gap-2 justify-content-end">
              <h4
                className="sub-heading textBlack text-uppercase mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                Date:
              </h4>
              <p
                className="sub-heading normal-font-weight text-black mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="ref-no d-flex align-items-center gap-2 justify-content-end">
              <h4
                className="sub-heading textBlack text-uppercase mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                Ref#
              </h4>
              <p
                className="sub-heading normal-font-weight text-black mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                {offerData.offerLetterRef}
              </p>
            </div>
          </div>
        </div>
        <div className="table-container-letter mb-3">
          <div className="table-content-letter">
            <Table
              responsive
              borderless
              className="text-center mb-0 emp-detail-table"
            >
              <tbody>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employee Detail:
                  </h4>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{offerData.intervieweeName}</td>
                </tr>
                <tr>
                  <td>Iqama Number</td>
                  <td>{offerData.iqamaNumber}</td>
                </tr>
                <tr>
                  <td>Border Number</td>
                  <td>{offerData.borderNumber}</td>
                </tr>
                <tr>
                  <td>Passport Number</td>
                  <td>{offerData.passportNumber}</td>
                </tr>
                <tr>
                  <td>Nationality</td>
                  <td>{offerData.nationality}</td>
                </tr>
                <tr>
                  <td>D.O.B</td>
                  <td>
                    {offerData.dateOfBirth
                      ? new Date(offerData.dateOfBirth).toLocaleDateString()
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Religion</td>
                  <td>{offerData.religion}</td>
                </tr>
                <tr>
                  <td>Home Country Number</td>
                  <td>{offerData.homeCountryContact}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="table-container-letter">
          <div className="table-content-letter">
            <Table
              responsive
              borderless
              className="text-center mb-0 emp-detail-table offer-table"
            >
              <tbody>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Offer
                  </h4>
                </tr>
                <tr>
                  <td>Position Offered</td>
                  <td>{offerData.positionOffered}</td>
                </tr>
                <tr>
                  <td>Duty Hours</td>
                  <td>{offerData.dutyHours}</td>
                </tr>
                <tr>
                  <td>Salary Period</td>
                  <td>{offerData.salaryPeriod}</td>
                </tr>
                <tr>
                  <td>Basic Salary</td>
                  <td>{offerData.basicSalary}</td>
                </tr>
                {offerData.allowances.map((item, index) => (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>
                      {item.category === "By Company"
                        ? "Provided by Company"
                        : item.amount}
                    </td>
                  </tr>
                ))}
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Other Comments & Special Instructions:
                  </h4>
                </tr>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <ul className="bg-transparent">
                    {offerData.instructions.map((item, index) => (
                      <li key={index}>{index + 1 + ". " + item.instruction}</li>
                    ))}
                  </ul>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td
                    className="sub-heading bg-transparent text-center text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employer
                  </td>
                  <td
                    className="sub-heading bg-transparent text-center text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employee
                  </td>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td style={{ height: "100px" }}>Sign:</td>
                  <td>Sign/Thumb:</td>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td>Date:</td>
                  <td>Date:</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </LetterFormatB>
    </div>
  );
};
export default LetterOffer;
