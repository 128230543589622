import React from "react";
import InternalLayout from "../components/InternalLayout";
import InsuranceComponent from "../components/Insurance";
import withAuth from "../config/private-route";
import Content from "../components/Employees/GetAPIS/get-all-types-employees-api";

const Insurance = () => {
  return (
    <InternalLayout>
      <Content />
      <InsuranceComponent />
    </InternalLayout>
  );
};
export default withAuth(Insurance);
