import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";

interface SkillsProps {
  data: {
    skillName: string;
    skillProficiency: string;
    notes: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    skillName: string;
    skillProficiency: string;
    notes: string;
  }) => void;
  uniqueId: string;
}

const SkillsSet: React.FC<SkillsProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [skillName, setSkillName] = useState(data.skillName);
  const [skillProficiency, setSkillProficiency] = useState(
    data.skillProficiency,
  );
  const [notes, setNotes] = useState(data.notes);
  const handleInputChange = () => {
    onInputChange({
      skillName,
      skillProficiency,
      notes,
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [skillName, skillProficiency, notes]);
  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Skill Name
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter skill name"
                  name="skillName"
                  onChange={(e) => setSkillName(e.target.value)}
                  value={skillName}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Skill Proficiency
                </h4>
                <Form.Select
                  name="skillProficiency"
                  onChange={(e) => setSkillProficiency(e.target.value)}
                  value={skillProficiency}
                  className="custom-input border-fill"
                >
                  <option value="">Select skill proficiency</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Note
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter notes"
                  name="notes"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => onRemove(uniqueId)}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SkillsSet;
