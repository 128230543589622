import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

const ViewUser = () => {
  const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
  return (
    <div className="add-user mt-2">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">View User</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="user-detail d-flex align-items-center gap-5">
            <div>
              <h4 className="sub-heading textBlack normal-font-weight mb-0">
                Email:
              </h4>
              <h4
                className="sub-heading"
                style={{ fontSize: "18px", fontWeight: "400" }}
              >
                {data.email ? data.email : "No Data"}
              </h4>
            </div>
            <div>
              <h4 className="sub-heading textBlack normal-font-weight mb-0">
                Designation:
              </h4>
              <h4
                className="sub-heading"
                style={{ fontSize: "18px", fontWeight: "400" }}
              >
                {data.role ? data.role : "No Data"}
              </h4>
            </div>
            <div>
              <h4 className="sub-heading textBlack normal-font-weight mb-0">
                Department:
              </h4>
              <h4
                className="sub-heading"
                style={{ fontSize: "18px", fontWeight: "400" }}
              >
                {data.department ? data.department : "No Data"}
              </h4>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="text-end">
            <button
              type="button"
              onClick={() => {
                navigate(`/settings/edit-user/${data._id}`, {state: {data: data}});
              }}
              className="btn primary-button normal-font-weight px-5"
            >
              Edit
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewUser;
