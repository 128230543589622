import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  AccountChart,
  AccountChartId,
  Billing,
  Vendor,
  VendorId,
  BillingId,
  VendorStatement,
  VendorBillFinance,
} from "../../interfaces/account-charts";

interface AccountChartList {
  data: {
    allAccounts: {
      data: AccountChart[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface VendorList {
  data: {
    vendors: {
      data: Vendor[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface BillingList {
  data: {
    bills: {
      data: Billing[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface SubAccountChartList {
  data: {
    allSubAccounts: AccountChart[];
  };
}

interface AccountChartIds {
  data: {
    allAccountIds: AccountChartId[];
  };
}

interface VendorIds {
  data: {
    vendorIds: VendorId[];
  };
}

interface BillIds {
  data: {
    billIds: BillingId[];
  };
}

interface Statements {
  data: {
    statement: VendorStatement;
  };
}

interface FinanceBillsList {
  data: {
    filteredBills: {
      data: VendorBillFinance[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const accountChartsAPI = createApi({
  reducerPath: "/api/account-charts",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getAccountChartList: builder.query<
      AccountChartList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        type: string;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-accounts/${data.type}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAccountChartIds: builder.query<
      AccountChartIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-all-accounts-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addAccountChart: builder.mutation({
      query: (data) => ({
        url: `/add-account/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editAccountChart: builder.mutation({
      query: (data) => ({
        url: `/edit-account/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteAccountChart: builder.mutation({
      query: (data) => ({
        url: `/delete-account/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getSubAccountChartList: builder.mutation<
      SubAccountChartList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-accounts-names/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Vendor
    getVendorList: builder.query<
      VendorList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-vendors/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getVendorIds: builder.query<
      VendorIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-vendor-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addVendor: builder.mutation({
      query: (data) => ({
        url: `/add-vendor/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editVendor: builder.mutation({
      query: (data) => ({
        url: `/edit-vendor/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteVendor: builder.mutation({
      query: (data) => ({
        url: `/delete-vendor/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterVendorList: builder.mutation<
      VendorList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-vendors/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Billing
    getBillingList: builder.query<
      BillingList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-bills/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getBillingIds: builder.query<
      BillIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-bill-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addBilling: builder.mutation({
      query: (data) => ({
        url: `/add-bill/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editBilling: builder.mutation({
      query: (data) => ({
        url: `/edit-bill/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteBilling: builder.mutation({
      query: (data) => ({
        url: `/delete-bill/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterBillingList: builder.mutation<
      BillingList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-bills/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getStatements: builder.query<
      Statements,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        vendorID: string;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/vendor-statement/${data.vendorID}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
    payBills: builder.mutation({
      query: (data) => ({
        url: `/pay-bills/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFinanceBillsList: builder.query<
      FinanceBillsList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
        empIds: string[];
      }
    >({
      query: (data) => ({
        url: `/get-filtered-finance-bills/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
          "search-id": `${data.empIds}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAccountChartListQuery,
  useGetAccountChartIdsQuery,
  useAddAccountChartMutation,
  useEditAccountChartMutation,
  useDeleteAccountChartMutation,
  useGetSubAccountChartListMutation,
  useGetVendorListQuery,
  useGetVendorIdsQuery,
  useAddVendorMutation,
  useEditVendorMutation,
  useDeleteVendorMutation,
  useGetFilterVendorListMutation,
  useGetBillingListQuery,
  useAddBillingMutation,
  useEditBillingMutation,
  useDeleteBillingMutation,
  useGetFilterBillingListMutation,
  useGetBillingIdsQuery,
  useGetStatementsQuery,
  usePayBillsMutation,
  useGetFinanceBillsListQuery,
} = accountChartsAPI;
