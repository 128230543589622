import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import InternalLayout from "../components/InternalLayout";
import SettingComponent from "../components/Settings";
import TabComponent from "../components/TabComponent";
import {
  ToastErrorComponent,
  ToastSuccessComponent,
} from "../components/Toasts";
// Redux Toolkit imports
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { setActiveTab, setLoading } from "../store/slices/settingsSlice";
import { useLocation } from "react-router-dom";
import { setSettingsChange } from "../store/slices/commonSlice";
// API
import { useUpdateProfileMutation } from "../services/Settings";
import { LoaderWhite } from "../components/Lotties";
import withAuth from "../config/private-route";

const convertString = (string: string) => {
  const convertedString = string
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/[^a-z0-9-]/g, "");

  return convertedString;
};

const Settings = () => {
  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);
  const [updateProfile] = useUpdateProfileMutation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathName = location.pathname.slice(10);
    const activeTab = state.tabList.find(
      (tab) => convertString(tab) === pathName,
    );
    if (activeTab) {
      dispatch(setActiveTab(activeTab));
    }
  }, [location.pathname]);

  const handleTabChange = useCallback(
    (tab: string) => {
      navigate(`/settings/${convertString(tab)}`);
    },
    [navigate],
  );

  // Handle Submit
  const handleSubmit = async () => {
    dispatch(setLoading(true));
    setShow(false);
    setShowError(false);
    const data = {
      accessToken: accessToken || "",
      userID: userID || "",
      payload: {
        phone: state.countryCode + " " + state.phone,
        country: state.country,
      },
    };

    try {
      // Initiate the mutation
      await updateProfile(data).unwrap();
      dispatch(setLoading(false));
      setShowError(false);
      setShow(true);
      dispatch(setSettingsChange(!settingsChange));
    } catch (err: any) {
      // Handle error
      if (err.data) {
        dispatch(setLoading(false));
        setShow(false);
        setShowError(true);
        setErrorContent(err.data?.message);
      }
    }
  };

  return (
    <InternalLayout>
      <TabComponent
        tabList={
          state.profileData.permissions.roleName === "Super Admin" ||
          state.profileData.permissions.roleName === "General Manager"
            ? state.tabList
            : ["User Profile"]
        }
        sectionName="Settings"
        activeTab={state.activeTab}
        handleTabChange={handleTabChange}
      >
        {state.activeTab === "User Profile" && (
          <button className="btn primary-button px-5" onClick={handleSubmit}>
            {state.loading ? <LoaderWhite height={30} width={30} /> : "Save"}
          </button>
        )}
      </TabComponent>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={show}
        setShow={setShow}
        content="Successfully Updated!"
      />
      <SettingComponent />
    </InternalLayout>
  );
};
export default withAuth(Settings);
