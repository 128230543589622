import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Vendors
import { useGetVendorListQuery } from "../../services/AccountCharts";
import {
  setVendorList,
  setVendorListLoading,
  setVendorListError,
  setVendorListTotalPages,
} from "../../store/slices/vendorsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const vendorChange = useAppSelector((state) => state.common.vendorChange);
  // States
  const state = useAppSelector((state) => state.vendors);

  // **************
  // **************
  // Get All Vendors
  // **************
  // **************
  const {
    data: allVendorss,
    isLoading: vendorListLoading,
    isError: vendorListError,
    refetch: vendorListRefetch,
  } = useGetVendorListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    pageNo: state.vendorListPageNo,
    pageSize: state.vendorListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (vendorListLoading) {
      dispatch(setVendorListLoading(true));
    }
    if (vendorListError) {
      dispatch(setVendorListError("Something went wrong"));
      dispatch(setVendorListLoading(false));
    }
    if (allVendorss) {
      dispatch(setVendorList(allVendorss.data.vendors[0].data || []));
      dispatch(
        setVendorListTotalPages(
          allVendorss.data.vendors[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setVendorListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allVendorss,
    profileData,
    state.vendorListPageNo,
    state.vendorListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    vendorListRefetch();
  }, [vendorChange]);
  return <></>;
};
export default Content;
