import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAddSalaryIncrementLoading } from "../../../store/slices/lettersSlice";
import { setSalaryIncrementChange } from "../../../store/slices/commonSlice";
// API imports
import { useAddSalaryIncrementMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";
import ApprovalsSet from "../approvals-set";
import OfferLetterAllowanceSet from "../AddEmployee/offer-letter-allowances";

const SalaryIncrement = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState("");
  const [dutyHours, setDutyHours] = React.useState("");
  const [incrementAmount, setIncrementAmount] = React.useState("");
  const [incrementFrom, setIncrementFrom] = React.useState("");
  const [newSalary, setNewSalary] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const salaryIncrementChange = useAppSelector(
    (state) => state.common.salaryIncrementChange,
  );
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  const selectEmployee = employees.find(
    (employee) => employee.empId === parseInt(empID),
  );

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >(selectEmployee ? selectEmployee.allowances : []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  useEffect(() => {
    if (selectEmployee) {
      setExperienceData(selectEmployee.allowances);
    }
  }, [empID]);

  // get all userId from languageData array
  const userIds =
    languageData.length > 0 ? languageData.map((data) => data.userId) : [];

  // API call for add salary increment
  const [addSalaryIncrement] = useAddSalaryIncrementMutation();
  const handleSubmit = async () => {
    dispatch(setAddSalaryIncrementLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: empID,
          projectId: selectEmployee?.empProjectId || "",
          incrementAmount: parseInt(incrementAmount),
          incrementFrom: incrementFrom,
          updatedSalary: parseInt(newSalary),
          dutyHours: parseInt(dutyHours),
          remarks: reason,
          link: `/hr/salary-increments`,
          approvals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...languageData,
          ],
          ids: [superadmin?._id || "", ...userIds],
          allowances: experienceData,
        },
      };
      await addSalaryIncrement(data).unwrap();
      dispatch(setSalaryIncrementChange(!salaryIncrementChange));
      navigate("/hr/salary-increments");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddSalaryIncrementLoading(false));
    }
  };

  React.useEffect(() => {
    // calculate increment amount and new salary from percentage and current basic Salary
    if (selectEmployee) {
      const currentSalary = selectEmployee.basicSalary;
      const newSalary = currentSalary + parseInt(incrementAmount);
      setNewSalary(newSalary.toString());
    }
  }, [incrementAmount, selectEmployee]);

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Salary Increment</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Select Employee by Id
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={options}
                value={empID}
                onChange={(e: any) => setEmpID(e)}
                search={true}
                placeholder="Select Employee"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Current Basic Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {selectEmployee?.basicSalary || "Select Employee"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Duty Hours
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {selectEmployee?.dutyHours || "Select Employee"}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
            <h4 className="sub-heading mb-4">Increment Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Increment Amount
                  </h4>
                  <Form.Control
                    type="number"
                    name="incrementAmount"
                    value={incrementAmount}
                    onChange={(e) => setIncrementAmount(e.target.value)}
                    placeholder="Enter increment amount"
                    className="custom-input border-fill no-spinner"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    New Salary
                  </h4>
                  <Form.Control
                    type="number"
                    name="newSalary"
                    value={newSalary}
                    onChange={(e) => setNewSalary(e.target.value)}
                    placeholder="Enter new salary"
                    disabled={true}
                    className="custom-input disabled-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Increment From
                  </h4>
                  <Form.Control
                    type="date"
                    name="incrementFrom"
                    placeholder="Enter increment from"
                    onChange={(e) => setIncrementFrom(e.target.value)}
                    value={incrementFrom}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Duty Hours
                  </h4>
                  <Form.Control
                    type="text"
                    name="dutyHours"
                    placeholder="Enter duty hours"
                    onChange={(e) => {
                      //   accept only numbers
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setDutyHours(e.target.value);
                      }
                    }}
                    value={dutyHours}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Reason
                  </h4>
                  <Form.Control
                    type="text"
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter reason"
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="button"
              onClick={handleAddExperienceSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Allowance
            </button>
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Approval user
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">
            {experienceSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Allowances
              </h4>
            )}
            {experienceSets}
          </div>
          <div className="extra-detail my-4">
            {languageSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Approval will be sent to these users:
              </h4>
            )}
            {languageSets}
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addSalaryIncrementLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SalaryIncrement;
