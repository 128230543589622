import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Dropdown, Form, Pagination, Table } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../../assets/images";
// Redux Imports
import { exportToCsv } from "../../../config";
// API imoprts
import { useReactToPrint } from "react-to-print";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import Content from "./get-credit-note-details";
import DatePicker from "react-datepicker";
import {
  setCreditNoteDetailsFromDate,
  setCreditNoteDetailsPageNo,
  setCreditNoteDetailsPageSize,
  setCreditNoteDetailsToDate,
} from "../../../store/slices/reportsSlice";
import { LoaderBlue } from "../../Lotties";

const CreditNoteDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.reports);
  const statementData = useAppSelector(
    (state) => state.reports.creditNoteDetails,
  );
  const clients = useAppSelector((state) => state.clients.clientIds);
  const returnClientName = (clientId: string) => {
    const client = clients.find((client) => client.clientId === clientId);
    return client ? client?.clientName : "";
  };
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  // Pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const currentPage = state.creditNoteDetailsPageNo;
  const pageSize = state.creditNoteDetailsPageSize;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = statementData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(state.creditNoteDetailsTotalPages / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    dispatch(setCreditNoteDetailsPageNo(currentPage + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    dispatch(setCreditNoteDetailsPageNo(currentPage - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setCreditNoteDetailsPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setCreditNoteDetailsPageSize(selectedPageSize));
  };

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Status",
      "Credit Date",
      "Credit Note No.",
      "Client Name",
      "Credit Note Amount",
      "Balance Amount",
    ],
    tableData: paginatedData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    Status: item.status,
    "Credit Date": item.creditNoteDate
      ? new Date(item.creditNoteDate).toLocaleDateString()
      : "N/A",
    "Credit Note No.": item.creditNoteNumber,
    "Client Name": returnClientName(item.clientId),
    "Credit Note Amount": item.payment,
    "Balance Amount": item.remainingAmount,
  }));

  const totalCreditAmount = statementData.reduce(
    (acc, item) => acc + item.payment,
    0,
  );

  const totalRemainingAmount = statementData.reduce(
    (acc, item) => acc + item.remainingAmount,
    0,
  );

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Content />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />{" "}
                <span
                  className="ms-2"
                  style={{ fontWeight: "600", fontSize: "20px" }}
                >
                  Back
                </span>
              </button>
              {/*<h1 className="heading mb-0">Customer Balance</h1>*/}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="d-flex align-items-center gap-3">
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    From Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="month"
                      selected={
                        state.creditNoteDetailsFromDate
                          ? new Date(state.creditNoteDetailsFromDate)
                          : null
                      }
                      onChange={(e: any) =>
                        dispatch(
                          setCreditNoteDetailsFromDate(e.toLocaleDateString()),
                        )
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select From Date"
                      className="w-100 custom-input"
                      dateFormat="MMMM"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    To Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="month"
                      selected={
                        state.creditNoteDetailsToDate
                          ? new Date(state.creditNoteDetailsToDate)
                          : null
                      }
                      onChange={(e: any) =>
                        dispatch(
                          setCreditNoteDetailsToDate(e.toLocaleDateString()),
                        )
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select To Date"
                      className="w-100 custom-input"
                      dateFormat="MMMM"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Dropdown
                  show={showExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      setShowExportDropdown(!showExportDropdown);
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={showExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "credit-note-details");
                        setShowExportDropdown(false);
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowExportDropdown(false);
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <div className="p-4" ref={tableRef}>
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <img src={Common.Vision} width="110px" alt="logo" />
              </div>
              <div className="letter-logo text-center gap-2">
                <img src={Common.LogoSVG} width="80px" alt="logo" />
                <div className="mt-1">
                  <h3
                    className="logo-text mb-0"
                    style={{
                      color: "#202020",
                      fontSize: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Senyar Arabian Trading Co. LTD
                  </h3>
                  <h3
                    className="logo-text logo-arabic mb-0"
                    style={{
                      color: "#202020",
                      fontSize: "13px",
                      fontWeight: "700",
                    }}
                  >
                    شركة سنيار العربية التجارية المحدودة
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <h1 className="sub-heading text-center mt-0">
                Credit Note Details
              </h1>
              <h1 className="sub-heading text-center mt-0">
                {new Date(state.creditNoteDetailsFromDate).toLocaleDateString()}{" "}
                - {new Date(state.creditNoteDetailsToDate).toLocaleDateString()}
              </h1>
            </div>
          </>

          <Col sm="12">
            <div className="mt-1">
              {state.creditNoteDetailsLoading ? (
                <LoaderBlue height={50} width={50} />
              ) : (
                <>
                  {tableData.tableData.length > 0 ? (
                    <Table
                      striped
                      bordered
                      responsive
                      className={
                        print ? "text-center print-table" : "text-center"
                      }
                    >
                      <thead>
                        <tr className="border-0">
                          {tableData.tableHeading.map((item, index) => (
                            <th className="border-0" key={index}>
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.tableData.map((item, index) => (
                          <tr
                            key={index}
                            style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                          >
                            <td>
                              {state.creditNoteDetailsTotalPages > 0
                                ? (currentPage - 1) * pageSize + index + 1
                                : index + 1}
                            </td>
                            <td>{item.status}</td>
                            <td>
                              {item.creditNoteDate
                                ? new Date(
                                    item.creditNoteDate,
                                  ).toLocaleDateString()
                                : "N/A"}
                            </td>
                            <td>{item.creditNoteNumber}</td>
                            <td>{returnClientName(item.clientId)}</td>
                            <td>{item.payment}</td>
                            <td>{item.remainingAmount}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5} className="text-end">
                            Total
                          </td>
                          <td>{totalCreditAmount}</td>
                          <td>{totalRemainingAmount}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  ) : (
                    <div className="text-center mt-5">
                      <h1 className="heading">No credit note details found</h1>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
          {paginatedData.length > 0 && !print && (
            <Col sm="12">
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <Form.Group
                  className="d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "14px", width: "230px" }}
                  >
                    Entries per page
                  </h4>
                  <Form.Select
                    name="pageSize"
                    onChange={handlePageSizeChange}
                    value={pageSize.toString()}
                    className="custom-input border-fill custom-height"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                    <option value="10000">All</option>
                  </Form.Select>
                </Form.Group>
                <div className="pagination-custom">
                  <Pagination className="justify-content-end">
                    <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                      <img src={Common.DoubleArrowLeft} alt="arrow" />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-left"
                        alt="arrow"
                      />
                    </Pagination.Item>

                    {pages.map((page, index) => (
                      <Pagination.Item
                        key={index}
                        onClick={() => handlePageChange(page + 1)}
                        className={
                          page + 1 === currentPage ? "active-page" : ""
                        }
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          currentPage < totalPages
                            ? currentPage + 1
                            : totalPages,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-right"
                        alt="arrow"
                      />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        handleGoToNextPages();
                      }}
                    >
                      <img src={Common.DoubleArrowRight} alt="arrow" />
                    </Pagination.Item>
                  </Pagination>
                </div>
              </div>
            </Col>
          )}
        </div>
      </Row>
    </div>
  );
};
export default CreditNoteDetails;
