import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";
import SelectSearch from "react-select-search";

interface LanguageProps {
  data: {
    userId: string;
    name: string;
    role: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: { userId: string; name: string; role: string }) => void;
  uniqueId: string;
}

const ApprovalsSet: React.FC<LanguageProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [userId, setUserId] = useState(data.userId);
  const [role, setRole] = useState(data.role);
  const [name, setName] = useState(data.name);

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const handleInputChange = () => {
    onInputChange({
      userId,
      role,
      name,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [userId, name, role]);

  const user = allUsers.find((user) => user._id === userId);

  useEffect(() => {
    if (user) {
      setRole(user.role);
      setName(user.firstName + " " + user.lastName);
    }
  }, [userId]);

  // Options
  const users = allUsers.filter(
    (user) => user.employeeId !== parseInt(profileData.employeeId),
  );
  const supervisorOptions = users.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee._id,
  }));

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="6">
              <Form.Group
                className="mb-3 text-start"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select user
                </h4>
                <Form.Group className="position-relative input-design d-flex align-items-center">
                  <SelectSearch
                    name="projectSupervisor"
                    options={supervisorOptions}
                    value={userId}
                    onChange={(value: any) => {
                      setUserId(value);
                    }}
                    search={true}
                    placeholder="Select user"
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group
                className="mb-3 text-start"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Name
                </h4>
                <Form.Control
                  type="text"
                  name="empName"
                  placeholder="Enter employee name"
                  value={name || "Select user Id"}
                  disabled={true}
                  className="custom-input disabled-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group
                className="mb-3 text-start"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Role
                </h4>
                <Form.Control
                  type="text"
                  name="empName"
                  placeholder="Enter role"
                  value={role || "Select user Id"}
                  disabled={true}
                  className="custom-input disabled-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ApprovalsSet;
