import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Dashboard
import { useGetCustomerBalanceQuery } from "../../../services/Reports";
import {
  getCustomerBalanceRequest,
  getCustomerBalanceFailure,
  getCustomerBalanceSuccess,
} from "../../../store/slices/reportsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  // State
  const state = useAppSelector((state) => state.reports);

  // **************
  // **************
  // Get Dashboard Data
  // **************
  // **************
  const {
    data: allCustomerBalance,
    isLoading: customerBalanceLoading,
    isError: customerBalanceError,
  } = useGetCustomerBalanceQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: state.customerBalancePageNo,
    pageSize: state.customerBalancePageSize,
    fromDate: new Date(state.customerBalanceFromDate).toLocaleDateString(),
    toDate: new Date(state.customerBalanceToDate).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (customerBalanceLoading) {
      dispatch(getCustomerBalanceRequest());
    }
    if (customerBalanceError) {
      dispatch(getCustomerBalanceFailure(customerBalanceError));
    }
    if (allCustomerBalance) {
      dispatch(
        getCustomerBalanceSuccess({
          data: allCustomerBalance.data.customerBalances[0].data || [],
          totalPages:
            allCustomerBalance.data.customerBalances[0].metadata[0]?.total || 0,
        }),
      );
    }
  }, [
    accessToken,
    userID,
    allCustomerBalance,
    profileData,
    state.customerBalanceFromDate,
    state.customerBalanceToDate,
    state.customerBalancePageNo,
    state.customerBalancePageSize,
  ]);
  return <></>;
};
export default Content;
