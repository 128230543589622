import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { SalaryIncrement } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterSalaryIncrementTotalPages,
  setSalaryIncrementLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredIncrementsMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setSalaryIncrements?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  salaryIncrements: SalaryIncrement[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  projectSupervisor: string;
  incrementPercentage: string;
  incrementFrom: string;
  incrementTo: string;
  incrementType: string;
  incrementStatus: string;
}

const SalaryIncrementFilterModal = ({
  show,
  setShow,
  salaryIncrements,
  setSalaryIncrements,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getAllFilteredIncrements] = useGetAllFilteredIncrementsMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setSalaryIncrementLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterSalaryIncrementPageNo,
          limit: state.filterSalaryIncrementPageSize,
          projectId: filterInput.projectId,
          projectSupervisor: filterInput.projectSupervisor,
          incrementPercentage: parseInt(filterInput.incrementPercentage),
          incrementFrom: filterInput.incrementFrom,
          incrementTo: filterInput.incrementTo,
          incrementType: filterInput.incrementType,
          incrementStatus: filterInput.incrementStatus,
        },
      };
      const response = await getAllFilteredIncrements(data).unwrap();
      if (response) {
        setSalaryIncrements(response.data.allIncrements[0].data);
        dispatch(
          setFilterSalaryIncrementTotalPages(
            response.data.allIncrements[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setSalaryIncrementLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setSalaryIncrementLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterSalaryIncrementPageNo,
            limit: state.filterSalaryIncrementPageSize,
            empId: query,
            projectId: filterInput.projectId,
            projectSupervisor: filterInput.projectSupervisor,
            incrementPercentage: parseInt(filterInput.incrementPercentage),
            incrementFrom: filterInput.incrementFrom,
            incrementTo: filterInput.incrementTo,
            incrementType: filterInput.incrementType,
            incrementStatus: filterInput.incrementStatus,
          },
        };
        const response = await getAllFilteredIncrements(data).unwrap();
        if (response) {
          setSalaryIncrements(response.data.allIncrements[0].data);
          dispatch(
            setFilterSalaryIncrementTotalPages(
              response.data.allIncrements[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setSalaryIncrementLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [
    state.filterSalaryIncrementPageNo,
    state.filterSalaryIncrementPageSize,
    query,
  ]);

  const handleReset = () => {
    setSalaryIncrements(salaryIncrements);
    setFilterInput({
      projectId: "",
      projectSupervisor: "",
      incrementPercentage: "",
      incrementFrom: "",
      incrementTo: "",
      incrementType: "",
      incrementStatus: "",
    });
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Increments Requests</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Supervisor
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectSupervisor"
                        options={supervisorOptions}
                        value={filterInput.projectSupervisor}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectSupervisor: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Supervisor"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Increment Percentage
                    </h4>
                    <Form.Control
                      type="number"
                      name="incrementPercentage"
                      value={filterInput.incrementPercentage}
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          incrementPercentage: e.target.value,
                        })
                      }
                      placeholder="Enter increment percentage"
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Increment Type
                    </h4>
                    <Form.Select
                      name="incrementType"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          incrementType: e.target.value,
                        })
                      }
                      value={filterInput.incrementType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Increment Type</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Permanent">Permanent</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Increment Status
                    </h4>
                    <Form.Select
                      name="incrementStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          incrementStatus: e.target.value,
                        })
                      }
                      value={filterInput.incrementStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Increment From
                    </h4>
                    <Form.Control
                      type="date"
                      name="incrementFrom"
                      placeholder="Enter increment from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          incrementFrom: e.target.value,
                        })
                      }
                      value={filterInput.incrementFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Increment To
                    </h4>
                    <Form.Control
                      type="date"
                      name="incrementTo"
                      placeholder="Enter increment to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          incrementTo: e.target.value,
                        })
                      }
                      value={filterInput.incrementTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SalaryIncrementFilterModal;
