import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Dashboard
import { useGetSalesQuery } from "../../../services/Reports";
import {
  getSalesRequest,
  getSalesFailure,
  getSalesSuccess,
} from "../../../store/slices/reportsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  // State
  const state = useAppSelector((state) => state.reports);

  // **************
  // **************
  // Get Dashboard Data
  // **************
  // **************
  const {
    data: allSales,
    isLoading: salesLoading,
    isError: salesError,
  } = useGetSalesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: state.salesPageNo,
    pageSize: state.salesPageSize,
    fromDate: new Date(state.salesFromDate).toLocaleDateString(),
    toDate: new Date(state.salesToDate).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (salesLoading) {
      dispatch(getSalesRequest());
    }
    if (salesError) {
      dispatch(getSalesFailure(salesError));
    }
    if (allSales) {
      dispatch(
        getSalesSuccess({
          data: allSales.data.sales[0].data || [],
          totalPages: allSales.data.sales[0].metadata[0]?.total || 0,
        }),
      );
    }
  }, [
    accessToken,
    userID,
    allSales,
    profileData,
    state.salesFromDate,
    state.salesToDate,
    state.salesPageNo,
    state.salesPageSize,
  ]);
  return <></>;
};
export default Content;
