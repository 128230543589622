import React from "react";
import InternalLayout from "../components/InternalLayout";
import HRComponent from "../components/HR";
import withAuth from "../config/private-route";

const HR = () => {
  return (
    <InternalLayout>
      <HRComponent />
    </InternalLayout>
  );
};
export default withAuth(HR);
