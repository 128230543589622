import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import CreateInvoice from "./create-invoice";
import CutomerPayment from "./customer-payment";

const TransactionContainer = () => {
  const [type, setType] = useState<string>("");
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <Col sm="12">
        <div className="section-heading mb-4">
          <h1 className="heading mb-0">Create Transaction</h1>
        </div>
      </Col>
      <Col sm="3">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Type
          </h4>
          <Form.Select
            name="type"
            onChange={(e) => setType(e.target.value)}
            value={type}
            className="custom-input border-fill"
          >
            <option value="">Select Type</option>
            <option value="Invoice">Invoice</option>
            <option value="Credit Note">Credit Note</option>
            <option value="Customer Payment">Customer Payment</option>
          </Form.Select>
        </Form.Group>
      </Col>
      {type === "Invoice" || type === "Credit Note" ? (
        <CreateInvoice type={type} />
      ) : null}
      {type === "Customer Payment" ? <CutomerPayment /> : null}
    </div>
  );
};

export default TransactionContainer;
