import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Certificate
import { useGetCertificatesQuery } from "../../services/Letters";
import {
  setCertificateLoading,
  setCertificateError,
  setCertificates,
  setCertificateTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const certificateChange = useAppSelector(
    (state) => state.common.certificateChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Certificates
  // **************
  // **************
  const {
    data: allCertificates,
    isLoading: certificatesLoading,
    isError: certificatesError,
    refetch: certificatesRefetch,
  } = useGetCertificatesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.certificatePageNo,
    limit: state.certificatePageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (certificatesLoading) {
      dispatch(setCertificateLoading(true));
    }
    if (certificatesError) {
      dispatch(setCertificateError("Something went wrong"));
      dispatch(setCertificateLoading(false));
    }
    if (allCertificates) {
      dispatch(
        setCertificates(allCertificates.data.certificates[0].data || []),
      );
      dispatch(
        setCertificateTotalPages(
          allCertificates.data.certificates[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setCertificateLoading(false));
    }
  }, [
    accessToken,
    userID,
    allCertificates,
    profileData,
    state.certificatePageNo,
    state.certificatePageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    certificatesRefetch();
  }, [certificateChange]);
  return <></>;
};
export default Content;
