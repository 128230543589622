import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import LetterFormatA from "./letter-format-a";
import { useReactToPrint } from "react-to-print";

const FineClearanceLetter = () => {
  const fineClearanceRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => fineClearanceRef.current,
  });

  const location = useLocation();
  const fineClearanceData = location.state.data;

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div className="letter-container position-relative" ref={fineClearanceRef}>
      <LetterFormatA heading="Fine Clearance Letter">
        <div className="my-2">
          <h4 className="sub-heading normal-font-weight text-black">
            Dear {fineClearanceData.empName},
          </h4>
          <p
            className="para-medium text-black mb-0"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            This is to bring to your notice the fact that based on your recent
            assessment & your manager & client feedback, we have concluded that
            your fineClearance notice period is for days from today. <br />
            <br /> Based on these <b>days</b> we will decide whether to increase
            your fineClearance period or not.
          </p>
        </div>
        <div>
          <h4 className="sub-heading text-black">Employee Detail</h4>
          <Table striped bordered responsive className="text-center">
            <thead>
              <tr>
                <th style={{ fontWeight: "600" }}>Emp Id</th>
                <th style={{ fontWeight: "600" }}>Emp Name</th>
                <th style={{ fontWeight: "600" }}>Signature</th>
                <th style={{ fontWeight: "600" }}>Thumb</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fineClearanceData.empId}</td>
                <td>{fineClearanceData.empName}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <h4 className="sub-heading normal-font-weight text-black mb-1">
          Approved By:
        </h4>
        <div className="signature-authority d-flex justify-content-between">
          <div className="authority">
            <h4 className="sub-heading text-black">Supervisor</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
          <div className="authority">
            <h4 className="sub-heading text-black">Supervisor Coordinator</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
          <div className="authority">
            <h4 className="sub-heading text-black">HR Manager</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
        </div>
      </LetterFormatA>
    </div>
  );
};

export default FineClearanceLetter;
