import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { RecruitAgency } from "../../interfaces/employee";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterRecruitAgencyTotalPages,
  setRecruitAgencyListLoading,
} from "../../store/slices/campsSlice";
// API imports
import { useGetFilterRecruitAgencyListMutation } from "../../services/Camps";

interface Props {
  show?: boolean;
  setShow?: any;
  setRecruitAgencies?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  recruitAgencies: RecruitAgency[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  licenseNumber: string;
}

const RecruitAgencyFilterModal = ({
  show,
  setShow,
  recruitAgencies,
  setRecruitAgencies,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.camps);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterRecruitAgencyList] = useGetFilterRecruitAgencyListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setRecruitAgencyListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterRecruitAgencyPageNo,
          limit: state.filterRecruitAgencyPageSize,
          licenseNumber: filterInput.licenseNumber,
        },
      };
      const response = await getFilterRecruitAgencyList(data).unwrap();
      if (response) {
        setRecruitAgencies(response.data.recruiters[0].data);
        dispatch(
          setFilterRecruitAgencyTotalPages(
            response.data.recruiters[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setRecruitAgencyListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setRecruitAgencyListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterRecruitAgencyPageNo,
            limit: state.filterRecruitAgencyPageSize,
            recruiterId: query,
            licenseNumber: filterInput.licenseNumber,
          },
        };
        const response = await getFilterRecruitAgencyList(data).unwrap();
        if (response) {
          setRecruitAgencies(response.data.recruiters[0].data);
          dispatch(
            setFilterRecruitAgencyTotalPages(
              response.data.recruiters[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setRecruitAgencyListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [
    state.filterRecruitAgencyPageNo,
    state.filterRecruitAgencyPageSize,
    query,
  ]);

  const handleReset = () => {
    setRecruitAgencies(recruitAgencies);
    setFilterInput({
      licenseNumber: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Recruit Agencies</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      License Number
                    </h4>
                    <Form.Control
                      type="text"
                      name="licenseNumber"
                      placeholder="Enter license number"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          licenseNumber: e.target.value,
                        })
                      }
                      value={filterInput.licenseNumber}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecruitAgencyFilterModal;
