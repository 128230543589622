import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ToastErrorComponent } from "../Toasts";
import { setAddOfferLetterLoading } from "../../store/slices/lettersSlice";
import { setOfferLetterChange } from "../../store/slices/commonSlice";
import { useAddOfferLetterMutation } from "../../services/Letters";
import OfferLetterAllowanceSet from "../Employees/AddEmployee/offer-letter-allowances";
import OfferLetterInstructions from "../Employees/AddEmployee/instructions";
import { Common } from "../../assets/images";
import { OfferLetterDetail } from "../../interfaces/letters";
import { LoaderWhite } from "../Lotties";

const AddInterviewer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const offerData: OfferLetterDetail = location.state.data;
  const [show, setShow] = React.useState(false);
  const [basicSalary, setBasicSalary] = useState(
    offerData?.basicSalary?.toString() || "",
  );
  const [positionOffered, setPositionOffered] = useState(
    offerData.positionOffered || "",
  );
  const [dutyHours, setDutyHours] = useState(
    offerData?.dutyHours?.toString() || "",
  );
  const [salaryPeriod, setSalaryPeriod] = useState(
    offerData.salaryPeriod || "",
  );
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const settings = useAppSelector((state) => state.settings);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const offerLetterChange = useAppSelector(
    (state) => state.common.offerLetterChange,
  );

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >(offerData.allowances || []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  useEffect(() => {
    offerData.allowances = experienceData;
  }, [experienceData]);

  // Skills Sets
  const [skillsSets, setSkillsSets] = useState<React.ReactNode[]>([]);
  const [skillsData, setSkillsData] = useState<
    {
      uId: string;
      instruction: string;
    }[]
  >(
    offerData.instructions.length > 0
      ? offerData.instructions
      : [
          {
            uId: Math.random().toString(36).substring(7),
            instruction: "Salary will be calculated on based of working hours",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction: "Overtime will have considered One equal to One (1=1)",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "First Month Salary will be security with the company.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "Indiscipline will not be tolerated and will lead to termination of contract without any pay.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "If food is provided, food allowance will not be provided.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "10% salary will be increased automatically on end of second year to onward.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "End of service benefit will be provided to employee as per Saudi labor law.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "Passport will be kept with company as a security of worker.",
          },
          {
            uId: Math.random().toString(36).substring(7),
            instruction:
              "We can transfer to any other division without negotiation.",
          },
        ],
  );

  const handleAddSkillsSet = () => {
    setSkillsData([
      ...skillsData,
      {
        uId: Math.random().toString(36).substring(7),
        instruction: "",
      },
    ]);
  };

  const handleRemoveSkillsSet = (index: string) => {
    const updatedInputData = skillsData.filter((item, i) => item.uId !== index);
    setSkillsData(updatedInputData);
  };

  const handleSkillsInputChange = (
    index: string,
    data: {
      instruction: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = skillsData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setSkillsData(updatedInput);
  };

  useEffect(() => {
    const updatedSkillsSets = skillsData.map((data, index) => (
      <OfferLetterInstructions
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveSkillsSet(key)}
        onInputChange={(inputData) =>
          handleSkillsInputChange(data.uId, inputData)
        }
      />
    ));
    setSkillsSets(updatedSkillsSets);
  }, [skillsData]);

  useEffect(() => {
    offerData.instructions = skillsData;
  }, [skillsData]);

  // API call for add salary increment
  const [addOfferLetter] = useAddOfferLetterMutation();
  const handleSubmit = async () => {
    if (basicSalary === "") {
      setErrorContent("Please enter basic salary");
      setShow(true);
      return;
    }
    if (dutyHours === "") {
      setErrorContent("Please enter duty hours");
      setShow(true);
      return;
    }
    dispatch(setAddOfferLetterLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          intervieweeId: offerData.intervieweeId,
          positionOffered: positionOffered,
          offerLetterRef:
            offerData.offerLetterStatus === "Not Issued"
              ? ""
              : offerData.offerLetterRef,
          dutyHours: parseInt(dutyHours),
          salaryPeriod: salaryPeriod,
          basicSalary: parseInt(basicSalary),
          allowances: experienceData,
          instructions: skillsData,
          link: `/initial-evaluation`,
        },
      };
      await addOfferLetter(data).unwrap();
      dispatch(setOfferLetterChange(!offerLetterChange));
      navigate(`/initial-evaluation`);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddOfferLetterLoading(false));
    }
  };

  // get rolename from setting.roles as array of string
  const roles = settings.roles.map((item) => item.roleName);

  const categoriesList = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Other",
  ];

  const categories = [...roles, ...categoriesList];

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Create Offer</h1>
          </div>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Position Offered
            </h4>
            <Form.Select
              name="role"
              onChange={(e) => setPositionOffered(e.target.value)}
              value={positionOffered}
              className="custom-input border-fill"
            >
              <option value="">Select Position</option>
              {categories.map((item: string, index: number) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Duty Hours
            </h4>
            <Form.Control
              type="text"
              name="dutyHours"
              placeholder="Enter duty hours"
              onChange={(e) => {
                //     accept only numbers
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setDutyHours(e.target.value);
                }
              }}
              value={dutyHours}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Salary Period
            </h4>
            <Form.Select
              name="salaryPeriod"
              onChange={(e) => setSalaryPeriod(e.target.value)}
              value={salaryPeriod}
              className="custom-input border-fill"
            >
              <option value="">Select Salary Period</option>
              <option value="Hourly">Hourly</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Basic Salary (SAR)
            </h4>
            <Form.Control
              type="text"
              name="basicSalary"
              placeholder="Enter basic salary"
              onChange={(e) => {
                //     accept only numbers
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setBasicSalary(e.target.value);
                }
              }}
              value={basicSalary}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>

        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddExperienceSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Allowance
            </button>
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddSkillsSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Instruction
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">
            {experienceSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Allowances
              </h4>
            )}
            {experienceSets}
            {skillsSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Instructions
              </h4>
            )}
            {skillsSets}
          </div>
          <div className="text-end">
            <button
              className="btn primary-button w-25 mb-5"
              onClick={handleSubmit}
            >
              {state.addOfferLetterLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : offerData.offerLetterStatus === "Not Issued" ? (
                "Create Offer Letter"
              ) : (
                "Edit Offer Letter"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddInterviewer;
