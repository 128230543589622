import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters

import { useGetAllEmployeesQuery } from "../../../services/Employees";
import {
  setEmpLoading,
  setEmployees,
  setEmpResponseError,
  setTotalRecords,
} from "../../../store/slices/employeeSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const projectChange = useAppSelector((state) => state.common.projectChange);

  // States
  const employeeState = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Employees
  // **************
  // **************
  const {
    data: allEmployees,
    isLoading: employeeLoading,
    isError: employeeError,
    refetch: employeeRefetch,
  } = useGetAllEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    pageNo: employeeState.pageNo,
    pageSize: employeeState.pageSize,
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (employeeLoading) {
      dispatch(setEmpLoading(true));
    }
    if (employeeError) {
      dispatch(setEmpResponseError("Something went wrong"));
      dispatch(setEmpLoading(false));
    }
    if (allEmployees) {
      dispatch(setEmployees(allEmployees.data.employees[0].data || []));
      dispatch(
        setTotalRecords(allEmployees.data.employees[0].metadata[0]?.total || 0),
      );
      dispatch(setEmpLoading(false));
    }
  }, [
    accessToken,
    userID,
    allEmployees,
    profileData,
    employeeState.pageNo,
    employeeState.pageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    employeeRefetch();
  }, [employeeChange, projectChange]);
  return <></>;
};
export default Content;
