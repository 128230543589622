import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { Invoice } from "../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterInvoiceTotalPages,
  setInvoiceListLoading,
} from "../../store/slices/clientsSlice";
// API imports
import { useGetFilterInvoiceListMutation } from "../../services/Clients";

interface Props {
  show?: boolean;
  setShow?: any;
  setInvoices?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  invoices: Invoice[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  invoiceType: string;
  status: string;
  fromDate: string;
  toDate: string;
}

const InvoicesFilterModal = ({
  show,
  setShow,
  invoices,
  setInvoices,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.clients);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterInvoiceList] = useGetFilterInvoiceListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setInvoiceListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterInvoicePageNo,
          limit: state.filterInvoicePageSize,
          clientId: state.clientIdNumber,
          invoiceType: filterInput.invoiceType,
          status: filterInput.status,
          fromDate: filterInput.fromDate,
          toDate: filterInput.toDate,
        },
      };
      const response = await getFilterInvoiceList(data).unwrap();
      if (response) {
        setInvoices(response.data.invoices[0].data);
        dispatch(
          setFilterInvoiceTotalPages(
            response.data.invoices[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setInvoiceListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setInvoiceListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterInvoicePageNo,
            limit: state.filterInvoicePageSize,
            clientId: state.clientIdNumber,
            invoiceId: query,
            invoiceType: filterInput.invoiceType,
            status: filterInput.status,
            fromDate: filterInput.fromDate,
            toDate: filterInput.toDate,
          },
        };
        const response = await getFilterInvoiceList(data).unwrap();
        if (response) {
          setInvoices(response.data.invoices[0].data);
          dispatch(
            setFilterInvoiceTotalPages(
              response.data.invoices[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setInvoiceListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterInvoicePageNo, state.filterInvoicePageSize, query]);

  const handleReset = () => {
    setInvoices(invoices);
    setFilterInput({
      invoiceType: "",
      status: "",
      fromDate: "",
      toDate: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Invoice List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Invoice Type
                    </h4>
                    <Form.Select
                      name="invoiceType"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          invoiceType: e.target.value,
                        })
                      }
                      value={filterInput.invoiceType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Invoice Type</option>
                      <option value="Invoice">Invoice</option>
                      <option value="Credit Note">Credit Note</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Status
                    </h4>
                    <Form.Select
                      name="status"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          status: e.target.value,
                        })
                      }
                      value={filterInput.status}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Un paid">Un paid</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      placeholder="From date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          fromDate: e.target.value,
                        })
                      }
                      value={filterInput.fromDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="toDate"
                      placeholder="To date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          toDate: e.target.value,
                        })
                      }
                      value={filterInput.toDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoicesFilterModal;
