import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Common } from "../../../assets/images";

interface ExperienceProps {
  data: {
    region: string;
    basicSalary: string;
    experienceProficiency: string;
    startDate: string;
    endDate: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    region: string;
    basicSalary: string;
    experienceProficiency: string;
    startDate: string;
    endDate: string;
  }) => void;
  uniqueId: string;
}

const ExperienceSet: React.FC<ExperienceProps> = ({
  onRemove,
  onInputChange,
  uniqueId,
  data,
}) => {
  const [region, setRegion] = useState(data.region);
  const [basicSalary, setBasicSalary] = useState(data.basicSalary);
  const [experienceProficiency, setExperienceProficiency] = useState(
    data.experienceProficiency,
  );
  const [startDate, setStartDate] = useState(data.startDate);
  const [endDate, setEndDate] = useState(data.endDate);
  const handleInputChange = () => {
    onInputChange({
      region,
      basicSalary,
      experienceProficiency,
      startDate,
      endDate,
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [region, basicSalary, experienceProficiency, startDate, endDate]);
  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Region
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter region"
                  name="region"
                  onChange={(e) => setRegion(e.target.value)}
                  value={region}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Basic Salary
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter basic salary"
                  name="basicSalary"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setBasicSalary(e.target.value);
                    }
                  }}
                  value={basicSalary}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Experience Proficiency
                </h4>
                <Form.Select
                  name="experienceProficiency"
                  onChange={(e) => setExperienceProficiency(e.target.value)}
                  value={experienceProficiency}
                  className="custom-input border-fill"
                >
                  <option value="">Select experience proficiency</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mt-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Start Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="startDate"
                    selected={startDate ? new Date(startDate) : null}
                    onChange={(e: any) => setStartDate(e)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select start date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mt-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  End Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="endDate"
                    selected={endDate ? new Date(endDate) : null}
                    onChange={(e: any) => setEndDate(e)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select end date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => onRemove(uniqueId)}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExperienceSet;
