import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BasicInfo from "./basic-info";
import LegalInfo from "./legal-info";
import OtherDetails from "./other-details";
import EvaluationDetail from "./evaluation-detail";
import AgreementDetail from "./agreement-detail";
import Attachments from "./attachments";
import AttendanceLog from "./attendance-log";
import Timeline from "./timeline";
import SalaryTimeline from "./salary-timeline";
import StatementList from "./statement";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  setDetailActiveTab,
  setDetailPageAgreementDetails,
  setDetailPageBasicInfo,
  setDetailPageEvaluationDetails,
  setDetailPageLegalInfo,
  setDetailPageOtherDetails,
  setDetailPageAttachments,
  setDetailPageAttendanceLog,
  setDetailPageTimeline,
  setEmpLoading,
  setDetailPageIncrementTimeline,
  setDetailPageStatement,
  setDetailPagePayslips,
} from "../../../store/slices/employeeSlice";
import TabComponent from "../../TabComponent";
// API Imports
import { useGetEmployeeDetailMutation } from "../../../services/Employees";
import { LoaderBlue } from "../../Lotties";
import { EmpDetail } from "../../../interfaces/employee";
import Payslips from "./payslips";

export const initialDetail = {
  empId: null,
  empProjectId: "",
  empSupervisorName: "",
  empImage: "",
  empName: "",
  empCategory: "",
  empPlace: "",
  empNationality: "",
  empReligion: "",
  empCompanyName: "",
  empPhone: "",
  empStatus: "",
  empDob: "",
  campDetails: {
    campId: "",
    campName: "",
    campCity: "",
  },
  empDepartment: "",
  empHomeCountryNumber: "",
  empBankDetails: [],
  empIqamaNo: "",
  workPermitExpiry: "",
  empIqamaExpiry: "",
  empProfessionOnIqama: "",
  empSponsorName: "",
  empBorderNo: "",
  empIqamaPrinted: "",
  iqamaHandOverToEmployee: "",
  empPassportWithCompany: "",
  empPassportNo: "",
  empPassportExpiry: "",
  empVisaNo: "",
  dateOfEmpInSA: "",
  empVisaExpiry: "",
  empIncrementDate: "",
  empWorking: "",
  empProjectJoiningDate: "",
  empProjectCategory: "",
  empProjectPrice: 0,
  empProjectWorkingHours: 0,
  overTime: 0,
  overTimePrice: 0,
  transferTimeline: [],
  salaryTimeline: [],
  empIqamaStatus: "",
  empInsuranceCost: "",
  empInsuranceStatus: "",
  insuranceHistory: [],
  empIdNo: "",
  recruiterDetails: {
    recruiterId: "",
    recruiterName: "",
    companyName: "",
  },
  empInsuranceCompany: "",
  empInsuranceExpiry: "",
  basicSalary: 0,
  dutyHours: 0,
  salaryPeriod: "",
  attendances: [],
  empTotalSalary: null,
  evaluationDetail: {
    obedient: "",
    behavior: "",
    appearance: "",
    remarks: "",
  },
  language: [],
  experience: [],
  skills: [],
  agreement: {
    issueDate: "",
    expiryDate: "",
    attachment: "",
    status: "",
  },
  attachments: [],
  allowances: [],
};

const EmployeeDetailContainer = () => {
  const dispatch = useAppDispatch();
  const [empDetail, setEmpDetail] = useState<EmpDetail>(initialDetail);
  const [logDate, setLogDate] = useState(new Date().toLocaleDateString());
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const navigate = useNavigate();
  const location = useLocation();
  const { empNo } = useParams();
  // Handle Tab change
  const handleTabChange = (tab: string) => {
    dispatch(setDetailActiveTab(tab));
    if (tab === "Basic Information") {
      dispatch(setDetailPageBasicInfo(true));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Legal Details") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(true));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Other Details") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(true));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Evaluation Details") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(true));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Agreement Details") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(true));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Attachments") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(true));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Attendance Log") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(true));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Projects Timeline") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(true));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Salary Timeline") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(true));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Payslips") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(true));
      dispatch(setDetailPageStatement(false));
    }
    if (tab === "Statement") {
      dispatch(setDetailPageBasicInfo(false));
      dispatch(setDetailPageLegalInfo(false));
      dispatch(setDetailPageOtherDetails(false));
      dispatch(setDetailPageEvaluationDetails(false));
      dispatch(setDetailPageAgreementDetails(false));
      dispatch(setDetailPageAttachments(false));
      dispatch(setDetailPageAttendanceLog(false));
      dispatch(setDetailPageTimeline(false));
      dispatch(setDetailPageIncrementTimeline(false));
      dispatch(setDetailPagePayslips(false));
      dispatch(setDetailPageStatement(true));
    }
  };

  const [getEmployeeDetail] = useGetEmployeeDetailMutation();

  const getDetail = async () => {
    dispatch(setEmpLoading(true));
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData?.permissions.showEmployee || false,
      payload: {
        empId: empNo ? parseInt(empNo) : "",
        date: new Date(logDate).toLocaleDateString(),
      },
    };
    try {
      const result = await getEmployeeDetail(data).unwrap();
      if (result) {
        dispatch(setEmpLoading(false));
        setEmpDetail(result.data.employees[0]);
      }
    } catch (e) {
      dispatch(setEmpLoading(false));
    }
  };
  React.useEffect(() => {
    getDetail();
  }, [accessToken, userID, employeeChange, profileData, logDate]);

  return (
    <>
      <TabComponent
        tabList={state.detailTabList}
        activeTab={state.detailActiveTab}
        sectionName="Employee Detail"
        handleTabChange={handleTabChange}
      >
        <button
          className="btn primary-button px-5"
          onClick={() =>
            navigate(`/employees/edit-employee/${location?.state?.empId}`, {
              state: { empId: location?.state?.empId },
            })
          }
        >
          Edit
        </button>
      </TabComponent>

      <div className="add-employee section px-4 py-4 mt-4">
        {state.empLoading ? (
          <LoaderBlue height={50} width={50} />
        ) : (
          <Row>
            <Col sm="12">
              <div className="employee-form mt-4">
                {state.detailPageBasicInfo && (
                  <BasicInfo empDetail={empDetail} />
                )}
                {state.detailPageLegalInfo && (
                  <LegalInfo empDetail={empDetail} />
                )}
                {state.detailPageOtherDetails && (
                  <OtherDetails empDetail={empDetail} />
                )}
                {state.detailPageEvaluationDetails && (
                  <EvaluationDetail empDetail={empDetail} />
                )}
                {state.detailPageAgreementDetails && (
                  <AgreementDetail empDetail={empDetail} />
                )}
                {state.detailPageAttachments && (
                  <Attachments empDetail={empDetail} />
                )}
                {state.detailPageAttendanceLog && (
                  <AttendanceLog
                    empDetail={empDetail}
                    logDate={logDate}
                    setLogDate={setLogDate}
                  />
                )}
                {state.detailPageTimeline && <Timeline empDetail={empDetail} />}
                {state.detailPageIncrementTimeline && (
                  <SalaryTimeline empDetail={empDetail} />
                )}
                {state.detailPagePayslips && <Payslips empDetail={empDetail} />}
                {state.detailPageStatement && (
                  <StatementList empDetail={empDetail} />
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default EmployeeDetailContainer;
