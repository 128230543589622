import React, { useRef, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailActiveTab,
  setDetailPageIncrementTimeline,
  setDetailPagePayslips,
  setDetailPageTimeline,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";
import { useReactToPrint } from "react-to-print";

interface Props {
  empDetail: EmpDetail;
}

const SalaryTimeline = ({ empDetail }: Props) => {
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  // get last doucumnet of salary timeline
  const lastSalary =
    empDetail.salaryTimeline[empDetail.salaryTimeline.length - 1];

  const lastSalaryDate =
    empDetail.salaryTimeline.length > 0
      ? new Date(lastSalary.toDate)
      : new Date();
  lastSalaryDate.setDate(lastSalaryDate.getDate() + 1);

  const currentSalary =
    empDetail.empStatus === "Absconded" || empDetail.empStatus === "Final Exit"
      ? []
      : [
          {
            _id: "0",
            uId: "0",
            basicSalary: empDetail.basicSalary,
            fromDate:
              empDetail.salaryTimeline.length > 0
                ? lastSalaryDate
                : new Date(empDetail.empIncrementDate),
            toDate: new Date(),
            dutyHours: empDetail.dutyHours,
            allowances: empDetail.allowances,
            submittedBy: {
              empId: 0,
              empName: "",
              remarks: "Current Salary",
            },
          },
        ];

  const mergedSalary = empDetail.salaryTimeline.concat(currentSalary);

  return (
    <Row>
      <Col sm="3">
        <div className="mb-4">
          <button
            className="btn primary-button"
            onClick={handlePrinted}
            type="button"
          >
            Print Salary Timeline
          </button>
        </div>
      </Col>
      {mergedSalary.length > 0 ? (
        <>
          <div className="p-3" ref={tableRef}>
            {print && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <img src={Common.Vision} width="110px" alt="logo" />
                  </div>
                  <div className="letter-logo text-center gap-2">
                    <img src={Common.LogoSVG} width="80px" alt="logo" />
                    <div className="mt-1">
                      <h3
                        className="logo-text mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Senyar Arabian Trading Co. LTD
                      </h3>
                      <h3
                        className="logo-text logo-arabic mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "13px",
                          fontWeight: "700",
                        }}
                      >
                        شركة سنيار العربية التجارية المحدودة
                      </h3>
                    </div>
                  </div>
                </div>
                <h1 className="sub-heading text-center mt-3">
                  Salary Timeline of Employee ID: {empDetail.empId} -{" "}
                  {empDetail.empName}
                </h1>
              </>
            )}
            <Table
              striped
              bordered
              responsive
              className={print ? "text-center print-table" : "text-center"}
            >
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Basic Salary</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Duty Hours</th>
                  <th>No. of Allowances</th>
                  <th>Allowances Amount</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {mergedSalary.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.basicSalary}</td>
                    <td>
                      {item.fromDate
                        ? new Date(item.fromDate).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>
                      {item.toDate
                        ? new Date(item.toDate).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{item.dutyHours}</td>
                    <td>{item?.allowances?.length}</td>
                    <td>
                      {item?.allowances?.reduce(
                        (acc, curr) => acc + curr.amount,
                        0,
                      )}
                    </td>
                    <td>{item.submittedBy.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">No Salary Timeline</h1>
        </div>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Projects Timeline"));
              dispatch(setDetailPageTimeline(true));
              dispatch(setDetailPageIncrementTimeline(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Payslips"));
              dispatch(setDetailPagePayslips(true));
              dispatch(setDetailPageIncrementTimeline(false));
            }}
            style={{ width: "15%" }}
          >
            Next
            <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default SalaryTimeline;
