import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

const ViewRole = () => {
  const state = useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  const location = useLocation();

  const data = location?.state?.data;

  return (
    <div className="add-user mt-2">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">View Role</h1>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col md="12">
              <div className="mb-5">
                <h4 className="sub-heading normal-font-weight textBlack">
                  Role Name:
                </h4>
                <h4 className="sub-heading normal-font-weight">
                  {data.roleName}
                </h4>
              </div>
            </Col>
            <Col md="12">
              <div className="grant-permissions">
                <h3 className="sub-heading mb-4">Grant Permissions</h3>
                <div className="permission-list">
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        User Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add User"
                          name="userManagement.write"
                          checked={data.addUser}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update User"
                          name="userManagement.edit"
                          checked={data.updateUser}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete User"
                          name="userManagement.delete"
                          checked={data.deleteUser}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show User"
                          name="userManagement.read"
                          checked={data.showUser}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Employee Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add Employee"
                          name="employeeManagement.write"
                          checked={data.addEmployee}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update Employee"
                          name="employeeManagement.edit"
                          checked={data.updateEmployee}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete Employee"
                          name="employeeManagement.delete"
                          checked={data.deleteEmployee}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show Employee"
                          name="employeeManagement.read"
                          checked={data.showEmployee}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Project Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add Project"
                          name="projectManagement.write"
                          checked={data.addProject}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update Project"
                          name="projectManagement.edit"
                          checked={data.updateProject}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete Project"
                          name="projectManagement.delete"
                          checked={data.deleteProject}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show Project"
                          name="projectManagement.read"
                          checked={data.showProject}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Attendance Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add Attendance"
                          name="attendanceManagement.write"
                          checked={data.addAttendance}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update Attendance"
                          name="attendanceManagement.edit"
                          checked={data.updateAttendance}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete Attendance"
                          name="attendanceManagement.delete"
                          checked={data.deleteAttendance}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show Attendance"
                          name="attendanceManagement.read"
                          checked={data.showAttendance}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Legal Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add Legal"
                          name="attendanceManagement.write"
                          checked={data.addLegal}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update Legal"
                          name="attendanceManagement.edit"
                          checked={data.updateLegal}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete Legal"
                          name="attendanceManagement.delete"
                          checked={data.deleteLegal}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show Legal"
                          name="attendanceManagement.read"
                          checked={data.showLegal}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="permission-item mb-4">
                    <div className="permission-item-heading">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Petty Cash Management
                      </h4>
                    </div>
                    <div className="permission-item-body d-flex align-items-center gap-5">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Add PettyCash"
                          name="attendanceManagement.write"
                          checked={data.addPettyCash}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Update PettyCash"
                          name="attendanceManagement.edit"
                          checked={data.updatePettyCash}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Delete PettyCash"
                          name="attendanceManagement.delete"
                          checked={data.deletePettyCash}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Show PettyCash"
                          name="attendanceManagement.read"
                          checked={data.showPettyCash}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className="text-end">
                <button
                  type="submit"
                  onClick={() =>
                    navigate(`/settings/edit-role/${data.roleName}`, {
                      state: { data: data },
                    })
                  }
                  className="btn primary-button normal-font-weight"
                >
                  Edit Role
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default ViewRole;
