import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Common } from "../../../assets/images";
import { useAppSelector } from "../../../store/hooks";
import SelectSearch from "react-select-search";
import { ca } from "date-fns/locale";
import { ToastErrorComponent } from "../../Toasts";

interface ExperienceProps {
  data: {
    projectId: string;
    projectName: string;
    projectSupervisor: string;
    fromDate: Date;
    toDate: Date;
    attendendDays: number;
    category: string;
    workingHours: number;
    price: number;
    overTime: number;
    overTimePrice: number;
    trasferType: string;
  };
  index: number;
  onInputChange: (data: {
    projectId: string;
    projectName: string;
    projectSupervisor: string;
    fromDate: Date;
    toDate: Date;
    attendendDays: number;
    category: string;
    workingHours: number;
    price: number;
    overTime: number;
    overTimePrice: number;
    trasferType: string;
  }) => void;
  onRemove: (key: string) => void;
  timelineData: {
    uId: string;
    projectId: string;
    projectName: string;
    projectSupervisor: string;
    fromDate: Date;
    toDate: Date;
    attendendDays: number;
    trasferType: string;
    category: string;
    workingHours: number;
    price: number;
    overTime: number;
    overTimePrice: number;
    submittedBy: {
      empId: number;
      empName: string;
      remarks: string;
    };
  }[];
  uniqueId: string;
  nationality: string;
}

const ProjectTimelineSet: React.FC<ExperienceProps> = ({
  onInputChange,
  index,
  data,
  uniqueId,
  onRemove,
  nationality,
  timelineData,
}) => {
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [projectId, setProjectId] = useState(data.projectId);
  const project = projectIds.find((item) => item.projectId === projectId);
  const [projectName, setProjectName] = useState(data.projectName);
  const [projectSupervisor, setProjectSupervisor] = useState(
    data.projectSupervisor,
  );
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [fromDate, setFromDate] = useState(data.fromDate);
  const [toDate, setToDate] = useState(data.toDate);
  const [attendendDays, setAttendendDays] = useState(data.attendendDays);
  const [category, setCategory] = useState(data.category);

  const [workingHours, setWorkingHours] = useState(data.workingHours);
  const [price, setPrice] = useState(data.price);
  const [overTime, setOverTime] = useState(data.overTime);
  const [overTimePrice, setOverTimePrice] = useState(data.overTimePrice);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const [trasferType, setTrasferType] = useState(data.trasferType);
  // Options
  const campOptions = campIds.map((item) => ({
    name: item.campId + " - " + item.campName,
    value: item.campId,
  }));
  const campSelected = campIds.find((item) => item.campId === projectId);
  const handleInputChange = () => {
    onInputChange({
      projectId,
      projectName:
        trasferType === "Transfer To Camp"
          ? campSelected
            ? campSelected.campName
            : ""
          : projectName,
      projectSupervisor:
        trasferType === "Transfer To Camp"
          ? campSelected
            ? campSelected.campSupervisor
            : ""
          : projectSupervisor,
      fromDate,
      toDate,
      attendendDays,
      category: trasferType === "Transfer To Camp" ? "" : category,
      workingHours: trasferType === "Transfer To Camp" ? 0 : workingHours,
      price: trasferType === "Transfer To Camp" ? 0 : price,
      overTime: trasferType === "Transfer To Camp" ? 0 : overTime,
      overTimePrice: trasferType === "Transfer To Camp" ? 0 : overTimePrice,
      trasferType,
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [
    projectId,
    projectName,
    projectSupervisor,
    fromDate,
    toDate,
    category,
    attendendDays,
    workingHours,
    price,
    overTime,
    overTimePrice,
    trasferType,
  ]);

  useEffect(() => {
    if (project) {
      setProjectName(project.projectName);
      setProjectSupervisor(project.projectSupervisor);
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      const selectedCategory = project.employeeRequired.find(
        (item) => item.category === category,
      );
      if (selectedCategory) {
        setWorkingHours(selectedCategory.workingHours);
        setPrice(selectedCategory.price);
        setOverTime(selectedCategory.overTime);
        setOverTimePrice(selectedCategory.overTimePrice);
      }
    }
  }, [project, category]);

  useEffect(() => {
    //     set attendend days
    if (fromDate && toDate) {
      const diffTime = Math.abs(
        new Date(toDate).getTime() - new Date(fromDate).getTime(),
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setAttendendDays(diffDays);
    }
  }, [fromDate, toDate]);

  const projectOptions = projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  const categories = project?.employeeRequired.filter(
    (item) => item.nationality === "" || item.nationality === nationality,
  );

  const options =
    trasferType === "Transfer To Camp" ? campOptions : projectOptions;

  // check from date exist in timeline from from date to to date
  const checkDateExist = (date: Date) => {
    let isExist = false;
    const transferOnDate = timelineData[timelineData.length - 2];
    const dateFormat = new Date(date);
    const toDate = new Date(transferOnDate.toDate);
    dateFormat.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
    if (dateFormat <= toDate) {
      isExist = true;
    }
    return isExist;
  };

  return (
    <div className="mb-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="2">
              <Form.Group
                className="d-flex align-items-center gap-4"
                controlId="formBasicEmail"
              >
                <h4 className="sub-heading">{index}.</h4>
                <div className="w-100">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Type
                  </h4>
                  <Form.Select
                    name="trasferType"
                    onChange={(e) => {
                      setTrasferType(e.target.value);
                    }}
                    value={trasferType}
                    className="custom-input border-fill"
                  >
                    <option value="">Select type</option>
                    <option value="Transfer">Transfer To Project</option>
                    <option value="Transfer To Camp">Transfer To Camp</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Project/Camp ID
                </h4>
                <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                  <SelectSearch
                    name="emp"
                    options={options}
                    value={projectId}
                    onChange={(value: any) => setProjectId(value)}
                    search={true}
                    placeholder="Select Project/Camp Id"
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  value={category}
                  className="custom-input border-fill"
                >
                  <option value="">Select category</option>
                  {project &&
                    categories?.map((item) => (
                      <option key={item.category} value={item.category}>
                        {item.category + " - " + item.nationality}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  From Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="fromDate"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={(e: any) => {
                      if (index === 1) {
                        setFromDate(e);
                        return;
                      }
                      if (checkDateExist(e)) {
                        window.scrollTo(0, 0);
                        setErrorContent("From date already exist in timeline");
                        setShow(true);
                        return;
                      }
                      setFromDate(e);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select from date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  To Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="toDate"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={(e: any) => {
                      setToDate(e);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    minDate={fromDate}
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select to date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="1">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectTimelineSet;
