import React from "react";
import InternalLayout from "../components/InternalLayout";
import AttendanceComponent from "../components/Attendance";
import withAuth from "../config/private-route";

const Attendance = () => {
  return (
    <InternalLayout>
      <AttendanceComponent />
    </InternalLayout>
  );
};
export default withAuth(Attendance);
