import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarFooter,
} from "cdbreact";
import { Common, SidebarIcons } from "../../assets/images";
import AccordionItem from "./accordion-item";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarItems = [
    {
      id: 1,
      icon1: SidebarIcons.Home,
      icon2: SidebarIcons.HomeBlue,
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 2,
      icon1: SidebarIcons.ClientWhite,
      icon2: SidebarIcons.ClientBlue,
      name: "Employee",
      list: [
        {
          id: 1,
          name: "All Employees",
          link: "/employees",
        },
        {
          id: 8,
          name: "Active Employees",
          link: "/employees/active-employees",
        },
        {
          id: 4,
          name: "Probation Employees",
          link: "/employees/probation-employees",
        },
        {
          id: 5,
          name: "Absconded Employees",
          link: "/employees/absconded-employees",
        },

        {
          id: 6,
          name: "Final Exit Employees",
          link: "/employees/final-exit-employees",
        },

        {
          id: 7,
          name: "Terminated Employees",
          link: "/employees/terminated-employees",
        },
        {
          id: 3,
          name: "All Candidates",
          link: "/initial-evaluation",
        },
        {
          id: 2,
          name: "Behaviour Statements",
          link: "/employees/employee-behaviour-statement",
        },
      ],
    },

    {
      id: 6,
      icon1: SidebarIcons.CardsWhite,
      icon2: SidebarIcons.CardsBlue,
      name: "Legal",
      list: [
        {
          id: 1,
          name: "Legal",
          link: "/legal",
        },
        {
          id: 2,
          name: "Iqama",
          link: "/iqama",
        },
        {
          id: 3,
          name: "Insurance",
          link: "/insurance",
        },
        {
          id: 4,
          name: "All Recruiters",
          link: "/recruit-agency/recruiters",
        },
        {
          id: 5,
          name: "Visa",
          link: "/recruit-agency/visa",
        },
      ],
    },
    {
      id: 12,
      icon1: SidebarIcons.HRWhite,
      icon2: SidebarIcons.HRBlue,
      name: "HR",
      list: [
        {
          id: 1,
          name: "Companies",
          link: "/companies",
        },
        {
          id: 3,
          name: "Create Payrolls",
          link: "/employees-payroll/create-payrolls",
        },
        {
          id: 4,
          name: "Violations / Fines",
          link: "/hr/expenses",
        },
        {
          id: 5,
          name: "Leave Requests",
          link: "/hr/leave-requests",
        },
        {
          id: 6,
          name: "Salary Increments",
          link: "/hr/salary-increments",
        },
        {
          id: 7,
          name: "Certificates",
          link: "/hr/certificates",
        },
      ],
    },
    {
      id: 5,
      icon1: SidebarIcons.CampWhite,
      icon2: SidebarIcons.CampBlue,
      name: "Camps",
      link: "/camps",
    },

    {
      id: 7,
      icon1: SidebarIcons.ClientWhite,
      icon2: SidebarIcons.ClientBlue,
      name: "Clients",
      list: [
        {
          id: 2,
          name: "Clients",
          link: "/clients",
        },
        {
          id: 1,
          name: "All Projects",
          link: "/projects/",
        },
        {
          id: 3,
          name: "Transfer / New joiners",
          link: "/projects/transfer-employee-requests",
        },
        {
          id: 4,
          name: "Client Reviews",
          link: "/projects/client-reviews",
        },
      ],
    },
    // {
    //   id: 8,
    //   icon1: SidebarIcons.Attedance,
    //   icon2: SidebarIcons.AttendanceBlue,
    //   name: "Attendance",
    //   list: [],
    // },
    {
      id: 13,
      icon1: SidebarIcons.Project,
      icon2: SidebarIcons.ProjectBlue,
      name: "Accounts",
      list: [
        {
          id: 4,
          name: "Create Transaction",
          link: "/create-transaction",
        },
        {
          id: 1,
          name: "Manual Journal",
          link: "/manual-journal",
        },
        {
          id: 2,
          name: "Chart of Accounts",
          link: "/account-charts",
        },
        {
          id: 3,
          name: "Vendors",
          link: "/vendors",
        },
        {
          id: 5,
          name: "Billing",
          link: "/billing",
        },
      ],
    },
    {
      id: 15,
      icon1: SidebarIcons.Iqama,
      icon2: SidebarIcons.IqamaBlue,
      name: "Finance",
      list: [
        {
          id: 1,
          name: "Payrolls Due",
          link: "/employees-payroll/payslips",
        },
        {
          id: 2,
          name: "Bills Payable",
          link: "/billing/bills-payable",
        },
      ],
    },
    {
      id: 9,
      icon1: SidebarIcons.PettyCash,
      icon2: SidebarIcons.PettyCashBlue,
      name: "Petty Cash",
      link: "/petty-cash/ledger",
    },
    {
      id: 10,
      icon1: SidebarIcons.TimeSheetWhite,
      icon2: SidebarIcons.TimeSheetBlue,
      name: "Timesheets",
      list: [
        {
          id: 5,
          name: "Attendance Form",
          link: "/attendance/attendance-form",
        },
        {
          id: 6,
          name: "Attendance Log",
          link: "/attendance/attendance-log",
        },
        {
          id: 1,
          name: "Projected Salarysheet",
          link: "/timesheet/salarysheet",
        },
        {
          id: 2,
          name: "Actual Salarysheet",
          link: "/timesheet/actual-salarysheet",
        },
        {
          id: 3,
          name: "Projected Revenue Sheet",
          link: "/timesheet/revenue-sheet",
        },
        {
          id: 4,
          name: "Actual Revenue Sheet",
          link: "/timesheet/actual-revenuesheet",
        },
      ],
    },
    {
      id: 16,
      icon1: SidebarIcons.Attedance,
      icon2: SidebarIcons.AttendanceBlue,
      name: "Reports",
      link: "/reports",
    },
  ];

  // Active item
  const [activeItem, setActiveItem] = useState("");
  const [settingsActive, setSettingsActive] = useState(false);

  useEffect(() => {
    const pathName = location.pathname;
    const activeTab = sidebarItems.find((item) => item?.link === pathName);
    if (activeTab) {
      setActiveItem(activeTab.name);
    }
  }, [location.pathname]);

  const handleActiveSettings = () => {
    navigate("/settings/user-profile");
    setActiveItem("");
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <CDBSidebar
      className="my-sidebar"
      textColor="white"
      backgroundColor="#001A35"
      breakpoint={0}
      toggled={false}
      minWidth="120px"
      maxWidth="300px"
    >
      {/*Sidebar header*/}
      <CDBSidebarHeader>
        <img
          className="mx-auto d-flex justify-content-center"
          width="80px"
          height="70px"
          src={Common.Logo}
          alt="logo"
        />
        <img
          src={SidebarIcons.Line}
          width="100%"
          height="3px"
          className="my-3"
          alt="line"
        />
      </CDBSidebarHeader>
      <CDBSidebarContent className="sidebar-content">
        <CDBSidebarMenu className="pt-2 px-4">
          {sidebarItems.map((item, index) => (
            <div key={item.id}>
              {item.link ? (
                <Link
                  to={item.link}
                  className={`sidebar-item p-0 mb-4 ${
                    item.name === activeItem ? "sidebar-item-active" : ""
                  }`}
                >
                  {item.name === activeItem ? (
                    <img
                      src={item.icon2}
                      width="26px"
                      height="26px"
                      className="ms-3 me-2"
                      alt="dashboard"
                    />
                  ) : (
                    <img
                      src={item.icon1}
                      width="26px"
                      height="26px"
                      className="me-2"
                      alt="dashboard"
                    />
                  )}
                  {item.name}
                </Link>
              ) : (
                <Accordion>
                  <AccordionItem
                    eventKey={`${index}`}
                    title={item.name}
                    collapsedImage={item.icon1}
                    image={item.icon2}
                    width="26px"
                    height="26px"
                    className="mb-4"
                  >
                    {item.list ? (
                      <>
                        {item.list.map((list) => (
                          <div key={list.id}>
                            {list.link ? (
                              <Link
                                to={list.link}
                                className={`item-content mb-1 ms-2`}
                                style={{ fontSize: "14px" }}
                              >
                                <img
                                  src={SidebarIcons.Circle}
                                  width="8px"
                                  height="8px"
                                  className="me-2"
                                  alt="circle"
                                />{" "}
                                {list.name}
                              </Link>
                            ) : // <Accordion className="sub-dropdown">
                            //   <AccordionItem
                            //     eventKey="1"
                            //     title="Actions"
                            //     collapsedImage={SidebarIcons.Circle}
                            //     image={SidebarIcons.Circle}
                            //     width="8px"
                            //     height="8px"
                            //     className="ms-2"
                            //   >
                            //     {list.list ? (
                            //       <>
                            //         {list?.list.map((listItem: any) => (
                            //           <Link
                            //             to={listItem.link}
                            //             className={`item-content mb-1`}
                            //             style={{ fontSize: "13px" }}
                            //             key={listItem.id}
                            //           >
                            //             <img
                            //               src={SidebarIcons.Rectangle}
                            //               width="8px"
                            //               height="8px"
                            //               className="me-1"
                            //               alt="circle"
                            //             />{" "}
                            //             {listItem.name}
                            //           </Link>
                            //         ))}
                            //       </>
                            //     ) : null}
                            //   </AccordionItem>
                            // </Accordion>
                            //)
                            null}
                          </div>
                        ))}
                      </>
                    ) : null}
                  </AccordionItem>
                </Accordion>
              )}
            </div>
          ))}
        </CDBSidebarMenu>
      </CDBSidebarContent>

      <CDBSidebarFooter className="mt-auto">
        <div className="sidebar-btn-wrapper mb-4">
          <CDBSidebarMenu className="px-4">
            <button
              onClick={() => {
                handleActiveSettings();
              }}
              className={`btn sidebar-item  w-100 p-0 py-2 mb-4 ${
                "/settings/user-profile" === location.pathname
                  ? "sidebar-item-active"
                  : ""
              }`}
            >
              {"/settings/user-profile" === location.pathname ? (
                <img
                  src={SidebarIcons.SettingsBlue}
                  width="26px"
                  height="26px"
                  className="ms-3 me-2"
                  alt="dashboard"
                />
              ) : (
                <img
                  src={SidebarIcons.Settings}
                  width="26px"
                  height="26px"
                  className="me-2"
                  alt="dashboard"
                />
              )}
              Settings
            </button>
            <button
              className={`sidebar-item-toggled sidebar-item btn sidebar-item-active w-100 mt-4`}
              onClick={handleLogout}
            >
              <img
                src={SidebarIcons.Logout}
                className="me-2"
                width="26px"
                height="26px"
                alt="logout"
              />
              Logout
            </button>
          </CDBSidebarMenu>
        </div>
      </CDBSidebarFooter>
    </CDBSidebar>
  );
};

export default Sidebar;
