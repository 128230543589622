import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common, LoginIcons } from "../../../assets/images";

const WorkRefusal = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const options = [
    { name: "JDSFJ34567654", value: "JDSFJ34567654" },
    { name: "ADSFJ34567653", value: "ADSFJ34567653" },
  ];
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading ">
              <h1 className="heading mb-0">Employee Work Refusal</h1>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <div className="ref-no d-flex align-items-center gap-2">
                  <h4 className="sub-heading normal-font-weight textBlack mb-0">
                    Reference No.
                  </h4>
                  <p
                    className="sub-heading normal-font-weight mb-0"
                    style={{ fontSize: "16px", color: "#29406C" }}
                  >
                    2837823
                  </p>
                </div>
                <div className="ref-no d-flex align-items-center gap-2">
                  <h4 className="sub-heading normal-font-weight textBlack mb-0">
                    Date:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight mb-0"
                    style={{ fontSize: "16px", color: "#29406C" }}
                  >
                    23, July 2022
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="4">
            <div className="search-emp">
              <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
                Employee Name
              </h4>
              <Form.Group className="position-relative input-design d-flex align-items-center">
                <SelectSearch
                  options={options}
                  value="JDSFJ34567654"
                  search={true}
                  placeholder="Select Employee"
                />
                <img src={Common.ArrowDown} alt="message" />
              </Form.Group>
            </div>
          </Col>
          <Col sm="12">
            <div className="emp-detail mt-5">
              <h4 className="sub-heading mb-4">Employee Detail</h4>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      JDSFJ34567654
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Ali Asif
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Status:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Probation
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Current Project:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      Zain Telecom
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="emp-detail mt-4">
              <h4 className="sub-heading mb-4">Work Refusal Detail</h4>
              <Row>
                <Col sm="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Types of Voilations:
                    </h4>
                    <Form.Select
                      name="voilationType"
                      className="custom-input border-fill"
                    >
                      <option value="">Select Voilation Type</option>
                      <option value="1">Attendance</option>
                      <option value="2">Failure to follow instructions</option>
                      <option value="3">Violation of safety rules</option>
                      <option value="4">Carelessness</option>
                      <option value="5">Working on personal matters</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Remarks:
              </h4>
              <textarea
                name="remarks"
                placeholder="Leave a comment here..."
                className="border-fill w-100"
                rows={6}
                style={{ resize: "none" }}
              />
            </Form.Group>
          </Col>
          <Col sm="12">
            <div className="emp-detail-item mt-4">
              <h4 className="sub-heading mb-4">Approval Detail</h4>
              <Row>
                <Col sm="4">
                  <Form.Group>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor{" "}
                      <img
                        src={Common.SuccessTickUnbordered}
                        className="ms-1"
                        alt="success"
                      />
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        value="JDSFJ34567654"
                        search={true}
                        placeholder="Approved"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                    <p className="para-medium textBlack mt-1">
                      09, August 2023
                    </p>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor Coordinator
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        value="JDSFJ34567654"
                        search={true}
                        placeholder="Pending"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      HR Manager
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        value="JDSFJ34567654"
                        search={true}
                        placeholder="Pending"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="emp-detail-print text-end mt-5">
              <button
                className="btn primary-button w-25 me-3"
                onClick={handlePrint}
              >
                Print
              </button>
              <button className="btn primary-button w-25">Save</button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default WorkRefusal;
