import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Common, NotificationsIcons } from "../../assets/images";
import TabComponent from "../TabComponent";
// Redux import
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setApprovalPageSize } from "../../store/slices/notificationsSlice";
import { setApprovalChange } from "../../store/slices/commonSlice";
// API imports
import {
  useAeApprovalMutation,
  useScApprovalMutation,
  useAfoApprovalMutation,
  useApprovalMutation,
} from "../../services/Notifications";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";

const ApprovalsList = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.notification);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const approvalChange = useAppSelector((state) => state.common.approvalChange);
  const [statusAcceptLoadingMap, setStatusAcceptLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const [statusRejectLoadingMap, setStatusRejectLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const profileData = useAppSelector((state) => state.settings.profileData);
  // AE Approval
  const [aeApproval] = useAeApprovalMutation();
  //   SC Approval
  const [scApproval] = useScApprovalMutation();
  //   AFO Approval
  const [afoApproval] = useAfoApprovalMutation();

  // const approval =
  //   profileData.permissions.roleName === "Accounts Executive"
  //     ? aeApproval
  //     : profileData.permissions.roleName === "Admin Finance Officer"
  //       ? afoApproval
  //       : scApproval;

  const [approval] = useApprovalMutation();

  const handleApproval = async (
    empId: string,
    status: string,
    link: string,
  ) => {
    status === "Rejected"
      ? setStatusRejectLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }))
      : setStatusAcceptLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: true,
        payload: {
          notificationId: empId,
          status: status,
          link: link,
        },
      };
      await approval(data).unwrap();
      dispatch(setApprovalChange(!approvalChange));
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      status === "Rejected"
        ? setStatusRejectLoadingMap((prevMap) => ({
            ...prevMap,
            [empId]: false,
          }))
        : setStatusAcceptLoadingMap((prevMap) => ({
            ...prevMap,
            [empId]: false,
          }));
    }
  };
  return (
    <div className="notifications mt-2">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Container fluid>
        <Row>
          <Col
            xxl="8"
            xl="10"
            lg="12"
            md="12"
            sm="12"
            className="mx-auto section"
          >
            <div className="notification-card">
              <div className="notification-list mt-4">
                <ul>
                  {state.approvalsLoading ? (
                    <LoaderBlue height={60} width={60} />
                  ) : (
                    <>
                      {state.approvals.length > 0 ? (
                        <>
                          {state.approvals.map((item, index) => (
                            <li key={index}>
                              <div
                                className={
                                  item.status === "Un-read"
                                    ? "notification-summary-list-item unread-notification d-flex"
                                    : "notification-summary-list-item d-flex"
                                }
                              >
                                <div className="notification-summary-list-item-content">
                                  <Link to={item.link}>
                                    <h5
                                      className="mb-0 text-decoration-none"
                                      style={{
                                        width: "fit-content",
                                        color: "#5E657B",
                                      }}
                                    >
                                      {item.title}
                                    </h5>
                                  </Link>
                                  <Link to={`#fdmdf`}>
                                    <p className="service-name mb-0 text-decoration-none">
                                      {item.message}
                                    </p>
                                  </Link>
                                </div>
                                <div className="notification-time text-end ms-auto">
                                  <div className="d-flex justify-content-end mb-2">
                                    <p className="mt-1 mb-0 text-end text-capitalize">
                                      {item.updatedAt}
                                    </p>
                                  </div>
                                  {item.status === "Un-read" ? (
                                    <div className="mb-1 d-flex gap-2 align-items-end justify-content-end">
                                      <button
                                        className="btn primary-button"
                                        onClick={() =>
                                          handleApproval(
                                            item._id,
                                            "Approved",
                                            item.link,
                                          )
                                        }
                                        style={{ backgroundColor: "#39b359" }}
                                      >
                                        {statusAcceptLoadingMap[item._id] ? (
                                          <LoaderWhite height={30} width={30} />
                                        ) : (
                                          "Approve"
                                        )}
                                      </button>
                                      <button
                                        className="btn primary-button"
                                        onClick={() =>
                                          handleApproval(
                                            item._id,
                                            "Rejected",
                                            item.link,
                                          )
                                        }
                                        style={{ backgroundColor: "#ff2b43" }}
                                      >
                                        {statusRejectLoadingMap[item._id] ? (
                                          <LoaderWhite height={30} width={30} />
                                        ) : (
                                          "Reject"
                                        )}
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="mb-1 d-flex gap-2 align-items-end justify-content-end">
                                      <div
                                        className="status-text"
                                        style={{
                                          color:
                                            item.status === "Approved"
                                              ? "#39b359"
                                              : "#ff2b43",
                                        }}
                                      >
                                        {item.status}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <hr />
                            </li>
                          ))}
                          {state.totalApprovals > state.approvalPageSize && (
                            <div className="text-center mt-2">
                              <button
                                className="btn p-0 border-0 sub-heading text-decoration-underline"
                                onClick={() =>
                                  dispatch(
                                    setApprovalPageSize(
                                      state.approvalPageSize + 10,
                                    ),
                                  )
                                }
                              >
                                See more approvals
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="text-center mt-5">
                          <h1 className="heading">No Approvals Found</h1>
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApprovalsList;
