import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import EditBasicInfo from "./edit-basic-info";
import EditLegalInfo from "./edit-legal-info";
import EditOtherDetails from "./edit-other-details";
import EditEvaluationDetail from "./edit-evaluation-detail";
import EditAgreementDetail from "./agreement-detail";
import EditAttachments from "./attachments";
import EditProjectTimeline from "./edit-project-timeline";
import EditSalaryTimeline from "./edit-salary-timeline";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  setActiveTab,
  setCountryCode,
  setEditBasicInfo,
  setEditEvaluationDetails,
  setEditLegalInfo,
  setEditOtherDetails,
  setEditAgreementDetails,
  setEditAttachments,
  setEmpLoading,
  setEditTimeline,
  setEditIncrementTimeline,
} from "../../../store/slices/employeeSlice";
import TabComponent from "../../TabComponent";
// API Imports
import {
  useGetEmployeeDetailMutation,
  useEditEmployeeMutation,
} from "../../../services/Employees";
import { initialDetail } from "../EmployeeDetail/employee-detail-container";
import { EmpDetail } from "../../../interfaces/employee";
import { LoaderBlue } from "../../Lotties";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddEmployeeProps,
  initialValues,
} from "../AddEmployee/add-employee-container";
import { ToastErrorComponent, ToastSuccessComponent } from "../../Toasts";
import { setEmployeeChange } from "../../../store/slices/commonSlice";

const EditEmployeeContainer = () => {
  const dispatch = useAppDispatch();
  const [empDetail, setEmpDetail] = useState<EmpDetail>(initialDetail);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.employee);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const navigate = useNavigate();
  const location = useLocation();
  const { empNo } = useParams();
  // Handle Tab change
  const handleTabChange = (tab: string) => {
    dispatch(setActiveTab(tab));
    if (tab === "Basic Information") {
      dispatch(setEditBasicInfo(true));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Legal Details") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(true));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Other Details") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(true));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Evaluation Details") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(true));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Agreement Details") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(true));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Attachments") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(true));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Projects Timeline") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(true));
      dispatch(setEditIncrementTimeline(false));
    }
    if (tab === "Salary Timeline") {
      dispatch(setEditBasicInfo(false));
      dispatch(setEditLegalInfo(false));
      dispatch(setEditOtherDetails(false));
      dispatch(setEditEvaluationDetails(false));
      dispatch(setEditAgreementDetails(false));
      dispatch(setEditAttachments(false));
      dispatch(setEditTimeline(false));
      dispatch(setEditIncrementTimeline(true));
    }
  };
  // Emp Detail
  const [getEmployeeDetail] = useGetEmployeeDetailMutation();

  const getDetail = async () => {
    dispatch(setEmpLoading(true));
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData?.permissions.showEmployee || false,
      payload: {
        empId: empNo ? parseInt(empNo) : "",
      },
    };
    try {
      const result = await getEmployeeDetail(data).unwrap();
      if (result) {
        setEmpDetail(result.data.employees[0]);
        dispatch(setEmpLoading(false));
        dispatch(
          setCountryCode(result.data.employees[0].empPhone?.split(" ")[0]),
        );
      }
    } catch (e) {
      dispatch(setEmpLoading(false));
    }
  };

  // Initial Values
  const initialValuesEdit: AddEmployeeProps = loadingData
    ? initialValues
    : {
        profileImage: empDetail.empImage,
        empCode: empDetail.empId ? empDetail.empId.toString() : "",
        empName: empDetail.empName,
        category: empDetail.empCategory,
        place: empDetail.empPlace,
        nationality: empDetail.empNationality,
        religion: empDetail.empReligion,
        mobileNo: empDetail.empPhone ? empDetail.empPhone?.split(" ")[1] : "",
        dob: empDetail.empDob,
        empBankDetails: empDetail.empBankDetails,
        iqamaNo: empDetail.empIqamaNo,
        companyName: empDetail.empCompanyName,
        iqamaExpiry: empDetail.empIqamaExpiry,
        professionOnIqama: empDetail.empProfessionOnIqama,
        sponsorName: empDetail.empSponsorName,
        borderNo: empDetail.empBorderNo,
        department: empDetail.empDepartment,
        workPermitExpiry: empDetail.workPermitExpiry,
        homeCountryNumber: empDetail.empHomeCountryNumber,
        iqamaPrinted: empDetail.empIqamaPrinted,
        handOverToEmployee: empDetail.iqamaHandOverToEmployee,
        passportWithCompany: empDetail.empPassportWithCompany,
        passportNo: empDetail.empPassportNo,
        passportExpiry: empDetail.empPassportExpiry,
        dutyHours: empDetail.dutyHours?.toString() || "",
        campDetails: {
          campId: empDetail.campDetails?.campId,
          campName: empDetail.campDetails?.campName,
          campCity: empDetail.campDetails?.campCity,
        },
        otherCategory:
          empDetail.empCategory === "Other" ? empDetail.empCategory : "",
        salaryPeriod: empDetail.salaryPeriod,
        visaNo: empDetail.empVisaNo,
        empInsuranceCost: empDetail.empInsuranceCost,
        empInsuranceAttachment:
          empDetail.insuranceHistory.length > 0
            ? empDetail.insuranceHistory[empDetail.insuranceHistory.length - 1]
                .attachemnt
            : "",
        dateOfEmpInSA: empDetail.dateOfEmpInSA,
        visaExpiry: empDetail.empVisaExpiry,
        idNo: empDetail.empIdNo,
        recruiterDetails: {
          recruiterId: empDetail?.recruiterDetails?.recruiterId || "",
          recruiterName: empDetail?.recruiterDetails?.recruiterName || "",
          companyName: empDetail?.recruiterDetails?.companyName || "",
        },
        insuranceCompany: empDetail.empInsuranceCompany,
        insuranceExpiry: empDetail.empInsuranceExpiry,
        basicSalary: empDetail.basicSalary
          ? empDetail.basicSalary?.toString()
          : "",
        agreement: {
          issueDate: empDetail.agreement.issueDate || "",
          expiryDate: empDetail.agreement.expiryDate || "",
          attachment: empDetail.agreement.attachment || "",
        },
        allowances: empDetail.allowances ? empDetail.allowances : [],
        attachment: empDetail.attachments ? empDetail.attachments : [],
        evaluationDetail: {
          obedient: empDetail?.evaluationDetail?.obedient || "",
          behavior: empDetail?.evaluationDetail?.behavior || "",
          appearance: empDetail?.evaluationDetail?.appearance || "",
          remarks: empDetail?.evaluationDetail?.remarks || "",
        },
        language: empDetail.language ? empDetail.language : [],
        experience: empDetail.experience ? empDetail.experience : [],
        skills: empDetail.skills ? empDetail.skills : [],
      };
  React.useEffect(() => {
    const fetchData = async () => {
      await getDetail();
      setLoadingData(false);
    };

    fetchData();
  }, [accessToken, userID, profileData]);
  // Form Submit Function
  const [editEmployee] = useEditEmployeeMutation();
  const handleSubmit = async (values: AddEmployeeProps) => {
    const camp = campIds.find(
      (camp) => camp.campId === values.campDetails.campId,
    );
    // Check if there are any Yup errors
    const isValid = await validationSchema.isValid(values);

    if (!isValid) {
      setErrorContent("Please fill all the required fields");
      setShow(true);
      return;
    }
    dispatch(setEmpLoading(true));
    const data = {
      accessToken: accessToken || "",
      userID: userID || "",
      permission: profileData.permissions.updateEmployee || false,
      payload: {
        empId: location?.state?.empId || "",
        link: `/employees`,
        empImage: values.profileImage,
        empName: values.empName,
        empCategory:
          values.category === "Other" ? values.otherCategory : values.category,
        empPlace: values.place,
        empNationality: values.nationality,
        empReligion: values.religion,
        empPhone: state.countryCode + " " + values.mobileNo,
        empDob: values.dob,
        empBankDetails: values.empBankDetails,
        empIqamaNo: values.iqamaNo,
        empIqamaExpiry: values.iqamaExpiry,
        empProfessionOnIqama: values.professionOnIqama,
        empSponsorName: values.sponsorName,
        empBorderNo: values.borderNo,
        workPermitExpiry: values.workPermitExpiry,
        empDepartment: values.department,
        empHomeCountryNumber: values.homeCountryNumber,
        campDetails: {
          campId: values.campDetails.campId
            ? values.campDetails.campId.includes("CMP")
              ? values.campDetails.campId
              : ""
            : "",
          campName: values.campDetails.campId
            ? values.campDetails.campId.includes("CMP")
              ? camp?.campName
              : values.campDetails.campId
            : "",
          campCity: values.campDetails.campId
            ? values.campDetails.campId.includes("CMP")
              ? camp?.campCity
              : ""
            : "",
        },
        dutyHours: values.dutyHours,
        salaryPeriod: values.salaryPeriod,
        empIqamaPrinted: values.iqamaPrinted,
        iqamaHandOverToEmployee: values.handOverToEmployee,
        empPassportWithCompany: values.passportWithCompany,
        empPassportNo: values.passportNo,
        empPassportExpiry: values.passportExpiry,
        empInsuranceCost: parseInt(values.empInsuranceCost),
        insuranceHistory: {
          updatedOn: new Date(),
          cost: parseInt(values.empInsuranceCost),
          updatedBy: profileData.employeeId,
          updaterName: profileData.firstName + " " + profileData.lastName,
          remarks: "",
          attachemnt: values.empInsuranceAttachment,
        },
        empVisaNo: values.visaNo,
        dateOfEmpInSA: values.dateOfEmpInSA,
        empVisaExpiry: values.visaExpiry,
        empIdNo: values.idNo,
        recruiterDetails: {
          recruiterId: values.recruiterDetails.recruiterId,
          recruiterName: values.recruiterDetails.recruiterName,
          companyName: values.recruiterDetails.companyName,
        },
        empInsuranceCompany: values.insuranceCompany,
        empInsuranceExpiry: values.insuranceExpiry,
        evaluationDetail: {
          obedient: values.evaluationDetail.obedient,
          behavior: values.evaluationDetail.behavior,
          appearance: values.evaluationDetail.appearance,
          remarks: values.evaluationDetail.remarks,
        },
        language: values.language,
        experience: values.experience,
        skills: values.skills,
        agreement: {
          issueDate: values.agreement.issueDate,
          expiryDate: values.agreement.expiryDate,
          attachment: values.agreement.attachment,
        },
        attachments: values.attachment,
      },
    };
    try {
      await editEmployee(data).unwrap();
      dispatch(setEmployeeChange(!employeeChange));
      dispatch(setEmpLoading(false));
      window.scrollTo(0, 0);
      setSuccess(true);
    } catch (e: any) {
      if (e) {
        // Scroll to top
        window.scrollTo(0, 0);
        dispatch(setEmpLoading(false));
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    }
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({});
  return (
    <>
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={success}
        setShow={setSuccess}
        content="Updated Successfully!"
      />
      <TabComponent
        tabList={state.tabList}
        activeTab={state.activeTab}
        sectionName="Edit Employee"
        handleTabChange={handleTabChange}
      ></TabComponent>

      <div className="add-employee section px-4 py-4 mt-4">
        {loadingData ? (
          <LoaderBlue height={50} width={50} />
        ) : (
          <Row>
            <Col sm="12">
              <div className="employee-form mt-4">
                <Formik
                  initialValues={initialValuesEdit}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {state.editBasicInfo ? (
                        <EditBasicInfo
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          touched={touched}
                          handleBlur={handleBlur}
                        />
                      ) : state.editLegalInfo ? (
                        <EditLegalInfo
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          errors={errors}
                          touched={touched}
                        />
                      ) : state.editOtherDetails ? (
                        <EditOtherDetails
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          errors={errors}
                          touched={touched}
                        />
                      ) : state.editEvaluationDetails ? (
                        <EditEvaluationDetail
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          errors={errors}
                          touched={touched}
                        />
                      ) : state.editAgreementDetails ? (
                        <EditAgreementDetail
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          errors={errors}
                          touched={touched}
                        />
                      ) : state.editAttachments ? (
                        <EditAttachments
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShow={setShow}
                          setErrorContent={setErrorContent}
                          errors={errors}
                          touched={touched}
                        />
                      ) : null}
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
            <Col sm="12">
              {state.editTimeline && (
                <EditProjectTimeline
                  employeeID={empDetail.empId}
                  empDetails={empDetail}
                  timelineData={empDetail.transferTimeline}
                />
              )}
              {state.editIncrementTimeline && (
                <EditSalaryTimeline
                  employeeID={empDetail.empId}
                  timelineData={empDetail.salaryTimeline}
                />
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default EditEmployeeContainer;
