import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import { Project } from "../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterTotalRecords,
  setProjectsLoading,
} from "../../store/slices/projectSlice";
import { useGetFilterProjectsMutation } from "../../services/Projects";

interface Props {
  show?: boolean;
  setShow?: any;
  setProjects?: any;
  setFilterSearch: any;
  filterSearch: boolean;
  projects: Project[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  supervisor: string;
  location: string;
  status: string;
  fromEmployees: number | null;
  toEmployees: number | null;
}

const ProjectFilterModal = ({
  show,
  setShow,
  projects,
  setProjects,
  setFilterSearch,
  filterSearch,
  query,
  filterInput,
  setFilterInput,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [getFilterProjects] = useGetFilterProjectsMutation();
  // Handle Submit Function to filter employees based on the form filterInput
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setProjectsLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterPageNo,
          limit: state.filterPageSize,
          supervisor: filterInput.supervisor,
          location: filterInput.location,
          status: filterInput.status,
          fromEmployees: filterInput.fromEmployees,
          toEmployees: filterInput.toEmployees,
        },
      };
      const response = await getFilterProjects(data).unwrap();
      if (response) {
        setProjects(response.data.projects[0].data);
        dispatch(
          setFilterTotalRecords(response.data.projects[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setProjectsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setProjectsLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterPageNo,
            limit: state.filterPageSize,
            projectId: query,
            supervisor: filterInput.supervisor,
            location: filterInput.location,
            status: filterInput.status,
            fromEmployees: filterInput.fromEmployees,
            toEmployees: filterInput.toEmployees,
          },
        };
        const response = await getFilterProjects(data).unwrap();
        if (response) {
          setProjects(response.data.projects[0].data);
          dispatch(
            setFilterTotalRecords(response.data.projects[0].metadata[0].total),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setProjectsLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterPageNo, state.filterPageSize, query]);

  const handleReset = () => {
    setProjects(projects);
    setFilterInput({
      status: "",
      sponsorName: "",
      professionOnIqama: "",
      fromDate: "",
      toDate: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Project List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Status
                    </h4>
                    <Form.Select
                      name="status"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          status: e.target.value,
                        })
                      }
                      value={filterInput.status}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="In progress">In progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Closed">Closed</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Supervisor
                    </h4>
                    <Form.Control
                      type="text"
                      name="supervisor"
                      placeholder="Project Supervisor"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          supervisor: e.target.value,
                        })
                      }
                      value={filterInput.supervisor}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Location
                    </h4>
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Project Location"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          location: e.target.value,
                        })
                      }
                      value={filterInput.location}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No. of Employees from
                    </h4>
                    <Form.Control
                      type="number"
                      name="fromEmployees"
                      placeholder="Enter no. of employees from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          fromEmployees: e.target.value,
                        })
                      }
                      value={filterInput.fromEmployees || undefined}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No. of Employees to
                    </h4>
                    <Form.Control
                      type="number"
                      name="toEmployees"
                      placeholder="Enter no. of employees to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          toEmployees: e.target.value,
                        })
                      }
                      value={filterInput.toEmployees || undefined}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectFilterModal;
