import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { Sponsor, SponsorId, OwnerId, Owner } from "../../interfaces/sponsor";

interface SponsorList {
  data: {
    companys: {
      data: Sponsor[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface OwnerList {
  data: {
    owners: {
      data: Owner[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface SponsorIds {
  data: {
    companyIds: SponsorId[];
  };
}

interface OwnerIds {
  data: {
    ownerIds: OwnerId[];
  };
}

export const sponsorAPI = createApi({
  reducerPath: "/api/sponsor",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getSponsorList: builder.query<
      SponsorList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-companys/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getSponsorIds: builder.query<
      SponsorIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-company-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addSponsor: builder.mutation({
      query: (data) => ({
        url: `/add-company/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editSponsor: builder.mutation({
      query: (data) => ({
        url: `/edit-company/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteSponsor: builder.mutation({
      query: (data) => ({
        url: `/delete-company/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterSponsorList: builder.mutation<
      SponsorList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-companys/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Owner
    getOwnerList: builder.query<
      OwnerList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-owners/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getOwnerIds: builder.query<
      OwnerIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-owner-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addOwner: builder.mutation({
      query: (data) => ({
        url: `/add-owner/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editOwner: builder.mutation({
      query: (data) => ({
        url: `/edit-owner/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteOwner: builder.mutation({
      query: (data) => ({
        url: `/delete-owner/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterOwnerList: builder.mutation<
      OwnerList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-filtered-owners/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetSponsorListQuery,
  useGetSponsorIdsQuery,
  useAddSponsorMutation,
  useEditSponsorMutation,
  useDeleteSponsorMutation,
  useGetFilterSponsorListMutation,
  useGetOwnerListQuery,
  useAddOwnerMutation,
  useDeleteOwnerMutation,
  useGetFilterOwnerListMutation,
  useEditOwnerMutation,
  useGetOwnerIdsQuery,
} = sponsorAPI;
