import React, { useState, useEffect } from "react";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { Formik } from "formik";
import { AttendanceIcons, Common, EmployeeIcons } from "../../assets/images";
import SelectSearch from "react-select-search";
import AttachmentSet from "../Employees/AddEmployee/attachments";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowEditEmployee,
  setShowEditProjectDetail,
  setEditProjectLoading,
} from "../../store/slices/projectSlice";
import { setProjectChange } from "../../store/slices/commonSlice";
import { useNavigate, useLocation } from "react-router-dom";
// API Imports
import { useEditProjectMutation } from "../../services/Projects";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import CategoriesSet from "./categories";
import { IdolEmployee } from "../../interfaces/employee";
import DatePicker from "react-datepicker";

interface FormProps {
  projectName: string;
  agreementFrom: string;
  agreementTo: string;
  projectSupervisor: string;
  projectLocation: string;
  projectStatus: string;
}

const EditProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectData = location.state.data;
  const [employeesToBeAdded, setEmployeesToBeAdded] = useState<number[]>([]); // Array of employee ids to be added to project
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectChange = useAppSelector((state) => state.common.projectChange);
  const allEmployees = useAppSelector(
    (state) => state.employee.allEmployeesList,
  );
  const allUsers = useAppSelector((state) => state.settings.allUsers);

  // Categories Sets
  const [categoriesSets, setCategoriesSets] = useState<React.ReactNode[]>([]);
  const [categoriesData, setCategoriesData] = useState<
    {
      uId: string;
      category: string;
      price: number;
      overTime: number;
      nationality: string;
      accomodation: string;
      transportation: string;
      food: string;
      workingHours: number;
      holidays: number;
      duration: number;
      overTimePrice: number;
    }[]
  >(projectData.employeeRequired || []);

  const handleAddCategoriesSet = () => {
    setCategoriesData([
      ...categoriesData,
      {
        uId: Math.random().toString(36).substring(7),
        category: "",
        price: 0,
        overTime: 0,
        nationality: "",
        accomodation: "",
        transportation: "",
        food: "",
        workingHours: 0,
        holidays: 0,
        duration: 0,
        overTimePrice: 0,
      },
    ]);
  };

  const handleRemoveCategoriesSet = (index: string) => {
    const updatedInputData = categoriesData.filter(
      (item, i) => item.uId !== index,
    );
    setCategoriesData(updatedInputData);
  };
  const handleCategoriesInputChange = (
    index: string,
    data: {
      category: string;
      price: number;
      overTime: number;
      nationality: string;
      accomodation: string;
      transportation: string;
      food: string;
      workingHours: number;
      holidays: number;
      duration: number;
      overTimePrice: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = categoriesData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setCategoriesData(updatedInput);
  };

  useEffect(() => {
    const updatedCategoriesSets = categoriesData.map((data, index) => (
      <CategoriesSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveCategoriesSet(key)}
        onInputChange={(inputData) =>
          handleCategoriesInputChange(data.uId, inputData)
        }
      />
    ));
    setCategoriesSets(updatedCategoriesSets);
  }, [categoriesData]);

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Selected Employees Table Data & Pagination
  const alreadySelectedEmployees = allEmployees.filter((employee) => {
    return projectData.projectEmployees.includes(employee.empId);
  });
  const [currentSelectectPage, setCurrentSelectedPage] = useState(1);
  const [pageSizeSelected, setPageSizeSelected] = useState(10);
  const startIndexSelected = (currentSelectectPage - 1) * pageSizeSelected;
  const endIndexSelected = startIndexSelected + pageSizeSelected;
  const paginatedDataSelected = alreadySelectedEmployees.slice(
    startIndexSelected,
    endIndexSelected,
  );

  const totalPagesSelected = Math.ceil(
    alreadySelectedEmployees.length / pageSizeSelected,
  );

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((currentSelectectPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPagesSelected).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentSelectectPage > totalPagesSelected - showPages) {
      return;
    }
    setCurrentSelectedPage(currentSelectectPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentSelectectPage <= 5) {
      return;
    }
    setCurrentSelectedPage(currentSelectectPage - showPages);
  };

  const handlePageSelectedChange = (page: any) => {
    setCurrentSelectedPage(page);
  };

  const handlePageSizeSelectedChange = (event: any) => {
    const selectedPageSizeSelected = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSizeSelected(selectedPageSizeSelected);
  };
  const tableSelectedData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Place",
    ],
    tableData: paginatedDataSelected,
  };

  const initialValues: FormProps = {
    projectName: projectData.projectName || "",
    agreementFrom: projectData.agreementFrom || "",
    agreementTo: projectData.agreementTo || "",
    projectSupervisor: projectData.projectSupervisor || "",
    projectLocation: projectData.projectLocation || "",
    projectStatus: projectData.projectStatus || "",
  };

  const [editProject] = useEditProjectMutation();
  const isHour = categoriesData.every((item) => item.workingHours !== 0);
  const isPrice = categoriesData.every((item) => item.price !== 0);
  const isCategory = categoriesData.every((item) => item.category !== "");

  const handleSubmit = async (values: FormProps) => {
    if (values.projectName === "") {
      window.scrollTo(0, 0);
      setErrorContent("Please fill project name");
      setShow(true);
      return;
    }
    if (values.projectSupervisor === "") {
      window.scrollTo(0, 0);
      setErrorContent("Please fill project supervisor");
      setShow(true);
      return;
    }
    if (!isCategory) {
      setErrorContent("Please fill category for all categories");
      setShow(true);
      return;
    }
    if (!isHour) {
      setErrorContent("Please fill working hours for all categories");
      setShow(true);
      return;
    }
    if (!isPrice) {
      setErrorContent("Please fill price for all categories");
      setShow(true);
      return;
    }
    dispatch(setEditProjectLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateProject || false,
        payload: {
          projectId: projectData.projectId,
          projectName: values.projectName,
          agreementFrom: values.agreementFrom,
          agreementTo: values.agreementTo,
          projectSupervisor: parseInt(values.projectSupervisor),
          projectLocation: values.projectLocation,
          projectStatus: values.projectStatus,
          newJoiners: employeesToBeAdded,
          employeeRequired: categoriesData,
          link: `/projects`,
        },
      };
      await editProject(data).unwrap();
      dispatch(setProjectChange(!projectChange));
      navigate("/projects");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setEditProjectLoading(false));
    }
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName + " " + employee.lastName, // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Project</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project Id
                      </h4>
                      <Form.Control
                        type="text"
                        name="projectId"
                        placeholder="Enter project id"
                        value={projectData.projectId}
                        disabled={true}
                        className="custom-input disabled-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project Name
                      </h4>
                      <Form.Control
                        type="text"
                        name="projectName"
                        placeholder="Enter project name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projectName}
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project Supervisor
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center">
                        <SelectSearch
                          name="projectSupervisor"
                          options={supervisorOptions}
                          value={values.projectSupervisor}
                          onChange={(value: any) => {
                            handleChange({
                              target: {
                                name: "projectSupervisor",
                                value: value,
                              },
                            });
                          }}
                          search={true}
                          placeholder="Select Project Supervisor"
                        />
                        <img src={Common.ArrowDown} alt="message" />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project Location
                      </h4>
                      <Form.Control
                        type="text"
                        name="projectLocation"
                        placeholder="Enter project location"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projectLocation}
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project Status
                      </h4>
                      <Form.Select
                        name="projectStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projectStatus}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Project Status</option>
                        <option value="In progress">In progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Closed">Closed</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Agreement From
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="agreementFrom"
                          selected={
                            values.agreementFrom
                              ? new Date(values.agreementFrom)
                              : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "agreementFrom", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Enter Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Agreement To
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="dob"
                          selected={
                            values.agreementTo
                              ? new Date(values.agreementTo)
                              : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "agreementTo", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Enter Date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="extra-detail my-4">
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Add Employee Category and their per unit price by
                        client...
                      </h4>
                      <button
                        className="btn primary-button normal-font-weight mb-4"
                        type="button"
                        onClick={handleAddCategoriesSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Category
                      </button>
                      {categoriesSets}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="extra-detail my-4">
                      <button
                        className="btn primary-button normal-font-weight mb-4"
                        type="button"
                        onClick={handleAddLanguageSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Attachment
                      </button>
                      {languageSets}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="selected-employees mt-4">
                      <h4 className="sub-heading">Selected Employees</h4>
                      {tableSelectedData.tableData.length > 0 ? (
                        <Table
                          striped
                          bordered
                          responsive
                          className=" text-center"
                        >
                          <thead>
                            <tr className="border-0">
                              {tableSelectedData.tableHeading.map(
                                (item, index) => (
                                  <th className="border-0" key={index}>
                                    {item}
                                  </th>
                                ),
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {tableSelectedData.tableData.map((item, index) => (
                              <tr
                                key={index}
                                style={{
                                  borderColor: "rgba(32, 32, 32, 0.20)",
                                }}
                              >
                                <td>{index + 1}</td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empId}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/employees/employee-detail/${item.empId}`,
                                      { state: { empId: item.empId } },
                                    )
                                  }
                                >
                                  {item.empName}
                                </td>
                                <td>{item.empPhone}</td>
                                <td>{item.empIqamaNo}</td>
                                <td>{item.empPlace}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center mt-5">
                          <h1 className="heading">No Employees Selected</h1>
                        </div>
                      )}
                    </div>
                  </Col>
                  {paginatedDataSelected.length > 0 && (
                    <Col sm="12">
                      <div className="mt-3 d-flex justify-content-between align-items-center">
                        <Form.Group
                          className="d-flex align-items-center gap-2"
                          controlId="formBasicEmail"
                        >
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "14px", width: "230px" }}
                          >
                            Entries per page
                          </h4>
                          <Form.Select
                            name="pageSize"
                            onChange={handlePageSizeSelectedChange}
                            value={pageSizeSelected.toString()}
                            className="custom-input border-fill custom-height"
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="100">100</option>
                          </Form.Select>
                        </Form.Group>
                        <div className="pagination-custom">
                          <Pagination className="justify-content-end">
                            <Pagination.Item
                              onClick={() => handleGoToPreviousPages()}
                            >
                              <img src={Common.DoubleArrowLeft} alt="arrow" />
                            </Pagination.Item>
                            <Pagination.Item
                              onClick={() =>
                                handlePageSelectedChange(
                                  currentSelectectPage > 1
                                    ? currentSelectectPage - 1
                                    : 1,
                                )
                              }
                            >
                              <img
                                src={EmployeeIcons.ArrowDownBlue}
                                className="rotate-left"
                                alt="arrow"
                              />
                            </Pagination.Item>

                            {pages.map((page, index) => (
                              <Pagination.Item
                                key={index}
                                onClick={() =>
                                  handlePageSelectedChange(page + 1)
                                }
                                className={
                                  page + 1 === currentSelectectPage
                                    ? "active-page"
                                    : ""
                                }
                              >
                                {page + 1}
                              </Pagination.Item>
                            ))}

                            <Pagination.Item
                              onClick={() =>
                                handlePageSelectedChange(
                                  currentSelectectPage < totalPagesSelected
                                    ? currentSelectectPage + 1
                                    : totalPagesSelected,
                                )
                              }
                            >
                              <img
                                src={EmployeeIcons.ArrowDownBlue}
                                className="rotate-right"
                                alt="arrow"
                              />
                            </Pagination.Item>
                            <Pagination.Item
                              onClick={() => {
                                handleGoToNextPages();
                              }}
                            >
                              <img src={Common.DoubleArrowRight} alt="arrow" />
                            </Pagination.Item>
                          </Pagination>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-5">
                      <button className="btn primary-button w-25" type="submit">
                        {state.editProjectLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          <>
                            Update
                            <img
                              src={Common.ArrowRightWhite}
                              className="ms-1"
                              alt="next"
                            />
                          </>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditProject;
