import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { ProbationEmployee } from "../../interfaces/employee";
import ProbationLetter from "../Letters/probation-letter";
import { Common } from "../../assets/images";

const ProbationDetail = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: ProbationEmployee = location.state.data;

  // check if all status are approved in approvals array
  const isApproved = statementData.probationApprovals.every(
    (item) => item.status === "Approved",
  );

  return (
    <div className=" mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <ProbationLetter probationData={statementData} />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Probation Detail</h1>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation Duration
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.probationDuration}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation From
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.probationFrom
                      ? new Date(
                          statementData.probationFrom,
                        ).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation To
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.probationTo
                      ? new Date(statementData.probationTo).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Probation Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.probationApprovalsStatus}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">Probation Approvals:</h2>
              <Row>
                {statementData.probationApprovals.length > 0 ? (
                  <>
                    {statementData.probationApprovals.map((item, index) => (
                      <div className="col-sm-4" key={index}>
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            {item.name} ({item.role})
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {item.status}{" "}
                            {item.status === "Approved"
                              ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                              : null}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Approvals
                    </h4>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              {isApproved && (
                <button
                  className="btn primary-button w-25"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ProbationDetail;
