import React from "react";
import InternalLayout from "../components/InternalLayout";
import DashboardComponent from "../components/Dashboard";
import withAuth from "../config/private-route";
import Content from "../components/Dashboard/get-dashboard-data";

const Dashboard = () => {
  return (
    <InternalLayout>
      <Content />
      <DashboardComponent />
    </InternalLayout>
  );
};
export default withAuth(Dashboard);
