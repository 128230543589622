import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetAccountChartListQuery } from "../../services/AccountCharts";
import {
  setAccountChartsList,
  setAccountChartsListLoading,
  setAccountChartsListError,
  setAccountChartsListTotalPages,
} from "../../store/slices/accountChartsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const accountChartsChange = useAppSelector(
    (state) => state.common.accountChartsChange,
  );
  // States
  const state = useAppSelector((state) => state.accountCharts);

  // **************
  // **************
  // Get All Camps
  // **************
  // **************
  const {
    data: allAccountCharts,
    isLoading: accountChartsListLoading,
    isError: accountChartsListError,
    refetch: accountChartsListRefetch,
  } = useGetAccountChartListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    pageNo: state.accountChartsListPageNo,
    pageSize: state.accountChartsListPageSize,
    type: state.type,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (accountChartsListLoading) {
      dispatch(setAccountChartsListLoading(true));
    }
    if (accountChartsListError) {
      dispatch(setAccountChartsListError("Something went wrong"));
      dispatch(setAccountChartsListLoading(false));
    }
    if (allAccountCharts) {
      dispatch(
        setAccountChartsList(allAccountCharts.data.allAccounts[0].data || []),
      );
      dispatch(
        setAccountChartsListTotalPages(
          allAccountCharts.data.allAccounts[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setAccountChartsListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allAccountCharts,
    profileData,
    state.accountChartsListPageNo,
    state.accountChartsListPageSize,
    state.type,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    accountChartsListRefetch();
  }, [accountChartsChange]);
  return <></>;
};
export default Content;
