import React, { useEffect } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setNotificationChange,
  setProjectChange,
  setCertificateChange,
  setOfferLetterChange,
  setFinalExitChange,
  setAbscondedChange,
  setApprovalChange,
  setLeaveRequestChange,
  setProbationChange,
  setIqamaChange,
  setSalaryIncrementChange,
  setTransferEmployeeChange,
} from "../store/slices/commonSlice";
import app from "./firebase";

const requestNotificationPermission = async () => {
  if ("Notification" in window) {
    try {
      const permission = await Notification.requestPermission();
      return permission;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  } else {
    console.warn("This browser does not support notifications.");
  }
};

const CustomToastContent: React.FC<{ title: string; body: string }> = ({
  title,
  body,
}) => (
  <div>
    <h3 style={{ fontSize: "20px" }}>{title}</h3>
    <p className="mb-0" style={{ fontSize: "16px" }}>
      {body}
    </p>
  </div>
);

const NotificationComponent = () => {
  const messaging = getMessaging(app);
  const dispatch = useAppDispatch();
  const commonState = useAppSelector((state) => state.common);
  const notificationChange = useAppSelector(
    (state) => state.common.notificationChange,
  );
  const activateMessages = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BIDkWwa7nUtUgAknClBTDHCXlOZ38Bv74MnjhoUfrm0GzQz76U2un7r6fFUYTzgBRO4ja78zuAF3Ao_05hTTklc",
    }).catch((error) => console.log("error generatin token"));

    if (token) localStorage.setItem("firebaseToken", token);
    if (!token) console.log("no token");
  };

  useEffect(() => {
    requestNotificationPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted!");
        activateMessages();
        // Now you can proceed to register for push notifications (if using a service like Firebase Cloud Messaging)
      } else if (permission === "denied") {
        console.warn("Notification permission denied by the user.");
      } else if (permission === "default") {
        console.warn("Notification permission dismissed by the user.");
      }
    });

    onMessage(messaging, (message: any) => {
      dispatch(setNotificationChange(!notificationChange));
      dispatch(setApprovalChange(!commonState.approvalChange));
      if (message.data.approvalType === "Transfer") {
        dispatch(setProjectChange(!commonState.projectChange));
        dispatch(
          setTransferEmployeeChange(!commonState.transferEmployeeChange),
        );
      }
      if (message.data.approvalType === "Certificate") {
        dispatch(setCertificateChange(!commonState.certificateChange));
      }
      if (message.data.approvalType === "offerLetterApprovals") {
        dispatch(setOfferLetterChange(!commonState.offerLetterChange));
      }
      if (message.data.approvalType === "finalExitApprovals") {
        dispatch(setFinalExitChange(!commonState.finalExitChange));
      }
      if (message.data.approvalType === "abscondApprovals") {
        dispatch(setAbscondedChange(!commonState.abscondedChange));
      }
      if (message.data.approvalType === "Leave") {
        dispatch(setLeaveRequestChange(!commonState.leaveRequestChange));
      }
      if (message.data.approvalType === "probationApprovals") {
        dispatch(setProbationChange(!commonState.probationChange));
      }
      if (
        message.data.approvalType === "iqamaApprovals" ||
        message.data.approvalType === "iqamaHandoverApprovals"
      ) {
        dispatch(setIqamaChange(!commonState.iqamaChange));
      }
      if (message.data.approvalType === "Increment") {
        dispatch(setSalaryIncrementChange(!commonState.salaryIncrementChange));
      }
      toast(
        <CustomToastContent
          title={message.notification.title}
          body={message.notification.body}
        />,
        {
          style: {
            backgroundColor: "#29406c", // Set the background color to black
            color: "white", // Set the text color to white
          },
        },
      );
      // console.log("payload.data.url"+message.data.url)
      //window.location.replace("http://localhost:3000/"+message.data.url)
    });
  });

  return <ToastContainer theme="dark" hideProgressBar={true} />;
};

export default NotificationComponent;
