import React from "react";
import InternalLayout from "../components/InternalLayout";
import IqamaComponent from "../components/Iqama";
import withAuth from "../config/private-route";
import Content from "../components/Iqama/get-iqama-list-api";

const Iqama = () => {
  return (
    <InternalLayout>
      <Content />
      <IqamaComponent />
    </InternalLayout>
  );
};
export default withAuth(Iqama);
