import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setBillChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { usePayBillsMutation } from "../../services/AccountCharts";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";
import { setPayBillLoading } from "../../store/slices/vendorsSlice";
import SelectSearch from "react-select-search";

interface Props {
  show: boolean;
  setShow: any;
  billIds: string[];
  payBillLoading: boolean;
  setSuccessContent: any;
  setShowSuccess: any;
  billChange: boolean;
  status: string;
  totalAmount: number;
}

const PayBillModal = ({
  show,
  setShow,
  billIds,
  payBillLoading,
  setShowSuccess,
  billChange,
  setSuccessContent,
  status,
  totalAmount,
}: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const state = useAppSelector((state) => state.accountCharts);
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [accountName, setAccountName] = React.useState("");
  const [paidOn, setPaidOn] = React.useState("");
  const [payBills] = usePayBillsMutation();

  //Options
  const options = state.accountChartsIds.map((item) => ({
    name: item.accountId + " - " + item.accountName,
    value: item.accountName,
  }));

  const handleCreatePayroll = async () => {
    if (billIds.length === 0) {
      setErrorContent("Please select atleast one bill");
      setShowError(true);
      return;
    }
    if (!paidOn) {
      setErrorContent("Please select paid on date");
      setShowError(true);
      return;
    }
    if (!accountName) {
      setErrorContent("Please select account name");
      setShowError(true);
      return;
    }
    if (!status) {
      setErrorContent("Please select status");
      setShowError(true);
      return;
    }
    dispatch(setPayBillLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          billIds: billIds,
          accountName: accountName,
          paidOn: new Date(paidOn).toLocaleDateString(),
          status: status,
          totalAmount: totalAmount,
          link: "",
        },
      };
      await payBills(data).unwrap();
      dispatch(setBillChange(!billChange));
      setAccountName("");
      setPaidOn("");
      setSuccessContent("Bills Paid Successfully!");
      setShowSuccess(true);
      setShow(false);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setPayBillLoading(false));
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="mb-4 text-center">
            <h1 className="heading">Are you sure?</h1>
            {/*<h4 className="sub-heading normal-font-weight text-center">*/}
            {/*  Payslips once created cannot be edited.*/}
            {/*</h4>*/}
            {status === "Paid" && (
              <Row>
                <Col sm="6">
                  <Form.Group className="w-100 mb-3" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack text-start"
                      style={{ fontSize: "18px" }}
                    >
                      Select Account Name
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        onChange={(value: any) => {
                          setAccountName(value);
                        }}
                        value={accountName}
                        search={true}
                        placeholder="Search account name"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack text-start"
                      style={{ fontSize: "18px" }}
                    >
                      Paid On
                    </h4>
                    <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                      <DatePicker
                        name="paidOn"
                        selected={paidOn ? new Date(paidOn) : null}
                        onChange={(e: any) => setPaidOn(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="Select paid on date"
                        className="w-100 custom-input"
                        dateFormat="yyyy-MM-d"
                      />
                      <img
                        src={Common.Calendar}
                        className="calendar-date-recur"
                        alt="calendarblack"
                      />
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <div className="d-flex align-items-center justify-content-center gap-4">
              <button
                className="btn secondary-button mt-4"
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button
                className="btn primary-button border-0 mt-4"
                onClick={handleCreatePayroll}
              >
                {payBillLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : status === "Paid" ? (
                  "Pay"
                ) : status === "Hold" ? (
                  "Hold"
                ) : (
                  "Cancel"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PayBillModal;
