import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setEditAttendanceLoading } from "../../store/slices/attendanceSlice";
import { setAttendanceChange } from "../../store/slices/commonSlice";
// API Imports
import { useEditAttendanceMutation } from "../../services/Attendance";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

const EditAttendance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const dataA = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.attendance);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const attendanceChange = useAppSelector(
    (state) => state.common.attendanceChange,
  );
  const [editAttendance] = useEditAttendanceMutation();
  const handleSubmit = async (values: any) => {
    dispatch(setEditAttendanceLoading(true));
    const checkinHours = parseInt(values.checkin.split(":")[0]);
    const checkoutHours = parseInt(values.checkout.split(":")[0]);
    const hours = checkoutHours - checkinHours;
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateAttendance || false,
        payload: {
          docId: dataA._id,
          projectedStatus: values.status,
          fromDate: values.checkin,
          toDate: values.checkout,
          hours: values.status === "Absent" ? 0 : hours,
          overTime: hours - dataA.workingHours,
          link: "/attendance/attendance-log",
        },
      };
      await editAttendance(data).unwrap();
      dispatch(setAttendanceChange(!attendanceChange));
      navigate("/attendance/attendance-log");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setEditAttendanceLoading(false));
    }
  };
  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Attendance</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={{
              checkin: dataA.fromDate,
              checkout: dataA.toDate,
              status: dataA.status,
            }}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Checkin Time
                      </h4>
                      <Form.Control
                        type="time"
                        name="checkin"
                        value={values.checkin}
                        onChange={handleChange}
                        placeholder="Enter checkin time"
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Checkout Time
                      </h4>
                      <Form.Control
                        type="time"
                        name="checkout"
                        value={values.checkout}
                        onChange={handleChange}
                        placeholder="Enter checkout time"
                        className="custom-input border-fill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Status
                      </h4>
                      <Form.Select
                        name="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Status</option>
                        <option value="Leave">Leave</option>
                        <option value="Absent">Absent</option>
                        <option value="Present">Present</option>
                        <option value="Paid holiday">Paid holiday</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="text-end mt-4">
                      <button className="btn primary-button w-25" type="submit">
                        {state.editAttendanceLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditAttendance;
