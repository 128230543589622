import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Services
import { useGetRecruitAgencyListQuery } from "../../services/Camps";
import {
  setRecruitAgencyList,
  setRecruitAgencyListLoading,
  setRecruitAgencyListError,
  setRecruitAgencyListTotalPages,
} from "../../store/slices/campsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const recruitAgencyChange = useAppSelector(
    (state) => state.common.recruitAgencyChange,
  );
  // States
  const state = useAppSelector((state) => state.camps);

  // **************
  // **************
  // Get All Recruit Agency
  // **************
  // **************
  const {
    data: allRecruitAgencies,
    isLoading: recruitAgencyLoading,
    isError: recruitAgencyError,
    refetch: recruitAgencyRefetch,
  } = useGetRecruitAgencyListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showLegal || false,
    pageNo: state.recruitAgencyListPageNo,
    pageSize: state.recruitAgencyListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (recruitAgencyLoading) {
      dispatch(setRecruitAgencyListLoading(true));
    }
    if (recruitAgencyError) {
      dispatch(setRecruitAgencyListError("Something went wrong"));
      dispatch(setRecruitAgencyListLoading(false));
    }
    if (allRecruitAgencies) {
      dispatch(
        setRecruitAgencyList(allRecruitAgencies.data.recruiters[0].data || []),
      );
      dispatch(
        setRecruitAgencyListTotalPages(
          allRecruitAgencies.data.recruiters[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setRecruitAgencyListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allRecruitAgencies,
    profileData,
    state.recruitAgencyListPageNo,
    state.recruitAgencyListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    recruitAgencyRefetch();
  }, [recruitAgencyChange]);
  return <></>;
};
export default Content;
