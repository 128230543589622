import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Dropdown, Form, Pagination, Table } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
// Redux Imports
import { exportToCsv } from "../../config";
// API imoprts
import { useReactToPrint } from "react-to-print";
import { ManualJournal } from "../../interfaces/account-charts";

const ViewJournal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const journalData: ManualJournal = location.state.data;
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = journalData.accounts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(journalData.accounts.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Account",
      "Description",
      "Contact",
      "Debit (SAR)",
      "Credit (SAR)",
    ],
    tableData: paginatedData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    Account: item.account,
    Description: item.description,
    Contact: item.contact,
    "Debit (SAR)": item.debit > 0 ? item.debit : "",
    "Credit (SAR)": item.credit > 0 ? item.credit : "",
  }));

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h1 className="heading mb-0">Journal Detail</h1>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-4">
              <div className="d-flex align-items-center gap-4">
                <Dropdown
                  show={showExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      setShowExportDropdown(!showExportDropdown);
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={showExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "journal-detail");
                        setShowExportDropdown(false);
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowExportDropdown(false);
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <div className="p-3" ref={tableRef}>
          {print && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <img src={Common.Vision} width="110px" alt="logo" />
                </div>
                <div className="letter-logo text-center gap-2">
                  <img src={Common.LogoSVG} width="80px" alt="logo" />
                  <div className="mt-1">
                    <h3
                      className="logo-text mb-0"
                      style={{
                        color: "#202020",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      Senyar Arabian Trading Co. LTD
                    </h3>
                    <h3
                      className="logo-text logo-arabic mb-0"
                      style={{
                        color: "#202020",
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                    >
                      شركة سنيار العربية التجارية المحدودة
                    </h3>
                  </div>
                </div>
              </div>
              <h1 className="sub-heading text-center mt-3">Journal Detail</h1>
            </>
          )}
          <Col sm="12">
            <Row>
              <Col sm="8">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: print ? "14px" : "18px" }}
                    >
                      Note
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: print ? "12px" : "16px" }}
                    >
                      {journalData.journalDetail}
                    </h5>
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-4 text-end"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: print ? "14px" : "18px" }}
                  >
                    Id: {journalData.journalId}
                  </h4>
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: print ? "14px" : "18px" }}
                  >
                    Date: {journalData.journalDate}
                  </h4>
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: print ? "14px" : "18px" }}
                  >
                    Reference No: {journalData.reference}
                  </h4>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          {!print && (
            <>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: print ? "16px" : "26px" }}
                  >
                    Attachments:
                  </h4>
                </div>
              </Col>
              <Col sm="12">
                {journalData.attachments.length > 0 ? (
                  <div className="table-responsive mb-3">
                    <table className="table text-center table-bordered">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Date</th>
                          <th>File Title</th>
                          <th>Uploader Emp Id</th>
                          <th>Uploader Emp Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {journalData.attachments.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.date
                                ? new Date(item.date).toLocaleDateString()
                                : "N/A"}
                            </td>
                            <td>{item.title}</td>
                            <td>{item.empId}</td>
                            <td>{item.empName}</td>
                            <td>
                              <a href={item.link} download>
                                <img
                                  src={Common.Download}
                                  className="me-2"
                                  width="24px"
                                  height="24px"
                                  alt="share"
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        No Attachment Found
                      </h4>
                    </Form.Group>
                  </Col>
                )}
              </Col>
            </>
          )}
          <Col sm="12">
            <h4
              className="sub-heading textBlack"
              style={{ fontSize: print ? "16px" : "26px" }}
            >
              Accounts:
            </h4>
          </Col>
          <Col sm="12">
            <div className="mt-4">
              {tableData.tableData.length > 0 ? (
                <Table
                  striped
                  bordered
                  responsive
                  className={print ? "text-center print-table" : "text-center"}
                >
                  <thead>
                    <tr className="border-0">
                      {tableData.tableHeading.map((item, index) => (
                        <th className="border-0" key={index}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.tableData.map((item, index) => (
                      <tr
                        key={index}
                        style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                      >
                        <td>
                          {journalData.accounts.length > 0
                            ? (currentPage - 1) * pageSize + index + 1
                            : index + 1}
                        </td>
                        <td>{item.account}</td>
                        <td>{item.description}</td>
                        <td>{item.contact}</td>
                        <td>{item.debit > 0 ? item.debit : ""}</td>
                        <td>{item.credit > 0 ? item.credit : ""}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} className="text-end">
                        Total
                      </td>
                      <td>
                        {journalData.accounts.reduce((acc, item) => {
                          return acc + item.debit;
                        }, 0)}
                      </td>
                      <td>
                        {journalData.accounts.reduce((acc, item) => {
                          return acc + item.credit;
                        }, 0)}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No accounts found</h1>
                </div>
              )}
            </div>
          </Col>
          {paginatedData.length > 0 && !print && (
            <Col sm="12">
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <Form.Group
                  className="d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "14px", width: "230px" }}
                  >
                    Entries per page
                  </h4>
                  <Form.Select
                    name="pageSize"
                    onChange={handlePageSizeChange}
                    value={pageSize.toString()}
                    className="custom-input border-fill custom-height"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                  </Form.Select>
                </Form.Group>
                <div className="pagination-custom">
                  <Pagination className="justify-content-end">
                    <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                      <img src={Common.DoubleArrowLeft} alt="arrow" />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-left"
                        alt="arrow"
                      />
                    </Pagination.Item>

                    {pages.map((page, index) => (
                      <Pagination.Item
                        key={index}
                        onClick={() => handlePageChange(page + 1)}
                        className={
                          page + 1 === currentPage ? "active-page" : ""
                        }
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          currentPage < totalPages
                            ? currentPage + 1
                            : totalPages,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-right"
                        alt="arrow"
                      />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        handleGoToNextPages();
                      }}
                    >
                      <img src={Common.DoubleArrowRight} alt="arrow" />
                    </Pagination.Item>
                  </Pagination>
                </div>
              </div>
            </Col>
          )}
        </div>
      </Row>
    </div>
  );
};
export default ViewJournal;
