import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { AddEmployeeProps } from "../AddEmployee/add-employee-container";
import { Common, EmployeeIcons } from "../../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEditOtherDetails,
  setEditBasicInfo,
  setEditLegalInfo,
  setActiveTab,
} from "../../../store/slices/employeeSlice";
import DatePicker from "react-datepicker";
import { LoaderBlue, LoaderWhite } from "../../Lotties";
import SelectSearch from "react-select-search";
import { useUploadImageMutation } from "../../../services/images-upload-service";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const EditLegalInfo = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const [workPermitType, setWorkPermitType] = useState("Valid Till");
  const sponsorIds = useAppSelector((state) => state.sponsor.sponsorIds);
  const state = useAppSelector((state) => state.employee);
  const [recruiter, setRecruiter] = React.useState(
    values.recruiterDetails.recruiterId || "",
  );
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState("");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const recruiterIds = useAppSelector((state) => state.camps.recruitAgencyIds);
  const selectedRecruiter = recruiterIds.find(
    (item) => item.recruiterId === recruiter,
  );
  const options = recruiterIds.map((employee) => ({
    name: employee.recruiterName, // Use the actual property name for the employee name
    value: employee.recruiterId,
  }));

  useEffect(() => {
    if (selectedRecruiter) {
      values.recruiterDetails.recruiterId = recruiter;
      values.recruiterDetails.recruiterName = selectedRecruiter.recruiterName;
      values.recruiterDetails.companyName = selectedRecruiter.companyName;
    }
  }, [recruiter]);

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (imageUploaded) {
      values.empInsuranceAttachment = imageUploaded;
    }
  }, [imageUploaded]);

  // Using the URL API to extract the pathname
  const pathname = imageUploaded ? new URL(imageUploaded).pathname : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split("/").pop();

  const sponsorSelected = sponsorIds.find(
    (item) => item.companyName === values.sponsorName,
  );

  useEffect(() => {
    if (sponsorSelected) {
      values.idNo = sponsorSelected.unifiedNumber.toString();
    }
  }, [values.sponsorName]);

  const workPermit = new Date(values.workPermitExpiry).toLocaleDateString();

  useEffect(() => {
    if (workPermit === "Invalid Date") {
      setWorkPermitType("Malwi");
    } else {
      setWorkPermitType("Valid Till");
    }
  }, []);

  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Legal Details</h3>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Iqama No.
          </h4>
          <Form.Control
            type="text"
            name="iqamaNo"
            placeholder="Enter iqama no."
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.iqamaNo}
            className={
              touched.iqamaNo && errors.iqamaNo
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.iqamaNo && errors.iqamaNo ? (
            <div className="invalid-feedback">{errors.iqamaNo}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Iqama Expiry Date
          </h4>
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <DatePicker
              name="iqamaExpiry"
              selected={
                values.iqamaExpiry ? new Date(values.iqamaExpiry) : null
              }
              onChange={(date: any) =>
                handleChange({
                  target: { name: "iqamaExpiry", value: date },
                })
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              placeholderText="Select iqama expiry date"
              className={
                errors.iqamaExpiry && touched.iqamaExpiry
                  ? "is-invalid w-100 custom-input"
                  : "w-100 custom-input"
              }
              dateFormat="yyyy-MM-d"
            />
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
          </Form.Group>
          {touched.iqamaExpiry && errors.iqamaExpiry ? (
            <div className="invalid-feedback">{errors.iqamaExpiry}</div>
          ) : null}
        </Form.Group>
      </Col>
      {values.nationality !== "Saudi Arabia" && (
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Profession on Iqama
            </h4>
            <Form.Control
              type="text"
              name="professionOnIqama"
              placeholder="Enter profession on iqama"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.professionOnIqama}
              className={
                touched.professionOnIqama && errors.professionOnIqama
                  ? "is-invalid custom-input border-fill"
                  : "custom-input border-fill"
              }
            />
            {touched.professionOnIqama && errors.professionOnIqama ? (
              <div className="invalid-feedback">{errors.professionOnIqama}</div>
            ) : null}
          </Form.Group>
        </Col>
      )}
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Sponser Name
          </h4>

          <Form.Select
            name="sponsorName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sponsorName}
            className={
              touched.sponsorName && errors.sponsorName
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          >
            <option value="">Select sponsor name</option>
            {sponsorIds.map((item, index) => (
              <option key={index} value={item.companyName}>
                {item.companyId + " - " + item.companyName}
              </option>
            ))}
          </Form.Select>

          {touched.sponsorName && errors.sponsorName ? (
            <div className="invalid-feedback">{errors.sponsorName}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Sponsor Id No.
          </h4>
          <Form.Control
            type="text"
            name="idNo"
            placeholder="Enter id no."
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.idNo}
            disabled={true}
            className={
              touched.idNo && errors.idNo
                ? "is-invalid custom-input disabled-input border-fill"
                : "custom-input disabled-input border-fill"
            }
          />
          {touched.idNo && errors.idNo ? (
            <div className="invalid-feedback">{errors.idNo}</div>
          ) : null}
        </Form.Group>
      </Col>
      {values.nationality !== "Saudi Arabia" && (
        <>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Iqama Printed
              </h4>
              <Form.Select
                name="iqamaPrinted"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.iqamaPrinted}
                className={
                  touched.iqamaPrinted && errors.iqamaPrinted
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select printed status</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>

              {touched.iqamaPrinted && errors.iqamaPrinted ? (
                <div className="invalid-feedback">{errors.iqamaPrinted}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Handover to Employee
              </h4>
              <Form.Select
                name="handOverToEmployee"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.handOverToEmployee}
                className={
                  touched.handOverToEmployee && errors.handOverToEmployee
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">
                  Select iqama handover to employee status
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>

              {touched.handOverToEmployee && errors.handOverToEmployee ? (
                <div className="invalid-feedback">
                  {errors.handOverToEmployee}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport with Company
              </h4>
              <Form.Select
                name="passportWithCompany"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passportWithCompany}
                className={
                  touched.passportWithCompany && errors.passportWithCompany
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select passport with company status</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>

              {touched.passportWithCompany && errors.passportWithCompany ? (
                <div className="invalid-feedback">
                  {errors.passportWithCompany}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport No.
              </h4>
              <Form.Control
                type="text"
                name="passportNo"
                placeholder="Enter passport no."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passportNo}
                className={
                  touched.passportNo && errors.passportNo
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              />
              {touched.passportNo && errors.passportNo ? (
                <div className="invalid-feedback">{errors.passportNo}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport Expiry Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="passportExpiry"
                  selected={
                    values.passportExpiry
                      ? new Date(values.passportExpiry)
                      : null
                  }
                  onChange={(date: any) =>
                    handleChange({
                      target: { name: "passportExpiry", value: date },
                    })
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select passport expiry date"
                  className={
                    errors.passportExpiry && touched.passportExpiry
                      ? "is-invalid w-100 custom-input"
                      : "w-100 custom-input"
                  }
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
              {touched.passportExpiry && errors.passportExpiry ? (
                <div className="invalid-feedback">{errors.passportExpiry}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Visa No.
              </h4>
              <Form.Control
                type="text"
                name="visaNo"
                placeholder="Enter visa no."
                onChange={(e) => {
                  //     accept only numbers
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    handleChange(e);
                  }
                }}
                onBlur={handleBlur}
                value={values.visaNo}
                className={
                  touched.visaNo && errors.visaNo
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              />
              {touched.visaNo && errors.visaNo ? (
                <div className="invalid-feedback">{errors.visaNo}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Entry Date Of Emp In Saudia Arabia
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="dateOfEmpInSA"
                  selected={
                    values.dateOfEmpInSA ? new Date(values.dateOfEmpInSA) : null
                  }
                  onChange={(date: any) =>
                    handleChange({
                      target: { name: "dateOfEmpInSA", value: date },
                    })
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select date of employeement in saudia arabia"
                  className={
                    errors.dateOfEmpInSA && touched.dateOfEmpInSA
                      ? "is-invalid w-100 custom-input"
                      : "w-100 custom-input"
                  }
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
              {touched.dateOfEmpInSA && errors.dateOfEmpInSA ? (
                <div className="invalid-feedback">{errors.dateOfEmpInSA}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Visa Expiry Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="visaExpiry"
                  selected={
                    values.visaExpiry ? new Date(values.visaExpiry) : null
                  }
                  onChange={(date: any) =>
                    handleChange({
                      target: { name: "visaExpiry", value: date },
                    })
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select visa expiry date"
                  className={
                    errors.visaExpiry && touched.visaExpiry
                      ? "is-invalid w-100 custom-input"
                      : "w-100 custom-input"
                  }
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
              {touched.visaExpiry && errors.visaExpiry ? (
                <div className="invalid-feedback">{errors.visaExpiry}</div>
              ) : null}
            </Form.Group>
          </Col>
        </>
      )}
      <Col sm="4">
        <Form.Group className="mb-3">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Select Recruiter Id.
          </h4>
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <SelectSearch
              options={options}
              value={recruiter}
              onChange={(e: any) => setRecruiter(e)}
              search={true}
              placeholder="Select Recruiter"
            />
            <img src={Common.ArrowDown} alt="message" />
          </Form.Group>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Company
          </h4>
          <Form.Control
            type="text"
            name="insuranceCompany"
            placeholder="Enter insurance company"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.insuranceCompany}
            className={
              touched.insuranceCompany && errors.insuranceCompany
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.insuranceCompany && errors.insuranceCompany ? (
            <div className="invalid-feedback">{errors.insuranceCompany}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Expiry
          </h4>
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <DatePicker
              name="insuranceExpiry"
              selected={
                values.insuranceExpiry ? new Date(values.insuranceExpiry) : null
              }
              onChange={(date: any) =>
                handleChange({
                  target: { name: "insuranceExpiry", value: date },
                })
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              placeholderText="Select insurance expiry date"
              className={
                errors.insuranceExpiry && touched.insuranceExpiry
                  ? "is-invalid w-100 custom-input"
                  : "w-100 custom-input"
              }
              dateFormat="yyyy-MM-d"
            />
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
          </Form.Group>
          {touched.insuranceExpiry && errors.insuranceExpiry ? (
            <div className="invalid-feedback">{errors.insuranceExpiry}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Cost
          </h4>
          <Form.Control
            type="text"
            name="empInsuranceCost"
            placeholder="Enter insurance cost"
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.empInsuranceCost}
            className={
              touched.empInsuranceCost && errors.empInsuranceCost
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.empInsuranceCost && errors.empInsuranceCost ? (
            <div className="invalid-feedback">{errors.empInsuranceCost}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group>
          {imageUploaded ? null : (
            <div>
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Insurance Attachment
              </h4>
            </div>
          )}
          {imageLoading ? (
            <LoaderBlue height={50} width={50} />
          ) : (
            <>
              {imageUploaded ? (
                <div className="d-flex">
                  <div className="text-center">
                    <img
                      src={
                        fileNameWithExtension === "jpg" ||
                        fileNameWithExtension === "png" ||
                        fileNameWithExtension === "jpeg"
                          ? Common.ImageIcon
                          : Common.FileIcon
                      }
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                      alt="employee-image"
                    />
                    <p className="para-medium textBlack text-center">
                      {fileNameWithExtension}
                    </p>
                  </div>
                </div>
              ) : (
                <Form.Label
                  className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center"
                  style={{ height: "auto", padding: "8px 24px" }}
                >
                  <Form.Control
                    type="file"
                    name="profileImage"
                    onChange={handleUploadImage}
                    accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, docx, .xlsx, .xls"
                    className="d-none"
                    multiple={false}
                  />
                  <div className="text-center d-flex align-items-center gap-3">
                    <div
                      className="add-profile-image d-flex align-items-center justify-content-center mx-auto"
                      style={{ width: "30px", height: "30px" }}
                    >
                      <img src={EmployeeIcons.Plus} width="25px" alt="plus" />
                    </div>
                    <p
                      className="upload-para mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Upload PNG, JPG, PDF, CSV, DOCS, XLSX File only
                    </p>
                  </div>
                </Form.Label>
              )}
            </>
          )}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Work Permit Type
          </h4>
          <Form.Select
            name="workPermitType"
            onChange={(e) => {
              values.workPermitExpiry = "";
              setWorkPermitType(e.target.value);
            }}
            value={workPermitType}
            className="custom-input border-fill"
          >
            <option value="">Select work permit type</option>
            <option value="Valid Till">Valid Till</option>
            <option value="Malwi">Exit Work Permit</option>
          </Form.Select>
        </Form.Group>
      </Col>
      {workPermitType === "Valid Till" || workPermit !== "Invalid Date" ? (
        <Col sm="4">
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Work Permit Expiry
            </h4>
            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
              <DatePicker
                name="workPermitExpiry"
                selected={
                  values.workPermitExpiry
                    ? new Date(values.workPermitExpiry)
                    : null
                }
                onChange={(date: any) =>
                  handleChange({
                    target: { name: "workPermitExpiry", value: date },
                  })
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                placeholderText="Select work permit expiry date"
                className="w-100 custom-input"
                dateFormat="yyyy-MM-d"
              />
              <img
                src={Common.Calendar}
                className="calendar-date-recur"
                alt="calendarblack"
              />
            </Form.Group>
          </Form.Group>
        </Col>
      ) : null}
      {workPermitType === "Malwi" || workPermit === "Invalid Date" ? (
        <Col sm="4">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Work Permit Text
            </h4>
            <Form.Control
              type="text"
              name="workPermitExpiry"
              placeholder="Enter text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.workPermitExpiry}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
      ) : null}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setEditBasicInfo(true));
              dispatch(setEditLegalInfo(false));
              dispatch(setActiveTab("Basic Information"));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <div className="w-100">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="submit"
              style={{ width: "15%" }}
            >
              {state.empLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
            <button
              className="btn primary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditLegalInfo(false));
                dispatch(setEditOtherDetails(true));
                dispatch(setActiveTab("Other Details"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              Next <img src={Common.ArrowRightWhite} alt="next" />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default EditLegalInfo;
