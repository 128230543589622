import React, { useRef, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import LetterFormatB from "./letter-format-b";
import { useReactToPrint } from "react-to-print";
import { BehaviourStatement } from "../../interfaces/letters";

interface Props {
  bData: BehaviourStatement;
}

const WorkRefusalLetter = ({ bData }: Props) => {
  const probationRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => probationRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div className="letter-container position-relative" ref={probationRef}>
      <LetterFormatB heading="Offer Letter" data="hello">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h4
              className="sub-heading text-black mb-0 text-decoration-underline"
              style={{ fontSize: "12px" }}
            >
              Senyar Arabian Trading Co. Ltd.
            </h4>
            <ul>
              <li className="d-flex align-items-center gap-2 mb-0">
                <h4
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  P.O Box 8931 Jeddah 23345
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <h4
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  Kingdom of Saudi Arabia
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <span
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  Website:
                </span>
                <h4
                  className="sub-heading normal-font-weight text-black mb-0 ms-3"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                  }}
                >
                  www.senyarsa.com
                </h4>
              </li>
              <li className="d-flex align-items-center gap-2 mb-0">
                <span
                  className="sub-heading normal-font-weight text-black mb-0"
                  style={{ fontSize: "12px" }}
                >
                  E-mail:
                </span>
                <h4
                  className="sub-heading normal-font-weight text-black mb-0 ms-3"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                  }}
                >
                  hr@senyarsa.com
                </h4>
              </li>
            </ul>
          </div>
          <div>
            <div className="ref-no d-flex align-items-center gap-2 justify-content-end">
              <h4
                className="sub-heading textBlack text-uppercase mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                Date:
              </h4>
              <p
                className="sub-heading normal-font-weight text-black mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="ref-no d-flex align-items-center gap-2 justify-content-end">
              <h4
                className="sub-heading textBlack text-uppercase mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                Ref#
              </h4>
              <p
                className="sub-heading normal-font-weight text-black mb-0"
                style={{ fontSize: "14px", color: "#29406C" }}
              >
                23232323
              </p>
            </div>
          </div>
        </div>
        <div className="table-container-letter mb-3">
          <div className="table-content-letter">
            <Table
              responsive
              borderless
              className="text-center mb-0 emp-detail-table"
            >
              <tbody>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employee Detail:
                  </h4>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>Ali Asif</td>
                </tr>
                <tr>
                  <td>Iqama Number</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Border Number</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Passport Number</td>
                  <td>PJK32323</td>
                </tr>
                <tr>
                  <td>Nationality</td>
                  <td>Pakistani</td>
                </tr>
                <tr>
                  <td>D.O.B</td>
                  <td>23-03-1999</td>
                </tr>
                <tr>
                  <td>Religion</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Home Country Number</td>
                  <td>23232323</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="table-container-letter">
          <div className="table-content-letter">
            <Table
              responsive
              borderless
              className="text-center mb-0 emp-detail-table offer-table"
            >
              <tbody>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Offer
                  </h4>
                </tr>
                <tr>
                  <td>Position Offered</td>
                  <td>Ali Asif</td>
                </tr>
                <tr>
                  <td>Duty Hours</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Salary Period</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Basic Salary</td>
                  <td>PJK32323</td>
                </tr>
                <tr>
                  <td>Housing Allowance</td>
                  <td>Pakistani</td>
                </tr>
                <tr>
                  <td>Transportation Allowance</td>
                  <td>23-03-1999</td>
                </tr>
                <tr>
                  <td>Food Allowance</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Medical Insurance</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Iqama Renewal</td>
                  <td>23232323</td>
                </tr>
                <tr>
                  <td>Return Ticket</td>
                  <td>23232323</td>
                </tr>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <h4
                    className="sub-heading bg-transparent text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Other Comments & Special Instructions:
                  </h4>
                </tr>
                <tr style={{ gridTemplateColumns: "none" }}>
                  <ul className="bg-transparent">
                    <li>
                      1. Salary will be calculated on based of working hours
                    </li>
                    <li>
                      2. Overtime will have considered One equal to One (1=1)
                    </li>
                    <li>
                      3. First Month Salary will be security with the company.
                    </li>
                    <li>
                      4. Indiscipline will not be tolerated and will lead to
                      termination of contract without any pay.
                    </li>
                    <li>
                      5. If food is provided, food allowance will not be
                      provided.
                    </li>
                    <li>
                      6. 10% salary will be increased automatically on end of
                      second year to onward.
                    </li>
                    <li>
                      7. End of service benefit will be provided to employee as
                      per Saudi labor law.{" "}
                    </li>
                    <li>
                      8. Passport will be kept with company as a security of
                      worker.
                    </li>
                    <li>
                      9. We can transfer to any other division without
                      negotiation.
                    </li>
                  </ul>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td
                    className="sub-heading bg-transparent text-center text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employer
                  </td>
                  <td
                    className="sub-heading bg-transparent text-center text-uppercase mb-0"
                    style={{ fontSize: "14px", padding: "1px" }}
                  >
                    Employee
                  </td>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td style={{ height: "100px" }}>Sign:</td>
                  <td>Sign/Thumb:</td>
                </tr>
                <tr style={{ gridTemplateColumns: "2fr 2fr" }}>
                  <td>Date:</td>
                  <td>Date:</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </LetterFormatB>
    </div>
  );
};

export default WorkRefusalLetter;
