import { createSlice } from "@reduxjs/toolkit";
import { AccountChart, AccountChartId } from "../../interfaces/account-charts";

interface Index {
  accountChartsList: AccountChart[];
  accountChartsListLoading: boolean;
  accountChartsListError: string;
  accountChartsListPageNo: number;
  accountChartsListPageSize: number;
  accountChartsListTotalPages: number;
  type: string;
  showAccountChartsExportDropdown: boolean;
  showAccountChartsFilterDropdown: boolean;
  addAccountChartsLoading: boolean;
  accountChartsIds: AccountChartId[];
  accountChartsIdsLoading: boolean;
}

const initialState: Index = {
  accountChartsList: [],
  accountChartsListLoading: false,
  accountChartsListError: "",
  accountChartsListPageNo: 1,
  accountChartsListPageSize: 10,
  accountChartsListTotalPages: 0,
  type: "All",
  showAccountChartsExportDropdown: false,
  showAccountChartsFilterDropdown: false,
  addAccountChartsLoading: false,
  accountChartsIds: [],
  accountChartsIdsLoading: false,
};

const accountChartsSlice = createSlice({
  name: "accountCharts",
  initialState: initialState,
  reducers: {
    setAccountChartsList: (state, action) => {
      state.accountChartsList = action.payload;
    },
    setAccountChartsListLoading: (state, action) => {
      state.accountChartsListLoading = action.payload;
    },
    setAccountChartsListError: (state, action) => {
      state.accountChartsListError = action.payload;
    },
    setAccountChartsListPageNo: (state, action) => {
      state.accountChartsListPageNo = action.payload;
    },
    setAccountChartsListPageSize: (state, action) => {
      state.accountChartsListPageSize = action.payload;
    },
    setAccountChartsListTotalPages: (state, action) => {
      state.accountChartsListTotalPages = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setShowAccountChartsExportDropdown: (state, action) => {
      state.showAccountChartsExportDropdown = action.payload;
    },
    setShowAccountChartsFilterDropdown: (state, action) => {
      state.showAccountChartsFilterDropdown = action.payload;
    },
    setAddAccountChartsLoading: (state, action) => {
      state.addAccountChartsLoading = action.payload;
    },
    setAccountChartsIds: (state, action) => {
      state.accountChartsIds = action.payload;
    },
    setAccountChartsIdsLoading: (state, action) => {
      state.accountChartsIdsLoading = action.payload;
    },
  },
});

export const {
  setAccountChartsList,
  setAccountChartsListLoading,
  setAccountChartsListError,
  setAccountChartsListPageNo,
  setAccountChartsListPageSize,
  setAccountChartsListTotalPages,
  setType,
  setShowAccountChartsExportDropdown,
  setShowAccountChartsFilterDropdown,
  setAddAccountChartsLoading,
  setAccountChartsIds,
  setAccountChartsIdsLoading,
} = accountChartsSlice.actions;
export default accountChartsSlice.reducer;
