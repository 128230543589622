import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Billing } from "../../interfaces/account-charts";
import { Common } from "../../assets/images";

const ViewBill = () => {
  // const [print, setPrint] = useState(false);
  // const handlePrint = () => {
  //   setPrint(true);
  // };
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: Billing = location.state.data;

  // check if all status are approved in approvals array
  // const isApproved = statementData.approvals.every(
  //   (item) =>
  //     item.status === "Approved" || item.status === "Approved by Super Admin",
  // );

  return (
    <>
      <div className=" mt-2 section px-4 py-4">
        {/*{print ? (*/}
        {/*    <div>*/}
        {/*      <button*/}
        {/*          className="btn primary-button"*/}
        {/*          onClick={() => setPrint(false)}*/}
        {/*      >*/}
        {/*        Back*/}
        {/*      </button>*/}
        {/*      <EmployeeLeaveRequest item={statementData} />*/}
        {/*    </div>*/}
        {/*) : (*/}
        <Row>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 mb-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h1 className="heading mb-0">Bill Detail</h1>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billNo}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billDate
                      ? new Date(statementData.billDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.vendor}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Order Number
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.orderNumber || "N/A"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Due Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.dueDate
                      ? new Date(statementData.dueDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Subject
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.subject}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Is Recurring
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.isRecurring ? "Yes" : "No"}
                  </h5>
                </Form.Group>
              </Col>
              {statementData.isRecurring && (
                <>
                  <Col sm="4">
                    <Form.Group className="mb-5">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Repeat Every
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData.repeatEvery}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-5" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Starts on
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData.repeatOn
                          ? new Date(
                              statementData.repeatOn,
                            ).toLocaleDateString()
                          : ""}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-5" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Ends on
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData.neverExpires
                          ? "Never Expires"
                          : statementData.repeatOn
                            ? new Date(
                                statementData.repeatOn,
                              ).toLocaleDateString()
                            : ""}
                      </h5>
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Amount
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.totalAmount}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Note
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.note}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            {statementData.listItems.length > 0 ? (
              <>
                <h2 className="sub-heading mb-4">List Items:</h2>
                <div className="table-responsive mb-3">
                  <table className="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Item Description</th>
                        {/*<th>Account</th>*/}
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Discount</th>
                        <th>Tax %</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statementData.listItems.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.description}</td>
                          {/*<td>{item.account}</td>*/}
                          <td>{item.quantity}</td>
                          <td>{item.rate}</td>
                          <td>{item.discount + " " + item.discountType}</td>
                          <td>{item.taxPercentage}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">No list items</h1>
              </div>
            )}
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">Bill Approvals:</h2>
              <Row>
                {statementData.approvals.length > 0 ? (
                  <>
                    {statementData.approvals.map((item, index) => (
                      <div className="col-sm-4" key={index}>
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            {item.name} ({item.role})
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {item.status}{" "}
                            {item.status !== "Pending"
                              ? `on ${
                                  item.date
                                    ? new Date(item.date).toLocaleDateString()
                                    : ""
                                }`
                              : null}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Approvals
                    </h4>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          <Col sm="12">
            {statementData.attachments.length > 0 ? (
              <>
                <h2 className="sub-heading mb-4">Attachments:</h2>
                <div className="table-responsive mb-3">
                  <table className="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Date</th>
                        <th>File Title</th>
                        <th>Uploader Emp Id</th>
                        <th>Uploader Emp Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statementData.attachments.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.date
                              ? new Date(item.date).toLocaleDateString()
                              : "N/A"}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.empId}</td>
                          <td>{item.empName}</td>
                          <td>
                            <a href={item.link} download>
                              <img
                                src={Common.Download}
                                className="me-2"
                                width="24px"
                                height="24px"
                                alt="share"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">No Attachments</h1>
              </div>
            )}
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              {/*{isApproved && (*/}
              {/*    <button*/}
              {/*        className="btn primary-button w-25"*/}
              {/*        onClick={handlePrint}*/}
              {/*    >*/}
              {/*      Print*/}
              {/*    </button>*/}
              {/*)}*/}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
        {/*)}*/}
      </div>
    </>
  );
};
export default ViewBill;
