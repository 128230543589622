import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function withAuth<T extends object>(Component: React.ComponentType<T>) {
  const AuthenticatedComponent = (props: T) => {
    const accessTokenShort = sessionStorage.getItem("accessToken");
    const accessTokenLong = localStorage.getItem("accessToken");

    const navigate = useNavigate();

    useEffect(() => {
      if (!accessTokenShort && !accessTokenLong) {
        navigate("/login");
      }
    }, [accessTokenShort, navigate]);

    if (!accessTokenShort && !accessTokenLong) {
      return null;
    }

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
}

export default withAuth;
