import React from "react";
import InternalLayout from "../components/InternalLayout";
import NotificationsComponent from "../components/Notifications";
import withAuth from "../config/private-route";
import Content from "../components/Notifications/get-all-approvals-and-notifications-api";

const Notifications = () => {
  return (
    <InternalLayout>
      <Content />
      <NotificationsComponent />
    </InternalLayout>
  );
};
export default withAuth(Notifications);
