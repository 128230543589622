// Common Images
import Logo from "./common/logo.png";
import User from "./common/user.png";
import UserImg from "./authentication/user-img.png";
import Invoice from "./common/invoice.jpeg";
// Settings Images
import ProfileUserImg from "./settings/user-img.jpeg";
import PH from "./common/PH.png";

export const Common = {
  Logo: Logo,
  EyeOpen: require("./common/eye-open.svg").default,
  EyeClose: require("./common/eye-close.svg").default,
  ToastSuccess: require("./common/toast-success.svg").default,
  ToastError: require("./common/toast-error.svg").default,
  ToastInfo: require("./common/toast-info.svg").default,
  ToastWarning: require("./common/toast-warning.svg").default,
  SuccessTick: require("./common/success-tick.svg").default,
  ArrowRightBlue: require("./common/arrow-right-blue.svg").default,
  ArrowLeftWhite: require("./common/arrow-left-white.svg").default,
  BuildingWhite: require("./common/building.svg").default,
  UserTie: require("./common/user-tie.svg").default,
  Calendar: require("./common/calendar.svg").default,
  User: User,
  ArrowDown: require("./common/arrow-down.svg").default,
  UAE: require("./common/uae.svg").default,
  SA: require("./common/saudi.svg").default,
  PK: require("./common/pakistan.svg").default,
  IR: require("./common/iran.svg").default,
  BD: require("./common/bangladesh.svg").default,
  IN: require("./common/india.svg").default,
  AF: require("./common/afghanistan.svg").default,
  SuccessTickUnbordered: require("./common/success-tick-unbordered.svg")
    .default,
  Search: require("./common/search.svg").default,
  ArrowRightWhite: require("./common/arrow-right-white.svg").default,
  ArrowLeftBlue: require("./common/arrow-left-blue.svg").default,
  Plus: require("./common/plus.svg").default,
  Cross: require("./common/cross.svg").default,
  Print: require("./common/print.svg").default,
  Dot: require("./common/dot.svg").default,
  Invoice: Invoice,
  ArrowDownBlack: require("./common/arrow-down-black.svg").default,
  RedDot: require("./common/red-dot.svg").default,
  BlueDot: require("./common/blue-dot.svg").default,
  GreenDot: require("./common/green-dot.svg").default,
  OrangeDot: require("./common/orange-dot.svg").default,
  PDF: require("./common/pdf.svg").default,
  Download: require("./common/download.svg").default,
  Eye: require("./common/eye.svg").default,
  UserImgPlaceholder: require("./common/user-img.svg").default,
  LogoComplete: require("./common/logo-complete.svg").default,
  BluePrint: require("./common/print-blue.svg").default,
  LogoSVG: require("./common/logo-svg.svg").default,
  Vision: require("./common/vision.svg").default,
  FileIcon: require("./common/file-icon.svg").default,
  ImageIcon: require("./common/image-icon.svg").default,
  DoubleArrowLeft: require("./common/double-arrow-left.svg").default,
  DoubleArrowRight: require("./common/double-arrow-right.svg").default,
  PH: PH,
};

export const DashboardIcons = {
  UserPresent: require("./dashboard/user-present.svg").default,
  UserPresentHover: require("./dashboard/user-present-hover.svg").default,
  UserAbsent: require("./dashboard/user-absent.svg").default,
  UserAbsentHover: require("./dashboard/user-absent-hover.svg").default,
  UserLeave: require("./dashboard/user-leave.svg").default,
  UserLeaveHover: require("./dashboard/user-leave-hover.svg").default,
  User: require("./dashboard/user.svg").default,
  UserHover: require("./dashboard/user-hover.svg").default,
};

export const LoginIcons = {
  Password: require("./authentication/password.svg").default,
  Message: require("./authentication/message.svg").default,
  MessageWhite: require("./authentication/message-white.svg").default,
  ProfileInput: require("./authentication/profile-input.svg").default,
};

export const SidebarIcons = {
  ArrowCircleLeft: require("./sidebar/arrow-circle-left.svg").default,
  ArrowCircleRight: require("./sidebar/arrow-circle-right.svg").default,
  Logout: require("./sidebar/logout.svg").default,
  Home: require("./sidebar/home.svg").default,
  HomeBlue: require("./sidebar/home-blue.svg").default,
  Employee: require("./sidebar/employee.svg").default,
  InitialEvalution: require("./sidebar/initial-evalution.svg").default,
  InitialEvaluationBlue: require("./sidebar/initial-evaluation-blue.svg")
    .default,
  PettyCash: require("./sidebar/petty-cash.svg").default,
  PettyCashBlue: require("./sidebar/petty-cash-blue.svg").default,
  Iqama: require("./sidebar/iqama.svg").default,
  IqamaBlue: require("./sidebar/iqama-blue.svg").default,
  Absconding: require("./sidebar/absconding.svg").default,
  AbscondingBlue: require("./sidebar/absconding-blue.svg").default,
  Certificates: require("./sidebar/certificates.svg").default,
  CertificatesBlue: require("./sidebar/certificates-blue.svg").default,
  Acknowledgement: require("./sidebar/acknowledgement.svg").default,
  Project: require("./sidebar/project.svg").default,
  ProjectBlue: require("./sidebar/project-blue.svg").default,
  Settings: require("./sidebar/settings.svg").default,
  SettingsBlue: require("./sidebar/settings-blue.svg").default,
  Attedance: require("./sidebar/attendance.svg").default,
  AttendanceBlue: require("./sidebar/attendance-blue.svg").default,
  EmployeeWhite: require("./sidebar/employee-white.svg").default,
  Circle: require("./sidebar/circle.svg").default,
  Rectangle: require("./sidebar/rectangle.svg").default,
  Line: require("./sidebar/line.svg").default,
  UserImg: UserImg,
  Notifications: require("./sidebar/notification.svg").default,
  NotificationsWhite: require("./sidebar/notifications-white.svg").default,
  CampWhite: require("./sidebar/camp-white.svg").default,
  CampBlue: require("./sidebar/camp-blue.svg").default,
  ClientWhite: require("./sidebar/client-white.svg").default,
  ClientBlue: require("./sidebar/client-blue.svg").default,
  TimeSheetWhite: require("./sidebar/timesheet-white.svg").default,
  TimeSheetBlue: require("./sidebar/timesheet-blue.svg").default,
  HRBlue: require("./sidebar/hr-blue.svg").default,
  HRWhite: require("./sidebar/hr-white.svg").default,
  RecruiteBlue: require("./sidebar/recruiter-blue.svg").default,
  RecruiteWhite: require("./sidebar/recruiter-white.svg").default,
  CardsWhite: require("./sidebar/cards-white.svg").default,
  CardsBlue: require("./sidebar/cards-blue.svg").default,
  InsuranceWhite: require("./sidebar/insurance-white.svg").default,
  InsuranceBlue: require("./sidebar/insurance-blue.svg").default,
};

export const SettingsIcons = {
  UserImg: ProfileUserImg,
  EditIcon: require("./settings/edit.svg").default,
  CrossIcon: require("./settings/cross.svg").default,
  EditUser: require("./settings/edit-user.svg").default,
  Delete: require("./settings/delete.svg").default,
};

export const EmployeeIcons = {
  ArrowDownBlue: require("./employee/arrow-down-blue.svg").default,
  ArrowDownWhite: require("./employee/arrow-down-white.svg").default,
  Plus: require("./employee/plus.svg").default,
};

export const AttendanceIcons = {
  EmptyCircle: require("./attendance/empty-circle.svg").default,
  FilledCircle: require("./attendance/fill-circle.svg").default,
};

export const NotificationsIcons = {
  Readed: require("./notifications/readed.svg").default,
  Unreaded: require("./notifications/unread.svg").default,
  Delete: require("./notifications/delete.svg").default,
};

export const LettersIcons = {
  Phone: require("./letters/phone.svg").default,
  Mail: require("./letters/mail.svg").default,
  Location: require("./letters/location.svg").default,
};
