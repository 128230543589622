import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { SalaryTimeline } from "../../../interfaces/employee";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveTab,
  setEditTimeline,
  setEditIncrementTimeline,
} from "../../../store/slices/employeeSlice";
// API
import { LoaderWhite } from "../../Lotties";
import SalaryTimelineSet from "./salary-timeline-set";
import { useEditEmployeeTimelineMutation } from "../../../services/Employees";
import { setEmployeeChange } from "../../../store/slices/commonSlice";
import { ToastErrorComponent, ToastSuccessComponent } from "../../Toasts";

interface Props {
  timelineData: SalaryTimeline[];
  employeeID: number | null;
}

const EditSalaryTimeline = ({ timelineData, employeeID }: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [loading, setLoading] = useState(false);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      basicSalary: number;
      fromDate: Date;
      toDate: Date;
      dutyHours: number;
      submittedBy: {
        empId: number;
        empName: string;
        remarks: string;
      };
      allowances: {
        uId: string;
        title: string;
        amount: number;
        category: string;
      }[];
    }[]
  >(timelineData || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        basicSalary: 0,
        fromDate: new Date(),
        toDate: new Date(),
        dutyHours: 0,
        submittedBy: {
          empId: 0,
          empName: "",
          remarks: "",
        },
        allowances: [],
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };

  const handleLanguageInputChange = (
    index: string,
    data: {
      basicSalary: number;
      fromDate: Date;
      toDate: Date;
      dutyHours: number;
      allowances: {
        uId: string;
        title: string;
        amount: number;
        category: string;
      }[];
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <SalaryTimelineSet
        key={data.uId}
        data={data}
        index={index + 1}
        uniqueId={data.uId}
        timelineData={languageData}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Filter the changed data from the timelineData
  const updatedData = timelineData.map((data) => {
    const found = languageData.find((item) => item.uId === data.uId);
    if (found) {
      return found;
    }
    return data;
  });

  // Form Submit Function
  const [editEmployeeTimeline] = useEditEmployeeTimelineMutation();

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      accessToken: accessToken || "",
      userID: userID || "",
      permission: profileData.permissions.updateEmployee || false,
      payload: {
        empId: employeeID,
        type: "SalaryTimeline",
        timeline: languageData,
        updatedTimeline: updatedData,
      },
    };
    try {
      await editEmployeeTimeline(data).unwrap();
      dispatch(setEmployeeChange(!employeeChange));
      window.scrollTo(0, 0);
      setLoading(false);
      setSuccess(true);
    } catch (e: any) {
      if (e) {
        // Scroll to top
        window.scrollTo(0, 0);
        setLoading(false);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={success}
        setShow={setSuccess}
        content="Updated Successfully!"
      />

      <Row>
        <Col sm="12">
          <h3 className="sub-heading">Salary Timeline</h3>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Salary Timeline
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">{languageSets}</div>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center justify-content-between">
            <button
              className="btn secondary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditTimeline(true));
                dispatch(setEditIncrementTimeline(false));
                dispatch(setActiveTab("Projects Timeline"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              <img src={Common.ArrowLeftBlue} alt="next" />
              Back
            </button>
            <div className="w-100">
              <button
                className="btn primary-button normal-font-weight me-3"
                onClick={handleSubmit}
                style={{ width: "15%" }}
              >
                {loading ? <LoaderWhite height={30} width={30} /> : "Update"}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EditSalaryTimeline;
