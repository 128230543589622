import React from "react";
// Package Imports
import { Col, Form, Row } from "react-bootstrap";
// Redux Toolkit Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setUserIdentity,
  setUserInfo,
  setUserPassword,
  visiblePasswordState,
  visibleRepeatPasswordState,
} from "../../store/slices/authenticationSlice";
// Files Imports
import { Common, LoginIcons } from "../../assets/images";
import { LoaderBlue } from "../Lotties";

interface Props {
  handleChange: any;
  handleBlur: any;
  values: any;
  touched: any;
  errors: any;
  handleSubmit: any;
  isLoading: boolean;
}

const UserPassword = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  handleSubmit,
  isLoading,
}: Props) => {
  const dispatch = useAppDispatch();
  const { visiblePasswords, visibleRepeatPasswords, passwordError } =
    useAppSelector((state) => state.authentication);

  const handleNextBtn = () => {
    if (
      errors.email ||
      errors.setPassword ||
      errors.retypePassword ||
      errors.terms ||
      errors.role ||
      errors.department ||
      errors.dob ||
      errors.firstName ||
      errors.lastName ||
      errors.phNo
    ) {
      dispatch(setUserPassword(false));
      dispatch(setUserInfo(false));
      dispatch(setUserIdentity(true));
    }
  };

  const handleBackBtn = () => {
    dispatch(setUserInfo(true));
    dispatch(setUserPassword(false));
  };

  return (
    <Row className="w-75 mx-auto">
      <Col lg="12">
        <Form.Group className="mb-4">
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <img src={LoginIcons.Password} alt="message" />
            <Form.Control
              type={visiblePasswords ? "text" : "password"}
              name="setPassword"
              onChange={handleChange}
              placeholder="Set password"
              onBlur={handleBlur}
              value={values.setPassword}
              className={
                touched.setPassword && errors.setPassword ? "is-invalid" : ""
              }
            />
            <button
              className="btn p-0 border-0 show-password-icon"
              onClick={() => dispatch(visiblePasswordState(!visiblePasswords))}
              type="button"
            >
              {!visiblePasswords ? (
                <img src={Common.EyeClose} alt="eye-open" />
              ) : (
                <img src={Common.EyeOpen} alt="eye-open" />
              )}
            </button>
          </Form.Group>
          {passwordError ? (
            <div className="invalid-feedback">{passwordError}</div>
          ) : (
            <>
              {touched.setPassword && errors.setPassword ? (
                <div className="invalid-feedback">{errors.setPassword}</div>
              ) : null}
            </>
          )}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <img src={LoginIcons.Password} alt="message" />
            <Form.Control
              type={visibleRepeatPasswords ? "text" : "password"}
              name="retypePassword"
              onChange={handleChange}
              placeholder="Re-enter password"
              onBlur={handleBlur}
              value={values.retypePassword}
              className={
                touched.retypePassword && errors.retypePassword
                  ? "is-invalid"
                  : ""
              }
            />
            <button
              className="btn p-0 border-0 show-password-icon"
              onClick={() =>
                dispatch(visibleRepeatPasswordState(!visibleRepeatPasswords))
              }
              type="button"
            >
              {!visibleRepeatPasswords ? (
                <img src={Common.EyeClose} alt="eye-open" />
              ) : (
                <img src={Common.EyeOpen} alt="eye-open" />
              )}
            </button>
          </Form.Group>

          {touched.retypePassword && errors.retypePassword ? (
            <div className="invalid-feedback">{errors.retypePassword}</div>
          ) : null}
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="I agree to the terms and conditions as set out by the user agreement."
            name="terms"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.terms}
            className={touched.terms && errors.terms ? "is-invalid" : ""}
          />
          {touched.terms && errors.terms ? (
            <div className="invalid-feedback">{errors.terms}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col lg="12">
        <div className="mt-4 d-flex justify-content-between gap-5">
          <button
            type="button"
            onClick={handleBackBtn}
            className="btn secondary-button nunito-font bg-blacks hover-effect text-white d-flex align-items-center w-100 justify-content-center"
          >
            <img
              src={Common.ArrowLeftWhite}
              className="me-2"
              alt="arrow-blue"
            />
            <span>Back</span>
          </button>
          <button
            type="submit"
            onClick={() => {
              handleNextBtn();
              handleSubmit();
            }}
            className="btn secondary-button nunito-font d-flex align-items-center w-100 justify-content-center"
          >
            {isLoading ? (
              <LoaderBlue height={30} width={30} />
            ) : (
              <>
                <span>Finish</span>{" "}
                <img
                  src={Common.ArrowRightBlue}
                  className="ms-2"
                  alt="arrow-blue"
                />
              </>
            )}
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default UserPassword;
