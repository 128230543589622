import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { DashboardIcons } from "../../assets/images";
import { format } from "date-fns";
import PresentEmployeesRatio from "./charts/present-employees-ratio";
import AbsentEmployeesRatio from "./charts/absent-employees-ratio";
import RecruitedEmployeesRatio from "./charts/recruited-employees-ratio";
import ProjectDetailGraph from "./charts/project-detail-graph";
import FiredEmployeesRatio from "./charts/fired-employees-ratio";
import AbscondingEmployeesRatio from "./charts/absconding-employees-ratio";
import { useAppSelector } from "../../store/hooks";

const DashboardComponent = () => {
  const state = useAppSelector((state) => state.dashboard.dashboardData);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [hover, setHover] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="dashboard">
      <Container fluid>
        <div className="mt-2 section px-4 py-4">
          <div className="section-heading d-flex align-items-center justify-content-between">
            <h1 className="heading mb-0">HR Management System</h1>
            <div className="">
              <div className="d-flex align-items-center">
                <h4
                  className="me-3 sub-heading normal-font-weight text-black"
                  style={{ fontSize: "18px" }}
                >
                  Date:
                </h4>
                <h4
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  {format(currentTime, "dd, MMMM yyyy")}
                </h4>
              </div>
              <div className="d-flex align-items-center">
                <h4
                  className="sub-heading me-3 normal-font-weight text-black"
                  style={{ fontSize: "18px" }}
                >
                  Local Time:
                </h4>
                <h4
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  {currentTime.toLocaleTimeString()}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="modules-short-detail">
          <Row className="align-items-center">
            <Col sm="4">
              <div className="mt-4">
                <ProjectDetailGraph />
              </div>
            </Col>
            <Col sm="8">
              <Row>
                <Col sm="6">
                  <div
                    className="modules-short-detail-item mt-4 section px-4 py-4 d-flex align-items-center justify-content-between"
                    onMouseEnter={() => setHover("total")}
                    onMouseLeave={() => setHover("")}
                  >
                    <div>
                      <h4 className="sub-heading" style={{ fontSize: "30px" }}>
                        {state.attendanceData.totalEmployees}
                      </h4>
                      <p
                        className="para-medium"
                        style={{ color: "#20202080", fontSize: "18px" }}
                      >
                        Total Employees
                      </p>
                    </div>
                    {hover === "total" ? (
                      <img
                        src={DashboardIcons.UserHover}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    ) : (
                      <img
                        src={DashboardIcons.User}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div
                    className="modules-short-detail-item mt-4 section px-4 py-4 d-flex align-items-center justify-content-between"
                    onMouseEnter={() => setHover("present")}
                    onMouseLeave={() => setHover("")}
                  >
                    <div>
                      <h4 className="sub-heading" style={{ fontSize: "30px" }}>
                        {state.attendanceData.presentEmployees}
                      </h4>
                      <p
                        className="para-medium"
                        style={{ color: "#20202080", fontSize: "18px" }}
                      >
                        Present Employees
                      </p>
                    </div>
                    {hover === "present" ? (
                      <img
                        src={DashboardIcons.UserPresentHover}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    ) : (
                      <img
                        src={DashboardIcons.UserPresent}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div
                    className="modules-short-detail-item mt-4 section px-4 py-4 d-flex align-items-center justify-content-between"
                    onMouseEnter={() => setHover("absent")}
                    onMouseLeave={() => setHover("")}
                  >
                    <div>
                      <h4 className="sub-heading" style={{ fontSize: "30px" }}>
                        {state.attendanceData.absentEmployees}
                      </h4>
                      <p
                        className="para-medium"
                        style={{ color: "#20202080", fontSize: "18px" }}
                      >
                        Absent Employees
                      </p>
                    </div>
                    {hover === "absent" ? (
                      <img
                        src={DashboardIcons.UserAbsentHover}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    ) : (
                      <img
                        src={DashboardIcons.UserAbsent}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div
                    className="modules-short-detail-item mt-4 section px-4 py-4 d-flex align-items-center justify-content-between"
                    onMouseEnter={() => setHover("leave")}
                    onMouseLeave={() => setHover("")}
                  >
                    <div>
                      <h4 className="sub-heading" style={{ fontSize: "30px" }}>
                        {state.attendanceData.leaveEmployees}
                      </h4>
                      <p
                        className="para-medium"
                        style={{ color: "#20202080", fontSize: "18px" }}
                      >
                        Employees on leave
                      </p>
                    </div>
                    {hover === "leave" ? (
                      <img
                        src={DashboardIcons.UserLeaveHover}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    ) : (
                      <img
                        src={DashboardIcons.UserLeave}
                        width="60px"
                        height="60px"
                        alt="user"
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr
            style={{ borderColor: "rgba(32, 32, 32, 0.6)", margin: "3rem 0" }}
          />
          <Row>
            <Col sm="6">
              <PresentEmployeesRatio />
            </Col>
            <Col sm="6">
              <AbsentEmployeesRatio />
            </Col>
            <Col sm="12">
              <RecruitedEmployeesRatio />
            </Col>
            <Col sm="6">
              <FiredEmployeesRatio />
            </Col>
            <Col sm="6">
              <AbscondingEmployeesRatio />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default DashboardComponent;
