import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Letters
import { useGetOfferLettersQuery } from "../../services/Letters";
import {
  setOfferLetterLoading,
  setOfferLetterError,
  setOfferLetter,
  setOfferLetterTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const offerLetterChange = useAppSelector(
    (state) => state.common.offerLetterChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Candidates
  // **************
  // **************
  const {
    data: allOfferLetter,
    isLoading: offerLetterLoading,
    isError: offerLetterError,
    refetch: offerLetterRefetch,
  } = useGetOfferLettersQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.offerLetterPageNo,
    limit: state.offerLetterPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (offerLetterLoading) {
      dispatch(setOfferLetterLoading(true));
    }
    if (offerLetterError) {
      dispatch(setOfferLetterError("Something went wrong"));
      dispatch(setOfferLetterLoading(false));
    }
    if (allOfferLetter) {
      dispatch(setOfferLetter(allOfferLetter.data.candidates[0].data || []));
      dispatch(
        setOfferLetterTotalPages(
          allOfferLetter.data.candidates[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setOfferLetterLoading(false));
    }
  }, [
    accessToken,
    userID,
    allOfferLetter,
    profileData,
    state.offerLetterPageNo,
    state.offerLetterPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    offerLetterRefetch();
  }, [offerLetterChange]);
  return <></>;
};
export default Content;
