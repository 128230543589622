import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAddLeaveRequestLoading } from "../../../store/slices/lettersSlice";
import { setLeaveRequestChange } from "../../../store/slices/commonSlice";
// API imports
import { useAddLeaveRequestMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";
import ApprovalsSet from "../approvals-set";

const LeaveRequest = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = useState("");
  const [lastSalaryStatus, setLastSalaryStatus] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [showGuaranters, setShowGuaranters] = useState(false); // [false, false
  const [guaranter1, setGuaranter1] = useState("");
  const [guaranter2, setGuaranter2] = useState("");
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const leaveRequestChange = useAppSelector(
    (state) => state.common.leaveRequestChange,
  );

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // get all userId from languageData array
  const userIds =
    languageData.length > 0 ? languageData.map((data) => data.userId) : [];

  // API Calls
  const [addLeaveRequest] = useAddLeaveRequestMutation();

  const handleSubmit = async () => {
    dispatch(setAddLeaveRequestLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: empID,
          leaveCategory: leaveType,
          leaveFrom: leaveFrom,
          leaveTo: leaveTo,
          lastSalaryStatus: lastSalaryStatus,
          link: `/hr/leave-requests`,
          approvals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...languageData,
          ],
          ids: [superadmin?._id || "", ...userIds],
          guaranters: showGuaranters
            ? guaranter1 && !guaranter2
              ? [parseInt(guaranter1)]
              : guaranter2 && !guaranter1
                ? [parseInt(guaranter2)]
                : [parseInt(guaranter1), parseInt(guaranter2)]
            : [],
        },
      };
      await addLeaveRequest(data).unwrap();
      dispatch(setLeaveRequestChange(!leaveRequestChange));
      navigate("/hr/leave-requests");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddLeaveRequestLoading(false));
    }
  };
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Employee Leave Request</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Select Employee by Id
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={options}
                value={empID}
                onChange={(e: any) => setEmpID(e)}
                search={true}
                placeholder="Select Employee by Id"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Financial Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Last Salary Status
                  </h4>
                  <Form.Select
                    name="leaveType"
                    value={lastSalaryStatus}
                    onChange={(e) => setLastSalaryStatus(e.target.value)}
                    className="custom-input border-fill"
                  >
                    <option value="">Select Last Salary Status</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-4">
            <h4 className="sub-heading mb-4">Leave Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave Type
                  </h4>
                  <Form.Select
                    name="leaveType"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                    className="custom-input border-fill"
                  >
                    <option value="">Select Leave Type</option>
                    <option value="Emergency">Emergency</option>
                    <option value="Annual">Annual</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave From
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveFrom"
                    placeholder="Enter passport expiry from"
                    onChange={(e) => setLeaveFrom(e.target.value)}
                    value={leaveFrom}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Leave To
                  </h4>
                  <Form.Control
                    type="date"
                    name="leaveTo"
                    placeholder="Enter passport expiry to"
                    onChange={(e) => setLeaveTo(e.target.value)}
                    value={leaveTo}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="emp-detail-print mt-5 mb-5">
                  <button
                    className="btn primary-button w-25"
                    onClick={() => setShowGuaranters(!showGuaranters)}
                  >
                    {showGuaranters ? "Remove Guaranters" : "Add Guaranters"}
                  </button>
                </div>
              </Col>
            </Row>
            {showGuaranters && (
              <Row>
                <Col sm="4">
                  <div className="search-emp mb-4">
                    <h4 className="sub-heading normal-font-weight textBlack">
                      Guarantor One:
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        value={guaranter1}
                        onChange={(e: any) => setGuaranter1(e)}
                        search={true}
                        placeholder="Select Employee by Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="search-emp mb-4">
                    <h4 className="sub-heading normal-font-weight textBlack">
                      Guarantor Two:
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        options={options}
                        value={guaranter2}
                        onChange={(e: any) => setGuaranter2(e)}
                        search={true}
                        placeholder="Select Employee by Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Approval user
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">
            {languageSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Approval will be sent to these users:
              </h4>
            )}
            {languageSets}
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addLeaveRequestLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LeaveRequest;
