import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const ClientsComponent = () => {
  return (
    <div className="employees">
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
};
export default ClientsComponent;
