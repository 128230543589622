import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, SettingsIcons } from "../../assets/images";
import AttachmentSet from "../Employees/AddEmployee/attachments";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddVendorLoading } from "../../store/slices/vendorsSlice";
import { setVendorChange } from "../../store/slices/commonSlice";
// API
import { useAddVendorMutation } from "../../services/AccountCharts";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

export interface AddVendor {
  vendorName: string;
  vendorNameArabic: string;
  contactPersonName: string;
  contactPersonDesignation: string;
  vendorAddress: string;
  vendorAddressArabic: string;
  city: string;
  country: string;
  postalCode: string;
  phone: string;
  numberVAT: string;
  numberCR: string;
  vendorStatus: string;
  contactPersonPhone: string;
  companyName: string;
  shippingAddress: string;
  shippingAddressArabic: string;
}

const initialValues: AddVendor = {
  vendorName: "",
  vendorNameArabic: "",
  contactPersonName: "",
  contactPersonDesignation: "",
  vendorAddress: "",
  vendorAddressArabic: "",
  city: "",
  country: "",
  postalCode: "",
  phone: "",
  numberVAT: "",
  numberCR: "",
  vendorStatus: "",
  contactPersonPhone: "",
  companyName: "",
  shippingAddress: "",
  shippingAddressArabic: "",
};

const AddVendor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.vendors);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [showAttachment, setShowAttachment] = useState(false);
  const vendorChange = useAppSelector((state) => state.common.vendorChange);
  const [email, setEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  const [addVendor] = useAddVendorMutation();
  const handleSubmit = async (values: AddVendor) => {
    dispatch(setAddVendorLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          vendorName: values.vendorName,
          companyName: values.companyName,
          vendorNameArabic: values.vendorNameArabic,
          contactPersonName: values.contactPersonName,
          contactPersonDesignation: values.contactPersonDesignation,
          contactPersonPhone: values.contactPersonPhone,
          vendorAddress: values.vendorAddress,
          vendorAddressArabic: values.vendorAddressArabic,
          // city: values.city,
          // country: values.country,
          numberCR: parseInt(values.numberCR),
          postalCode: parseInt(values.postalCode),
          numberVAT: parseInt(values.numberVAT),
          phone: values.phone,
          vendorStatus: values.vendorStatus,
          vendorAttachments: languageData,
          emails: selectedEmails,
          shippingAddress: values.shippingAddress,
          shippingAddressArabic: values.shippingAddressArabic,
          link: "/vendors",
        },
      };
      await addVendor(data).unwrap();
      dispatch(setVendorChange(!vendorChange));
      navigate("/vendors");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddVendorLoading(false));
    }
  };

  const handleEmail = () => {
    //   add email in the list if it already exists then remvoe it
    if (email === "") {
      setErrorContent("Please enter email");
      setShow(true);
      return;
    }
    if (selectedEmails.includes(email)) {
      setErrorContent("Email already exists");
      setShow(true);
      return;
    }
    setSelectedEmails([...selectedEmails, email]);
    setEmail("");
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Add Vendor </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showAttachment && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Vendor Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="vendorName"
                          onChange={handleChange}
                          placeholder="Enter vendor name"
                          value={values.vendorName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.vendorName && errors.vendorName ? (
                          <div className="invalid-feedback">
                            {errors.vendorName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Vendor Name in Arabic
                        </h4>
                        <Form.Control
                          type="text"
                          name="vendorNameArabic"
                          onChange={handleChange}
                          placeholder="Enter vendor name in arabic"
                          value={values.vendorNameArabic}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          placeholder="Enter company name"
                          value={values.companyName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.companyName && errors.companyName ? (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Vendor's Contact No. *
                        </h4>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter vendor's contact no."
                          value={values.phone}
                          className="custom-input border-fill"
                        />
                        {touched.phone && errors.phone ? (
                          <div className="invalid-feedback">{errors.phone}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonName"
                          onChange={handleChange}
                          placeholder="Enter contact person name"
                          value={values.contactPersonName}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonName &&
                        errors.contactPersonName ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Designation
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonDesignation"
                          placeholder="Enter contact person designation"
                          onChange={handleChange}
                          value={values.contactPersonDesignation}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonDesignation &&
                        errors.contactPersonDesignation ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonDesignation}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact person phone
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonPhone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter contact person phone number"
                          required={true}
                          value={values.contactPersonPhone}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonPhone &&
                        errors.contactPersonPhone ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonPhone}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      City*/}
                    {/*    </h4>*/}
                    {/*    <Form.Control*/}
                    {/*      type="text"*/}
                    {/*      name="city"*/}
                    {/*      placeholder="Enter city"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.city}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    />*/}
                    {/*    {touched.city && errors.city ? (*/}
                    {/*      <div className="invalid-feedback">{errors.city}</div>*/}
                    {/*    ) : null}*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      Country*/}
                    {/*    </h4>*/}
                    {/*    <Form.Select*/}
                    {/*      name="country"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.country}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    >*/}
                    {/*      <option value="">Select Country</option>*/}
                    {/*      {countryCodes.map((item, index) => (*/}
                    {/*        <option key={index} value={item.name}>*/}
                    {/*          {item.name}*/}
                    {/*        </option>*/}
                    {/*      ))}*/}
                    {/*    </Form.Select>*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Postal Code
                        </h4>
                        <Form.Control
                          type="text"
                          name="postalCode"
                          placeholder="Enter postal code"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.postalCode}
                          className="custom-input border-fill"
                        />
                        {touched.city && errors.city ? (
                          <div className="invalid-feedback">{errors.city}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          VAT Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberVAT"
                          placeholder="Enter VAT number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberVAT}
                          required={true}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          C.R. Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberCR"
                          placeholder="Enter CR number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberCR}
                          required={true}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Vendor Status
                        </h4>
                        <Form.Select
                          name="vendorStatus"
                          onChange={handleChange}
                          value={values.vendorStatus}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Vendor Status</option>
                          <option value="Active">Active</option>
                          <option value="In-active">In-active</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="8">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="custom-input border-fill"
                          />
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            onClick={handleEmail}
                            style={{ width: "25%" }}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Address *
                        </h4>
                        <textarea
                          name="vendorAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendorAddress}
                          required={true}
                          placeholder="Add address"
                          className={
                            touched.vendorAddress && errors.vendorAddress
                              ? "is-invalid border-fill w-100"
                              : "border-fill w-100"
                          }
                          rows={6}
                          style={{ resize: "none" }}
                        />
                        {touched.vendorAddress && errors.vendorAddress ? (
                          <div className="invalid-feedback">
                            {errors.vendorAddress}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Address in arabic
                        </h4>
                        <textarea
                          name="vendorAddressArabic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendorAddressArabic}
                          placeholder="Add address in arabic"
                          className="border-fill w-100"
                          rows={6}
                          style={{ resize: "none" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Shipping Address *
                        </h4>
                        <textarea
                          name="shippingAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.shippingAddress}
                          required={true}
                          placeholder="Add shipping address"
                          className={
                            touched.shippingAddress && errors.shippingAddress
                              ? "is-invalid border-fill w-100"
                              : "border-fill w-100"
                          }
                          rows={6}
                          style={{ resize: "none" }}
                        />
                        {touched.shippingAddress && errors.shippingAddress ? (
                          <div className="invalid-feedback">
                            {errors.shippingAddress}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Shipping Address in arabic
                        </h4>
                        <textarea
                          name="shippingAddressArabic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.shippingAddressArabic}
                          placeholder="Add address in arabic"
                          className="border-fill w-100"
                          rows={6}
                          style={{ resize: "none" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email List
                        </h4>
                        {selectedEmails.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEmails.map(
                                (item: string, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.toLocaleLowerCase()}</td>
                                    <td>
                                      <button
                                        className="btn p-0 border-0"
                                        type="button"
                                        onClick={() => {
                                          const updatedEmails =
                                            selectedEmails.filter(
                                              (email) => email !== item,
                                            );
                                          setSelectedEmails(updatedEmails);
                                        }}
                                      >
                                        <img
                                          src={SettingsIcons.Delete}
                                          width="24px"
                                          height="24px"
                                          alt="edit-user"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">No emails added</div>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn primary-button w-25"
                          onClick={() => {
                            if (values.vendorName === "") {
                              setErrorContent("Vendor Name is required");
                              setShow(true);

                              return;
                            }
                            if (values.phone === "") {
                              setErrorContent("Vendor Contact no. is required");
                              setShow(true);

                              return;
                            }
                            if (values.companyName === "") {
                              setErrorContent("Company name is required");
                              setShow(true);

                              return;
                            }
                            if (values.numberVAT === "") {
                              setErrorContent("VAT number is required");
                              setShow(true);

                              return;
                            }
                            if (values.numberCR === "") {
                              setErrorContent("C.R. number is required");
                              setShow(true);

                              return;
                            }
                            if (values.vendorAddress === "") {
                              setErrorContent("Vendor address is required");
                              setShow(true);

                              return;
                            }
                            setShowAttachment(true);
                          }}
                        >
                          Next
                          <img src={Common.ArrowRightWhite} alt="arrow" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {showAttachment && (
                  <Row>
                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachments
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">{languageSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-between mt-4">
                        <button
                          className="btn secondary-button w-25"
                          type="button"
                          onClick={() => {
                            setShowAttachment(false);
                          }}
                        >
                          <img
                            src={Common.ArrowLeftBlue}
                            className="me-1"
                            alt="next"
                          />{" "}
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn primary-button w-25"
                        >
                          {state.addVendorLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Add Vendor"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddVendor;
