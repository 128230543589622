import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AddEmployeeProps } from "./add-employee-container";
import { Common } from "../../../assets/images";
import AttachmentSet from "./attachments";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEvaluationDetails,
  setAgreementDetails,
  setAttachments,
} from "../../../store/slices/employeeSlice";
// API
import { LoaderWhite } from "../../Lotties";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const AddAttachments = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const profileData = useAppSelector((state) => state.settings.profileData);
  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  useEffect(() => {
    values.attachment = languageData;
  }, [languageData]);

  console.log("languageData", languageData);

  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Attachments</h3>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center gap-4">
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={handleAddLanguageSet}
            style={{ width: "15%" }}
          >
            <img
              src={Common.Plus}
              width="20px"
              height="20px"
              className="me-2"
              alt="next"
            />{" "}
            Attachments
          </button>
        </div>
      </Col>
      <Col sm="12">
        <div className="extra-detail my-4">{languageSets}</div>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setAgreementDetails(true));
              dispatch(setAttachments(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <div className="w-100">
            <button
              className="btn primary-button normal-font-weight"
              onClick={() => {
                dispatch(setAttachments(false));
                dispatch(setEvaluationDetails(true));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              Next <img src={Common.ArrowRightWhite} alt="next" />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AddAttachments;
