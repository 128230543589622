import { createSlice } from "@reduxjs/toolkit";
import { Sponsor, SponsorId } from "../../interfaces/sponsor";

interface Index {
  sponsorList: Sponsor[];
  sponsorListLoading: boolean;
  sponsorListError: string;
  sponsorListPageNo: number;
  sponsorListPageSize: number;
  sponsorListTotalPages: number;
  filterSponsorPageNo: number;
  filterSponsorPageSize: number;
  filterSponsorTotalPages: number;
  showSponsorExportDropdown: boolean;
  addSponsorLoading: boolean;
  sponsorIds: SponsorId[];
  sponsorIdsLoading: boolean;
  sponsorIdsError: string;
}

const initialState: Index = {
  sponsorList: [],
  sponsorListLoading: false,
  sponsorListError: "",
  sponsorListPageNo: 1,
  sponsorListPageSize: 10,
  sponsorListTotalPages: 0,
  filterSponsorPageNo: 1,
  filterSponsorPageSize: 10,
  filterSponsorTotalPages: 0,
  showSponsorExportDropdown: false,
  addSponsorLoading: false,
  sponsorIds: [],
  sponsorIdsLoading: false,
  sponsorIdsError: "",
};

const sponsorSlice = createSlice({
  name: "sponsor",
  initialState: initialState,
  reducers: {
    setSponsorListLoading: (state, action) => {
      state.sponsorListLoading = action.payload;
    },
    setSponsorListError: (state, action) => {
      state.sponsorListError = action.payload;
    },
    setSponsorList: (state, action) => {
      state.sponsorList = action.payload;
    },
    setSponsorListPageNo: (state, action) => {
      state.sponsorListPageNo = action.payload;
    },
    setSponsorListPageSize: (state, action) => {
      state.sponsorListPageSize = action.payload;
    },
    setSponsorListTotalPages: (state, action) => {
      state.sponsorListTotalPages = action.payload;
    },
    setFilterSponsorPageNo: (state, action) => {
      state.filterSponsorPageNo = action.payload;
    },
    setFilterSponsorPageSize: (state, action) => {
      state.filterSponsorPageSize = action.payload;
    },
    setFilterSponsorTotalPages: (state, action) => {
      state.filterSponsorTotalPages = action.payload;
    },
    setShowSponsorExportDropdown: (state, action) => {
      state.showSponsorExportDropdown = action.payload;
    },
    setAddSponsorLoading: (state, action) => {
      state.addSponsorLoading = action.payload;
    },
    setSponsorsIds: (state, action) => {
      state.sponsorIds = action.payload;
    },
    setSponsorsIdsLoading: (state, action) => {
      state.sponsorIdsLoading = action.payload;
    },
    setSponsorsIdsError: (state, action) => {
      state.sponsorIdsError = action.payload;
    },
  },
});

export const {
  setSponsorListLoading,
  setSponsorListError,
  setSponsorList,
  setSponsorListPageNo,
  setSponsorListPageSize,
  setSponsorListTotalPages,
  setFilterSponsorPageNo,
  setFilterSponsorPageSize,
  setFilterSponsorTotalPages,
  setShowSponsorExportDropdown,
  setAddSponsorLoading,
  setSponsorsIds,
  setSponsorsIdsLoading,
  setSponsorsIdsError,
} = sponsorSlice.actions;
export default sponsorSlice.reducer;
