import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetFinanceBillsListQuery } from "../../services/AccountCharts";
import {
  setVendorBill,
  setVendorBillError,
  setVendorBillLoading,
  setVendorBillTotalRecords,
} from "../../store/slices/vendorsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const billChange = useAppSelector((state) => state.common.billChange);
  // States
  const state = useAppSelector((state) => state.vendors);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allVendorBillIds,
    isLoading: vendorBillIdsLoading,
    isError: vendorBillIdsError,
    refetch: vendorBillIdsRefetch,
  } = useGetFinanceBillsListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    empIds: state.searchIds,
    fromDate: new Date(state.billStartFrom).toLocaleDateString(),
    toDate: new Date(state.billEndTo).toLocaleDateString(),
    pageNo: state.vendorBillPageNo,
    pageSize: state.vendorBillPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (vendorBillIdsLoading) {
      dispatch(setVendorBillLoading(true));
    }
    if (vendorBillIdsError) {
      dispatch(setVendorBillError("Something went wrong"));
      dispatch(setVendorBillLoading(false));
    }
    if (allVendorBillIds) {
      dispatch(
        setVendorBill(allVendorBillIds.data.filteredBills[0].data || []),
      );
      dispatch(
        setVendorBillTotalRecords(
          allVendorBillIds.data.filteredBills[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setVendorBillLoading(false));
    }
  }, [
    accessToken,
    userID,
    allVendorBillIds,
    profileData,
    state.searchIds,
    state.billStartFrom,
    state.billEndTo,
    state.vendorBillPageNo,
    state.vendorBillPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    vendorBillIdsRefetch();
  }, [
    billChange,
    state.searchIds,
    state.billStartFrom,
    state.billEndTo,
    state.vendorBillPageNo,
    state.vendorBillPageSize,
  ]);
  return <></>;
};
export default Content;
