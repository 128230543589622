import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetAllExpensesQuery } from "../../services/Letters";
import {
  setExpenses,
  setExpensesLoading,
  setExpensesError,
  setExpensesTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const violationsChange = useAppSelector(
    (state) => state.common.violationsChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Expenses
  // **************
  // **************
  const {
    data: allExpenses,
    isLoading: expensesLoading,
    isError: expensesError,
    refetch: expensesRefetch,
  } = useGetAllExpensesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.expensesPageNo,
    limit: state.expensesPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (expensesLoading) {
      dispatch(setExpensesLoading(true));
    }
    if (expensesError) {
      dispatch(setExpensesError("Something went wrong"));
      dispatch(setExpensesLoading(false));
    }
    if (allExpenses) {
      dispatch(setExpenses(allExpenses.data.expenses[0].data || []));
      dispatch(
        setExpensesTotalPages(
          allExpenses.data.expenses[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setExpensesLoading(false));
    }
  }, [
    accessToken,
    userID,
    allExpenses,
    profileData,
    state.expensesPageNo,
    state.expensesPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    expensesRefetch();
  }, [violationsChange]);
  return <></>;
};
export default Content;
