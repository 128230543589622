import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form, Dropdown, DropdownMenu } from "react-bootstrap";
import { Formik } from "formik";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddAccountChartsLoading } from "../../store/slices/accountChartsSlice";
import { setAccountChartsChange } from "../../store/slices/commonSlice";
// API
import {
  useAddAccountChartMutation,
  useGetSubAccountChartListMutation,
} from "../../services/AccountCharts";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { EmployeeIcons } from "../../assets/images";
import { AccountChart } from "../../interfaces/account-charts";

export interface AddAccount {
  accountName: string;
  accountType: string;
  description: string;
  bankAccountNumber: string;
}

const initialValues: AddAccount = {
  accountName: "",
  accountType: "",
  description: "",
  bankAccountNumber: "",
};

const AddAccountChart = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.accountCharts);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [loading, setLoading] = useState<boolean>(false);
  const [accountCategory, setAccountCategory] = useState<string>("");
  const [parentAccountID, setParentAccountID] = useState<string>("");
  const [subAccountType, setSubAccountType] = useState<string>("");
  const [showSubAccountType, setShowSubAccountType] = useState<boolean>(false);
  const accountChartsChange = useAppSelector(
    (state) => state.common.accountChartsChange,
  );
  const [accountExists, setAccountExists] = useState<AccountChart[]>([]);

  const [addAccountChart] = useAddAccountChartMutation();
  const handleSubmit = async (values: AddAccount) => {
    dispatch(setAddAccountChartsLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          link: `/account-charts`,
          accountName: values.accountName,
          accountType: values.accountType,
          parentAccountName: subAccountType,
          parentAccountId: parentAccountID,
          description: values.description,
          accountCategory: accountCategory,
          bankAccountNumber:
            values.accountType === "Bank" ? values.bankAccountNumber : "",
        },
      };
      await addAccountChart(data).unwrap();
      dispatch(setAccountChartsChange(!accountChartsChange));
      navigate("/account-charts");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddAccountChartsLoading(false));
    }
  };

  const [getSubAccountChartList] = useGetSubAccountChartListMutation();

  const handleAccountsExists = async () => {
    setLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          link: `/account-charts`,
          type: accountCategory,
        },
      };
      const response = await getSubAccountChartList(data).unwrap();
      if (response) {
        setAccountExists(response.data.allSubAccounts);
      }
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const otherCurrentAsset = [
    "Advance Tax",
    "Employee Advance",
    "Prepaid Expenses",
  ];
  const cash = ["Petty Cash", "Undeposited Funds"];
  const fixedAsset = ["Furniture and Equipment"];
  const otherCurrentLiability = ["Employee Reimbursements"];
  const equity = ["Opening Balance Offset", "Drawings", "Owner's Equity"];
  const income = ["Sales", "Interest Income", "General Income"];
  const expense = [
    "Consultant Expense",
    "Advertising and Marketing",
    "Credit Card Charges",
    "Depreciation Expense",
    "Janitorial Expense",
    "IT and Internet Expenses",
    "Office Supplies",
    "Meals and Entertainment",
    "Other Expenses",
    "Telephone Expense",
    "Travel Expense",
    "Lodging",
    "Automobile Expense",
    "Postage",
    "Printing and Stationary",
    "Rent Expense",
    "Repairs and Maintenance",
  ];
  const costOfGoodsSold = ["Cost of Goods Sold"];

  useEffect(() => {
    if (accountCategory) {
      handleAccountsExists();
    }
  }, [accountCategory]);

  const renderAccounts = (accounts: AccountChart[], parentId: string = "") => {
    const children = accounts.filter(
      (account) => account.parentAccountId === parentId,
    );

    return (
      <>
        {children.map((account, index) => (
          <div
            key={account._id}
            style={{ marginLeft: parentId ? `10px` : "0" }}
          >
            <Dropdown.Item
              className="d-flex align-items-center gap-2"
              onClick={() => {
                setSubAccountType(account.accountName);
                setParentAccountID(account.accountId);
                setShowSubAccountType(false);
              }}
            >
              {parentId ? <span>.</span> : null}
              {account.accountName}
            </Dropdown.Item>
            {renderAccounts(accounts, account.accountId)}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Add Account </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Account Name
                      </h4>
                      <Form.Control
                        type="text"
                        name="accountName"
                        onChange={handleChange}
                        placeholder="Enter account name"
                        value={values.accountName}
                        required={true}
                        className="custom-input border-fill"
                      />
                      {touched.accountName && errors.accountName ? (
                        <div className="invalid-feedback">
                          {errors.accountName}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Account Type
                      </h4>
                      <Form.Select
                        name="accountType"
                        onChange={(e) => {
                          handleChange(e);
                          setAccountCategory(
                            e.target.value === "Other Asset" ||
                              e.target.value === "Other Current Asset" ||
                              e.target.value === "Cash" ||
                              e.target.value === "Bank" ||
                              e.target.value === "Fixed Asset" ||
                              e.target.value === "Stock" ||
                              e.target.value === "Payment Clearing" ||
                              e.target.value === "Input Tax"
                              ? "Assets"
                              : e.target.value === "Other Current Liability" ||
                                  e.target.value === "Credit Card" ||
                                  e.target.value === "Long Term Liability" ||
                                  e.target.value === "Other Liability" ||
                                  e.target.value === "Overseas Tax Payable" ||
                                  e.target.value === "Output Tax"
                                ? "Liability"
                                : e.target.value === "Equity"
                                  ? "Equity"
                                  : e.target.value === "Income" ||
                                      e.target.value === "Other Income"
                                    ? "Income"
                                    : e.target.value === "Expense" ||
                                        e.target.value ===
                                          "Cost of Goods Sold" ||
                                        e.target.value === "Other Expense"
                                      ? "Expense"
                                      : "",
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.accountType}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Account Type</option>
                        <option
                          value=""
                          style={{ fontWeight: "600", fontSize: "16px" }}
                          disabled={true}
                        >
                          Assets
                        </option>
                        <option value="Other Asset">Other Asset</option>
                        <option value="Other Current Asset">
                          Other Current Asset
                        </option>
                        <option value="Cash">Cash</option>
                        <option value="Bank">Bank</option>
                        <option value="Fixed Asset">Fixed Asset</option>
                        <option value="Stock">Stock</option>
                        <option value="Payment Clearing">
                          Payment Clearing
                        </option>
                        <option value="Input Tax">Input Tax</option>
                        <option
                          value=""
                          style={{ fontWeight: "600", fontSize: "16px" }}
                          disabled={true}
                        >
                          Liability
                        </option>
                        <option value="Other Current Liability">
                          Other Current Liability
                        </option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Long Term Liability">
                          Long Term Liability
                        </option>
                        <option value="Other Liability">Other Liability</option>
                        <option value="Overseas Tax Payable">
                          Overseas Tax Payable
                        </option>
                        <option value="Output Tax">Output Tax</option>
                        <option
                          value=""
                          style={{ fontWeight: "600", fontSize: "16px" }}
                          disabled={true}
                        >
                          Equity
                        </option>
                        <option value="Equity">Equity</option>
                        <option
                          value=""
                          style={{ fontWeight: "600", fontSize: "16px" }}
                          disabled={true}
                        >
                          Income
                        </option>
                        <option value="Income">Income</option>
                        <option value="Other Income">Other Income</option>
                        <option
                          value=""
                          style={{ fontWeight: "600", fontSize: "16px" }}
                          disabled={true}
                        >
                          Expense
                        </option>
                        <option value="Expense">Expense</option>
                        <option value="Cost of Goods Sold">
                          Cost of Goods Sold
                        </option>
                        <option value="Other Expense">Other Expense</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {values.accountType === "Bank" && (
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Bank Account No
                        </h4>
                        <Form.Control
                          type="text"
                          name="bankAccountNumber"
                          onChange={handleChange}
                          placeholder="Enter bank account no"
                          value={values.bankAccountNumber}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.bankAccountNumber &&
                        errors.bankAccountNumber ? (
                          <div className="invalid-feedback">
                            {errors.bankAccountNumber}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Sub Account Type
                      </h4>
                      <Dropdown
                        show={showSubAccountType}
                        className="position-relative"
                      >
                        <button
                          id="dropdown-basic"
                          onClick={() => {
                            setShowSubAccountType(!showSubAccountType);
                          }}
                          type="button"
                          className="bg-transparent dropdown-toggle-white custom-input border-fill w-100 d-flex align-items-center justify-content-between"
                          style={{ boxShadow: "none" }}
                        >
                          {subAccountType
                            ? subAccountType
                            : "Select Sub Account Type"}
                          <img
                            src={EmployeeIcons.ArrowDownBlue}
                            alt="down-arrow"
                          />
                        </button>
                        <Dropdown.Menu
                          className="mt-1 custom-scrollbar w-100 account-sub-type-dropdown"
                          show={showSubAccountType}
                        >
                          <Dropdown.Item
                            onClick={() => {
                              setSubAccountType("");
                              setShowSubAccountType(false);
                            }}
                          >
                            Select Sub Account Type
                          </Dropdown.Item>
                          {loading ? (
                            <Dropdown.Item>Loading...</Dropdown.Item>
                          ) : (
                            <>
                              {values.accountType === "Other Asset" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Other Current Asset" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {otherCurrentAsset.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Cash" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {cash.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Fixed Asset" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {fixedAsset.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Stock" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Payment Clearing" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Input Tax" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType ===
                                "Other Current Liability" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {otherCurrentLiability.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Credit Card" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Long Term Liability" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Other Liability" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType ===
                                "Overseas Tax Payable" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Output Tax" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Equity" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {equity.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Income" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {income.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Other Income" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                              {values.accountType === "Expense" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {expense.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Cost of Goods Sold" && (
                                <>
                                  {renderAccounts(accountExists)}
                                  {costOfGoodsSold.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setSubAccountType(item);
                                        setShowSubAccountType(false);
                                      }}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </>
                              )}
                              {values.accountType === "Other Expense" && (
                                <>{renderAccounts(accountExists)}</>
                              )}
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Description
                      </h4>
                      <textarea
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        required={true}
                        placeholder="Add description here"
                        className={
                          touched.description && errors.description
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.description && errors.description ? (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.addAccountChartsLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Add Account"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddAccountChart;
