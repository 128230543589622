import React from "react";
import ProfileCompleteionComponent from "../components/ProfileCompletion";

const ProfileCompletion = () => {
  return (
    <>
      <ProfileCompleteionComponent />
    </>
  );
};
export default ProfileCompletion;
