import React from "react";
import { Common } from "../../assets/images";
import QRCode from "react-qr-code";

interface Props {
  heading: string;
  children: React.ReactNode;
  data: string;
}

const LetterFormatB = ({ heading, children, data }: Props) => {
  return (
    <>
      <div className="letter-format-a letter-format-b">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <img src={Common.Vision} width="110px" alt="logo" />
          </div>
          <div className="letter-logo text-center gap-2">
            <img src={Common.LogoSVG} width="80px" alt="logo" />
            <div className="mt-1">
              <h3
                className="logo-text mb-0"
                style={{
                  color: "#202020",
                  fontSize: "10px",
                  fontWeight: "700",
                }}
              >
                Senyar Arabian Trading Co. LTD
              </h3>
              <h3
                className="logo-text logo-arabic mb-0"
                style={{
                  color: "#202020",
                  fontSize: "13px",
                  fontWeight: "700",
                }}
              >
                شركة سنيار العربية التجارية المحدودة
              </h3>
            </div>
          </div>
        </div>
        <h4
          className="heading text-center text-uppercase"
          style={{ textAlign: "center", fontSize: "24px" }}
        >
          {heading}
        </h4>
        <div className="letter-content letter-content-b mt-2">
          <div className="letter-body">{children}</div>
        </div>
      </div>
      <div
        className="compnay-detail "
        style={{ position: "absolute", bottom: "14px", left: "0px" }}
      >
        <div className="d-flex align-items-center justify-content-end mb-1">
          <span style={{ fontSize: "12px" }}>:</span>
          <h4
            className="sub-heading ms-1 mb-0 text-black text-end"
            style={{ fontSize: "12px" }}
          >
            الرقم الموحد
          </h4>
        </div>
        <div style={{ background: "rgb(0 0 0)", padding: "4px 30px" }}>
          <h4
            className="sub-heading text-white mb-0 ms-4"
            style={{ fontSize: "22px" }}
          >
            9200 14 811
          </h4>
        </div>
        <h4
          className="sub-heading ms-2 text-black mb-0 text-center"
          style={{ fontSize: "10px", letterSpacing: "2px" }}
        >
          www.senyarsa.com
        </h4>
      </div>
      <div
        className="qr-code-letter"
        style={{
          position: "absolute",
          bottom: "60px",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      >
        <QRCode value={data || ""} />
      </div>
      <div
        className="compnay-detail d-flex justify-content-end"
        style={{ position: "absolute", bottom: "14px", right: "24px" }}
      >
        <div style={{ width: "1px", border: "1px solid #0000005e" }}></div>
        <ul className="ms-3">
          <li className="d-flex align-items-center gap-2 mb-1">
            <span
              className="sub-heading normal-font-weight text-black mb-0"
              style={{ fontSize: "12px" }}
            >
              K.S.A.
            </span>
            <h4
              className="sub-heading ms-2 normal-font-weight text-black mb-0"
              style={{ fontSize: "12px", wordSpacing: "10px" }}
            >
              P.O Box 8931 Jeddah 23345
            </h4>
          </li>
          <li className="d-flex align-items-center gap-2 mb-1">
            <span
              className="sub-heading normal-font-weight text-black mb-0"
              style={{ fontSize: "12px" }}
            >
              Jeddah -
            </span>
            <h4
              className="sub-heading normal-font-weight text-black mb-0"
              style={{ fontSize: "12px", wordSpacing: "2px" }}
            >
              Jamil bin moamer St, AlRehab
            </h4>
          </li>
          <li className="d-flex align-items-center gap-2 mb-1">
            <span
              className="sub-heading normal-font-weight text-black mb-0"
              style={{ fontSize: "12px" }}
            >
              E-mail:
            </span>
            <h4
              className="sub-heading normal-font-weight text-black mb-0 ms-3"
              style={{ fontSize: "12px", letterSpacing: "4px" }}
            >
              info@senyarsa.com
            </h4>
          </li>
        </ul>
      </div>
    </>
  );
};
export default LetterFormatB;
