import React from "react";
// Packages Imports
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// Redux Toolkit Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  loadingState,
  emailErrorState,
  passwordErrorState,
  loginSuccess,
  setRememberMe,
} from "../../store/slices/authenticationSlice";
// Files Imports
import { LoaderWhite } from "../Lotties";
import CommonBGLayout from "../CommonBGLayout";
import { Common, LoginIcons } from "../../assets/images";
// API
import { useForgotPasswordMutation } from "../../services/Authentication";

interface FormProps {
  email: string;
}

const ForgotPasswordComponent = () => {
  const dispatch = useAppDispatch();
  const { emailError } = useAppSelector((state) => state.authentication);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const navigate = useNavigate();
  // Initial Values
  const initialValues: FormProps = {
    email: "",
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
  });
  // Form Submit Function
  const handleSubmit = async (values: FormProps) => {
    dispatch(emailErrorState(""));
    try {
      const { email } = values;
      await forgotPassword({ email }).unwrap();
      navigate("/otp-verification", { state: { email: email } });
    } catch (err: any) {
      if (err.data.data.email) {
        window.scrollTo(0, 0);
        dispatch(emailErrorState(err.data.data.email));
      }
    }
  };

  return (
    <CommonBGLayout>
      <div className="login">
        <Container>
          <Row>
            <Col>
              <div className="login-card text-center mx-auto">
                <div className="login-overlay"></div>
                <div className="login-card-header mb-4">
                  <img
                    src={Common.Logo}
                    width="130px"
                    height="101px"
                    alt="logo"
                  />
                  <h3 className="mt-2 mb-0">Forgot Password</h3>
                  <p className="para-big mb-0">Keep Safe your Password</p>
                </div>
                <div className="login-card-body text-start">
                  <div className="common-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <Form.Group className="position-relative input-design d-flex align-items-center">
                              <img src={LoginIcons.Message} alt="message" />
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                            </Form.Group>
                            {emailError ? (
                              <div className="invalid-feedback">
                                {emailError}
                              </div>
                            ) : (
                              <>
                                {touched.email && errors.email ? (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Form.Group>
                          <div className="d-flex mt-4">
                            {/* Forgot Password */}
                            <a
                              href="/login"
                              className="ms-auto forget-password"
                            >
                              Account Login?
                            </a>
                          </div>
                          <button
                            className="btn primary-button mt-5 w-100"
                            type="submit"
                          >
                            {isLoading ? (
                              <LoaderWhite height={30} width={30} />
                            ) : (
                              "Verify email"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CommonBGLayout>
  );
};

export default ForgotPasswordComponent;
