import React, { useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Common, SettingsIcons } from "../../../assets/images";
import { EmpDetail } from "../../../interfaces/employee";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setDetailPageBasicInfo,
  setDetailPageLegalInfo,
  setDetailActiveTab,
} from "../../../store/slices/employeeSlice";
import { useReactToPrint } from "react-to-print";
import LetterFormatB from "../../Letters/letter-format-b";

interface Props {
  empDetail: EmpDetail;
}

const BasicInfo = ({ empDetail }: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const basicInfoRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => basicInfoRef.current,
  });
  return (
    <>
      <Row className="align-items-center">
        <Col sm="1">
          <div>
            {empDetail.empImage ? (
              empDetail.empImage.includes("https") ? (
                <img
                  src={empDetail.empImage}
                  className=" mb-3"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="setting-icon"
                />
              ) : (
                <img
                  src={Common.UserImgPlaceholder}
                  className=" mb-3"
                  style={{ width: "100%" }}
                  alt="setting-icon"
                />
              )
            ) : (
              <img
                src={Common.UserImgPlaceholder}
                className=" mb-3"
                style={{ width: "100%" }}
                alt="setting-icon"
              />
            )}
          </div>
        </Col>
        <Col sm="11">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Emp Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Emp Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Place
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empPlace}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Category
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empCategory}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Nationality
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empNationality}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Religion
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empReligion}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Company Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empCompanyName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Mobile No.
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empPhone}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Employee Status
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {empDetail.empStatus}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "16px" }}
                >
                  Date of Birth
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "14px" }}
                >
                  {new Date(empDetail.empDob).toLocaleDateString()}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {empDetail.empNationality !== "Saudi Arabia" && (
          <Col sm="12">
            <h3 className="sub-heading mt-4">Camp Information</h3>
          </Col>
        )}
        {empDetail.campDetails ? (
          <Col sm="12">
            <Row>
              {empDetail.empNationality !== "Saudi Arabia" && (
                <>
                  <Col sm="4">
                    <Form.Group className="mb-3">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "16px" }}
                      >
                        Camp Id
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "14px" }}
                      >
                        {empDetail.campDetails.campId}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "16px" }}
                      >
                        Camp Name
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "14px" }}
                      >
                        {empDetail.campDetails.campName}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "16px" }}
                      >
                        Camp City
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "14px" }}
                      >
                        {empDetail.campDetails.campCity}
                      </h5>
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col sm="4">
                <Form.Group className="mb-3">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "16px" }}
                  >
                    Department
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "14px" }}
                  >
                    {empDetail.empDepartment}
                  </h5>
                </Form.Group>
              </Col>
              {empDetail.empNationality !== "Saudi Arabia" && (
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "16px" }}
                    >
                      Home Country Contact
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "14px" }}
                    >
                      {empDetail.empHomeCountryNumber}
                    </h5>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Col>
        ) : (
          <>
            {empDetail.empNationality !== "Saudi Arabia" && (
              <Col sm="4">
                <Form.Group className="mb-3">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "16px" }}
                  >
                    No Camp Information
                  </h4>
                </Form.Group>
              </Col>
            )}
          </>
        )}
        <Col sm="12">
          <h3 className="sub-heading mt-4">Bank Information</h3>
        </Col>
        <Col sm="12">
          <Row>
            {empDetail.empBankDetails.length === 0 && (
              <div className="text-center mt-5">
                <h1 className="heading">No Bank Info Added</h1>
              </div>
            )}
            {empDetail.empBankDetails.length > 0 && (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th className="bg-transparent">Sr.</th>
                      <th className="bg-transparent">Bank Name</th>
                      <th className="bg-transparent">Bank A/C No. OR IBAN</th>
                      <th className="bg-transparent">Bank Account Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empDetail.empBankDetails.map((item, index) => (
                      <tr key={index}>
                        <td className="bg-transparent">{index + 1}</td>
                        <td className="bg-transparent">{item.empBankName}</td>
                        <td className="bg-transparent">
                          {item.empBankAccountNo}
                        </td>
                        <td className="bg-transparent">
                          {item.empBankAccountTitle}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Row>
        </Col>
      </Row>

      <div className="text-end mt-4">
        <button
          className="btn primary-button normal-font-weight"
          onClick={() => {
            dispatch(setDetailActiveTab("Legal Details"));
            dispatch(setDetailPageBasicInfo(false));
            dispatch(setDetailPageLegalInfo(true));
          }}
          type="button"
          style={{ width: "15%" }}
        >
          Next <img src={Common.ArrowRightWhite} alt="next" />
        </button>
      </div>
    </>
  );
};

export default BasicInfo;
