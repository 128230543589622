import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetBillingListQuery } from "../../services/AccountCharts";
import {
  setBillList,
  setBillListLoading,
  setBillListError,
  setBillListTotalPages,
} from "../../store/slices/vendorsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const billChange = useAppSelector((state) => state.common.billChange);
  // States
  const state = useAppSelector((state) => state.vendors);

  // **************
  // **************
  // Get All Bills
  // **************
  // **************
  const {
    data: allBill,
    isLoading: billListLoading,
    isError: billListError,
    refetch: billListRefetch,
  } = useGetBillingListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    pageNo: state.billListPageNo,
    pageSize: state.billListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (billListLoading) {
      dispatch(setBillListLoading(true));
    }
    if (billListError) {
      dispatch(setBillListError("Something went wrong"));
      dispatch(setBillListLoading(false));
    }
    if (allBill) {
      dispatch(setBillList(allBill.data.bills[0].data || []));
      dispatch(
        setBillListTotalPages(allBill.data.bills[0].metadata[0]?.total || 0),
      );
      dispatch(setBillListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allBill,
    profileData,
    state.billListPageNo,
    state.billListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    billListRefetch();
  }, [billChange]);

  return <></>;
};
export default Content;
