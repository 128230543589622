import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, SettingsIcons } from "../../assets/images";
import AttachmentSet from "../Employees/AddEmployee/attachments";
import { Client } from "../../interfaces/project";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddClientLoading } from "../../store/slices/clientsSlice";
import { setClientsChange } from "../../store/slices/commonSlice";
// API
import { useEditClientMutation } from "../../services/Clients";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

export interface AddClient {
  clientName: string;
  clientNameArabic: string;
  contactPersonName: string;
  contactPersonDesignation: string;
  clientsContactNo: string;
  clientAddress1: string;
  clientAddressArabic: string;
  city: string;
  country: string;
  postalCode: string;
  phone: string;
  companyName: string;
  numberVAT: string;
  numberCR: string;
  clientMOL: string;
  otherBuyerId: string;
  clientStatus: string;
  clientAttachments: {
    uId: string;
    title: string;
    link: string;
    date: string;
    empId: number;
    empName: string;
  }[];
}

const EditClient = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clientData: Client = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [showAttachment, setShowAttachment] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    clientData.emails || [],
  );
  const clientsChange = useAppSelector((state) => state.common.clientsChange);

  const initialValues: AddClient = {
    clientName: clientData.clientName || "",
    clientNameArabic: clientData.clientNameArabic || "",
    contactPersonName: clientData.contactPersonName || "",
    contactPersonDesignation: clientData.contactPersonDesignation || "",
    clientsContactNo: clientData.clientsContactNo || "",
    clientAddress1: clientData.clientAddress1 || "",
    clientAddressArabic: clientData.clientAddressArabic || "",
    city: clientData.city || "",
    country: clientData.country || "",
    companyName: clientData.companyName || "",
    clientMOL: clientData.clientMOL || "",
    postalCode: clientData.postalCode ? clientData.postalCode.toString() : "",
    phone: clientData.phone || "",
    numberVAT: clientData.numberVAT ? clientData.numberVAT.toString() : "",
    numberCR: clientData.numberCR ? clientData.numberCR.toString() : "",
    otherBuyerId: clientData.otherBuyerId || "",
    clientStatus: clientData.clientStatus || "",
    clientAttachments: clientData.clientAttachments || [],
  };

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >(clientData.clientAttachments || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  const [editClient] = useEditClientMutation();
  const handleSubmit = async (values: AddClient) => {
    dispatch(setAddClientLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateProject || false,
        payload: {
          clientId: clientData.clientId,
          clientName: values.clientName,
          clientNameArabic: values.clientNameArabic,
          contactPersonName: values.contactPersonName,
          contactPersonDesignation: values.contactPersonDesignation,
          clientsContactNo: values.clientsContactNo,
          clientAddress1: values.clientAddress1,
          clientAddressArabic: values.clientAddressArabic,
          // city: values.city,
          companyName: values.companyName,
          // country: values.country,
          postalCode: values.postalCode,
          phone: values.phone,
          clientMOL: values.clientMOL,
          numberVAT: values.numberVAT,
          numberCR: values.numberCR,
          otherBuyerId: values.otherBuyerId,
          clientStatus: values.clientStatus,
          clientAttachments: languageData,
          emails: selectedEmails,
          link: `/clients`,
        },
      };
      await editClient(data).unwrap();
      dispatch(setClientsChange(!clientsChange));
      navigate("/clients");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddClientLoading(false));
    }
  };

  const handleEmail = () => {
    //   add email in the list if it already exists then remvoe it
    if (email === "") {
      setErrorContent("Please enter email");
      setShow(true);
      return;
    }
    if (selectedEmails.includes(email)) {
      setErrorContent("Email already exists");
      setShow(true);
      return;
    }
    setSelectedEmails([...selectedEmails, email]);
    setEmail("");
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Client </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showAttachment && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="clientName"
                          onChange={handleChange}
                          placeholder="Enter client name"
                          value={values.clientName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.clientName && errors.clientName ? (
                          <div className="invalid-feedback">
                            {errors.clientName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client Name in Arabic
                        </h4>
                        <Form.Control
                          type="text"
                          name="clientNameArabic"
                          onChange={handleChange}
                          placeholder="Enter client name in arabic"
                          value={values.clientNameArabic}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client's Contact No. *
                        </h4>
                        <Form.Control
                          type="text"
                          name="clientsContactNo"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter client's contact number"
                          required={true}
                          value={values.clientsContactNo}
                          className="custom-input border-fill"
                        />
                        {touched.clientsContactNo && errors.clientsContactNo ? (
                          <div className="invalid-feedback">
                            {errors.clientsContactNo}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonName"
                          onChange={handleChange}
                          placeholder="Enter contact person name"
                          value={values.contactPersonName}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonName &&
                        errors.contactPersonName ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Designation
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonDesignation"
                          placeholder="Enter contact person designation"
                          onChange={handleChange}
                          value={values.contactPersonDesignation}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonDesignation &&
                        errors.contactPersonDesignation ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonDesignation}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact person phone
                        </h4>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter contact person phone number"
                          value={values.phone}
                          className="custom-input border-fill"
                        />
                        {touched.phone && errors.phone ? (
                          <div className="invalid-feedback">{errors.phone}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          placeholder="Enter company name"
                          value={values.companyName}
                          className="custom-input border-fill"
                        />
                        {touched.companyName && errors.companyName ? (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      City*/}
                    {/*    </h4>*/}
                    {/*    <Form.Control*/}
                    {/*      type="text"*/}
                    {/*      name="city"*/}
                    {/*      placeholder="Enter city"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.city}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    />*/}
                    {/*    {touched.city && errors.city ? (*/}
                    {/*      <div className="invalid-feedback">{errors.city}</div>*/}
                    {/*    ) : null}*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      Country*/}
                    {/*    </h4>*/}
                    {/*    <Form.Select*/}
                    {/*      name="country"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.country}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    >*/}
                    {/*      <option value="">Select Country</option>*/}
                    {/*      {countryCodes.map((item, index) => (*/}
                    {/*        <option key={index} value={item.name}>*/}
                    {/*          {item.name}*/}
                    {/*        </option>*/}
                    {/*      ))}*/}
                    {/*    </Form.Select>*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client MOL
                        </h4>
                        <Form.Control
                          type="text"
                          name="clientMOL"
                          placeholder="Enter Client MOL"
                          onChange={handleChange}
                          value={values.clientMOL}
                          className="custom-input border-fill"
                        />
                        {touched.clientMOL && errors.clientMOL ? (
                          <div className="invalid-feedback">
                            {errors.clientMOL}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Postal Code
                        </h4>
                        <Form.Control
                          type="text"
                          name="postalCode"
                          placeholder="Enter postal code"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.postalCode}
                          className="custom-input border-fill"
                        />
                        {touched.city && errors.city ? (
                          <div className="invalid-feedback">{errors.city}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          VAT Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberVAT"
                          placeholder="Enter VAT number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberVAT}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.city && errors.city ? (
                          <div className="invalid-feedback">{errors.city}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          C.R. Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberCR"
                          placeholder="Enter CR number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberCR}
                          required={true}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Other Buyer ID
                        </h4>
                        <Form.Control
                          type="text"
                          name="otherBuyerId"
                          placeholder="Enter other buyer ID"
                          onChange={handleChange}
                          value={values.otherBuyerId}
                          className="custom-input border-fill"
                        />
                        {touched.city && errors.city ? (
                          <div className="invalid-feedback">{errors.city}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Client Status
                        </h4>
                        <Form.Select
                          name="clientStatus"
                          onChange={handleChange}
                          value={values.clientStatus}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Client Status</option>
                          <option value="Active">Active</option>
                          <option value="In-active">In-active</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="8">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="custom-input border-fill"
                          />
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            onClick={handleEmail}
                            style={{ width: "25%" }}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Address 1 *
                        </h4>
                        <textarea
                          name="clientAddress1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientAddress1}
                          required={true}
                          placeholder="Add address 1"
                          className={
                            touched.clientAddress1 && errors.clientAddress1
                              ? "is-invalid border-fill w-100"
                              : "border-fill w-100"
                          }
                          rows={6}
                          style={{ resize: "none" }}
                        />
                        {touched.clientAddress1 && errors.clientAddress1 ? (
                          <div className="invalid-feedback">
                            {errors.clientAddress1}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Address in arabic
                        </h4>
                        <textarea
                          name="clientAddressArabic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientAddressArabic}
                          placeholder="Add address 1"
                          className="border-fill w-100"
                          rows={6}
                          style={{ resize: "none" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email List
                        </h4>
                        {selectedEmails.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEmails.map(
                                (item: string, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.toLocaleLowerCase()}</td>
                                    <td>
                                      <button
                                        className="btn p-0 border-0"
                                        type="button"
                                        onClick={() => {
                                          const updatedEmails =
                                            selectedEmails.filter(
                                              (email) => email !== item,
                                            );
                                          setSelectedEmails(updatedEmails);
                                        }}
                                      >
                                        <img
                                          src={SettingsIcons.Delete}
                                          width="24px"
                                          height="24px"
                                          alt="edit-user"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">No emails added</div>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn primary-button w-25"
                          onClick={() => {
                            if (values.clientName === "") {
                              setErrorContent("Client Name is required");
                              setShow(true);

                              return;
                            }
                            if (values.clientsContactNo === "") {
                              setErrorContent("Client Contact no. is required");
                              setShow(true);

                              return;
                            }
                            if (values.companyName === "") {
                              setErrorContent("Company name is required");
                              setShow(true);

                              return;
                            }
                            if (values.numberVAT === "") {
                              setErrorContent("VAT number is required");
                              setShow(true);

                              return;
                            }
                            if (values.clientAddress1 === "") {
                              setErrorContent("Client address is required");
                              setShow(true);

                              return;
                            }
                            setShowAttachment(true);
                          }}
                        >
                          Next
                          <img src={Common.ArrowRightWhite} alt="arrow" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {showAttachment && (
                  <Row>
                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachments
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">{languageSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-between mt-4">
                        <button
                          className="btn secondary-button w-25"
                          type="button"
                          onClick={() => {
                            setShowAttachment(false);
                          }}
                        >
                          <img
                            src={Common.ArrowLeftBlue}
                            className="me-1"
                            alt="next"
                          />{" "}
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn primary-button w-25"
                        >
                          {state.addClientReviewLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Edit Client"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditClient;
