import React from "react";
// Package Imports
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// Redux Toolkit Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  visiblePasswordState,
  visibleRepeatPasswordState,
  showResetModalState,
} from "../../store/slices/authenticationSlice";
// Files Imports
import { LoaderWhite } from "../Lotties";
import CommonBGLayout from "../CommonBGLayout";
import { Common, LoginIcons } from "../../assets/images";
import ResetPasswordSuccessModal from "../Modals/reset-password-success-modal";
// API
import {useResetPasswordMutation} from "../../services/Authentication"

interface FormProps {
  password: string,
  confirmPassword: string
}

const ResetPasswordComponent = () => {
  const dispatch = useAppDispatch();
  const {
    visiblePasswords,
    visibleRepeatPasswords,
    passwordError,
    showResetModal,
  } = useAppSelector((state) => state.authentication);

  const location = useLocation();

  const email = location?.state?.email;
    const [resetPassword, { isLoading }] = useResetPasswordMutation();

  // Initial Values
    const initialValues: FormProps = {
        password: "",
        confirmPassword: "",
    };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character"
        ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
  });
  // Form Submit Function
  const handleSubmit = async (values: FormProps) => {
    try {
      const { password } = values;
      await resetPassword({ email, password }).unwrap();
      dispatch(showResetModalState(true));
    } catch (err: any) {

    }
  };

  return (
    <CommonBGLayout>
      <ResetPasswordSuccessModal show={showResetModal} />
      <div className="login">
        <Container>
          <Row>
            <Col>
              <div className="login-card text-center mx-auto">
                <div className="login-overlay"></div>
                <div className="login-card-header mb-4">
                  <img
                    src={Common.Logo}
                    width="130px"
                    height="101px"
                    alt="logo"
                  />
                  <h3 className="mt-2 mb-0">Reset Password</h3>
                  <p className="para-big mb-0">Set your new password</p>
                </div>
                <div className="login-card-body text-start">
                  <div className="common-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group className="mb-4">
                            <Form.Group className="position-relative input-design d-flex align-items-center">
                              <img src={LoginIcons.Password} alt="message" />
                              <Form.Control
                                type={visiblePasswords ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                placeholder="Enter new password"
                                onBlur={handleBlur}
                                value={values.password}
                                className={
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              <button
                                className="btn p-0 border-0 show-password-icon"
                                onClick={() =>
                                  dispatch(
                                    visiblePasswordState(!visiblePasswords)
                                  )
                                }
                                type="button"
                              >
                                {!visiblePasswords ? (
                                  <img src={Common.EyeClose} alt="eye-open" />
                                ) : (
                                  <img src={Common.EyeOpen} alt="eye-open" />
                                )}
                              </button>
                            </Form.Group>
                            {passwordError ? (
                              <div className="invalid-feedback">
                                {passwordError}
                              </div>
                            ) : (
                              <>
                                {touched.password && errors.password ? (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Group className="position-relative input-design d-flex align-items-center">
                              <img src={LoginIcons.Password} alt="message" />
                              <Form.Control
                                type={
                                  visibleRepeatPasswords ? "text" : "password"
                                }
                                name="confirmPassword"
                                onChange={handleChange}
                                placeholder="Re-enter password"
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                className={
                                  touched.confirmPassword &&
                                  errors.confirmPassword
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                              <button
                                className="btn p-0 border-0 show-password-icon"
                                onClick={() =>
                                  dispatch(
                                    visibleRepeatPasswordState(
                                      !visibleRepeatPasswords
                                    )
                                  )
                                }
                                type="button"
                              >
                                {!visibleRepeatPasswords ? (
                                  <img src={Common.EyeClose} alt="eye-open" />
                                ) : (
                                  <img src={Common.EyeOpen} alt="eye-open" />
                                )}
                              </button>
                            </Form.Group>

                            {touched.confirmPassword &&
                            errors.confirmPassword ? (
                              <div className="invalid-feedback">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                          </Form.Group>
                          <div className="d-flex mt-4">
                            {/* Forgot Password */}
                            <a
                              href="/login"
                              className="ms-auto forget-password"
                            >
                              Account Login?
                            </a>
                          </div>
                          <button
                            className="btn primary-button mt-5 w-100"
                            type="submit"
                          >
                            {isLoading ? (
                              <LoaderWhite height={30} width={30} />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CommonBGLayout>
  );
};

export default ResetPasswordComponent;
