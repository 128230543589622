import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageOtherDetails,
  setDetailActiveTab,
  setDetailPageAgreementDetails,
  setDetailPageAttachments,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";

interface Props {
  empDetail: EmpDetail;
}

const AgreementDetail = ({ empDetail }: Props) => {
  // Redux
  const dispatch = useAppDispatch();

  // Using the URL API to extract the pathname
  const pathname = empDetail.agreement.attachment
    ? new URL(empDetail.agreement.attachment).pathname
    : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split("/").pop();

  // Extracting the file extension from the file name
  const fileExtension = fileNameWithExtension
    ? fileNameWithExtension.split(".").pop()
    : "";
  return (
    <Row>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Agreement Issued Date
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.agreement.issueDate
              ? new Date(empDetail.agreement.issueDate).toLocaleDateString()
              : "N/A"}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Agreement Expiry Date
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.agreement.expiryDate
              ? new Date(empDetail.agreement.expiryDate).toLocaleDateString()
              : "N/A"}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="12">
        <div className="emp-detail-item mb-4">
          <h4
            className="sub-heading normal-font-weight textBlack mb-2"
            style={{ fontSize: "18px" }}
          >
            Attachment
          </h4>
          {!empDetail.agreement.attachment ? (
            <div>
              <h4 className="sub-heading">No Attachment</h4>
            </div>
          ) : (
            <div className="uploaded-item position-relative mt-4 w-25">
              <div className="upload-item-header">
                <img
                  src={
                    fileNameWithExtension === "jpg" ||
                    fileNameWithExtension === "png" ||
                    fileNameWithExtension === "jpeg"
                      ? Common.ImageIcon
                      : Common.FileIcon
                  }
                  width="120px"
                  alt="uploaded-item"
                />

                <div className="upload-item-body d-flex justify-content-between align-items-center">
                  <p className="mb-0">{fileNameWithExtension}</p>
                  <div className="ms-auto text-end">
                    <a href={empDetail.agreement.attachment} download>
                      <img
                        src={Common.Download}
                        className="me-2"
                        width="24px"
                        height="24px"
                        alt="share"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Other Details"));
              dispatch(setDetailPageOtherDetails(true));
              dispatch(setDetailPageAgreementDetails(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Attachments"));
              dispatch(setDetailPageAgreementDetails(false));
              dispatch(setDetailPageAttachments(true));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            Next <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default AgreementDetail;
