import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import WorkRefusalLetter from "../Letters/work-refusal-letter";
import { BehaviourStatement } from "../../interfaces/letters";

const ViewEmployeeBehaviourStatement = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const statementData: BehaviourStatement = location.state.data;

  return (
    <div className=" mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <WorkRefusalLetter bData={statementData} />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">
                Employee Behaviour Statement Detail
              </h1>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Category
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empCategory}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.projectId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.projectName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Supervisor Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.supervisorName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Violation Type
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.violationType}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Violation Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.violationDate
                      ? new Date(
                          statementData.violationDate,
                        ).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Violation Time
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.violationDate
                      ? new Date(
                          statementData.violationDate,
                        ).toLocaleTimeString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Supervisor Agree with Employee Statement
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.supervisorToEmployeeStatement ? "Yes" : "No"}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Action Taken
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.actionsTaken}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Employee Statement
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.violationStatement}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Supervisor Reason to Agree/Disagree with Employee Statement
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.reasonToAgree}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Consequences
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.conscequences}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              <button className="btn primary-button w-25" onClick={handlePrint}>
                Print
              </button>
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewEmployeeBehaviourStatement;
