import { createSlice } from "@reduxjs/toolkit";

const token =
  localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
const userID =
  localStorage.getItem("userID") || sessionStorage.getItem("userID");
const role = localStorage.getItem("role") || sessionStorage.getItem("role");

interface Index {
  accessToken: string | null;
  userID: string | null;
  settingsChange: boolean;
  role: string | null;
  employeeChange: boolean;
  pettyCashChange: boolean;
  projectChange: boolean;
  iqamaChange: boolean;
  clientReviewChange: boolean;
  attendanceChange: boolean;
  behaviourStatementChange: boolean;
  violationsChange: boolean;
  notificationChange: boolean;
  transferEmployeeChange: boolean;
  leaveRequestChange: boolean;
  salaryIncrementChange: boolean;
  probationChange: boolean;
  abscondedChange: boolean;
  approvalChange: boolean;
  finalExitChange: boolean;
  offerLetterChange: boolean;
  certificateChange: boolean;
  clientsChange: boolean;
  campsChange: boolean;
  recruitAgencyChange: boolean;
  legalChange: boolean;
  visaChange: boolean;
  actualSheetChange: boolean;
  accountChartsChange: boolean;
  manualJournalChange: boolean;
  sponsorChange: boolean;
  vendorChange: boolean;
  ownerChange: boolean;
  payrollChange: boolean;
  billChange: boolean;
}

const initialState: Index = {
  accessToken: token,
  userID: userID,
  settingsChange: false,
  role: role,
  employeeChange: false,
  pettyCashChange: false,
  projectChange: false,
  iqamaChange: false,
  clientReviewChange: false,
  attendanceChange: false,
  behaviourStatementChange: false,
  violationsChange: false,
  notificationChange: false,
  transferEmployeeChange: false,
  leaveRequestChange: false,
  salaryIncrementChange: false,
  probationChange: false,
  abscondedChange: false,
  approvalChange: false,
  finalExitChange: false,
  offerLetterChange: false,
  certificateChange: false,
  clientsChange: false,
  campsChange: false,
  recruitAgencyChange: false,
  legalChange: false,
  visaChange: false,
  actualSheetChange: false,
  accountChartsChange: false,
  manualJournalChange: false,
  sponsorChange: false,
  vendorChange: false,
  ownerChange: false,
  payrollChange: false,
  billChange: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    getCommonValues: (state) => {
      state.accessToken = token;
      state.userID = userID;
      state.role = role;
    },
    setSettingsChange: (state, action) => {
      state.settingsChange = action.payload;
    },
    setEmployeeChange: (state, action) => {
      state.employeeChange = action.payload;
    },
    setPettyCashChange: (state, action) => {
      state.pettyCashChange = action.payload;
    },
    setProjectChange: (state, action) => {
      state.projectChange = action.payload;
    },
    setIqamaChange: (state, action) => {
      state.iqamaChange = action.payload;
    },
    setClientReviewChange: (state, action) => {
      state.clientReviewChange = action.payload;
    },
    setAttendanceChange: (state, action) => {
      state.attendanceChange = action.payload;
    },
    setBehaviourStatementChange: (state, action) => {
      state.behaviourStatementChange = action.payload;
    },
    setViolationsChange: (state, action) => {
      state.violationsChange = action.payload;
    },
    setNotificationChange: (state, action) => {
      state.notificationChange = action.payload;
    },
    setTransferEmployeeChange: (state, action) => {
      state.transferEmployeeChange = action.payload;
    },
    setLeaveRequestChange: (state, action) => {
      state.leaveRequestChange = action.payload;
    },
    setSalaryIncrementChange: (state, action) => {
      state.salaryIncrementChange = action.payload;
    },
    setProbationChange: (state, action) => {
      state.probationChange = action.payload;
    },
    setAbscondedChange: (state, action) => {
      state.abscondedChange = action.payload;
    },
    setApprovalChange: (state, action) => {
      state.approvalChange = action.payload;
    },
    setFinalExitChange: (state, action) => {
      state.finalExitChange = action.payload;
    },
    setOfferLetterChange: (state, action) => {
      state.offerLetterChange = action.payload;
    },
    setCertificateChange: (state, action) => {
      state.certificateChange = action.payload;
    },
    setClientsChange: (state, action) => {
      state.clientsChange = action.payload;
    },
    setCampsChange: (state, action) => {
      state.campsChange = action.payload;
    },
    setRecruitAgencyChange: (state, action) => {
      state.recruitAgencyChange = action.payload;
    },
    setLegalChange: (state, action) => {
      state.legalChange = action.payload;
    },
    setVisaChange: (state, action) => {
      state.visaChange = action.payload;
    },
    setActualSheetChange: (state, action) => {
      state.actualSheetChange = action.payload;
    },
    setAccountChartsChange: (state, action) => {
      state.accountChartsChange = action.payload;
    },
    setManualJournalChange: (state, action) => {
      state.manualJournalChange = action.payload;
    },
    setSponsorChange: (state, action) => {
      state.sponsorChange = action.payload;
    },
    setVendorChange: (state, action) => {
      state.vendorChange = action.payload;
    },
    setOwnerChange: (state, action) => {
      state.ownerChange = action.payload;
    },
    setPayrollChange: (state, action) => {
      state.payrollChange = action.payload;
    },
    setBillChange: (state, action) => {
      state.billChange = action.payload;
    },
  },
});

export const {
  getCommonValues,
  setNotificationChange,
  setBehaviourStatementChange,
  setViolationsChange,
  setSettingsChange,
  setAttendanceChange,
  setClientReviewChange,
  setIqamaChange,
  setEmployeeChange,
  setProjectChange,
  setPettyCashChange,
  setTransferEmployeeChange,
  setLeaveRequestChange,
  setSalaryIncrementChange,
  setProbationChange,
  setAbscondedChange,
  setApprovalChange,
  setFinalExitChange,
  setOfferLetterChange,
  setCertificateChange,
  setClientsChange,
  setCampsChange,
  setRecruitAgencyChange,
  setLegalChange,
  setVisaChange,
  setActualSheetChange,
  setAccountChartsChange,
  setManualJournalChange,
  setSponsorChange,
  setVendorChange,
  setOwnerChange,
  setPayrollChange,
  setBillChange,
} = commonSlice.actions;
export default commonSlice.reducer;
