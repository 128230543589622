import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetInvoiceListQuery } from "../../services/Clients";
import {
  setInvoiceList,
  setInvoiceListLoading,
  setInvoiceListError,
  setInvoiceListTotalPages,
} from "../../store/slices/clientsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  // States
  const state = useAppSelector((state) => state.clients);

  // **************
  // **************
  // Get All Invoices
  // **************
  // **************
  const {
    data: allInvoicess,
    isLoading: invoiceListLoading,
    isError: invoiceListError,
    refetch: invoiceListRefetch,
  } = useGetInvoiceListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    pageNo: state.invoiceListPageNo,
    pageSize: state.invoiceListPageSize,
    clientID: state.clientIdNumber,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (invoiceListLoading) {
      dispatch(setInvoiceListLoading(true));
    }
    if (invoiceListError) {
      dispatch(setInvoiceListError("Something went wrong"));
      dispatch(setInvoiceListLoading(false));
    }
    if (allInvoicess) {
      dispatch(setInvoiceList(allInvoicess.data.invoices[0].data || []));
      dispatch(
        setInvoiceListTotalPages(
          allInvoicess.data.invoices[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setInvoiceListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allInvoicess,
    profileData,
    state.invoiceListPageNo,
    state.invoiceListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    invoiceListRefetch();
  }, [clientsChange, state.clientIdNumber]);
  return <></>;
};
export default Content;
