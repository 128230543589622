import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { Legal } from "../../interfaces/iqama";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterLegalTotalRecords,
  setLegalLoading,
} from "../../store/slices/iqamaSlice";
// API imports
import { useGetFilterLegalListMutation } from "../../services/Iqama";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setLegal?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  legals: Legal[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  category: string;
  validFrom: string;
  validTo: string;
  status: string;
  updatedFrom: string;
  updatedTo: string;
  workPermit: string;
}

const LegalFilterModal = ({
  show,
  setShow,
  legals,
  setLegal,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.iqama);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterLegalList] = useGetFilterLegalListMutation();
  const projectIds = useAppSelector((state) => state.project.projectIds);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLegalLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showLegal || false,
        payload: {
          page: state.filterLegalPageNo,
          limit: state.filterLegalPageSize,
          projectId: filterInput.projectId,
          category: filterInput.category,
          validFrom: filterInput.validFrom,
          validTo: filterInput.validTo,
          status: filterInput.status,
          updatedFrom: filterInput.updatedFrom,
          updatedTo: filterInput.updatedTo,
          workPermit: filterInput.workPermit,
        },
      };
      const response = await getFilterLegalList(data).unwrap();
      if (response) {
        setLegal(response.data.legal[0].data);
        dispatch(
          setFilterLegalTotalRecords(response.data.legal[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setLegalLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLegalLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showLegal || false,
          payload: {
            page: state.filterLegalPageNo,
            limit: state.filterLegalPageSize,
            empId: query,
            projectId: filterInput.projectId,
            category: filterInput.category,
            validFrom: filterInput.validFrom,
            validTo: filterInput.validTo,
            status: filterInput.status,
            updatedFrom: filterInput.updatedFrom,
            updatedTo: filterInput.updatedTo,
            workPermit: filterInput.workPermit,
          },
        };
        const response = await getFilterLegalList(data).unwrap();
        if (response) {
          setLegal(response.data.legal[0].data);
          dispatch(
            setFilterLegalTotalRecords(
              response.data.legal[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setLegalLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterLegalPageNo, state.filterLegalPageSize, query]);

  const handleReset = () => {
    setLegal(legals);
    setFilterInput({
      category: "",
      validFrom: "",
      validTo: "",
      status: "",
      updatedFrom: "",
      updatedTo: "",
      projectId: "",
      workPermit: "",
    });
  };

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Legal Request</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Work Permit Status
                    </h4>
                    <Form.Select
                      name="workPermit"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          workPermit: e.target.value,
                        })
                      }
                      value={filterInput.workPermit}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Work Permit Status</option>
                      <option value="Valid">Valid</option>
                      <option value="Expired">Expired</option>
                      <option value="ملغى">ملغى</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Category
                    </h4>
                    <Form.Select
                      name="category"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          category: e.target.value,
                        })
                      }
                      value={filterInput.category}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Category</option>
                      <option value="Driver Card">Driver Card</option>
                      <option value="Municipality card">
                        Municipality card
                      </option>
                      <option value="Ajeer">Ajeer</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Status
                    </h4>
                    <Form.Select
                      name="status"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          status: e.target.value,
                        })
                      }
                      value={filterInput.status}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Valid">Valid</option>
                      <option value="Expired">Expired</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Valid From
                    </h4>
                    <Form.Control
                      type="date"
                      name="validFrom"
                      placeholder="Enter Valid from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          validFrom: e.target.value,
                        })
                      }
                      value={filterInput.validFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Valid To
                    </h4>
                    <Form.Control
                      type="date"
                      name="validTo"
                      placeholder="Enter Valid to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          validTo: e.target.value,
                        })
                      }
                      value={filterInput.validTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Updated From
                    </h4>
                    <Form.Control
                      type="date"
                      name="updatedFrom"
                      placeholder="Enter Updated from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          updatedFrom: e.target.value,
                        })
                      }
                      value={filterInput.updatedFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Updated To
                    </h4>
                    <Form.Control
                      type="date"
                      name="updatedTo"
                      placeholder="Enter Updated to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          updatedTo: e.target.value,
                        })
                      }
                      value={filterInput.updatedTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LegalFilterModal;
