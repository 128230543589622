import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowClientExportDropdown,
  setShowCLientFilterDropdown,
  setClientPageNo,
  setClientPageSize,
  setFilterClientPageNo,
  setFilterClientPageSize,
} from "../../store/slices/projectSlice";
import { useNavigate } from "react-router-dom";
import { LoaderBlue } from "../Lotties";
import { useDeleteClientReviewMutation } from "../../services/Projects";
import { setClientReviewChange } from "../../store/slices/commonSlice";
import { ClientReview } from "../../interfaces/project";
import { exportToCsv, exportToPDF } from "../../config";
import Content from "./get-all-client-reviews-api";
import ClientReviewFilterModal, {
  FormProps,
} from "../Modals/client-review-filter-modal";
import { useReactToPrint } from "react-to-print";
import SelectSearch from "react-select-search";

const ClientReviews = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const clientReviewChange = useAppSelector(
    (state) => state.common.clientReviewChange,
  );
  const projectIds = useAppSelector((state) => state.project.projectIds);
  // Options
  const options = projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));
  // Pagination && Filter
  const [filterInput, setFilterInput] = useState<FormProps>({
    projectId: "",
    reviewType: "",
    statementDateFrom: "",
    statementDateTo: "",
  });
  const [filterSearch, setFilterSearch] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<ClientReview[]>([]);
  const totalPages = Math.ceil(state.clientTotalRecords / state.clientPageSize);
  const totalCount = filterSearch
    ? state.filterClientTotalRecords
    : state.clientTotalRecords;

  const filterTotalPages = Math.ceil(
    state.filterClientTotalRecords / state.filterClientPageSize,
  );

  const handlePageChange = (page: any) => {
    dispatch(setClientPageNo(page));
  };

  const handleFilterPageChange = (page: any) => {
    dispatch(setFilterClientPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    if (filterSearch) {
      dispatch(setFilterClientPageSize(selectedPageSize));
    } else {
      dispatch(setClientPageSize(selectedPageSize));
    }
  };
  useEffect(() => {
    setFilteredData(state.reviews);
  }, [state.reviews]);
  // Delete Review
  const [deleteClientReview] = useDeleteClientReviewMutation();

  const handleDelete = async (reviewId: string, projectId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [reviewId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.deleteProject || false,
        payload: {
          reviewId: reviewId,
          projectId: projectId,
        },
      };
      await deleteClientReview(data).unwrap();
      dispatch(setClientReviewChange(!clientReviewChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [reviewId]: false }));
    }
  };

  // Table Data
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Date",
      "Client code",
      "Client name",
      "Project Id.",
      "Project Name",
      "Type",
      "Statement",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Review Date": item.statementDate
      ? new Date(item.statementDate).toLocaleDateString()
      : "",
    "Client code": item.clientId,
    "Client name": item.contactPersonName,
    "Project Id.": item.projectId,
    "Project Name": item.projectName,
    "Review Type": item.reviewType,
    "Client's Statement": item.statement,
  }));

  const handleReset = () => {
    setQuery("");
    setSearch("");
    setFilteredData(state.reviews);
    setFilterSearch(false);
    setFilterInput({
      projectId: "",
      reviewType: "",
      statementDateFrom: "",
      statementDateTo: "",
    });
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <ClientReviewFilterModal
        setFilterSearch={setFilterSearch}
        clientReviews={state.reviews}
        query={query}
        filterSearch={filterSearch}
        setClientReviews={setFilteredData}
        show={showFilterModal}
        setShow={setShowFilterModal}
        filterInput={filterInput}
        setFilterInput={setFilterInput}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Client Reviews</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        setQuery(value);
                        setFilterSearch(true);
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Project by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/projects/add-client-review")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  New
                </button>
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Filter{" "}
                </button>
                <Dropdown
                  show={state.showClientExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(setShowCLientFilterDropdown(false));
                      dispatch(
                        setShowClientExportDropdown(
                          !state.showClientExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showClientExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "client-review-list");
                        dispatch(setShowClientExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowClientExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.projectsLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showProject ? (
            <>
              {tableData.tableData.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Client Reviews
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? filterSearch
                                ? (state.filterClientPageNo - 1) *
                                    state.filterClientPageSize +
                                  index +
                                  1
                                : (state.clientPageNo - 1) *
                                    state.clientPageSize +
                                  index +
                                  1
                              : index + 1}
                          </td>
                          <td>
                            {item.statementDate
                              ? new Date(
                                  item.statementDate,
                                ).toLocaleDateString()
                              : ""}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/projects/view-client-review/${item.clientId}`,
                                { state: { data: item } },
                              )
                            }
                          >
                            {item.clientId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/projects/view-client-review/${item.clientId}`,
                                { state: { data: item } },
                              )
                            }
                          >
                            {item.contactPersonName}
                          </td>
                          <td>{item.projectId}</td>
                          <td>{item.projectName}</td>
                          <td>{item.reviewType}</td>
                          <td>{item.statement}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/projects/view-client-review/${item.clientId}`,
                                    { state: { data: item } },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/projects/edit-client-review/${item.clientId}`,
                                    { state: { data: item } },
                                  )
                                }
                              >
                                <img
                                  src={SettingsIcons.EditUser}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              {deleteLoadingMap[item._id] ? (
                                <LoaderBlue height={20} width={20} />
                              ) : (
                                <button
                                  className="btn p-0 border-0"
                                  onClick={() =>
                                    handleDelete(item._id, item.projectId)
                                  }
                                >
                                  <img
                                    src={SettingsIcons.Delete}
                                    width="24px"
                                    height="24px"
                                    alt="edit-user"
                                  />
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No Reviews Found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={
                    filterSearch
                      ? state.filterClientPageSize.toString()
                      : state.clientPageSize.toString()
                  }
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterClientPageNo > 1
                              ? state.filterClientPageNo - 1
                              : 1,
                          )
                        : handlePageChange(
                            state.clientPageNo > 1 ? state.clientPageNo - 1 : 1,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  {filterSearch ? (
                    <>
                      {[...Array(filterTotalPages)].map((_, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handleFilterPageChange(index + 1)}
                          className={
                            index + 1 === state.filterClientPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(index + 1)}
                          className={
                            index + 1 === state.clientPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  )}
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterClientPageNo < filterTotalPages
                              ? state.filterClientPageNo + 1
                              : filterTotalPages,
                          )
                        : handlePageChange(
                            state.clientPageNo < totalPages
                              ? state.clientPageNo + 1
                              : totalPages,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default ClientReviews;
