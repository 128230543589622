import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Table, Pagination } from "react-bootstrap";
import { Camp } from "../../interfaces/employee";
import { Common, EmployeeIcons } from "../../assets/images";

const ViewCamp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const campData: Camp = location.state.data;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = campData.campEmployees.slice(startIndex, endIndex);

  const totalPages = Math.ceil(campData.campEmployees.length / pageSize);

  const totalCount = campData.campEmployees.length;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Category",
      "Working",
      "Status",
    ],
    tableData: paginatedData,
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Camp Detail</h1>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Id
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campId}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Name
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campName}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Supervisor
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campSupervisor}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp City
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campCity}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Address
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campAddress}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Capacity
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campCapacity}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp OwnerShip
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campOwnerShip ? "Owned" : "Rented"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Contract From
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campContractFrom
                    ? new Date(campData.campContractFrom).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Contract To
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campContractTo
                    ? new Date(campData.campContractTo).toLocaleDateString()
                    : "N/A"}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Camp Monthly Rent
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {campData.campMonthlyRent}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Assets Detail</h1>
          </div>
        </Col>
        <Col sm="12">
          {campData.campAssets.length > 0 ? (
            <>
              {campData.campAssets.map((asset, index) => (
                <div className="row" key={index}>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Item Name
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {asset.item}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Item Counts
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {asset.count}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Estimated Value
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {asset.estimatedValue}
                      </h5>
                    </Form.Group>
                  </Col>
                </div>
              ))}
            </>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Assets Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Attachments</h1>
          </div>
        </Col>
        <Col sm="12">
          {campData.campAttachements.length > 0 ? (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Date</th>
                    <th>File Title</th>
                    <th>Uploader Emp Id</th>
                    <th>Uploader Emp Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {campData.campAttachements.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.date
                          ? new Date(item.date).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{item.title}</td>
                      <td>{item.empId}</td>
                      <td>{item.empName}</td>
                      <td>
                        <a href={item.link} download>
                          <img
                            src={Common.Download}
                            className="me-2"
                            width="24px"
                            height="24px"
                            alt="share"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading textBlack"
                  style={{ fontSize: "18px" }}
                >
                  No Attachment Found
                </h4>
              </Form.Group>
            </Col>
          )}
        </Col>
        <Col sm="12">
          <div className="selected-employees mt-4">
            <h4 className="sub-heading">Camps Employees</h4>
            {tableData.tableData.length > 0 ? (
              <Table striped bordered responsive className=" text-center">
                <thead>
                  <tr className="border-0">
                    {tableData.tableHeading.map((item, index) => (
                      <th className="border-0" key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.tableData.map((item, index) => (
                    <tr
                      key={index}
                      style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                    >
                      <td>
                        {totalCount > 0
                          ? (currentPage - 1) * pageSize + index + 1
                          : index + 1}
                      </td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/employees/employee-detail/${item.empId}`, {
                            state: { empId: item.empId },
                          })
                        }
                      >
                        {item.empId}
                      </td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/employees/employee-detail/${item.empId}`, {
                            state: { empId: item.empId },
                          })
                        }
                      >
                        {item.empName}
                      </td>
                      <td>{item.empCategory}</td>
                      <td>{item.empWorking}</td>
                      <td>{item.empStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">No Employees Selected</h1>
              </div>
            )}
          </div>
        </Col>
        {paginatedData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewCamp;
