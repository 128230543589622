import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { PettyCash } from "../../interfaces/petty-cash";

interface LedgerList {
  data: {
    ledger: PettyCash[];
    totalCredit: number;
    totalDebit: number;
    lastMonthBalance: number;
  };
}

const formattedDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const dateObject = new Date(dateString);
  const formattedDateString = dateObject.toLocaleDateString("en-GB", options);

  // Extracting year, month, and day components
  const [day, month, year] = formattedDateString.split("/");

  // Concatenating in the desired order
  return `${year}-${month}-${day}`;
};

export const pettyCashAPI = createApi({
  reducerPath: "/api/patty-cash",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getLedger: builder.query<
      LedgerList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        dateData: string;
      }
    >({
      query: (data) => ({
        url: `/ledger/${data.permission}/${formattedDate(data.dateData)}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addDebit: builder.mutation({
      query: (data) => ({
        url: `/debit/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addCredit: builder.mutation({
      query: (data) => ({
        url: `/credit/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editLedger: builder.mutation({
      query: (data) => ({
        url: `/edit-ledger/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterLedger: builder.mutation<
      LedgerList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/search-transaction/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useAddDebitMutation,
  useAddCreditMutation,
  useGetLedgerQuery,
  useEditLedgerMutation,
  useGetFilterLedgerMutation,
} = pettyCashAPI;
