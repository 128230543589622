import React from "react";
import InternalLayout from "../components/InternalLayout";
import CertificateComponent from "../components/Certificate";
import withAuth from "../config/private-route";

const Certificate = () => {
  return (
    <InternalLayout>
      <CertificateComponent />
    </InternalLayout>
  );
};
export default withAuth(Certificate);
