import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetPayslipListQuery } from "../../services/EmployeePayroll";
import {
  setPayslips,
  setPayslipError,
  setPayslipLoading,
  setPayslipTotalRecords,
} from "../../store/slices/employeePayrollSlice";

interface Props {
  status: string;
}

const Content = ({ status }: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const payrollChange = useAppSelector((state) => state.common.payrollChange);
  // States
  const state = useAppSelector((state) => state.employeePayroll);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allPayslipIds,
    isLoading: payslipIdsLoading,
    isError: payslipIdsError,
    refetch: payslipIdsRefetch,
  } = useGetPayslipListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    empIds: state.searchIds,
    status: status,
    fromDate: new Date(state.payStartFrom).toLocaleDateString(),
    toDate: new Date(state.payEndTo).toLocaleDateString(),
    pageNo: state.payslipPageNo,
    pageSize: state.payslipPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (payslipIdsLoading) {
      dispatch(setPayslipLoading(true));
    }
    if (payslipIdsError) {
      dispatch(setPayslipError("Something went wrong"));
      dispatch(setPayslipLoading(false));
    }
    if (allPayslipIds) {
      dispatch(setPayslips(allPayslipIds.data.filteredPayrolls[0].data || []));
      dispatch(
        setPayslipTotalRecords(
          allPayslipIds.data.filteredPayrolls[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setPayslipLoading(false));
    }
  }, [
    accessToken,
    userID,
    allPayslipIds,
    profileData,
    state.searchIds,
    state.payStartFrom,
    state.payEndTo,
    state.payslipPageNo,
    state.payslipPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    payslipIdsRefetch();
  }, [
    payrollChange,
    state.searchIds,
    state.payStartFrom,
    state.payEndTo,
    state.payslipPageNo,
    state.payslipPageSize,
  ]);
  return <></>;
};
export default Content;
