import React from "react";
import { Row, Col } from "react-bootstrap";
import TabComponent from "../TabComponent";
// Redux import
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowNotification,
  setActiveTab,
} from "../../store/slices/notificationsSlice";
import NotificationsList from "./notifications";
import ApprovalsList from "./approvals";

const NotificationsComponent = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.notification);
  // Handle Tab change
  const handleTabChange = (tab: string) => {
    dispatch(setActiveTab(tab));
    if (tab === "Notifications") {
      dispatch(setShowNotification(true));
    }
    if (tab === "Approval Requests") {
      dispatch(setShowNotification(false));
    }
  };
  return (
    <>
      <Row>
        <Col xxl="8" xl="10" lg="12" md="12" sm="12" className="mx-auto">
          <TabComponent
            tabList={state.tabList}
            activeTab={state.activeTab}
            sectionName="Notifications & Approvals"
            handleTabChange={handleTabChange}
          ></TabComponent>
        </Col>
      </Row>
      {state.showNotification ? <NotificationsList /> : <ApprovalsList />}
    </>
  );
};

export default NotificationsComponent;
