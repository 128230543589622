import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Common } from "../../assets/images";

const PettyCashDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  // Using the URL API to extract the pathname
  const pathname = data.transactionImg
    ? new URL(data.transactionImg).pathname
    : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split("/").pop();

  // Extracting the file extension from the file name
  const fileExtension = fileNameWithExtension
    ? fileNameWithExtension.split(".").pop()
    : "";
  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">
              {data.transactionType === "Debit" ? "Debit" : "Credit"} Petty Cash
            </h1>
            <div className="transaction-id mt-4 d-flex align-items-center gap-2 mb-4">
              <h4 className="sub-heading normal-font-weight textBlack">
                Transaction ID:
              </h4>
              <h4 className="sub-heading normal-font-weight">
                {data.transactionId}
              </h4>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="3">
              <div className="emp-detail-item">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-2"
                  style={{ fontSize: "18px" }}
                >
                  Expense Type
                </h4>
                <p
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.expenseType}
                </p>
              </div>
            </Col>
            <Col sm="3">
              <div className="emp-detail-item">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-2"
                  style={{ fontSize: "18px" }}
                >
                  Expense Amount:
                </h4>
                <p
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.transactionAmount} SAR
                </p>
              </div>
            </Col>
            <Col sm="3">
              <div className="emp-detail-item">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-2"
                  style={{ fontSize: "18px" }}
                >
                  Expense Date
                </h4>
                <p
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.transactionDate
                    ? new Date(data.transactionDate).toLocaleDateString()
                    : ""}
                </p>
              </div>
            </Col>
            {data.transactionMethode ? (
              <Col sm="3">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Transaction Method
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {data.transactionMethode}
                  </p>
                </div>
              </Col>
            ) : null}
            <Col sm="12">
              <div className="emp-detail-item">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-2"
                  style={{ fontSize: "18px" }}
                >
                  Expense Detail
                </h4>
                <p
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {data.transactionDetail}
                </p>
              </div>
            </Col>
            <Col sm="12">
              <div className="emp-detail-item mb-4">
                <h4
                  className="sub-heading normal-font-weight textBlack mb-2"
                  style={{ fontSize: "18px" }}
                >
                  Attachment
                </h4>
                {!data.transactionImg ? (
                  <div>
                    <h4 className="sub-heading">No Attachment</h4>
                  </div>
                ) : fileExtension === "pdf" ? (
                  <div className="uploaded-item position-relative mt-4 w-25">
                    <div className="upload-item-header">
                      <img src={Common.PDF} width="100%" alt="uploaded-item" />

                      <div className="upload-item-body d-flex justify-content-between align-items-center">
                        <p className="mb-0">{fileNameWithExtension}</p>
                        <div className="ms-auto text-end">
                          <a href={data.transactionImg} download>
                            <img
                              src={Common.Download}
                              className="me-2"
                              width="24px"
                              height="24px"
                              alt="share"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="uploaded-item position-relative mt-4 w-25">
                    <div className="upload-item-header">
                      <img
                        src={data.transactionImg}
                        width="100%"
                        alt="uploaded-item"
                      />

                      <div className="upload-item-body d-flex justify-content-between align-items-center">
                        <p className="mb-0">{fileNameWithExtension}</p>
                        <div className="ms-auto text-end">
                          <a href={data.transactionImg} download>
                            <img
                              src={Common.Download}
                              className="me-2"
                              width="24px"
                              height="24px"
                              alt="share"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="d-flex align-items-center gap-3 justify-content-end">
            <button
              className="btn secondary-button w-25"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              className="btn primary-button w-25"
              onClick={() =>
                navigate(
                  data.transactionType === "Debit"
                    ? `/petty-cash/edit-debit/${data.transactionId}`
                    : `/petty-cash/edit-credit/${data.transactionId}`,
                  { state: { data: data } },
                )
              }
            >
              Edit Detail
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PettyCashDetail;
