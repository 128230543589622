import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters

import { useGetAllActiveEmployeesQuery } from "../../../services/Employees";
import {
  setActiveEmpLoading,
  setActiveEmployees,
  setActiveEmpResponseError,
  setActiveTotalRecords,
} from "../../../store/slices/employeeSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const projectChange = useAppSelector((state) => state.common.projectChange);

  // States
  const employeeState = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Employees
  // **************
  // **************
  const {
    data: allEmployees,
    isLoading: employeeLoading,
    isError: employeeError,
    refetch: employeeRefetch,
  } = useGetAllActiveEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    pageNo: employeeState.activePageNo,
    pageSize: employeeState.activePageSize,
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (employeeLoading) {
      dispatch(setActiveEmpLoading(true));
    }
    if (employeeError) {
      dispatch(setActiveEmpResponseError("Something went wrong"));
      dispatch(setActiveEmpLoading(false));
    }
    if (allEmployees) {
      dispatch(setActiveEmployees(allEmployees.data.employees[0].data || []));
      dispatch(
        setActiveTotalRecords(
          allEmployees.data.employees[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setActiveEmpLoading(false));
    }
  }, [
    accessToken,
    userID,
    allEmployees,
    profileData,
    employeeState.activePageNo,
    employeeState.activePageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    employeeRefetch();
  }, [employeeChange, projectChange]);
  return <></>;
};
export default Content;
