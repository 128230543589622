import React from "react";
import { Routes, Route } from "react-router-dom";
// Pages
// Preloader page
import PreLoader from "./pre-loader";
// Authentication pages
import Login from "./login";
import ResetPassword from "./reset-password";
import ForgotPassword from "./forgot-password";
import OtpVerification from "./otp-verification";
import ProfileCompletion from "./profile-completion";
// Dashboard pages
import Dashboard from "./dashboard";
// Settings pages
import Settings from "./settings";
import UserSettings from "../components/Settings/user-settings";
import UsersList from "../components/Settings/users";
import Permissions from "../components/Settings/permissions";
import AddUser from "../components/Settings/add-user";
import EditUser from "../components/Settings/edit-user";
import ViewUser from "../components/Settings/view-user";
import AddRole from "../components/Settings/add-role";
import EditRole from "../components/Settings/edit-role";
import ViewRole from "../components/Settings/view-role";
// Acknowledgement pages
import Acknowledgement from "./acknowledgment";
// Certificate pages
import Certificate from "./certificate";
import CertificateDetail from "../components/Certificate/certificate-detail";
import Certificates from "../components/Employees/certificates";
// Employees pages
import Employees from "./employee";
import AllEmployees from "../components/Employees/all-employees";
import AbscondedEmployees from "../components/Employees/absconded-employees";
import FinalExitEmployees from "../components/Employees/final-exit-employees";
import ProbationEmployees from "../components/Employees/probation-employees";
import TerminatedEmployees from "../components/Employees/terminated-employees";
import AddEmployeeContainer from "../components/Employees/AddEmployee/add-employee-container";
import EditEmployeeContainer from "../components/Employees/EditEmployee/edit-employee-container";
import EmployeeDetailContainer from "../components/Employees/EmployeeDetail/employee-detail-container";
import ViewEmployeeBehaviourStatement from "../components/Employees/view-employee-behaviour-statement";
import ActiveEmployees from "../components/Employees/active-employees";
// Employee Actions
import FinalExit from "../components/Employees/Actions/final-exit";
import EditFinalExit from "../components/Employees/Actions/edit-final-exit";
import LeaveRequest from "../components/Employees/Actions/leave-request";
import EditLeaveRequest from "../components/Employees/Actions/edit-leave-request";
import SalaryIncrement from "../components/Employees/Actions/salary-increment";
import EditSalaryIncrement from "../components/Employees/Actions/edit-salary-increment";
import WorkRefusal from "../components/Employees/Actions/work-refusal";
import FineClearance from "../components/Employees/Actions/fine-clearance";
import EditFineClearance from "../components/Employees/Actions/edit-fine-clearance";
import EmployeeWarning from "../components/Employees/Actions/employee-warning";
import EditEmployeeWarning from "../components/Employees/Actions/edit-employee-warning";
// Attendance pages
import Attendance from "./attendance";
import AttendanceForm from "../components/Attendance/attendance-form";
import EditAttendance from "../components/Attendance/edit-attendance";
import AttendanceLog from "../components/Attendance/attendance-log";
import EmployeeGurantee from "../components/Attendance/employee-gurantee";
// Project pages
import Project from "./project";
import AddProject from "../components/Project/add-project";
import AllProjects from "../components/Project/all-projects";
import ProjectDetail from "../components/Project/project-detail";
import EditProject from "../components/Project/edit-project";
import TransferNewJoiner from "../components/Project/transfer-new-joiner";
import ClientReviews from "../components/Project/client-reviews";
import AddClientReviews from "../components/Project/add-client-reviews";
import ViewClientReview from "../components/Project/view-client-review";
import EditClientReviews from "../components/Project/edit-client-reviews";
import TransferRequestsContainer from "../components/Project/transfer-requests-container";
import TransferRequestDetail from "../components/Project/transfer-request-detail";
// Initial Evaluation pages
import OfferLetter from "../components/InitialEvaluation/offer-letter";
import OfferLetterDetail from "../components/InitialEvaluation/offer-letter-detail";
import FinalExitDetail from "../components/InitialEvaluation/final-exit-detail";
import ProbationForm from "../components/InitialEvaluation/probation-form";
import EditProbation from "../components/InitialEvaluation/edit-probation";
import ProbationDetail from "../components/InitialEvaluation/probation-detail";
// Petty Cash pages
import PettyCash from "./petty-cash";
import PettyCashLedger from "../components/PettyCash/petty-cash-ledger";
import DebitPettyCash from "../components/PettyCash/debit-petty-cash";
import CreditPettyCash from "../components/PettyCash/credit-petty-cash";
import PettyCashDetail from "../components/PettyCash/petty-cash-detail";
import EditDebitPettyCash from "../components/PettyCash/edit-debit-petty-cash";
import EditCreditPettyCash from "../components/PettyCash/edit-credit-petty-cash";
// import RevenueSheet from "../components/PettyCash/revenue-sheet";
// import AddRevenueSheet from "../components/PettyCash/add-revenue-sheet";
// import EditRevenueSheet from "../components/PettyCash/edit-revenue-sheet";
// import ViewRevenueSheet from "../components/PettyCash/view-revenue-sheet";
// Iqama pages
import Iqama from "./iqama";
import IqamaList from "../components/Iqama/iqama-list";
import IqamaDetail from "../components/Iqama/iqama-detail";
// Absconding Pages
import Absconding from "./absconding";
import EmployeeAbsconding from "../components/Absconding/employee-absconding";
import EditEmployeeAbsconding from "../components/Absconding/edit-employee-absconding";
import AbscondDetail from "../components/Absconding/abconding-detail";
import EmployeeBehaviourStatement from "../components/Employees/emplyee-behaviour-statement";
// Timesheet Pages
import Timesheet from "./timesheet";
import SalarySheet from "../components/Timesheets/salarysheet";
import RevenueSheet from "../components/Timesheets/revenuesheet";
import ActualSalarySheet from "../components/Timesheets/actual-salarysheet";
import ActualRevenueSheet from "../components/Timesheets/actual-revenuesheet";
import ViolationsLogSheets from "../components/Timesheets/violations-log-sheets";
import LeaveRequests from "../components/Timesheets/leave-requests";
import LeaveRequestDetail from "../components/Timesheets/leave-request-detail";
import SalaryIncrements from "../components/Timesheets/salary-increments";
import SalaryIncrementDetail from "../components/Timesheets/salary-increment-detail";
// Notifications
import Notifications from "./notifications";
// Violation Letter
import FineClearanceLetter from "../components/Letters/fine-clearance-letter";
// Interview
import Interview from "./interview";
import AddInterviewer from "../components/Interviews/add-interviewer";
import AllInterviewers from "../components/Interviews/all-interviewers";
import EditInterviewer from "../components/Interviews/edit-interviewer";
import ViewInterviewer from "../components/Interviews/view-interviewer";
// Clients
import Clients from "./clients";
import AddClient from "../components/Clients/add-client";
import AllClients from "../components/Clients/all-clients";
import EditClient from "../components/Clients/edit-client";
import ViewClient from "../components/Clients/view-client";
import CreateInvoice from "./create-invoice";
import EditInvoice from "../components/Clients/edit-invoice";
import ViewInvoice from "../components/Clients/view-invoice";
import ViewPayment from "../components/Clients/view-payment";
import EditCustomerPayment from "../components/Clients/edit-payment";
// Camps
import Camps from "./camps";
import AddCamp from "../components/Camps/add-camp";
import AllCamps from "../components/Camps/all-camps";
import EditCamp from "../components/Camps/edit-camp";
import ViewCamp from "../components/Camps/view-camp";
import HR from "./hr";
import ViewExpenses from "../components/HR/view-expenses";
// Recruitment Agencies
import RecruitAgency from "./recruit-agency";
import AddRecruitAgency from "../components/RecruitAgency/add-recruit-agency";
import AllRecruitAgencies from "../components/RecruitAgency/all-recruit-agencies";
import EditRecruitAgency from "../components/RecruitAgency/edit-recruit-agency";
import ViewRecruitAgency from "../components/RecruitAgency/view-recruit-agency";
// Visa
import AllVisa from "../components/RecruitAgency/all-visa";
import AddVisa from "../components/RecruitAgency/add-visa";
import ViewVisa from "../components/RecruitAgency/view-visa";
import EditVisa from "../components/RecruitAgency/edit-visa";
// Legal
import Legal from "./legal";
import AllLegalList from "../components/Legals/all-legal-list";
import AddLegal from "../components/Legals/add-legal";
import EditLegal from "../components/Legals/edit-legal";
import ViewLegalDetail from "../components/Legals/view-legal-detail";
// Insurance
import Insurance from "./insurance";
import AllInsurance from "../components/Insurance/all-insurance";
// Account Charts
import AccountCharts from "./account-charts";
import AllAccounts from "../components/AccountCharts/all-accounts";
import AddAccountChart from "../components/AccountCharts/add-account";
import EditAccountChart from "../components/AccountCharts/edit-account";
import ViewAccount from "../components/AccountCharts/view-account";
// Manual Journal
import ManualJournal from "./manual-journal";
import AllJournals from "../components/ManualJournal/allJournals";
import AddJournal from "../components/ManualJournal/addJournal";
import ViewJournal from "../components/ManualJournal/view-journal";
import EditJournal from "../components/ManualJournal/edit-journal";
// Owners
import Owners from "./owners";
import AllOwners from "../components/Owners/all-owners";
import AddOwner from "../components/Owners/add-owner";
import EditOwner from "../components/Owners/edit-owner";
import ViewOwner from "../components/Owners/view-owner";
// Vendors
import Vendors from "./vendors";
import AllVendors from "../components/Vendors/all-vendors";
import AddVendor from "../components/Vendors/add-vendor";
import EditVendor from "../components/Vendors/edit-vendor";
import ViewVendor from "../components/Vendors/view-vendor";
// Sponsors
import Sponsors from "./sponsors";
import AllSponsors from "../components/Sponsor/all-sponsors";
import AddSponsor from "../components/Sponsor/add-sponsor";
import EditSponsor from "../components/Sponsor/edit-sponsor";
import ViewSponsor from "../components/Sponsor/view-sponsor";
// Employee Payroll
import EmployeePayroll from "./employee-payroll";
import CreatePayroll from "../components/EmployeePayrolls/create-payroll";
import ViewCreatePayroll from "../components/EmployeePayrolls/view-create-payroll";
import Payslips from "../components/EmployeePayrolls/payslips";
import ViewPayslip from "../components/EmployeePayrolls/view-payslip";
// Billing
import Billing from "./billing";
import AddBilling from "../components/Billing/addBilling";
import EditBilling from "../components/Billing/editBilling";
import AllBills from "../components/Billing/allBills";
import ViewBill from "../components/Billing/view-bill";
import VendorBills from "../components/Billing/billing-finance-list";
import ViewFinanceBill from "../components/Billing/view-finance-bill";
// Reports
import Reports from "./reports";
import ReportsContainer from "../components/Reports/reports-container";
import CustomerBalance from "../components/Reports/CustomerBalance";
import Sales from "../components/Reports/Sales";
import CreditNoteDetails from "../components/Reports/CreditNoteDetails";
import PaymentsRecieved from "../components/Reports/PaymentsRecieved";

const AllRoutes = () => {
  return (
    <Routes>
      {/* Preloader */}
      <Route path="/" element={<PreLoader />} />
      {/* Login */}
      <Route path="/login" element={<Login />} />
      {/* Reset Password */}
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* Forgot Password */}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* OTP Verification */}
      <Route path="/otp-verification" element={<OtpVerification />} />
      {/* Profile Completion */}
      <Route path="/profile-completion" element={<ProfileCompletion />} />
      {/* Dashboard */}
      <Route path="/dashboard" element={<Dashboard />} />
      {/* Notifications */}
      <Route path="/notifications" element={<Notifications />} />
      {/*  Settings  */}
      <Route path="/settings" element={<Settings />}>
        <Route path="/settings/user-profile" element={<UserSettings />} />
        <Route path="/settings/users" element={<UsersList />} />
        <Route path="/settings/designations" element={<Permissions />} />
        <Route path="/settings/add-user" element={<AddUser />} />
        <Route path="/settings/edit-user/:srno" element={<EditUser />} />
        <Route path="/settings/view-user/:name" element={<ViewUser />} />
        <Route path="/settings/add-role" element={<AddRole />} />
        <Route path="/settings/edit-role/:srnos" element={<EditRole />} />
        <Route path="/settings/view-role/:ename" element={<ViewRole />} />
      </Route>
      {/* Acknowledgement */}
      <Route path="/acknowledgment" element={<Acknowledgement />} />
      {/* Certificate */}
      <Route path="/certificates" element={<Certificate />} />
      <Route
        path="/certificate-detail/:empNo"
        element={<CertificateDetail />}
      />
      {/*  Employees  */}
      <Route path="/employees" element={<Employees />}>
        <Route path="/employees/" element={<AllEmployees />} />
        <Route
          path="/employees/active-employees"
          element={<ActiveEmployees />}
        />
        <Route
          path="/employees/absconded-employees"
          element={<AbscondedEmployees />}
        />
        <Route
          path="/employees/final-exit-employees"
          element={<FinalExitEmployees />}
        />
        <Route
          path="/employees/probation-employees"
          element={<ProbationEmployees />}
        />
        <Route
          path="/employees/terminated-employees"
          element={<TerminatedEmployees />}
        />
        <Route
          path="/employees/add-employee"
          element={<AddEmployeeContainer />}
        />
        <Route
          path="/employees/edit-employee/:empNo"
          element={<EditEmployeeContainer />}
        />
        <Route
          path="/employees/employee-detail/:empNo"
          element={<EmployeeDetailContainer />}
        />
        <Route
          path="/employees/employee-behaviour-statement"
          element={<EmployeeBehaviourStatement />}
        />
        <Route
          path="/employees/edit-employee-behaviour-statement/:empNo"
          element={<EditEmployeeWarning />}
        />
        <Route
          path="/employees/view-employee-behaviour-statement/:empNo"
          element={<ViewEmployeeBehaviourStatement />}
        />
        {/* Actions   */}
        <Route path="/employees/final-exit" element={<FinalExit />} />
        <Route
          path="/employees/edit-final-exit/:empNo"
          element={<EditFinalExit />}
        />
        <Route
          path="/employees/view-final-exit-employee/:empNo"
          element={<FinalExitDetail />}
        />
        <Route path="/employees/leave-request" element={<LeaveRequest />} />
        <Route
          path="/employees/edit-leave-request/:empNo"
          element={<EditLeaveRequest />}
        />
        <Route
          path="/employees/salary-increment"
          element={<SalaryIncrement />}
        />
        <Route
          path="/employees/edit-salary-increment/:empNo"
          element={<EditSalaryIncrement />}
        />
        <Route path="/employees/work-refusal" element={<WorkRefusal />} />
        <Route path="/employees/fine-clearance" element={<FineClearance />} />
        <Route
          path="/employees/fine-clearance-letter/:empNo"
          element={<FineClearanceLetter />}
        />
        <Route
          path="/employees/edit-fine-clearance/:empNo"
          element={<EditFineClearance />}
        />
        <Route
          path="/employees/employee-warning"
          element={<EmployeeWarning />}
        />
      </Route>
      {/*  Attendance */}
      <Route path="/attendance" element={<Attendance />}>
        <Route
          path="/attendance/attendance-form"
          element={<AttendanceForm />}
        />
        <Route
          path="/attendance/edit-attendance/:empNo"
          element={<EditAttendance />}
        />
        <Route path="/attendance/attendance-log" element={<AttendanceLog />} />
        <Route
          path="/attendance/employee-gurantee"
          element={<EmployeeGurantee />}
        />
      </Route>
      {/* Interviews / Initial Evaluation */}
      <Route path="/initial-evaluation" element={<Interview />}>
        <Route path="/initial-evaluation/" element={<AllInterviewers />} />
        <Route
          path="/initial-evaluation/add-interviewer"
          element={<AddInterviewer />}
        />
        <Route
          path="/initial-evaluation/edit-interviewer/:interviewerId"
          element={<EditInterviewer />}
        />
        <Route
          path="/initial-evaluation/view-interviewer/:interviewerId"
          element={<ViewInterviewer />}
        />
        <Route
          path="/initial-evaluation/create-offer-letter/:id"
          element={<OfferLetter />}
        />
        <Route
          path="/initial-evaluation/probation-form"
          element={<ProbationForm />}
        />
        <Route
          path="/initial-evaluation/edit-probation/:empNo"
          element={<EditProbation />}
        />
        <Route
          path="/initial-evaluation/probation-detail/:empNo"
          element={<ProbationDetail />}
        />
        <Route
          path="/initial-evaluation/offer-letter-detail/:empNo"
          element={<OfferLetterDetail />}
        />
      </Route>
      {/*  Project */}
      <Route path="/projects" element={<Project />}>
        <Route path="/projects/" element={<AllProjects />} />
        <Route path="/projects/add-project" element={<AddProject />} />
        <Route
          path="/projects/project-detail/:code"
          element={<ProjectDetail />}
        />
        <Route path="/projects/edit-project/:code" element={<EditProject />} />
        <Route
          path="/projects/transfer-employee"
          element={<TransferNewJoiner />}
        />
        <Route
          path="/projects/transfer-employee-requests"
          element={<TransferRequestsContainer />}
        />
        <Route
          path="/projects/transfer-request-detail/:empNo"
          element={<TransferRequestDetail />}
        />
        <Route
          path="/projects/newjoiner-request-detail/:empNo"
          element={<TransferRequestDetail />}
        />
        <Route path="/projects/client-reviews" element={<ClientReviews />} />
        <Route
          path="/projects/add-client-review"
          element={<AddClientReviews />}
        />
        <Route
          path="/projects/view-client-review/:reviewID"
          element={<ViewClientReview />}
        />
        <Route
          path="/projects/edit-client-review/:reviewID"
          element={<EditClientReviews />}
        />
      </Route>
      {/* Clients */}
      <Route path="/clients" element={<Clients />}>
        <Route path="/clients/" element={<AllClients />} />
        <Route path="/clients/add-client" element={<AddClient />} />
        <Route path="/clients/edit-client/:clientID" element={<EditClient />} />
        <Route path="/clients/view-client/:clientID" element={<ViewClient />} />
        <Route
          path="/clients/view-invoice/:invoiceID"
          element={<ViewInvoice />}
        />
        <Route
          path="/clients/edit-invoice/:invoiceID"
          element={<EditInvoice />}
        />
        <Route
          path="/clients/view-payment/:paymentID"
          element={<ViewPayment />}
        />
        <Route
          path="/clients/edit-payment/:paymentID"
          element={<EditCustomerPayment />}
        />
      </Route>
      <Route path="/create-transaction" element={<CreateInvoice />} />
      {/* Camps */}
      <Route path="/camps" element={<Camps />}>
        <Route path="/camps/" element={<AllCamps />} />
        <Route path="/camps/add-camp" element={<AddCamp />} />
        <Route path="/camps/edit-camp/:campID" element={<EditCamp />} />
        <Route path="/camps/view-camp/:campID" element={<ViewCamp />} />
      </Route>
      {/*  Petty Cash */}
      <Route path="/petty-cash" element={<PettyCash />}>
        <Route path="/petty-cash/ledger" element={<PettyCashLedger />} />
        <Route path="/petty-cash/debit" element={<DebitPettyCash />} />
        <Route path="/petty-cash/credit" element={<CreditPettyCash />} />
        <Route
          path="/petty-cash/detail/:transId"
          element={<PettyCashDetail />}
        />
        <Route
          path="/petty-cash/edit-debit/:transId"
          element={<EditDebitPettyCash />}
        />
        <Route
          path="/petty-cash/edit-credit/:transId"
          element={<EditCreditPettyCash />}
        />
      </Route>
      {/*  Iqama  */}
      <Route path="/iqama" element={<Iqama />}>
        <Route path="/iqama/" element={<IqamaList />} />
        <Route path="/iqama/iqama-detail/:iqamaNo" element={<IqamaDetail />} />
      </Route>
      {/*  Absconding  */}
      <Route path="/absconding" element={<Absconding />}>
        <Route
          path="/absconding/employee-absconding"
          element={<EmployeeAbsconding />}
        />
        <Route
          path="/absconding/edit-employee-absconding/:empNo"
          element={<EditEmployeeAbsconding />}
        />
        <Route
          path="/absconding/abscond-detail/:empNo"
          element={<AbscondDetail />}
        />
      </Route>
      {/*  Timesheet  */}
      <Route path="/timesheet" element={<Timesheet />}>
        <Route path="/timesheet/salarysheet" element={<SalarySheet />} />
        <Route path="/timesheet/revenue-sheet" element={<RevenueSheet />} />
        <Route
          path="/timesheet/actual-salarysheet"
          element={<ActualSalarySheet />}
        />
        <Route
          path="/timesheet/actual-revenuesheet"
          element={<ActualRevenueSheet />}
        />
        {/*<Route*/}
        {/*  path="/timesheet/add-revenue-sheet"*/}
        {/*  element={<AddRevenueSheet />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/timesheet/view-revenue-sheet/:sheet"*/}
        {/*  element={<ViewRevenueSheet />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/timesheet/edit-revenue-sheet/:sheet"*/}
        {/*  element={<EditRevenueSheet />}*/}
        {/*/>*/}
      </Route>
      {/* HR */}
      <Route path="/hr" element={<HR />}>
        <Route path="/hr/expenses" element={<ViolationsLogSheets />} />
        <Route path="/hr/view-expense/:expId" element={<ViewExpenses />} />
        <Route path="/hr/leave-requests" element={<LeaveRequests />} />
        <Route
          path="/hr/leave-request-detail/:empNo"
          element={<LeaveRequestDetail />}
        />
        <Route path="/hr/salary-increments" element={<SalaryIncrements />} />
        <Route
          path="/hr/salary-increment-detail/:empNo"
          element={<SalaryIncrementDetail />}
        />
        <Route path="/hr/certificates" element={<Certificates />} />
      </Route>
      {/* Recruitment Agencies */}
      <Route path="/recruit-agency" element={<RecruitAgency />}>
        <Route
          path="/recruit-agency/recruiters"
          element={<AllRecruitAgencies />}
        />
        <Route
          path="/recruit-agency/add-recruit-agency"
          element={<AddRecruitAgency />}
        />
        <Route
          path="/recruit-agency/edit-recruit-agency/:agencyID"
          element={<EditRecruitAgency />}
        />
        <Route
          path="/recruit-agency/view-recruit-agency/:agencyID"
          element={<ViewRecruitAgency />}
        />
        <Route path="/recruit-agency/visa" element={<AllVisa />} />
        <Route path="/recruit-agency/add-visa" element={<AddVisa />} />
        <Route
          path="/recruit-agency/view-visa/:visaID"
          element={<ViewVisa />}
        />
        <Route
          path="/recruit-agency/edit-visa/:visaID"
          element={<EditVisa />}
        />
      </Route>
      {/*  Legal  */}
      <Route path="/legal" element={<Legal />}>
        <Route path="/legal/" element={<AllLegalList />} />
        <Route path="/legal/add-legal" element={<AddLegal />} />
        <Route path="/legal/edit-legal/:legalID" element={<EditLegal />} />
        <Route
          path="/legal/view-legal/:legalID"
          element={<ViewLegalDetail />}
        />
      </Route>
      {/*  Insurance  */}
      <Route path="/insurance" element={<Insurance />}>
        <Route path="/insurance/" element={<AllInsurance />} />
      </Route>
      {/*  Account Charts  */}
      <Route path="/account-charts" element={<AccountCharts />}>
        <Route
          path="/account-charts/add-account"
          element={<AddAccountChart />}
        />
        <Route
          path="/account-charts/edit-account/:accountID"
          element={<EditAccountChart />}
        />
        <Route
          path="/account-charts/view-account/:accountID"
          element={<ViewAccount />}
        />
        <Route path="/account-charts/" element={<AllAccounts />} />
      </Route>
      {/*  Manual Journal  */}
      <Route path="/manual-journal" element={<ManualJournal />}>
        <Route path="/manual-journal/add-journal" element={<AddJournal />} />
        <Route
          path="/manual-journal/view-journal/:journalID"
          element={<ViewJournal />}
        />
        <Route path="/manual-journal/" element={<AllJournals />} />
        <Route
          path="/manual-journal/edit-journal/:journalID"
          element={<EditJournal />}
        />
      </Route>
      {/*  Owners  */}
      <Route path="/company-employees" element={<Owners />}>
        <Route path="/company-employees/" element={<AllOwners />} />
        <Route
          path="/company-employees/add-company-employee"
          element={<AddOwner />}
        />
        <Route
          path="/company-employees/edit-company-employee/:companyID"
          element={<EditOwner />}
        />
        <Route
          path="/company-employees/view-company-employee/:companyID"
          element={<ViewOwner />}
        />
      </Route>
      {/*  Vendors  */}
      <Route path="/vendors" element={<Vendors />}>
        <Route path="/vendors/" element={<AllVendors />} />
        <Route path="/vendors/add-vendor" element={<AddVendor />} />
        <Route path="/vendors/edit-vendor/:vendorID" element={<EditVendor />} />
        <Route path="/vendors/view-vendor/:vendorID" element={<ViewVendor />} />
      </Route>
      {/*  Sponsors  */}
      <Route path="/companies" element={<Sponsors />}>
        <Route path="/companies/" element={<AllSponsors />} />
        <Route path="/companies/add-company" element={<AddSponsor />} />
        <Route
          path="/companies/edit-company/:companyID"
          element={<EditSponsor />}
        />
        <Route
          path="/companies/view-company/:companyID"
          element={<ViewSponsor />}
        />
      </Route>
      {/* Employee Payroll */}
      <Route path="/employees-payroll" element={<EmployeePayroll />}>
        <Route
          path="/employees-payroll/create-payrolls"
          element={<CreatePayroll />}
        />
        <Route
          path="/employees-payroll/view-create-payroll/:empId"
          element={<ViewCreatePayroll />}
        />
        <Route path="/employees-payroll/payslips" element={<Payslips />} />
        <Route
          path="/employees-payroll/view-payslip/:payslipId"
          element={<ViewPayslip />}
        />
      </Route>
      {/* Billing */}
      <Route path="/billing" element={<Billing />}>
        <Route path="/billing/create-bill" element={<AddBilling />} />
        <Route path="/billing/view-bill/:billId" element={<ViewBill />} />
        <Route path="/billing/" element={<AllBills />} />
        <Route path="/billing/edit-bill/:billId" element={<EditBilling />} />
        <Route path="/billing/bills-payable" element={<VendorBills />} />
        <Route
          path="/billing/view-finance-bill"
          element={<ViewFinanceBill />}
        />
      </Route>
      {/* Reports */}
      <Route path="/reports" element={<Reports />}>
        <Route path="/reports/" element={<ReportsContainer />} />
        <Route path="/reports/customer-balance" element={<CustomerBalance />} />
        <Route path="/reports/sales" element={<Sales />} />
        <Route
          path="/reports/credit-note-details"
          element={<CreditNoteDetails />}
        />
        <Route
          path="/reports/payments-received"
          element={<PaymentsRecieved />}
        />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
