import React from "react";
import InternalLayout from "../components/InternalLayout";
import SponsorComponent from "../components/Sponsor";
import Content from "../components/Sponsor/get-all-sponsor-api";
import withAuth from "../config/private-route";

const Sponsors = () => {
  return (
    <InternalLayout>
      <Content />
      <SponsorComponent />
    </InternalLayout>
  );
};
export default withAuth(Sponsors);
