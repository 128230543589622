import React, { useRef, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailActiveTab,
  setDetailPageTimeline,
  setDetailPageAttendanceLog,
  setDetailPageIncrementTimeline,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";
import { useReactToPrint } from "react-to-print";
import { tr } from "date-fns/locale";

interface Props {
  empDetail: EmpDetail;
}

const Timeline = ({ empDetail }: Props) => {
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };

  const lastTransfer =
    empDetail.transferTimeline[empDetail.transferTimeline.length - 1];

  // increment of 1 day in last transfer date
  const transferToDate =
    empDetail.transferTimeline.length > 0
      ? new Date(lastTransfer.toDate)
      : new Date();
  transferToDate.setDate(transferToDate.getDate() + 1);

  const currentFromDate =
    empDetail.transferTimeline.length > 0
      ? transferToDate
      : new Date(empDetail.empProjectJoiningDate);

  const currentProject =
    empDetail.empStatus === "Absconded" || empDetail.empStatus === "Final Exit"
      ? []
      : [
          {
            _id: "0",
            uId: "0",
            projectId: empDetail.empProjectId,
            projectName: empDetail.empProjectId,
            projectSupervisor: empDetail.empSupervisorName,
            fromDate: currentFromDate,
            toDate: new Date(),
            attendendDays: currentFromDate
              ? Math.floor(
                  (new Date().getTime() - new Date(currentFromDate).getTime()) /
                    (1000 * 60 * 60 * 24),
                )
              : 0,
            trasferType: "Current Project",
            category: empDetail.empProjectCategory,
            workingHours: empDetail.empProjectWorkingHours,
            price: empDetail.empProjectPrice,
            overTime: empDetail.overTime,
            overTimePrice: empDetail.overTimePrice,
            submittedBy: {
              empId: 0,
              empName: "",
              remarks: "Current Project",
            },
          },
        ];

  const transferTimeline =
    empDetail.empProjectId === ""
      ? empDetail.transferTimeline
      : empDetail.transferTimeline.concat(currentProject);

  return (
    <Row>
      <Col sm="3">
        <div className="mb-4">
          <button
            className="btn primary-button"
            onClick={handlePrinted}
            type="button"
          >
            Print Projects Timeline
          </button>
        </div>
      </Col>
      {transferTimeline.length > 0 ? (
        <>
          <div className="p-3" ref={tableRef}>
            {print && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <img src={Common.Vision} width="110px" alt="logo" />
                  </div>
                  <div className="letter-logo text-center gap-2">
                    <img src={Common.LogoSVG} width="80px" alt="logo" />
                    <div className="mt-1">
                      <h3
                        className="logo-text mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Senyar Arabian Trading Co. LTD
                      </h3>
                      <h3
                        className="logo-text logo-arabic mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "13px",
                          fontWeight: "700",
                        }}
                      >
                        شركة سنيار العربية التجارية المحدودة
                      </h3>
                    </div>
                  </div>
                </div>
                <h1 className="sub-heading text-center mt-3">
                  Attendance Log of Employee ID: {empDetail.empId} -{" "}
                  {empDetail.empName}
                </h1>
              </>
            )}
            <Table
              striped
              bordered
              responsive
              className={print ? "text-center print-table" : "text-center"}
            >
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Project Id.</th>
                  <th>Project Name</th>
                  <th>Project Supervisor</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Attendend Days</th>
                  <th>Transfer Type</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {transferTimeline.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.projectId}</td>
                    <td>{item.projectName}</td>
                    <td>{item.projectSupervisor}</td>
                    <td>
                      {item.fromDate
                        ? new Date(item.fromDate).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>
                      {item.toDate
                        ? new Date(item.toDate).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{item.attendendDays.toFixed()}</td>
                    <td>{item.trasferType}</td>
                    <td>{item.submittedBy.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">No Projects Timeline</h1>
        </div>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Attendance Log"));
              dispatch(setDetailPageAttendanceLog(true));
              dispatch(setDetailPageTimeline(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Salary Timeline"));
              dispatch(setDetailPageTimeline(false));
              dispatch(setDetailPageIncrementTimeline(true));
            }}
            style={{ width: "15%" }}
          >
            Next
            <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default Timeline;
