import React from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActualSheetChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { usePostActualSheetMutation } from "../../services/Projects";
import { setPostSalaryLoading } from "../../store/slices/projectSlice";

interface Props {
  show: boolean;
  setShow: any;
  payload: {
    date: string;
    empId: number;
    workedHours: number;
    overtimeHours: number;
    perHourCost: number;
    basicSalary: number;
    totalSalary: number;
    totalOvertimeAmount: number;
    status: string;
    projectId: string;
  }[];
  attendancesIds: string[];
  empIds: number[];
  date: string;
  postSalaryLoading: boolean;
  setSuccessContent: any;
  setShowSuccess: any;
  actualSheetChange: boolean;
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

const ConfirmPostSalarySheetModal = ({
  show,
  setShow,
  payload,
  date,
  empIds,
  setShowSuccess,
  postSalaryLoading,
  setSuccessContent,
  actualSheetChange,
  attendancesIds,
}: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [postActualSheet] = usePostActualSheetMutation();

  const handlePostSalarySheet = async () => {
    if (payload.length === 0) return;
    dispatch(setPostSalaryLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          postSalaries: payload,
          empIds: empIds,
          date: formatedDate(date),
          attendancesIds: attendancesIds,
        },
      };
      await postActualSheet(data).unwrap();
      dispatch(setActualSheetChange(!actualSheetChange));
      setSuccessContent("Actual Salary Sheet Posted Successfully!");
      setShowSuccess(true);
      setShow(false);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShowError(true);
      }
    } finally {
      dispatch(setPostSalaryLoading(false));
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="mb-4 text-center">
            <h1 className="heading">Are you sure?</h1>
            <h4 className="sub-heading normal-font-weight text-center">
              All the attendances of selected employees for this month could not
              be updated.
            </h4>
            <div className="d-flex align-items-center justify-content-center gap-4">
              <button
                className="btn secondary-button mt-4"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className="btn primary-button border-0 mt-4"
                onClick={handlePostSalarySheet}
              >
                {postSalaryLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : (
                  "Post Sheet"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmPostSalarySheetModal;
