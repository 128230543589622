import React from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setEmployeeChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { useDeleteEmployeeMutation } from "../../services/Employees";

interface Props {
  show?: boolean;
  setShow?: any;
  empId: number;
}

const DeleteEmployeeModal = ({ show, setShow, empId }: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [reason, setReason] = React.useState("");

  const employeeChange = useAppSelector((state) => state.common.employeeChange);

  const [deleteEmployee] = useDeleteEmployeeMutation();

  const handleDelete = async (empId: number) => {
    if (!reason) {
      setErrorContent("Please enter the reason for termination");
      setShowError(true);
      return;
    }
    setDeleteLoadingMap(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.deleteEmployee || false,
        payload: {
          empId: empId,
          reason: reason,
        },
      };
      await deleteEmployee(data).unwrap();
      dispatch(setEmployeeChange(!employeeChange));
      setReason("");
      setShow(false);
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap(false);
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={setShow}
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="mb-4 text-center">
            <h1 className="heading">Are you sure?</h1>
            <h4 className="sub-heading normal-font-weight text-center">
              Enter the reason for terminating the employee
            </h4>
            <textarea
              className="form-control mt-4"
              placeholder="Reason"
              value={reason}
              required
              onChange={(e) => setReason(e.target.value)}
            />

            <div className="d-flex align-items-center justify-content-center gap-2">
              <button
                className="btn primary-button border-0 mt-4"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className="btn primary-button border-0 mt-4"
                onClick={() => handleDelete(empId)}
              >
                {deleteLoadingMap ? (
                  <LoaderWhite height={30} width={30} />
                ) : (
                  "Terminate"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteEmployeeModal;
