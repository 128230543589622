import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, SettingsIcons } from "../../assets/images";
import AttachmentSet from "../Employees/AddEmployee/attachments";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddRecruitAgencyLoading } from "../../store/slices/campsSlice";
import { setRecruitAgencyChange } from "../../store/slices/commonSlice";
// API
import { useEditRecruitAgencyMutation } from "../../services/Camps";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";

export interface AddRecruiter {
  recruiterName: string;
  companyName: string;
  companyPhone: string;
  licenseNumber: string;
  contactPersonName: string;
  contactPersonDesignation: string;
  contactPersonPhone: string;
  address: string;
  website: string;
}

const EditRecruitAgency = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recruiterData = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.camps);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const [sEmployee, setSEmployee] = useState("");
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [email, setEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    recruiterData.emails || [],
  );
  const [selectedRecruitEmployee, setSelectedRecruitEmployee] = useState<
    number[]
  >(recruiterData.employeeRecruited || []);
  const [showAttachment, setShowAttachment] = useState(false);
  const recruitAgencyChange = useAppSelector(
    (state) => state.common.recruitAgencyChange,
  );
  const allEmployees = useAppSelector(
    (state) => state.employee.allEmployeesList,
  );

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >(recruiterData.recruitmentAttachments || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: "",
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);
  // Initial Values
  const initialValues: AddRecruiter = {
    recruiterName: recruiterData.recruiterName || "",
    companyName: recruiterData.companyName || "",
    companyPhone: recruiterData.companyPhone || "",
    licenseNumber: recruiterData.licenseNumber || "",
    contactPersonName: recruiterData.contactPersonName || "",
    contactPersonDesignation: recruiterData.contactPersonDesignation || "",
    contactPersonPhone: recruiterData.contactPersonPhone || "",
    address: recruiterData.address || "",
    website: recruiterData.website || "",
  };

  const [editRecruitAgency] = useEditRecruitAgencyMutation();
  const handleSubmit = async (values: AddRecruiter) => {
    dispatch(setAddRecruitAgencyLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          recruiterId: recruiterData.recruiterId || "",
          recruiterName: values.recruiterName,
          companyName: values.companyName,
          companyPhone: values.companyPhone,
          licenseNumber: values.licenseNumber,
          contactPersonName: values.contactPersonName,
          contactPersonDesignation: values.contactPersonDesignation,
          contactPersonPhone: values.contactPersonPhone,
          employeeRecruited: selectedRecruitEmployee,
          emails: selectedEmails,
          address: values.address,
          website: values.website,
          recruitmentAttachments: languageData,
          link: `/recruit-agency/recruiters`,
        },
      };
      await editRecruitAgency(data).unwrap();
      dispatch(setRecruitAgencyChange(!recruitAgencyChange));
      navigate("/recruit-agency/recruiters");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddRecruitAgencyLoading(false));
    }
  };

  // Options
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const employeeOptions = allEmployees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Recruiter </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showAttachment && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Recruiter Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="recruiterName"
                          onChange={handleChange}
                          placeholder="Enter recruiter name"
                          value={values.recruiterName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.recruiterName && errors.recruiterName ? (
                          <div className="invalid-feedback">
                            {errors.recruiterName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyName"
                          placeholder="Enter company name"
                          onChange={handleChange}
                          value={values.companyName}
                          className="custom-input border-fill"
                        />
                        {touched.companyName && errors.companyName ? (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Phone
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyPhone"
                          placeholder="Enter company phone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.companyPhone}
                          className="custom-input border-fill"
                        />
                        {touched.companyPhone && errors.companyPhone ? (
                          <div className="invalid-feedback">
                            {errors.companyPhone}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          License Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="licenseNumber"
                          placeholder="Enter license number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.licenseNumber}
                          className="custom-input border-fill"
                        />
                        {touched.licenseNumber && errors.licenseNumber ? (
                          <div className="invalid-feedback">
                            {errors.licenseNumber}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Website
                        </h4>
                        <Form.Control
                          type="text"
                          name="website"
                          placeholder="Enter website url"
                          onChange={handleChange}
                          value={values.website}
                          className="custom-input border-fill"
                        />
                        {touched.website && errors.website ? (
                          <div className="invalid-feedback">
                            {errors.website}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonName"
                          placeholder="Enter contact person name"
                          onChange={handleChange}
                          value={values.contactPersonName}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonName &&
                        errors.contactPersonName ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Cotact Person Designation
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonDesignation"
                          placeholder="Enter contact person designation"
                          onChange={handleChange}
                          value={values.contactPersonDesignation}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonDesignation &&
                        errors.contactPersonDesignation ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonDesignation}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Contact Person Phone
                        </h4>
                        <Form.Control
                          type="text"
                          name="contactPersonPhone"
                          placeholder="Enter contact person phone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.contactPersonPhone}
                          className="custom-input border-fill"
                        />
                        {touched.contactPersonPhone &&
                        errors.contactPersonPhone ? (
                          <div className="invalid-feedback">
                            {errors.contactPersonPhone}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Employee Recruited
                        </h4>
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <SelectSearch
                            name="campSupervisor"
                            options={employeeOptions}
                            value={sEmployee}
                            onChange={(value: any) => {
                              setSEmployee(value);
                              setSelectedRecruitEmployee([
                                ...selectedRecruitEmployee,
                                Number(value),
                              ]);
                              setSEmployee("");
                            }}
                            search={true}
                            placeholder="Select Employee Id"
                          />
                          <img src={Common.ArrowDown} alt="message" />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="custom-input border-fill"
                          />
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            onClick={() => {
                              setSelectedEmails([...selectedEmails, email]);
                              setEmail("");
                            }}
                            style={{ width: "25%" }}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Address *
                        </h4>
                        <textarea
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          required={true}
                          placeholder="Add address 1"
                          className={
                            touched.address && errors.address
                              ? "is-invalid border-fill w-100"
                              : "border-fill w-100"
                          }
                          rows={6}
                          style={{ resize: "none" }}
                        />
                        {touched.address && errors.address ? (
                          <div className="invalid-feedback">
                            {errors.address}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Recruited Employees
                        </h4>
                        {selectedRecruitEmployee.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Emp Id.</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedRecruitEmployee.map(
                                (item: number, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item}</td>
                                    <td>
                                      <button
                                        className="btn p-0 border-0"
                                        type="button"
                                        onClick={() => {
                                          const updatedRecruitedEmployees =
                                            selectedRecruitEmployee.filter(
                                              (employee) => employee !== item,
                                            );
                                          setSelectedRecruitEmployee(
                                            updatedRecruitedEmployees,
                                          );
                                        }}
                                      >
                                        <img
                                          src={SettingsIcons.Delete}
                                          width="24px"
                                          height="24px"
                                          alt="edit-user"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">
                            No recruited employees added
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email List
                        </h4>
                        {selectedEmails.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEmails.map(
                                (item: string, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.toLocaleLowerCase()}</td>
                                    <td>
                                      <button
                                        className="btn p-0 border-0"
                                        type="button"
                                        onClick={() => {
                                          const updatedEmails =
                                            selectedEmails.filter(
                                              (email) => email !== item,
                                            );
                                          setSelectedEmails(updatedEmails);
                                        }}
                                      >
                                        <img
                                          src={SettingsIcons.Delete}
                                          width="24px"
                                          height="24px"
                                          alt="edit-user"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">No emails added</div>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn primary-button w-25"
                          onClick={() => {
                            if (values.recruiterName === "") {
                              setErrorContent("Recruiter Name is required");
                              setShow(true);
                              return;
                            }
                            setShowAttachment(true);
                          }}
                        >
                          Next
                          <img src={Common.ArrowRightWhite} alt="arrow" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {showAttachment && (
                  <Row>
                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4 mb-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachments
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      {languageSets.length > 0 && (
                        <h4 className="sub-heading normal-font-weight mb-4">
                          Attachments
                        </h4>
                      )}
                      <div className="extra-detail my-4">{languageSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="submit"
                          className="btn primary-button w-25"
                        >
                          {state.addRecruitAgencyLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Edit Recruiter"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditRecruitAgency;
