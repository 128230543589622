import React from "react";
import InternalLayout from "../components/InternalLayout";
import Content from "../components/Camps/get-api-call";
import CampsComponent from "../components/Camps";
import withAuth from "../config/private-route";

const Camps = () => {
  return (
    <InternalLayout>
      <Content />
      <CampsComponent />
    </InternalLayout>
  );
};
export default withAuth(Camps);
