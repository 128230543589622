import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { ClientReview } from "../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterClientTotalRecords,
  setClientReviewsLoading,
} from "../../store/slices/projectSlice";
// API imports
import { useGetFilterClientReviewsMutation } from "../../services/Projects";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setClientReviews?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  clientReviews: ClientReview[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  reviewType: string;
  statementDateFrom: string;
  statementDateTo: string;
}

const ClientReviewFilterModal = ({
  show,
  setShow,
  clientReviews,
  setClientReviews,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getFilterClientReviews] = useGetFilterClientReviewsMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setClientReviewsLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterClientPageNo,
          limit: state.filterClientPageSize,
          projectId: filterInput.projectId,
          reviewType: filterInput.reviewType,
          statementDateFrom: filterInput.statementDateFrom,
          statementDateTo: filterInput.statementDateTo,
        },
      };
      const response = await getFilterClientReviews(data).unwrap();
      if (response) {
        setClientReviews(response.data.reviews[0].data);
        dispatch(
          setFilterClientTotalRecords(
            response.data.reviews[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setClientReviewsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setClientReviewsLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterClientPageNo,
            limit: state.filterClientPageSize,
            clientId: query,
            projectId: filterInput.projectId,
            reviewType: filterInput.reviewType,
            statementDateFrom: filterInput.statementDateFrom,
            statementDateTo: filterInput.statementDateTo,
          },
        };
        const response = await getFilterClientReviews(data).unwrap();
        if (response) {
          setClientReviews(response.data.reviews[0].data);
          dispatch(
            setFilterClientTotalRecords(
              response.data.reviews[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setClientReviewsLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterClientPageNo, state.filterClientPageSize, query]);

  const handleReset = () => {
    setClientReviews(clientReviews);
    setFilterInput({
      projectId: "",
      reviewType: "",
      statementDateFrom: "",
      statementDateTo: "",
    });
  };

  // Options
  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Client Reviews</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Review Type
                    </h4>
                    <Form.Select
                      name="reviewType"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          reviewType: e.target.value,
                        })
                      }
                      value={filterInput.reviewType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Review Type</option>
                      <option value="Inquiry">Inquiry</option>
                      <option value="Complain">Complain</option>
                      <option value="Meeting">Meeting</option>
                      <option value="Deployment">Deployment</option>
                      <option value="Increase">Increase</option>
                      <option value="Replacement">Replacement</option>
                      <option value="Agreement">Agreement</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Statement Date From
                    </h4>
                    <Form.Control
                      type="date"
                      name="statementDateFrom"
                      placeholder="Enter statement date from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          statementDateFrom: e.target.value,
                        })
                      }
                      value={filterInput.statementDateFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Statement Date To
                    </h4>
                    <Form.Control
                      type="date"
                      name="statementDateTo"
                      placeholder="Enter statement date to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          statementDateTo: e.target.value,
                        })
                      }
                      value={filterInput.statementDateTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientReviewFilterModal;
