import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Projects
import { useGetProjectsQuery } from "../../services/Projects";
import {
  setProjectError,
  setProjects,
  setProjectsLoading,
  setTotalRecords as projectRecords,
} from "../../store/slices/projectSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectChange = useAppSelector((state) => state.common.projectChange);
  // States
  const state = useAppSelector((state) => state.project);

  // **************
  // **************
  // Get All Projects
  // **************
  // **************

  const {
    data: allProjects,
    isLoading: projectLoading,
    isError: projectError,
    refetch: projectRefetch,
  } = useGetProjectsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    page: state.pageNo,
    limit: state.pageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (projectLoading) {
      dispatch(setProjectsLoading(true));
    }
    if (projectError) {
      dispatch(setProjectError("Something went wrong"));
      dispatch(setProjectsLoading(false));
    }
    if (allProjects) {
      dispatch(setProjects(allProjects.data.projects[0].data || []));
      dispatch(
        projectRecords(allProjects.data.projects[0].metadata[0]?.total || 0),
      );
      dispatch(setProjectsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allProjects,
    profileData,
    state.pageNo,
    state.pageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    projectRefetch();
  }, [projectChange]);
  return <></>;
};
export default Content;
