import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  CustomerBalanceSheet,
  PaymentsReceivedSheet,
  SalesSheet,
  CreditNoteDetailsSheet,
} from "../../interfaces/reports";

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const reportsAPI = createApi({
  reducerPath: "/api/reports",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getCustomerBalance: builder.query<
      CustomerBalanceSheet,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/customer-balance/${data.pageNo}/${data.pageSize}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
    getPaymentsRecieved: builder.query<
      PaymentsReceivedSheet,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/payments-recieved/${data.pageNo}/${data.pageSize}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
    getCreditNoteDetails: builder.query<
      CreditNoteDetailsSheet,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/credit-note-details/${data.pageNo}/${data.pageSize}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
    getSales: builder.query<
      SalesSheet,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/sales/${data.pageNo}/${data.pageSize}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
  }),
});

export const {
  useGetCustomerBalanceQuery,
  useGetPaymentsRecievedQuery,
  useGetSalesQuery,
  useGetCreditNoteDetailsQuery,
} = reportsAPI;
