import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";

interface LanguageProps {
  data: {
    selectedLanguage: string;
    languageProficiency: string;
    writingSkills: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    selectedLanguage: string;
    languageProficiency: string;
    writingSkills: string;
  }) => void;
  uniqueId: string;
}

const LanguageSet: React.FC<LanguageProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    data.selectedLanguage,
  );
  const [languageProficiency, setLanguageProficiency] = useState(
    data.languageProficiency,
  );
  const [writingSkills, setWritingSkills] = useState(data.writingSkills);

  const handleInputChange = () => {
    onInputChange({
      selectedLanguage,
      languageProficiency,
      writingSkills,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [selectedLanguage, languageProficiency, writingSkills]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Language
                </h4>
                <Form.Select
                  name="selectedLanguage"
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  value={selectedLanguage}
                  className="custom-input border-fill"
                >
                  <option value="">Select Language</option>
                  <option value="English">English</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Urdu">Urdu</option>
                  <option value="German">German</option>
                  <option value="French">French</option>
                  <option value="Latin">Latin</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Chineese">Chineese</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Language Proficiency
                </h4>
                <Form.Select
                  name="languageProficiency"
                  onChange={(e) => setLanguageProficiency(e.target.value)}
                  value={languageProficiency}
                  className="custom-input border-fill"
                >
                  <option value="">Select language proficiency</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Writing Skills
                </h4>
                <Form.Select
                  name="writingSkills"
                  onChange={(e) => setWritingSkills(e.target.value)}
                  value={writingSkills}
                  className="custom-input border-fill"
                >
                  <option value="">Select writing skills</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LanguageSet;
