import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { BehaviourStatement } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterBsTotalPages,
  setBehaviourStatementsLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredBehaviourStatementsMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setBehaviourStatements?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  behaviourStatements: BehaviourStatement[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  empCategory: string;
  projectId: string;
  empStatus: string;
  supervisorName: string;
  violationType: string;
  violationDateFrom: string;
  violationDateTo: string;
}

const BehaviourStatementFilterModal = ({
  show,
  setShow,
  behaviourStatements,
  setBehaviourStatements,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const settings = useAppSelector((state) => state.settings);
  const [getAllFilteredBehaviourStatements] =
    useGetAllFilteredBehaviourStatementsMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setBehaviourStatementsLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterBsPageNo,
          limit: state.filterBsPageSize,
          projectId: filterInput.projectId,
          supervisorName: filterInput.supervisorName,
          empCategory: filterInput.empCategory,
          empStatus: filterInput.empStatus,
          violationType: filterInput.violationType,
          violationDateFrom: filterInput.violationDateFrom,
          violationDateTo: filterInput.violationDateTo,
        },
      };
      const response = await getAllFilteredBehaviourStatements(data).unwrap();
      if (response) {
        setBehaviourStatements(response.data.statements[0].data);
        dispatch(
          setFilterBsTotalPages(response.data.statements[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setBehaviourStatementsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setBehaviourStatementsLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterBsPageNo,
            limit: state.filterBsPageSize,
            empId: query,
            projectId: filterInput.projectId,
            supervisorName: filterInput.supervisorName,
            empCategory: filterInput.empCategory,
            empStatus: filterInput.empStatus,
            violationType: filterInput.violationType,
            violationDateFrom: filterInput.violationDateFrom,
            violationDateTo: filterInput.violationDateTo,
          },
        };
        const response = await getAllFilteredBehaviourStatements(data).unwrap();
        if (response) {
          setBehaviourStatements(response.data.statements[0].data);
          dispatch(
            setFilterBsTotalPages(
              response.data.statements[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setBehaviourStatementsLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterBsPageNo, state.filterBsPageSize, query]);

  const handleReset = () => {
    setBehaviourStatements(behaviourStatements);
    setFilterInput({
      empCategory: "",
      projectId: "",
      empStatus: "",
      supervisorName: "",
      violationType: "",
      violationDateFrom: "",
      violationDateTo: "",
    });
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  // get rolename from setting.roles as array of string
  const roles = settings.roles.map((item) => item.roleName);

  const categories = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Other",
  ];

  const combinedRoles = [...roles, ...categories];

  const vTypes = [
    "Attendance",
    "Failure to follow instructions",
    "Unsatisfactory work quality",
    "Violation of safety rules",
    "Tardiness or early quit",
    "Rudeness to employees or customers",
    "Carelessness",
    "Working on personal matters",
    "Violation of company policies or procedures",
    "Willful damage to material or equipment",
    "Other",
  ];

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Employee Behaviour Statements</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor Name
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="supervisorName"
                        options={supervisorOptions}
                        value={filterInput.supervisorName}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            supervisorName: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Supervisor Name"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Employee Category
                    </h4>
                    <Form.Select
                      name="empCategory"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          empCategory: e.target.value,
                        })
                      }
                      value={filterInput.empCategory}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Emp Category</option>
                      {combinedRoles.map((item: string, index: number) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Status
                    </h4>
                    <Form.Select
                      name="empStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          empStatus: e.target.value,
                        })
                      }
                      value={filterInput.empStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Employee">Employee</option>
                      <option value="Initial Evaluation">
                        Initial Evaluation
                      </option>
                      <option value="Probation">Probation</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Violation Type
                    </h4>
                    <Form.Select
                      name="violationType"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          violationType: e.target.value,
                        })
                      }
                      value={filterInput.violationType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Violation Type</option>
                      {vTypes.map((method, index) => (
                        <option key={index} value={method}>
                          {method}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Violation Date From
                    </h4>
                    <Form.Control
                      type="date"
                      name="violationDateFrom"
                      placeholder="Enter violation date from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          violationDateFrom: e.target.value,
                        })
                      }
                      value={filterInput.violationDateFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Violation Date To
                    </h4>
                    <Form.Control
                      type="date"
                      name="violationDateTo"
                      placeholder="Enter violation date to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          violationDateTo: e.target.value,
                        })
                      }
                      value={filterInput.violationDateTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BehaviourStatementFilterModal;
