import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Common, EmployeeIcons } from "../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPettyCashChange } from "../../store/slices/commonSlice";
import { setAddDebitLoading } from "../../store/slices/pettyCashSlice";
// API Imports
import { useAddDebitMutation } from "../../services/PettyCash";
import { useUploadImageMutation } from "../../services/images-upload-service";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import { useNavigate } from "react-router-dom";

interface AddDebitCash {
  expenseType: string;
  expenseAmount: string;
  expenseDate: string;
  expenseDetail: string;
  transactionMethod: string;
}

const initialValues: AddDebitCash = {
  expenseType: "",
  expenseAmount: "",
  expenseDate: "",
  expenseDetail: "",
  transactionMethod: "",
};

const DebitPettyCash = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [attachment, setAttachment] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const pettyCashChange = useAppSelector(
    (state) => state.common.pettyCashChange,
  );
  const pettyCashLoading = useAppSelector(
    (state) => state.pettyCash.addDebitLoading,
  );
  const pettyCashState = useAppSelector((state) => state.pettyCash);
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");

  const [addDebit] = useAddDebitMutation();

  const handleSubmit = async (values: AddDebitCash) => {
    dispatch(setAddDebitLoading(true));
    const date = values.expenseDate
      ? new Date(values.expenseDate).toLocaleDateString()
      : "";
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          transactionDate: date,
          expenseType: values.expenseType,
          transactionDetail: values.expenseDetail,
          transactionImg: attachment,
          transactionAmount: values.expenseAmount,
          transactionMethode: values.transactionMethod,
          link: "/petty-cash/ledger",
        },
      };
      await addDebit(data).unwrap();
      dispatch(setPettyCashChange(!pettyCashChange));
      navigate("/petty-cash/ledger");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddDebitLoading(false));
    }
  };

  // Upload Attachment
  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: true,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setAttachment(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {}
    }
  };

  const pathname = attachment ? new URL(attachment).pathname : "";

  const fileName = pathname.split("/").pop();

  const latestTransactionId =
    pettyCashState.ledger.length > 0
      ? pettyCashState.ledger[pettyCashState.ledger.length - 1].transactionId
      : "TRS000001";

  const newTransactionId = latestTransactionId
    ? latestTransactionId.split("TRS").pop()
    : "000001";

  const newTransactionIdNumber = newTransactionId
    ? parseInt(newTransactionId) + 1
    : "000001";

  const newTransactionIdString = newTransactionIdNumber.toString();

  const finalTransactionId = "TRS" + newTransactionIdString.padStart(6, "0");

  const transactionMethods: string[] = [
    "Cash",
    "Credit/Debit Cards",
    "Bank Transfers",
    "Checks",
    "Mobile Payments",
    "Online Banking",
    "Wire Transfers",
    "Cryptocurrency",
    "Money Orders",
    "Electronic Funds Transfer (EFT)",
    "PayPal and Other Online Payment Services",
    "Automatic Clearing House (ACH) Payments",
    "Contactless Payments",
    "Smart Cards",
    "Point of Sale (POS) Systems",
    "Barter",
    "Installment Plans",
    "Direct Debit",
  ];

  const validationSchema = Yup.object().shape({
    expenseType: Yup.string().required("Expense Type is required"),
    expenseAmount: Yup.string().required("Expense Amount is required"),
    expenseDate: Yup.string().required("Expense Date is required"),
  });

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Debit Petty Cash</h1>
            <div className="transaction-id mt-4 d-flex align-items-center gap-2 mb-4">
              <h4 className="sub-heading normal-font-weight textBlack">
                Transaction ID:
              </h4>
              <h4 className="sub-heading normal-font-weight">
                {finalTransactionId}
              </h4>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Expense Type *
                      </h4>
                      <Form.Control
                        type="text"
                        name="expenseType"
                        placeholder="Enter expense type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.expenseType}
                        className={
                          touched.expenseType && errors.expenseType
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      />
                      {touched.expenseType && errors.expenseType ? (
                        <div className="invalid-feedback">
                          {errors.expenseType}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack mb-2"
                        style={{ fontSize: "18px" }}
                      >
                        Expense Amount *
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center">
                        <Form.Control
                          type="text"
                          name="expenseAmount"
                          onChange={(e) => {
                            // Accept numbers and float values
                            const re = /^[0-9\b]*(\.\d{0,2})?$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          placeholder="Enter expense amount"
                          className="custom-input"
                          value={values.expenseAmount}
                        />
                        SAR
                      </Form.Group>
                      {touched.expenseAmount && errors.expenseAmount ? (
                        <div className="invalid-feedback">
                          {errors.expenseAmount}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Expense Date *
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="expenseDate"
                          selected={
                            values.expenseDate
                              ? new Date(values.expenseDate)
                              : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "expenseDate", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          maxDate={new Date()}
                          placeholderText="Expense Date"
                          className={
                            errors.expenseDate && touched.expenseDate
                              ? "is-invalid w-100 custom-input"
                              : "w-100 custom-input"
                          }
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                      {touched.expenseDate && errors.expenseDate ? (
                        <div className="invalid-feedback">
                          {errors.expenseDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="8">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Expense Detail
                      </h4>
                      <textarea
                        name="expenseDetail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.expenseDetail}
                        placeholder="Leave a comment here..."
                        className={
                          touched.expenseDetail && errors.expenseDetail
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.expenseDetail && errors.expenseDetail ? (
                        <div className="invalid-feedback">
                          {errors.expenseDetail}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Transaction Method
                      </h4>
                      <Form.Select
                        name="transactionMethod"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.transactionMethod}
                        className={
                          touched.transactionMethod && errors.transactionMethod
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      >
                        <option value="">Select transaction method</option>
                        {transactionMethods.map((method, index) => (
                          <option key={index} value={method}>
                            {method}
                          </option>
                        ))}
                      </Form.Select>

                      {touched.transactionMethod && errors.transactionMethod ? (
                        <div className="invalid-feedback">
                          {errors.transactionMethod}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Attachment
                      </h4>
                      <Form.Label className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center">
                        <Form.Control
                          type="file"
                          name="attachment"
                          onChange={handleUploadImage}
                          accept=".pdf, .jpg, .jpeg, .png"
                          className="d-none"
                          multiple={false}
                        />
                        <div className="text-center">
                          <div className="add-profile-image d-flex align-items-center justify-content-center mx-auto">
                            <img src={EmployeeIcons.Plus} alt="plus" />
                          </div>
                          {attachment ? (
                            <p
                              className="upload-para mt-2 mb-0"
                              style={{ fontSize: "14px" }}
                            >
                              Change Attachment
                            </p>
                          ) : null}
                          <p
                            className="upload-para mt-2 mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            Upload JPG, JPEG, PNG & PDF File only
                          </p>
                          <p className="upload-para mt-0 mb-0">
                            Max file size is 100MB
                          </p>
                        </div>
                      </Form.Label>
                    </Form.Group>
                    <div>
                      {imageLoading ? (
                        <LoaderBlue height={40} width={40} />
                      ) : attachment ? (
                        <h4 className="sub-heading">{fileName}</h4>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {pettyCashLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Add Amount"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default DebitPettyCash;
