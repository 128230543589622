import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { EmployeePayslip as PayslipSchema } from "../../interfaces/employee";
import EmployeePayslip from "../Letters/employee-payslip";
import { useAppSelector } from "../../store/hooks";
import PaySalariesModal from "../Modals/pay-salaries-modal";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";

const ViewPayslip = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const payslipData: PayslipSchema = location.state.data;
  const type: string = location.state.type;
  const [showPaySalaryModal, setShowPaySalaryModal] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [show, setShow] = useState(false);
  const payrollChange = useAppSelector((state) => state.common.payrollChange);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [status, setStatus] = React.useState("");
  const state = useAppSelector((state) => state.employeePayroll);
  const [hideLetterHead, setHideLetterHead] = useState(false);
  return (
    <div className=" mt-2 section px-4 py-4">
      {type === "paySalary" && (
        <>
          <ToastErrorComponent
            show={show}
            setShow={setShow}
            content={errorContent}
          />
          <ToastSuccessComponent
            show={showSuccess}
            setShow={setShowSuccess}
            content={successContent}
          />
          <PaySalariesModal
            show={showPaySalaryModal}
            setShow={setShowPaySalaryModal}
            salaryIds={payslipData.salaryIds}
            expenseIds={payslipData.expenseIds}
            payrollIds={[payslipData._id]}
            createPayslipLoading={state.addEmployeePayrollLoading}
            setSuccessContent={setSuccessContent}
            setShowSuccess={setShowSuccess}
            payslipChange={payrollChange}
            totalAmount={payslipData.netSalary}
            status={status}
          />
        </>
      )}
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <EmployeePayslip
            removeLetterHead={hideLetterHead}
            payload={payslipData}
          />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="btn border-0 p-0"
                    onClick={() => {
                      navigate(-1);
                    }}
                    type="button"
                  >
                    {" "}
                    <img src={Common.ArrowLeftBlue} alt="next" />
                  </button>
                  <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                    Payslip Detail
                  </h4>
                </div>
                {type === "paySalary" && (
                  <Dropdown show={statusDropdown} className="position-relative">
                    <button
                      id="dropdown-basic"
                      onClick={() => {
                        setStatusDropdown(!statusDropdown);
                      }}
                      className="dropdown-toggle-white  dropdown-toggle-blue"
                      style={{ width: "max-content" }}
                    >
                      Status
                      <img
                        src={EmployeeIcons.ArrowDownWhite}
                        className="ms-2"
                        alt="down-arrow"
                      />
                    </button>
                    <Dropdown.Menu
                      className="mt-1 custom-scrollbar"
                      show={statusDropdown}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setStatus("Paid");
                          setShowPaySalaryModal(true);
                          setStatusDropdown(false);
                        }}
                      >
                        Paid
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setStatus("Hold");
                          setStatusDropdown(false);
                          setShowPaySalaryModal(true);
                        }}
                      >
                        Hold
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setStatus("Cancelled");
                          setStatusDropdown(false);
                          setShowPaySalaryModal(true);
                        }}
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div className="d-flex align-items-center gap-4">
                <Form.Check
                  type="checkbox"
                  id="hideLetterHeadCheckbox" // Add an id to target the checkbox
                  label="Hide Senyar Letter Head"
                  className="hide-letter-head"
                  name="hideLetterHead"
                  checked={hideLetterHead}
                  onChange={(e) => {
                    setHideLetterHead(e.target.checked);
                  }}
                />
                <button
                  className="btn primary-button"
                  onClick={handlePrint}
                  type="button"
                >
                  Preview
                </button>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.payrollDate
                      ? new Date(payslipData.payrollDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Payslip Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.payrollId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.empId}
                  </h5>
                </Form.Group>
              </Col>

              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Phone
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.empPhone}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.status}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Gross Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.grossSalary.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Deductions
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.totalDeductions.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Net Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payslipData.netSalary.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="sub-heading mb-0">Earnings</h1>
            </div>
          </Col>
          <Col sm="12">
            {payslipData.earnings.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Hours</th>
                      <th>Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payslipData.earnings.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.hours}</td>
                        <td>{item.rate.toFixed(2)}</td>
                        <td>{item.amount.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No earnings found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="sub-heading mb-0">Deductions</h1>
            </div>
          </Col>
          <Col sm="12">
            {payslipData.deductions.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payslipData.deductions.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.amount.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No deductions found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewPayslip;
