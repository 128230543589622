import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Ledger
import { useGetLedgerQuery } from "../../services/PettyCash";
import {
  setLastMonthBalance,
  setLedger,
  setLedgerLoading,
  setPettyCashError,
  setTotalCredit,
  setTotalDebit,
} from "../../store/slices/pettyCashSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const pettyCashChange = useAppSelector(
    (state) => state.common.pettyCashChange,
  );
  // States
  const state = useAppSelector((state) => state.pettyCash);

  // **************
  // **************
  // Get All Ledger
  // **************
  // **************
  const {
    data: allLedger,
    isLoading: ledLoading,
    isError: ledError,
    refetch: ledgerRefetch,
  } = useGetLedgerQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
    dateData: state.filterDate,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (ledLoading) {
      dispatch(setLedgerLoading(true));
    }
    if (ledError) {
      dispatch(setPettyCashError("Something went wrong"));
      dispatch(setLedgerLoading(false));
    }
    if (allLedger) {
      dispatch(setLedger(allLedger.data.ledger || []));
      dispatch(setTotalCredit(allLedger.data.totalCredit || 0));
      dispatch(setTotalDebit(allLedger.data.totalDebit || 0));
      dispatch(setLastMonthBalance(allLedger.data.lastMonthBalance || 0));
      dispatch(setLedgerLoading(false));
    }
    dispatch(setLedgerLoading(false));
  }, [accessToken, userID, allLedger, profileData, state.filterDate]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    ledgerRefetch();
  }, [pettyCashChange]);
  return <></>;
};
export default Content;
