import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common, SettingsIcons } from "../../assets/images";
import AttachmentSet from "../Employees/AddEmployee/attachments";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddSponsorLoading } from "../../store/slices/sponsorSlices";
import { setSponsorChange } from "../../store/slices/commonSlice";
// API
import { useAddSponsorMutation } from "../../services/Sponsor";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import SubscriptionSet from "./subscription-set";

export interface AddSponsor {
  companyName: string;
  companyNameArabic: string;
  companyAddress: string;
  companyAddressArabic: string;
  city: string;
  country: string;
  postalCode: string;
  phone: string;
  numberVAT: string;
  numberCR: string;
  companyStatus: string;
  companyMOL: string;
  unifiedNumber: string;
  crExpiry: string;
  companySerial: string;
}

const initialValues: AddSponsor = {
  companyName: "",
  companyNameArabic: "",
  companyAddress: "",
  companyAddressArabic: "",
  city: "",
  country: "",
  postalCode: "",
  phone: "",
  numberCR: "",
  numberVAT: "",
  companyMOL: "",
  companyStatus: "",
  unifiedNumber: "",
  crExpiry: "",
  companySerial: "",
};

const AddSponsor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.sponsor);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [showAttachment, setShowAttachment] = useState(false);
  const sponsorChange = useAppSelector((state) => state.common.sponsorChange);
  const [email, setEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [owner, setOwner] = useState<string>("");
  const [ownerIds, setOwnerIds] = useState<number[]>([]);
  const [saudi, setSaudi] = useState<string>("");
  const [saudiIds, setSaudiIds] = useState<number[]>([]);
  const [bankName, setBankName] = useState<string>("");
  const [iban, setIban] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<number>(0);
  const [swiftCode, setSwiftCode] = useState<string>("");
  const [bankDetails, setBankDetails] = useState<
    {
      bankName: string;
      iban: string;
      accountNumber: number;
      swiftCode: string;
    }[]
  >([]);

  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  // Options
  const ownerFilter = employees.filter((item) => item.empCategory === "Owner");
  const saudiFilter = employees.filter(
    (item) => item.empCategory === "Saudi Employee",
  );

  const ownerOptions = ownerFilter.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId,
  }));

  const saudiEmployeesOptions = saudiFilter.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId,
  }));

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      status: string;
      category: string;
      fromDate: string;
      toDate: string;
      cost: string;
    }[]
  >([]);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        status: "",
        category: "",
        fromDate: "",
        toDate: "",
        cost: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      status: string;
      category: string;
      fromDate: string;
      toDate: string;
      cost: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <SubscriptionSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  const [addSponsor] = useAddSponsorMutation();
  const handleSubmit = async (values: AddSponsor) => {
    dispatch(setAddSponsorLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          companyName: values.companyName,
          companyNameArabic: values.companyNameArabic,
          companyAddress: values.companyAddress,
          companyAddressArabic: values.companyAddressArabic,
          companyMOL: values.companyMOL,
          // city: values.city,
          // country: values.country,
          crExpiry: values.crExpiry,
          unifiedNumber: parseInt(values.unifiedNumber),
          companySerial: parseInt(values.companySerial),
          numberCR: parseInt(values.numberCR),
          postalCode: parseInt(values.postalCode),
          numberVAT: parseInt(values.numberVAT),
          phone: values.phone,
          companyStatus: values.companyStatus,
          companyAttachments: languageData,
          emails: selectedEmails,
          companyOwners: ownerIds,
          registeredSaudis: saudiIds,
          bankDetails: bankDetails,
          subscriptions: experienceData,
          link: "/companies",
        },
      };
      await addSponsor(data).unwrap();
      dispatch(setSponsorChange(!sponsorChange));
      navigate("/companies");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddSponsorLoading(false));
    }
  };

  const handleEmail = () => {
    //   add email in the list if it already exists then remvoe it
    if (email === "") {
      setErrorContent("Please enter email");
      setShow(true);
      return;
    }
    if (selectedEmails.includes(email)) {
      setErrorContent("Email already exists");
      setShow(true);
      return;
    }
    setSelectedEmails([...selectedEmails, email]);
    setEmail("");
  };

  const handleOwner = () => {
    //   add owner in the list if it already exists then remvoe it
    if (owner === "") {
      setErrorContent("Please enter owner");
      setShow(true);
      return;
    }
    if (ownerIds.includes(parseInt(owner))) {
      setErrorContent("Owner already exists");
      setShow(true);
      return;
    }
    setOwnerIds([...ownerIds, parseInt(owner)]);
    setOwner("");
  };

  const handleSaudi = () => {
    //   add saudi in the list if it already exists then remvoe it
    if (saudi === "") {
      setErrorContent("Please enter saudi");
      setShow(true);
      return;
    }
    if (saudiIds.includes(parseInt(saudi))) {
      setErrorContent("Saudi already exists");
      setShow(true);
      return;
    }
    setSaudiIds([...saudiIds, parseInt(saudi)]);
    setSaudi("");
  };

  const handleBankDetails = () => {
    //   add bank details in the list if it already exists then remvoe it
    if (bankName === "") {
      setErrorContent("Please enter bank name");
      setShow(true);
      return;
    }
    if (iban === "") {
      setErrorContent("Please enter iban");
      setShow(true);
      return;
    }
    if (accountNumber === 0) {
      setErrorContent("Please enter account number");
      setShow(true);
      return;
    }
    if (swiftCode === "") {
      setErrorContent("Please enter swift code");
      setShow(true);
      return;
    }
    const bankExists = bankDetails.filter(
      (item) =>
        item.iban === iban ||
        item.accountNumber === accountNumber ||
        item.swiftCode === swiftCode,
    );
    if (bankExists.length > 0) {
      setErrorContent("Bank details already exists");
      setShow(true);
      return;
    }
    setBankDetails([
      ...bankDetails,
      {
        bankName: bankName,
        iban: iban,
        accountNumber: accountNumber,
        swiftCode: swiftCode,
      },
    ]);
    setBankName("");
    setIban("");
    setAccountNumber(0);
    setSwiftCode("");
  };

  const ownerSelected = ownerFilter.filter((item) =>
    ownerIds.includes(item.empId),
  );
  const saudiSelected = saudiFilter.filter((item) =>
    saudiIds.includes(item.empId),
  );

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Add Company </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showAttachment && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          placeholder="Enter company name"
                          value={values.companyName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.companyName && errors.companyName ? (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name in Arabic
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyNameArabic"
                          onChange={handleChange}
                          placeholder="Enter company name in arabic"
                          value={values.companyNameArabic}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company's Contact No. *
                        </h4>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={(e) => {
                            // Accept numbers and special characters, but not alphabets
                            const re =
                              /^[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\b]+$/;

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter company's contact no."
                          value={values.phone}
                          className="custom-input border-fill"
                        />
                        {touched.phone && errors.phone ? (
                          <div className="invalid-feedback">{errors.phone}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Unified Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="unifiedNumber"
                          placeholder="Enter unified number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.unifiedNumber}
                          className="custom-input border-fill"
                        />
                        {touched.unifiedNumber && errors.unifiedNumber ? (
                          <div className="invalid-feedback">
                            {errors.unifiedNumber}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Employee Serial
                        </h4>
                        <Form.Control
                          type="text"
                          name="companySerial"
                          placeholder="Enter company serial"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.companySerial}
                          className="custom-input border-fill"
                        />
                        {touched.companySerial && errors.companySerial ? (
                          <div className="invalid-feedback">
                            {errors.companySerial}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      City*/}
                    {/*    </h4>*/}
                    {/*    <Form.Control*/}
                    {/*      type="text"*/}
                    {/*      name="city"*/}
                    {/*      placeholder="Enter city"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.city}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    />*/}
                    {/*    {touched.city && errors.city ? (*/}
                    {/*      <div className="invalid-feedback">{errors.city}</div>*/}
                    {/*    ) : null}*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    {/*<Col sm="4">*/}
                    {/*  <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*    <h4*/}
                    {/*      className="sub-heading normal-font-weight textBlack"*/}
                    {/*      style={{ fontSize: "18px" }}*/}
                    {/*    >*/}
                    {/*      Country*/}
                    {/*    </h4>*/}
                    {/*    <Form.Select*/}
                    {/*      name="country"*/}
                    {/*      onChange={handleChange}*/}
                    {/*      value={values.country}*/}
                    {/*      className="custom-input border-fill"*/}
                    {/*    >*/}
                    {/*      <option value="">Select Country</option>*/}
                    {/*      {countryCodes.map((item, index) => (*/}
                    {/*        <option key={index} value={item.name}>*/}
                    {/*          {item.name}*/}
                    {/*        </option>*/}
                    {/*      ))}*/}
                    {/*    </Form.Select>*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company MOL
                        </h4>
                        <Form.Control
                          type="text"
                          name="companyMOL"
                          placeholder="Enter Company MOL"
                          onChange={handleChange}
                          value={values.companyMOL}
                          className="custom-input border-fill"
                        />
                        {touched.companyMOL && errors.companyMOL ? (
                          <div className="invalid-feedback">
                            {errors.companyMOL}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Postal Code
                        </h4>
                        <Form.Control
                          type="text"
                          name="postalCode"
                          placeholder="Enter postal code"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.postalCode}
                          className="custom-input border-fill"
                        />
                        {touched.city && errors.city ? (
                          <div className="invalid-feedback">{errors.city}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          VAT Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberVAT"
                          placeholder="Enter VAT number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberVAT}
                          required={true}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          C.R. Number *
                        </h4>
                        <Form.Control
                          type="text"
                          name="numberCR"
                          placeholder="Enter CR number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.numberCR}
                          required={true}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          CR Expiry Date
                        </h4>
                        <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                          <DatePicker
                            name="crExpiry"
                            selected={
                              values.crExpiry ? new Date(values.crExpiry) : null
                            }
                            onChange={(date: any) =>
                              handleChange({
                                target: { name: "crExpiry", value: date },
                              })
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="Select cr expiry date"
                            className={
                              errors.crExpiry && touched.crExpiry
                                ? "is-invalid w-100 custom-input"
                                : "w-100 custom-input"
                            }
                            dateFormat="yyyy-MM-d"
                          />
                          <img
                            src={Common.Calendar}
                            className="calendar-date-recur"
                            alt="calendarblack"
                          />
                        </Form.Group>
                        {touched.crExpiry && errors.crExpiry ? (
                          <div className="invalid-feedback">
                            {errors.crExpiry}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Status
                        </h4>
                        <Form.Select
                          name="companyStatus"
                          onChange={handleChange}
                          value={values.companyStatus}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Company Status</option>
                          <option value="Active">Active</option>
                          <option value="In-active">In-active</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <Row>
                        <Col sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Address *
                            </h4>
                            <textarea
                              name="companyAddress"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyAddress}
                              required={true}
                              placeholder="Add address "
                              className={
                                touched.companyAddress && errors.companyAddress
                                  ? "is-invalid border-fill w-100"
                                  : "border-fill w-100"
                              }
                              rows={6}
                              style={{ resize: "none" }}
                            />
                            {touched.companyAddress && errors.companyAddress ? (
                              <div className="invalid-feedback">
                                {errors.companyAddress}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Address in arabic
                            </h4>
                            <textarea
                              name="companyAddressArabic"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyAddressArabic}
                              placeholder="Add address 1"
                              className="border-fill w-100"
                              rows={6}
                              style={{ resize: "none" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="custom-input border-fill"
                          />
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            onClick={handleEmail}
                            style={{ width: "25%" }}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Email List
                        </h4>
                        {selectedEmails.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEmails.map(
                                (item: string, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.toLocaleLowerCase()}</td>
                                    <td>
                                      <button
                                        className="btn p-0 border-0"
                                        type="button"
                                        onClick={() => {
                                          const updatedEmails =
                                            selectedEmails.filter(
                                              (email) => email !== item,
                                            );
                                          setSelectedEmails(updatedEmails);
                                        }}
                                      >
                                        <img
                                          src={SettingsIcons.Delete}
                                          width="24px"
                                          height="24px"
                                          alt="edit-user"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">No emails added</div>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddExperienceSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Subscription
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">{experienceSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn primary-button w-25"
                          onClick={() => {
                            if (values.companyName === "") {
                              setErrorContent("Company Name is required");
                              setShow(true);

                              return;
                            }
                            if (values.phone === "") {
                              setErrorContent(
                                "Company Contact no. is required",
                              );
                              setShow(true);

                              return;
                            }
                            if (values.companyName === "") {
                              setErrorContent("Company name is required");
                              setShow(true);

                              return;
                            }
                            if (values.numberVAT === "") {
                              setErrorContent("VAT number is required");
                              setShow(true);

                              return;
                            }
                            if (values.numberCR === "") {
                              setErrorContent("C.R. number is required");
                              setShow(true);

                              return;
                            }
                            if (values.companyAddress === "") {
                              setErrorContent("Company address is required");
                              setShow(true);

                              return;
                            }
                            setShowAttachment(true);
                          }}
                        >
                          Next
                          <img src={Common.ArrowRightWhite} alt="arrow" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {showAttachment && (
                  <Row>
                    <Col sm="3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Bank Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="bankName"
                          onChange={(e) => setBankName(e.target.value)}
                          placeholder="Enter bank name"
                          value={bankName}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          IBAN
                        </h4>
                        <Form.Control
                          type="text"
                          name="iban"
                          onChange={(e) => setIban(e.target.value)}
                          placeholder="Enter IBAN"
                          value={iban}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Account Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="accountNumber"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setAccountNumber(parseInt(e.target.value));
                            }
                          }}
                          placeholder="Enter account number"
                          value={accountNumber}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Swift Code
                        </h4>
                        <Form.Control
                          type="text"
                          name="swiftCode"
                          onChange={(e) => setSwiftCode(e.target.value)}
                          placeholder="Enter swift code"
                          value={swiftCode}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="1">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Add Bank
                      </h4>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn primary-button w-100"
                          type="button"
                          onClick={handleBankDetails}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Add
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Bank Details
                        </h4>
                        {bankDetails.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Bank Name</th>
                                <th>IBAN</th>
                                <th>Account Number</th>
                                <th>Swift Code</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bankDetails.map((item, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.bankName}</td>
                                  <td>{item.iban}</td>
                                  <td>{item.accountNumber}</td>
                                  <td>{item.swiftCode}</td>
                                  <td>
                                    <button
                                      className="btn p-0 border-0"
                                      type="button"
                                      onClick={() => {
                                        const updatedEmails =
                                          bankDetails.filter(
                                            (email) => email !== item,
                                          );
                                        setBankDetails(updatedEmails);
                                      }}
                                    >
                                      <img
                                        src={SettingsIcons.Delete}
                                        width="24px"
                                        height="24px"
                                        alt="edit-user"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">
                            No bank details added
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Owner
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Group className="position-relative input-design d-flex align-items-center w-100">
                            <SelectSearch
                              name="owner"
                              options={ownerOptions}
                              value={owner}
                              onChange={(value: any) => setOwner(value)}
                              search={true}
                              placeholder="Select Owner"
                            />
                            <img src={Common.ArrowDown} alt="message" />
                          </Form.Group>
                          <button
                            className="btn primary-button normal-font-weight "
                            type="button"
                            style={{ width: "25%" }}
                            onClick={handleOwner}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Owner List
                        </h4>
                        {ownerSelected.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Owner Id</th>
                                <th>Ower Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ownerSelected.map((item, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.empId}</td>
                                  <td>{item.empName}</td>
                                  <td>
                                    <button
                                      className="btn p-0 border-0"
                                      type="button"
                                      onClick={() => {
                                        const updatedEmails = ownerIds.filter(
                                          (email) => email !== item.empId,
                                        );
                                        setOwnerIds(updatedEmails);
                                      }}
                                    >
                                      <img
                                        src={SettingsIcons.Delete}
                                        width="24px"
                                        height="24px"
                                        alt="edit-user"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">No owners added</div>
                        )}
                      </div>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Saudi Employee
                        </h4>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Group className="position-relative input-design d-flex align-items-center w-100">
                            <SelectSearch
                              name="saudi"
                              options={saudiEmployeesOptions}
                              value={saudi}
                              onChange={(value: any) => setSaudi(value)}
                              search={true}
                              placeholder="Select Saudi Employee"
                            />
                            <img src={Common.ArrowDown} alt="message" />
                          </Form.Group>
                          <button
                            className="btn primary-button normal-font-weight"
                            type="button"
                            style={{ width: "25%" }}
                            onClick={handleSaudi}
                          >
                            <img
                              src={Common.Plus}
                              width="20px"
                              height="20px"
                              className="me-2"
                              alt="next"
                            />{" "}
                            Add
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="table-responsive mb-3">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Saudi Employees List
                        </h4>
                        {saudiSelected.length > 0 ? (
                          <table className="table text-center table-bordered">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Emp Id</th>
                                <th>Emp Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {saudiSelected.map((item, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.empId}</td>
                                  <td>{item.empName}</td>
                                  <td>
                                    <button
                                      className="btn p-0 border-0"
                                      type="button"
                                      onClick={() => {
                                        const updatedEmails = saudiIds.filter(
                                          (email) => email !== item.empId,
                                        );
                                        setSaudiIds(updatedEmails);
                                      }}
                                    >
                                      <img
                                        src={SettingsIcons.Delete}
                                        width="24px"
                                        height="24px"
                                        alt="edit-user"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center">
                            No saudi employees added
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachments
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="extra-detail my-4">{languageSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-between mt-4">
                        <button
                          className="btn secondary-button w-25"
                          type="button"
                          onClick={() => {
                            setShowAttachment(false);
                          }}
                        >
                          <img
                            src={Common.ArrowLeftBlue}
                            className="me-1"
                            alt="next"
                          />{" "}
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn primary-button w-25"
                        >
                          {state.addSponsorLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Add Company"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddSponsor;
