import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ToastErrorComponent } from "../Toasts";
import { setClientsChange } from "../../store/slices/commonSlice";
import { Common } from "../../assets/images";
import { LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import { useCreateTransactionMutation } from "../../services/Clients";
import {
  setAddClientLoading,
  setAddPaymentLoading,
  setClientIdNumber,
} from "../../store/slices/clientsSlice";
import AttachmentSet from "../Employees/AddEmployee/attachments";
import { InvoiceId } from "../../interfaces/project";

const CustomerPayment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.clients);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  const invoiceIds = useAppSelector((state) => state.clients.invoiceIds);
  const unpaidInvoice = invoiceIds.filter(
    (item) => item.status === "Un paid" && item.invoiceType === "Invoice",
  );
  const [unpaidInvoices, setUnpaidInvoices] =
    useState<InvoiceId[]>(unpaidInvoice);
  const [paymentDate, setPaymentDate] = useState<string>(
    new Date().toLocaleDateString(),
  );
  const [totalAmountForPayment, setTotalAmountForPayment] =
    useState<string>("");
  const [depositTo, setDepositTo] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [paymentMode, setPaymentMode] = useState<string>("");
  const [amountRecieved, setAmountRecieved] = useState<string>("0");
  const [amountRecievedCopy, setAmountRecievedCopy] = useState<string>("0");
  const [bankCharges, setBankCharges] = useState<string>("0");
  const [note, setNote] = useState<string>("");

  const clientIds = useAppSelector((state) => state.clients.clientIds);
  // Options
  const clientOptions = clientIds.map((item) => ({
    name: item.clientId + " - " + item.clientName,
    value: item.clientId,
  }));
  const accountChartsIds = useAppSelector(
    (state) => state.accountCharts.accountChartsIds,
  );
  // Options
  const companyOptions = accountChartsIds.map((item) => ({
    name: item.accountId + " - " + item.accountName,
    value: item.accountName,
  }));

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // API call for add salary increment
  const [createTransaction] = useCreateTransactionMutation();
  const handleSubmit = async () => {
    if (!paymentDate) {
      window.scrollTo(0, 0);
      setErrorContent("Please select payment date");
      setShow(true);
      return;
    }
    if (!client) {
      window.scrollTo(0, 0);
      setErrorContent("Please select client");
      setShow(true);
      return;
    }
    if (!amountRecieved || parseFloat(amountRecieved) === 0) {
      window.scrollTo(0, 0);
      setErrorContent("Please enter amount received");
      setShow(true);
      return;
    }
    if (!depositTo) {
      window.scrollTo(0, 0);
      setErrorContent("Please select deposit to");
      setShow(true);
      return;
    }
    if (parseFloat(totalAmountForPayment) > parseFloat(amountRecieved)) {
      window.scrollTo(0, 0);
      setErrorContent(
        "The amount entered for individual invoice(s) exceeds the total payment the customer has made. Please check and retry.",
      );
      setShow(true);
      return;
    }
    dispatch(setAddPaymentLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          paymentDate: paymentDate,
          clientId: client,
          amountReceived: parseFloat(amountRecievedCopy),
          paymentMode: paymentMode,
          depositTo: depositTo,
          referenceNumber: referenceNumber,
          note: note,
          attachments: languageData,
          transactions: unpaidInvoices.filter((item) => item.payment > 0),
          bankCharges: parseFloat(bankCharges),
          amountForPayments: parseFloat(totalAmountForPayment),
          amountExcess:
            parseFloat(amountRecieved) - parseFloat(totalAmountForPayment),
          link: `/clients`,
        },
      };
      await createTransaction(data).unwrap();
      dispatch(setClientIdNumber(""));
      dispatch(setClientsChange(!clientsChange));
      navigate(`/clients`);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddPaymentLoading(false));
    }
  };

  const paymentModeOptions = [
    { name: "Cash", value: "Cash" },
    { name: "Bank Transfer", value: "Bank Transfer" },
    { name: "Cheque", value: "Cheque" },
    { name: "Credit Card", value: "Credit Card" },
    { name: "Bank Remittance", value: "Bank Remittance" },
  ];

  // set amount recieved to the payment of all the invoices according to the amount due if the amount recieved is greater than the amount due then put the remaining amount in the next index
  useEffect(() => {
    let amount = parseFloat(amountRecieved);
    const updatedData = unpaidInvoices.map((item) => {
      if (amount > 0) {
        if (amount >= item.remainingAmount) {
          // If amountReceived is greater than or equal to remainingAmount
          amount -= item.remainingAmount; // Reduce amountReceived by remainingAmount
          console.log("amount", amount);
          return {
            ...item,
            payment: item.remainingAmount,
            remainingAmount: 0,
          };
        } else {
          // If amountReceived is less than amountDue
          const payment = amount; // Set payment as amountReceived
          amount = 0; // Set amountReceived to 0
          return {
            ...item,
            payment,
            remainingAmount: item.remainingAmount - payment,
          };
        }
      } else {
        // If amountReceived is 0 or less, set payment to 0
        return {
          ...item,
          payment: 0,
        };
      }
    });
    setUnpaidInvoices(updatedData);
  }, [amountRecieved]);

  const handlePayment = (index: number, value: number) => {
    const updatedData = unpaidInvoices.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          payment: value,
        };
      }
      return item;
    });
    setUnpaidInvoices(updatedData);
  };

  useEffect(() => {
    const totalAmount = unpaidInvoices.reduce(
      (acc, item) => acc + item.payment,
      0,
    );
    setTotalAmountForPayment(totalAmount.toString());
  }, [unpaidInvoices]);

  useEffect(() => {
    if (state.invoiceIdsLoading) {
      return;
    }
    if (!client) {
      return;
    }
    if (invoiceIds.length === 0) {
      return;
    }
    if (!state.invoiceIdsLoading && invoiceIds.length > 0) {
      setUnpaidInvoices(unpaidInvoice);
    }
  }, [amountRecievedCopy, unpaidInvoice.length]);

  return (
    <div className="mt-2 ">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />

      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Create Customer Payment</h1>
          </div>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Payment Date *
            </h4>
            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
              <DatePicker
                name="invoiceDate"
                selected={paymentDate ? new Date(paymentDate) : null}
                onChange={(e: any) => setPaymentDate(e)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                placeholderText="Select payment date"
                className="w-100 custom-input"
                dateFormat="yyyy-MM-d"
              />
              <img
                src={Common.Calendar}
                className="calendar-date-recur"
                alt="calendarblack"
              />
            </Form.Group>
          </Form.Group>
        </Col>

        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Select Client *
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
              <SelectSearch
                name="client"
                options={clientOptions}
                value={client}
                onChange={(value: any) => {
                  setClient(value);
                  setUnpaidInvoices([]);
                  dispatch(setClientIdNumber(value));
                }}
                search={true}
                placeholder="Select Client"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Amount Received *
            </h4>
            <Form.Control
              type="text"
              name="amountRecievedCopy"
              placeholder="Enter amount received"
              onChange={(e) => {
                //     accept only numbers
                const re = /^[0-9\b]*(\.\d{0,2})?$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setAmountRecievedCopy(e.target.value);
                }
              }}
              value={amountRecievedCopy}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="3">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Add this amount to be reflected in the Payment field?
          </h4>
          <button
            className="btn primary-button w-100"
            onClick={() => setAmountRecieved(amountRecievedCopy)}
          >
            Reflect Amount
          </button>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Bank Charges (if any)
            </h4>
            <Form.Control
              type="text"
              name="bankCharges"
              placeholder="Enter bank charges"
              onChange={(e) => {
                //     accept only numbers
                const re = /^[0-9\b]*(\.\d{0,2})?$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setBankCharges(e.target.value);
                }
              }}
              value={bankCharges}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Payment Mode
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
              <SelectSearch
                name="paymentMode"
                options={paymentModeOptions}
                value={paymentMode}
                onChange={(value: any) => setPaymentMode(value)}
                search={true}
                placeholder="Select Payment Mode"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Deposit To *
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
              <SelectSearch
                name="depositTo"
                options={companyOptions}
                value={depositTo}
                onChange={(value: any) => setDepositTo(value)}
                search={true}
                placeholder="Select Deposit To"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Reference #
            </h4>
            <Form.Control
              type="text"
              name="referenceNumber"
              placeholder="Enter reference number"
              onChange={(e) => setReferenceNumber(e.target.value)}
              value={referenceNumber}
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="8">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Note
            </h4>
            <textarea
              name="note"
              onChange={(e) => setNote(e.target.value)}
              value={note}
              required={true}
              placeholder="Add note"
              className="border-fill w-100"
              rows={6}
              style={{ resize: "none" }}
            />
          </Form.Group>
        </Col>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Unpaid Invoices</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail mb-4">
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Invoice Amount</th>
                    <th>Amount Due</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {unpaidInvoices.map((item, index) => (
                    <tr key={index}>
                      <td style={{ verticalAlign: "middle" }}>
                        {item.invoiceDate
                          ? new Date(item.invoiceDate).toLocaleDateString()
                          : ""}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {item.invoiceId}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {item.totalBalance.toFixed()}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {item.remainingAmount.toFixed()}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="payment"
                            placeholder="Enter payment"
                            autoComplete="off"
                            onChange={(e) => {
                              // Accept numbers and float values
                              const re = /^[0-9\b]*(\.\d{0,2})?$/;

                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                handlePayment(
                                  index,
                                  parseFloat(e.target.value),
                                );
                              }
                            }}
                            value={item.payment.toString()}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="6" className="ms-auto">
              <div
                className="sub-total p-4"
                style={{ background: "#f9f9fb", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-end gap-5">
                  <h4 className="sub-heading normal-font-weight me-5">
                    Amount Recieved:
                  </h4>
                  <div style={{ width: "100px" }}>
                    <h4 className="sub-heading normal-font-weight text-end">
                      {amountRecievedCopy ? amountRecievedCopy : 0}
                    </h4>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-5">
                  <h4 className="sub-heading normal-font-weight me-5">
                    Amount used for Payments:
                  </h4>
                  <div style={{ width: "100px" }}>
                    <h4 className="sub-heading normal-font-weight text-end">
                      {totalAmountForPayment}
                    </h4>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-5">
                  <h4 className="sub-heading normal-font-weight me-5">
                    Amount Refunded:
                  </h4>
                  <div style={{ width: "100px" }}>
                    <h4 className="sub-heading normal-font-weight text-end">
                      {0}
                    </h4>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-5">
                  <h4 className="sub-heading normal-font-weight me-5">
                    Amount Excess:
                  </h4>
                  <div style={{ width: "100px" }}>
                    <h4 className="sub-heading normal-font-weight text-end">
                      {parseFloat(amountRecieved) -
                        parseFloat(totalAmountForPayment)}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Attachments
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">{languageSets}</div>
        </Col>
        <Col sm="12">
          <div className="text-end">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addPaymentLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Create Customer Payment"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerPayment;
