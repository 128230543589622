import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetStatementsQuery } from "../../services/Clients";
import {
  setStatementIdsError,
  setStatementIdsLoading,
  setStatementIds,
} from "../../store/slices/clientsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  // States
  const state = useAppSelector((state) => state.clients);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allStatementIds,
    isLoading: statementIdsLoading,
    isError: statementIdsError,
    refetch: statementIdsRefetch,
  } = useGetStatementsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    clientID: state.clientIdNumber,
    fromDate: new Date(state.statementFrom).toLocaleDateString(),
    toDate: new Date(state.statementTo).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (statementIdsLoading) {
      dispatch(setStatementIdsLoading(true));
    }
    if (statementIdsError) {
      dispatch(setStatementIdsError("Something went wrong"));
      dispatch(setStatementIdsLoading(false));
    }
    if (allStatementIds) {
      dispatch(setStatementIds(allStatementIds.data.statement || []));
      dispatch(setStatementIdsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allStatementIds,
    profileData,
    state.clientIdNumber,
    state.statementFrom,
    state.statementTo,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    statementIdsRefetch();
  }, [
    clientsChange,
    state.clientIdNumber,
    state.statementFrom,
    state.statementTo,
  ]);
  return <></>;
};
export default Content;
