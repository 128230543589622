import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Common } from "../../assets/images";

interface Props {
  show?: boolean;
  setShow?: any;
}

const ResetPasswordSuccessModal = ({ show, setShow }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="general-modal reset-password-success-modal"
    >
      <Modal.Body className="text-center">
        <img src={Common.SuccessTick} alt="success-tick" />
        <h4 className="mt-2 mb-0">Password reset successfully.</h4>
        <p className="mb-0">Your Password is Successfully updated...</p>
        <button
          className="btn primary-button mt-4"
          onClick={() => navigate("/login")}
        >
          Login
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordSuccessModal;
