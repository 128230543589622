import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Table, Pagination, Form } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setPageNo, setPageSize } from "../../store/slices/attendanceSlice";
import { LoaderBlue } from "../Lotties";
import { useDeleteAttendanceMutation } from "../../services/Attendance";
import { AttendanceLog } from "../../interfaces/attendance";

interface Props {
  attendancess: AttendanceLog[];
  totalRecordss: number;
}

const LogData = ({ attendancess, totalRecordss }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const state = useAppSelector((state) => state.attendance);
  const attendances =
    attendancess.length > 0 ? attendancess : state.attendances;
  const totalRecords =
    attendancess.length > 0 ? totalRecordss : state.totalRecords;
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  // Pagination
  const currentPage = state.pageNo;
  const pageSize = state.pageSize;

  const totalPages = Math.ceil(totalRecords / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    dispatch(setPageNo(currentPage + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    dispatch(setPageNo(currentPage - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setPageSize(selectedPageSize));
  };
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Date",
      "Emp Code",
      "Emp Name",
      "Project Id",
      "Checkin Time",
      "Checkout Time",
      "Worked Hours",
      "Status",
      "Actions",
    ],
    tableData: attendances,
  };

  const [deleteAttendance] = useDeleteAttendanceMutation();

  const handleDeleteAll = async (empId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData.permissions.deleteAttendance || false,
      payload: {
        docId: empId,
        type: "",
        ids: [],
      },
    };
    try {
      await deleteAttendance(data).unwrap();
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: false }));
    }
  };

  return (
    <div className=" mt-4 section px-4 py-4">
      <Row>
        <Col sm="12">
          {state.attendanceLogLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showAttendance ? (
            <>
              {tableData.tableData.length > 0 ? (
                <Table striped bordered responsive className="mt-5 text-center">
                  <thead>
                    <tr className="border-0">
                      {tableData.tableHeading.map((item, index) => (
                        <th className="border-0" key={index}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.tableData.map(
                      (item: AttendanceLog, index: number) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalRecords > 0
                              ? (currentPage - 1) * pageSize + index + 1
                              : index + 1}
                          </td>
                          <td>
                            {item.date
                              ? new Date(item.date).toLocaleDateString()
                              : ""}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                { state: { empId: item.empId } },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                { state: { empId: item.empId } },
                              )
                            }
                          >
                            {item.empName}
                          </td>
                          <td>{item.projectId}</td>
                          <td>{item.fromDate}</td>
                          <td>{item.toDate}</td>
                          <td>{item.hours}</td>
                          <td>
                            <p
                              className="mb-0 py-1 px-4 text-white mx-auto"
                              style={{
                                background:
                                  item.projectedStatus === "Present"
                                    ? "rgba(57, 179, 89, 1)"
                                    : item.projectedStatus === "Absent"
                                      ? "rgba(235, 78, 43, 1)"
                                      : "rgba(239, 148, 0, 1)",
                                width: "fit-content",
                                borderRadius: "20px",
                              }}
                            >
                              {item.projectedStatus}
                            </p>
                          </td>
                          <td className="py-3">
                            <button
                              className="btn p-0 border-0"
                              style={{ height: "30px" }}
                              onClick={() =>
                                navigate(
                                  `/attendance/edit-attendance/${item.empId}`,
                                  { state: { data: item } },
                                )
                              }
                            >
                              <img
                                src={SettingsIcons.EditUser}
                                width="30px"
                                height="30px"
                                alt="edit-user"
                              />
                            </button>
                            {deleteLoadingMap[item._id] ? (
                              <LoaderBlue height={20} width={20} />
                            ) : (
                              <button
                                className="btn p-0 border-0"
                                onClick={() => handleDeleteAll(item._id)}
                              >
                                <img
                                  src={SettingsIcons.Delete}
                                  width="24px"
                                  height="24px"
                                  alt="edit-user"
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No attendance log found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {attendances.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default LogData;
