import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { Expense } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterExpensesTotalPages,
  setExpensesLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredExpensesMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setExpenses?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  expenses: Expense[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  fromDate: string;
  toDate: string;
  amountFrom: string;
  amountTo: string;
  payAbleBy: string;
  expenseType: string;
  expenseStatus: string;
}

const ExpensesFilterModal = ({
  show,
  setShow,
  expenses,
  setExpenses,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectState = useAppSelector((state) => state.project);
  const [getAllFilteredExpenses] = useGetAllFilteredExpensesMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setExpensesLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterExpensesPageNo,
          limit: state.filterExpensesPageSize,
          projectId: filterInput.projectId,
          fromDate: filterInput.fromDate,
          toDate: filterInput.toDate,
          amountFrom: parseInt(filterInput.amountFrom),
          amountTo: parseInt(filterInput.amountTo),
          payAbleBy: filterInput.payAbleBy,
          expenseType: filterInput.expenseType,
          expenseStatus: filterInput.expenseStatus,
        },
      };
      const response = await getAllFilteredExpenses(data).unwrap();
      if (response) {
        setExpenses(response.data.expenses[0].data);
        dispatch(
          setFilterExpensesTotalPages(
            response.data.expenses[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setExpensesLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setExpensesLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterExpensesPageNo,
            limit: state.filterExpensesPageSize,
            empId: parseInt(query),
            projectId: filterInput.projectId,
            fromDate: filterInput.fromDate,
            toDate: filterInput.toDate,
            amountFrom: parseInt(filterInput.amountFrom),
            amountTo: parseInt(filterInput.amountTo),
            payAbleBy: filterInput.payAbleBy,
            expenseType: filterInput.expenseType,
            expenseStatus: filterInput.expenseStatus,
          },
        };
        const response = await getAllFilteredExpenses(data).unwrap();
        if (response) {
          setExpenses(response.data.expenses[0].data);
          dispatch(
            setFilterExpensesTotalPages(
              response.data.expenses[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setExpensesLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterExpensesPageNo, state.filterExpensesPageSize, query]);

  const handleReset = () => {
    setExpenses(expenses);
    setFilterInput({
      projectId: "",
      fromDate: "",
      toDate: "",
      amountFrom: "",
      amountTo: "",
      payAbleBy: "",
      expenseType: "",
      expenseStatus: "",
    });
  };

  const projectOptions = projectState.projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Expenses</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project ID
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="emp"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) =>
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          })
                        }
                        search={true}
                        placeholder="Select Project Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Expense Type
                    </h4>
                    <Form.Select
                      name="selectedLanguage"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          expenseType: e.target.value,
                        })
                      }
                      value={filterInput.expenseType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Expense Type</option>
                      <option value="Pending">Pending</option>
                      <option value="Cleared">Cleared</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Payable by
                    </h4>
                    <Form.Select
                      name="selectedLanguage"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          payAbleBy: e.target.value,
                        })
                      }
                      value={filterInput.payAbleBy}
                      className="custom-input border-fill"
                    >
                      <option value="">Select payable by</option>
                      <option value="Pending">Pending</option>
                      <option value="Cleared">Cleared</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Expense Status
                    </h4>
                    <Form.Select
                      name="selectedLanguage"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          expenseStatus: e.target.value,
                        })
                      }
                      value={filterInput.expenseStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Expense Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Cleared">Cleared</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Amount From
                    </h4>
                    <Form.Control
                      type="text"
                      name="amountFrom"
                      placeholder="Amount From"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            amountFrom: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.amountFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Amount To
                    </h4>
                    <Form.Control
                      type="text"
                      name="amountTo"
                      placeholder="Amount To"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            amountTo: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.amountTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      placeholder="From Date"
                      onChange={(e) => {
                        setFilterInput({
                          ...filterInput,
                          fromDate: e.target.value,
                        });
                      }}
                      value={filterInput.fromDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Control
                      type="date"
                      name="toDate"
                      placeholder="To Date"
                      onChange={(e) => {
                        setFilterInput({
                          ...filterInput,
                          toDate: e.target.value,
                        });
                      }}
                      value={filterInput.toDate}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpensesFilterModal;
