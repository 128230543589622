import React from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCountryCode,
  setShowDropDown,
  setShowPasswordUpdateModal,
  setCountry,
  setPhone,
} from "../../store/slices/settingsSlice";
import { setSettingsChange } from "../../store/slices/commonSlice";
import ChangePasswordModal from "../Modals/change-password-modal";
// API
import { useEditProfileImageMutation } from "../../services/images-upload-service";
import { LoaderBlue } from "../Lotties";

const UserSettings = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.settings);
  const [imageLoading, setImageLoading] = React.useState(false);
  const userID = useAppSelector((state) => state.common.userID);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
  ];

  const [editProfileImage] = useEditProfileImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        userId: userID || "",
        permission: true,
        payload: formData,
      };

      try {
        await editProfileImage(data).unwrap();
        dispatch(setSettingsChange(!settingsChange));
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  return (
    <div className="user-settings mt-2">
      <ChangePasswordModal
        show={state.showPasswordUpdateModal}
        setShow={setShowPasswordUpdateModal}
      />
      {state.loading ? (
        <LoaderBlue height={70} width={70} />
      ) : state.responseError ? (
        <p>Something went wrong</p>
      ) : (
        <Row>
          <Col md="4">
            <div className="user-settings-left">
              <div className="d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Edit Image
                </h4>
                <label className="d-flex align-items-center justify-content-center cursor-pointer">
                  <input
                    type="file"
                    name="profileImage"
                    className="d-none"
                    onChange={handleUploadImage}
                    accept=".png, .jpg, .jpeg"
                    multiple={false}
                  />
                  <div className="edit-icon d-flex align-items-center justify-content-center">
                    <img
                      src={SettingsIcons.EditIcon}
                      width="18px"
                      height="18px"
                      alt="edit"
                    />
                  </div>
                </label>
              </div>
              {imageLoading ? (
                <LoaderBlue height={50} width={50} />
              ) : (
                <>
                  {state.profileData.img ? (
                    <img
                      src={state.profileData.img}
                      className="settings-profile-img mt-2 mb-3"
                      alt="setting-icon"
                    />
                  ) : (
                    <img
                      src={Common.UserImgPlaceholder}
                      className="settings-profile-img mt-2 mb-3"
                      alt="setting-icon"
                    />
                  )}
                </>
              )}
              <h4 className="heading normal-font-weight textBlack mt-2 mb-0">
                {state.profileData.firstName + " " + state.profileData.lastName}
              </h4>
            </div>
          </Col>
          <Col md="8">
            <div className="user-info-disabled">
              <Row>
                {state.profileData.permissions.roleName !== "Super Admin" && (
                  <Col md="6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Emp Code
                      </h4>
                      <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                        <Form.Control
                          type="text"
                          name="empCode"
                          className="custom-input"
                          placeholder="Enter emp code"
                          value={state.profileData.employeeId}
                          disabled={true}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                )}
                <Col md="6">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4 className="sub-heading normal-font-weight textBlack">
                      Email
                    </h4>
                    <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                      <Form.Control
                        type="email"
                        name="email"
                        className="custom-input"
                        value={state.profileData.email}
                        disabled={true}
                      />
                    </Form.Group>
                  </Form.Group>
                </Col>
                {state.profileData.permissions.roleName === "Super Admin" && (
                  <>
                    <Col md="6">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4 className="sub-heading normal-font-weight textBlack">
                          Designation
                        </h4>
                        <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                          <Form.Control
                            type="text"
                            name="designation"
                            className="custom-input"
                            value={state.profileData.designation}
                            disabled={true}
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4 className="sub-heading normal-font-weight textBlack">
                          Department
                        </h4>
                        <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                          <Form.Control
                            type="text"
                            name="department"
                            className="custom-input"
                            value={state.profileData.department}
                            disabled={true}
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              {state.profileData.permissions.roleName !== "Super Admin" && (
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Designation
                      </h4>
                      <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                        <Form.Control
                          type="text"
                          name="designation"
                          className="custom-input"
                          value={state.profileData.designation}
                          disabled={true}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Department
                      </h4>
                      <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                        <Form.Control
                          type="text"
                          name="department"
                          className="custom-input"
                          value={state.profileData.department}
                          disabled={true}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {state.profileData.permissions.roleName !== "Super Admin" && (
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Date of Birth
                      </h4>
                      <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
                        <Form.Control
                          type="text"
                          name="dob"
                          className="custom-input"
                          placeholder="Enter DOB"
                          value={
                            state.profileData.dateOfBirth
                              ? new Date(
                                  state.profileData.dateOfBirth,
                                ).toLocaleDateString()
                              : ""
                          }
                          disabled={true}
                        />
                        <img src={Common.Calendar} alt="message" />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    {/*<Formik*/}
                    {/*  initialValues={initialValues}*/}
                    {/*  validationSchema={validationSchema}*/}
                    {/*  onSubmit={(values) => {*/}
                    {/*    */}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  {({*/}
                    {/*    values,*/}
                    {/*    errors,*/}
                    {/*    touched,*/}
                    {/*    handleChange,*/}
                    {/*    handleBlur,*/}
                    {/*    handleSubmit,*/}
                    {/*  }) => (*/}
                    {/*    <Form onSubmit={handleSubmit}>*/}
                    <Form.Group controlId="formBasicEmail">
                      <h4 className="sub-heading normal-font-weight textBlack">
                        Mobile No
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center">
                        <Dropdown
                          show={state.showDropDown}
                          onToggle={() =>
                            dispatch(setShowDropDown(!state.showDropDown))
                          }
                          className="ms-auto task-detail-menu"
                        >
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="p-0 border-0"
                          >
                            <img
                              src={
                                state.countryCode === "+966"
                                  ? Common.SA
                                  : state.countryCode === "+971"
                                    ? Common.UAE
                                    : state.countryCode === "+92"
                                      ? Common.PK
                                      : state.countryCode === "+91"
                                        ? Common.IN
                                        : state.countryCode === "+98"
                                          ? Common.IR
                                          : state.countryCode === "+884"
                                            ? Common.BD
                                            : state.countryCode === "+93"
                                              ? Common.AF
                                              : Common.UAE
                              }
                              height="18px"
                              width="26px"
                              className="me-2"
                              alt="uae"
                            />
                            <img src={Common.ArrowDown} alt="toggle" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            show={state.showDropDown}
                            className="px-3"
                          >
                            <ul className="countries-list">
                              {countryCodes.map((item, index) => (
                                <li key={index}>
                                  <button
                                    className="btn p-0 border-0 d-flex align-items-center"
                                    type="button"
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                      dispatch(setCountryCode(item.dial_code));
                                      dispatch(setCountry(item.name));
                                      dispatch(setShowDropDown(false));
                                    }}
                                  >
                                    <p className="mb-0 para-small">
                                      {item.name}
                                    </p>
                                  </button>
                                  <hr className="my-2" />
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="mb-0 country-code ms-2">
                          {state.countryCode}
                        </p>
                        <Form.Control
                          type="text"
                          name="phone"
                          placeholder="Phone number"
                          onChange={(e) => dispatch(setPhone(e.target.value))}
                          value={state.phone}
                          required={true}
                          className="custom-input"
                        />
                      </Form.Group>
                    </Form.Group>
                    {/*    </Form>*/}
                    {/*  )}*/}
                    {/*</Formik>*/}
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="6">
                  <button
                    type="button"
                    onClick={() => dispatch(setShowPasswordUpdateModal(true))}
                    className="btn primary-button normal-font-weight mt-2 w-100"
                  >
                    Change Password
                  </button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default UserSettings;
