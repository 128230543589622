import React from "react";
import InternalLayout from "../components/InternalLayout";
import ProjectComponent from "../components/Project";
import withAuth from "../config/private-route";

const Project = () => {
  return (
    <InternalLayout>
      <ProjectComponent />
    </InternalLayout>
  );
};
export default withAuth(Project);
