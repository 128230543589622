import { createSlice } from "@reduxjs/toolkit";

interface Index {
  accessToken: string | null;
  loading: boolean;
  emailError: string;
  passwordError: string;
  visiblePasswords: boolean;
  visibleRepeatPasswords: boolean;
  showResetModal: boolean;
  userIdentity: boolean;
  userInfo: boolean;
  userPassword: boolean;
  showDropDown: boolean;
  countryCode: string;
  rememberMe: boolean;
  country: string;
  userId: string | null;
  role: string | null;
}

const initialState: Index = {
  accessToken: null,
  loading: false,
  emailError: "",
  passwordError: "",
  visiblePasswords: false,
  visibleRepeatPasswords: false,
  showResetModal: false,
  userIdentity: true,
  userInfo: false,
  userPassword: false,
  showDropDown: false,
  countryCode: "+966",
  rememberMe: false,
  country: "Saudi Arabia",
  userId: null,
  role: null,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.accessToken = action.payload.data.user.accessToken;
      state.userId = action.payload.data.user._id;
      state.role = action.payload.data.user.role;
    },
    loadingState: (state, action) => {
      state.loading = action.payload;
    },
    emailErrorState: (state, action) => {
      state.emailError = action.payload;
    },
    passwordErrorState: (state, action) => {
      state.passwordError = action.payload;
    },
    visiblePasswordState: (state, action) => {
      state.visiblePasswords = action.payload;
    },
    visibleRepeatPasswordState: (state, action) => {
      state.visibleRepeatPasswords = action.payload;
    },
    showResetModalState: (state, action) => {
      state.showResetModal = action.payload;
    },
    setUserIdentity: (state, action) => {
      state.userIdentity = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserPassword: (state, action) => {
      state.userPassword = action.payload;
    },
    setShowDropDown: (state, action) => {
      state.showDropDown = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
      if (state.rememberMe === false) {
        sessionStorage.setItem("accessToken", state.accessToken || "");
        sessionStorage.setItem("userID", state.userId || "");
        sessionStorage.setItem("role", state.role || "");
      } else {
        localStorage.setItem("accessToken", state.accessToken || "");
        localStorage.setItem("userID", state.userId || "");
        localStorage.setItem("role", state.role || "");
      }
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loadingState,
  emailErrorState,
  passwordErrorState,
  visiblePasswordState,
  visibleRepeatPasswordState,
  showResetModalState,
  setUserIdentity,
  setUserInfo,
  setUserPassword,
  setShowDropDown,
  setCountryCode,
  setRememberMe,
  setCountry,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
