import React, { useEffect } from "react";
import { Row, Col, ListGroup, Form } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";

const ReportsContainer = () => {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("");
  const [filterSearch, setFilterSearch] = React.useState(false);

  const reportCategory = [
    { name: "Business Overview", value: "business-overview" },
    { name: "Sales", value: "sales" },
    { name: "Receivables", value: "receivables" },
    { name: "Payments Received", value: "payments-received" },
    { name: "Recurring Invoices", value: "recurring-invoices" },
    { name: "Payables", value: "payables" },
    { name: "Purchases and Expenses", value: "purchases-and-expenses" },
    // { name: "Banking", value: "banking" },
    { name: "Projects and Timesheet", value: "projects-and-timesheet" },
    { name: "Accountant", value: "accountant" },
    // { name: "Budgets", value: "budgets" },
    // { name: "Currency", value: "currency" },
  ];
  const handleReset = () => {
    setQuery("");
    setFilterSearch(false);
  };

  const reports = {
    businessOverview: [
      {
        name: "Profit and Loss",
        type: "Profit and Loss",
        createdBy: "System Generated",
        href: "/reports/profit-and-loss",
      },
      {
        name: "Cash Flow Statement",
        type: "Cash Flow Statement",
        createdBy: "System Generated",
        href: "/reports/cash-flow-statement",
      },
      {
        name: "Balance Sheet",
        type: "Balance Sheet",
        createdBy: "System Generated",
        href: "/reports/balance-sheet",
      },
      // {
      //   name: "Business Performance Ratios",
      //   type: "Business Performance Ratios",
      //   createdBy: "System Generated",
      //   href: "/reports/business-performance-ratios",
      // },
      // {
      //   name: "Movement of Equity",
      //   type: "Movement of Equity",
      //   createdBy: "System Generated",
      //   href: "/reports/movement-of-equity",
      // },
    ],
    sales: [
      {
        name: "Sales",
        type: "Sales",
        createdBy: "System Generated",
        href: "/reports/sales",
      },
      // {
      //   name: "Sales by Item",
      //   type: "Sales by Item",
      //   createdBy: "System Generated",
      //   href: "/reports/sales-by-item",
      // },
      // {
      //   name: "Sales by Sales Person",
      //   type: "Sales by Sales Person",
      //   createdBy: "System Generated",
      //   href: "/reports/sales-by-sales-person",
      // },
    ],
    receivables: [
      {
        name: "Customer Balances",
        type: "Customer Balances",
        createdBy: "System Generated",
        href: "/reports/customer-balance",
      },
      // {
      //   name: "AR Aging Summary",
      //   type: "AR Aging Summary",
      //   createdBy: "System Generated",
      //   href: "/reports/ar-aging-summary",
      // },
      // {
      //   name: "AR Aging Details",
      //   type: "AR Aging Details",
      //   createdBy: "System Generated",
      //   href: "/reports/ar-aging-details",
      // },
      {
        name: "Invoice Details",
        type: "Invoice Details",
        createdBy: "System Generated",
        href: "/reports/invoice-details",
      },
      {
        name: "Delivery Challan Details",
        type: "Delivery Challan Details",
        createdBy: "System Generated",
        href: "/reports/delivery-challan-details",
      },
      // {
      //   name: "Quote Details",
      //   type: "Quote Details",
      //   createdBy: "System Generated",
      //   href: "/reports/quote-details",
      // },
      {
        name: "Customer Balance Summary",
        type: "Customer Balance Summary",
        createdBy: "System Generated",
        href: "/reports/customer-balance-summary",
      },
      {
        name: "Receivable Summary",
        type: "Receivable Summary",
        createdBy: "System Generated",
        href: "/reports/receivable-summary",
      },
      {
        name: "Receivable Details",
        type: "Receivable Details",
        createdBy: "System Generated",
        href: "/reports/receivable-details",
      },
    ],
    paymentsReceived: [
      {
        name: "Payments Received",
        type: "Payments Received",
        createdBy: "System Generated",
        href: "/reports/payments-received",
      },
      {
        name: "Time to Get Paid",
        type: "Time to Get Paid",
        createdBy: "System Generated",
        href: "/reports/time-to-get-paid",
      },
      {
        name: "Credit Note Details",
        type: "Credit Note Details",
        createdBy: "System Generated",
        href: "/reports/credit-note-details",
      },
      // {
      //   name: "Refund History",
      //   type: "Refund History",
      //   createdBy: "System Generated",
      //   href: "/reports/refund-history",
      // },
    ],
    recurringInvoices: [
      {
        name: "Recurring Invoice Details",
        type: "Recurring Invoice Details",
        createdBy: "System Generated",
        href: "/reports/recurring-invoice-details",
      },
    ],
    payables: [
      {
        name: "Vendor Balance",
        type: "Vendor Balance",
        createdBy: "System Generated",
        href: "/reports/vendor-balance",
      },
      {
        name: "Vendor Balance Summary",
        type: "Vendor Balance Summary",
        createdBy: "System Generated",
        href: "/reports/vendor-balance-summary",
      },
      // {
      //   name: "AP Aging Summary",
      //   type: "AP Aging Summary",
      //   createdBy: "System Generated",
      //   href: "/reports/ap-aging-summary",
      // },
      // {
      //   name: "AP Aging Details",
      //   type: "AP Aging Details",
      //   createdBy: "System Generated",
      //   href: "/reports/ap-aging-details",
      // },
      {
        name: "Bills Details",
        type: "Bills Details",
        createdBy: "System Generated",
        href: "/reports/bills-details",
      },
      {
        name: "Vendor Credit Details",
        type: "Vendor Credit Details",
        createdBy: "System Generated",
        href: "/reports/vendor-credit-details",
      },
      {
        name: "Payments Made",
        type: "Payments Made",
        createdBy: "System Generated",
        href: "/reports/payments-made",
      },
      // {
      //   name: "Refund History",
      //   type: "Refund History",
      //   createdBy: "System Generated",
      //   href: "/reports/refund-history",
      // },
      {
        name: "Payable Summary",
        type: "Payable Summary",
        createdBy: "System Generated",
        href: "/reports/payable-summary",
      },
      {
        name: "Payable Details",
        type: "Payable Details",
        createdBy: "System Generated",
        href: "/reports/payable-details",
      },
    ],
    purchasesAndExpenses: [
      {
        name: "Purchases by Vendor",
        type: "Purchases by Vendor",
        createdBy: "System Generated",
        href: "/reports/purchases-by-vendor",
      },
      {
        name: "Purchases by Item",
        type: "Purchases by Item",
        createdBy: "System Generated",
        href: "/reports/purchases-by-item",
      },
      {
        name: "Expense Details",
        type: "Expense Details",
        createdBy: "System Generated",
        href: "/reports/expense-details",
      },
      {
        name: "Expenses by Category",
        type: "Expenses by Category",
        createdBy: "System Generated",
        href: "/reports/expenses-by-category",
      },
      {
        name: "Expenses by Customer",
        type: "Expenses by Customer",
        createdBy: "System Generated",
        href: "/reports/expenses-by-customer",
      },
      {
        name: "Expenses by Project",
        type: "Expenses by Project",
        createdBy: "System Generated",
        href: "/reports/expenses-by-project",
      },
      {
        name: "Expenses by Employee",
        type: "Expenses by Employee",
        createdBy: "System Generated",
        href: "/reports/expenses-by-employee",
      },
      {
        name: "Billable Expense Details",
        type: "Billable Expense Details",
        createdBy: "System Generated",
        href: "/reports/billable-expense-details",
      },
    ],
    // banking: [
    //   {
    //     name: "Reconciliation Status",
    //     type: "Reconciliation Status",
    //     createdBy: "System Generated",
    //     href: "/reports/reconciliation-status",
    //   },
    // ],
    projectsAndTimesheet: [
      {
        name: "Timesheet Profitabilty Summary",
        type: "Timesheet Profitabilty Summary",
        createdBy: "System Generated",
        href: "/reports/timesheet-profitabilty-summary",
      },
      {
        name: "Project Summary",
        type: "Project Summary",
        createdBy: "System Generated",
        href: "/reports/project-summary",
      },
      {
        name: "Project Details",
        type: "Project Details",
        createdBy: "System Generated",
        href: "/reports/project-details",
      },
      {
        name: "Projects Cost Summary",
        type: "Projects Cost Summary",
        createdBy: "System Generated",
        href: "/reports/projects-cost-summary",
      },
      {
        name: "Projects Revenue Summary",
        type: "Projects Revenue Summary",
        createdBy: "System Generated",
        href: "/reports/projects-revenue-summary",
      },
      {
        name: "Projects Performance Summary",
        type: "Projects Performance Summary",
        createdBy: "System Generated",
        href: "/reports/projects-performance-summary",
      },
    ],
    accountant: [
      {
        name: "Account Transactions",
        type: "Account Transactions",
        createdBy: "System Generated",
        href: "/reports/account-transactions",
      },
      {
        name: "Account Type Summary",
        type: "Account Type Summary",
        createdBy: "System Generated",
        href: "/reports/account-type-summary",
      },
      {
        name: "General Ledger",
        type: "General Ledger",
        createdBy: "System Generated",
        href: "/reports/general-ledger",
      },
      {
        name: "Detailed General Ledger",
        type: "Detailed General Ledger",
        createdBy: "System Generated",
        href: "/reports/detailed-general-ledger",
      },
      {
        name: "Journal Report",
        type: "Journal Report",
        createdBy: "System Generated",
        href: "/reports/journal-report",
      },
      {
        name: "Trial Balance",
        type: "Trial Balance",
        createdBy: "System Generated",
        href: "/reports/trial-balance",
      },
    ],
    // budgets: [
    //   {
    //     name: "Budget Vs Actuals",
    //     type: "Budget Vs Actuals",
    //     createdBy: "System Generated",
    //     href: "/reports/budget-vs-actuals",
    //   },
    // ],
    // currency: [
    //   {
    //     name: "Realized Gain or Loss",
    //     type: "Realized Gain or Loss",
    //     createdBy: "System Generated",
    //     href: "/reports/realized-gain-or-loss",
    //   },
    //   {
    //     name: "Unrealized Gain or Loss",
    //     type: "Unrealized Gain or Loss",
    //     createdBy: "System Generated",
    //     href: "/reports/unrealized-gain-or-loss",
    //   },
    // ],
  };

  const options = reports.businessOverview
    .concat(
      reports.sales,
      reports.receivables,
      reports.paymentsReceived,
      reports.recurringInvoices,
      reports.payables,
      reports.purchasesAndExpenses,
      // reports.banking,
      reports.projectsAndTimesheet,
      reports.accountant,
      // reports.budgets,
      // reports.currency,
    )
    .map((report) => {
      return { name: report.name, value: report.href };
    });

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4 d-flex align-items-center justify-content-between">
            <h1 className="heading mb-0">Reports Center</h1>
            <div
              className=" d-flex align-items-center gap-2"
              style={{ width: "25%" }}
            >
              <Form.Group className="w-100" controlId="formBasicEmail">
                <Form.Group className="position-relative input-design d-flex align-items-center">
                  <SelectSearch
                    options={options}
                    onChange={(value: any) => {
                      setQuery(value);
                      setFilterSearch(true);
                      navigate(value);
                    }}
                    value={query}
                    search={true}
                    placeholder="Search Reports "
                  />
                  <img src={Common.ArrowDown} alt="message" />
                </Form.Group>
              </Form.Group>
              {query !== "" ? (
                <button
                  type="button"
                  className="btn primary-button w-25"
                  onClick={handleReset}
                >
                  {" "}
                  Reset
                </button>
              ) : filterSearch ? (
                <button
                  type="button"
                  className="btn primary-button w-25"
                  onClick={handleReset}
                >
                  {" "}
                  Reset
                </button>
              ) : null}
            </div>
          </div>
        </Col>
        <Col sm={3}>
          <ListGroup>
            <ListGroup.Item>
              <h4 className="sub-heading my-1">Report Category</h4>
            </ListGroup.Item>
            {reportCategory.map((option, index) => (
              <ListGroup.Item key={index} action href={`#${option.value}`}>
                <h4
                  className="sub-heading my-1 normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  {option.name}
                </h4>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col sm={9}>
          <ListGroup style={{ height: "76vh", overflow: "auto" }}>
            <ListGroup.Item>
              <h4 className="sub-heading my-1">All Reports</h4>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-flex justify-content-between align-items-center gap-5">
                <h4
                  className="sub-heading my-1 textBlack normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  Report Name
                </h4>
                <h4
                  className="sub-heading my-1 textBlack normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  Type
                </h4>
                <h4
                  className="sub-heading my-1 textBlack normal-font-weight"
                  style={{ fontSize: "18px" }}
                >
                  Created By
                </h4>
              </div>
            </ListGroup.Item>
            <ListGroup.Item id="business-overview">
              <h4 className="sub-heading my-1">Business Overview</h4>
            </ListGroup.Item>
            {reports.businessOverview.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="sales">
              <h4 className="sub-heading my-1">Sales</h4>
            </ListGroup.Item>
            {reports.sales.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="receivables">
              <h4 className="sub-heading my-1">Receivables</h4>
            </ListGroup.Item>
            {reports.receivables.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="payments-received">
              <h4 className="sub-heading my-1">Payments Received</h4>
            </ListGroup.Item>
            {reports.paymentsReceived.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="recurring-invoices">
              <h4 className="sub-heading my-1">Recurring Invoices</h4>
            </ListGroup.Item>
            {reports.recurringInvoices.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="payables">
              <h4 className="sub-heading my-1">Payables</h4>
            </ListGroup.Item>
            {reports.payables.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="purchases-and-expenses">
              <h4 className="sub-heading my-1">Purchases and Expenses</h4>
            </ListGroup.Item>
            {reports.purchasesAndExpenses.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            {/*<ListGroup.Item id="banking">*/}
            {/*  <h4 className="sub-heading my-1">Banking</h4>*/}
            {/*</ListGroup.Item>*/}
            {/*{reports.banking.map((report, index) => (*/}
            {/*  <ListGroup.Item key={index}>*/}
            {/*<Link to={report.href}>*/}
            {/*    <div className="d-flex justify-content-between align-items-center gap-5">*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.name}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.type}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.createdBy}*/}
            {/*      </h4>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*  </ListGroup.Item>*/}
            {/*))}*/}
            <ListGroup.Item id="projects-and-timesheet">
              <h4 className="sub-heading my-1">Projects and Timesheet</h4>
            </ListGroup.Item>
            {reports.projectsAndTimesheet.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            <ListGroup.Item id="accountant">
              <h4 className="sub-heading my-1">Accountant</h4>
            </ListGroup.Item>
            {reports.accountant.map((report, index) => (
              <ListGroup.Item key={index}>
                <Link to={report.href}>
                  <div className="d-flex justify-content-between align-items-center gap-5">
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.name}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.type}
                    </h4>
                    <h4
                      className="sub-heading my-1 textBlack normal-font-weight"
                      style={{ fontSize: "18px" }}
                    >
                      {report.createdBy}
                    </h4>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
            {/*<ListGroup.Item id="budgets">*/}
            {/*  <h4 className="sub-heading my-1">Budgets</h4>*/}
            {/*</ListGroup.Item>*/}
            {/*{reports.budgets.map((report, index) => (*/}
            {/*  <ListGroup.Item key={index}>*/}
            {/*<Link to={report.href}>*/}
            {/*    <div className="d-flex justify-content-between align-items-center gap-5">*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.name}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.type}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.createdBy}*/}
            {/*      </h4>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*  </ListGroup.Item>*/}
            {/*))}*/}
            {/*<ListGroup.Item id="currency">*/}
            {/*  <h4 className="sub-heading my-1">Currency</h4>*/}
            {/*</ListGroup.Item>*/}
            {/*{reports.currency.map((report, index) => (*/}
            {/*  <ListGroup.Item key={index}>*/}
            {/*<Link to={report.href}>*/}
            {/*    <div className="d-flex justify-content-between align-items-center gap-5">*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.name}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.type}*/}
            {/*      </h4>*/}
            {/*      <h4*/}
            {/*        className="sub-heading my-1 textBlack normal-font-weight"*/}
            {/*        style={{ fontSize: "18px" }}*/}
            {/*      >*/}
            {/*        {report.createdBy}*/}
            {/*      </h4>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*  </ListGroup.Item>*/}
            {/*))}*/}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsContainer;
