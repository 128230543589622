import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common } from "../../assets/images";
import SelectSearch from "react-select-search";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setEditClientReviewLoading } from "../../store/slices/projectSlice";
import { setClientReviewChange } from "../../store/slices/commonSlice";
// API
import { useEditClientReviewMutation } from "../../services/Projects";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import DatePicker from "react-datepicker";

interface AddDebitCash {
  projectId: string;
  type: string;
  statement: string;
  date: string;
}

const EditClientReviews = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientReviewChange = useAppSelector(
    (state) => state.common.clientReviewChange,
  );
  const options = state.projectIds.map((project) => ({
    name: project.projectName, // Use the actual property name for the project name
    value: project.projectId,
  }));
  const [editClientReview] = useEditClientReviewMutation();
  const clientData = location.state.data;
  const initialValues: AddDebitCash = {
    projectId: clientData.projectId || "",
    type: clientData.reviewType || "",
    statement: clientData.statement || "",
    date: clientData.statementDate || "",
  };

  const handleSubmit = async (values: AddDebitCash) => {
    dispatch(setEditClientReviewLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateProject || false,
        payload: {
          reviewId: clientData._id || "",
          projectId: values.projectId,
          reviewType: values.type,
          statement: values.statement,
          statementDate: values.date,
          link: `/projects/client-reviews`,
        },
      };
      await editClientReview(data).unwrap();
      dispatch(setClientReviewChange(!clientReviewChange));
      navigate("/projects/client-reviews");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setEditClientReviewLoading(false));
    }
  };
  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Client Review</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Project ID
                      </h4>
                      <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                        <SelectSearch
                          name="projectId"
                          options={options}
                          value={values.projectId}
                          onChange={(value: any) =>
                            handleChange("projectId")(value)
                          }
                          search={true}
                          disabled={true}
                          placeholder="Select Project Id"
                        />
                        <img src={Common.ArrowDown} alt="message" />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Type:
                      </h4>
                      <Form.Select
                        name="type"
                        onChange={handleChange}
                        value={values.type}
                        className="custom-input border-fill"
                      >
                        <option value="">Select Type</option>
                        <option value="Inquiry">Inquiry</option>
                        <option value="Complain">Complain</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Deployment">Deployment</option>
                        <option value="Increase">Increase</option>
                        <option value="Replacement">Replacement</option>
                        <option value="Agreement">Agreement</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Statement Date
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="date"
                          selected={values.date ? new Date(values.date) : null}
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "date", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          placeholderText="Select statement date"
                          className="w-100 custom-input"
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Review
                      </h4>
                      <textarea
                        name="statement"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.statement}
                        placeholder="Add client review here..."
                        className={
                          touched.statement && errors.statement
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.statement && errors.statement ? (
                        <div className="invalid-feedback">
                          {errors.statement}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {state.editClientReviewLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Edit Review"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditClientReviews;
