import { createSlice } from "@reduxjs/toolkit";
import { Notification, Approval } from "../../interfaces/notifications";

interface Index {
  notificationsLoading: boolean;
  notificationError: string;
  activeTab: string;
  tabList: string[];
  showNotification: boolean;
  notifications: Notification[];
  approvals: Approval[];
  readLoading: boolean;
  approvalsLoading: boolean;
  approvalsError: string;
  handleApproveLoading: boolean;
  approvalPageSize: number;
  notificationPageSize: number;
  totalApprovals: number;
  totalNotifications: number;
}

const initialState: Index = {
  notificationsLoading: false,
  notificationError: "",
  notifications: [],
  readLoading: false,
  activeTab: "Notifications",
  tabList: ["Notifications", "Approval Requests"],
  showNotification: true,
  approvals: [],
  approvalsLoading: false,
  approvalsError: "",
  handleApproveLoading: false,
  approvalPageSize: 10,
  notificationPageSize: 10,
  totalApprovals: 0,
  totalNotifications: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setNotificationsLoading: (state, action) => {
      state.notificationsLoading = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setNotificationError: (state, action) => {
      state.notificationError = action.payload;
    },
    setShowNotification: (state, action) => {
      state.showNotification = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setReadLoading: (state, action) => {
      state.readLoading = action.payload;
    },
    setApprovals: (state, action) => {
      state.approvals = action.payload;
    },
    setApprovalsLoading: (state, action) => {
      state.approvalsLoading = action.payload;
    },
    setApprovalsError: (state, action) => {
      state.approvalsError = action.payload;
    },
    setHandleApproveLoading: (state, action) => {
      state.handleApproveLoading = action.payload;
    },
    setApprovalPageSize: (state, action) => {
      state.approvalPageSize = action.payload;
    },
    setNotificationPageSize: (state, action) => {
      state.notificationPageSize = action.payload;
    },
    setTotalApprovals: (state, action) => {
      state.totalApprovals = action.payload;
    },
    setTotalNotifications: (state, action) => {
      state.totalNotifications = action.payload;
    },
  },
});

export const {
  setNotificationsLoading,
  setApprovalsLoading,
  setHandleApproveLoading,
  setNotifications,
  setApprovalsError,
  setReadLoading,
  setApprovals,
  setNotificationError,
  setShowNotification,
  setActiveTab,
  setNotificationPageSize,
  setApprovalPageSize,
  setTotalApprovals,
  setTotalNotifications,
} = notificationSlice.actions;
export default notificationSlice.reducer;
