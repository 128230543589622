import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { Project, ClientReview, ProjectId } from "../../interfaces/project";
import { IdolEmployee, AllEmployees } from "../../interfaces/employee";
import { Timesheet } from "../../interfaces/timesheet";

interface ProjectsList {
  data: {
    projects: {
      data: Project[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface ClientReviews {
  data: {
    reviews: {
      data: ClientReview[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface IdolEmployees {
  data: {
    idol: AllEmployees[];
  };
}

interface TimeSheetData {
  data: {
    salarySheetData: {
      data: Timesheet[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface ProjectIds {
  data: {
    projectIds: ProjectId[];
  };
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const projectsAPI = createApi({
  reducerPath: "/api/projects",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getProjects: builder.query<
      ProjectsList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-projects/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getProjectsIds: builder.query<
      ProjectIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-project-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getIdolEmployees: builder.query<
      IdolEmployees,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-idol-employees/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getProjectedTime: builder.query<
      TimeSheetData,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        projectId: string[];
        page: number;
        limit: number;
        date: string;
      }
    >({
      query: (data) => ({
        url: `/projected-time-sheets/${data.page}/${data.limit}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "x-date": `${formatedDate(data.date)}`,
          "search-id": `${data.projectId}`,
        },
      }),
    }),
    addProject: builder.mutation({
      query: (data) => ({
        url: `/add-project/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editProject: builder.mutation({
      query: (data) => ({
        url: `/edit-project/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteProject: builder.mutation({
      query: (data) => ({
        url: `/delete-project/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterProjects: builder.mutation<
      ProjectsList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-projects/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    // Client Reviews
    getClientReviews: builder.query<
      ClientReviews,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/client-reviews-list/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addClientReview: builder.mutation({
      query: (data) => ({
        url: `/add-client-review/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editClientReview: builder.mutation({
      query: (data) => ({
        url: `/edit-client-review/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteClientReview: builder.mutation({
      query: (data) => ({
        url: `/delete-client-review/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterClientReviews: builder.mutation<
      ClientReviews,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/filtered-client-reviews-list/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editActualSheet: builder.mutation({
      query: (data) => ({
        url: `/edit-actual-attandence/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    postActualSheet: builder.mutation({
      query: (data) => ({
        url: `/post-salaries/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useEditProjectMutation,
  useAddClientReviewMutation,
  useEditClientReviewMutation,
  useDeleteClientReviewMutation,
  useGetClientReviewsQuery,
  useGetFilterProjectsMutation,
  useGetIdolEmployeesQuery,
  useGetProjectedTimeQuery,
  useGetProjectsIdsQuery,
  useGetFilterClientReviewsMutation,
  useEditActualSheetMutation,
  usePostActualSheetMutation,
} = projectsAPI;
