import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Letters
import {
  useGetNewJoinersListQuery,
  useGetTransfersEmployeeListQuery,
} from "../../services/Letters";
import {
  setTransferEmployeeLoading,
  setTransferEmployeeError,
  setTransferEmployee,
  setTransferEmployeeTotalPages,
  setNewJoinersLoading,
  setNewJoinersError,
  setNewJoiners,
  setNewJoinerTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const transferEmployeeChange = useAppSelector(
    (state) => state.common.transferEmployeeChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Transfer Employee Requests
  // **************
  // **************
  const {
    data: allTransferEmployee,
    isLoading: transferLoading,
    isError: transferError,
    refetch: transferRefetch,
  } = useGetTransfersEmployeeListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.transferEmployeePageNo,
    limit: state.transferEmployeePageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (transferLoading) {
      dispatch(setTransferEmployeeLoading(true));
    }
    if (transferError) {
      dispatch(setTransferEmployeeError("Something went wrong"));
      dispatch(setTransferEmployeeLoading(false));
    }
    if (allTransferEmployee) {
      dispatch(
        setTransferEmployee(allTransferEmployee.data.transfers[0].data || []),
      );
      dispatch(
        setTransferEmployeeTotalPages(
          allTransferEmployee.data.transfers[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setTransferEmployeeLoading(false));
    }
  }, [
    accessToken,
    userID,
    allTransferEmployee,
    profileData,
    state.transferEmployeePageNo,
    state.transferEmployeePageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    transferRefetch();
  }, [transferEmployeeChange]);
  // **************
  // **************
  // Get All New joiners Requests
  // **************
  // **************
  const {
    data: allNewJoiners,
    isLoading: newJoinersLoading,
    isError: newJoinersError,
    refetch: newJoinersRefetch,
  } = useGetNewJoinersListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.newJoinerPageNo,
    limit: state.newJoinerPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (newJoinersLoading) {
      dispatch(setNewJoinersLoading(true));
    }
    if (newJoinersError) {
      dispatch(setNewJoinersError("Something went wrong"));
      dispatch(setNewJoinersLoading(false));
    }
    if (allNewJoiners) {
      dispatch(setNewJoiners(allNewJoiners.data.joiners[0].data || []));
      dispatch(
        setNewJoinerTotalPages(
          allNewJoiners.data.joiners[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setNewJoinersLoading(false));
    }
  }, [
    accessToken,
    userID,
    allNewJoiners,
    profileData,
    state.newJoinerPageNo,
    state.newJoinerPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    newJoinersRefetch();
  }, [transferEmployeeChange]);
  return <></>;
};
export default Content;
