import React from "react";
import InternalLayout from "../components/InternalLayout";
import OwnerComponent from "../components/Owners";
import Content from "../components/Clients/get-api-call";
import withAuth from "../config/private-route";

const Owners = () => {
  return (
    <InternalLayout>
      <Content />
      <OwnerComponent />
    </InternalLayout>
  );
};
export default withAuth(Owners);
