import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const SettingComponent = () => {
  return (
    <div className="settings section mt-4 px-4 py-4">
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
};
export default SettingComponent;
