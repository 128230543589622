import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { ClientId, Invoice } from "../../interfaces/project";
import { Common } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";
import Invoices from "../Letters/invoice";
import { SponsorId } from "../../interfaces/sponsor";

const ViewInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };

  const invoiceData: Invoice = location.state.data;
  const [hideLetterHead, setHideLetterHead] = useState(false);
  const companies = useAppSelector((state) => state.sponsor.sponsorIds);
  const company = companies.find(
    (company) => company.companyId === invoiceData.companyId,
  );
  const clients = useAppSelector((state) => state.clients.clientIds);
  const client = clients.find(
    (client) => client.clientId === invoiceData.clientId,
  );

  const dummyClient: ClientId = {
    _id: "",
    clientId: "",
    clientName: "",
    companyName: "",
    contactPersonName: "",
    contactPersonDesignation: "",
    phone: "",
    clientMOL: "",
    clientAddress1: "",
    clientAddressArabic: "",
    numberVAT: 0,
    clientNameArabic: "",
  };

  const dummyCompany: SponsorId = {
    _id: "",
    companyId: "",
    companyName: "",
    companyNameArabic: "",
    companyAddress: "",
    companyAddressArabic: "",
    numberVAT: 0,
    bankDetails: [],
    unifiedNumber: 0,
  };

  const creditNoteData = {
    creditNoteNumber: invoiceData.creditNoteNumber,
    creditNoteDate: invoiceData.creditNoteDate,
    transactionType: invoiceData.transactionType,
    totalAmountToBeReceived: invoiceData.totalAmountToBeReceived,
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <Invoices
            client={client ? client : dummyClient}
            company={company ? company : dummyCompany}
            bankDetails={invoiceData.bankDetails}
            subject={invoiceData.subject}
            type={invoiceData.invoiceType}
            invoiceDate={invoiceData.invoiceDate}
            creditNoteData={creditNoteData}
            tax={invoiceData.taxApplied.toString()}
            removeLetterHead={hideLetterHead}
            invoiceNumber={invoiceData.invoiceId}
            categories={invoiceData.invoiceItem}
          />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                  {invoiceData.invoiceType === "Invoice"
                    ? "Invoice"
                    : "Credit Note"}{" "}
                  Detail
                </h4>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Form.Check
                  type="checkbox"
                  id="hideLetterHeadCheckbox" // Add an id to target the checkbox
                  label="Hide Senyar Letter Head"
                  className="hide-letter-head"
                  name="hideLetterHead"
                  checked={hideLetterHead}
                  onChange={(e) => {
                    setHideLetterHead(e.target.checked);
                  }}
                />
                <button
                  className="btn primary-button"
                  onClick={handlePrint}
                  type="button"
                >
                  Print
                </button>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              {invoiceData.invoiceType === "Credit Note" && (
                <>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Credit Note Id
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.creditNoteNumber}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Credit Note Date
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.creditNoteDate
                          ? new Date(
                              invoiceData.creditNoteDate,
                            ).toLocaleDateString()
                          : ""}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Transaction Type
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.transactionType}
                      </h5>
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Invoice Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.invoiceId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Invoice Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.invoiceDate
                      ? new Date(invoiceData.invoiceDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Subject
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.subject}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Tax Applied
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.taxApplied}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Amount (Excluding VAT)
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.totalAmount}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total VAT
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.totalVAT}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Amount (Including VAT)
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.totalBalance}
                  </h5>
                </Form.Group>
              </Col>
              {invoiceData.invoiceType === "Invoice" && (
                <>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Amount Received
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.totalBalance - invoiceData.remainingAmount}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Amount Remaining
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.remainingAmount}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Status
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.status}
                      </h5>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col sm="6">
            <Row>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <h1 className="sub-heading mb-0">Client Detail</h1>
                </div>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Client Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.clientId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Client Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {client?.clientName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Address
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {client?.clientAddress1 +
                      " - " +
                      client?.clientAddressArabic}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <h1 className="sub-heading mb-0">Company Detail</h1>
                </div>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Company Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {invoiceData.companyId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Company Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {company?.companyName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Address
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {company?.companyAddress +
                      " - " +
                      company?.companyAddressArabic}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          {invoiceData.invoiceType === "Invoice" && (
            <>
              <Col sm="12">
                <div className="section-heading mb-4">
                  <h1 className="sub-heading mb-0">Bank Info</h1>
                </div>
              </Col>
              <Col sm="12">
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Bank Name
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.bankDetails.bankName}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        IBAN
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.bankDetails.iban}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Account Number
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.bankDetails.accountNumber}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Swift Code
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {invoiceData.bankDetails.swiftCode}
                      </h5>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </>
          )}
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="sub-heading mb-0">Line Items</h1>
            </div>
          </Col>
          <Col sm="12">
            {invoiceData.invoiceItem.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>S.</th>
                      <th>Category</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Discount</th>
                      <th>Tax</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.invoiceItem.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.category}</td>
                        <td>{item.categoryPrice}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {item.discount}{" "}
                          {item.discountType === "percent" ? "%" : "SAR"}
                        </td>
                        <td>{invoiceData.taxApplied} %</td>
                        <td>{item.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No Line items found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewInvoice;
