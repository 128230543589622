import React from "react";
import InternalLayout from "../components/InternalLayout";
import PettyCashComponent from "../components/PettyCash";
import withAuth from "../config/private-route";
import Content from "../components/PettyCash/get-ledger-api";

const PettyCash = () => {
  return (
    <InternalLayout>
      <Content />
      <PettyCashComponent />
    </InternalLayout>
  );
};
export default withAuth(PettyCash);
