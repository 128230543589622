import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setManualJournalChange } from "../../store/slices/commonSlice";
import { setAddManualJournalLoading } from "../../store/slices/manualJournalSlice";
// API Imports
import { useEditManualJournalMutation } from "../../services/ManualJournal";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import { useNavigate, useLocation } from "react-router-dom";
import AttachmentSet from "../Employees/AddEmployee/attachments";
import JournalSet from "./journalSet";
import { ManualJournal } from "../../interfaces/account-charts";

interface AddManualJournal {
  journalDate: string;
  journalDetail: string;
  reference: string;
}

const EditJournal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const journalData: ManualJournal = location.state.data;
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const manualJournalChange = useAppSelector(
    (state) => state.common.manualJournalChange,
  );
  const manualJournalState = useAppSelector((state) => state.manualJournal);
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");

  const initialValues: AddManualJournal = {
    journalDate: journalData.journalDate || "",
    journalDetail: journalData.journalDetail || "",
    reference: journalData.reference || "",
  };

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >(journalData.attachments || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      account: string;
      debit: number;
      credit: number;
      description: string;
      contact: string;
    }[]
  >(
    journalData.accounts || [
      {
        uId: Math.random().toString(36).substring(7),
        account: "",
        debit: 0,
        credit: 0,
        description: "",
        contact: "Debit",
      },
      {
        uId: Math.random().toString(36).substring(7),
        account: "",
        debit: 0,
        credit: 0,
        description: "",
        contact: "Credit",
      },
    ],
  );

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        account: "",
        debit: 0,
        credit: 0,
        description: "",
        contact: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      account: string;
      debit: number;
      credit: number;
      description: string;
      contact: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <JournalSet
        key={data.uId}
        data={data}
        setLength={experienceData.length}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  const [editManualJournal] = useEditManualJournalMutation();

  const handleSubmit = async (values: AddManualJournal) => {
    dispatch(setAddManualJournalLoading(true));
    const date = values.journalDate
      ? new Date(values.journalDate).toLocaleDateString()
      : "";
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addPettyCash || false,
        payload: {
          journalId: journalData.journalId,
          journalDate: date,
          journalDetail: values.journalDetail,
          reference: values.reference,
          accounts: experienceData,
          attachments: languageData,
          link: "/manual-journal",
        },
      };
      await editManualJournal(data).unwrap();
      dispatch(setManualJournalChange(!manualJournalChange));
      navigate("/manual-journal");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddManualJournalLoading(false));
    }
  };

  const validationSchema = Yup.object().shape({
    journalDate: Yup.string().required("Date is required"),
  });

  const totalDebit = experienceData.reduce((acc, item) => acc + item.debit, 0);

  const totalCredit = experienceData.reduce(
    (acc, item) => acc + item.credit,
    0,
  );

  const debitDifference = totalCredit - totalDebit;

  const creditDifference = totalDebit - totalCredit;

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-5">
            <h1 className="heading mb-0">Edit Journal</h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Date *
                      </h4>
                      <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                        <DatePicker
                          name="journalDate"
                          selected={
                            values.journalDate
                              ? new Date(values.journalDate)
                              : null
                          }
                          onChange={(date: any) =>
                            handleChange({
                              target: { name: "journalDate", value: date },
                            })
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          maxDate={new Date()}
                          placeholderText="Date"
                          className={
                            errors.journalDate && touched.journalDate
                              ? "is-invalid w-100 custom-input"
                              : "w-100 custom-input"
                          }
                          dateFormat="yyyy-MM-d"
                        />
                        <img
                          src={Common.Calendar}
                          className="calendar-date-recur"
                          alt="calendarblack"
                        />
                      </Form.Group>
                      {touched.journalDate && errors.journalDate ? (
                        <div className="invalid-feedback">
                          {errors.journalDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Reference #
                      </h4>
                      <Form.Control
                        type="text"
                        name="reference"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                        placeholder="Reference number"
                        className={
                          touched.reference && errors.reference
                            ? "is-invalid custom-input border-fill"
                            : "custom-input border-fill"
                        }
                      />
                      {touched.reference && errors.reference ? (
                        <div className="invalid-feedback">
                          {errors.reference}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="8">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Note *
                      </h4>
                      <textarea
                        name="journalDetail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.journalDetail}
                        placeholder="Leave a comment here..."
                        required={true}
                        className={
                          touched.journalDetail && errors.journalDetail
                            ? "is-invalid border-fill w-100"
                            : "border-fill w-100"
                        }
                        rows={6}
                        style={{ resize: "none" }}
                      />
                      {touched.journalDetail && errors.journalDetail ? (
                        <div className="invalid-feedback">
                          {errors.journalDetail}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    {experienceSets.length > 0 && (
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Accounts
                      </h4>
                    )}
                    <div className="extra-detail my-4">{experienceSets}</div>
                  </Col>
                  <Col sm="8">
                    <div
                      className="sub-total p-4"
                      style={{ background: "#f9f9fb", borderRadius: "12px" }}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ width: "300px", marginLeft: "auto" }}
                      >
                        <h4 className="sub-heading normal-font-weight">
                          Debit
                        </h4>
                        <h4 className="sub-heading normal-font-weight">
                          Credit
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4 className="sub-heading normal-font-weight">
                          Total:
                        </h4>
                        <div
                          className="d-flex justify-content-between"
                          style={{ width: "300px" }}
                        >
                          <h4 className="sub-heading normal-font-weight">
                            {totalDebit}
                          </h4>
                          <h4 className="sub-heading normal-font-weight">
                            {totalCredit}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          className="sub-heading normal-font-weight"
                          style={{ color: "#ff2b43" }}
                        >
                          Difference:
                        </h4>
                        <div
                          className="d-flex justify-content-between"
                          style={{ width: "300px" }}
                        >
                          <h4
                            className="sub-heading normal-font-weight"
                            style={{ color: "#ff2b43" }}
                          >
                            {debitDifference > 0 ? debitDifference : ""}
                          </h4>
                          <h4
                            className="sub-heading normal-font-weight"
                            style={{ color: "#ff2b43" }}
                          >
                            {creditDifference > 0 ? creditDifference : ""}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="text-end mt-4 d-flex align-items-center gap-4 mb-4">
                      <button
                        className="btn primary-button normal-font-weight"
                        type="button"
                        onClick={handleAddExperienceSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Row
                      </button>
                      <button
                        className="btn primary-button normal-font-weight"
                        type="button"
                        onClick={handleAddLanguageSet}
                        style={{ width: "15%" }}
                      >
                        <img
                          src={Common.Plus}
                          width="20px"
                          height="20px"
                          className="me-2"
                          alt="next"
                        />{" "}
                        Attachments
                      </button>
                    </div>
                  </Col>
                  <Col sm="12">
                    {languageSets.length > 0 && (
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Attachments
                      </h4>
                    )}
                    <div className="extra-detail my-4">{languageSets}</div>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn primary-button w-25">
                        {manualJournalState.addManualJournalLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          "Edit Journal"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditJournal;
