import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters

import { useGetAllProbationEmployeesQuery } from "../../../services/Employees";
import {
  setProbationEmployees,
  setProbationError,
  setProbationLoading,
  setProbationTotalRecords,
} from "../../../store/slices/employeeSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const probationChange = useAppSelector(
    (state) => state.common.probationChange,
  );
  // States
  const employeeState = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Probation Employees
  // **************
  // **************
  const {
    data: allProbationEmployees,
    isLoading: probationLoading,
    isError: probationError,
    refetch: probationRefetch,
  } = useGetAllProbationEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    pageNo: employeeState.probationPageNo,
    pageSize: employeeState.probationPageSize,
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (probationLoading) {
      dispatch(setProbationLoading(true));
    }
    if (probationError) {
      dispatch(setProbationError("Something went wrong"));
      dispatch(setProbationLoading(false));
    }
    if (allProbationEmployees) {
      dispatch(
        setProbationEmployees(
          allProbationEmployees.data.probation[0].data || [],
        ),
      );
      dispatch(
        setProbationTotalRecords(
          allProbationEmployees.data.probation[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setProbationLoading(false));
    }
  }, [
    accessToken,
    userID,
    allProbationEmployees,
    profileData,
    employeeState.probationPageNo,
    employeeState.probationPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    probationRefetch();
  }, [probationChange]);

  return <></>;
};
export default Content;
