import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";

export const authenticationAPI = createApi({
  reducerPath: "/api/authentication",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    login: builder.mutation({
      query: (newLogin) => ({
        url: "/login",
        method: "POST",
        body: newLogin,
      }),
    }),
    completeProfile: builder.mutation({
      query: (newProfile) => ({
        url: "/complete-profile",
        method: "POST",
        body: newProfile,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/send-forgot-otp",
        method: "POST",
        body: email,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (otp) => ({
        url: "/verify-forgot-otp",
        method: "POST",
        body: otp,
      }),
    }),
    resetPassword: builder.mutation({
      query: (newPassword) => ({
        url: "/add-new-password",
        method: "POST",
        body: newPassword,
      }),
    }),
    logout: builder.mutation({
      query: (authorization) => ({
        url: "/logout",
        method: "POST",
        headers: {
          Authorization: `${authorization}`,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCompleteProfileMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useLogoutMutation,
} = authenticationAPI;
