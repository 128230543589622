import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetSponsorListQuery } from "../../services/Sponsor";
import {
  setSponsorList,
  setSponsorListLoading,
  setSponsorListError,
  setSponsorListTotalPages,
} from "../../store/slices/sponsorSlices";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const sponsorChange = useAppSelector((state) => state.common.sponsorChange);
  // States
  const state = useAppSelector((state) => state.sponsor);

  // **************
  // **************
  // Get All Sponsors
  // **************
  // **************
  const {
    data: allSponsorss,
    isLoading: sponsorListLoading,
    isError: sponsorListError,
    refetch: sponsorListRefetch,
  } = useGetSponsorListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    pageNo: state.sponsorListPageNo,
    pageSize: state.sponsorListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (sponsorListLoading) {
      dispatch(setSponsorListLoading(true));
    }
    if (sponsorListError) {
      dispatch(setSponsorListError("Something went wrong"));
      dispatch(setSponsorListLoading(false));
    }
    if (allSponsorss) {
      dispatch(setSponsorList(allSponsorss.data.companys[0].data || []));
      dispatch(
        setSponsorListTotalPages(
          allSponsorss.data.companys[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setSponsorListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allSponsorss,
    profileData,
    state.sponsorListPageNo,
    state.sponsorListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    sponsorListRefetch();
  }, [sponsorChange]);
  return <></>;
};
export default Content;
