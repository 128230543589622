import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";

interface AllowanceProps {
  data: {
    instruction: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: { instruction: string }) => void;
  uniqueId: string;
}

const OfferLetterInstructions: React.FC<AllowanceProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [instruction, setInstruction] = useState(data.instruction);

  const handleInputChange = () => {
    onInputChange({
      instruction,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [instruction]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Instruction
                </h4>
                <Form.Control
                  type="text"
                  name="allowanceTitle"
                  placeholder="Enter instruction"
                  onChange={(e) => setInstruction(e.target.value)}
                  value={instruction}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OfferLetterInstructions;
