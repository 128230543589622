import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Camps
import { useGetStatementsQuery } from "../../../services/Employees";
import {
  setStatementIdsError,
  setStatementIdsLoading,
  setStatementIds,
} from "../../../store/slices/employeeSlice";

interface Props {
  empId: number;
}

const Content = ({ empId }: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  // States
  const state = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allStatementIds,
    isLoading: statementIdsLoading,
    isError: statementIdsError,
    refetch: statementIdsRefetch,
  } = useGetStatementsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    empID: empId.toString(),
    fromDate: new Date(state.statementFrom).toLocaleDateString(),
    toDate: new Date(state.statementTo).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (statementIdsLoading) {
      dispatch(setStatementIdsLoading(true));
    }
    if (statementIdsError) {
      dispatch(setStatementIdsError("Something went wrong"));
      dispatch(setStatementIdsLoading(false));
    }
    if (allStatementIds) {
      dispatch(setStatementIds(allStatementIds.data.statement || []));
      dispatch(setStatementIdsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allStatementIds,
    profileData,
    empId,
    state.statementFrom,
    state.statementTo,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    statementIdsRefetch();
  }, [employeeChange, empId, state.statementFrom, state.statementTo]);
  return <></>;
};
export default Content;
