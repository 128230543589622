import React from "react";
import TabComponent from "../TabComponent";
import TransferRequests from "./transfer-requests";
import NewJoinersRequests from "./new-joiners-request";
// Redux Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  setActiveTab,
  setShowNewJoiners,
  setShowTransferRequests,
} from "../../store/slices/lettersSlice";

const TransferRequestsContainer = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const handleTabChange = (tabName: string) => {
    dispatch(setActiveTab(tabName));
    if (tabName === "Transfer Employees Requests") {
      dispatch(setShowTransferRequests(true));
      dispatch(setShowNewJoiners(false));
    }
    if (tabName === "New Joiners Requests") {
      dispatch(setShowTransferRequests(false));
      dispatch(setShowNewJoiners(true));
    }
  };
  return (
    <>
      <TabComponent
        tabList={state.tabList}
        activeTab={state.activeTab}
        sectionName="Transfer / New Joiners Requests"
        handleTabChange={handleTabChange}
      />
      {state.showTransferRequests && <TransferRequests />}
      {state.showNewJoiners && <NewJoinersRequests />}
    </>
  );
};

export default TransferRequestsContainer;
