import PapaParse from "papaparse";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const baseURL = "https://api.senyarsa.com/api/v1";

export const exportToCsv = (csvData: any, filename: string) => {
  // Extracting column headings from the first row of csvData
  const columns = Object.keys(csvData[0]);

  // Creating an array to store the CSV rows
  const csvRows = [];

  // Adding the heading row to the CSV
  csvRows.push(columns);

  // Adding specified data rows to the CSV
  csvData.forEach((rowData: any) => {
    const row = columns.map((column) => rowData[column]);
    csvRows.push(row);
  });

  // Converting the array of rows to a CSV string using PapaParse
  const csvString = PapaParse.unparse(csvRows);

  // Creating a download link and triggering the download
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`,
  );
  link.setAttribute("download", `${filename}.csv`);
  link.click();
};

export const exportToPDF = async (
  tableData: any[],
  filename: string,
  title: string,
) => {
  const pdf = new jsPDF({
    orientation: "landscape",
  });

  // Add heading below the logo
  // Add heading centered below the logo
  const heading = title;
  const headingWidth = pdf.getTextWidth(heading);
  const centerHeadingX = (pdf.internal.pageSize.width - headingWidth) / 2;

  pdf.text(heading, centerHeadingX, 55); // Adjust the Y coordinate based on your logo size and spacing

  // Convert the table data to an array of arrays
  const rows = tableData.map((item) => Object.values(item));

  // Apply autoTable with custom styles and table data
  autoTable(pdf, {
    head: [Object.keys(tableData[0])],
    body: rows,
    startY: 60,
  }); // Adjust the startY coordinate based on your heading size and spacing

  pdf.save(`${filename}.pdf`);
};
