import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowEmployeePayrollExportDropdown,
  setEmployeePayrollListPageSize,
  setEmployeePayrollListPageNo,
  setEmpIds,
  setFromDate,
  setToDate,
} from "../../store/slices/employeePayrollSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { EmployeePayroll } from "../../interfaces/employee";
import { exportToCsv } from "../../config";
import { useReactToPrint } from "react-to-print";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import Content from "./get-payroll-api-call";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import ConfirmCreatePayslipsModal from "../Modals/create-payslips-modal";
import { tr } from "date-fns/locale";

const returnLastDateOfMonth = (fromDate: string) => {
  const date = new Date(fromDate);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const CreatePayroll = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.employeePayroll);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const payrollChange = useAppSelector((state) => state.common.payrollChange);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [successContent, setSuccessContent] = useState("");
  const [payrollDate, setPayrollDate] = useState("");
  const [showCreatePayrollModal, setShowCreatePayrollModal] = useState(false);
  const [checked, setChecked] = useState<{
    [key: number]: boolean;
  }>({});
  // Options
  const options = employees.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId.toString(),
  }));
  const projectOptions = projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  // Add custom options for "management" and "idol employee"
  projectOptions.splice(0, 0, { name: "All", value: "All" });
  projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<EmployeePayroll[]>([]);
  const totalPages = Math.ceil(
    state.employeePayrollListTotalPages / state.employeePayrollListPageSize,
  );
  const totalCount = state.employeePayrollListTotalPages;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.employeePayrollListPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);

  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.employeePayrollListPageNo > totalPages - showPages) {
      return;
    }
    dispatch(
      setEmployeePayrollListPageNo(state.employeePayrollListPageNo + showPages),
    );
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.employeePayrollListPageNo <= 5) {
      return;
    }
    dispatch(
      setEmployeePayrollListPageNo(state.employeePayrollListPageNo - showPages),
    );
  };

  const handlePageChange = (page: any) => {
    dispatch(setEmployeePayrollListPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number

    dispatch(setEmployeePayrollListPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.employeePayrollList);
  }, [state.employeePayrollList]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Id.",
      "Emp Name",
      "Nationality",
      "Addition (SAR)",
      "Gross Salary (SAR)",
      "Deduction (SAR)",
      "Net Salary (SAR)",
      "Actions",
    ],
    tableData: filteredData,
  };

  const getCollectedValue = (type: string, item: EmployeePayroll) => {
    // Earnings
    const allowancesArray = item.salaryTimeline.map((allowance) => {
      return {
        title: allowance.title,
        description: "",
        hours: 0,
        rate: 0,
        amount: allowance.amount,
      };
    });
    const basicSalaryDataArray = item.salaryData.map((salary) => {
      return {
        title: "Basic Salary",
        description: "",
        hours: salary.workedHours,
        rate: salary.perHourCost,
        amount: salary.totalSalary,
      };
    });
    const overtimeDataArray = item.salaryData.map((overtime) => {
      return {
        title: "Overtime",
        description: "",
        hours: overtime.overtimeHours,
        rate: overtime.perHourCost,
        amount: overtime.totalOvertimeAmount,
      };
    });
    const expenseAdditionDataArray = item.matchingExpenses
      .filter((item) => item.expenseCategory === "Addition")
      .map((expense) => {
        const finalObject =
          expense.installments.length > 0
            ? {
                title: expense.expenseType,
                description: expense.expenseDetail,
                hours: 0,
                rate: 0,
                amount: expense.installments.reduce(
                  (acc, item) => acc + item.amount,
                  0,
                ),
              }
            : {
                title: expense.expenseType,
                description: expense.expenseDetail,
                hours: 0,
                rate: 0,
                amount: expense.expenseAmount,
              };
        return finalObject;
      });

    const earnings = [
      ...basicSalaryDataArray,
      ...overtimeDataArray,
      ...allowancesArray,
      ...expenseAdditionDataArray,
    ];

    // Deductions

    const expenseDeductionDataArray = item.matchingExpenses
      .filter((item) => item.expenseCategory === "Deduction")
      .map((expense) => {
        const finalObject =
          expense.installments.length > 0
            ? {
                title: expense.expenseType,
                description: expense.expenseDetail,
                amount: expense.installments.reduce(
                  (acc, item) => acc + item.amount,
                  0,
                ),
              }
            : {
                title: expense.expenseType,
                description: expense.expenseDetail,
                amount: expense.expenseAmount,
              };
        return finalObject;
      });

    const grossSalary = earnings.reduce((acc, item) => acc + item.amount, 0);

    const totalDeductions = expenseDeductionDataArray.reduce(
      (acc, item) => acc + item.amount,
      0,
    );

    const netSalary = grossSalary - totalDeductions;

    const basicSalary = basicSalaryDataArray.reduce(
      (acc, item) => acc + item.amount,
      0,
    );

    const overtimeSalary = overtimeDataArray.reduce(
      (acc, item) => acc + item.amount,
      0,
    );

    const workingSalary = basicSalary + overtimeSalary;

    const additions = [...allowancesArray, ...expenseAdditionDataArray].reduce(
      (acc, item) => acc + item.amount,
      0,
    );

    return type === "gross"
      ? grossSalary
      : type === "deduction"
        ? totalDeductions
        : type === "net"
          ? netSalary
          : type === "addition"
            ? additions
            : workingSalary;
  };

  const csvData = tableData.tableData.map((item, index) => {
    return {
      "Sr. No.": index + 1,
      "Emp Id.": item.empId,
      "Emp Name": item.empName,
      Nationality: item.empNationality,
      "Addition (SAR)": getCollectedValue("addition", item),
      "Gross Salary (SAR)": getCollectedValue("gross", item),
      "Deduction (SAR)": getCollectedValue("deduction", item),
      "Net Salary (SAR)": getCollectedValue("net", item),
    };
  });

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const handleSelectAll = () => {
    const allEmpIds = tableData.tableData.map((item) => item.empId);
    const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);
    if (allEmpIdsChecked) {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = false;
      });
      setChecked(newChecked);
    } else {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = true;
      });
      setChecked(newChecked);
    }
  };

  const handleSingleSelect = (empId: number) => {
    setChecked({
      ...checked,
      [empId]: !checked[empId],
    });
  };

  const handleProjectId = (value: any) => {
    const oldArray =
      state.empIds[0] === "All"
        ? []
        : state.empIds[0] === "Management"
          ? []
          : !state.empIds[0].includes("PR")
            ? []
            : state.empIds;
    if (value === "All") {
      dispatch(setEmpIds([value]));
      return;
    }
    if (value === "Management") {
      dispatch(setEmpIds([value]));
      return;
    }
    if (state.empIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Project Id already added");
      setShow(true);
      return;
    }
    dispatch(setEmpIds([...oldArray, value]));
  };

  const handleEmployeeId = (value: any) => {
    const oldArray = state.empIds[0]?.includes("All")
      ? []
      : state.empIds[0]?.includes("Management")
        ? []
        : state.empIds[0]?.includes("PR")
          ? []
          : state.empIds;
    if (state.empIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Employee already added");
      setShow(true);
      return;
    }
    dispatch(setEmpIds([...oldArray, value]));
  };

  const allEmpIds = tableData.tableData.map((item) => item.empId);

  const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);

  const payload = tableData.tableData
    .filter((item) => checked[item.empId])
    .map((item) => {
      // Earnings
      const allowancesArray = item.salaryTimeline.map((allowance) => {
        return {
          title: allowance.title,
          description: "Allowance",
          type: "allowance",
          hours: 0,
          rate: 0,
          amount: allowance.amount,
        };
      });
      const basicSalaryDataArray = item.salaryData.map((salary) => {
        return {
          title: "Basic Salary",
          description: "",
          type: "basic",
          hours: salary.workedHours,
          rate: salary.perHourCost,
          amount: salary.totalSalary,
        };
      });
      const overtimeDataArray = item.salaryData.map((overtime) => {
        return {
          title: "Overtime",
          description: "",
          type: "overtime",
          hours: overtime.overtimeHours,
          rate: overtime.perHourCost,
          amount: overtime.totalOvertimeAmount,
        };
      });
      const expenseAdditionDataArray = item.matchingExpenses
        .filter((item) => item.expenseCategory === "Addition")
        .map((expense) => {
          const finalObject =
            expense.installments.length > 0
              ? {
                  title: expense.expenseType,
                  description: expense.expenseDetail,
                  type: "expense",
                  hours: 0,
                  rate: 0,
                  amount: expense.installments.reduce(
                    (acc, item) => acc + item.amount,
                    0,
                  ),
                }
              : {
                  title: expense.expenseType,
                  description: expense.expenseDetail,
                  type: "expense",
                  hours: 0,
                  rate: 0,
                  amount: expense.expenseAmount,
                };
          return finalObject;
        });

      const earnings = [
        ...basicSalaryDataArray,
        ...overtimeDataArray,
        ...allowancesArray,
        ...expenseAdditionDataArray,
      ];

      // Deductions

      const expenseDeductionDataArray = item.matchingExpenses
        .filter((item) => item.expenseCategory === "Deduction")
        .map((expense) => {
          const finalObject =
            expense.installments.length > 0
              ? {
                  title: expense.expenseType,
                  description: expense.expenseDetail,
                  amount: expense.installments.reduce(
                    (acc, item) => acc + item.amount,
                    0,
                  ),
                }
              : {
                  title: expense.expenseType,
                  description: expense.expenseDetail,
                  amount: expense.expenseAmount,
                };
          return finalObject;
        });

      const grossSalary = earnings.reduce((acc, item) => acc + item.amount, 0);

      const totalDeductions = expenseDeductionDataArray.reduce(
        (acc, item) => acc + item.amount,
        0,
      );

      const netSalary = grossSalary - totalDeductions;

      const basicSalary = basicSalaryDataArray.reduce(
        (acc, item) => acc + item.amount,
        0,
      );

      const overtimeSalary = overtimeDataArray.reduce(
        (acc, item) => acc + item.amount,
        0,
      );

      const salaryIds = item.salaryData.map((salary) => salary._id);
      const installmentIds = item.matchingExpenses.map((expense) =>
        expense.installments.map((item) => item._id),
      );

      const expenseIds = item.matchingExpenses.map((expense) => expense._id);

      const mixedInstallmentIds = installmentIds.flat();
      const mixedExpenseIds = expenseIds.concat(mixedInstallmentIds);

      return {
        payrollId: "",
        payrollDate: new Date(payrollDate).toLocaleDateString(),
        projectId: item.salaryData[0].projectId || "",
        empId: item.empId,
        empName: item.empName,
        empPhone: item.empPhone,
        empNationality: item.empNationality,
        empIqamaNo: item.empIqamaNo,
        status: "Pending",
        grossSalary: grossSalary,
        totalDeductions: totalDeductions,
        netSalary: netSalary,
        workedSalary: basicSalary + overtimeSalary,
        earnings: earnings,
        deductions: expenseDeductionDataArray,
        salaryIds: salaryIds,
        expenseIds: mixedExpenseIds,
      };
    });

  useEffect(() => {
    if (state.fromDate) {
      dispatch(
        setToDate(returnLastDateOfMonth(state.fromDate).toLocaleDateString()),
      );
    }
  }, [state.fromDate]);

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Content />
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      <ConfirmCreatePayslipsModal
        show={showCreatePayrollModal}
        setShow={setShowCreatePayrollModal}
        payload={payload}
        setPayrollDate={setPayrollDate}
        payrollDate={payrollDate}
        createPayslipLoading={state.addEmployeePayrollLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        payslipChange={payrollChange}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Create Employee Payrolls</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="w-100 d-flex align-items-center gap-3">
                <Form.Group className=" w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project ID/Type
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                    <SelectSearch
                      name="emp"
                      options={projectOptions}
                      value={state.empIds}
                      onChange={(value: any) => handleProjectId(value)}
                      search={true}
                      placeholder="Select Project Id"
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Or
                  </h4>
                </Form.Group>
                <Form.Group className="w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Select Employee
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        handleEmployeeId(value);
                      }}
                      value={state.empIds}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowCreatePayrollModal(true);
                  }}
                  style={{ width: "max-content" }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Create Selected Payslips
                </button>
                <Dropdown
                  show={state.showEmployeePayrollExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowEmployeePayrollExportDropdown(
                          !state.showEmployeePayrollExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                    style={{ width: "max-content" }}
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showEmployeePayrollExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "employeePayroll-list");
                        dispatch(setShowEmployeePayrollExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowEmployeePayrollExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="w-100 d-flex align-items-center gap-3 mt-4">
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                From Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.fromDate)}
                  onChange={(e: any) =>
                    dispatch(setFromDate(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select From Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                To Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.toDate)}
                  onChange={(e: any) =>
                    dispatch(setToDate(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  maxDate={returnLastDateOfMonth(state.fromDate)}
                  minDate={new Date(state.fromDate)}
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select To Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <Form.Group
            className="mt-5 d-flex align-items-center gap-4"
            controlId="formBasicEmail"
          >
            <h4
              className="sub-heading normal-font-weight textBlack mb-0"
              style={{ fontSize: "18px" }}
            >
              Selected Projects/Employee Ids:
            </h4>

            <div className="d-flex align-items-center gap-2 overflow-auto">
              {state.empIds.map((item, index) => (
                <div
                  key={index}
                  className="selected-projects primary-button d-flex align-items-center gap-3 py-0 px-4"
                  style={{
                    height: "40px",
                    borderRadius: "20px",
                    boxShadow: "none",
                  }}
                >
                  <h4 className="mb-0" style={{ fontSize: "16px" }}>
                    {item}
                  </h4>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => {
                      if (state.empIds.length === 1) {
                        dispatch(setEmpIds(["All"]));
                        return;
                      }
                      const newProjects = state.empIds.filter(
                        (project) => project !== item,
                      );
                      dispatch(setEmpIds(newProjects));
                    }}
                  >
                    <img
                      src={Common.Cross}
                      style={{ width: "22px", height: "22px" }}
                      alt="close"
                    />
                  </button>
                </div>
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col sm="12">
          {state.employeePayrollListLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        EmployeePayrolls List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-3 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {print ? null : (
                          <th className="border-0">
                            <Form.Check
                              type="checkbox"
                              id="tableCheckbox"
                              className="table-checkbox justify-content-center"
                              name="hideLetterHead"
                              checked={
                                //   if all the checkboxes are checked then return true
                                allEmpIdsChecked
                              }
                              onChange={() => handleSelectAll()}
                            />
                          </th>
                        )}
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          {print ? null : (
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="tableCheckbox" // Add an id to target the checkbox
                                className="table-checkbox justify-content-center"
                                name="hideLetterHead"
                                checked={checked[item.empId]}
                                onChange={() => handleSingleSelect(item.empId)}
                              />
                            </td>
                          )}
                          <td>
                            {totalCount > 0
                              ? (state.employeePayrollListPageNo - 1) *
                                  state.employeePayrollListPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-create-payroll/${item.empId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-create-payroll/${item.empId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {print ? twoWords(item.empName) : item.empName}
                          </td>
                          <td>{item.empPhone}</td>
                          <td>
                            {getCollectedValue("addition", item).toFixed(2)}
                          </td>
                          <td>{getCollectedValue("gross", item).toFixed(2)}</td>
                          <td>
                            {getCollectedValue("deduction", item).toFixed(2)}
                          </td>
                          <td>{getCollectedValue("net", item).toFixed(2)}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/employees-payroll/view-create-payroll/${item.empId}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No payroll found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={state.employeePayrollListPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.employeePayrollListPageNo > 1
                          ? state.employeePayrollListPageNo - 1
                          : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {/* render pages here */}
                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.employeePayrollListPageNo
                          ? "active-page"
                          : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.employeePayrollListPageNo < totalPages
                          ? state.employeePayrollListPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default CreatePayroll;
