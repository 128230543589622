import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { FinaLExitEmployee } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterLeaveRequestTotalPages,
  setLeaveRequestLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredFinalExitEmployeesMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setFinalExitEmployees?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  finalExitEmployees: FinaLExitEmployee[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  finalExitDateFrom: string;
  finalExitDateTo: string;
  projectId: string;
  supervisorName: string;
  finalExitStatus: string;
}

const FinalExitFilterModal = ({
  show,
  setShow,
  finalExitEmployees,
  setFinalExitEmployees,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getAllFilteredFinalExitEmployees] =
    useGetAllFilteredFinalExitEmployeesMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLeaveRequestLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterFinalExitPageNo,
          limit: state.filterFinalExitPageSize,
          projectId: filterInput.projectId,
          finalExitDateFrom: filterInput.finalExitDateFrom,
          finalExitDateTo: filterInput.finalExitDateTo,
          supervisorName: filterInput.supervisorName,
          finalExitStatus: filterInput.finalExitStatus,
        },
      };
      const response = await getAllFilteredFinalExitEmployees(data).unwrap();
      if (response) {
        setFinalExitEmployees(response.data.finalExit[0].data);
        dispatch(
          setFilterLeaveRequestTotalPages(
            response.data.finalExit[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setLeaveRequestLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLeaveRequestLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterFinalExitPageNo,
            limit: state.filterFinalExitPageSize,
            empId: query,
            projectId: filterInput.projectId,
            finalExitDateFrom: filterInput.finalExitDateFrom,
            finalExitDateTo: filterInput.finalExitDateTo,
            supervisorName: filterInput.supervisorName,
            finalExitStatus: filterInput.finalExitStatus,
          },
        };
        const response = await getAllFilteredFinalExitEmployees(data).unwrap();
        if (response) {
          setFinalExitEmployees(response.data.finalExit[0].data);
          dispatch(
            setFilterLeaveRequestTotalPages(
              response.data.finalExit[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setLeaveRequestLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterFinalExitPageNo, state.filterFinalExitPageSize, query]);

  const handleReset = () => {
    setFinalExitEmployees(finalExitEmployees);
    setFilterInput({
      finalExitDateFrom: "",
      finalExitDateTo: "",
      projectId: "",
      supervisorName: "",
      finalExitStatus: "",
    });
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Final Exit Employees</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor Name
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="supervisorName"
                        options={supervisorOptions}
                        value={filterInput.supervisorName}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            supervisorName: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Supervisor"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Final Exit Status
                    </h4>
                    <Form.Select
                      name="finalExitStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          finalExitStatus: e.target.value,
                        })
                      }
                      value={filterInput.finalExitStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Final Exit From
                    </h4>
                    <Form.Control
                      type="date"
                      name="finalExitDateFrom"
                      placeholder="Enter final exit from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          finalExitDateFrom: e.target.value,
                        })
                      }
                      value={filterInput.finalExitDateFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Final Exit To
                    </h4>
                    <Form.Control
                      type="date"
                      name="finalExitDateTo"
                      placeholder="Enter final exit to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          finalExitDateTo: e.target.value,
                        })
                      }
                      value={filterInput.finalExitDateTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FinalExitFilterModal;
