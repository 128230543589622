import React, { useState, useRef } from "react";
// Packages Imports
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
// Files Imports
import { LoaderWhite } from "../Lotties";
import CommonBGLayout from "../CommonBGLayout";
import { Common } from "../../assets/images";
import { ToastSuccessComponent, ToastErrorComponent } from "../Toasts";
// API
import {
  useVerifyOTPMutation,
  useForgotPasswordMutation,
} from "../../services/Authentication";

const OtpVerificationComponent = () => {
  const [otp, setOtp] = useState<Array<string>>(new Array(4).fill(""));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [otpError, setOtpError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resentText, setResentText] = useState("");

  const [verifyOTP, { isLoading: verifyOTPLoading }] = useVerifyOTPMutation();
  const [forgotPassword, { isLoading: forgotPasswordLoading }] =
    useForgotPasswordMutation();

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (value.length === 0 || /^\d+$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on next input box
      if (value.length !== 0 && index < 5 && inputsRef.current[index + 1]) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      event.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputsRef.current[index - 1]
    ) {
      inputsRef.current[index - 1]?.focus();
    }
    if (
      event.key === "Delete" &&
      !otp[index] &&
      index < 5 &&
      inputsRef.current[index + 1]
    ) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const email = location?.state?.email;
  // Resend Email
  const resendEmail = async () => {
    try {
      await forgotPassword({ email }).unwrap();
      setShowToast(true);
      setResentText("OTP resent successfully.");
    } catch (err: any) {}
  };

  // Form Submit Function
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // dispatch(loadingState(true));
    e.preventDefault();
    try {
      const data = {
        email: email,
        otp: otp.join(""),
      };
      await verifyOTP(data).unwrap();
      navigate("/reset-password", { state: { email: email } });
    } catch (err: any) {
      if (err.data.data.otp) {
        window.scrollTo(0, 0);
        setOtpError(err.data.data.otp);
      }
    }
  };

  return (
    <CommonBGLayout>
      <ToastSuccessComponent
        show={showToast}
        setShow={setShowToast}
        content={resentText}
      />
      <div className="login">
        <Container>
          <Row>
            <Col>
              <div className="login-card text-center mx-auto">
                <div className="login-overlay"></div>
                <div className="login-card-header mb-4">
                  <img
                    src={Common.Logo}
                    width="130px"
                    height="101px"
                    alt="logo"
                  />
                  <h3 className="mt-2 mb-0">Email verification</h3>
                  <p className="para-big mb-0">
                    Please enter the 4-digit verification code sent to your
                    email.
                  </p>
                </div>
                <div className="login-card-body text-start">
                  <div className="common-form">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="otp-input-container mt-5">
                        <div className="otp-input-container">
                          {Array.from({ length: 4 }, (_, index) => (
                            <Form.Control
                              key={index}
                              type="text"
                              value={otp[index]}
                              inputMode="numeric"
                              maxLength={1}
                              onChange={(e: any) => handleChange(index, e)}
                              onKeyDown={(e: any) => handleKeyDown(index, e)}
                              ref={(input: any) =>
                                inputsRef.current.push(input)
                              }
                              className={otpError ? "is-invalid-otp" : ""}
                            />
                          ))}
                        </div>
                      </Form.Group>
                      {otpError ? (
                        <div className="invalid-feedback text-center">
                          {otpError}
                        </div>
                      ) : null}
                      <div className="text-center mt-3">
                        {forgotPasswordLoading ? (
                          <LoaderWhite height={30} width={30} />
                        ) : (
                          <button
                            className="btn p-0 border-0 primary-button bg-transparent text-decoration-underline mx-auto"
                            type="button"
                            onClick={resendEmail}
                            style={{ fontSize: "16px" }}
                          >
                            Resend code
                          </button>
                        )}
                      </div>
                      <div className="submit mt-5">
                        <button
                          type="submit"
                          disabled={otp.includes("")}
                          className={
                            otp.includes("")
                              ? "btn primary-button submit-disabled w-100"
                              : "btn primary-button w-100"
                          }
                        >
                          {verifyOTPLoading ? (
                            <LoaderWhite width={30} height={30} />
                          ) : (
                            "Verify Email"
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CommonBGLayout>
  );
};

export default OtpVerificationComponent;
