import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setActiveEmpLoading,
  setFilterActiveTotalRecords,
} from "../../store/slices/employeeSlice";
import SelectSearch from "react-select-search";
import { Employees } from "../../interfaces/employee";
// API imports
import { useGetAllFilterEmployeesMutation } from "../../services/Employees";

interface Props {
  show?: boolean;
  setShow?: any;
  setEmployees?: any;
  setFilterSearch: any;
  query: string;
  employees: Employees[];
  filterInput: FormProps;
  setFilterInput: any;
  filterSearch: boolean;
}

export interface FormProps {
  nationality: string;
  projectId: string;
  category: string;
  status: string;
  place: string;
  totalSalaryFrom: number | null;
  totalSalaryTo: number | null;
  passportExpiryFrom: string;
  passportExpiryTo: string;
}

const ActiveEmployeeFilterModal = ({
  show,
  setShow,
  employees,
  setEmployees,
  setFilterSearch,
  filterSearch,
  query,
  filterInput,
  setFilterInput,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [getAllFilterEmployees] = useGetAllFilterEmployeesMutation();
  // Handle Submit Function to filter employees based on the form values
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setActiveEmpLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterActivePageNo,
          limit: state.filterActivePageSize,
          nationality: filterInput.nationality,
          projectId: filterInput.projectId,
          category: filterInput.category,
          status: filterInput.status,
          place: filterInput.place,
          totalSalaryFrom: filterInput.totalSalaryFrom,
          totalSalaryTo: filterInput.totalSalaryTo,
          passportExpiryFrom: filterInput.passportExpiryFrom,
          passportExpiryTo: filterInput.passportExpiryTo,
        },
      };
      const response = await getAllFilterEmployees(data).unwrap();
      if (response) {
        setEmployees(response.data.employees[0].data);
        dispatch(
          setFilterActiveTotalRecords(
            response.data.employees[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setActiveEmpLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setActiveEmpLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterActivePageNo,
            limit: state.filterActivePageSize,
            empId: parseInt(query),
            nationality: filterInput.nationality,
            projectId: filterInput.projectId,
            category: filterInput.category,
            status: filterInput.status,
            place: filterInput.place,
            totalSalaryFrom: filterInput.totalSalaryFrom,
            totalSalaryTo: filterInput.totalSalaryTo,
            passportExpiryFrom: filterInput.passportExpiryFrom,
            passportExpiryTo: filterInput.passportExpiryTo,
          },
        };
        const response = await getAllFilterEmployees(data).unwrap();
        if (response) {
          setEmployees(response.data.employees[0].data);
          dispatch(
            setFilterActiveTotalRecords(
              response.data.employees[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setActiveEmpLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterActivePageNo, state.filterActivePageSize, query]);

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
  ];

  const categories = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Other",
  ];

  const projects = useAppSelector((state) => state.project.projectIds);

  const options = projects.map((project) => ({
    name: project.projectId + " - " + project.projectName, // Use the actual property name for the project name
    value: project.projectId,
  }));

  const handleReset = () => {
    setEmployees(employees);
    setFilterInput({
      nationality: "",
      projectId: "",
      category: "",
      status: "",
      place: "",
      totalSalaryFrom: null,
      totalSalaryTo: null,
      passportExpiryFrom: "",
      passportExpiryTo: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Employee List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Nationality
                    </h4>
                    <Form.Select
                      name="nationality"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          nationality: e.target.value,
                        })
                      }
                      value={filterInput.nationality}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Nationality</option>
                      {countryCodes.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Select Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="projectId"
                        options={options}
                        value={filterInput.projectId}
                        onChange={(e: any) =>
                          setFilterInput({
                            ...filterInput,
                            projectId: e,
                          })
                        }
                        search={true}
                        placeholder="Select Project Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Category
                    </h4>
                    <Form.Select
                      name="category"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          category: e.target.value,
                        })
                      }
                      value={filterInput.category}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Category</option>
                      <option value="Owner">Owner</option>
                      <option value="Saudi Employee">Saudi Employee</option>
                      {categories.map((item: string, index: number) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Employee Status
                    </h4>
                    <Form.Select
                      name="status"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          status: e.target.value,
                        })
                      }
                      value={filterInput.status}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Employee">Employee</option>
                      <option value="Initial Evaluation">
                        Initial Evaluation
                      </option>
                      <option value="Probation">Probation</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Place
                    </h4>
                    <Form.Select
                      name="place"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          place: e.target.value,
                        })
                      }
                      value={filterInput.place}
                      className="custom-input border-fill"
                    >
                      <option value="">Working place</option>
                      <option value="Management">Management</option>
                      <option value="Indoor">Indoor</option>
                      <option value="Outdoor">Outdoor</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Passport Expiry From
                    </h4>
                    <Form.Control
                      type="date"
                      name="passportExpiryFrom"
                      placeholder="Enter passport expiry from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          passportExpiryFrom: e.target.value,
                        })
                      }
                      value={filterInput.passportExpiryFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Passport Expiry To
                    </h4>
                    <Form.Control
                      type="date"
                      name="passportExpiryTo"
                      placeholder="Enter passport expiry to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          passportExpiryTo: e.target.value,
                        })
                      }
                      value={filterInput.passportExpiryTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Total Salary From
                    </h4>
                    <Form.Control
                      type="number"
                      name="totalSalaryFrom"
                      placeholder="Enter total salary from "
                      onChange={(e) => {
                        setFilterInput({
                          ...filterInput,
                          totalSalaryFrom: parseInt(e.target.value),
                        });
                      }}
                      value={filterInput.totalSalaryFrom || undefined}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Total Salary To
                    </h4>
                    <Form.Control
                      type="number"
                      name="totalSalaryTo"
                      placeholder="Enter total salary to"
                      onChange={(e) => {
                        setFilterInput({
                          ...filterInput,
                          totalSalaryTo: parseInt(e.target.value),
                        });
                      }}
                      value={filterInput.totalSalaryTo || undefined}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActiveEmployeeFilterModal;
