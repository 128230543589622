import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Client } from "../../interfaces/project";
import { Common } from "../../assets/images";
import TabComponent from "../TabComponent";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  setActiveTab,
  setShowClientDetails,
  setShowInvoiceDetails,
  setShowTransactionDetails,
  setClientIdNumber,
  setShowStatementDetails,
  setShowAttachmentDetails,
} from "../../store/slices/clientsSlice";
import AllInvoices from "./all-invoices";
import AllPayments from "./all-payments";
import Attachments from "./attachments";
import Statement from "./statement";

const ViewClient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.clients);
  const clientsData: Client = location.state.data;

  const handleTabChange = (tab: string) => {
    dispatch(setActiveTab(tab));
    if (tab === "Client Detail") {
      dispatch(setShowClientDetails(true));
      dispatch(setShowInvoiceDetails(false));
      dispatch(setShowTransactionDetails(false));
      dispatch(setShowAttachmentDetails(false));
      dispatch(setShowStatementDetails(false));
    }
    if (tab === "Invoices") {
      dispatch(setShowClientDetails(false));
      dispatch(setShowInvoiceDetails(true));
      dispatch(setShowTransactionDetails(false));
      dispatch(setShowAttachmentDetails(false));
      dispatch(setShowStatementDetails(false));
    }
    if (tab === "Payments") {
      dispatch(setShowClientDetails(false));
      dispatch(setShowInvoiceDetails(false));
      dispatch(setShowTransactionDetails(true));
      dispatch(setShowAttachmentDetails(false));
      dispatch(setShowStatementDetails(false));
    }
    if (tab === "Attachments") {
      dispatch(setShowClientDetails(false));
      dispatch(setShowInvoiceDetails(false));
      dispatch(setShowTransactionDetails(false));
      dispatch(setShowAttachmentDetails(true));
      dispatch(setShowStatementDetails(false));
    }
    if (tab === "Statement") {
      dispatch(setShowClientDetails(false));
      dispatch(setShowInvoiceDetails(false));
      dispatch(setShowTransactionDetails(false));
      dispatch(setShowAttachmentDetails(false));
      dispatch(setShowStatementDetails(true));
    }
  };

  useEffect(() => {
    dispatch(setClientIdNumber(clientsData.clientId));
  }, []);

  return (
    <>
      <TabComponent
        tabList={state.tabList}
        activeTab={state.activeTab}
        sectionName="View Client"
        handleTabChange={handleTabChange}
      ></TabComponent>
      <div className=" mt-2 section px-4 py-4">
        {state.showClientDetails && (
          <Row>
            <Col sm="12">
              <div className="section-heading mb-4">
                <div className="d-flex align-items-center gap-4">
                  <button
                    className="btn border-0 p-0"
                    onClick={() => {
                      navigate(-1);
                    }}
                    type="button"
                  >
                    {" "}
                    <img src={Common.ArrowLeftBlue} alt="next" />
                  </button>
                  <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                    Client Detail
                  </h4>
                </div>
              </div>
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientId}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientName +
                        " " +
                        clientsData.clientNameArabic}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client's Contact No
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientsContactNo}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Contact Person Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.contactPersonName}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Contact Person Designation
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.contactPersonDesignation}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Contact Person Contact No
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.phone}
                    </h5>
                  </Form.Group>
                </Col>
                {/*<Col sm="4">*/}
                {/*  <Form.Group className="mb-4" controlId="formBasicEmail">*/}
                {/*    <h4*/}
                {/*      className="sub-heading textBlack"*/}
                {/*      style={{ fontSize: "18px" }}*/}
                {/*    >*/}
                {/*      Client City*/}
                {/*    </h4>*/}
                {/*    <h5*/}
                {/*      className="sub-heading normal-font-weight"*/}
                {/*      style={{ fontSize: "16px" }}*/}
                {/*    >*/}
                {/*      {clientsData.city}*/}
                {/*    </h5>*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client MOL
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientMOL}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Postal Code
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.postalCode}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      VAT Number
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.numberVAT}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      CR Number
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.numberCR}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Other Buyer Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.otherBuyerId}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Balance
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.totalBalance}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Status
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientStatus}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <h4
                      className="sub-heading textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Address
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {clientsData.clientAddress1 +
                        " " +
                        clientsData.clientAddressArabic}
                    </h5>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm="12">
              <div className="table-responsive mb-3">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Email List
                </h4>
                {clientsData.emails.length > 0 ? (
                  <table className="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsData.emails.map((item: string, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.toLocaleLowerCase()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">No emails added</div>
                )}
              </div>
            </Col>
          </Row>
        )}
        {state.showInvoiceDetails && <AllInvoices />}
        {state.showTransactionDetails && <AllPayments />}
        {state.showAttachmentDetails && (
          <Attachments clientsData={clientsData} />
        )}
        {state.showStatementDetails && <Statement />}
      </div>
    </>
  );
};
export default ViewClient;
