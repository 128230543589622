import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowVendorExportDropdown,
  setVendorListPageSize,
  setVendorListPageNo,
  setFilterVendorPageNo,
  setFilterVendorPageSize,
} from "../../store/slices/vendorsSlice";
import { setVendorChange } from "../../store/slices/commonSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { useDeleteVendorMutation } from "../../services/AccountCharts";
import { Vendor } from "../../interfaces/account-charts";
import { exportToCsv, exportToPDF } from "../../config";
import VendorFilterModal, { FormProps } from "../Modals/vendor-filter-modal";
import { useReactToPrint } from "react-to-print";
import SelectSearch from "react-select-search";

const AllVendors = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.vendors);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const vendorChange = useAppSelector((state) => state.common.vendorChange);
  const vendorIds = useAppSelector((state) => state.vendors.vendorIds);
  // Options
  const options = vendorIds.map((item) => ({
    name: item.vendorId + " - " + item.vendorName,
    value: item.vendorId,
  }));
  // Pagination && Filter
  const [filterInput, setFilterInput] = useState<FormProps>({
    city: "",
    country: "",
    vendorStatus: "",
  });
  const [filterSearch, setFilterSearch] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<Vendor[]>([]);
  const totalPages = Math.ceil(
    state.vendorListTotalPages / state.vendorListPageSize,
  );
  const totalCount = filterSearch
    ? state.filterVendorTotalPages
    : state.vendorListTotalPages;

  const filterTotalPages = Math.ceil(
    state.filterVendorTotalPages / state.filterVendorPageSize,
  );

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.vendorListPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  const filterStartPage =
    Math.floor((state.filterVendorPageNo - 1) / showPages) * showPages;
  const filterEndPage = filterStartPage + showPages;
  const filterPages = [...Array(filterTotalPages).keys()].slice(
    filterStartPage,
    filterEndPage,
  );
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.vendorListPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setVendorListPageNo(state.vendorListPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.vendorListPageNo <= 5) {
      return;
    }
    dispatch(setVendorListPageNo(state.vendorListPageNo - showPages));
  };

  const handleFilterGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.filterVendorPageNo > filterTotalPages - showPages) {
      return;
    }
    dispatch(setFilterVendorPageNo(state.filterVendorPageNo + showPages));
  };

  const handleFilterGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.filterVendorPageNo <= 5) {
      return;
    }
    dispatch(setFilterVendorPageNo(state.filterVendorPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setVendorListPageNo(page));
  };

  const handleFilterPageChange = (page: any) => {
    dispatch(setFilterVendorPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    if (filterSearch) {
      dispatch(setFilterVendorPageSize(selectedPageSize));
    } else {
      dispatch(setVendorListPageSize(selectedPageSize));
    }
  };
  useEffect(() => {
    setFilteredData(state.vendorList);
  }, [state.vendorList]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Vendor Id.",
      "Vendor Name",
      "Contact Person",
      "Contact Person Designation",
      "Contact Person No.",
      "Company Name",
      "VAT Number",
      "Vendor Status",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Vendor Id.": item.vendorId,
    "Vendor Name": item.vendorName,
    "Contact Person": item.contactPersonName,
    "Contact Person Designation": item.contactPersonDesignation,
    "Contact Person No.": item.phone,
    "Company Name": item.companyName,
    "VAT Number": item.numberVAT,
    "Vendor Status": item.vendorStatus,
  }));

  const [deleteVendor] = useDeleteVendorMutation();

  const handleDelete = async (empId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.deletePettyCash || false,
        payload: {
          vendorId: empId,
        },
      };
      await deleteVendor(data).unwrap();
      dispatch(setVendorChange(!vendorChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [empId]: false }));
    }
  };

  const handleReset = () => {
    setQuery("");
    setSearch("");
    setFilteredData(state.vendorList);
    setFilterSearch(false);
    setFilterInput({
      city: "",
      country: "",
      vendorStatus: "",
    });
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <VendorFilterModal
        show={showFilterModal}
        setShow={setShowFilterModal}
        setVendors={setFilteredData}
        filterSearch={filterSearch}
        setFilterSearch={setFilterSearch}
        vendors={state.vendorList}
        query={query}
        filterInput={filterInput}
        setFilterInput={setFilterInput}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">All Vendors</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        setQuery(value);
                        setFilterSearch(true);
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Vendor by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/vendors/add-vendor")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  New
                </button>
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Filter{" "}
                </button>
                <Dropdown
                  show={state.showVendorExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowVendorExportDropdown(
                          !state.showVendorExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showVendorExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "vendors-list");
                        dispatch(setShowVendorExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowVendorExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.vendorListLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showPettyCash ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Vendors List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? filterSearch
                                ? (state.filterVendorPageNo - 1) *
                                    state.filterVendorPageSize +
                                  index +
                                  1
                                : (state.vendorListPageNo - 1) *
                                    state.vendorListPageSize +
                                  index +
                                  1
                              : index + 1}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/vendors/view-vendor/${item.vendorId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {item.vendorId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/vendors/view-vendor/${item.vendorId}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {item.vendorName}
                          </td>
                          <td>{item.contactPersonName}</td>
                          <td>{item.contactPersonDesignation}</td>
                          <td>{item.phone}</td>
                          <td>{item.companyName}</td>
                          {/*<td>{item.city}</td>*/}
                          {/*<td>{item.country}</td>*/}
                          <td>{item.numberVAT}</td>
                          <td>{item.vendorStatus}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/vendors/view-vendor/${item.vendorId}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/vendors/edit-vendor/${item.vendorId}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={SettingsIcons.EditUser}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              {deleteLoadingMap[item.vendorId] ? (
                                <LoaderBlue height={20} width={20} />
                              ) : (
                                <button
                                  className="btn p-0 border-0"
                                  onClick={() => handleDelete(item.vendorId)}
                                >
                                  <img
                                    src={SettingsIcons.Delete}
                                    width="24px"
                                    height="24px"
                                    alt="edit-user"
                                  />
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No vendors found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={
                    filterSearch
                      ? state.filterVendorPageSize.toString()
                      : state.vendorListPageSize.toString()
                  }
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterGoToPreviousPages()
                        : handleGoToPreviousPages()
                    }
                  >
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterVendorPageNo > 1
                              ? state.filterVendorPageNo - 1
                              : 1,
                          )
                        : handlePageChange(
                            state.vendorListPageNo > 1
                              ? state.vendorListPageNo - 1
                              : 1,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  {filterSearch ? (
                    <>
                      {/* render filter pages here */}
                      {filterPages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handleFilterPageChange(page + 1)}
                          className={
                            page + 1 === state.filterVendorPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* render pages here */}
                      {pages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(page + 1)}
                          className={
                            page + 1 === state.vendorListPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  )}
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterVendorPageNo < filterTotalPages
                              ? state.filterVendorPageNo + 1
                              : filterTotalPages,
                          )
                        : handlePageChange(
                            state.vendorListPageNo < totalPages
                              ? state.vendorListPageNo + 1
                              : totalPages,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      filterSearch
                        ? handleFilterGoToNextPages()
                        : handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default AllVendors;
