import React, { useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { AddEmployeeProps } from "../AddEmployee/add-employee-container";
import { Common, EmployeeIcons, SettingsIcons } from "../../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveTab,
  setEditOtherDetails,
  setEditAgreementDetails,
  setEditAttachments,
} from "../../../store/slices/employeeSlice";
import DatePicker from "react-datepicker";
// API
import { useUploadImageMutation } from "../../../services/images-upload-service";
import { LoaderBlue, LoaderWhite } from "../../Lotties";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const EditAgreementDetail = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState(
    values.agreement.attachment,
  );
  // Redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  // Using the URL API to extract the pathname
  const pathname = imageUploaded ? new URL(imageUploaded).pathname : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split("/").pop();

  useEffect(() => {
    if (imageUploaded) {
      values.agreement.attachment = imageUploaded;
    }
  }, [imageUploaded]);

  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Agreement Detail</h3>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Agreement Issued Date
          </h4>
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <DatePicker
              name="agreement.issueDate"
              selected={
                values.agreement.issueDate
                  ? new Date(values.agreement.issueDate)
                  : null
              }
              onChange={(date: any) =>
                handleChange({
                  target: { name: "agreement.issueDate", value: date },
                })
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              placeholderText="Issue Date"
              className="w-100 custom-input"
              dateFormat="yyyy-MM-d"
            />
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
          </Form.Group>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Agreement Expiry Date
          </h4>
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <DatePicker
              name="agreement.expiryDate"
              selected={
                values.agreement.expiryDate
                  ? new Date(values.agreement.expiryDate)
                  : null
              }
              onChange={(date: any) =>
                handleChange({
                  target: { name: "agreement.expiryDate", value: date },
                })
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              placeholderText="Expiry Date"
              className="w-100 custom-input"
              dateFormat="yyyy-MM-d"
            />
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
          </Form.Group>
        </Form.Group>
      </Col>
      <Col sm="12">
        <Form.Group>
          {imageUploaded ? null : (
            <div>
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Attachment
              </h4>
            </div>
          )}
          {imageLoading ? (
            <LoaderBlue height={50} width={50} />
          ) : (
            <>
              {imageUploaded ? (
                <div className="d-flex">
                  <div className="text-center">
                    <img
                      src={
                        fileNameWithExtension === "jpg" ||
                        fileNameWithExtension === "png" ||
                        fileNameWithExtension === "jpeg"
                          ? Common.ImageIcon
                          : Common.FileIcon
                      }
                      style={{
                        width: "130px",
                        height: "130px",
                        objectFit: "contain",
                      }}
                      alt="employee-image"
                    />
                    <p className="para-medium textBlack text-center">
                      {fileNameWithExtension}
                    </p>
                  </div>
                  {imageUploaded ? (
                    <label className="d-flex align-items-center justify-content-center cursor-pointer">
                      <input
                        type="file"
                        name="profileImage"
                        onChange={handleUploadImage}
                        accept=".png, .jpg, .jpeg"
                        className="d-none"
                        multiple={false}
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <h4 className="sub-heading text-decoration-underline">
                          Change Attachment
                        </h4>
                      </div>
                    </label>
                  ) : null}
                </div>
              ) : (
                <Form.Label className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center">
                  <Form.Control
                    type="file"
                    name="profileImage"
                    onChange={handleUploadImage}
                    accept=".png, .jpg, .jpeg, .pdf, .doc, docx"
                    className="d-none"
                    multiple={false}
                  />
                  <div className="text-center">
                    <div className="add-profile-image d-flex align-items-center justify-content-center mx-auto">
                      <img src={EmployeeIcons.Plus} width="25px" alt="plus" />
                    </div>
                    <p
                      className="upload-para mb-0 mt-4"
                      style={{ fontSize: "16px" }}
                    >
                      Upload PNG, JPG, PDF, DOCS File only
                    </p>
                    <p
                      className="upload-para mt-0 mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      Max file size is 5MB
                    </p>
                  </div>
                </Form.Label>
              )}
            </>
          )}
        </Form.Group>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setEditOtherDetails(true));
              dispatch(setEditAgreementDetails(false));
              dispatch(setActiveTab("Other Details"));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <div className="w-100">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="submit"
              style={{ width: "15%" }}
            >
              {state.empLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
            <button
              className="btn primary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditAgreementDetails(false));
                dispatch(setEditAttachments(true));
                dispatch(setActiveTab("Attachments"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              Next <img src={Common.ArrowRightWhite} alt="next" />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default EditAgreementDetail;
