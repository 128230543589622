import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Vendor } from "../../interfaces/account-charts";
import { Common, EmployeeIcons } from "../../assets/images";
import DatePicker from "react-datepicker";
import {
  setStatementFrom,
  setStatementTo,
  setVendorIdNo,
} from "../../store/slices/vendorsSlice";
import { exportToCsv } from "../../config";
import { LoaderBlue } from "../Lotties";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Content from "./get-statement-api";

const ViewVendor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [viewStatement, setViewStatement] = React.useState(false);
  const vendorsData: Vendor = location.state.data;
  const state = useAppSelector((state) => state.vendors);
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = state.vendorStatement.statements.slice(
    startIndex,
    endIndex,
  );

  const totalPages = Math.ceil(
    state.vendorStatement.statements.length / pageSize,
  );

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Date",
      "Statement Id",
      "Type",
      "Note",
      "Credit (SAR)",
      "Debit (SAR)",
    ],
    tableData: paginatedData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    Date: item.date ? new Date(item.date).toLocaleDateString() : "",
    "Statement Id": item.statementId,
    Type: item.type,
    Note: item.note,
    "Credit (SAR)": item.credit ? item.credit : "",
    "Debit (SAR)": item.debit ? item.debit : "",
  }));

  React.useEffect(() => {
    dispatch(setVendorIdNo(vendorsData.vendorId));
  }, []);

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <div className="d-flex align-items-center gap-4">
              <button
                className="btn border-0 p-0"
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
              >
                {" "}
                <img src={Common.ArrowLeftBlue} alt="next" />
              </button>
              <h1 className="heading mb-0">
                Vendor {viewStatement ? "Statement" : "Detail"}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      {!viewStatement ? (
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.vendorId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.vendorName +
                      " " +
                      vendorsData.vendorNameArabic}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor's Contact No
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.phone}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Contact Person Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.contactPersonName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Contact Person Designation
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.contactPersonDesignation}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Contact Person Contact No
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.phone}
                  </h5>
                </Form.Group>
              </Col>
              {/*<Col sm="4">*/}
              {/*  <Form.Group className="mb-4" controlId="formBasicEmail">*/}
              {/*    <h4*/}
              {/*      className="sub-heading textBlack"*/}
              {/*      style={{ fontSize: "18px" }}*/}
              {/*    >*/}
              {/*      Vendor City*/}
              {/*    </h4>*/}
              {/*    <h5*/}
              {/*      className="sub-heading normal-font-weight"*/}
              {/*      style={{ fontSize: "16px" }}*/}
              {/*    >*/}
              {/*      {vendorsData.city}*/}
              {/*    </h5>*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Postal Code
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.postalCode}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    VAT Number
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.numberVAT}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    CR Number
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.numberCR}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.vendorStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Address
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.vendorAddress +
                      " - " +
                      vendorsData.vendorAddressArabic}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="12">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Shipping Address
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {vendorsData.shippingAddress +
                      " - " +
                      vendorsData.shippingAddressArabic}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div className="table-responsive mb-3">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Email List
              </h4>
              {vendorsData.emails.length > 0 ? (
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorsData.emails.map((item: string, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.toLocaleLowerCase()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">No emails added</div>
              )}
            </div>
          </Col>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">Attachments</h1>
            </div>
          </Col>
          <Col sm="12">
            {vendorsData.vendorAttachments.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>File Title</th>
                      <th>Uploader Emp Id</th>
                      <th>Uploader Emp Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorsData.vendorAttachments.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {item.date
                            ? new Date(item.date).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td>{item.title}</td>
                        <td>{item.empId}</td>
                        <td>{item.empName}</td>
                        <td>
                          <a href={item.link} download>
                            <img
                              src={Common.Download}
                              className="me-2"
                              width="24px"
                              height="24px"
                              alt="share"
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No Attachment Found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center gap-3">
                  <Form.Group className="" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      From Date
                    </h4>
                    <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                      <DatePicker
                        name="month"
                        selected={
                          state.statementFrom
                            ? new Date(state.statementFrom)
                            : null
                        }
                        onChange={(e: any) =>
                          dispatch(setStatementFrom(e.toLocaleDateString()))
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="Select From Date"
                        className="w-100 custom-input"
                        dateFormat="MMMM"
                      />
                      <img
                        src={Common.Calendar}
                        className="calendar-date-recur"
                        alt="calendarblack"
                      />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      To Date
                    </h4>
                    <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                      <DatePicker
                        name="month"
                        selected={
                          state.statementTo ? new Date(state.statementTo) : null
                        }
                        onChange={(e: any) =>
                          dispatch(setStatementTo(e.toLocaleDateString()))
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="Select To Date"
                        className="w-100 custom-input"
                        dateFormat="MMMM"
                      />
                      <img
                        src={Common.Calendar}
                        className="calendar-date-recur"
                        alt="calendarblack"
                      />
                    </Form.Group>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <Dropdown
                    show={showExportDropdown}
                    className="position-relative"
                  >
                    <button
                      id="dropdown-basic"
                      onClick={() => {
                        setShowExportDropdown(!showExportDropdown);
                      }}
                      className=" bg-transparent dropdown-toggle-white"
                    >
                      Export{" "}
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="ms-2"
                        alt="down-arrow"
                      />
                    </button>
                    <Dropdown.Menu
                      className="mt-1 custom-scrollbar"
                      show={showExportDropdown}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          exportToCsv(csvData, "client-statement-detail");
                          setShowExportDropdown(false);
                        }}
                      >
                        CSV
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowExportDropdown(false);
                          handlePrinted();
                        }}
                      >
                        PDF
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Col>
          <div className="p-4" ref={tableRef}>
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <img src={Common.Vision} width="110px" alt="logo" />
                </div>
                <div className="letter-logo text-center gap-2">
                  <img src={Common.LogoSVG} width="80px" alt="logo" />
                  <div className="mt-1">
                    <h3
                      className="logo-text mb-0"
                      style={{
                        color: "#202020",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      Senyar Arabian Trading Co. LTD
                    </h3>
                    <h3
                      className="logo-text logo-arabic mb-0"
                      style={{
                        color: "#202020",
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                    >
                      شركة سنيار العربية التجارية المحدودة
                    </h3>
                  </div>
                </div>
              </div>
              <div>
                <h1 className="sub-heading text-center mt-0">
                  {vendorsData.vendorName}'s Statement
                </h1>
                <h1 className="sub-heading text-center mt-0">
                  {new Date(state.statementFrom).toLocaleDateString()} -{" "}
                  {new Date(state.statementTo).toLocaleDateString()}
                </h1>
                <div className="text-end">
                  <h1 className="sub-heading textBlack my-3">
                    Account Summary:
                  </h1>
                  <hr style={{ width: "200px", marginLeft: "auto" }} />

                  <div className="d-flex align-items-center justify-content-end">
                    <h1
                      className="sub-heading textBlack"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "170px",
                      }}
                    >
                      Opening Balance:
                    </h1>
                    <h1
                      className="sub-heading textBlack text-end"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "300px",
                      }}
                    >
                      {state.vendorStatement.openingBalance?.toFixed(2)} SAR
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <h1
                      className="sub-heading textBlack"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "170px",
                      }}
                    >
                      Billed Amount:
                    </h1>
                    <h1
                      className="sub-heading textBlack text-end"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "300px",
                      }}
                    >
                      {state.vendorStatement.billedAmount?.toFixed(2)} SAR
                    </h1>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <h1
                      className="sub-heading textBlack"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "170px",
                      }}
                    >
                      Amount Paid:
                    </h1>
                    <h1
                      className="sub-heading textBlack text-end"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "300px",
                      }}
                    >
                      {state.vendorStatement.amountPaid?.toFixed(2)} SAR
                    </h1>
                  </div>
                  <hr style={{ width: "460px", marginLeft: "auto" }} />
                  <div className="d-flex align-items-center justify-content-end">
                    <h1
                      className="sub-heading textBlack"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "170px",
                      }}
                    >
                      Balance Due:
                    </h1>
                    <h1
                      className="sub-heading textBlack text-end"
                      style={{
                        fontSize: print ? "14px" : "18px",
                        width: "300px",
                      }}
                    >
                      {state.vendorStatement.balanceDue?.toFixed(2)} SAR
                    </h1>
                  </div>
                </div>
              </div>
            </>

            <Col sm="12">
              <div className="mt-1">
                {state.vendorStatementLoading ? (
                  <LoaderBlue height={50} width={50} />
                ) : (
                  <>
                    {tableData.tableData.length > 0 ? (
                      <Table
                        striped
                        bordered
                        responsive
                        className={
                          print ? "text-center print-table" : "text-center"
                        }
                      >
                        <thead>
                          <tr className="border-0">
                            {tableData.tableHeading.map((item, index) => (
                              <th className="border-0" key={index}>
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.tableData.map((item, index) => (
                            <tr
                              key={index}
                              style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                            >
                              <td>
                                {state.vendorStatement.statements.length > 0
                                  ? (currentPage - 1) * pageSize + index + 1
                                  : index + 1}
                              </td>
                              <td>
                                {item.date
                                  ? new Date(item.date).toLocaleDateString()
                                  : ""}
                              </td>
                              <td>{item.statementId}</td>
                              <td>{item.type}</td>
                              <td>{item.note}</td>
                              <td>{item.credit ? item.credit : ""}</td>
                              <td>{item.debit ? item.debit : ""}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={5} className="text-end">
                              Total
                            </td>
                            <td>{state.vendorStatement.amountPaid}</td>
                            <td>{state.vendorStatement.billedAmount}</td>
                          </tr>
                        </tfoot>
                      </Table>
                    ) : (
                      <div className="text-center mt-5">
                        <h1 className="heading">No accounts found</h1>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
            {paginatedData.length > 0 && !print && (
              <Col sm="12">
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  <Form.Group
                    className="d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "14px", width: "230px" }}
                    >
                      Entries per page
                    </h4>
                    <Form.Select
                      name="pageSize"
                      onChange={handlePageSizeChange}
                      value={pageSize.toString()}
                      className="custom-input border-fill custom-height"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="100">100</option>
                      <option value="10000">All</option>
                    </Form.Select>
                  </Form.Group>
                  <div className="pagination-custom">
                    <Pagination className="justify-content-end">
                      <Pagination.Item
                        onClick={() => handleGoToPreviousPages()}
                      >
                        <img src={Common.DoubleArrowLeft} alt="arrow" />
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1,
                          )
                        }
                      >
                        <img
                          src={EmployeeIcons.ArrowDownBlue}
                          className="rotate-left"
                          alt="arrow"
                        />
                      </Pagination.Item>

                      {pages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(page + 1)}
                          className={
                            page + 1 === currentPage ? "active-page" : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}

                      <Pagination.Item
                        onClick={() =>
                          handlePageChange(
                            currentPage < totalPages
                              ? currentPage + 1
                              : totalPages,
                          )
                        }
                      >
                        <img
                          src={EmployeeIcons.ArrowDownBlue}
                          className="rotate-right"
                          alt="arrow"
                        />
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() => {
                          handleGoToNextPages();
                        }}
                      >
                        <img src={Common.DoubleArrowRight} alt="arrow" />
                      </Pagination.Item>
                    </Pagination>
                  </div>
                </div>
              </Col>
            )}
          </div>
        </Row>
      )}
      <Row>
        <Col sm="12">
          <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
            <button
              className="btn primary-button w-25"
              onClick={() => setViewStatement(!viewStatement)}
            >
              {viewStatement ? "Hide Statement" : "View Statement"}
            </button>
            <button
              className="btn primary-button w-25"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ViewVendor;
