import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Legal
import { useGetLegalListQuery } from "../../services/Iqama";
import {
  setLegalLists,
  setLegalLoading,
  setLegalError,
  setLegalTotalRecords,
} from "../../store/slices/iqamaSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const legalChange = useAppSelector((state) => state.common.legalChange);
  // States
  const state = useAppSelector((state) => state.iqama);

  // **************
  // **************
  // Get All Expenses
  // **************
  // **************
  const {
    data: allLegals,
    isLoading: legalLoading,
    isError: legalError,
    refetch: legalRefetch,
  } = useGetLegalListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showLegal || false,
    pageNo: state.legalPageNo,
    pageSize: state.legalPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (legalLoading) {
      dispatch(setLegalLoading(true));
    }
    if (legalError) {
      dispatch(setLegalError("Something went wrong"));
      dispatch(setLegalLoading(false));
    }
    if (allLegals) {
      dispatch(setLegalLists(allLegals.data.legal[0].data || []));
      dispatch(
        setLegalTotalRecords(allLegals.data.legal[0].metadata[0]?.total || 0),
      );
      dispatch(setLegalLoading(false));
    }
  }, [
    accessToken,
    userID,
    allLegals,
    profileData,
    state.legalPageNo,
    state.legalPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    legalRefetch();
  }, [legalChange]);
  return <></>;
};
export default Content;
