import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
// Redux imports
import { Certificate } from "../../interfaces/letters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterCertificateTotalPages,
  setCertificateLoading,
} from "../../store/slices/lettersSlice";
// API imports
import { useGetAllFilteredCertificatesMutation } from "../../services/Letters";
import SelectSearch from "react-select-search";

interface Props {
  show?: boolean;
  setShow?: any;
  setCertificates?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  certificates: Certificate[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  projectId: string;
  certificateType: string;
  certificateStatus: string;
}

const CertificateFilterModal = ({
  show,
  setShow,
  certificates,
  setCertificates,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getAllFilteredCertificates] = useGetAllFilteredCertificatesMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setCertificateLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterCertificatePageNo,
          limit: state.filterCertificatePageSize,
          projectId: filterInput.projectId,
          certificateType: filterInput.certificateType,
          certificateStatus: filterInput.certificateStatus,
        },
      };
      const response = await getAllFilteredCertificates(data).unwrap();
      if (response) {
        setCertificates(response.data.certificates[0].data);
        dispatch(
          setFilterCertificateTotalPages(
            response.data.certificates[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setCertificateLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setCertificateLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterCertificatePageNo,
            limit: state.filterCertificatePageSize,
            empId: query,
            projectId: filterInput.projectId,
            certificateType: filterInput.certificateType,
            certificateStatus: filterInput.certificateStatus,
          },
        };
        const response = await getAllFilteredCertificates(data).unwrap();
        if (response) {
          setCertificates(response.data.certificates[0].data);
          dispatch(
            setFilterCertificateTotalPages(
              response.data.certificates[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setCertificateLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterCertificatePageNo, state.filterCertificatePageSize, query]);

  const handleReset = () => {
    setCertificates(certificates);
    setFilterInput({
      projectId: "",
      certificateType: "",
      certificateStatus: "",
    });
  };

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  const certificateTypesOptions = [
    { name: "Achievement", value: "Achievement" },
    { name: "Best Employee", value: "Best Employee" },
    { name: "Training", value: "Training" },
    { name: "Appreciation", value: "Appreciation" },
    { name: "Experience", value: "Experience" },
    { name: "Service", value: "Service" },
    { name: "Performance", value: "Performance" },
    { name: "Attendance", value: "Attendance" },
    { name: "Others", value: "Others" },
  ];

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Certificates</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="projectId"
                        options={projectOptions}
                        value={filterInput.projectId}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            projectId: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Certificate Type
                    </h4>
                    <Form.Select
                      name="certificateType"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          certificateType: e.target.value,
                        })
                      }
                      value={filterInput.certificateType}
                      className="custom-input border-fill"
                    >
                      <option value="">Select certificate type</option>
                      {certificateTypesOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Certificate Status
                    </h4>
                    <Form.Select
                      name="certificateStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          certificateStatus: e.target.value,
                        })
                      }
                      value={filterInput.certificateStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CertificateFilterModal;
