import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setShowPayslipExportDropdown,
  setPayslipPageSize,
  setPayslipPageNo,
  setPayStartFrom,
  setPayEndTo,
} from "../../../store/slices/employeeSlice";
import { LoaderBlue } from "../../Lotties";
// API Imports
import { EmpDetail, EmployeePayslip } from "../../../interfaces/employee";
import { exportToCsv } from "../../../config";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import Content from "./get-payslips-api";

interface Props {
  empDetail: EmpDetail;
}

const Payslips = ({ empDetail }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.employee);
  const profileData = useAppSelector((state) => state.settings.profileData);
  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<EmployeePayslip[]>([]);
  const totalPages = Math.ceil(
    state.payslipTotalRecords / state.payslipPageSize,
  );
  const totalCount = state.payslipTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.payslipPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);

  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.payslipPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setPayslipPageNo(state.payslipPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.payslipPageNo <= 5) {
      return;
    }
    dispatch(setPayslipPageNo(state.payslipPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setPayslipPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number

    dispatch(setPayslipPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.payslips);
  }, [state.payslips]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Id.",
      "Emp Name",
      "Emp Phone",
      "Gross Salary (SAR)",
      "Deduction (SAR)",
      "Net Salary (SAR)",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => {
    return {
      "Sr. No.": index + 1,
      "Emp Id.": item.empId,
      "Emp Name": item.empName,
      "Emp Phone": item.empPhone,
      "Gross Salary (SAR)": item.grossSalary.toFixed(2),
      "Deduction (SAR)": item.totalDeductions.toFixed(2),
      "Net Salary (SAR)": item.netSalary.toFixed(2),
    };
  });

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Content empId={empDetail.empId ? empDetail.empId : 0} />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="w-100 d-flex align-items-center gap-3">
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    From Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="month"
                      selected={new Date(state.payStartFrom)}
                      onChange={(e: any) =>
                        dispatch(setPayStartFrom(e.toLocaleDateString()))
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select From Date"
                      className="w-100 custom-input"
                      dateFormat="MMMM"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    To Date
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="month"
                      selected={new Date(state.payEndTo)}
                      onChange={(e: any) =>
                        dispatch(setPayEndTo(e.toLocaleDateString()))
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select To Date"
                      className="w-100 custom-input"
                      dateFormat="MMMM"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Dropdown
                  show={state.showPayslipExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowPayslipExportDropdown(
                          !state.showPayslipExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                    style={{ width: "max-content" }}
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showPayslipExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "employeePayroll-list");
                        dispatch(setShowPayslipExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowPayslipExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.payslipLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Payslips List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-3 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? (state.payslipPageNo - 1) *
                                  state.payslipPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-payslip/${item.empId}`,
                                {
                                  state: { data: item, type: "employee" },
                                },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees-payroll/view-payslip/${item.empId}`,
                                {
                                  state: { data: item, type: "employee" },
                                },
                              )
                            }
                          >
                            {print ? twoWords(item.empName) : item.empName}
                          </td>
                          <td>{item.empPhone}</td>
                          <td>{item.grossSalary.toFixed(2)}</td>
                          <td>{item.totalDeductions.toFixed(2)}</td>
                          <td>{item.netSalary.toFixed(2)}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/employees-payroll/view-payslip/${item.empId}`,
                                    {
                                      state: { data: item, type: "employee" },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No payslips found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={state.payslipPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.payslipPageNo > 1 ? state.payslipPageNo - 1 : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {/* render pages here */}
                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.payslipPageNo ? "active-page" : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.payslipPageNo < totalPages
                          ? state.payslipPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default Payslips;
