import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
import { Formik } from "formik";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setAbscondedLoading,
  setFilterAbscondedTotalRecords,
} from "../../store/slices/employeeSlice";
import SelectSearch from "react-select-search";
import { AbscondedEmployee } from "../../interfaces/employee";
// API imports
import { useGetAllFilterAbscondedEmployeesMutation } from "../../services/Employees";

interface Props {
  show?: boolean;
  setShow?: any;
  setAbscondedEmployees?: any;
  setFilterSearch: any;
  query: string;
  abscondedEmployees: AbscondedEmployee[];
  filterInput: FormProps;
  setFilterInput: any;
  filterSearch: boolean;
}

export interface FormProps {
  abscondDateFrom: string;
  abscondDateTo: string;
  abscondStatus: string;
  abscondProject: string;
}

const AbscondedFilterModal = ({
  show,
  setShow,
  abscondedEmployees,
  setAbscondedEmployees,
  setFilterSearch,
  filterSearch,
  query,
  filterInput,
  setFilterInput,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectIds = useAppSelector((state) => state.project.projectIds);
  const [getAllFilterAbscondedEmployees] =
    useGetAllFilterAbscondedEmployeesMutation();
  // Handle Submit Function to filter abscondedEmployees based on the form values
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setAbscondedLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter abscondedEmployees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterAbscondedPageNo,
          limit: state.filterAbscondedPageSize,
          abscondDateFrom: filterInput.abscondDateFrom,
          abscondDateTo: filterInput.abscondDateTo,
          abscondStatus: filterInput.abscondStatus,
          abscondProject: filterInput.abscondProject,
        },
      };
      const response = await getAllFilterAbscondedEmployees(data).unwrap();
      if (response) {
        setAbscondedEmployees(response.data.absconds[0].data);
        dispatch(
          setFilterAbscondedTotalRecords(
            response.data.absconds[0].metadata[0].total,
          ),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setAbscondedLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setAbscondedLoading(true));
      // Filter abscondedEmployees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterAbscondedPageNo,
            limit: state.filterAbscondedPageSize,
            empId: parseInt(query),
            abscondDateFrom: filterInput.abscondDateFrom,
            abscondDateTo: filterInput.abscondDateTo,
            abscondStatus: filterInput.abscondStatus,
            abscondProject: filterInput.abscondProject,
          },
        };
        const response = await getAllFilterAbscondedEmployees(data).unwrap();
        if (response) {
          setAbscondedEmployees(response.data.absconds[0].data);
          dispatch(
            setFilterAbscondedTotalRecords(
              response.data.absconds[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setAbscondedLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterAbscondedPageNo, state.filterAbscondedPageSize, query]);

  const handleReset = () => {
    setAbscondedEmployees(abscondedEmployees);
    setFilterInput({
      abscondDateFrom: "",
      abscondDateTo: "",
      abscondStatus: "",
      abscondProject: "",
    });
  };

  const projectOptions = projectIds.map((employee) => ({
    name: employee.projectId + " - " + employee.projectName, // Use the actual property name for the project name
    value: employee.projectId,
  }));

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Absconded Employees</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center">
                      <SelectSearch
                        name="abscondProject"
                        options={projectOptions}
                        value={filterInput.abscondProject}
                        onChange={(value: any) => {
                          setFilterInput({
                            ...filterInput,
                            abscondProject: value,
                          });
                        }}
                        search={true}
                        placeholder="Select Project Id."
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Abscond Status
                    </h4>
                    <Form.Select
                      name="abscondStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          abscondStatus: e.target.value,
                        })
                      }
                      value={filterInput.abscondStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Abscond From
                    </h4>
                    <Form.Control
                      type="date"
                      name="abscondDateFrom"
                      placeholder="Enter abscond from"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          abscondDateFrom: e.target.value,
                        })
                      }
                      value={filterInput.abscondDateFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Abscond To
                    </h4>
                    <Form.Control
                      type="date"
                      name="abscondDateTo"
                      placeholder="Enter abscond to"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          abscondDateTo: e.target.value,
                        })
                      }
                      value={filterInput.abscondDateTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AbscondedFilterModal;
