import React, { useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { AddEmployeeProps } from "../AddEmployee/add-employee-container";
import { Common, EmployeeIcons, SettingsIcons } from "../../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setCountryCode,
  setShowCountryCodeDropdown,
  setEditBasicInfo,
  setEditLegalInfo,
  setActiveTab,
} from "../../../store/slices/employeeSlice";
import DatePicker from "react-datepicker";
// API
import {
  useUploadImageMutation,
  useEditEmployeeImageMutation,
} from "../../../services/images-upload-service";
import { LoaderBlue, LoaderWhite } from "../../Lotties";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const EditBasicInfo = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState(
    values.profileImage || "",
  );
  const [showOtherCategory, setShowOtherCategory] = React.useState(false);
  // Redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const settings = useAppSelector((state) => state.settings);
  const userID = useAppSelector((state) => state.common.userID);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const [bankName, setBankName] = React.useState("");
  const [bankAccountNo, setBankAccountNo] = React.useState("");
  const [bankAccountTitle, setBankAccountTitle] = React.useState("");
  const [anyChange, setAnyChange] = React.useState(false);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [borderNumber, setBorderNumber] = React.useState("");
  const allVisas = useAppSelector((state) => state.camps.visaList);
  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
  ];

  // get rolename from setting.roles as array of string
  const roles = settings.roles.map((item) => item.roleName);

  const categories =
    values.place !== "Management"
      ? [
          "Driver",
          "Security Guard",
          "Mason",
          "Plumber",
          "Laborer",
          "Electrician",
          "Painter",
          "Carpenter",
          "Cleaner",
          "Welder",
          "Mechanic",
          "Helper",
          "Technician",
          "Chef",
          "Craftsman",
          "Kitchen Worker",
          "Landscaper",
          "Heavy Driver",
          "Other",
        ]
      : roles;

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  const [editEmployeeImage] = useEditEmployeeImageMutation();

  const handleEditUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        empId: values.empCode,
        userId: userID || "",
        payload: formData,
      };

      try {
        const result = await editEmployeeImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  useEffect(() => {
    values.profileImage = imageUploaded;
  }, [imageUploaded]);

  useEffect(() => {
    if (values.category === "Other") {
      setShowOtherCategory(true);
    } else {
      setShowOtherCategory(false);
    }
  }, [values.category]);

  const camp = campIds.find(
    (item) => item.campId === values.campDetails.campId,
  );

  // make a function to push bank info in an array
  const handleBankInfo = () => {
    values.empBankDetails = [
      ...values.empBankDetails,
      {
        empBankName: bankName,
        empBankAccountNo: bankAccountNo,
        empBankAccountTitle: bankAccountTitle,
      },
    ];
    setBankName("");
    setBankAccountNo("");
    setBankAccountTitle("");
  };

  const handleDeleteBankInfo = (index: number) => {
    // Create a shallow copy of the empBankDetails array
    const updatedBankDetails = [...values.empBankDetails];
    // Remove the element at the specified index
    updatedBankDetails.splice(index, 1);
    // Update the state with the modified array
    values.empBankDetails = updatedBankDetails;
    setAnyChange(!anyChange);
  };

  useEffect(() => {
    const updatedBankDetails = [...values.empBankDetails];
    values.empBankDetails = updatedBankDetails;
  }, [anyChange]);

  const visaSelected = allVisas.find((item) =>
    item.requestedVisas.find(
      (item) => item.borderNumber === parseInt(borderNumber),
    ),
  );

  const selectedBorderNumber = visaSelected?.requestedVisas.find(
    (item) => item.borderNumber === parseInt(borderNumber),
  );

  useEffect(() => {
    if (visaSelected && selectedBorderNumber) {
      values.sponsorName = visaSelected.establishmentName;
      values.idNo = visaSelected.establishmentNumber.toString();
      values.nationality = selectedBorderNumber.nationality;
      if (!categories.includes(selectedBorderNumber.occupation)) {
        values.category = "Other";
        values.otherCategory = selectedBorderNumber.occupation;
      } else {
        values.category = selectedBorderNumber.occupation;
      }
      values.religion = selectedBorderNumber.religion;
      values.recruiterDetails.recruiterId = selectedBorderNumber.recruiterId;
      values.visaNo = visaSelected.visaNumber.toString();
    }
  }, [borderNumber]);

  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Basic Information</h3>
      </Col>
      <Col sm="4">
        <Form.Group>
          <div className="d-flex align-items-center mb-2">
            <h4
              className="sub-heading normal-font-weight textBlack mb-0 me-2"
              style={{ fontSize: "18px" }}
            >
              Profile Image
            </h4>
            {imageUploaded ? (
              <label className="d-flex align-items-center justify-content-center cursor-pointer">
                <input
                  type="file"
                  name="profileImage"
                  onChange={handleEditUploadImage}
                  accept=".png, .jpg, .jpeg"
                  className="d-none"
                  multiple={false}
                />
                <div className="edit-icon d-flex align-items-center justify-content-center">
                  <img
                    src={SettingsIcons.EditIcon}
                    width="18px"
                    height="18px"
                    alt="edit"
                  />
                </div>
              </label>
            ) : null}
          </div>
          {imageLoading ? (
            <LoaderBlue height={50} width={50} />
          ) : (
            <>
              {imageUploaded ? (
                <img
                  src={imageUploaded}
                  style={{
                    width: "242px",
                    height: "242px",
                    objectFit: "contain",
                  }}
                  alt="employee-image"
                />
              ) : (
                <Form.Label
                  className={
                    touched.profileImage && errors.profileImage
                      ? "is-invalid profile-image-label cursor-pointer d-flex align-items-center justify-content-center"
                      : "profile-image-label cursor-pointer d-flex align-items-center justify-content-center"
                  }
                >
                  <Form.Control
                    type="file"
                    name="profileImage"
                    onChange={handleUploadImage}
                    accept=".png, .jpg, .jpeg"
                    className="d-none"
                    multiple={false}
                  />
                  <div className="text-center">
                    <div className="add-profile-image d-flex align-items-center justify-content-center mx-auto">
                      <img src={EmployeeIcons.Plus} alt="plus" />
                    </div>
                    <p
                      className="upload-para mt-2 mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Upload PNG & JPG File only
                    </p>
                    <p className="upload-para mt-0 mb-0">
                      Max file size is 5MB
                    </p>
                  </div>
                </Form.Label>
              )}
            </>
          )}
        </Form.Group>
      </Col>
      <Col sm="8">
        <Row>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Emp Code
              </h4>
              <Form.Control
                type="text"
                name="empCode"
                placeholder="Enter employee code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.empCode}
                disabled={true}
                className={
                  touched.empCode && errors.empCode
                    ? "is-invalid custom-input disabled-input border-fill"
                    : "custom-input disabled-input border-fill"
                }
              />
              {touched.empCode && errors.empCode ? (
                <div className="invalid-feedback">{errors.empCode}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Emp Name
              </h4>
              <Form.Control
                type="text"
                name="empName"
                placeholder="Enter employee name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.empName}
                className={
                  touched.empName && errors.empName
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              />
              {touched.empName && errors.empName ? (
                <div className="invalid-feedback">{errors.empName}</div>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Place
              </h4>
              <Form.Select
                name="place"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.place}
                className={
                  touched.place && errors.place
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Working place</option>
                <option value="Management">Management</option>
                <option value="Indoor">Indoor</option>
                <option value="Outdoor">Outdoor</option>
              </Form.Select>

              {touched.place && errors.place ? (
                <div className="invalid-feedback">{errors.place}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Border No.
              </h4>
              <Form.Control
                type="text"
                name="borderNo"
                placeholder="Enter border no."
                onChange={(e) => {
                  //     accept only numbers
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    handleChange(e);
                    setBorderNumber(e.target.value);
                  }
                }}
                onBlur={handleBlur}
                value={values.borderNo}
                className={
                  touched.borderNo && errors.borderNo
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              />
              {touched.borderNo && errors.borderNo ? (
                <div className="invalid-feedback">{errors.borderNo}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Category
              </h4>
              <Form.Select
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.category}
                className={
                  touched.category && errors.category
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Category</option>
                {categories.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>

              {touched.category && errors.category ? (
                <div className="invalid-feedback">{errors.category}</div>
              ) : null}
            </Form.Group>
          </Col>
          {showOtherCategory && (
            <Col sm="6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Other Category
                </h4>
                <Form.Control
                  type="text"
                  name="otherCategory"
                  placeholder="Enter other category name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otherCategory}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          )}
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Nationality
              </h4>
              <Form.Select
                name="nationality"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nationality}
                className={
                  touched.nationality && errors.nationality
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Nationality</option>
                {countryCodes.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>

              {touched.nationality && errors.nationality ? (
                <div className="invalid-feedback">{errors.nationality}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Religion
              </h4>
              <Form.Select
                name="religion"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.religion}
                className={
                  touched.religion && errors.religion
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Religion</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Chritianity</option>
                <option value="Hindu">Hindu</option>
                <option value="Jew">Jew</option>
                <option value="Atheist">Atheist</option>
              </Form.Select>

              {touched.religion && errors.religion ? (
                <div className="invalid-feedback">{errors.religion}</div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col sm="12">
        <Row>
          <Col sm="4">
            <Form.Group controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Mobile No.
              </h4>
              <Form.Group className="position-relative input-design d-flex align-items-center">
                <Dropdown
                  show={state.showCountryCodeDropdown}
                  onToggle={() =>
                    dispatch(
                      setShowCountryCodeDropdown(
                        !state.showCountryCodeDropdown,
                      ),
                    )
                  }
                  className="ms-auto task-detail-menu"
                >
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="p-0 border-0"
                  >
                    <img
                      src={
                        state.countryCode === "+966"
                          ? Common.SA
                          : state.countryCode === "+971"
                            ? Common.UAE
                            : state.countryCode === "+92"
                              ? Common.PK
                              : state.countryCode === "+91"
                                ? Common.IN
                                : state.countryCode === "+98"
                                  ? Common.IR
                                  : state.countryCode === "+884"
                                    ? Common.BD
                                    : state.countryCode === "+93"
                                      ? Common.AF
                                      : state.countryCode === "+63"
                                        ? Common.PH
                                        : Common.UAE
                      }
                      height="18px"
                      width="26px"
                      className="me-2"
                      alt="uae"
                    />
                    <img src={Common.ArrowDown} alt="toggle" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    show={state.showCountryCodeDropdown}
                    className="px-3"
                  >
                    <ul className="countries-list">
                      {countryCodes.map((item, index) => (
                        <li key={index}>
                          <button
                            className="btn p-0 border-0 d-flex align-items-center"
                            type="button"
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                              dispatch(setCountryCode(item.dial_code));
                              dispatch(setShowCountryCodeDropdown(false));
                            }}
                          >
                            <p className="mb-0 para-small">{item.name}</p>
                          </button>
                          <hr className="my-2" />
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 country-code ms-2">{state.countryCode}</p>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  placeholder="Phone number"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.mobileNo}
                  className={
                    touched.mobileNo && errors.mobileNo
                      ? "is-invalid custom-input"
                      : "custom-input"
                  }
                />
              </Form.Group>
              {touched.location && errors.location ? (
                <div className="invalid-feedback">{errors.location}</div>
              ) : null}
              {touched.mobileNo && errors.mobileNo ? (
                <div className="invalid-feedback">{errors.mobileNo}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Date of Birth
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="dob"
                  selected={values.dob ? new Date(values.dob) : null}
                  onChange={(date: any) =>
                    handleChange({
                      target: { name: "dob", value: date },
                    })
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Date of birth"
                  className={
                    errors.dob && touched.dob
                      ? "is-invalid w-100 custom-input"
                      : "w-100 custom-input"
                  }
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
              {touched.dob && errors.dob ? (
                <div className="invalid-feedback">{errors.dob}</div>
              ) : null}
            </Form.Group>
          </Col>
          {values.nationality !== "Saudi Arabia" && (
            <>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Camp Id
                  </h4>
                  <Form.Select
                    name="campDetails.campId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.campDetails.campId}
                    className={
                      touched.campId && errors.campId
                        ? "is-invalid custom-input border-fill"
                        : "custom-input border-fill"
                    }
                  >
                    <option value="">Select Camp Id</option>
                    <option value="Out of Camp">Out of Camp</option>
                    <option value="Clients Camp">Clients Camp</option>
                    {campIds.map((item, index) => (
                      <option key={index} value={item.campId}>
                        {item.campId}
                      </option>
                    ))}
                  </Form.Select>

                  {touched.campId && errors.campId ? (
                    <div className="invalid-feedback">{errors.campId}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Camp Name
                  </h4>
                  <Form.Control
                    type="text"
                    name="homeCountryNumber"
                    placeholder="Enter camp Name"
                    disabled={true}
                    value={camp?.campName || values.campDetails.campName}
                    className="custom-input disabled-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Camp City
                  </h4>
                  <Form.Control
                    type="text"
                    name="homeCountryNumber"
                    placeholder="Enter camp city"
                    disabled={true}
                    value={camp?.campCity || "Select camp id"}
                    className="custom-input disabled-input border-fill"
                  />
                </Form.Group>
              </Col>
            </>
          )}
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Department
              </h4>
              <Form.Select
                name="department"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.department}
                className={
                  touched.department && errors.department
                    ? "is-invalid custom-input border-fill"
                    : "custom-input border-fill"
                }
              >
                <option value="">Select Department</option>
                <option value="General">General</option>
                <option value="Operations">Operations</option>
                <option value="Accounts">Accounts</option>
                <option value="Finance">Finance</option>
                <option value="HR">HR</option>
                <option value="Marketing">Marketing</option>
              </Form.Select>

              {touched.department && errors.department ? (
                <div className="invalid-feedback">{errors.department}</div>
              ) : null}
            </Form.Group>
          </Col>
          {values.nationality !== "Saudi Arabia" && (
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Home Country Contact
                </h4>
                <Form.Control
                  type="text"
                  name="homeCountryNumber"
                  placeholder="Enter home country contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.homeCountryNumber}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </Col>
      <Col sm="12">
        <h3 className="sub-heading">Bank Information</h3>
      </Col>
      <Col sm="12">
        <div>
          {values.empBankDetails.length > 0 && (
            <div className="table-responsive mb-3">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Bank Name</th>
                    <th>Bank A/C No. OR IBAN</th>
                    <th>Bank Account Title</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {values.empBankDetails.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.empBankName}</td>
                      <td>{item.empBankAccountNo}</td>
                      <td>{item.empBankAccountTitle}</td>
                      <td>
                        <button
                          className="btn p-0 border-0"
                          type="button"
                          onClick={() => handleDeleteBankInfo(index)}
                        >
                          <img
                            src={SettingsIcons.Delete}
                            width="24px"
                            height="24px"
                            alt="edit-user"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Col>
      <Col sm="12">
        <Row>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Bank Name
              </h4>
              <Form.Control
                type="text"
                name="bankName"
                placeholder="Enter bank name"
                onChange={(e) => setBankName(e.target.value)}
                onBlur={handleBlur}
                value={bankName}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Bank A/C No. OR IBAN
              </h4>
              <Form.Control
                type="text"
                name="bankAccountNo"
                placeholder="Enter account number"
                onChange={(e) => setBankAccountNo(e.target.value)}
                onBlur={handleBlur}
                value={bankAccountNo}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Bank Account Title
              </h4>
              <Form.Control
                type="text"
                name="bankAccountTitle"
                placeholder="Enter account title"
                onChange={(e) => setBankAccountTitle(e.target.value)}
                onBlur={handleBlur}
                value={bankAccountTitle}
                className="custom-input border-fill"
              />
            </Form.Group>
          </Col>
          <Col sm="12">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="button"
              onClick={handleBankInfo}
              style={{ width: "15%" }}
            >
              Add More
            </button>
          </Col>
        </Row>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4">
          <button
            className="btn primary-button normal-font-weight me-3"
            type="submit"
            style={{ width: "15%" }}
          >
            {state.empLoading ? (
              <LoaderWhite height={30} width={30} />
            ) : (
              "Update"
            )}
          </button>
          <button
            className="btn primary-button normal-font-weight"
            onClick={() => {
              dispatch(setEditBasicInfo(false));
              dispatch(setEditLegalInfo(true));
              dispatch(setActiveTab("Legal Details"));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            Next <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default EditBasicInfo;
