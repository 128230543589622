import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../assets/images";

interface InvoiceProps {
  data: {
    discount: number;
    amount: number;
    category: string;
    quantity: number;
    categoryPrice: number;
    discountType: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    discount: number;
    amount: number;
    category: string;
    quantity: number;
    categoryPrice: number;
    discountType: string;
  }) => void;
  uniqueId: string;
}

const InvoiceCategoriesSet: React.FC<InvoiceProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const [discount, setDiscount] = useState(data.discount.toString());
  const [amount, setAmount] = useState(data.amount.toString());
  const [category, setCategory] = useState(data.category);
  const [quantity, setQuantity] = useState(data.quantity.toString());
  const [categoryPrice, setCategoryPrice] = useState(
    data.categoryPrice.toString(),
  );
  const [discountType, setDiscountType] = useState(data.discountType);

  const handleInputChange = () => {
    onInputChange({
      discount: parseFloat(discount),
      amount: parseFloat(amount),
      category,
      quantity: parseFloat(quantity),
      categoryPrice: parseFloat(categoryPrice),
      discountType,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [discount, amount, category, quantity, categoryPrice, discountType]);

  useEffect(() => {
    if (categoryPrice && quantity) {
      if (discount) {
        if (discountType === "percent") {
          const amount =
            parseFloat(categoryPrice) * parseFloat(quantity) -
            (parseFloat(categoryPrice) *
              parseFloat(quantity) *
              parseFloat(discount)) /
              100;
          setAmount(amount.toString());
        } else {
          const amount =
            parseFloat(categoryPrice) * parseFloat(quantity) -
            parseFloat(discount);
          setAmount(amount.toString());
        }
      } else {
        const amount = parseFloat(categoryPrice) * parseFloat(quantity);
        setAmount(amount.toString());
      }
    }
  }, [categoryPrice, quantity, discount, discountType]);

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <Form.Control
                  type="text"
                  name="category"
                  placeholder="Enter category"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Price
                </h4>
                <Form.Control
                  type="text"
                  name="categoryPrice"
                  placeholder="Enter category price"
                  onChange={(e) => {
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCategoryPrice(e.target.value);
                    }
                  }}
                  value={categoryPrice}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Quantity
                </h4>
                <Form.Control
                  type="text"
                  name="quantity"
                  placeholder="Enter quantity"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setQuantity(e.target.value);
                    }
                  }}
                  value={quantity}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Discount
                </h4>
                <Form.Control
                  type="text"
                  name="discount"
                  placeholder="Enter discount"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setDiscount(e.target.value);
                    }
                  }}
                  value={discount}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Type
                </h4>
                <Form.Select
                  name="discountType"
                  onChange={(e) => setDiscountType(e.target.value)}
                  value={discountType}
                  className="custom-input border-fill"
                >
                  <option value="percent">%</option>
                  <option value="sar">SAR</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Total
                </h4>
                <Form.Control
                  type="text"
                  name="amount"
                  placeholder="Enter amount"
                  value={amount}
                  disabled={true}
                  className="custom-input disabled-input border-fill"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>

        <Col sm="1">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceCategoriesSet;
