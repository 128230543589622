import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowVendorBillExportDropdown,
  setVendorBillPageSize,
  setVendorBillPageNo,
  setBillStartFrom,
  setBillEndTo,
  setSearchIds,
} from "../../store/slices/vendorsSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { VendorBillFinance } from "../../interfaces/account-charts";
import { exportToCsv } from "../../config";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import SelectSearch from "react-select-search";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import PayBillModal from "../Modals/pay-bill-modal";
import Content from "./get-finance-bills-api";

const VendorBills = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.vendors);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const vendorIds = useAppSelector((state) => state.vendors.vendorIds);
  const billIds = useAppSelector((state) => state.vendors.billIds);
  const billChange = useAppSelector((state) => state.common.billChange);
  const [showPaySalaryModal, setShowPaySalaryModal] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [status, setStatus] = React.useState("");
  const [checked, setChecked] = useState<{
    [key: string]: boolean;
  }>({});
  // Options
  const options = vendorIds.map((item) => ({
    name: item.vendorId + " - " + item.vendorName,
    value: item.vendorId,
  }));
  const projectOptions = billIds.map((item) => ({
    name: item.billNo + " - " + item.vendor,
    value: item.billNo,
  }));

  // Add custom options for "management" and "idol employee"
  projectOptions.splice(0, 0, { name: "All", value: "All" });
  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<VendorBillFinance[]>([]);
  const totalPages = Math.ceil(
    state.vendorBillTotalRecords / state.vendorBillPageSize,
  );
  const totalCount = state.vendorBillTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.vendorBillPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);

  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.vendorBillPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setVendorBillPageNo(state.vendorBillPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.vendorBillPageNo <= 5) {
      return;
    }
    dispatch(setVendorBillPageNo(state.vendorBillPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setVendorBillPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number

    dispatch(setVendorBillPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.vendorBill);
  }, [state.vendorBill]);

  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Bill Date",
      "Bill No.",
      "Vendor Name",
      "Due Date",
      "Total Amount",
      "Approval Status",
      "Bill Status",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => {
    return {
      "Sr. No.": index + 1,
      "Bill Date": item.billDate,
      "Bill No.": item.billNo,
      "Vendor Name": item.vendor,
      "Due Date": item.dueDate,
      "Total Amount": item.totalAmount,
      "Approval Status": item.status,
      "Bill Status": item.billStatus,
    };
  });

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const handleProjectId = (value: any) => {
    const oldArray =
      state.searchIds[0] === "All"
        ? []
        : !state.searchIds[0].includes("PR")
          ? []
          : state.searchIds;
    if (value === "All") {
      dispatch(setSearchIds([value]));
      return;
    }
    if (state.searchIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Project Id already added");
      setShow(true);
      return;
    }
    dispatch(setSearchIds([...oldArray, value]));
  };

  const handleEmployeeId = (value: any) => {
    const oldArray = state.searchIds[0].includes("All")
      ? []
      : state.searchIds[0].includes("PR")
        ? []
        : state.searchIds;
    if (state.searchIds.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Employee already added");
      setShow(true);
      return;
    }
    dispatch(setSearchIds([...oldArray, value]));
  };

  const allEmps = tableData.tableData.filter(
    (item) => item.status === "Approved",
  );
  const handleSelectAll = () => {
    const allEmpIds = allEmps.map((item) => item.billNo);
    const allEmpIdsChecked = allEmpIds.every((billNo) => checked[billNo]);
    if (allEmpIdsChecked) {
      const newChecked = { ...checked };
      allEmpIds.forEach((billNo) => {
        newChecked[billNo] = false;
      });
      setChecked(newChecked);
    } else {
      const newChecked = { ...checked };
      allEmpIds.forEach((billNo) => {
        newChecked[billNo] = true;
      });
      setChecked(newChecked);
    }
  };

  const handleSingleSelect = (empId: string) => {
    setChecked({
      ...checked,
      [empId]: !checked[empId],
    });
  };

  const allEmpIds = allEmps.map((item) => item.billNo);

  const allEmpIdsChecked = allEmpIds.every((billNo) => checked[billNo]);

  const selectedPayload = tableData.tableData.filter(
    (item) => checked[item.billNo],
  );

  const billIDs = selectedPayload.map((item) => item._id);

  const totalAmount = selectedPayload.reduce(
    (acc, item) => acc + item.totalAmount,
    0,
  );

  console.log(selectedPayload);

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      <PayBillModal
        show={showPaySalaryModal}
        setShow={setShowPaySalaryModal}
        billIds={billIDs}
        payBillLoading={state.payBillLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        billChange={billChange}
        status={status}
        totalAmount={totalAmount}
      />
      <Content />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Bills Payable</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="w-100 d-flex align-items-center gap-3">
                <Form.Group className=" w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill ID/Type
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                    <SelectSearch
                      name="emp"
                      options={projectOptions}
                      value={state.searchIds}
                      onChange={(value: any) => handleProjectId(value)}
                      search={true}
                      placeholder="Select Project Id"
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Or
                  </h4>
                </Form.Group>
                <Form.Group className="w-25" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Select Vendor
                  </h4>
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        handleEmployeeId(value);
                      }}
                      value={state.searchIds}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Dropdown show={statusDropdown} className="position-relative">
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      setStatusDropdown(!statusDropdown);
                    }}
                    className="dropdown-toggle-white  dropdown-toggle-blue"
                    style={{ width: "max-content" }}
                  >
                    Actions
                    <img
                      src={EmployeeIcons.ArrowDownWhite}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={statusDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Paid");
                        setShowPaySalaryModal(true);
                        setStatusDropdown(false);
                      }}
                    >
                      Pay
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Hold");
                        setStatusDropdown(false);
                        setShowPaySalaryModal(true);
                      }}
                    >
                      Hold
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setStatus("Cancelled");
                        setStatusDropdown(false);
                        setShowPaySalaryModal(true);
                      }}
                    >
                      Cancel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  show={state.showVendorBillExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowVendorBillExportDropdown(
                          !state.showVendorBillExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                    style={{ width: "max-content" }}
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showVendorBillExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "employeePayroll-list");
                        dispatch(setShowVendorBillExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowVendorBillExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <div className="w-100 d-flex align-items-center gap-3 mt-4">
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                From Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.billStartFrom)}
                  onChange={(e: any) =>
                    dispatch(setBillStartFrom(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select From Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
            <Form.Group className="" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                To Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="month"
                  selected={new Date(state.billEndTo)}
                  onChange={(e: any) =>
                    dispatch(setBillEndTo(e.toLocaleDateString()))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select To Date"
                  className="w-100 custom-input"
                  dateFormat="MMMM"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <Form.Group
            className="mt-5 d-flex align-items-center gap-4"
            controlId="formBasicEmail"
          >
            <h4
              className="sub-heading normal-font-weight textBlack mb-0"
              style={{ fontSize: "18px" }}
            >
              Selected Bill/Vendor Ids:
            </h4>

            <div className="d-flex align-items-center gap-2 overflow-auto">
              {state.searchIds.map((item, index) => (
                <div
                  key={index}
                  className="selected-projects primary-button d-flex align-items-center gap-3 py-0 px-4"
                  style={{
                    height: "40px",
                    borderRadius: "20px",
                    boxShadow: "none",
                  }}
                >
                  <h4 className="mb-0" style={{ fontSize: "16px" }}>
                    {item}
                  </h4>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => {
                      if (state.searchIds.length === 1) {
                        dispatch(setSearchIds(["All"]));
                        return;
                      }
                      const newProjects = state.searchIds.filter(
                        (project) => project !== item,
                      );
                      dispatch(setSearchIds(newProjects));
                    }}
                  >
                    <img
                      src={Common.Cross}
                      style={{ width: "22px", height: "22px" }}
                      alt="close"
                    />
                  </button>
                </div>
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col sm="12">
          {state.vendorBillLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData?.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Payable Bills List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-3 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {print ? null : (
                          <th className="border-0">
                            <Form.Check
                              type="checkbox"
                              id="tableCheckbox"
                              className="table-checkbox justify-content-center"
                              name="hideLetterHead"
                              checked={
                                //   if all the checkboxes are checked then return true
                                allEmpIdsChecked
                              }
                              onChange={() => handleSelectAll()}
                            />
                          </th>
                        )}
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          {print ? null : item.status === "Approved" ? (
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="tableCheckbox" // Add an id to target the checkbox
                                className="table-checkbox justify-content-center"
                                name="hideLetterHead"
                                checked={checked[item.billNo]}
                                onChange={() => handleSingleSelect(item.billNo)}
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            {totalCount > 0
                              ? (state.vendorBillPageNo - 1) *
                                  state.vendorBillPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td>
                            {item.billDate
                              ? new Date(item.billDate).toLocaleDateString()
                              : ""}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/billing/view-finance-bill/${item.billNo}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {item.billNo}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/billing/view-finance-bill/${item.billNo}`,
                                {
                                  state: { data: item },
                                },
                              )
                            }
                          >
                            {print ? twoWords(item.vendor) : item.vendor}
                          </td>
                          <td>
                            {item.dueDate
                              ? new Date(item.dueDate).toLocaleDateString()
                              : "N/A"}
                          </td>
                          <td>{item.totalAmount}</td>
                          <td>{item.status}</td>
                          <td>{item.billStatus}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/billing/view-finance-bill/${item.billNo}`,
                                    {
                                      state: { data: item },
                                    },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No payable bills found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={state.vendorBillPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.vendorBillPageNo > 1
                          ? state.vendorBillPageNo - 1
                          : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {/* render pages here */}
                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.vendorBillPageNo ? "active-page" : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.vendorBillPageNo < totalPages
                          ? state.vendorBillPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default VendorBills;
