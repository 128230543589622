import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ToastErrorComponent } from "../Toasts";
import {
  setAddOfferLetterLoading,
  setCountryCodeDropdown,
  setCountryCode,
} from "../../store/slices/lettersSlice";
import { setOfferLetterChange } from "../../store/slices/commonSlice";
import { useAddInterviewerLetterMutation } from "../../services/Letters";
import { Formik } from "formik";
import * as Yup from "yup";
import EvaluationDetail from "./evaluation-details";
import ApprovalsSet from "../Employees/approvals-set";

export interface AddCandidateProps {
  requestee: {
    name: string;
    empId: string;
    withinCompany: boolean;
  };
  department: string;
  interviewDate: string;
  mobileNo: string;
  location: string;
  dob: string;
  religion: string;
  iqamaNumber: string;
  homeCountryContact: string;
  role: string;
  intervieweeName: string;
  interviewConducted: string;
  otherCategory: string;
  companyName: string;
  nationality: string;
  requesteeType: string;
  passportNumber: string;
  borderNo: string;
  interviewerDetails: string;
  evaluationDetail: {
    obedient: string;
    behavior: string;
    appearance: string;
    remarks: string;
  };
  language: {
    uId: string;
    selectedLanguage: string;
    languageProficiency: string;
    writingSkills: string;
  }[];
  experience: {
    uId: string;
    region: string;
    basicSalary: string;
    experienceProficiency: string;
    startDate: string;
    endDate: string;
  }[];
  skills: {
    uId: string;
    skillName: string;
    skillProficiency: string;
    notes: string;
  }[];
}

export const initialValues: AddCandidateProps = {
  requestee: {
    name: "",
    empId: "",
    withinCompany: false,
  },
  requesteeType: "",
  department: "",
  interviewDate: "",
  location: "",
  role: "",
  intervieweeName: "",
  passportNumber: "",
  dob: "",
  interviewConducted: "No",
  iqamaNumber: "",
  companyName: "",
  otherCategory: "",
  religion: "",
  homeCountryContact: "",
  borderNo: "",
  mobileNo: "",
  nationality: "",
  interviewerDetails: "",
  evaluationDetail: {
    obedient: "",
    behavior: "",
    appearance: "",
    remarks: "",
  },
  language: [],
  experience: [],
  skills: [],
};

const AddInterviewer = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [showEvaluation, setShowEvaluation] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settings = useAppSelector((state) => state.settings);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const offerLetterChange = useAppSelector(
    (state) => state.common.offerLetterChange,
  );

  const employee = settings.allUsers.find(
    (employee) => employee.employeeId === parseInt(empID),
  );

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // get all userId from languageData array
  const userIds =
    languageData.length > 0 ? languageData.map((data) => data.userId) : [];

  // API call for add salary increment
  const [addInterviewerLetter] = useAddInterviewerLetterMutation();
  const handleSubmit = async (values: AddCandidateProps) => {
    dispatch(setAddOfferLetterLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          requestee: {
            name: values.requestee.name,
            empId: parseInt(values.requestee.empId) || "",
            withinCompany:
              values.requesteeType === "Within Company" ? true : false,
          },
          department: values.department,
          interviewDate: values.interviewDate,
          role: values.role === "Other" ? values.otherCategory : values.role,
          intervieweeName: values.intervieweeName,
          iqamaNumber: values.iqamaNumber,
          dateOfBirth: values.dob,
          companyName: values.companyName,
          religion: values.religion,
          homeCountryContact: values.homeCountryContact,
          interviewConducted:
            values.interviewConducted === "Yes" ? true : false,
          passportNumber: values.passportNumber,
          borderNumber: parseInt(values.borderNo),
          contact: state.countryCode + " " + values.mobileNo,
          nationality: values.nationality,
          interviewerDetails: parseInt(empID),
          evaluationDetail: {
            obedient: values.evaluationDetail.obedient,
            behavior: values.evaluationDetail.behavior,
            appearance: values.evaluationDetail.appearance,
            remarks: values.evaluationDetail.remarks,
          },
          language: values.language,
          experience: values.experience,
          skills: values.skills,
          link: "/initial-evaluation",
          interviewerApprovals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...languageData,
          ],
          ids: [superadmin?._id || "", ...userIds],
        },
      };
      await addInterviewerLetter(data).unwrap();
      dispatch(setOfferLetterChange(!offerLetterChange));
      navigate("/initial-evaluation");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddOfferLetterLoading(false));
    }
  };

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
  ];

  // get rolename from setting.roles as array of string
  const roles = settings.roles.map((item) => item.roleName);

  const categoriesList = [
    "Driver",
    "Security Guard",
    "Mason",
    "Plumber",
    "Laborer",
    "Electrician",
    "Painter",
    "Carpenter",
    "Cleaner",
    "Welder",
    "Mechanic",
    "Helper",
    "Technician",
    "Chef",
    "Craftsman",
    "Kitchen Worker",
    "Landscaper",
    "Heavy Driver",
    "Other",
  ];

  const categories = [...roles, ...categoriesList];

  const supervisorOptions = settings.allUsers.map((employee) => ({
    name: employee.employeeId, // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  // Validation Schema
  const validationSchema = Yup.object().shape({
    intervieweeName: Yup.string().required("Required"),
    department: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
  });

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Add Candidate</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showEvaluation ? (
                  <Row>
                    <Col sm="12">
                      <h4 className="sub-heading mb-2">Candidate Info</h4>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Candidate Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="intervieweeName"
                          placeholder="Enter candidate name"
                          onChange={handleChange}
                          value={values.intervieweeName}
                          onBlur={handleBlur}
                          className={
                            touched.intervieweeName && errors.intervieweeName
                              ? "is-invalid custom-input border-fill"
                              : "custom-input border-fill"
                          }
                        />
                        {touched.intervieweeName && errors.intervieweeName ? (
                          <div className="invalid-feedback">
                            {errors.intervieweeName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Company Name
                        </h4>
                        <Form.Select
                          name="companyName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName}
                          className="custom-input border-fill"
                        >
                          <option value="">Enter Company Name</option>
                          <option value="Senyar Arabian">Senyar Arabian</option>
                          <option value="Star Sky">Star Sky</option>
                          <option value="Smart Enjaaz">Smart Enjaaz</option>
                          <option value="Kafalah Guys">Kafalah Guys</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Interview Date
                        </h4>
                        <Form.Control
                          type="date"
                          name="interviewDate"
                          placeholder="Enter interview date"
                          onChange={handleChange}
                          value={values.interviewDate}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Date of Birth
                        </h4>
                        <Form.Control
                          type="date"
                          name="dob"
                          placeholder="Enter date of birth"
                          onChange={handleChange}
                          value={values.dob}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Border Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="borderNo"
                          placeholder="Enter border number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.borderNo}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Passport Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="passportNumber"
                          placeholder="Enter passport number"
                          onChange={handleChange}
                          value={values.passportNumber}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Home Country Contact
                        </h4>
                        <Form.Control
                          type="text"
                          name="homeCountryContact"
                          placeholder="Enter home country contact"
                          onChange={handleChange}
                          value={values.homeCountryContact}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Iqama Number
                        </h4>
                        <Form.Control
                          type="text"
                          name="iqamaNumber"
                          placeholder="Enter iqama number"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.iqamaNumber}
                          onBlur={handleBlur}
                          className="custom-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Religion
                        </h4>
                        <Form.Select
                          name="religion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.religion}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Religion</option>
                          <option value="Islam">Islam</option>
                          <option value="Christianity">Chritianity</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Jew">Jew</option>
                          <option value="Atheist">Atheist</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Nationality
                        </h4>
                        <Form.Select
                          name="nationality"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationality}
                          className={
                            touched.nationality && errors.nationality
                              ? "is-invalid custom-input border-fill"
                              : "custom-input border-fill"
                          }
                        >
                          <option value="">Select Nationality</option>
                          {countryCodes.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>

                        {touched.nationality && errors.nationality ? (
                          <div className="invalid-feedback">
                            {errors.nationality}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Mobile No.
                        </h4>
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <Dropdown
                            show={state.showCountryCodeDropdown}
                            onToggle={() =>
                              dispatch(
                                setCountryCodeDropdown(
                                  !state.showCountryCodeDropdown,
                                ),
                              )
                            }
                            className="ms-auto task-detail-menu"
                          >
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="p-0 border-0"
                            >
                              <img
                                src={
                                  state.countryCode === "+966"
                                    ? Common.SA
                                    : state.countryCode === "+971"
                                      ? Common.UAE
                                      : state.countryCode === "+92"
                                        ? Common.PK
                                        : state.countryCode === "+91"
                                          ? Common.IN
                                          : state.countryCode === "+98"
                                            ? Common.IR
                                            : state.countryCode === "+884"
                                              ? Common.BD
                                              : state.countryCode === "+93"
                                                ? Common.AF
                                                : state.countryCode === "+63"
                                                  ? Common.PH
                                                  : Common.UAE
                                }
                                height="18px"
                                width="26px"
                                className="me-2"
                                alt="uae"
                              />
                              <img src={Common.ArrowDown} alt="toggle" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              show={state.showCountryCodeDropdown}
                              className="px-3"
                            >
                              <ul className="countries-list">
                                {countryCodes.map((item, index) => (
                                  <li key={index}>
                                    <button
                                      className="btn p-0 border-0 d-flex align-items-center"
                                      type="button"
                                      style={{ fontSize: "12px" }}
                                      onClick={() => {
                                        dispatch(
                                          setCountryCode(item.dial_code),
                                        );
                                        dispatch(setCountryCodeDropdown(false));
                                      }}
                                    >
                                      <p className="mb-0 para-small">
                                        {item.name}
                                      </p>
                                    </button>
                                    <hr className="my-2" />
                                  </li>
                                ))}
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className="mb-0 country-code ms-2">
                            {state.countryCode}
                          </p>
                          <Form.Control
                            type="text"
                            name="mobileNo"
                            placeholder="Phone number"
                            onChange={(e) => {
                              //     accept only numbers
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                handleChange(e);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.mobileNo}
                            className={
                              touched.mobileNo && errors.mobileNo
                                ? "is-invalid custom-input"
                                : "custom-input"
                            }
                          />
                        </Form.Group>
                        {touched.location && errors.location ? (
                          <div className="invalid-feedback">
                            {errors.location}
                          </div>
                        ) : null}
                        {touched.mobileNo && errors.mobileNo ? (
                          <div className="invalid-feedback">
                            {errors.mobileNo}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Department *
                        </h4>
                        <Form.Select
                          name="department"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.department}
                          className={
                            touched.department && errors.department
                              ? "is-invalid custom-input border-fill"
                              : "custom-input border-fill"
                          }
                        >
                          <option value="">Select Department</option>
                          <option value="General">General</option>
                          <option value="Operations">Operations</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Finance">Finance</option>
                          <option value="HR">HR</option>
                          <option value="Marketing">Marketing</option>
                        </Form.Select>

                        {touched.department && errors.department ? (
                          <div className="invalid-feedback">
                            {errors.department}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Role *
                        </h4>
                        <Form.Select
                          name="role"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.role}
                          className={
                            touched.role && errors.role
                              ? "is-invalid custom-input border-fill"
                              : "custom-input border-fill"
                          }
                        >
                          <option value="">Select Role</option>
                          {categories.map((item: string, index: number) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Select>

                        {touched.role && errors.role ? (
                          <div className="invalid-feedback">{errors.role}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {values.role === "Other" && (
                      <Col sm="4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Other Category Name
                          </h4>
                          <Form.Control
                            type="text"
                            name="otherCategory"
                            placeholder="Enter other category"
                            onChange={handleChange}
                            value={values.otherCategory}
                            onBlur={handleBlur}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col sm="12">
                      <h4 className="sub-heading mb-2">Requestee Detail</h4>
                    </Col>
                    <Col md="4">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Requestee Type
                        </h4>
                        <Form.Select
                          name="requesteeType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.requesteeType}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Requestee Type</option>
                          <option value="Within Company">Within Company</option>
                          <option value="Out of Company">Out of Company</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {values.requesteeType && (
                      <>
                        {values.requesteeType === "Within Company" && (
                          <Col sm="4">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <h4
                                className="sub-heading normal-font-weight textBlack"
                                style={{ fontSize: "18px" }}
                              >
                                Emp Code
                              </h4>
                              <Form.Control
                                type="text"
                                name="requestee.empId"
                                placeholder="Enter employee code"
                                onChange={(e) => {
                                  //     accept only numbers
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                value={values.requestee.empId}
                                onBlur={handleBlur}
                                className="custom-input border-fill"
                              />
                            </Form.Group>
                          </Col>
                        )}
                        <Col sm="4">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Name
                            </h4>
                            <Form.Control
                              type="text"
                              name="requestee.name"
                              placeholder="Enter requestee name"
                              onChange={handleChange}
                              value={values.requestee.name}
                              onBlur={handleBlur}
                              className="custom-input border-fill"
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col sm="12">
                      <h4 className="sub-heading mb-2">Interviewer Detail</h4>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Emp Code *
                        </h4>
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <SelectSearch
                            name="empId"
                            options={supervisorOptions}
                            value={empID}
                            onChange={(value: any) => {
                              setEmpID(value);
                            }}
                            search={true}
                            required={true}
                            placeholder="Select Emp Code"
                          />
                          <img src={Common.ArrowDown} alt="message" />
                        </Form.Group>
                        {touched.interviewerDetails &&
                        errors.interviewerDetails ? (
                          <div className="invalid-feedback">
                            {errors.interviewerDetails}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Emp Name
                        </h4>
                        <Form.Control
                          type="text"
                          name="empName"
                          placeholder="Enter emp name"
                          value={
                            employee
                              ? employee.firstName + " " + employee.lastName
                              : "Select Emp Id"
                          }
                          disabled={true}
                          className="custom-input disabled-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Role
                        </h4>
                        <Form.Control
                          type="text"
                          name="role"
                          placeholder="Enter role"
                          disabled={true}
                          value={employee?.role || "Select Emp Id"}
                          className="custom-input disabled-input border-fill"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="text-end mt-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={() => {
                            if (values.intervieweeName === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Candidate Name is required");
                              setShow(true);
                              return;
                            }
                            if (values.companyName === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Company Name is required");
                              setShow(true);
                              return;
                            }
                            if (values.department === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Department is required");
                              setShow(true);
                              return;
                            }
                            if (values.role === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Role is required");
                              setShow(true);
                              return;
                            }
                            if (empID === "") {
                              window.scrollTo(0, 0);
                              setErrorContent("Emp Code is required");
                              setShow(true);
                              return;
                            }
                            setShowEvaluation(true);
                          }}
                          style={{ width: "15%" }}
                        >
                          Next <img src={Common.ArrowRightWhite} alt="next" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <EvaluationDetail
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      setShowEvaluation={setShowEvaluation}
                      touched={touched}
                      edit={false}
                      loading={state.addOfferLetterLoading}
                      handleAddApprovalsSet={handleAddLanguageSet}
                      approvalsSets={languageSets}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default AddInterviewer;
