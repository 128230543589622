import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { VendorBillFinance } from "../../interfaces/account-charts";
import { Common, EmployeeIcons } from "../../assets/images";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import PayBillModal from "../Modals/pay-bill-modal";
import { useAppSelector } from "../../store/hooks";

const ViewFinanceBill = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: VendorBillFinance = location.state.data;
  const billChange = useAppSelector((state) => state.common.billChange);
  const state = useAppSelector((state) => state.vendors);
  const [showPaySalaryModal, setShowPaySalaryModal] = useState(false);
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [status, setStatus] = React.useState("");
  return (
    <>
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      <PayBillModal
        show={showPaySalaryModal}
        setShow={setShowPaySalaryModal}
        billIds={[statementData.billNo]}
        payBillLoading={state.payBillLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        billChange={billChange}
        status={status}
        totalAmount={statementData.totalAmount}
      />
      <div className=" mt-2 section px-4 py-4">
        <Row>
          <Col sm="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h1 className="heading mb-0">Bill Detail</h1>
              </div>
              <Dropdown show={statusDropdown} className="position-relative">
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setStatusDropdown(!statusDropdown);
                  }}
                  className="dropdown-toggle-white  dropdown-toggle-blue"
                  style={{ width: "max-content" }}
                >
                  Status
                  <img
                    src={EmployeeIcons.ArrowDownWhite}
                    className="ms-2"
                    alt="down-arrow"
                  />
                </button>
                <Dropdown.Menu
                  className="mt-1 custom-scrollbar"
                  show={statusDropdown}
                >
                  <Dropdown.Item
                    onClick={() => {
                      setStatus("Paid");
                      setShowPaySalaryModal(true);
                      setStatusDropdown(false);
                    }}
                  >
                    Paid
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStatus("Hold");
                      setStatusDropdown(false);
                      setShowPaySalaryModal(true);
                    }}
                  >
                    Hold
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStatus("Cancelled");
                      setStatusDropdown(false);
                      setShowPaySalaryModal(true);
                    }}
                  >
                    Cancelled
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billNo}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billDate
                      ? new Date(statementData.billDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Vendor Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.vendor}
                  </h5>
                </Form.Group>
              </Col>

              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Due Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.dueDate
                      ? new Date(statementData.dueDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>

              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Amount
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billStatus}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Bill Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.billStatus}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            {statementData.listItems.length > 0 ? (
              <>
                <h2 className="sub-heading mb-4">List Items:</h2>
                <div className="table-responsive mb-3">
                  <table className="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Item Description</th>
                        {/*<th>Account</th>*/}
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Discount</th>
                        <th>Tax %</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statementData.listItems.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.description}</td>
                          {/*<td>{item.account}</td>*/}
                          <td>{item.quantity}</td>
                          <td>{item.rate}</td>
                          <td>{item.discount + " " + item.discountType}</td>
                          <td>{item.taxPercentage}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <h1 className="heading">No list items</h1>
              </div>
            )}
          </Col>

          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ViewFinanceBill;
