// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsdndINTEK2It2Nlgs-wbn3qm3Sg6oqGI",
  authDomain: "senyar-arabian-dashboard.firebaseapp.com",
  projectId: "senyar-arabian-dashboard",
  storageBucket: "senyar-arabian-dashboard.appspot.com",
  messagingSenderId: "406049637153",
  appId: "1:406049637153:web:9f2c6931bae9ee10c97460",
  measurementId: "G-QR5966DJ3K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
