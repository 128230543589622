import React from "react";
import InternalLayout from "../components/InternalLayout";
import AbscondingComponent from "../components/Absconding";
import withAuth from "../config/private-route";

const Absconding = () => {
  return (
    <InternalLayout>
      <AbscondingComponent />
    </InternalLayout>
  );
};
export default withAuth(Absconding);
