import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetCreatePayrollListQuery } from "../../services/EmployeePayroll";
import {
  setEmployeePayrollList,
  setEmployeePayrollListLoading,
  setEmployeePayrollListError,
  setEmployeePayrollListTotalPages,
} from "../../store/slices/employeePayrollSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const actualSheetChange = useAppSelector(
    (state) => state.common.actualSheetChange,
  );
  // States
  const state = useAppSelector((state) => state.employeePayroll);

  // **************
  // **************
  // Get All Camps
  // **************
  // **************
  const {
    data: allEmployeePayrolls,
    isLoading: employeePayrollListLoading,
    isError: employeePayrollListError,
    refetch: employeePayrollListRefetch,
  } = useGetCreatePayrollListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    pageNo: state.employeePayrollListPageNo,
    pageSize: state.employeePayrollListPageSize,
    fromDate: new Date(state.fromDate).toLocaleDateString(),
    toDate: new Date(state.toDate).toLocaleDateString(),
    empIds: state.empIds,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (employeePayrollListLoading) {
      dispatch(setEmployeePayrollListLoading(true));
    }
    if (employeePayrollListError) {
      dispatch(setEmployeePayrollListError("Something went wrong"));
      dispatch(setEmployeePayrollListLoading(false));
    }
    if (allEmployeePayrolls) {
      dispatch(
        setEmployeePayrollList(
          allEmployeePayrolls.data.empPayrollsData[0].data || [],
        ),
      );
      dispatch(
        setEmployeePayrollListTotalPages(
          allEmployeePayrolls.data.empPayrollsData[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setEmployeePayrollListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allEmployeePayrolls,
    profileData,
    state.employeePayrollListPageNo,
    state.employeePayrollListPageSize,
    state.fromDate,
    state.toDate,
    state.empIds,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    employeePayrollListRefetch();
  }, [actualSheetChange, state.fromDate, state.toDate, state.empIds]);
  return <></>;
};
export default Content;
