import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../../assets/images";
import DatePicker from "react-datepicker";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAddBehaviourStatementLoading } from "../../../store/slices/lettersSlice";
import { setBehaviourStatementChange } from "../../../store/slices/commonSlice";
// API Imports
import { useAddBehaviourStatementMutation } from "../../../services/Letters";
import { ToastErrorComponent } from "../../Toasts";
import { LoaderWhite } from "../../Lotties";

const EmployeeWarning = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empId, setEmpId] = useState("");
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [violationType, setViolationType] = useState("");
  const [violationDate, setViolationDate] = useState("");
  const [violationTime, setViolationTime] = useState("");
  const [violationStatement, setViolationStatement] = useState("");
  const [supervisorAgreement, setSupervisorAgreement] = useState(false);
  const [supervisorStatement, setSupervisorStatement] = useState("");
  const [actionTaken, setActionTaken] = useState("");
  const [consequence, setConsequence] = useState("");
  const [actionOther, setActionOther] = useState("");
  const [otherViolation, setOtherViolation] = useState("");
  const state = useAppSelector((state) => state.letters);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const behaviourStatementChange = useAppSelector(
    (state) => state.common.behaviourStatementChange,
  );
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  const handleJoinDateandTime = (date: string, time: string) => {
    const selectedDate = new Date(date) || new Date();
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();

    // Extracting hours and minutes from the time
    const [hours, minutes] = time
      ? time.split(":").map(Number)
      : "00:00".split(":").map(Number);

    // Creating a new Date object with joined date and time
    const joinedDateTime = new Date(year, month, day, hours, minutes);

    return joinedDateTime;
  };

  const [addBehaviourStatement] = useAddBehaviourStatementMutation();

  const handleSave = async () => {
    dispatch(setAddBehaviourStatementLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: parseInt(empId),
          violationType:
            violationType === "Other" ? otherViolation : violationType,
          violationDate: handleJoinDateandTime(violationDate, violationTime),
          violationStatement: violationStatement,
          supervisorToEmployeeStatement: supervisorAgreement,
          reasonToAgree: supervisorStatement,
          actionsTaken: actionTaken === "Other" ? actionOther : actionTaken,
          conscequences: consequence,
          link: `/employees/employee-behaviour-statement`,
        },
      };
      await addBehaviourStatement(data).unwrap();
      dispatch(setBehaviourStatementChange(!behaviourStatementChange));
      navigate("/employees/employee-behaviour-statement");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddBehaviourStatementLoading(false));
    }
  };

  const actions = ["Warning", "Probation", "Suspension", "Dismissal", "Other"];

  const vTypes = [
    "Attendance",
    "Failure to follow instructions",
    "Unsatisfactory work quality",
    "Violation of safety rules",
    "Tardiness or early quit",
    "Rudeness to employees or customers",
    "Carelessness",
    "Working on personal matters",
    "Violation of company policies or procedures",
    "Willful damage to material or equipment",
    "Other",
  ];

  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Employee Warning Notice</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Employee Id
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={options}
                value={empId}
                onChange={(e: any) => {
                  setEmpId(e);
                }}
                search={true}
                placeholder="Select Employee"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Types Of Violations:</h4>
            <Row>
              <Col sm="3">
                <Form.Group className="mb-4">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Violation Type:
                  </h4>
                  <Form.Select
                    name="violationType"
                    onChange={(e) => {
                      setViolationType(e.target.value);
                    }}
                    value={violationType}
                    className="custom-input border-fill"
                  >
                    <option value="">Select violation type</option>
                    {vTypes.map((method, index) => (
                      <option key={index} value={method}>
                        {method}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {violationType === "Other" ? (
                <Col sm="3">
                  <Form.Group className="mb-4 " controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Other:
                    </h4>
                    <Form.Control
                      type="text"
                      name="other"
                      value={otherViolation}
                      onChange={(e) => setOtherViolation(e.target.value)}
                      placeholder=""
                      className="custom-input border-fill no-spinner"
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Employee Statement:</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Date of Incident:
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="dateOfIncident"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Enter violation detail"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                      selected={
                        violationDate ? new Date(violationDate) : new Date()
                      }
                      onChange={(date: any) => {
                        setViolationDate(date.toLocaleDateString());
                      }}
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4 " controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Time of Incident:
                  </h4>
                  <Form.Control
                    type="time"
                    name="time"
                    value={violationTime}
                    onChange={(e: any) => setViolationTime(e.target.value)}
                    placeholder="Enter violation time"
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
              <Col sm="8">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Description:
                  </h4>
                  <textarea
                    name="description"
                    placeholder="Leave a comment here..."
                    value={violationStatement}
                    onChange={(e) => setViolationStatement(e.target.value)}
                    className="border-fill w-100"
                    rows={6}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>

              <Col sm="12">
                <Form.Group className="mb-4">
                  <Form.Check
                    type="checkbox"
                    checked={supervisorAgreement}
                    onChange={(e) => setSupervisorAgreement(e.target.checked)}
                    label="I agree with Employer's Statement."
                    name="userManagement.write"
                    readOnly={true}
                  />
                </Form.Group>
              </Col>

              <Col sm="12">
                <Form.Group className="mb-4">
                  <Form.Check
                    type="checkbox"
                    checked={!supervisorAgreement}
                    onChange={(e) => setSupervisorAgreement(!e.target.checked)}
                    label="I disagree with Employer's description of violation for these reasons:"
                    readOnly={true}
                    name="userManagement.write"
                  />
                </Form.Group>
              </Col>
              <Col sm="8">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Reasons:
                  </h4>
                  <textarea
                    name="reasons"
                    placeholder="Leave a comment here..."
                    className="border-fill w-100"
                    rows={6}
                    value={supervisorStatement}
                    onChange={(e) => setSupervisorStatement(e.target.value)}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>

        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Action(s) to be taken</h4>
            <Row>
              <Col sm="3">
                <Form.Group className="mb-4">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Actions:
                  </h4>
                  <Form.Select
                    name="actionTaken"
                    onChange={(e) => {
                      setActionTaken(e.target.value);
                    }}
                    value={actionTaken}
                    className="custom-input border-fill"
                  >
                    <option value="">Select action</option>
                    {actions.map((method, index) => (
                      <option key={index} value={method}>
                        {method}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {actionTaken === "Other" ? (
                <Col sm="3">
                  <Form.Group className="mb-4 " controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Other:
                    </h4>
                    <Form.Control
                      type="text"
                      name="other"
                      value={actionOther}
                      onChange={(e) => setActionOther(e.target.value)}
                      placeholder=""
                      className="custom-input border-fill no-spinner"
                    />
                  </Form.Group>
                </Col>
              ) : null}
              <Col sm="10">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Consequence should incident occur again:
                  </h4>
                  <textarea
                    name="description"
                    placeholder="Leave a comment here..."
                    className="border-fill w-100"
                    value={consequence}
                    onChange={(e) => setConsequence(e.target.value)}
                    rows={6}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSave}>
              {state.addBehaviourStatementLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default EmployeeWarning;
