import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";

interface SubscriptionProps {
  data: {
    status: string;
    category: string;
    fromDate: string;
    toDate: string;
    cost: string;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    status: string;
    category: string;
    fromDate: string;
    toDate: string;
    cost: string;
  }) => void;
  uniqueId: string;
}

const SubscriptionSet: React.FC<SubscriptionProps> = ({
  onRemove,
  onInputChange,
  uniqueId,
  data,
}) => {
  const [status, setStatus] = useState(data.status);
  const [category, setCategory] = useState(data.category);
  const [cost, setCost] = useState(data.cost);
  const [fromDate, setFromDate] = useState(data.fromDate);
  const [toDate, setToDate] = useState(data.toDate);
  const handleInputChange = () => {
    onInputChange({
      category,
      status,
      cost,
      fromDate,
      toDate,
    });
  };
  useEffect(() => {
    handleInputChange();
  }, [category, status, cost, fromDate, toDate]);
  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="11">
          <Row>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Category
                </h4>
                <Form.Select
                  name="category"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  className="custom-input border-fill"
                >
                  <option value="">Select category</option>
                  <option value="Qiwa">Qiwa</option>
                  <option value="Absher/Muqeem">Absher/Muqeem</option>
                  <option value="Mudad">Mudad</option>
                  <option value="GOSI">GOSI</option>
                  <option value="SPL">SPL (Saudi Post)</option>
                  <option value="MOL">MOL (Ministry of labour)</option>
                  <option value="Saudi Chamber">Saudi Chamber</option>
                  <option value="Naql">Naql</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  From Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="fromDate"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={(e: any) => setFromDate(e)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select from date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  To Date
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="toDate"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={(e: any) => setToDate(e)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select to date"
                    className="w-100 custom-input"
                    dateFormat="yyyy-MM-d"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Cost (SAR)
                </h4>
                <Form.Control
                  type="text"
                  placeholder="Enter cost"
                  name="cost"
                  onChange={(e) => {
                    //     accept only numbers
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCost(e.target.value);
                    }
                  }}
                  value={cost}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Status
                </h4>
                <Form.Select
                  name="status"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  className="custom-input border-fill"
                >
                  <option value="">Select Status</option>
                  <option value="Valid">Valid</option>
                  <option value="Expired">Expired</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="1">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => onRemove(uniqueId)}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubscriptionSet;
