import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const LegalComponent = () => {
  return (
    <div className="mt-4">
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
};
export default LegalComponent;
