import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";

const ViewClientReview = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const clientData = location.state.data;

    return (
        <div className=" mt-2 section px-4 py-4">
            <Row>
                <Col sm="12">
                    <div className="section-heading mb-4">
                        <h1 className="heading mb-0">Client Review Detail</h1>
                    </div>
                </Col>
                <Col sm="12">
                    <Row>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Project Id
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.projectId}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Project Name
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.projectName}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Client Code
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.clientId}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Client Name
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.contactPersonName}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Type
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.reviewType}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Statement Date
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.statementDate ? new Date(clientData.statementDate).toLocaleDateString() : ""}
                                </h5>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <h4
                                    className="sub-heading textBlack"
                                    style={{ fontSize: "18px" }}
                                >
                                    Statement
                                </h4>
                                <h5
                                    className="sub-heading normal-font-weight"
                                    style={{ fontSize: "16px" }}
                                >
                                    {clientData.statement}
                                </h5>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
                        <button
                            className="btn primary-button w-25"
                        >
                            Print
                        </button>
                        <button
                            className="btn primary-button w-25"
                            onClick={() => navigate(-1)}
                        >
                            Go Back
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default ViewClientReview;
