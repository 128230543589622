import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageEvaluationDetails,
  setDetailActiveTab,
  setDetailPageAttachments,
  setDetailPageAttendanceLog,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";

interface Props {
  empDetail: EmpDetail;
}

const EvaluationDetail = ({ empDetail }: Props) => {
  const dispatch = useAppDispatch();
  return (
    <Row>
      {empDetail.evaluationDetail ? (
        <Col sm="12">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Obedient
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {empDetail.evaluationDetail.obedient}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Behavior
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {empDetail.evaluationDetail.behavior}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Appearance
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {empDetail.evaluationDetail.appearance}
                </h5>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Remarks
                </h4>
                <h5
                  className="sub-heading normal-font-weight"
                  style={{ fontSize: "16px" }}
                >
                  {empDetail.evaluationDetail.remarks}
                </h5>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col sm="12">
          <h3 className="sub-heading mb-4">No Evaluation Details Found</h3>
        </Col>
      )}
      {empDetail.language.length > 0 && (
        <>
          <Col sm="12">
            <h3 className="sub-heading mb-4">Languages</h3>
          </Col>
          <Col sm="12">
            <div className="table-responsive mb-4">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Language</th>
                    <th>Language Proficiency</th>
                    <th>Writing Skills</th>
                  </tr>
                </thead>
                <tbody>
                  {empDetail.language.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.selectedLanguage}</td>
                      <td>{item.languageProficiency}</td>
                      <td>{item.writingSkills}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </>
      )}
      {empDetail.experience.length > 0 && (
        <>
          <Col sm="12">
            <h3 className="sub-heading mb-4">Experience:</h3>
          </Col>
          <Col sm="12">
            <div className="table-responsive mb-4">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Region</th>
                    <th>Basic Salary</th>
                    <th>Experience Proficiency</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {empDetail.experience.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.region}</td>
                      <td>{item.basicSalary}</td>
                      <td>{item.experienceProficiency}</td>
                      <td>
                        {item.startDate
                          ? new Date(item.startDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        {item.endDate
                          ? new Date(item.endDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </>
      )}
      {empDetail.skills.length > 0 && (
        <>
          <Col sm="12">
            <h3 className="sub-heading mb-4">Extra Skills:</h3>
          </Col>
          <Col sm="12">
            <div className="table-responsive mb-4">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Skill Name</th>
                    <th>Skill Proficiency</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {empDetail.skills.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.skillName}</td>
                      <td>{item.skillProficiency}</td>
                      <td>{item.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Attachments"));
              dispatch(setDetailPageAttachments(true));
              dispatch(setDetailPageEvaluationDetails(false));
            }}
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Attendance Log"));
              dispatch(setDetailPageEvaluationDetails(false));
              dispatch(setDetailPageAttendanceLog(true));
            }}
            style={{ width: "15%" }}
          >
            Next
            <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default EvaluationDetail;
