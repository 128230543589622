import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
// Redux Imports
import { useAppSelector } from "../../store/hooks";
import { LoaderBlue } from "../Lotties";

const Permissions = () => {
  const state = useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  const tableData = {
    tableHead: ["Sr. No.", "Role Name", "Role User(s)", "Actions"],
    tableBody: state.roles,
  };
  let count = 1;
  return (
    <div className="user-list mt-2">
      <Row>
        <Col sm="12">
          <div className="section-heading d-flex align-items-center justify-content-between">
            <h1 className="heading mb-0">All Designations</h1>
            <Link
              to="/settings/add-role"
              className="primary-button normal-font-weight h-auto px-3 py-2"
            >
              Add Designation
            </Link>
          </div>
        </Col>
        {state.profileData.permissions.roleName === "Super Admin" ||
        state.profileData.permissions.roleName === "General Manager" ? (
          <Col sm="12">
            {state.loading ? (
              <LoaderBlue height={70} width={70} />
            ) : (
              <>
                {tableData.tableBody.length > 0 ? (
                  <Table
                    striped
                    bordered
                    responsive
                    className="mt-5 text-center"
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHead.map((item, index) => (
                          <th className="border-0" key={index}>
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableBody.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>{count++}</td>
                          <td
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/settings/view-role/${item.roleName}`, {
                                state: { data: item },
                              });
                            }}
                          >
                            {item.roleName}
                          </td>
                          <td>{item.roleUsers}</td>
                          <td>
                            <button
                              className="btn p-0 border-0 me-2"
                              onClick={() => {
                                navigate(
                                  `/settings/edit-role/${item.roleName}`,
                                  { state: { data: item } },
                                );
                              }}
                            >
                              <img
                                src={SettingsIcons.EditUser}
                                width="30px"
                                height="30px"
                                alt="edit-user"
                              />
                            </button>
                            <button className="btn p-0 border-0">
                              <img
                                src={SettingsIcons.Delete}
                                width="24px"
                                height="24px"
                                alt="edit-user"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center mt-5">
                    <h1 className="heading">No Designations Found</h1>
                  </div>
                )}
              </>
            )}
          </Col>
        ) : (
          <div className="text-center mt-5">
            <h1 className="heading">Unauthorized Access</h1>
          </div>
        )}
      </Row>
    </div>
  );
};
export default Permissions;
