import React from "react";
import InternalLayout from "../components/InternalLayout";
import ReportsComponent from "../components/Reports";
import withAuth from "../config/private-route";

const Reports = () => {
  return (
    <InternalLayout>
      <ReportsComponent />
    </InternalLayout>
  );
};
export default withAuth(Reports);
