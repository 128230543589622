import React from "react";
import InternalLayout from "../components/InternalLayout";
import EmployeePayrollComponent from "../components/EmployeePayrolls";
import withAuth from "../config/private-route";

const EmployeePayroll = () => {
  return (
    <InternalLayout>
      <EmployeePayrollComponent />
    </InternalLayout>
  );
};
export default withAuth(EmployeePayroll);
