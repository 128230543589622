import React from "react";
import InternalLayout from "../components/InternalLayout";
import RecruitAgencyComponent from "../components/RecruitAgency";
import withAuth from "../config/private-route";

const RecruitAgency = () => {
  return (
    <InternalLayout>
      <RecruitAgencyComponent />
    </InternalLayout>
  );
};
export default withAuth(RecruitAgency);
