import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppSelector } from "../../../store/hooks";

const ProjectDetailGraph = () => {
  const dashboardState = useAppSelector(
    (state) => state.dashboard.dashboardData,
  );
  const state = {
    series: dashboardState.projectsData,
    options: {
      chart: {
        type: "donut",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      title: {
        text: "Projects Detail",
        align: "left",
        style: {
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Poppins",
          color: "#000000",
        },
      },
      labels: ["Completed", "In Progress", "Closed"],
      colors: ["#39B359", "#29406C", "#F00"],
    } as ApexOptions,
  };

  return (
    <div className="section px-4 py-4">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
      />
    </div>
  );
};
export default ProjectDetailGraph;
