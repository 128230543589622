import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// Reducers
import { authenticationAPI } from "../services/Authentication";
import { settingsAPI } from "../services/Settings";
import { employeeAPI } from "../services/Employees";
import { imageUploadAPI } from "../services/images-upload-service";
import { pettyCashAPI } from "../services/PettyCash";
import { projectsAPI } from "../services/Projects";
import { iqamaAPI } from "../services/Iqama";
import { attendanceAPI } from "../services/Attendance";
import { lettersAPI } from "../services/Letters";
import { notificationsAPI } from "../services/Notifications";
import { clientsAPI } from "../services/Clients";
import { campsAPI } from "../services/Camps";
import { accountChartsAPI } from "../services/AccountCharts";
import { manualJournalAPI } from "../services/ManualJournal";
import { sponsorAPI } from "../services/Sponsor";
import { employeePayrollAPI } from "../services/EmployeePayroll";
import { dashboardAPI } from "../services/Dashboard";
import { reportsAPI } from "../services/Reports";
import authenticationSlice from "./slices/authenticationSlice";
import settingsSlice from "./slices/settingsSlice";
import employeeSlice from "./slices/employeeSlice";
import projectSlice from "./slices/projectSlice";
import pettyCashSlice from "./slices/pettyCashSlice";
import iqamaSlice from "./slices/iqamaSlice";
import commonSlice from "./slices/commonSlice";
import attendanceSlice from "./slices/attendanceSlice";
import lettersSlice from "./slices/lettersSlice";
import notificationsSlice from "./slices/notificationsSlice";
import clientsSlice from "./slices/clientsSlice";
import campsSlice from "./slices/campsSlice";
import accountChartsSlice from "./slices/accountChartsSlice";
import manualJournalSlice from "./slices/manualJournalSlice";
import sponsorSlices from "./slices/sponsorSlices";
import ownersSlice from "./slices/ownersSlice";
import vendorsSlice from "./slices/vendorsSlice";
import employeePayrollSlice from "./slices/employeePayrollSlice";
import dashboardSlice from "./slices/dashboardSlice";
import reportsSlice from "./slices/reportsSlice";

export const store = configureStore({
  reducer: {
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [settingsAPI.reducerPath]: settingsAPI.reducer,
    [employeeAPI.reducerPath]: employeeAPI.reducer,
    [imageUploadAPI.reducerPath]: imageUploadAPI.reducer,
    [pettyCashAPI.reducerPath]: pettyCashAPI.reducer,
    [projectsAPI.reducerPath]: projectsAPI.reducer,
    [iqamaAPI.reducerPath]: iqamaAPI.reducer,
    [attendanceAPI.reducerPath]: attendanceAPI.reducer,
    [lettersAPI.reducerPath]: lettersAPI.reducer,
    [notificationsAPI.reducerPath]: notificationsAPI.reducer,
    [clientsAPI.reducerPath]: clientsAPI.reducer,
    [campsAPI.reducerPath]: campsAPI.reducer,
    [accountChartsAPI.reducerPath]: accountChartsAPI.reducer,
    [manualJournalAPI.reducerPath]: manualJournalAPI.reducer,
    [sponsorAPI.reducerPath]: sponsorAPI.reducer,
    [employeePayrollAPI.reducerPath]: employeePayrollAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [reportsAPI.reducerPath]: reportsAPI.reducer,
    authentication: authenticationSlice,
    settings: settingsSlice,
    employee: employeeSlice,
    project: projectSlice,
    pettyCash: pettyCashSlice,
    iqama: iqamaSlice,
    common: commonSlice,
    attendance: attendanceSlice,
    letters: lettersSlice,
    notification: notificationsSlice,
    clients: clientsSlice,
    camps: campsSlice,
    accountCharts: accountChartsSlice,
    manualJournal: manualJournalSlice,
    sponsor: sponsorSlices,
    owners: ownersSlice,
    vendors: vendorsSlice,
    employeePayroll: employeePayrollSlice,
    dashboard: dashboardSlice,
    reports: reportsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationAPI.middleware,
      settingsAPI.middleware,
      employeeAPI.middleware,
      imageUploadAPI.middleware,
      pettyCashAPI.middleware,
      projectsAPI.middleware,
      iqamaAPI.middleware,
      attendanceAPI.middleware,
      lettersAPI.middleware,
      notificationsAPI.middleware,
      clientsAPI.middleware,
      campsAPI.middleware,
      accountChartsAPI.middleware,
      manualJournalAPI.middleware,
      sponsorAPI.middleware,
      employeePayrollAPI.middleware,
      dashboardAPI.middleware,
      reportsAPI.middleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
