import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppSelector } from "../../../store/hooks";

const AbsentEmployeesRatio = () => {
  const dashboardState = useAppSelector(
    (state) => state.dashboard.dashboardData,
  );
  const state = {
    series: [
      {
        name: "Employees",
        data: dashboardState.lastWeekAbsentRatio,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area", // Use 'area' type for area chart
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Last Week Absent Ratio",
        align: "center",
        style: {
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Poppins",
          color: "#000000",
        },
      },
      colors: ["rgba(255,0,0,1)"],
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        title: {
          text: "Days",
          style: {
            cssClass: "apex-chart-side-heading",
          },
        },
        categories: ["0", "1", "2", "3", "4", "5", "6"],
      },
      yaxis: {
        title: {
          text: "Employees",
          style: {
            cssClass: "apex-chart-side-heading",
          },
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        colors: ["rgba(255,0,0,0.87)"], // Specify the fill color
        // Specify the fill opacity
      },
    } as ApexOptions,
  };

  return (
    <div className="section px-4 py-4 mb-4">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default AbsentEmployeesRatio;
