import React from "react";
import { Toast } from "react-bootstrap";
import { Common } from "../../assets/images";

interface ToastProps {
  show: boolean;
  setShow: (show: boolean) => void;
  content: string;
}

export const ToastSuccessComponent = ({
  show,
  setShow,
  content,
}: ToastProps) => {
  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      className="toast-success"
      delay={4000}
      autohide
      style={{ zIndex: 9999 }}
    >
      <Toast.Body
        className=""
        style={{
          fontWeight: "500",
          fontSize: "18px",
          color: "black",
          padding: "12px 16px",
        }}
      >
        <img src={Common.ToastSuccess} className="me-2" alt="toast-successs" />
        {content}
      </Toast.Body>
    </Toast>
  );
};

export const ToastErrorComponent = ({ show, setShow, content }: ToastProps) => {
  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      className="toast-error"
      delay={4000}
      autohide
      style={{ zIndex: 9999 }}
    >
      <Toast.Body
        className=""
        style={{
          fontWeight: "500",
          fontSize: "18px",
          color: "black",
          padding: "12px 16px",
        }}
      >
        <img src={Common.ToastError} className="me-2" alt="toast-successs" />
        {content}
      </Toast.Body>
    </Toast>
  );
};

export const ToastInfoComponent = ({ show, setShow, content }: ToastProps) => {
  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      className="toast-info"
      delay={4000}
      autohide
      style={{ zIndex: 9999 }}
    >
      <Toast.Body
        className=""
        style={{
          fontWeight: "500",
          fontSize: "18px",
          color: "black",
          padding: "12px 16px",
        }}
      >
        <img src={Common.ToastInfo} className="me-2" alt="toast-successs" />
        {content}
      </Toast.Body>
    </Toast>
  );
};
