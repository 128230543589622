import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowSalaryIncrementExportDropdown,
  setSalaryIncrementPageNo,
  setSalaryIncrementPageSize,
  setFilterSalaryIncrementPageNo,
  setFilterSalaryIncrementPageSize,
} from "../../store/slices/lettersSlice";
import { LoaderBlue } from "../Lotties";
import { setSalaryIncrementChange } from "../../store/slices/commonSlice";
// API imports
import { useDeleteSalaryIncrementMutation } from "../../services/Letters";
import { SalaryIncrement } from "../../interfaces/letters";
import { exportToCsv, exportToPDF } from "../../config";
import Content from "../HR/get-salary-increments-api";
import SalaryIncrementFilterModal, {
  FormProps,
} from "../Modals/salary-increment-filter-modal";
import { useReactToPrint } from "react-to-print";

const SalaryIncrements = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [deleteLoadingMap, setDeleteLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});
  const salaryIncrementChange = useAppSelector(
    (state) => state.common.salaryIncrementChange,
  );
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  // Options
  const options = employees.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId,
  }));
  // Pagination && Filter
  const [filterInput, setFilterInput] = useState<FormProps>({
    projectId: "",
    projectSupervisor: "",
    incrementPercentage: "",
    incrementFrom: "",
    incrementTo: "",
    incrementType: "",
    incrementStatus: "",
  });
  const [filterSearch, setFilterSearch] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<SalaryIncrement[]>([]);
  const totalPages = Math.ceil(
    state.salaryIncrementTotalPages / state.salaryIncrementPageSize,
  );
  const totalCount = filterSearch
    ? state.filterSalaryIncrementTotalPages
    : state.salaryIncrementTotalPages;

  const filterTotalPages = Math.ceil(
    state.filterSalaryIncrementTotalPages / state.filterSalaryIncrementPageSize,
  );

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.salaryIncrementPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  const filterStartPage =
    Math.floor((state.filterSalaryIncrementPageNo - 1) / showPages) * showPages;
  const filterEndPage = filterStartPage + showPages;
  const filterPages = [...Array(filterTotalPages).keys()].slice(
    filterStartPage,
    filterEndPage,
  );
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.salaryIncrementPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setSalaryIncrementPageNo(state.salaryIncrementPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.salaryIncrementPageNo <= 5) {
      return;
    }
    dispatch(setSalaryIncrementPageNo(state.salaryIncrementPageNo - showPages));
  };

  const handleFilterGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.filterSalaryIncrementPageNo > filterTotalPages - showPages) {
      return;
    }
    dispatch(
      setFilterSalaryIncrementPageNo(
        state.filterSalaryIncrementPageNo + showPages,
      ),
    );
  };

  const handleFilterGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.filterSalaryIncrementPageNo <= 5) {
      return;
    }
    dispatch(
      setFilterSalaryIncrementPageNo(
        state.filterSalaryIncrementPageNo - showPages,
      ),
    );
  };

  const handlePageChange = (page: any) => {
    dispatch(setSalaryIncrementPageNo(page));
  };

  const handleFilterPageChange = (page: any) => {
    dispatch(setFilterSalaryIncrementPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    if (filterSearch) {
      dispatch(setFilterSalaryIncrementPageSize(selectedPageSize));
    } else {
      dispatch(setSalaryIncrementPageSize(selectedPageSize));
    }
  };
  useEffect(() => {
    setFilteredData(state.salaryIncrements);
  }, [state.salaryIncrements]);

  // Delete Behaviour Statement

  const [deleteSalaryIncrement] = useDeleteSalaryIncrementMutation();

  const handleDelete = async (leaveId: string) => {
    setDeleteLoadingMap((prevMap) => ({ ...prevMap, [leaveId]: true }));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.deleteEmployee || false,
        payload: {
          incrementId: leaveId,
        },
      };
      await deleteSalaryIncrement(data).unwrap();
      dispatch(setSalaryIncrementChange(!salaryIncrementChange));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoadingMap((prevMap) => ({ ...prevMap, [leaveId]: false }));
    }
  };

  // Table Data
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Employee ID",
      "Employee Name",
      "Project Id.",
      "Project Name",
      "Supervisor Name",
      "Increment Amount",
      "New Salary",
      "Increment From",
      "Increment Status",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Employee ID": item.empId,
    "Employee Name": item.empName,
    "Project Id.": item.projectId,
    "Project Name": item.projectName,
    "Supervisor Name": item.projectSupervisor,
    "Increment Amount": item.incrementAmount,
    "New Salary": item.updatedSalary,
    "Increment From": item.incrementFrom
      ? new Date(item.incrementFrom).toLocaleDateString()
      : "",
    "Increment Status": item.status,
  }));

  const handleReset = () => {
    setQuery("");
    setSearch("");
    setFilteredData(state.salaryIncrements);
    setFilterSearch(false);
    setFilterInput({
      projectId: "",
      projectSupervisor: "",
      incrementPercentage: "",
      incrementFrom: "",
      incrementTo: "",
      incrementType: "",
      incrementStatus: "",
    });
  };

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <SalaryIncrementFilterModal
        show={showFilterModal}
        setShow={setShowFilterModal}
        setSalaryIncrements={setFilteredData}
        filterSearch={filterSearch}
        setFilterSearch={setFilterSearch}
        salaryIncrements={state.salaryIncrements}
        query={query}
        filterInput={filterInput}
        setFilterInput={setFilterInput}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Salary Increments</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        setQuery(value);
                        setFilterSearch(true);
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <button
                  className="dropdown-toggle-white dropdown-toggle-blue"
                  type="button"
                  onClick={() => navigate("/employees/salary-increment")}
                >
                  <img src={Common.Plus} height="22px" width="22px" alt="img" />{" "}
                  New
                </button>
                <button
                  id="dropdown-basic"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                  className="dropdown-toggle-white dropdown-toggle-blue"
                >
                  Filter{" "}
                </button>
                <Dropdown
                  show={state.showSalaryIncrementExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowSalaryIncrementExportDropdown(
                          !state.showSalaryIncrementExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showSalaryIncrementExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "employees-salary-increments");
                        dispatch(setShowSalaryIncrementExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowSalaryIncrementExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.behaviourStatementsLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Employees Salary Increment List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? filterSearch
                                ? (state.filterSalaryIncrementPageNo - 1) *
                                    state.filterSalaryIncrementPageSize +
                                  index +
                                  1
                                : (state.salaryIncrementPageNo - 1) *
                                    state.salaryIncrementPageSize +
                                  index +
                                  1
                              : index + 1}
                          </td>

                          <td>{item.empId}</td>
                          <td>
                            {print ? twoWords(item.empName) : item.empName}
                          </td>
                          <td>{item.projectId}</td>
                          <td>{item.projectName}</td>
                          <td>{item.projectSupervisor}</td>
                          <td>{item.incrementAmount}</td>
                          <td>{item.updatedSalary}</td>
                          <td>
                            {item.incrementFrom
                              ? new Date(
                                  item.incrementFrom,
                                ).toLocaleDateString()
                              : ""}
                          </td>
                          <td>{item.status}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/hr/salary-increment-detail/${item.empId}`,
                                    { state: { data: item } },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                              {item.status === "Pending" && (
                                <button
                                  className="btn p-0 border-0 me-2"
                                  onClick={() =>
                                    navigate(
                                      `/employees/edit-salary-increment/${item.empId}`,
                                      { state: { data: item } },
                                    )
                                  }
                                >
                                  <img
                                    src={SettingsIcons.EditUser}
                                    width="30px"
                                    height="30px"
                                    alt="edit-user"
                                  />
                                </button>
                              )}
                              {item.status === "Pending" ||
                              item.status === "Rejected" ? (
                                <>
                                  {deleteLoadingMap[item._id] ? (
                                    <LoaderBlue height={20} width={20} />
                                  ) : (
                                    <button
                                      className="btn p-0 border-0"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <img
                                        src={SettingsIcons.Delete}
                                        width="24px"
                                        height="24px"
                                        alt="edit-user"
                                      />
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No requests found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={
                    filterSearch
                      ? state.filterSalaryIncrementPageSize.toString()
                      : state.salaryIncrementPageSize.toString()
                  }
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterGoToPreviousPages()
                        : handleGoToPreviousPages()
                    }
                  >
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterSalaryIncrementPageNo > 1
                              ? state.filterSalaryIncrementPageNo - 1
                              : 1,
                          )
                        : handlePageChange(
                            state.salaryIncrementPageNo > 1
                              ? state.salaryIncrementPageNo - 1
                              : 1,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  {filterSearch ? (
                    <>
                      {/* render filter pages here */}
                      {filterPages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handleFilterPageChange(page + 1)}
                          className={
                            page + 1 === state.filterSalaryIncrementPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* render pages here */}
                      {pages.map((page, index) => (
                        <Pagination.Item
                          key={index}
                          onClick={() => handlePageChange(page + 1)}
                          className={
                            page + 1 === state.salaryIncrementPageNo
                              ? "active-page"
                              : ""
                          }
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  )}
                  <Pagination.Item
                    onClick={() =>
                      filterSearch
                        ? handleFilterPageChange(
                            state.filterSalaryIncrementPageNo < filterTotalPages
                              ? state.filterSalaryIncrementPageNo + 1
                              : filterTotalPages,
                          )
                        : handlePageChange(
                            state.salaryIncrementPageNo < totalPages
                              ? state.salaryIncrementPageNo + 1
                              : totalPages,
                          )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      filterSearch
                        ? handleFilterGoToNextPages()
                        : handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default SalaryIncrements;
