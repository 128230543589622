import React, { useEffect } from "react";
// Package Imports
import { Col, Form, Row } from "react-bootstrap";
// Redux Toolkit Imports
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  setUserIdentity,
  setUserInfo,
} from "../../store/slices/authenticationSlice";
// Files Imports
import { Common, LoginIcons, SettingsIcons } from "../../assets/images";
import { useUploadRegistrationImageMutation } from "../../services/images-upload-service";
import { LoaderWhite } from "../Lotties";

interface Props {
  handleChange: any;
  handleBlur: any;
  values: any;
  touched: any;
  errors: any;
}

const UserIdentity = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
}: Props) => {
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState("");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const dispatch = useAppDispatch();
  const { emailError } = useAppSelector((state) => state.authentication);

  const handleNextBtn = () => {
    dispatch(setUserIdentity(false));
    dispatch(setUserInfo(true));
  };

  const [uploadRegistrationImage] = useUploadRegistrationImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        email: values.email,
        payload: formData,
      };

      try {
        const result = await uploadRegistrationImage(data).unwrap();
        setImageUploaded(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    values.profileImage = imageUploaded;
  }, [imageUploaded]);

  return (
    <Row>
      <Col lg="4">
        <Form.Group className=" text-center mb-4">
          {imageLoading ? (
            <LoaderWhite height={50} width={50} />
          ) : (
            <>
              {imageUploaded ? (
                <img src={imageUploaded} width="100%" alt="employee-image" />
              ) : (
                <Form.Label className="profile-label d-block">
                  <Form.Control
                    type="file"
                    name="profileImage"
                    placeholder="Upload your profile image"
                    accept=".png, .jpg, .jpeg"
                    className="d-none"
                    multiple={false}
                    onChange={handleUploadImage}
                  />
                  <span>
                    <img src={Common.User} alt="user" />
                  </span>
                </Form.Label>
              )}
            </>
          )}
          {imageUploaded ? (
            <label className="d-flex align-items-center justify-content-center cursor-pointer">
              <input
                type="file"
                name="profileImage"
                onChange={handleUploadImage}
                accept=".png, .jpg, .jpeg"
                className="d-none"
                multiple={false}
              />
              <div className="d-flex align-items-center justify-content-center">
                <h4
                  className="sub-heading text-white text-decoration-underline normal-font-weight mt-2"
                  style={{ fontSize: "16px", fontWeight: "300" }}
                >
                  Change profile pic
                </h4>
              </div>
            </label>
          ) : null}
        </Form.Group>
      </Col>
      <Col lg="8">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <img src={LoginIcons.ProfileInput} alt="message" />
            <Form.Control
              type="text"
              name="firstName"
              placeholder="First name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              className={
                touched.firstName && errors.firstName ? "is-invalid" : ""
              }
            />
          </Form.Group>

          {touched.firstName && errors.firstName ? (
            <div className="invalid-feedback">{errors.firstName}</div>
          ) : null}
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design d-flex align-items-center">
            <img src={LoginIcons.ProfileInput} alt="message" />
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Last name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              className={
                touched.lastName && errors.lastName ? "is-invalid" : ""
              }
            />
          </Form.Group>
          {touched.lastName && errors.lastName ? (
            <div className="invalid-feedback">{errors.lastName}</div>
          ) : null}
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Group className="position-relative input-design disabled-input d-flex align-items-center">
            <img src={LoginIcons.MessageWhite} alt="message" />
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter your email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={true}
              className={touched.email && errors.email ? "is-invalid" : ""}
            />
          </Form.Group>
          {emailError ? (
            <div className="invalid-feedback">{emailError}</div>
          ) : (
            <>
              {touched.email && errors.email ? (
                <div className="invalid-feedback">{errors.email}</div>
              ) : null}
            </>
          )}
        </Form.Group>
      </Col>
      <Col lg="12">
        <button
          type="button"
          onClick={handleNextBtn}
          className="btn secondary-button nunito-font d-flex align-items-center w-25 justify-content-center ms-auto mt-4"
        >
          <span>Next</span>{" "}
          <img src={Common.ArrowRightBlue} className="ms-2" alt="arrow-blue" />
        </button>
      </Col>
    </Row>
  );
};
export default UserIdentity;
