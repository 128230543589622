import Lottie from "react-lottie";
import loader from "./loader.json";
import loaderWhite from "./loader-white.json";

interface Props {
  height: number;
  width: number;
}

export const LoaderBlue = ({ height, width }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    strokeWidth: 1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie options={defaultOptions} height={height} width={width} />;
};

export const LoaderWhite = ({ height, width }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderWhite,
    strokeWidth: 1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie options={defaultOptions} height={height} width={width} />;
};
