import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  Client,
  ClientId,
  Invoice,
  InvoiceId,
  Payment,
  PaymentId,
  Statement,
} from "../../interfaces/project";

interface ClientList {
  data: {
    clients: {
      data: Client[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface ClientIds {
  data: {
    clientIds: ClientId[];
  };
}

interface InvoiceList {
  data: {
    invoices: {
      data: Invoice[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface InvoiceIds {
  data: {
    invoices: InvoiceId[];
  };
}

interface PaymentList {
  data: {
    payments: {
      data: Payment[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface PaymentIds {
  data: {
    payments: PaymentId[];
  };
}

interface Statements {
  data: {
    statement: Statement;
  };
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const clientsAPI = createApi({
  reducerPath: "/api/clients",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getClientList: builder.query<
      ClientList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-clients/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),

    getClientIds: builder.query<
      ClientIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-client-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    editClient: builder.mutation({
      query: (data) => ({
        url: `/edit-client/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addClient: builder.mutation({
      query: (data) => ({
        url: `/add-client/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),

    deleteClient: builder.mutation({
      query: (data) => ({
        url: `/delete-client/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterClientList: builder.mutation<
      ClientList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-clients/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    // Invoice
    getInvoiceList: builder.query<
      InvoiceList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        clientID: string;
      }
    >({
      query: (data) => ({
        url: `/get-all-invoices/${data.clientID}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getInvoiceIds: builder.query<
      InvoiceIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        clientID: string;
      }
    >({
      query: (data) => ({
        url: `/all-invoice-ids/${data.clientID}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    createInvoice: builder.mutation({
      query: (data) => ({
        url: `/add-invoice/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    updateInvoice: builder.mutation({
      query: (data) => ({
        url: `/edit-invoice/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteInvoice: builder.mutation({
      query: (data) => ({
        url: `/delete-invoice/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterInvoiceList: builder.mutation<
      InvoiceList,
      {
        userID: string;
        permission: boolean;
        accessToken: string;
        payload: any;
      }
    >({
      query: (data) => ({
        url: `/get-all-filtered-invoices/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Transaction
    getTransactionList: builder.query<
      PaymentList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
        clientID: string;
      }
    >({
      query: (data) => ({
        url: `/get-all-payments/${data.clientID}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getTransactionIds: builder.query<
      PaymentIds,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        clientID: string;
      }
    >({
      query: (data) => ({
        url: `/all-payment-ids/${data.clientID}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    createTransaction: builder.mutation({
      query: (data) => ({
        url: `/add-payment/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    updateTransaction: builder.mutation({
      query: (data) => ({
        url: `/edit-payment/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteTransaction: builder.mutation({
      query: (data) => ({
        url: `/delete-payment/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilterTransactionList: builder.mutation<
      PaymentList,
      {
        userID: string;
        permission: boolean;
        accessToken: string;
        payload: any;
      }
    >({
      query: (data) => ({
        url: `/get-all-filtered-payments/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Statement
    getStatements: builder.query<
      Statements,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        clientID: string;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/client-statement/${data.clientID}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
  }),
});

export const {
  useGetClientListQuery,
  useGetClientIdsQuery,
  useEditClientMutation,
  useAddClientMutation,
  useDeleteClientMutation,
  useGetFilterClientListMutation,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetInvoiceListQuery,
  useGetFilterInvoiceListMutation,
  useGetInvoiceIdsQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
  useGetTransactionListQuery,
  useGetFilterTransactionListMutation,
  useGetTransactionIdsQuery,
  useGetStatementsQuery,
} = clientsAPI;
