import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPayrollChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import { useCreatePayrollMutation } from "../../services/EmployeePayroll";
import { setAddEmployeePayrollLoading } from "../../store/slices/employeePayrollSlice";
import DatePicker from "react-datepicker";
import { Common } from "../../assets/images";

interface Props {
  show: boolean;
  setShow: any;
  payload: {
    payrollId: string;
    payrollDate: string;
    projectId: string;
    empId: number;
    empName: string;
    empPhone: string;
    status: string;
    grossSalary: number;
    totalDeductions: number;
    netSalary: number;
    workedSalary: number;
    earnings: {
      title: string;
      description: string;
      type: string;
      hours: number;
      rate: number;
      amount: number;
    }[];
    deductions: {
      title: string;
      description: string;
      amount: number;
    }[];
    salaryIds: string[];
    expenseIds: string[];
  }[];
  createPayslipLoading: boolean;
  setSuccessContent: any;
  setShowSuccess: any;
  payslipChange: boolean;
  payrollDate: string;
  setPayrollDate: any;
}

const ConfirmCreatePayslipsModal = ({
  show,
  setShow,
  payload,
  createPayslipLoading,
  setShowSuccess,
  payslipChange,
  setSuccessContent,
  payrollDate,
  setPayrollDate,
}: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [createPayroll] = useCreatePayrollMutation();

  const handleCreatePayroll = async () => {
    if (!payrollDate) {
      setErrorContent("Please select payroll date");
      setShowError(true);
      return;
    }
    if (payload.length === 0) {
      setErrorContent("Please add employees to create payslips");
      setShowError(true);
      return;
    }
    dispatch(setAddEmployeePayrollLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          payroll: payload,
          link: "",
        },
      };
      await createPayroll(data).unwrap();
      dispatch(setPayrollChange(!payslipChange));
      setSuccessContent("Payslips created Successfully!");
      setShowSuccess(true);
      setShow(false);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddEmployeePayrollLoading(false));
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="mb-4 text-center">
            <h1 className="heading">Are you sure?</h1>
            <h4 className="sub-heading normal-font-weight text-center">
              Payslips once created cannot be edited.
            </h4>

            <Form.Group className="mt-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack text-start"
                style={{ fontSize: "18px" }}
              >
                Set Payroll Date
              </h4>
              <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                <DatePicker
                  name="payrollDate"
                  selected={payrollDate ? new Date(payrollDate) : null}
                  onChange={(e: any) => setPayrollDate(e)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Select payroll date"
                  className="w-100 custom-input"
                  dateFormat="yyyy-MM-d"
                />
                <img
                  src={Common.Calendar}
                  className="calendar-date-recur"
                  alt="calendarblack"
                />
              </Form.Group>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center gap-4">
              <button
                className="btn secondary-button mt-4"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className="btn primary-button border-0 mt-4"
                onClick={handleCreatePayroll}
              >
                {createPayslipLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : (
                  "Create Payslips"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmCreatePayslipsModal;
