import { createSlice } from "@reduxjs/toolkit";
import { ClientReview, Project, ProjectId } from "../../interfaces/project";
import { IdolEmployee, AllEmployees } from "../../interfaces/employee";
import { Timesheet } from "../../interfaces/timesheet";

interface Index {
  showAddEmployee: boolean;
  showEditEmployee: boolean;
  showAddProjectDetail: boolean;
  showEditProjectDetail: boolean;
  showExportDropdown: boolean;
  showFilterDropdown: boolean;
  showClientExportDropdown: boolean;
  showCLientFilterDropdown: boolean;
  addProjectLoading: boolean;
  editProjectLoading: boolean;
  projectsLoading: boolean;
  projectError: string;
  addClientReviewLoading: boolean;
  clientReviewsLoading: boolean;
  clientReviewsError: string;
  editClientReviewLoading: boolean;
  reviews: ClientReview[];
  projects: Project[];
  pageNo: number;
  pageSize: number;
  totalRecords: number;
  filterPageNo: number;
  filterPageSize: number;
  filterTotalRecords: number;
  clientPageNo: number;
  clientPageSize: number;
  clientTotalRecords: number;
  filterClientPageNo: number;
  filterClientPageSize: number;
  filterClientTotalRecords: number;
  // Project Ids
  projectIds: ProjectId[];
  projectIdsError: string;
  projectIdsLoading: boolean;
  //   Idol Employees
  idolEmployees: AllEmployees[];
  idolEmployeesError: string;
  idolEmployeesLoading: boolean;
  //   Timesheet
  projectTimesheet: Timesheet[];
  actualTimesheet: Timesheet[];
  projectedRevenue: Timesheet[];
  actualRevenue: Timesheet[];
  actualSheetDate: string;
  projectedSheetDate: string;
  actualRevenueDate: string;
  projectedRevenueDate: string;
  projectTimesheetError: string;
  projectTimesheetLoading: boolean;
  actualSheetChangeLoading: boolean;
  showProjectedSalarySheetExportDropdown: boolean;
  showProjectedRevenueSheetExportDropdown: boolean;
  showActualSalarySheetExportDropdown: boolean;
  showActualRevenueSheetExportDropdown: boolean;
  projectedSalarySheetPageNo: number;
  projectedSalarySheetPageSize: number;
  projectedSalarySheetTotalRecords: number;
  actualSalarySheetPageNo: number;
  actualSalarySheetPageSize: number;
  actualSalarySheetTotalRecords: number;
  projectedRevenueSheetPageNo: number;
  projectedRevenueSheetPageSize: number;
  projectedRevenueSheetTotalRecords: number;
  actualRevenueSheetPageNo: number;
  actualRevenueSheetPageSize: number;
  actualRevenueSheetTotalRecords: number;
  projectedSalarySheet: string[];
  projectedType: string;
  actualSalarySheet: string[];
  actualType: string;
  projectedRevenueSheet: string[];
  projectedTypeRevenue: string;
  actualRevenueSheet: string[];
  actualTypeRevenue: string;
  postSalaryLoading: boolean;
}

const initialState: Index = {
  showAddEmployee: false,
  showEditEmployee: false,
  showCLientFilterDropdown: false,
  showClientExportDropdown: false,
  showAddProjectDetail: true,
  showEditProjectDetail: true,
  addProjectLoading: false,
  editProjectLoading: false,
  projectsLoading: false,
  projects: [],
  projectError: "",
  showExportDropdown: false,
  showFilterDropdown: false,
  addClientReviewLoading: false,
  clientReviewsLoading: false,
  clientReviewsError: "",
  editClientReviewLoading: false,
  reviews: [],
  pageNo: 1,
  pageSize: 10,
  totalRecords: 0,
  filterPageNo: 1,
  filterPageSize: 10,
  filterTotalRecords: 0,
  clientPageNo: 1,
  clientPageSize: 10,
  clientTotalRecords: 0,
  filterClientPageNo: 1,
  filterClientPageSize: 10,
  filterClientTotalRecords: 0,
  // Project Ids
  projectIds: [],
  projectIdsError: "",
  projectIdsLoading: false,
  //   Idol Employees
  idolEmployees: [],
  idolEmployeesError: "",
  idolEmployeesLoading: false,
  //   Timesheet
  projectTimesheet: [],
  actualTimesheet: [],
  projectedRevenue: [],
  actualRevenue: [],
  actualSheetDate: new Date().toLocaleDateString(),
  projectedSheetDate: new Date().toLocaleDateString(),
  actualRevenueDate: new Date().toLocaleDateString(),
  projectedRevenueDate: new Date().toLocaleDateString(),
  projectTimesheetError: "",
  projectTimesheetLoading: false,
  actualSheetChangeLoading: false,
  showProjectedSalarySheetExportDropdown: false,
  showProjectedRevenueSheetExportDropdown: false,
  showActualSalarySheetExportDropdown: false,
  showActualRevenueSheetExportDropdown: false,
  projectedSalarySheetPageNo: 1,
  projectedSalarySheetPageSize: 10,
  projectedSalarySheetTotalRecords: 0,
  actualSalarySheetPageNo: 1,
  actualSalarySheetPageSize: 10,
  actualSalarySheetTotalRecords: 0,
  projectedRevenueSheetPageNo: 1,
  projectedRevenueSheetPageSize: 10,
  projectedRevenueSheetTotalRecords: 0,
  actualRevenueSheetPageNo: 1,
  actualRevenueSheetPageSize: 10,
  actualRevenueSheetTotalRecords: 0,
  projectedSalarySheet: ["All"],
  projectedType: "All",
  actualSalarySheet: ["All"],
  actualType: "All",
  projectedRevenueSheet: [""],
  projectedTypeRevenue: "",
  actualRevenueSheet: [""],
  actualTypeRevenue: "",
  postSalaryLoading: false,
};

const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    setShowAddEmployee: (state, action) => {
      state.showAddEmployee = action.payload;
    },
    setShowEditEmployee: (state, action) => {
      state.showEditEmployee = action.payload;
    },
    setShowAddProjectDetail: (state, action) => {
      state.showAddProjectDetail = action.payload;
    },
    setShowEditProjectDetail: (state, action) => {
      state.showEditProjectDetail = action.payload;
    },
    setAddProjectLoading: (state, action) => {
      state.addProjectLoading = action.payload;
    },
    setEditProjectLoading: (state, action) => {
      state.editProjectLoading = action.payload;
    },
    setProjectsLoading: (state, action) => {
      state.projectsLoading = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProjectError: (state, action) => {
      state.projectError = action.payload;
    },
    setShowExportDropdown: (state, action) => {
      state.showExportDropdown = action.payload;
    },
    setShowFilterDropdown: (state, action) => {
      state.showFilterDropdown = action.payload;
    },
    setAddClientReviewLoading: (state, action) => {
      state.addClientReviewLoading = action.payload;
    },
    setClientReviewsLoading: (state, action) => {
      state.clientReviewsLoading = action.payload;
    },
    setClientReviewsError: (state, action) => {
      state.clientReviewsError = action.payload;
    },
    setEditClientReviewLoading: (state, action) => {
      state.editClientReviewLoading = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setShowCLientFilterDropdown: (state, action) => {
      state.showCLientFilterDropdown = action.payload;
    },
    setShowClientExportDropdown: (state, action) => {
      state.showClientExportDropdown = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setFilterPageNo: (state, action) => {
      state.filterPageNo = action.payload;
    },
    setFilterPageSize: (state, action) => {
      state.filterPageSize = action.payload;
    },
    setFilterTotalRecords: (state, action) => {
      state.filterTotalRecords = action.payload;
    },
    setClientPageNo: (state, action) => {
      state.clientPageNo = action.payload;
    },
    setClientPageSize: (state, action) => {
      state.clientPageSize = action.payload;
    },
    setClientTotalRecords: (state, action) => {
      state.clientTotalRecords = action.payload;
    },
    setFilterClientPageNo: (state, action) => {
      state.filterClientPageNo = action.payload;
    },
    setFilterClientPageSize: (state, action) => {
      state.filterClientPageSize = action.payload;
    },
    setFilterClientTotalRecords: (state, action) => {
      state.filterClientTotalRecords = action.payload;
    },
    // Project Ids
    setProjectIds: (state, action) => {
      state.projectIds = action.payload;
    },
    setProjectIdsError: (state, action) => {
      state.projectIdsError = action.payload;
    },
    setProjectIdsLoading: (state, action) => {
      state.projectIdsLoading = action.payload;
    },
    //   Idol Employees
    setIdolEmployees: (state, action) => {
      state.idolEmployees = action.payload;
    },
    setIdolEmployeesError: (state, action) => {
      state.idolEmployeesError = action.payload;
    },
    setIdolEmployeesLoading: (state, action) => {
      state.idolEmployeesLoading = action.payload;
    },
    //   Timesheet
    setProjectTimesheet: (state, action) => {
      state.projectTimesheet = action.payload;
    },
    setProjectTimesheetError: (state, action) => {
      state.projectTimesheetError = action.payload;
    },
    setProjectTimesheetLoading: (state, action) => {
      state.projectTimesheetLoading = action.payload;
    },
    setProjectedSalarySheet: (state, action) => {
      state.projectedSalarySheet = action.payload;
    },
    setActualSalarySheet: (state, action) => {
      state.actualSalarySheet = action.payload;
    },
    setProjectedRevenueSheet: (state, action) => {
      state.projectedRevenueSheet = action.payload;
    },
    setActualRevenueSheet: (state, action) => {
      state.actualRevenueSheet = action.payload;
    },
    setActualSheetDate: (state, action) => {
      state.actualSheetDate = action.payload;
    },
    setProjectedSheetDate: (state, action) => {
      state.projectedSheetDate = action.payload;
    },
    setActualRevenueDate: (state, action) => {
      state.actualRevenueDate = action.payload;
    },
    setProjectedRevenueDate: (state, action) => {
      state.projectedRevenueDate = action.payload;
    },
    setShowProjectedSalarySheetExportDropdown: (state, action) => {
      state.showProjectedSalarySheetExportDropdown = action.payload;
    },
    setShowProjectedRevenueSheetExportDropdown: (state, action) => {
      state.showProjectedRevenueSheetExportDropdown = action.payload;
    },
    setShowActualSalarySheetExportDropdown: (state, action) => {
      state.showActualSalarySheetExportDropdown = action.payload;
    },
    setShowActualRevenueSheetExportDropdown: (state, action) => {
      state.showActualRevenueSheetExportDropdown = action.payload;
    },
    setProjectedSalarySheetPageNo: (state, action) => {
      state.projectedSalarySheetPageNo = action.payload;
    },
    setProjectedSalarySheetPageSize: (state, action) => {
      state.projectedSalarySheetPageSize = action.payload;
    },
    setProjectedSalarySheetTotalRecords: (state, action) => {
      state.projectedSalarySheetTotalRecords = action.payload;
    },
    setActualSalarySheetPageNo: (state, action) => {
      state.actualSalarySheetPageNo = action.payload;
    },
    setActualSalarySheetPageSize: (state, action) => {
      state.actualSalarySheetPageSize = action.payload;
    },
    setActualSalarySheetTotalRecords: (state, action) => {
      state.actualSalarySheetTotalRecords = action.payload;
    },
    setProjectedRevenueSheetPageNo: (state, action) => {
      state.projectedRevenueSheetPageNo = action.payload;
    },
    setProjectedRevenueSheetPageSize: (state, action) => {
      state.projectedRevenueSheetPageSize = action.payload;
    },
    setProjectedRevenueSheetTotalRecords: (state, action) => {
      state.projectedRevenueSheetTotalRecords = action.payload;
    },
    setActualRevenueSheetPageNo: (state, action) => {
      state.actualRevenueSheetPageNo = action.payload;
    },
    setActualRevenueSheetPageSize: (state, action) => {
      state.actualRevenueSheetPageSize = action.payload;
    },
    setActualRevenueSheetTotalRecords: (state, action) => {
      state.actualRevenueSheetTotalRecords = action.payload;
    },
    setActualTimesheet: (state, action) => {
      state.actualTimesheet = action.payload;
    },
    setActualType: (state, action) => {
      state.actualType = action.payload;
    },
    setProjectedRevenue: (state, action) => {
      state.projectedRevenue = action.payload;
    },
    setActualRevenue: (state, action) => {
      state.actualRevenue = action.payload;
    },
    setActualTypeRevenue: (state, action) => {
      state.actualTypeRevenue = action.payload;
    },
    setProjectedTypeRevenue: (state, action) => {
      state.projectedTypeRevenue = action.payload;
    },
    setProjectedType: (state, action) => {
      state.projectedType = action.payload;
    },
    setActualSheetChangeLoading: (state, action) => {
      state.actualSheetChangeLoading = action.payload;
    },
    setPostSalaryLoading: (state, action) => {
      state.postSalaryLoading = action.payload;
    },
  },
});

export const {
  setShowAddEmployee,
  setShowEditProjectDetail,
  setShowClientExportDropdown,
  setShowCLientFilterDropdown,
  setReviews,
  setShowEditEmployee,
  setShowFilterDropdown,
  setShowExportDropdown,
  setShowAddProjectDetail,
  setAddProjectLoading,
  setProjectError,
  setProjects,
  setProjectsLoading,
  setEditProjectLoading,
  setEditClientReviewLoading,
  setClientReviewsLoading,
  setClientReviewsError,
  setAddClientReviewLoading,
  setFilterPageSize,
  setFilterPageNo,
  setFilterTotalRecords,
  setPageNo,
  setPageSize,
  setTotalRecords,
  setFilterClientPageSize,
  setFilterClientTotalRecords,
  setClientPageNo,
  setClientPageSize,
  setClientTotalRecords,
  setFilterClientPageNo,
  setIdolEmployeesLoading,
  setIdolEmployees,
  setIdolEmployeesError,
  setProjectTimesheet,
  setProjectTimesheetError,
  setProjectTimesheetLoading,
  setProjectIds,
  setProjectIdsError,
  setProjectIdsLoading,
  setProjectedRevenueSheet,
  setActualRevenueSheet,
  setProjectedSalarySheet,
  setActualSalarySheet,
  setShowProjectedSalarySheetExportDropdown,
  setShowProjectedRevenueSheetExportDropdown,
  setShowActualSalarySheetExportDropdown,
  setShowActualRevenueSheetExportDropdown,
  setProjectedSalarySheetPageNo,
  setProjectedSalarySheetPageSize,
  setProjectedSalarySheetTotalRecords,
  setActualSalarySheetPageNo,
  setActualSalarySheetPageSize,
  setActualSalarySheetTotalRecords,
  setProjectedRevenueSheetPageNo,
  setProjectedRevenueSheetPageSize,
  setProjectedRevenueSheetTotalRecords,
  setActualRevenueSheetPageNo,
  setActualRevenueSheetPageSize,
  setActualRevenueSheetTotalRecords,
  setActualTimesheet,
  setProjectedRevenue,
  setActualRevenue,
  setActualSheetChangeLoading,
  setActualSheetDate,
  setActualType,
  setProjectedType,
  setProjectedTypeRevenue,
  setActualTypeRevenue,
  setProjectedSheetDate,
  setActualRevenueDate,
  setProjectedRevenueDate,
  setPostSalaryLoading,
} = projectSlice.actions;
export default projectSlice.reducer;
