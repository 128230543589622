import React from "react";
import InternalLayout from "../components/InternalLayout";
import TransactionContainer from "../components/Clients/transaction-container";
import withAuth from "../config/private-route";

const CreateClientInvoice = () => {
  return (
    <InternalLayout>
      <TransactionContainer />
    </InternalLayout>
  );
};
export default withAuth(CreateClientInvoice);
