import React from "react";
import { useNavigate } from "react-router-dom";
import { Common, SidebarIcons } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";

const Navbar = () => {
  const navigate = useNavigate();
  const profileData = useAppSelector((state) => state.settings.profileData);
  return (
    <div
      className="py-3 px-4 bg-white d-flex align-items-center justify-content-end"
      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <button
        className="btn p-0 border-0"
        onClick={() => navigate("/notifications")}
      >
        <img
          src={SidebarIcons.Notifications}
          width="30px"
          height="30px"
          className="me-3"
          alt="notifications"
        />
      </button>
      <div className="d-flex align-items-center">
        <button
          className="btn p-0 border-0 d-flex align-items-center"
          onClick={() => navigate("/settings/user-profile")}
        >
          {profileData?.img ? (
            <img
              src={profileData.img}
              width="40px"
              height="40px"
              style={{ objectFit: "cover", borderRadius: "50px" }}
              alt="user"
            />
          ) : (
            <img
              src={Common.UserImgPlaceholder}
              width="40px"
              height="40px"
              style={{ objectFit: "cover", borderRadius: "50px" }}
              alt="user"
            />
          )}
          <div className="ms-2 me-2">
            <h6 className="mb-0">
              {profileData?.firstName + " " + profileData?.lastName}
            </h6>
            <p className="mb-0" style={{ fontSize: "14px" }}>
              {profileData?.permissions.roleName}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};
export default Navbar;
