import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common } from "../../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useEditFinalExitMutation } from "../../../services/Letters";
import { setAddFinalExitLoading } from "../../../store/slices/lettersSlice";
import { setFinalExitChange } from "../../../store/slices/commonSlice";
import { ToastErrorComponent } from "../../Toasts";
import DatePicker from "react-datepicker";
import { LoaderWhite } from "../../Lotties";

const EditFinalExit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const finalExitData = location.state.data;
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState(finalExitData.empId || "");
  const [show, setShow] = React.useState(false);
  const [finalExitDate, setFinalExitDate] = React.useState(
    finalExitData.finalExitDate || "",
  );
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  const finalExitChange = useAppSelector(
    (state) => state.common.finalExitChange,
  );
  const employee = employees.find(
    (employee) => employee.empId === parseInt(empID),
  );
  // API call for add salary increment
  const [editFinalExit] = useEditFinalExitMutation();
  const handleSubmit = async () => {
    dispatch(setAddFinalExitLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          empId: empID,
          finalExitDate: finalExitDate,
          link: `/employees/final-exit-employees`,
        },
      };
      await editFinalExit(data).unwrap();
      dispatch(setFinalExitChange(!finalExitChange));
      navigate("/employees/final-exit-employees");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddFinalExitLoading(false));
    }
  };
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Edit Employee Final Exit</h1>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Employee Detail</h4>
            <Row>
              <Col sm="4">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Emp ID:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empId || "Select Employee Id"}
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empName || "Select Employee Id"}
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Status:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empStatus || "Select Employee Id"}
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Iqama No.
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empIqamaNo || "Select Employee Id"}
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div className="emp-detail-item">
                  <h4
                    className="sub-heading normal-font-weight textBlack mb-2"
                    style={{ fontSize: "18px" }}
                  >
                    Current Project:
                  </h4>
                  <p
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {employee?.empProjectId || "Select Employee Id"}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail mt-5">
            <h4 className="sub-heading mb-4">Financial Detail</h4>
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Final Exit Date:
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="returnDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Date of birth"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                      selected={
                        finalExitDate ? new Date(finalExitDate) : new Date()
                      }
                      onChange={(date: any) => {
                        setFinalExitDate(date);
                      }}
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <div className="emp-detail-print text-end mt-5">
            <button className="btn primary-button w-25" onClick={handleSubmit}>
              {state.addFinalExitLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default EditFinalExit;
