import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { AttendanceLog } from "../../interfaces/attendance";

interface Attendance {
  data: {
    attendances: {
      data: AttendanceLog[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export const attendanceAPI = createApi({
  reducerPath: "/api/attendance",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    addAttendance: builder.mutation({
      query: (data) => ({
        url: `/add-attendance/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editAttendance: builder.mutation({
      query: (data) => ({
        url: `/edit-projected-attandence/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteAttendance: builder.mutation({
      query: (data) => ({
        url: `/delete-attandence/${data.permission}/${data.userID}`,
        method: "DELETE",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getTodaysAttendance: builder.mutation<
      Attendance,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        payload: any;
        page: number;
        limit: number;
      }
    >({
      query: (data) => ({
        url: `/get-todays-attendence/${data.permission}/${data.page}/${data.limit}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getFilteredAttendance: builder.mutation<
      Attendance,
      { userID: string; accessToken: string; permission: boolean; payload: any }
    >({
      query: (data) => ({
        url: `/get-filtered-attendence/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useAddAttendanceMutation,
  useEditAttendanceMutation,
  useGetFilteredAttendanceMutation,
  useGetTodaysAttendanceMutation,
  useDeleteAttendanceMutation,
} = attendanceAPI;
