import React, { useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import LetterFormatB from "./letter-format-b";
import { useReactToPrint } from "react-to-print";
import { FinaLExitEmployee } from "../../interfaces/letters";

interface Props {
  exitData: FinaLExitEmployee;
}

const FinalExitLetter = ({ exitData }: Props) => {
  const probationRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => probationRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div className="letter-container position-relative" ref={probationRef}>
      <LetterFormatB heading="Employee Final Exit" data="hello">
        <h4 className="sub-heading mt-5">Employee Detail</h4>
        <Row>
          <Col sm="4">
            <div className="emp-detail-item">
              <h4
                className="sub-heading normal-font-weight textBlack mb-2"
                style={{ fontSize: "18px" }}
              >
                Emp ID:
              </h4>
              <p
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {exitData.empId}
              </p>
            </div>
          </Col>
          <Col sm="4">
            <div className="emp-detail-item">
              <h4
                className="sub-heading normal-font-weight textBlack mb-2"
                style={{ fontSize: "18px" }}
              >
                Emp Name:
              </h4>
              <p
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {exitData.empName}
              </p>
            </div>
          </Col>

          <Col sm="4">
            <div className="emp-detail-item">
              <h4
                className="sub-heading normal-font-weight textBlack mb-2"
                style={{ fontSize: "18px" }}
              >
                Current Project:
              </h4>
              <p
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {exitData.empProjectId}
              </p>
            </div>
          </Col>
        </Row>
        <div style={{ position: "absolute", bottom: "80px" }}>
          <h4 className="sub-heading normal-font-weight text-black mt-6 mb-1">
            Approved By:
          </h4>
          <div className="signature-authority d-flex gap-5">
            <div className="authority">
              <h4 className="sub-heading text-black">Supervisor</h4>
              <p
                className="para-medium text-black mb-0"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                23, May 2022
              </p>
            </div>
            <div className="authority">
              <h4 className="sub-heading text-black">Supervisor Coordinator</h4>
              <p
                className="para-medium text-black mb-0"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                23, May 2022
              </p>
            </div>
            <div className="authority">
              <h4 className="sub-heading text-black">HR Manager</h4>
              <p
                className="para-medium text-black mb-0"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                23, May 2022
              </p>
            </div>
          </div>
        </div>
      </LetterFormatB>
    </div>
  );
};

export default FinalExitLetter;
