import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { AddEmployeeProps } from "../AddEmployee/add-employee-container";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEditOtherDetails,
  setEditLegalInfo,
  setEditEvaluationDetails,
  setEditAgreementDetails,
  setActiveTab,
} from "../../../store/slices/employeeSlice";
import { LoaderWhite } from "../../Lotties";
import OfferLetterAllowanceSet from "../AddEmployee/offer-letter-allowances";

interface Props {
  values: AddEmployeeProps;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setShow: any;
  setErrorContent: any;
}

const EditOtherDetails = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setShow,
  setErrorContent,
}: Props) => {
  // Redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      title: string;
      amount: number;
      category: string;
    }[]
  >(values.allowances || []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        amount: 0,
        category: "",
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      title: string;
      amount: number;
      category: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <OfferLetterAllowanceSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);

  useEffect(() => {
    values.allowances = experienceData;
  }, [experienceData]);
  return (
    <Row>
      <Col sm="12">
        <h3 className="sub-heading">Other Details</h3>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Duty Hours
          </h4>
          <Form.Control
            type="text"
            name="dutyHours"
            placeholder="Enter duty hours"
            onChange={(e) => {
              //     accept only numbers
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values.dutyHours}
            className={
              touched.dutyHours && errors.dutyHours
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          />
          {touched.dutyHours && errors.dutyHours ? (
            <div className="invalid-feedback">{errors.dutyHours}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Salary Period
          </h4>
          <Form.Select
            name="salaryPeriod"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.salaryPeriod}
            className={
              touched.salaryPeriod && errors.salaryPeriod
                ? "is-invalid custom-input border-fill"
                : "custom-input border-fill"
            }
          >
            <option value="">Select salary period</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>
          </Form.Select>

          {touched.salaryPeriod && errors.salaryPeriod ? (
            <div className="invalid-feedback">{errors.salaryPeriod}</div>
          ) : null}
        </Form.Group>
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setEditLegalInfo(true));
              dispatch(setEditOtherDetails(false));
              dispatch(setActiveTab("Legal Details"));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <div className="w-100">
            <button
              className="btn primary-button normal-font-weight me-3"
              type="submit"
              style={{ width: "15%" }}
            >
              {state.empLoading ? (
                <LoaderWhite height={30} width={30} />
              ) : (
                "Update"
              )}
            </button>
            <button
              className="btn primary-button normal-font-weight"
              onClick={() => {
                dispatch(setEditOtherDetails(false));
                dispatch(setEditAgreementDetails(true));
                dispatch(setActiveTab("Agreement Details"));
              }}
              type="button"
              style={{ width: "15%" }}
            >
              Next <img src={Common.ArrowRightWhite} alt="next" />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default EditOtherDetails;
