import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { LoaderBlue } from "../Lotties";
import { useUploadImageMutation } from "../../services/images-upload-service";
import { useAppSelector } from "../../store/hooks";
import DatePicker from "react-datepicker";

interface LanguageProps {
  data: {
    name: string;
    status: string;
    attachement: string;
    validFrom: string;
    validTo: string;
    charges: number;
  };
  onRemove: (key: string) => void;
  onInputChange: (data: {
    name: string;
    status: string;
    attachement: string;
    validFrom: string;
    validTo: string;
    charges: number;
  }) => void;
  uniqueId: string;
}

const IqamaProcessesSet: React.FC<LanguageProps> = ({
  onRemove,
  onInputChange,
  data,
  uniqueId,
}) => {
  const otherNameData = data.name === "Other" ? data.name : "";
  const [name, setName] = useState(data.name);
  const [status, setStatus] = useState(data.status);
  const [charges, setCharges] = useState(data.charges);
  const [attachement, setAttachement] = useState(data.attachement);
  const [otherName, setOtherName] = useState(otherNameData);
  const [validFrom, setValidFrom] = useState(data.validFrom);
  const [validTo, setValidTo] = useState(data.validTo);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [workPermitType, setWorkPermitType] = useState("Valid Till");
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const profileData = useAppSelector((state) => state.settings.profileData);

  const handleInputChange = () => {
    onInputChange({
      name: name === "Other" ? otherName : name,
      status,
      charges,
      attachement,
      validFrom,
      validTo,
    });
  };

  useEffect(() => {
    handleInputChange();
  }, [name, status, charges, attachement, otherName, validFrom, validTo]);

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("myImage", file);

      const data = {
        accessToken: accessToken || "",
        permission: profileData?.permissions.addEmployee || false,
        payload: formData,
      };

      try {
        const result = await uploadImage(data).unwrap();
        setAttachement(result.data.Profileimage);
        setImageLoading(false);
      } catch (e: any) {
        setImageLoading(false);
      }
    }
  };

  // Using the URL API to extract the pathname
  const pathname = attachement ? new URL(attachement).pathname : "";

  // Extracting the file name with extension from the pathname
  const fileNameWithExtension = pathname.split("/").pop();

  return (
    <div className="mb-4">
      <Row className="align-items-end">
        <Col sm="10">
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Type
                </h4>
                <Form.Select
                  name="name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setWorkPermitType("Valid Till");
                  }}
                  value={name}
                  className="custom-input border-fill"
                >
                  <option value="">Select Type</option>
                  <option value="Medical Checkup">Medical Checkup</option>
                  <option value="Work Permit">Work Permit</option>
                  <option value="Passport Department">
                    Passport Department
                  </option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {name === "Other" && (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Other
                  </h4>
                  <Form.Control
                    type="text"
                    name="otherName"
                    placeholder="Enter name"
                    onChange={(e) => setOtherName(e.target.value)}
                    value={otherName}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            )}
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Charges
                </h4>
                <Form.Control
                  type="number"
                  name="charges"
                  placeholder="Enter charges"
                  onChange={(e) => {
                    // Accept numbers and float values
                    const re = /^[0-9\b]*(\.\d{0,2})?$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCharges(Number(e.target.value));
                    }
                  }}
                  value={charges}
                  className="custom-input border-fill"
                />
              </Form.Group>
            </Col>
            {name === "Work Permit" ? (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Work Permit Type
                  </h4>
                  <Form.Select
                    name="workPermitType"
                    onChange={(e) => {
                      setValidTo("");
                      setWorkPermitType(e.target.value);
                    }}
                    value={workPermitType}
                    className="custom-input border-fill"
                  >
                    <option value="">Select work permit type</option>
                    <option value="Valid Till">Valid Till</option>
                    <option value="Malwi">Exit Work Permit</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Valid From
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="validFrom"
                      selected={validFrom ? new Date(validFrom) : null}
                      onChange={(e: any) => setValidFrom(e)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select valid from"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
            )}
            {workPermitType === "Malwi" ? (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Work Permit Text
                  </h4>
                  <Form.Control
                    type="text"
                    name="validTo"
                    placeholder="Enter work permit text"
                    onChange={(e) => {
                      setValidTo(e.target.value);
                    }}
                    value={validTo}
                    className="custom-input border-fill"
                  />
                </Form.Group>
              </Col>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Valid To
                  </h4>
                  <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                    <DatePicker
                      name="validTo"
                      selected={validTo ? new Date(validTo) : null}
                      onChange={(e: any) => setValidTo(e)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      placeholderText="Select valid to"
                      className="w-100 custom-input"
                      dateFormat="yyyy-MM-d"
                    />
                    <img
                      src={Common.Calendar}
                      className="calendar-date-recur"
                      alt="calendarblack"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
            )}
            <Col sm="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Status
                </h4>
                <Form.Select
                  name="status"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  className="custom-input border-fill"
                >
                  <option value="">Select Status</option>
                  <option value="In-process">In-process</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="8">
              <Form.Group>
                {attachement ? null : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Attachment
                    </h4>
                  </div>
                )}
                {imageLoading ? (
                  <LoaderBlue height={50} width={50} />
                ) : (
                  <>
                    {attachement ? (
                      <div className="d-flex">
                        <div className="text-center">
                          <img
                            src={
                              fileNameWithExtension === "jpg" ||
                              fileNameWithExtension === "png" ||
                              fileNameWithExtension === "jpeg"
                                ? Common.ImageIcon
                                : Common.FileIcon
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                            alt="employee-image"
                          />
                          <p className="para-medium textBlack text-center">
                            {fileNameWithExtension}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <Form.Label
                        className="profile-image-label cursor-pointer d-flex align-items-center justify-content-center"
                        style={{ height: "auto", padding: "8px 24px" }}
                      >
                        <Form.Control
                          type="file"
                          name="profileImage"
                          onChange={handleUploadImage}
                          accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, docx, .xlsx, .xls"
                          className="d-none"
                          multiple={false}
                        />
                        <div className="text-center d-flex align-items-center gap-3">
                          <div
                            className="add-profile-image d-flex align-items-center justify-content-center mx-auto"
                            style={{ width: "30px", height: "30px" }}
                          >
                            <img
                              src={EmployeeIcons.Plus}
                              width="25px"
                              alt="plus"
                            />
                          </div>
                          <p
                            className="upload-para mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            Upload PNG, JPG, PDF, CSV, DOCS, XLSX File only
                          </p>
                        </div>
                      </Form.Label>
                    )}
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm="2">
          <div className="d-flex gap-2 align-items-center">
            <button
              onClick={() => {
                onRemove(uniqueId);
              }}
              className="btn primary-button"
              type="button"
            >
              <img src={Common.Cross} height="20px" width="20px" alt="cross" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IqamaProcessesSet;
