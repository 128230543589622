import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import { Notification, Approval } from "../../interfaces/notifications";

interface NotificationsResponse {
  data: {
    notifications: {
      data: Notification[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface ApprovalResponse {
  data: {
    approvals: {
      data: Approval[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export const notificationsAPI = createApi({
  reducerPath: "/api/notification",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getNotifications: builder.query<
      NotificationsResponse,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/all-notifications/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    readNotification: builder.mutation({
      query: (data) => ({
        url: `/read-notifiaction/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteNotification: builder.mutation({
      query: (data) => ({
        url: `/delete-notification/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    // Approvals
    getApprovals: builder.query<
      ApprovalResponse,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/all-approvals/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    afoApproval: builder.mutation({
      query: (data) => ({
        url: `/afo-approval/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    aeApproval: builder.mutation({
      query: (data) => ({
        url: `/ae-approval/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    scApproval: builder.mutation({
      query: (data) => ({
        url: `/sc-approval/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    approval: builder.mutation({
      query: (data) => ({
        url: `/approve-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useDeleteNotificationMutation,
  useGetApprovalsQuery,
  useAfoApprovalMutation,
  useAeApprovalMutation,
  useScApprovalMutation,
  useApprovalMutation,
} = notificationsAPI;
