import React from "react";
import InternalLayout from "../components/InternalLayout";
import VendorComponent from "../components/Vendors";
import Content from "../components/Vendors/get-all-vendors-api";
import withAuth from "../config/private-route";

const Vendors = () => {
  return (
    <InternalLayout>
      <Content />
      <VendorComponent />
    </InternalLayout>
  );
};
export default withAuth(Vendors);
