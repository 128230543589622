import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Letters
import { useGetSalaryIncrementsQuery } from "../../services/Letters";
import {
  setSalaryIncrements,
  setSalaryIncrementLoading,
  setSalaryIncrementError,
  setSalaryIncrementTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const salaryIncrementChange = useAppSelector(
    (state) => state.common.salaryIncrementChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Salary increment Requests
  // **************
  // **************
  const {
    data: allSalaryIncrements,
    isLoading: salaryIncrementLoading,
    isError: salaryIncrementError,
    refetch: salaryIncrementRefetch,
  } = useGetSalaryIncrementsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.salaryIncrementPageNo,
    limit: state.salaryIncrementPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (salaryIncrementLoading) {
      dispatch(setSalaryIncrementLoading(true));
    }
    if (salaryIncrementError) {
      dispatch(setSalaryIncrementError("Something went wrong"));
      dispatch(setSalaryIncrementLoading(false));
    }
    if (allSalaryIncrements) {
      dispatch(
        setSalaryIncrements(
          allSalaryIncrements.data.allIncrements[0].data || [],
        ),
      );
      dispatch(
        setSalaryIncrementTotalPages(
          allSalaryIncrements.data.allIncrements[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setSalaryIncrementLoading(false));
    }
  }, [
    accessToken,
    userID,
    allSalaryIncrements,
    profileData,
    state.salaryIncrementPageNo,
    state.salaryIncrementPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    salaryIncrementRefetch();
  }, [salaryIncrementChange]);

  return <></>;
};
export default Content;
