import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config";
import {
  Employees,
  EmpDetail,
  AllEmployees,
  ProbationEmployee,
  AbscondedEmployee,
  DeletedEmployees,
  Statement,
  EmployeePayslip,
} from "../../interfaces/employee";

interface EmployeeList {
  data: {
    employees: {
      data: Employees[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface EmployeePayslipList {
  data: {
    empPayrolls: {
      data: EmployeePayslip[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface DeletedEmployeeList {
  data: {
    deleted: {
      data: DeletedEmployees[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

interface AllEmployeesList {
  data: {
    employees: AllEmployees[];
  };
}

export interface ProbationEmployeeList {
  data: {
    probation: {
      data: ProbationEmployee[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export interface AbscondedEmployeeList {
  data: {
    absconds: {
      data: AbscondedEmployee[];
      metadata: {
        total: number;
        page: number;
      }[];
    }[];
  };
}

export interface EmployeeDetail {
  data: {
    employees: EmpDetail[];
  };
}

interface Statements {
  data: {
    statement: Statement;
  };
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

export const employeeAPI = createApi({
  reducerPath: "/api/employee",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    getAllEmployees: builder.query<
      EmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-employees/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllActiveEmployees: builder.query<
      EmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-active-employees/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllDeletedEmployees: builder.query<
      DeletedEmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-all-deleted-employees/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getAllEmployeesList: builder.query<
      AllEmployeesList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
      }
    >({
      query: (data) => ({
        url: `/get-all-employees-ids/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    getEmployeeDetail: builder.mutation<
      EmployeeDetail,
      { userID: string; accessToken: string; payload: any; permission: boolean }
    >({
      query: (data) => ({
        url: `/get-employee-detail/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    addEmployee: builder.mutation({
      query: (data) => ({
        url: `/add-employee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editEmployee: builder.mutation({
      query: (data) => ({
        url: `/edit-employee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteEmployee: builder.mutation({
      query: (data) => ({
        url: `/delete-employee/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllFilterEmployees: builder.mutation<
      EmployeeList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/get-all-filtered-employees/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editEmployeeTimeline: builder.mutation({
      query: (data) => ({
        url: `/update-timeline/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Probation Employees
    getAllProbationEmployees: builder.query<
      ProbationEmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/all-probations-lists/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addProbationEmployee: builder.mutation({
      query: (data) => ({
        url: `/add-probation-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editProbationEmployee: builder.mutation({
      query: (data) => ({
        url: `/edit-probation-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteProbationEmployee: builder.mutation({
      query: (data) => ({
        url: `/delete-probation-request/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllFilterProbationEmployees: builder.mutation<
      ProbationEmployeeList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/all-filtered-probation-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Absconded Employees
    getAllAbscondedEmployees: builder.query<
      AbscondedEmployeeList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/all-absconds-lists/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
        },
      }),
    }),
    addAbscondedEmployee: builder.mutation({
      query: (data) => ({
        url: `/add-abscond/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editAbscondedEmployee: builder.mutation({
      query: (data) => ({
        url: `/edit-abscond/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    getAllFilterAbscondedEmployees: builder.mutation<
      AbscondedEmployeeList,
      { userID: string; permission: boolean; accessToken: string; payload: any }
    >({
      query: (data) => ({
        url: `/all-filtered-absconds-lists/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    deleteAbscondedEmployee: builder.mutation({
      query: (data) => ({
        url: `/delete-abscond/${data.permission}/${data.userID}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    //   Statement
    getStatements: builder.query<
      Statements,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        empID: string;
        fromDate: string;
        toDate: string;
      }
    >({
      query: (data) => ({
        url: `/employee-statement/${data.empID}/${data.permission}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
    //   Statement
    getPayslips: builder.query<
      EmployeePayslipList,
      {
        userID: string;
        accessToken: string;
        permission: boolean;
        empID: string;
        fromDate: string;
        toDate: string;
        pageNo: number;
        pageSize: number;
      }
    >({
      query: (data) => ({
        url: `/get-employee-payrolls/${data.empID}/${data.permission}/${data.pageNo}/${data.pageSize}/${data.userID}`,
        method: "GET",
        headers: {
          Authorization: `${data.accessToken}`,
          "from-date": `${formatedDate(data.fromDate)}`,
          "to-date": `${formatedDate(data.toDate)}`,
        },
      }),
    }),
  }),
});

export const {
  useAddEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetAllActiveEmployeesQuery,
  useGetEmployeeDetailMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetAllFilterEmployeesMutation,
  useGetAllEmployeesListQuery,
  useGetAllProbationEmployeesQuery,
  useAddProbationEmployeeMutation,
  useEditProbationEmployeeMutation,
  useDeleteProbationEmployeeMutation,
  useGetAllAbscondedEmployeesQuery,
  useAddAbscondedEmployeeMutation,
  useEditAbscondedEmployeeMutation,
  useGetAllDeletedEmployeesQuery,
  useGetAllFilterProbationEmployeesMutation,
  useGetAllFilterAbscondedEmployeesMutation,
  useDeleteAbscondedEmployeeMutation,
  useEditEmployeeTimelineMutation,
  useGetStatementsQuery,
  useGetPayslipsQuery,
} = employeeAPI;
