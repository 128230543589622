import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { Camp } from "../../interfaces/employee";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterCampTotalPages,
  setCampListLoading,
} from "../../store/slices/campsSlice";
// API imports
import { useGetFilterCampListMutation } from "../../services/Camps";

interface Props {
  show?: boolean;
  setShow?: any;
  setCamps?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  camps: Camp[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  campCity: string;
  campCapacityFrom: string;
  campCapacityTo: string;
  campContractFrom: string;
  campContractTo: string;
  campMonthlyRentFrom: string;
  campMonthlyRentTo: string;
}

const CampsFilterModal = ({
  show,
  setShow,
  camps,
  setCamps,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.camps);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterCampList] = useGetFilterCampListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setCampListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.filterCampPageNo,
          limit: state.filterCampPageSize,
          campCity: filterInput.campCity,
          campCapacityFrom: parseInt(filterInput.campCapacityFrom),
          campCapacityTo: parseInt(filterInput.campCapacityTo),
          campContractFrom: filterInput.campContractFrom,
          campContractTo: filterInput.campContractFrom,
          campMonthlyRentFrom: parseInt(filterInput.campMonthlyRentFrom),
          campMonthlyRentTo: parseInt(filterInput.campMonthlyRentTo),
        },
      };
      const response = await getFilterCampList(data).unwrap();
      if (response) {
        setCamps(response.data.camps[0].data);
        dispatch(
          setFilterCampTotalPages(response.data.camps[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setCampListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setCampListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showEmployee || false,
          payload: {
            page: state.filterCampPageNo,
            limit: state.filterCampPageSize,
            campId: query,
            campCity: filterInput.campCity,
            campCapacityFrom: parseInt(filterInput.campCapacityFrom),
            campCapacityTo: parseInt(filterInput.campCapacityTo),
            campContractFrom: filterInput.campContractFrom,
            campContractTo: filterInput.campContractFrom,
            campMonthlyRentFrom: parseInt(filterInput.campMonthlyRentFrom),
            campMonthlyRentTo: parseInt(filterInput.campMonthlyRentTo),
          },
        };
        const response = await getFilterCampList(data).unwrap();
        if (response) {
          setCamps(response.data.camps[0].data);
          dispatch(
            setFilterCampTotalPages(response.data.camps[0].metadata[0].total),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setCampListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterCampPageNo, state.filterCampPageSize, query]);

  const handleReset = () => {
    setCamps(camps);
    setFilterInput({
      campCity: "",
      campCapacityFrom: "",
      campCapacityTo: "",
      campContractFrom: "",
      campContractTo: "",
      campMonthlyRentFrom: "",
      campMonthlyRentTo: "",
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Camp List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="12">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp City
                    </h4>
                    <Form.Control
                      type="text"
                      name="campCity"
                      placeholder="Enter camp city"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          campCity: e.target.value,
                        })
                      }
                      value={filterInput.campCity}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Capacity From
                    </h4>
                    <Form.Control
                      type="text"
                      name="campCapacityFrom"
                      placeholder="Capacity From"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            campCapacityFrom: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.campCapacityFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Capacity To
                    </h4>
                    <Form.Control
                      type="text"
                      name="campCapacityTo"
                      placeholder="Capacity To"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            campCapacityTo: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.campCapacityTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Contract From
                    </h4>
                    <Form.Control
                      type="date"
                      name="campContractFrom"
                      placeholder="From date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          campContractFrom: e.target.value,
                        })
                      }
                      value={filterInput.campContractFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Contract To
                    </h4>
                    <Form.Control
                      type="date"
                      name="campContractTo"
                      placeholder="To date"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          campContractTo: e.target.value,
                        })
                      }
                      value={filterInput.campContractTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Monthly Rent From
                    </h4>
                    <Form.Control
                      type="text"
                      name="campMonthlyRentFrom"
                      placeholder="Monthly Rent From"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            campMonthlyRentFrom: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.campMonthlyRentFrom}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Monthly Rent To
                    </h4>
                    <Form.Control
                      type="text"
                      name="campMonthlyRentTo"
                      placeholder="Monthly Rent To"
                      onChange={(e) => {
                        //     accept only numbers
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFilterInput({
                            ...filterInput,
                            campMonthlyRentTo: e.target.value,
                          });
                        }
                      }}
                      value={filterInput.campMonthlyRentTo}
                      className="custom-input border-fill"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CampsFilterModal;
