import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { Common } from "../../assets/images";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useAddAbscondedEmployeeMutation } from "../../services/Employees";
import { setAddAbscondedLoading } from "../../store/slices/employeeSlice";
import { setAbscondedChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import ApprovalsSet from "../Employees/approvals-set";

const EmployeeAbsconding = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [empID, setEmpID] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [abscondedDate, setAbscondedDate] = React.useState("");
  const [abscondedLocation, setAbscondedLocation] = React.useState("");
  const [reasonOfAbscond, setReasonOfAbscond] = React.useState("");
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employees = useAppSelector((state) => state.employee.allEmployeesList);

  const abscondedChange = useAppSelector(
    (state) => state.common.abscondedChange,
  );
  // Options
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // get all userId from languageData array
  const userIds =
    languageData.length > 0 ? languageData.map((data) => data.userId) : [];

  // API call for add salary increment
  const [addAbscondedEmployee] = useAddAbscondedEmployeeMutation();
  const handleSubmit = async () => {
    dispatch(setAddAbscondedLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          empId: empID,
          abscondDate: abscondedDate,
          abscondLocation: abscondedLocation,
          reasonOfAbscond: reasonOfAbscond,
          abscondApprovals: [
            {
              uId: Math.random().toString(36).substring(7),
              userId: superadmin?._id || "",
              date: "",
              name: superadmin?.firstName + " " + superadmin?.lastName || "",
              role: superadmin?.role || "",
              status: "Pending",
            },
            ...languageData,
          ],
          ids: [superadmin?._id || "", ...userIds],
          link: `/employees/absconded-employees`,
        },
      };
      await addAbscondedEmployee(data).unwrap();
      dispatch(setAbscondedChange(!abscondedChange));

      navigate("/employees/absconded-employees");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddAbscondedLoading(false));
    }
  };

  const employee = employees.find((item) => item.empId === parseInt(empID));

  return (
    <div className="acknowledgment section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <h2 className="heading">Employee Absconding</h2>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </Col>
          <Col sm="4">
            <div className="search-emp">
              <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
                Select Employee by Id
              </h4>
              <Form.Group className="position-relative input-design d-flex align-items-center">
                <SelectSearch
                  options={options}
                  onChange={(value: any) => setEmpID(value)}
                  value={empID}
                  search={true}
                  placeholder="Select Employee by Id"
                />
                <img src={Common.ArrowDown} alt="message" />
              </Form.Group>
            </div>
          </Col>
          <Col sm="12">
            <div className="emp-detail mt-5">
              <h4 className="sub-heading mb-4">Employee Detail</h4>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {employee?.empId || "Select Emp Id..."}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {employee?.empName || "Select Emp Id..."}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Status:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {employee?.empStatus || "Select Emp Id..."}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Nationality:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {employee?.empNationality || "Select Emp Id..."}
                    </p>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="emp-detail-item mb-4 add-user mt-5">
                    <h4 className="sub-heading mb-4">Absconding Detail</h4>
                    <Row>
                      <Col sm="4">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Date:
                          </h4>
                          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                            <DatePicker
                              name="returnDate"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                              placeholderText="Date of birth"
                              className="w-100 custom-input"
                              dateFormat="yyyy-MM-d"
                              selected={
                                abscondedDate
                                  ? new Date(abscondedDate)
                                  : new Date()
                              }
                              onChange={(date: any) => {
                                setAbscondedDate(date);
                              }}
                            />
                            <img
                              src={Common.Calendar}
                              className="calendar-date-recur"
                              alt="calendarblack"
                            />
                          </Form.Group>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Absconding Location
                          </h4>
                          <Form.Control
                            type="text"
                            name="abscondingLocation"
                            onChange={(e) =>
                              setAbscondedLocation(e.target.value)
                            }
                            placeholder="Enter absconding location"
                            value={abscondedLocation}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            Reason of Abscond
                          </h4>
                          <Form.Control
                            type="text"
                            name="reasonOfAbscond"
                            placeholder="Enter reason of abscond"
                            value={reasonOfAbscond}
                            onChange={(e) => setReasonOfAbscond(e.target.value)}
                            className="custom-input border-fill"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="text-end mt-4 d-flex align-items-center gap-4">
                    <button
                      className="btn primary-button normal-font-weight"
                      type="button"
                      onClick={handleAddLanguageSet}
                      style={{ width: "15%" }}
                    >
                      <img
                        src={Common.Plus}
                        width="20px"
                        height="20px"
                        className="me-2"
                        alt="next"
                      />{" "}
                      Approval user
                    </button>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="extra-detail my-4">
                    {languageSets.length > 0 && (
                      <h4 className="sub-heading normal-font-weight mb-4">
                        Approval will be sent to these users:
                      </h4>
                    )}
                    {languageSets}
                  </div>
                </Col>
                <Col sm="12">
                  <div className="emp-detail-print text-end mt-5">
                    <button
                      className="btn primary-button w-25"
                      onClick={handleSubmit}
                    >
                      {state.addAbscondedLoading ? (
                        <LoaderWhite height={30} width={30} />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default EmployeeAbsconding;
