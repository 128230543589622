import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { SettingsIcons } from "../../assets/images";
// Redux imports
import { Client } from "../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilterClientTotalPages,
  setClientListLoading,
} from "../../store/slices/clientsSlice";
// API imports
import { useGetFilterClientListMutation } from "../../services/Clients";

interface Props {
  show?: boolean;
  setShow?: any;
  setClients?: any;
  filterSearch: boolean;
  setFilterSearch: any;
  clients: Client[];
  query: string;
  filterInput: FormProps;
  setFilterInput: any;
}

export interface FormProps {
  city: string;
  country: string;
  clientStatus: string;
}

const ClientsFilterModal = ({
  show,
  setShow,
  clients,
  setClients,
  setFilterSearch,
  query,
  filterInput,
  setFilterInput,
  filterSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.clients);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [getFilterClientList] = useGetFilterClientListMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setClientListLoading(true));
    setFilterSearch(true);
    setShow(false);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showProject || false,
        payload: {
          page: state.filterClientPageNo,
          limit: state.filterClientPageSize,
          city: filterInput.city,
          country: filterInput.country,
          clientStatus: filterInput.clientStatus,
        },
      };
      const response = await getFilterClientList(data).unwrap();
      if (response) {
        setClients(response.data.clients[0].data);
        dispatch(
          setFilterClientTotalPages(response.data.clients[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setClientListLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setClientListLoading(true));
      // Filter employees based on the form values
      try {
        const data = {
          userID: userID || "",
          accessToken: accessToken || "",
          permission: profileData?.permissions.showProject || false,
          payload: {
            page: state.filterClientPageNo,
            limit: state.filterClientPageSize,
            clientId: query,
            city: filterInput.city,
            country: filterInput.country,
            clientStatus: filterInput.clientStatus,
          },
        };
        const response = await getFilterClientList(data).unwrap();
        if (response) {
          setClients(response.data.clients[0].data);
          dispatch(
            setFilterClientTotalPages(
              response.data.clients[0].metadata[0].total,
            ),
          );
        }
      } catch (e: any) {
      } finally {
        dispatch(setClientListLoading(false));
      }
    };
    if (filterSearch) {
      fetchData();
    }
  }, [state.filterClientPageNo, state.filterClientPageSize, query]);

  const handleReset = () => {
    setClients(clients);
    setFilterInput({
      city: "",
      country: "",
      clientStatus: "",
    });
  };

  const countryCodes = [
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "UAE",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Bangladesh",
      dial_code: "+884",
      code: "BD",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
    },
  ];

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="heading">Filter Client List</h4>
            <button className="btn p-0 border-0" onClick={() => setShow(false)}>
              <img src={SettingsIcons.CrossIcon} alt="edit-icon" />
            </button>
          </div>
          <div className="change-password">
            <Form onSubmit={handleSubmit} className="text-end">
              <Row>
                <Col sm="6">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicEmail"
                  >
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Client Status
                    </h4>
                    <Form.Select
                      name="clientStatus"
                      onChange={(e) =>
                        setFilterInput({
                          ...filterInput,
                          clientStatus: e.target.value,
                        })
                      }
                      value={filterInput.clientStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="In-active">In-active</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-4">
                <button
                  className="btn primary-button text-center w-25 me-2"
                  onClick={handleReset}
                  type="button"
                >
                  Reset
                </button>
                <button
                  className="btn primary-button text-center w-25"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientsFilterModal;
