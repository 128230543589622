import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Dropdown, Table, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons } from "../../assets/images";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setActualSalarySheetPageNo,
  setActualSalarySheetPageSize,
  setActualSalarySheet,
  setShowActualSalarySheetExportDropdown,
  setActualSheetChangeLoading,
  setActualSheetDate,
  setActualType,
} from "../../store/slices/projectSlice";
import { setActualSheetChange } from "../../store/slices/commonSlice";
import { useEditActualSheetMutation } from "../../services/Projects";
import SelectSearch from "react-select-search";
import Content from "./get-actual-timesheet-data";
import { Timesheet } from "../../interfaces/timesheet";
import { AttendanceLog } from "../../interfaces/attendance";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import { LoaderBlue, LoaderWhite } from "../Lotties";
import { exportToCsv } from "../../config";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
import ConfirmPostSalarySheetModal from "../Modals/post-salary-sheet-modal";

interface DayAccumulator {
  [key: string]: string | number;
}

const formatedDate = (date: string) => {
  const currentDate: Date = new Date(date); // Convert the date string to a Date object
  const month: string = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice to get last 2 characters
  const day: string = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice to get last 2 characters
  const year: number = currentDate.getFullYear();

  // Construct the formatted date string
  const formattedDate: string = `${month}/${day}/${year}`;
  return formattedDate;
};

const ActualSalarySheet = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const projectState = useAppSelector((state) => state.project);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [checked, setChecked] = useState<{
    [key: number]: boolean;
  }>({});
  const [showPostSalarySheetModal, setShowPostSalarySheetModal] =
    useState(false);
  const [show, setShow] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [successContent, setSuccessContent] = React.useState("");
  const [errorContent, setErrorContent] = React.useState("");
  const actualSheetChange = useAppSelector(
    (state) => state.common.actualSheetChange,
  );
  const getlastDayOfMonth = () => {
    const actualSheetDate = new Date(projectState.actualSheetDate);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const actualYear = actualSheetDate.getFullYear();
    const actualMonth = actualSheetDate.getMonth();

    if (actualYear === currentYear && actualMonth === currentMonth) {
      // If the actualSheetDate is within the current month, return the current date
      return new Date();
    } else {
      // If the actualSheetDate is not in the current month, calculate the last date of the month
      return new Date(actualYear, actualMonth + 1, 0);
    }
  };
  const currentDate = getlastDayOfMonth();
  const monthYearString = new Date(projectState.actualSheetDate).toLocaleString(
    "default",
    {
      month: "long",
      year: "numeric",
    },
  );

  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };

  // Pagination && Filter
  const [filteredData, setFilteredData] = useState<Timesheet[]>([]);
  const totalPages = Math.ceil(
    projectState.actualSalarySheetTotalRecords /
      projectState.actualSalarySheetPageSize,
  );
  const totalCount = projectState.actualSalarySheetTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((projectState.actualSalarySheetPageNo - 1) / showPages) *
    showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (projectState.actualSalarySheetPageNo > totalPages - showPages) {
      return;
    }
    dispatch(
      setActualSalarySheetPageNo(
        projectState.actualSalarySheetPageNo + showPages,
      ),
    );
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (projectState.actualSalarySheetPageNo <= 5) {
      return;
    }
    dispatch(
      setActualSalarySheetPageNo(
        projectState.actualSalarySheetPageNo - showPages,
      ),
    );
  };

  const handlePageChange = (page: any) => {
    dispatch(setActualSalarySheetPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setActualSalarySheetPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(projectState.actualTimesheet);
  }, [projectState.actualTimesheet]);

  const generateDaysOfWeek = () => {
    const daysInMonth = currentDate.getDate();
    const daysOfWeek = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day,
      );
      const dayOfMonth = date.getDate();
      const dayOfWeek = date.toLocaleString("default", { weekday: "short" });

      daysOfWeek.push(
        <React.Fragment key={day}>
          {dayOfMonth}
          <br />
          {dayOfWeek}
        </React.Fragment>,
      );
    }
    return daysOfWeek;
  };

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Emp Name",
      "Category",
      "Total Salary",
      "Project Id.",
      ...generateDaysOfWeek(),
      "Total Hours",
      "Total Overtime",
      "Gross Salary",
    ],
    tableData: filteredData,
  };
  const projectOptions = projectState.projectIds.map((item) => ({
    name: item.projectId + " - " + item.projectName,
    value: item.projectId,
  }));

  // Add custom options for "management" and "idol employee"
  projectOptions.splice(0, 0, { name: "All", value: "All" });
  profileData.permissions.roleName === "Super Admin" &&
    projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  profileData.permissions.roleName === "General Manager" &&
    projectOptions.splice(1, 0, { name: "Management", value: "Management" });
  projectOptions.splice(2, 0, { name: "Idol Employees", value: "Idol" });

  // Calculate current month days
  // const d = new Date();
  // const year = d.getFullYear();
  // const month = d.getMonth() + 1;
  // const daysInMonth = new Date(year, month, 0).getDate();

  const handleHoursChange = (e: any, indexMain: number, dayIndex: number) => {
    const newHours = e.target.value;
    const newFilteredData = filteredData.map((item, index) => {
      if (index === indexMain) {
        // Update the hours for the selected day
        const newAttendances = item.attendances.map((attendance, index) => {
          if (new Date(attendance.date).getDate() === dayIndex + 1) {
            return {
              ...attendance,
              actualHours: parseInt(newHours),
              actualOverTime:
                item.empProjectId === ""
                  ? parseInt(newHours) - item.dutyHours
                  : parseInt(newHours) - item.empProjectWorkingHours,
              actualStatus:
                parseInt(newHours) === 0 ? "Absent" : attendance.actualStatus,
            };
          }
          return attendance;
        });
        return {
          ...item,
          attendances: newAttendances,
        };
      }
      return item;
    });
    setFilteredData(newFilteredData);
  };

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };
  const employeeOptions = state.allEmployeesList.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  // Calculate current month days
  const d = projectState.actualSheetDate
    ? new Date(projectState.actualSheetDate)
    : new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const totalDaysInMonth = new Date(year, month, 0).getDate();

  const basicSalaryTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.salaryTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.actualSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.basicSalary;
    } else {
      return mEmployee ? mEmployee.basicSalary : 0;
    }
  };

  const dutyHoursTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.salaryTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.actualSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.dutyHours;
    } else {
      return mEmployee ? mEmployee.dutyHours : 0;
    }
  };

  const projectIdTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.transferTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.actualSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      const project = projectState.projectIds.find(
        (item) => item.projectId === timeLine.projectId,
      );
      return project ? project.projectName : "";
    } else {
      const project = projectState.projectIds.find(
        (item) => item.projectId === mEmployee?.empProjectId,
      );
      return mEmployee ? (project ? project.projectName : "") : "";
    }
  };

  const categoryTimelineFunction = (index: number) => {
    const mEmployee = state.allEmployeesList.find(
      (item) => item.empId === index,
    );
    const timeLine = mEmployee
      ? mEmployee.transferTimeline.find((item) => {
          const fromDate = new Date(item.fromDate);
          const toDate = new Date(item.toDate);
          const currentDate = new Date(projectState.actualSheetDate);
          currentDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
          return (
            new Date(currentDate) >= new Date(fromDate) &&
            new Date(currentDate) <= new Date(toDate)
          );
        })
      : null;
    if (timeLine) {
      return timeLine.category;
    } else {
      return mEmployee ? mEmployee.empProjectCategory : "";
    }
  };

  const handleSelectAll = () => {
    const allEmpIds = tableData.tableData.map((item) => item.empId);
    const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);
    if (allEmpIdsChecked) {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = false;
      });
      setChecked(newChecked);
    } else {
      const newChecked = { ...checked };
      allEmpIds.forEach((empId) => {
        newChecked[empId] = true;
      });
      setChecked(newChecked);
    }
  };

  const handleSingleSelect = (empId: number) => {
    setChecked({
      ...checked,
      [empId]: !checked[empId],
    });
  };

  const renderTableData = () => {
    const daysInMonth = currentDate.getDate();
    const daysOfMonth = Array.from(
      { length: daysInMonth },
      (_, index) => index + 1,
    );
    return tableData.tableData.map((item, index) => {
      const rowData = daysOfMonth.map((dayOfMonth, dayIndex) => {
        const attendance = item.attendances.find(
          (attendance) => new Date(attendance.date).getDate() === dayOfMonth,
        );

        const date = attendance
          ? new Date(attendance.date).toLocaleString("default", {
              weekday: "short",
            })
          : "";
        const fridayHours = attendance
          ? attendance.hours - dutyHoursTimelineFunction(item.empId)
          : "-";
        // If friday hours is 0, then show '-'
        const fridayHoursZero = fridayHours === 0 ? "-" : fridayHours;

        const initialValue = attendance ? attendance.actualHours : 0; // Get initial value

        // Determine if the value has changed;

        return (
          <td key={dayIndex}>
            {attendance ? (
              <>
                {print ? (
                  date === "Fri" ? (
                    fridayHoursZero
                  ) : (
                    attendance.actualHours
                  )
                ) : (
                  <>
                    {attendance.updationStatus ? (
                      <input
                        type="number"
                        value={initialValue}
                        className="timesheet-input"
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            e.target.value = "0";
                            handleHoursChange(e, index, dayIndex);
                          }
                        }}
                        onChange={(e) => handleHoursChange(e, index, dayIndex)}
                      />
                    ) : date === "Fri" ? (
                      fridayHoursZero
                    ) : (
                      attendance.actualHours
                    )}
                  </>
                )}
              </>
            ) : (
              "-"
            )}
          </td>
        );
      });

      const totalHours = item.attendances.reduce((acc, curr) => {
        return acc + curr.actualHours;
      }, 0);

      const totalOvertime = item.attendances.reduce((acc, curr) => {
        return acc + curr.actualOverTime;
      }, 0);

      const totalSalary = item.attendances.map((attendance) => {
        const findLogDateInSalaryTimeline = item.salaryTimeline.find(
          (item) =>
            new Date(attendance.date) >= new Date(item.fromDate) &&
            new Date(attendance.date) <= new Date(item.toDate),
        );

        const salaryCalculation = (hours: number) => {
          // if (findLogDateInSalaryTimeline) {
          //   const salary = findLogDateInSalaryTimeline.basicSalary;
          //   const perHourSalary =
          //     salary / totalDaysInMonth / findLogDateInSalaryTimeline.dutyHours;
          //   return perHourSalary * hours;
          // }
          const salary = basicSalaryTimelineFunction(item.empId);
          const perHourSalary =
            salary / totalDaysInMonth / dutyHoursTimelineFunction(item.empId);
          return perHourSalary * hours;
        };

        return salaryCalculation(attendance.actualHours);
      });

      const grossSalary = totalSalary.reduce((acc, curr) => {
        return acc + curr;
      }, 0);

      return (
        <tr key={index} style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}>
          {print ? null : (
            <td>
              <Form.Check
                type="checkbox"
                id="tableCheckbox" // Add an id to target the checkbox
                className="table-checkbox justify-content-center"
                name="hideLetterHead"
                checked={checked[item.empId]}
                onChange={() => handleSingleSelect(item.empId)}
              />
            </td>
          )}
          <td>
            {totalCount > 0
              ? (projectState.actualSalarySheetPageNo - 1) *
                  projectState.actualSalarySheetPageSize +
                index +
                1
              : index + 1}
          </td>
          <td>{item.empId}</td>
          <td>{print ? twoWords(item.empName) : item.empName}</td>
          <td>{categoryTimelineFunction(item.empId)}</td>
          <td>{basicSalaryTimelineFunction(item.empId)}</td>
          <td>{projectIdTimelineFunction(item.empId)}</td>
          {rowData}
          {/* Add Total working hours of an employee */}
          <td>{totalHours - totalOvertime}</td>
          <td>{totalOvertime}</td>
          <td>{grossSalary.toFixed(2)}</td>
        </tr>
      );
    });
  };

  const tableFooterData = () => {
    // Sum of all employess working hours of all days
    const totalHoursWorked = filteredData.reduce((total, employee) => {
      // Iterate through each attendance record of the employee and sum up the working hours
      const employeeHours = employee.attendances.reduce((acc, attendance) => {
        return acc + attendance.actualHours;
      }, 0);
      // Add the total working hours of the employee to the total
      return total + employeeHours;
    }, 0);

    const totalOverTime = filteredData.reduce((total, employee) => {
      // Iterate through each attendance record of the employee and sum up the working hours
      const employeeHours = employee.attendances.reduce((acc, attendance) => {
        return acc + attendance.actualOverTime;
      }, 0);
      // Add the total working hours of the employee to the total
      return total + employeeHours;
    }, 0);

    // Sum of all employees salaries
    const totalSalaryOfAnEmployee = filteredData.map((employee) => {
      const totalSalary = employee.attendances.map((attendance) => {
        const findLogDateInSalaryTimeline = employee.salaryTimeline.find(
          (item) =>
            new Date(attendance.date) >= new Date(item.fromDate) &&
            new Date(attendance.date) <= new Date(item.toDate),
        );

        const salaryCalculation = (hours: number) => {
          // if (findLogDateInSalaryTimeline) {
          //   const salary = findLogDateInSalaryTimeline.basicSalary;
          //   const perHourSalary =
          //     salary / totalDaysInMonth / findLogDateInSalaryTimeline.dutyHours;
          //   return perHourSalary * hours;
          // }
          const salary = basicSalaryTimelineFunction(employee.empId);
          const perHourSalary =
            salary /
            totalDaysInMonth /
            dutyHoursTimelineFunction(employee.empId);
          return perHourSalary * hours;
        };

        return salaryCalculation(attendance.actualHours);
      });

      return totalSalary.reduce((acc, curr) => {
        return acc + curr;
      }, 0);
    });

    const totalGrossSalary = totalSalaryOfAnEmployee.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return (
      <tr>
        <td colSpan={print ? 6 : 7} className="text-end">
          Total
        </td>
        {/* Sum of all employees working hours of one day */}
        {generateDaysOfWeek().map((item, index) => (
          <td key={index}>
            {filteredData.reduce((acc, curr) => {
              const attendance = curr.attendances.find(
                (attendance) =>
                  new Date(attendance.date).getDate() === index + 1,
              );
              return acc + (attendance ? attendance.actualHours : 0);
            }, 0)}
          </td>
        ))}
        <td>{totalHoursWorked - totalOverTime}</td>
        <td>{totalOverTime}</td>
        <td>{totalGrossSalary.toFixed(2)}</td>
      </tr>
    );
  };

  const [editActualSheet] = useEditActualSheetMutation();

  // merged all attendances arrays from filteredData
  function mergeAttendances(arrayOfObjects: Timesheet[]) {
    let mergedAttendance: AttendanceLog[] = [];
    arrayOfObjects.forEach((obj) => {
      mergedAttendance = mergedAttendance.concat(obj.attendances);
    });
    return mergedAttendance;
  }

  const handleUpdateSheet = async () => {
    dispatch(setActualSheetChangeLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addProject || false,
        payload: {
          attandance: mergeAttendances(filteredData),
          link: "/timesheet/actual-salarysheet",
        },
      };
      await editActualSheet(data).unwrap();
      dispatch(setActualSheetChange(!actualSheetChange));
      setSuccessContent("Actual Salary Sheet updated successfully");
      setShowSuccess(true);
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setActualSheetChangeLoading(false));
    }
  };

  const daysInMonths = currentDate.getDate();
  const daysOfMonth = Array.from(
    { length: daysInMonths },
    (_, index) => index + 1,
  );

  const csvData = tableData.tableData.map((item, index) => {
    const dayAccumulator: DayAccumulator = {};

    const rowData = daysOfMonth.map((dayOfMonth, dayIndex) => {
      const attendance = item.attendances.find(
        (attendance) => new Date(attendance.date).getDate() === dayOfMonth,
      );

      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        dayOfMonth,
      );
      const dayOfWeek = date.toLocaleString("default", { weekday: "short" });

      const hours = attendance ? attendance.actualHours : "-"; // Default to 8 if date is missing
      dayAccumulator[`${dayOfMonth + " " + dayOfWeek} `] = hours;
      return hours;
    });

    const totalHours = item.attendances.reduce((acc, curr) => {
      return acc + curr.actualHours;
    }, 0);

    const totalOvertime = item.attendances.reduce((acc, curr) => {
      return acc + curr.actualOverTime;
    }, 0);

    const totalSalary = item.attendances.map((attendance) => {
      const findLogDateInSalaryTimeline = item.salaryTimeline.find(
        (item) =>
          new Date(attendance.date) >= new Date(item.fromDate) &&
          new Date(attendance.date) <= new Date(item.toDate),
      );

      const salaryCalculation = (hours: number) => {
        // if (findLogDateInSalaryTimeline) {
        //   const salary = findLogDateInSalaryTimeline.basicSalary;
        //   const perHourSalary =
        //     salary / totalDaysInMonth / findLogDateInSalaryTimeline.dutyHours;
        //   return perHourSalary * hours;
        // }
        const salary = basicSalaryTimelineFunction(item.empId);
        const perHourSalary =
          salary / totalDaysInMonth / dutyHoursTimelineFunction(item.empId);
        return perHourSalary * hours;
      };

      return salaryCalculation(attendance.actualHours);
    });

    const grossSalary = totalSalary.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return {
      "Sr. No.": index + 1,
      "Emp Code": item.empId,
      "Emp Name": item.empName,
      Category: item.empProjectCategory,
      "Total Salary": item.basicSalary,
      "Project Id.": item.empProjectId,
      // Place dayAccumulator here between "Project Id." and "Total Hours"
      ...dayAccumulator,
      "Total Hours": totalHours - totalOvertime,
      "Total Overtime": totalOvertime,
      "Gross Salary": grossSalary.toFixed(2),
    };
  });

  const allSelectedEmployeesAttendances = tableData.tableData
    .filter((item) => checked[item.empId])
    .map((item) => item.attendances);

  const allAttendancesIds = allSelectedEmployeesAttendances.map((attendances) =>
    attendances.map((attendance) => attendance._id),
  );

  const allAttendancesIdsFlattened = allAttendancesIds.flat();

  const payload = tableData.tableData
    .filter((item) => checked[item.empId])
    .map((item, index) => {
      const totalHours = item.attendances.reduce((acc, curr) => {
        return acc + curr.actualHours;
      }, 0);

      const totalOvertime = item.attendances.reduce((acc, curr) => {
        return acc + curr.actualOverTime;
      }, 0);

      const perHourSalary =
        basicSalaryTimelineFunction(item.empId) /
        totalDaysInMonth /
        dutyHoursTimelineFunction(item.empId);

      const workedHours = totalHours - totalOvertime;
      const attendancesIdz = item.attendances.map(
        (attendance) => attendance._id,
      );

      return {
        date: formatedDate(projectState.actualSheetDate),
        empId: item.empId,
        workedHours: workedHours,
        overtimeHours: totalOvertime,
        perHourCost: perHourSalary,
        basicSalary: basicSalaryTimelineFunction(item.empId),
        totalSalary: workedHours * perHourSalary,
        totalOvertimeAmount: totalOvertime * perHourSalary,
        status: "Un paid",
        projectId: projectIdTimelineFunction(item.empId),
      };
    });

  const payloadEmpIds = payload.map((item) => item.empId);

  const handleProjectId = (value: any) => {
    const oldArray =
      projectState.actualType === "All"
        ? []
        : projectState.actualType === "Management"
          ? []
          : projectState.actualType === "Idol"
            ? []
            : projectState.actualType === "Employee"
              ? []
              : projectState.actualSalarySheet;
    if (value === "All") {
      dispatch(setActualSalarySheet([value]));
      dispatch(setActualType("All"));
      return;
    }
    if (value === "Management") {
      dispatch(setActualSalarySheet([value]));
      dispatch(setActualType("Management"));
      return;
    }
    if (value === "Idol") {
      dispatch(setActualSalarySheet([value]));
      dispatch(setActualType("Idol"));
      return;
    }
    if (projectState.actualSalarySheet.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Project ID already selected");
      setShow(true);
      return;
    }
    dispatch(setActualSalarySheet([...oldArray, value]));
    dispatch(setActualType("Project"));
  };

  const handleEmployeeId = (value: any) => {
    const oldArray =
      projectState.actualType !== "Employee"
        ? []
        : projectState.actualSalarySheet;
    if (projectState.actualSalarySheet.includes(value)) {
      window.scrollTo(0, 0);
      setErrorContent("Employee ID already selected");
      setShow(true);
      return;
    }
    dispatch(setActualSalarySheet([...oldArray, value]));
    dispatch(setActualType("Employee"));
  };

  const allEmpIds = tableData.tableData.map((item) => item.empId);

  const allEmpIdsChecked = allEmpIds.every((empId) => checked[empId]);

  return (
    <div className=" mt-2 section px-4 py-4">
      <Content />
      <ConfirmPostSalarySheetModal
        show={showPostSalarySheetModal}
        setShow={setShowPostSalarySheetModal}
        payload={payload}
        empIds={payloadEmpIds}
        date={new Date(projectState.actualSheetDate).toLocaleDateString()}
        postSalaryLoading={projectState.postSalaryLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        actualSheetChange={actualSheetChange}
        attendancesIds={allAttendancesIdsFlattened}
      />
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      {profileData.permissions.showPettyCash ? (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">
                Actual Salary Sheet for the month of {monthYearString}
              </h1>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center gap-4 w-50">
                  <Form.Group className=" w-75" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project ID/Type
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="emp"
                        options={projectOptions}
                        value={projectState.actualSalarySheet}
                        onChange={(value: any) => handleProjectId(value)}
                        search={true}
                        placeholder="Select Project Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Or
                    </h4>
                  </Form.Group>
                  <Form.Group className=" w-75" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Employee ID
                    </h4>
                    <Form.Group className="position-relative input-design d-flex align-items-center mt-2">
                      <SelectSearch
                        name="emp"
                        options={employeeOptions}
                        value={projectState.actualSalarySheet}
                        onChange={(value: any) => handleEmployeeId(value)}
                        search={true}
                        placeholder="Select Emp Id"
                      />
                      <img src={Common.ArrowDown} alt="message" />
                    </Form.Group>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <Dropdown
                    show={projectState.showActualSalarySheetExportDropdown}
                    className="position-relative"
                  >
                    <button
                      id="dropdown-basic"
                      onClick={() => {
                        dispatch(
                          setShowActualSalarySheetExportDropdown(
                            !projectState.showActualSalarySheetExportDropdown,
                          ),
                        );
                      }}
                      className=" bg-transparent dropdown-toggle-white"
                    >
                      Export{" "}
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="ms-2"
                        alt="down-arrow"
                      />
                    </button>
                    <Dropdown.Menu
                      className="mt-1 custom-scrollbar"
                      show={projectState.showActualSalarySheetExportDropdown}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          exportToCsv(csvData, "actual-salary-sheet");
                          dispatch(
                            setShowActualSalarySheetExportDropdown(false),
                          );
                        }}
                      >
                        CSV
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handlePrinted}>PDF</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="12">
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Form.Group className="w-25" controlId="formBasicEmail">
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "18px" }}
                >
                  Select Month
                </h4>
                <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                  <DatePicker
                    name="month"
                    selected={
                      projectState.actualSheetDate
                        ? new Date(projectState.actualSheetDate)
                        : null
                    }
                    onChange={(e: any) =>
                      dispatch(setActualSheetDate(e.toLocaleDateString()))
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    placeholderText="Select start date"
                    className="w-100 custom-input"
                    dateFormat="MMMM"
                  />
                  <img
                    src={Common.Calendar}
                    className="calendar-date-recur"
                    alt="calendarblack"
                  />
                </Form.Group>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn secondary-button"
                  onClick={handleUpdateSheet}
                  disabled={projectState.actualSheetChangeLoading}
                >
                  {projectState.actualSheetChangeLoading ? (
                    <LoaderWhite height={30} width={30} />
                  ) : (
                    "Update Sheet"
                  )}
                </button>
                <button
                  className="btn primary-button ms-4"
                  onClick={() => setShowPostSalarySheetModal(true)}
                  disabled={projectState.postSalaryLoading}
                >
                  {projectState.postSalaryLoading ? (
                    <LoaderWhite height={30} width={30} />
                  ) : (
                    "Post Salary Sheet"
                  )}
                </button>
              </div>
            </div>
          </Col>
          {projectState.actualType === "" ? null : (
            <Col sm="12">
              <Form.Group
                className="mt-5 d-flex align-items-center gap-4"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack mb-0"
                  style={{ fontSize: "18px" }}
                >
                  Selected {projectState.actualType}:
                </h4>

                <div className="d-flex align-items-center gap-2 overflow-auto">
                  {projectState.actualSalarySheet.map((item, index) => (
                    <div
                      key={index}
                      className="selected-projects primary-button d-flex align-items-center gap-3 py-0 px-4"
                      style={{
                        height: "40px",
                        borderRadius: "20px",
                        boxShadow: "none",
                      }}
                    >
                      <h4 className="mb-0" style={{ fontSize: "16px" }}>
                        {item}
                      </h4>
                      <button
                        className="btn p-0 border-0"
                        onClick={() => {
                          const newProjects =
                            projectState.actualSalarySheet.filter(
                              (project) => project !== item,
                            );
                          dispatch(setActualSalarySheet(newProjects));
                          if (projectState.actualSalarySheet.length === 1) {
                            dispatch(setActualType(""));
                          }
                        }}
                      >
                        <img
                          src={Common.Cross}
                          style={{ width: "22px", height: "22px" }}
                          alt="close"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Col>
          )}
          {/*{projectState.projectTimesheetLoading ? (*/}
          {/*  <>*/}
          {/*    Loading attendances... <LoaderBlue height={50} width={50} />*/}
          {/*  </>*/}
          {/*) : null}*/}
          <Col sm="12">
            <div className="p-3" ref={tableRef}>
              {print && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Common.Vision} width="110px" alt="logo" />
                    </div>
                    <div className="letter-logo text-center gap-2">
                      <img src={Common.LogoSVG} width="80px" alt="logo" />
                      <div className="mt-1">
                        <h3
                          className="logo-text mb-0"
                          style={{
                            color: "#202020",
                            fontSize: "10px",
                            fontWeight: "700",
                          }}
                        >
                          Senyar Arabian Trading Co. LTD
                        </h3>
                        <h3
                          className="logo-text logo-arabic mb-0"
                          style={{
                            color: "#202020",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          شركة سنيار العربية التجارية المحدودة
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h1 className="sub-heading text-center">
                    Actual Salary Sheet
                  </h1>
                  <h1 className="sub-heading text-center mt-0">
                    {monthYearString}
                  </h1>
                </>
              )}
              <Table
                striped
                bordered
                responsive
                className={
                  print ? "mt-4 text-center print-sheet" : "mt-2 text-center"
                }
              >
                <thead>
                  <tr className="border-0">
                    {print ? null : (
                      <th className="border-0">
                        <Form.Check
                          type="checkbox"
                          id="tableCheckbox"
                          className="table-checkbox justify-content-center"
                          name="hideLetterHead"
                          checked={
                            //   if all the checkboxes are checked then return true
                            allEmpIdsChecked
                          }
                          onChange={() => handleSelectAll()}
                        />
                      </th>
                    )}
                    {tableData.tableHeading.map((item, index) => (
                      <th className="border-0" key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
                <tfoot>{tableFooterData()}</tfoot>
              </Table>
            </div>
          </Col>
          {filteredData.length > 0 && (
            <Col sm="12">
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <Form.Group
                  className="d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "14px", width: "230px" }}
                  >
                    Entries per page
                  </h4>
                  <Form.Select
                    name="pageSize"
                    onChange={handlePageSizeChange}
                    value={projectState.actualSalarySheetPageSize.toString()}
                    className="custom-input border-fill custom-height"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                    <option value="10000">All</option>
                  </Form.Select>
                </Form.Group>
                <div className="pagination-custom">
                  <Pagination className="justify-content-end">
                    <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                      <img src={Common.DoubleArrowLeft} alt="arrow" />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          projectState.actualSalarySheetPageNo > 1
                            ? projectState.actualSalarySheetPageNo - 1
                            : 1,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-left"
                        alt="arrow"
                      />
                    </Pagination.Item>

                    {pages.map((page, index) => (
                      <Pagination.Item
                        key={index}
                        onClick={() => handlePageChange(page + 1)}
                        className={
                          page + 1 === projectState.actualSalarySheetPageNo
                            ? "active-page"
                            : ""
                        }
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Item
                      onClick={() =>
                        handlePageChange(
                          projectState.actualSalarySheetPageNo < totalPages
                            ? projectState.actualSalarySheetPageNo + 1
                            : totalPages,
                        )
                      }
                    >
                      <img
                        src={EmployeeIcons.ArrowDownBlue}
                        className="rotate-right"
                        alt="arrow"
                      />
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        handleGoToNextPages();
                      }}
                    >
                      <img src={Common.DoubleArrowRight} alt="arrow" />
                    </Pagination.Item>
                  </Pagination>
                </div>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">Unauthorized Access</h1>
        </div>
      )}
    </div>
  );
};
export default ActualSalarySheet;
