import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters
import { useGetAllDeletedEmployeesQuery } from "../../../services/Employees";
import {
  setDeletedEmployees,
  setDeletedError,
  setDeletedLoading,
  setDeletedTotalRecords,
} from "../../../store/slices/employeeSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  // States
  const employeeState = useAppSelector((state) => state.employee);

  // **************
  // **************
  // Get All Deleted Employees
  // **************
  // **************
  const {
    data: allDeletedEmployees,
    isLoading: deletedEmployeesLoading,
    isError: deletedEmployeesError,
    refetch: deletedEmployeesRefetch,
  } = useGetAllDeletedEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    pageNo: employeeState.deletedPageNo,
    pageSize: employeeState.deletedPageSize,
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (deletedEmployeesLoading) {
      dispatch(setDeletedLoading(true));
    }
    if (deletedEmployeesError) {
      dispatch(setDeletedError("Something went wrong"));
      dispatch(setDeletedLoading(false));
    }
    if (allDeletedEmployees) {
      dispatch(
        setDeletedEmployees(allDeletedEmployees.data.deleted[0].data || []),
      );
      dispatch(
        setDeletedTotalRecords(
          allDeletedEmployees.data.deleted[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setDeletedLoading(false));
    }
  }, [
    accessToken,
    userID,
    allDeletedEmployees,
    profileData,
    employeeState.deletedPageNo,
    employeeState.deletedPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    deletedEmployeesRefetch();
  }, [employeeChange]);
  return <></>;
};
export default Content;
