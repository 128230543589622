import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import EmployeeLeaveRequest from "../Letters/employee-leave-request";
import { LeaveRequest } from "../../interfaces/letters";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddLeaveRequestLoading } from "../../store/slices/lettersSlice";
import { ToastErrorComponent } from "../Toasts";
import { useEditLeaveRequestMutation } from "../../services/Letters";
import { setLeaveRequestChange } from "../../store/slices/commonSlice";

const LeaveRequestDetail = () => {
  const dispatch = useAppDispatch();
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [leaveStatus, setLeaveStatus] = useState("");
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const leaveRequestChange = useAppSelector(
    (state) => state.common.leaveRequestChange
  );
  const navigate = useNavigate();
  const location = useLocation();
  const statementData: LeaveRequest = location.state.data;

  // check if all status are approved in approvals array
  const isApproved = statementData.approvals.every(
    (item) =>
      item.status === "Approved" || item.status === "Approved by Super Admin"
  );

  const [editLeaveRequest] = useEditLeaveRequestMutation();

  const handleSubmit = async () => {
    dispatch(setAddLeaveRequestLoading(true));
    try {
      const data = {
        accessToken: accessToken || "",
        userID: userID || "",
        permission: profileData.permissions.addEmployee || false,
        payload:
          leaveStatus === "Exited"
            ? {
                empId: statementData.empId,
                leaveId: statementData._id,
                exitStatus: true,
              }
            : {
                empId: statementData.empId,
                leaveId: statementData._id,
                entryStatus: true,
              },
      };
      await editLeaveRequest(data).unwrap();
      dispatch(setLeaveRequestChange(!leaveRequestChange));
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddLeaveRequestLoading(false));
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <div className=" mt-2 section px-4 py-4">
        {print ? (
          <div>
            <button
              className="btn primary-button"
              onClick={() => setPrint(false)}
            >
              Back
            </button>
            <EmployeeLeaveRequest item={statementData} />
          </div>
        ) : (
          <Row>
            <Col sm="12">
              <div className="section-heading mb-4">
                <h1 className="heading mb-0">Leave Request Detail</h1>
              </div>
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.empId}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.empName}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.projectId}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.projectName}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Project Supervisor
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.projectSupervisor}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Last Salary Status
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.lastSalaryStatus}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave Category
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.leaveCategory}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave From
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.leaveFrom
                        ? new Date(statementData.leaveFrom).toLocaleDateString()
                        : ""}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave To
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.leaveTo
                        ? new Date(statementData.leaveTo).toLocaleDateString()
                        : ""}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Leave Status
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.status}
                    </h5>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm="12">
              {statementData.guaranters.length > 0 ? (
                <>
                  {statementData.guaranters.map((item: any, index: number) => (
                    <div key={index}>
                      <Row>
                        <Col sm="12">
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading"
                              style={{ fontSize: "20px" }}
                            >
                              Guaranter {index + 1}
                            </h4>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Id
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empId}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Name
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empName}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Phone
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empPhone}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Iqama No.
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empIqamaNo}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Passport No.
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empPassportNo}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group className="mb-5">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Emp Status
                            </h4>
                            <h5
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.empStatus}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : (
                <Row>
                  <Col sm="12">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Guaranters
                    </h4>
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="12">
                  <Form.Group className="mb-5">
                    <h4 className="sub-heading" style={{ fontSize: "20px" }}>
                      Submitted By:
                    </h4>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.submittedBy.empId}
                    </h5>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Emp Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.submittedBy.empName}
                    </h5>
                  </Form.Group>
                </Col>
                {statementData.submittedBy.remarks && (
                  <Col sm="12">
                    <Form.Group className="mb-5">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Remarks
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData.submittedBy.remarks}
                      </h5>
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Col>
            <Col sm="12">
              <div>
                <h2 className="sub-heading mb-4">Leave Request Approvals:</h2>
                <Row>
                  {statementData.approvals.length > 0 ? (
                    <>
                      {statementData.approvals.map((item, index) => (
                        <div className="col-sm-4" key={index}>
                          <div className="emp-detail-item mb-4">
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              {item.name} ({item.role})
                            </h4>
                            <p
                              className="sub-heading normal-font-weight"
                              style={{ fontSize: "16px" }}
                            >
                              {item.status}{" "}
                              {item.status !== "Pending"
                                ? `on ${
                                    item.date
                                      ? new Date(item.date).toLocaleDateString()
                                      : ""
                                  }`
                                : null}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div>
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        No Approvals
                      </h4>
                    </div>
                  )}
                </Row>
              </div>
            </Col>
            <Col sm="12">
              <Row className="align-items-end">
                <Col sm="6">
                  <Form.Group className="text-start" controlId="formBasicEmail">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Entry/Exit Status
                    </h4>
                    <Form.Select
                      name="leaveStatus"
                      onChange={(e) => setLeaveStatus(e.target.value)}
                      value={leaveStatus}
                      className="custom-input border-fill"
                    >
                      <option value="">Select Status</option>
                      <option value="Exited">Exited</option>
                      <option value="Entered">Entered</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm="2">
                  <button
                    className="btn primary-button w-100"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Update Status
                  </button>
                </Col>
              </Row>
            </Col>
            <Col sm="12">
              <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
                {isApproved && (
                  <button
                    className="btn primary-button w-25"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                )}
                <button
                  className="btn primary-button w-25"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default LeaveRequestDetail;
