import React, { useRef, useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageEvaluationDetails,
  setDetailActiveTab,
  setDetailPageTimeline,
  setDetailPageAttendanceLog,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";

interface Props {
  empDetail: EmpDetail;
  logDate: string;
  setLogDate: (logDate: string) => void;
}

const AttendanceLog = ({ empDetail, logDate, setLogDate }: Props) => {
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };

  // Calculate current month days
  const d = logDate ? new Date(logDate) : new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const daysInMonth = new Date(year, month, 0).getDate();

  // Find document in empDetail.salaryTimeline where logDate is exist in fromDate and toDate
  const findLogDateInSalaryTimeline = empDetail.salaryTimeline.find(
    (item) =>
      new Date(logDate) >= new Date(item.fromDate) &&
      new Date(logDate) <= new Date(item.toDate),
  );

  const salaryCalculation = (hours: number) => {
    if (findLogDateInSalaryTimeline) {
      const salary = findLogDateInSalaryTimeline.basicSalary;
      const perHourSalary =
        salary / daysInMonth / findLogDateInSalaryTimeline.dutyHours;
      return (perHourSalary * hours).toFixed(2);
    }
    const salary = empDetail.basicSalary;
    const perHourSalary = salary / daysInMonth / empDetail.dutyHours;
    return (perHourSalary * hours).toFixed(2);
  };

  const totalHours = empDetail.attendances.reduce(
    (acc, item) => acc + item.hours,
    0,
  );

  return (
    <Row>
      <Col sm="3">
        <Form.Group className="mt-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Select Month
          </h4>
          <Form.Group className="d-flex align-items-center input-design position-relative w-100">
            <DatePicker
              name="month"
              selected={logDate ? new Date(logDate) : null}
              onChange={(e: any) => setLogDate(e + 1)}
              showMonthYearPicker
              dropdownMode="select"
              autoComplete="off"
              placeholderText="Select start date"
              className="w-100 custom-input"
              dateFormat="MMMM"
            />
            <img
              src={Common.Calendar}
              className="calendar-date-recur"
              alt="calendarblack"
            />
          </Form.Group>
        </Form.Group>
      </Col>
      <Col sm="3">
        <div className="mt-4">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Print Attendance Log
          </h4>
          <button
            className="btn primary-button"
            onClick={handlePrinted}
            type="button"
          >
            Print
          </button>
        </div>
      </Col>
      {empDetail.attendances.length > 0 ? (
        <>
          <div className="p-3" ref={tableRef}>
            {print && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <img src={Common.Vision} width="110px" alt="logo" />
                  </div>
                  <div className="letter-logo text-center gap-2">
                    <img src={Common.LogoSVG} width="80px" alt="logo" />
                    <div className="mt-1">
                      <h3
                        className="logo-text mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Senyar Arabian Trading Co. LTD
                      </h3>
                      <h3
                        className="logo-text logo-arabic mb-0"
                        style={{
                          color: "#202020",
                          fontSize: "13px",
                          fontWeight: "700",
                        }}
                      >
                        شركة سنيار العربية التجارية المحدودة
                      </h3>
                    </div>
                  </div>
                </div>
                <h1 className="sub-heading text-center mt-3">
                  Attendance Log of Employee ID: {empDetail.empId} -{" "}
                  {empDetail.empName}
                </h1>
              </>
            )}
            <Table
              striped
              bordered
              responsive
              className={print ? "text-center print-table" : "text-center"}
            >
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Emp Code</th>
                  <th>Emp Name</th>
                  <th>Location</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>In Time</th>
                  <th>Out Time</th>
                  <th>Status</th>
                  <th>Total Hours</th>
                  <th>Per Day Salary</th>
                </tr>
              </thead>
              <tbody>
                {empDetail.attendances.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.empId}</td>
                    <td>{item.empName}</td>
                    <td>
                      {item.projectId
                        ? item.projectId
                        : item.empId.toString().charAt(0) === "3"
                          ? "Management"
                          : "Idol"}
                    </td>
                    <td>
                      {item.date
                        ? new Date(item.date).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>
                      {item.date
                        ? new Date(item.date).toLocaleDateString("en-US", {
                            weekday: "long",
                          })
                        : "N/A"}
                    </td>
                    <td>{item.fromDate}</td>
                    <td>{item.toDate}</td>
                    <td>{item.projectedStatus}</td>
                    <td>{item.hours}</td>
                    <td>{salaryCalculation(item.hours)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={9} className="text-end">
                    Total
                  </td>
                  <td>
                    {empDetail.attendances.reduce(
                      (acc, item) => acc + item.hours,
                      0,
                    )}
                  </td>
                  <td>{salaryCalculation(totalHours)}</td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      ) : (
        <div className="text-center mt-5">
          <h1 className="heading">No Attendance log</h1>
        </div>
      )}
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Evaluation Details"));
              dispatch(setDetailPageEvaluationDetails(true));
              dispatch(setDetailPageAttendanceLog(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            type="button"
            onClick={() => {
              dispatch(setDetailActiveTab("Projects Timeline"));
              dispatch(setDetailPageTimeline(true));
              dispatch(setDetailPageAttendanceLog(false));
            }}
            style={{ width: "15%" }}
          >
            Next
            <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default AttendanceLog;
