import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Common } from "../../assets/images";
// Redux imports

interface Props {
  sectionName?: string;
  tabList: string[];
  activeTab: string;
  children?: React.ReactNode;
  handleTabChange: any;
}

const convertString = (string: string) => {
  const convertedString = string
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/[^a-z0-9-]/g, "");

  return convertedString;
};

const TabComponent = ({
  tabList,
  sectionName,
  activeTab,
  children,
  handleTabChange,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className="common-tab">
      <Container fluid>
        <Row>
          <Col sm="4">
            <div className="px-4 pt-4">
              <div className="d-flex align-items-center gap-4 mb-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img
                    src={Common.ArrowLeftBlue}
                    style={{ width: "30px", height: "30px" }}
                    alt="next"
                  />
                </button>
                <h2 className="heading ">{sectionName}</h2>
              </div>
            </div>
          </Col>
          <Col sm="8" className="">
            <div className="px-4 pt-4 text-end">{children}</div>
          </Col>
          <Col md={12}>
            <div className="d-flex align-items-center justify-content-between overflow-auto">
              <div className="tabs px-4">
                <div className="tab-head">
                  <ul className="tab-nav d-flex align-items-center gap-5">
                    {tabList.map((tab, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          handleTabChange(tab);
                        }}
                        className="tab-nav-item cursor-pointer"
                      >
                        <p
                          className={`mb-2 sub-heading normal-font-weight ${
                            activeTab === tab ? "active-tab-text-color" : ""
                          }`}
                        >
                          {tab}
                        </p>
                        {activeTab === tab ? (
                          <div className="tab-active"></div>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default TabComponent;
