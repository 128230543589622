import React from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEditIqamaProcessStatusMutation } from "../../services/Iqama";
import { setIqamaChange } from "../../store/slices/commonSlice";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";

interface Props {
  show?: boolean;
  setShow?: any;
  empId: number;
}

const ConfirmInitiateIqamaProcessModal = ({ show, setShow, empId }: Props) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const iqamaChange = useAppSelector((state) => state.common.iqamaChange);
  const [editIqamaProcessStatus] = useEditIqamaProcessStatusMutation();
  const [errorContent, setErrorContent] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleInitiateProcess = async () => {
    setLoading(true);
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateLegal || false,
        payload: {
          empId: empId,
          processesStatus: false,
        },
      };
      await editIqamaProcessStatus(data).unwrap();
      dispatch(setIqamaChange(!iqamaChange));
      window.location.reload();
      setShow(false);
    } catch (e: any) {
      if (e) {
        setErrorContent(e.data.data.message);
        setShowError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastErrorComponent
        show={showError}
        setShow={setShowError}
        content={errorContent}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="general-modal"
      >
        <Modal.Body className="text-center px-5">
          <div className="mb-4 text-center">
            <h1 className="heading">Are you sure?</h1>
            <h4 className="sub-heading normal-font-weight text-center">
              No more processes can be added after this.
            </h4>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <button
                className="btn primary-button border-0 mt-4"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className="btn primary-button border-0 mt-4"
                onClick={handleInitiateProcess}
              >
                {loading ? <LoaderWhite height={30} width={30} /> : "Initiate"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmInitiateIqamaProcessModal;
