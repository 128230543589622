import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Common } from "../../../assets/images";
// Redux Imports
import { useAppDispatch } from "../../../store/hooks";
import {
  setDetailPageOtherDetails,
  setDetailPageBasicInfo,
  setDetailPageLegalInfo,
  setDetailActiveTab,
} from "../../../store/slices/employeeSlice";
import { EmpDetail } from "../../../interfaces/employee";

interface Props {
  empDetail: EmpDetail;
}

const LegalInfo = ({ empDetail }: Props) => {
  // Redux
  const dispatch = useAppDispatch();

  const fileExtension = (path: string) => {
    // Using the URL API to extract the pathname
    const pathname = path ? new URL(path).pathname : "";

    // Extracting the file name with extension from the pathname
    const fileNameWithExtension = pathname.split("/").pop();

    return fileNameWithExtension;
  };
  return (
    <Row>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Iqama No.
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empIqamaNo}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Iqama Expiry Date
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {new Date(empDetail.empIqamaExpiry).toLocaleDateString()}
          </h5>
        </Form.Group>
      </Col>
      {empDetail.empNationality !== "Saudi Arabia" && (
        <Col sm="4">
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Profession on Iqama
            </h4>
            <h5
              className="sub-heading normal-font-weight"
              style={{ fontSize: "16px" }}
            >
              {empDetail.empProfessionOnIqama}
            </h5>
          </Form.Group>
        </Col>
      )}
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Sponsor Name
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empSponsorName}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Sponsor Id No.
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empIdNo}
          </h5>
        </Form.Group>
      </Col>
      {empDetail.empNationality !== "Saudi Arabia" && (
        <>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Border No.
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.empBorderNo}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Iqama Printed
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.empIqamaPrinted}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Handover to Employee
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.iqamaHandOverToEmployee}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport with Company
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.empPassportWithCompany}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport No.
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.empPassportNo}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Passport Expiry Date
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {new Date(empDetail.empPassportExpiry).toLocaleDateString()}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Visa No.
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.empVisaNo}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Entry Date Of Emp In Saudia Arabia
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {new Date(empDetail.dateOfEmpInSA).toLocaleDateString()}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Visa Expiry Date
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {new Date(empDetail.empVisaExpiry).toLocaleDateString()}
              </h5>
            </Form.Group>
          </Col>
        </>
      )}

      {empDetail.recruiterDetails ? (
        <>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Recruiter Id.
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.recruiterDetails.recruiterId}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Recruiter Name
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.recruiterDetails.recruiterName}
              </h5>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading normal-font-weight textBlack"
                style={{ fontSize: "18px" }}
              >
                Recruiter Company Name
              </h4>
              <h5
                className="sub-heading normal-font-weight"
                style={{ fontSize: "16px" }}
              >
                {empDetail.recruiterDetails.companyName}
              </h5>
            </Form.Group>
          </Col>
        </>
      ) : (
        <Col sm="4">
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              No Recruiter Details Found
            </h4>
          </Form.Group>
        </Col>
      )}
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Work Permit Expiry
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.workPermitExpiry}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Company
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empInsuranceCompany}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Expiry
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {new Date(empDetail.empInsuranceExpiry).toLocaleDateString()}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Cost
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empInsuranceCost}
          </h5>
        </Form.Group>
      </Col>
      <Col sm="4">
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <h4
            className="sub-heading normal-font-weight textBlack"
            style={{ fontSize: "18px" }}
          >
            Insurance Status
          </h4>
          <h5
            className="sub-heading normal-font-weight"
            style={{ fontSize: "16px" }}
          >
            {empDetail.empInsuranceStatus}
          </h5>
        </Form.Group>
      </Col>

      <Col sm="12">
        <div className="section-heading mb-4">
          <h1 className="sub-heading mb-0">Insurance Update On</h1>
        </div>
      </Col>
      <Col sm="12">
        {empDetail.insuranceHistory.length > 0 ? (
          <div className="table-responsive mb-3">
            <table className="table text-center table-bordered">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Updation Date</th>
                  <th>Updater Id.</th>
                  <th>Updater Name</th>
                  <th>Cost</th>
                  <th>Attachment</th>
                  <th>Remarks</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {empDetail.insuranceHistory.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item.updatedOn
                        ? new Date(item.updatedOn).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{item.updatedBy}</td>
                    <td>{item.updaterName}</td>
                    <td>{item.cost}</td>
                    <td>
                      {item.attachemnt ? fileExtension(item.attachemnt) : "N/A"}
                    </td>
                    <td>{item.remarks}</td>
                    <td>
                      {item.attachemnt ? (
                        <a href={item.attachemnt} download>
                          <img
                            src={Common.Download}
                            className="me-2"
                            width="24px"
                            height="24px"
                            alt="share"
                          />
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col sm="4">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <h4
                className="sub-heading textBlack"
                style={{ fontSize: "18px" }}
              >
                No updations yet...
              </h4>
            </Form.Group>
          </Col>
        )}
      </Col>
      <Col sm="12">
        <div className="text-end mt-4 d-flex align-items-center justify-content-between">
          <button
            className="btn secondary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Basic Information"));
              dispatch(setDetailPageBasicInfo(true));
              dispatch(setDetailPageLegalInfo(false));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            <img src={Common.ArrowLeftBlue} alt="next" />
            Back
          </button>
          <button
            className="btn primary-button normal-font-weight"
            onClick={() => {
              dispatch(setDetailActiveTab("Other Details"));
              dispatch(setDetailPageLegalInfo(false));
              dispatch(setDetailPageOtherDetails(true));
            }}
            type="button"
            style={{ width: "15%" }}
          >
            Next <img src={Common.ArrowRightWhite} alt="next" />
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default LegalInfo;
