import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table, Dropdown, Pagination } from "react-bootstrap";
import { Common, EmployeeIcons, SettingsIcons } from "../../assets/images";
import { useNavigate } from "react-router-dom";
// Redux Imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setShowDeletedExportDropdown,
  setDeletedPageSize,
  setDeletedPageNo,
  setDeletedLoading,
  setDeletedTotalRecords,
  setPageNo,
  setFilterPageNo,
} from "../../store/slices/employeeSlice";
import { LoaderBlue } from "../Lotties";
// API Imports
import { DeletedEmployees } from "../../interfaces/employee";
import { exportToCsv, exportToPDF } from "../../config";
import { useGetAllFilterEmployeesMutation } from "../../services/Employees";
import Content from "./GetAPIS/terminated-employees";
import { useReactToPrint } from "react-to-print";
import SelectSearch from "react-select-search";

const TerminatedEmployees = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.employee);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const tableRef = useRef(null);
  const [print, setPrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });
  const handlePrinted = async () => {
    await setPrint(true);
    handlePrint();
  };
  const employees = useAppSelector((state) => state.employee.allEmployeesList);
  // Options
  const options = employees.map((item) => ({
    name: item.empId + " - " + item.empName,
    value: item.empId,
  }));
  // Pagination && Filter

  const [filterSearch, setFilterSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<DeletedEmployees[]>([]);
  const totalPages = Math.ceil(
    state.deletedTotalRecords / state.deletedPageSize,
  );
  const totalCount = state.deletedTotalRecords;

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage =
    Math.floor((state.deletedPageNo - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);

  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (state.deletedPageNo > totalPages - showPages) {
      return;
    }
    dispatch(setPageNo(state.deletedPageNo + showPages));
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (state.deletedPageNo <= 5) {
      return;
    }
    dispatch(setPageNo(state.deletedPageNo - showPages));
  };

  const handlePageChange = (page: any) => {
    dispatch(setDeletedPageNo(page));
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    dispatch(setDeletedPageSize(selectedPageSize));
  };
  useEffect(() => {
    setFilteredData(state.deletedEmployees);
  }, [state.deletedEmployees]);
  // Employees List
  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Category",
      "Status",
      "Reason",
      "Actions",
    ],
    tableData: filteredData,
  };

  const csvData = tableData.tableData.map((item, index) => ({
    "Sr. No.": index + 1,
    "Emp Code": item.empId,
    Name: item.empName,
    Category: item.empCategory,
    Status: item.empStatus,
    "Termination Reason": item.reasonToTerminate,
  }));

  const [getAllFilterEmployees] = useGetAllFilterEmployeesMutation();
  // Handle Submit Function to filter employees based on the form values
  const handleSubmit = async (querySearch: string) => {
    setQuery(querySearch);
    dispatch(setDeletedLoading(true));
    setFilterSearch(true);
    // Filter employees based on the form values
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData?.permissions.showEmployee || false,
        payload: {
          page: state.deletedPageNo,
          limit: state.deletedPageSize,
          empId: parseInt(query),
        },
      };
      const response = await getAllFilterEmployees(data).unwrap();
      if (response) {
        setFilteredData(response.data.employees[0].data);
        dispatch(
          setDeletedTotalRecords(response.data.employees[0].metadata[0].total),
        );
      }
    } catch (e: any) {
    } finally {
      dispatch(setDeletedLoading(false));
    }
  };

  const handleReset = () => {
    setQuery("");
    setSearch("");
    setFilteredData(state.deletedEmployees);
    setFilterSearch(false);
  };

  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <Content />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Terminated Employees</h1>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div
                className=" d-flex align-items-center gap-2"
                style={{ width: "25%" }}
              >
                <Form.Group className="w-100" controlId="formBasicEmail">
                  <Form.Group className="position-relative input-design d-flex align-items-center">
                    <SelectSearch
                      options={options}
                      onChange={(value: any) => {
                        handleSubmit(value);
                      }}
                      value={query}
                      search={true}
                      placeholder="Search Employee by Id/Name "
                    />
                    <img src={Common.ArrowDown} alt="message" />
                  </Form.Group>
                </Form.Group>
                {query !== "" ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : filterSearch ? (
                  <button
                    type="button"
                    className="btn primary-button w-25"
                    onClick={handleReset}
                  >
                    {" "}
                    Reset
                  </button>
                ) : null}
              </div>
              <div className="d-flex align-items-center gap-4">
                <Dropdown
                  show={state.showDeletedExportDropdown}
                  className="position-relative"
                >
                  <button
                    id="dropdown-basic"
                    onClick={() => {
                      dispatch(
                        setShowDeletedExportDropdown(
                          !state.showDeletedExportDropdown,
                        ),
                      );
                    }}
                    className=" bg-transparent dropdown-toggle-white"
                  >
                    Export{" "}
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="ms-2"
                      alt="down-arrow"
                    />
                  </button>
                  <Dropdown.Menu
                    className="mt-1 custom-scrollbar"
                    show={state.showDeletedExportDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        exportToCsv(csvData, "deleted-employees-list");
                        dispatch(setShowDeletedExportDropdown(false));
                      }}
                    >
                      CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setShowDeletedExportDropdown(false));
                        handlePrinted();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12">
          {state.deletedLoading ? (
            <LoaderBlue height={70} width={70} />
          ) : profileData.permissions.showEmployee ? (
            <>
              {tableData.tableData.length > 0 ? (
                <div className="p-3" ref={tableRef}>
                  {print && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img src={Common.Vision} width="110px" alt="logo" />
                        </div>
                        <div className="letter-logo text-center gap-2">
                          <img src={Common.LogoSVG} width="80px" alt="logo" />
                          <div className="mt-1">
                            <h3
                              className="logo-text mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Senyar Arabian Trading Co. LTD
                            </h3>
                            <h3
                              className="logo-text logo-arabic mb-0"
                              style={{
                                color: "#202020",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                            >
                              شركة سنيار العربية التجارية المحدودة
                            </h3>
                          </div>
                        </div>
                      </div>
                      <h1 className="sub-heading text-center mt-3">
                        Terminated Employees List
                      </h1>
                    </>
                  )}
                  <Table
                    striped
                    bordered
                    responsive
                    className={
                      print
                        ? "mt-5 text-center print-table"
                        : "mt-5 text-center"
                    }
                  >
                    <thead>
                      <tr className="border-0">
                        {tableData.tableHeading.map((item, index) => (
                          <th
                            className={
                              item === "Actions" && print
                                ? "border-0 d-none"
                                : "border-0"
                            }
                            key={index}
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.tableData.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderColor: "rgba(32, 32, 32, 0.20)" }}
                        >
                          <td>
                            {totalCount > 0
                              ? (state.deletedPageNo - 1) *
                                  state.deletedPageSize +
                                index +
                                1
                              : index + 1}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                { state: { empId: item.empId } },
                              )
                            }
                          >
                            {item.empId}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/employees/employee-detail/${item.empId}`,
                                { state: { empId: item.empId } },
                              )
                            }
                          >
                            {" "}
                            {print ? twoWords(item.empName) : item.empName}
                          </td>
                          <td>{item.empCategory}</td>
                          <td>{item.empStatus}</td>
                          <td>{item.reasonToTerminate}</td>
                          {!print && (
                            <td>
                              <button
                                className="btn p-0 border-0 me-2"
                                onClick={() =>
                                  navigate(
                                    `/employees/employee-detail/${item.empId}`,
                                    { state: { empId: item.empId } },
                                  )
                                }
                              >
                                <img
                                  src={Common.Eye}
                                  width="30px"
                                  height="30px"
                                  alt="edit-user"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <h1 className="heading">No Deleted Employees Found</h1>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">Unauthorized Access</h1>
            </div>
          )}
        </Col>
        {filteredData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="deletedPageSize"
                  onChange={handlePageSizeChange}
                  value={state.deletedPageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="10000">All</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.deletedPageNo > 1 ? state.deletedPageNo - 1 : 1,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={
                        page + 1 === state.deletedPageNo ? "active-page" : ""
                      }
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        state.deletedPageNo < totalPages
                          ? state.deletedPageNo + 1
                          : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default TerminatedEmployees;
