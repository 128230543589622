import React from "react";
import InternalLayout from "../components/InternalLayout";
import Content from "../components/ManualJournal/get-all-journals-api";
import ManualJournalComponent from "../components/ManualJournal";
import withAuth from "../config/private-route";

const ManualJournal = () => {
  return (
    <InternalLayout>
      <Content />
      <ManualJournalComponent />
    </InternalLayout>
  );
};
export default withAuth(ManualJournal);
