import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Dashboard
import { useGetCreditNoteDetailsQuery } from "../../../services/Reports";
import {
  getCreditNoteDetailsRequest,
  getCreditNoteDetailsFailure,
  getCreditNoteDetailsSuccess,
} from "../../../store/slices/reportsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);

  // State
  const state = useAppSelector((state) => state.reports);

  // **************
  // **************
  // Get Dashboard Data
  // **************
  // **************
  const {
    data: allCreditNoteDetails,
    isLoading: creditNoteDetailsLoading,
    isError: creditNoteDetailsError,
  } = useGetCreditNoteDetailsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: true,
    pageNo: state.creditNoteDetailsPageNo,
    pageSize: state.creditNoteDetailsPageSize,
    fromDate: new Date(state.creditNoteDetailsFromDate).toLocaleDateString(),
    toDate: new Date(state.creditNoteDetailsToDate).toLocaleDateString(),
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (creditNoteDetailsLoading) {
      dispatch(getCreditNoteDetailsRequest());
    }
    if (creditNoteDetailsError) {
      dispatch(getCreditNoteDetailsFailure(creditNoteDetailsError));
    }
    if (allCreditNoteDetails) {
      dispatch(
        getCreditNoteDetailsSuccess({
          data: allCreditNoteDetails.data.creditNotes[0].data || [],
          totalPages:
            allCreditNoteDetails.data.creditNotes[0].metadata[0]?.total || 0,
        }),
      );
    }
  }, [
    accessToken,
    userID,
    allCreditNoteDetails,
    profileData,
    state.creditNoteDetailsFromDate,
    state.creditNoteDetailsToDate,
    state.creditNoteDetailsPageNo,
    state.creditNoteDetailsPageSize,
  ]);
  return <></>;
};
export default Content;
