import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../config";

export const imageUploadAPI = createApi({
  reducerPath: "/api/image-upload",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }), // Set your base URL here
  endpoints: (builder) => ({
    // Define your endpoints here
    uploadImage: builder.mutation({
      query: (data) => ({
        url: `/upload-image/${data.permission}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    uploadRegistrationImage: builder.mutation({
      query: (data) => ({
        url: `/upload-register-image`,
        method: "POST",
        headers: {
          Email: `${data.email}`,
        },
        body: data.payload,
      }),
    }),
    editEmployeeImage: builder.mutation({
      query: (data) => ({
        url: `upload-employee-image/${data.permission}/${data.empId}/${data.userId}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
    editProfileImage: builder.mutation({
      query: (data) => ({
        url: `upload-user-image/${data.permission}/${data.userId}`,
        method: "POST",
        headers: {
          Authorization: `${data.accessToken}`,
        },
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useUploadImageMutation,
  useEditEmployeeImageMutation,
  useEditProfileImageMutation,
  useUploadRegistrationImageMutation,
} = imageUploadAPI;
