import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Letters
import { useGetLeavesRequestsQuery } from "../../services/Letters";
import {
  setLeaveRequests,
  setLeaveRequestLoading,
  setLeaveRequestError,
  setLeaveRequestTotalPages,
} from "../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const leaveRequestChange = useAppSelector(
    (state) => state.common.leaveRequestChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Leave Requests
  // **************
  // **************
  const {
    data: allLeaveRequests,
    isLoading: leavesLoading,
    isError: leavesError,
    refetch: leavesRefetch,
  } = useGetLeavesRequestsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.leaveRequestPageNo,
    limit: state.leaveRequestPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (leavesLoading) {
      dispatch(setLeaveRequestLoading(true));
    }
    if (leavesError) {
      dispatch(setLeaveRequestError("Something went wrong"));
      dispatch(setLeaveRequestLoading(false));
    }
    if (allLeaveRequests) {
      dispatch(setLeaveRequests(allLeaveRequests.data.leaves[0].data || []));
      dispatch(
        setLeaveRequestTotalPages(
          allLeaveRequests.data.leaves[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setLeaveRequestLoading(false));
    }
  }, [
    accessToken,
    userID,
    allLeaveRequests,
    profileData,
    state.leaveRequestPageNo,
    state.leaveRequestPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    leavesRefetch();
  }, [leaveRequestChange]);
  return <></>;
};
export default Content;
