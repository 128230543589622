import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
// Settings
import {
  useGetAllRolesMutation,
  useGetProfileQuery,
  useGetAllUsersMutation,
  useGetAllDesignationsMutation,
} from "../services/Settings";
import {
  setPhone,
  setProfileData,
  setLoading,
  setResponseError,
  setRoles,
  setAllUsers,
  setAllDesignations,
  setCountryCode,
} from "../store/slices/settingsSlice";
// Employees
import {
  useGetAllEmployeesQuery,
  useGetAllEmployeesListQuery,
  useGetAllProbationEmployeesQuery,
  useGetAllAbscondedEmployeesQuery,
  useGetAllDeletedEmployeesQuery,
} from "../services/Employees";
import {
  setEmpLoading,
  setEmpResponseError,
  setEmployees,
  setTotalRecords,
  setAllEmployeesList,
  setAllEmployeesListLoading,
  setProbationEmployees,
  setProbationTotalRecords,
  setProbationError,
  setProbationLoading,
  setAbscondedError,
  setAbscondedLoading,
  setAbscondedEmployees,
  setAbscondedTotalRecords,
  setDeletedEmployees,
  setDeletedError,
  setDeletedLoading,
  setDeletedTotalRecords,
} from "../store/slices/employeeSlice";
// Projects
import {
  useGetIdolEmployeesQuery,
  useGetProjectsIdsQuery,
} from "../services/Projects";
import {
  setIdolEmployeesError,
  setIdolEmployees,
  setIdolEmployeesLoading,
  setProjectIds,
  setProjectIdsLoading,
  setProjectIdsError,
} from "../store/slices/projectSlice";
// Client Ids
import {
  useGetClientIdsQuery,
  useGetInvoiceIdsQuery,
} from "../services/Clients";
import {
  setClientIdsLoading,
  setClientIdsError,
  setClientIds,
  setInvoiceIdsLoading,
  setInvoiceIdsError,
  setInvoiceIds,
} from "../store/slices/clientsSlice";
// Camp Ids
import {
  useGetCampIdsQuery,
  useGetRecruitAgencyIdsQuery,
  useGetVisaListQuery,
} from "../services/Camps";
import {
  setCampsIdsLoading,
  setCampsIdsError,
  setCampsIds,
  setRecruitAgencyIdsLoading,
  setRecruitAgencyIdsError,
  setRecruitAgencyIds,
  setVisaListLoading,
  setVisaListError,
  setVisaList,
} from "../store/slices/campsSlice";
// Accounts Ids
import {
  useGetAccountChartIdsQuery,
  useGetVendorIdsQuery,
  useGetBillingIdsQuery,
} from "../services/AccountCharts";
import {
  setAccountChartsIdsLoading,
  setAccountChartsListError,
  setAccountChartsIds,
} from "../store/slices/accountChartsSlice";
// Vendors
import {
  setVendorsIdsLoading,
  setVendorListError,
  setVendorsIds,
  setBillIds,
  setBillIdsError,
  setBillIdsLoading,
} from "../store/slices/vendorsSlice";
// Sponsor
import { useGetSponsorIdsQuery } from "../services/Sponsor";
import {
  setSponsorsIdsLoading,
  setSponsorListError,
  setSponsorsIds,
} from "../store/slices/sponsorSlices";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const settingsChange = useAppSelector((state) => state.common.settingsChange);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const role = useAppSelector((state) => state.common.role);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const projectChange = useAppSelector((state) => state.common.projectChange);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  const campsChange = useAppSelector((state) => state.common.campsChange);
  const recruitAgencyChange = useAppSelector(
    (state) => state.common.recruitAgencyChange,
  );
  const transferChange = useAppSelector(
    (state) => state.common.transferEmployeeChange,
  );

  const salaryIncrementChange = useAppSelector(
    (state) => state.common.salaryIncrementChange,
  );

  const accountChartsChange = useAppSelector(
    (state) => state.common.accountChartsChange,
  );

  const sponsorChange = useAppSelector((state) => state.common.sponsorChange);

  const vendorChange = useAppSelector((state) => state.common.vendorChange);
  const visaChange = useAppSelector((state) => state.common.visaChange);
  // States
  const campState = useAppSelector((state) => state.camps);
  const clientState = useAppSelector((state) => state.clients);
  const billChange = useAppSelector((state) => state.common.billChange);
  // States
  const vendorState = useAppSelector((state) => state.vendors);

  // **************
  // **************
  // Get Profile
  // **************
  // **************
  const {
    data: userProfileData,
    isLoading: profileLoading,
    isError: profileError,
    refetch: profileRefetch,
  } = useGetProfileQuery({
    accessToken: accessToken || "",
    userID: userID || "",
  });
  React.useEffect(() => {
    if (profileLoading) {
      dispatch(setLoading(true));
    }
    if (profileError) {
      dispatch(setResponseError("Something went wrong"));
      dispatch(setLoading(false));
    }
    if (userProfileData) {
      dispatch(setProfileData(userProfileData.data.user));
      const [countryCode, phoneNumber] =
        userProfileData.data.user.phone.split(" ");
      dispatch(setCountryCode(countryCode));
      dispatch(setPhone(phoneNumber));
      dispatch(setLoading(false));
    }
  }, [accessToken, userID, userProfileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    profileRefetch();
  }, [settingsChange]);
  // **************
  // **************
  // Get All Designations
  // **************
  // **************
  const [getAllDesignations] = useGetAllDesignationsMutation();
  const getDesignations = async () => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      payload: {
        myRole: role || "",
      },
    };
    try {
      const result = await getAllDesignations(data).unwrap();
      if (result) {
        dispatch(setAllDesignations(result.data.roles || []));
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    } else {
      getDesignations();
    }
  }, [accessToken, userID, settingsChange]);
  // **************
  // **************
  // Get All Roles
  // **************
  // **************
  const [getAllRoles] = useGetAllRolesMutation();
  const getRoles = async () => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      payload: {
        myRole: role || "",
      },
    };
    try {
      const result = await getAllRoles(data).unwrap();
      if (result) {
        dispatch(setRoles(result.data.roles || []));
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    } else {
      getRoles();
    }
  }, [accessToken, userID, settingsChange]);
  // **************
  // **************
  // Get All Users
  // **************
  // **************
  const [getAllUsers] = useGetAllUsersMutation();
  const getUsers = async () => {
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      payload: {
        myRole: role || "",
      },
    };
    try {
      const result = await getAllUsers(data).unwrap();
      if (result) {
        dispatch(setAllUsers(result.data.users || []));
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    } else {
      getUsers();
    }
  }, [accessToken, userID, settingsChange]);

  // **************
  // **************
  // Get All Employees (Full List)
  // **************
  // **************
  const {
    data: allEmployeesFullList,
    isLoading: employeeFullListLoading,
    isError: employeeFullListError,
    refetch: employeeFullListRefetch,
  } = useGetAllEmployeesListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (employeeFullListLoading) {
      dispatch(setAllEmployeesListLoading(true));
    }
    if (employeeFullListError) {
      dispatch(setEmpResponseError("Something went wrong"));
      dispatch(setAllEmployeesListLoading(false));
    }
    if (allEmployeesFullList) {
      dispatch(setAllEmployeesList(allEmployeesFullList.data.employees || []));
      dispatch(setAllEmployeesListLoading(false));
    }
  }, [accessToken, userID, allEmployeesFullList, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    employeeFullListRefetch();
  }, [employeeChange, projectChange, transferChange, salaryIncrementChange]);

  // **************
  // **************
  // Get All Idol Employees
  // **************
  // **************
  const {
    data: allIdolEmployees,
    isLoading: idolLoading,
    isError: idolError,
    refetch: idolRefetch,
  } = useGetIdolEmployeesQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (idolLoading) {
      dispatch(setIdolEmployeesLoading(true));
    }
    if (idolError) {
      dispatch(setIdolEmployeesError("Something went wrong"));
      dispatch(setIdolEmployeesLoading(false));
    }
    if (allIdolEmployees) {
      dispatch(setIdolEmployees(allIdolEmployees.data.idol || []));
      dispatch(setIdolEmployeesLoading(false));
    }
  }, [accessToken, userID, allIdolEmployees, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    idolRefetch();
  }, [projectChange, employeeChange, transferChange, salaryIncrementChange]);

  // **************
  // **************
  // Get All Client Ids
  // **************
  // **************
  const {
    data: allClientIds,
    isLoading: clientIdsLoading,
    isError: clientIdsError,
    refetch: clientIdsRefetch,
  } = useGetClientIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (clientIdsLoading) {
      dispatch(setClientIdsLoading(true));
    }
    if (clientIdsError) {
      dispatch(setClientIdsError("Something went wrong"));
      dispatch(setClientIdsLoading(false));
    }
    if (allClientIds) {
      dispatch(setClientIds(allClientIds.data.clientIds || []));
      dispatch(setClientIdsLoading(false));
    }
  }, [accessToken, userID, allClientIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    clientIdsRefetch();
  }, [clientsChange]);
  // **************
  // **************
  // Get All Camp Ids
  // **************
  // **************
  const {
    data: allCampsIds,
    isLoading: campIdsLoading,
    isError: campIdsError,
    refetch: campIdsRefetch,
  } = useGetCampIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (campIdsLoading) {
      dispatch(setCampsIdsLoading(true));
    }
    if (campIdsError) {
      dispatch(setCampsIdsError("Something went wrong"));
      dispatch(setCampsIdsLoading(false));
    }
    if (allCampsIds) {
      dispatch(setCampsIds(allCampsIds.data.campIds || []));
      dispatch(setCampsIdsLoading(false));
    }
  }, [accessToken, userID, allCampsIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    campIdsRefetch();
  }, [campsChange]);
  // **************
  // **************
  // Get All Recruit Agency Ids
  // **************
  // **************
  const {
    data: allRecruitAgencyIds,
    isLoading: recruitAgencyIdsLoading,
    isError: recruitAgencyIdsError,
    refetch: recruitAgencyIdsRefetch,
  } = useGetRecruitAgencyIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (recruitAgencyIdsLoading) {
      dispatch(setRecruitAgencyIdsLoading(true));
    }
    if (recruitAgencyIdsError) {
      dispatch(setRecruitAgencyIdsError("Something went wrong"));
      dispatch(setRecruitAgencyIdsLoading(false));
    }
    if (allRecruitAgencyIds) {
      dispatch(
        setRecruitAgencyIds(allRecruitAgencyIds.data.recruiterIds || []),
      );
      dispatch(setRecruitAgencyIdsLoading(false));
    }
  }, [accessToken, userID, allRecruitAgencyIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    recruitAgencyIdsRefetch();
  }, [recruitAgencyChange]);
  // **************
  // **************
  // Get All Project Ids
  // **************
  // **************
  const {
    data: allProjectIds,
    isLoading: projectIdsLoading,
    isError: projectIdsError,
    refetch: projectIdsRefetch,
  } = useGetProjectsIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (projectIdsLoading) {
      dispatch(setProjectIdsLoading(true));
    }
    if (projectIdsError) {
      dispatch(setProjectIdsError("Something went wrong"));
      dispatch(setProjectIdsLoading(false));
    }
    if (allProjectIds) {
      dispatch(setProjectIds(allProjectIds.data.projectIds || []));
      dispatch(setProjectIdsLoading(false));
    }
  }, [accessToken, userID, allProjectIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    projectIdsRefetch();
  }, [projectChange, transferChange]);
  // **************
  // **************
  // Get All Accounts Ids
  // **************
  // **************
  const {
    data: allAccountChartsIds,
    isLoading: accountChartsIdsLoading,
    isError: accountChartsIdsError,
    refetch: accountChartsIdsRefetch,
  } = useGetAccountChartIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (accountChartsIdsLoading) {
      dispatch(setAccountChartsIdsLoading(true));
    }
    if (accountChartsIdsError) {
      dispatch(setAccountChartsListError("Something went wrong"));
      dispatch(setAccountChartsIdsLoading(false));
    }
    if (allAccountChartsIds) {
      dispatch(
        setAccountChartsIds(allAccountChartsIds.data.allAccountIds || []),
      );
      dispatch(setAccountChartsIdsLoading(false));
    }
  }, [accessToken, userID, allAccountChartsIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    accountChartsIdsRefetch();
  }, [accountChartsChange]);
  // **************
  // **************
  // Get All Sponsor Ids
  // **************
  // **************
  const {
    data: allSponsorsIds,
    isLoading: sponsorsIdsLoading,
    isError: sponsorsIdsError,
    refetch: sponsorsIdsRefetch,
  } = useGetSponsorIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (sponsorsIdsLoading) {
      dispatch(setSponsorsIdsLoading(true));
    }
    if (sponsorsIdsError) {
      dispatch(setSponsorListError("Something went wrong"));
      dispatch(setSponsorsIdsLoading(false));
    }
    if (allSponsorsIds) {
      dispatch(setSponsorsIds(allSponsorsIds.data.companyIds || []));
      dispatch(setSponsorsIdsLoading(false));
    }
  }, [accessToken, userID, allSponsorsIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    sponsorsIdsRefetch();
  }, [sponsorChange]);
  // **************
  // **************
  // Get All Vendor Ids
  // **************
  // **************
  const {
    data: allVendorsIds,
    isLoading: vendorsIdsLoading,
    isError: vendorsIdsError,
    refetch: vendorsIdsRefetch,
  } = useGetVendorIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (vendorsIdsLoading) {
      dispatch(setVendorsIdsLoading(true));
    }
    if (vendorsIdsError) {
      dispatch(setVendorListError("Something went wrong"));
      dispatch(setVendorsIdsLoading(false));
    }
    if (allVendorsIds) {
      dispatch(setVendorsIds(allVendorsIds.data.vendorIds || []));
      dispatch(setVendorsIdsLoading(false));
    }
  }, [accessToken, userID, allVendorsIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    vendorsIdsRefetch();
  }, [vendorChange]);
  // **************
  // **************
  // Get All Invoice Ids
  // **************
  // **************
  const {
    data: allInvoiceIds,
    isLoading: invoiceIdsLoading,
    isError: invoiceIdsError,
    refetch: invoiceIdsRefetch,
  } = useGetInvoiceIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    clientID: clientState.clientIdNumber,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (invoiceIdsLoading) {
      dispatch(setInvoiceIdsLoading(true));
    }
    if (invoiceIdsError) {
      dispatch(setInvoiceIdsError("Something went wrong"));
      dispatch(setInvoiceIdsLoading(false));
    }
    if (allInvoiceIds) {
      dispatch(setInvoiceIds(allInvoiceIds.data.invoices || []));
      dispatch(setInvoiceIdsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allInvoiceIds,
    profileData,
    clientState.clientIdNumber,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    invoiceIdsRefetch();
  }, [clientsChange, clientState.clientIdNumber]);
  // **************visa******
  // Get All Visa
  // **************
  // **************
  const {
    data: allVisa,
    isLoading: visaLoading,
    isError: visaError,
    refetch: visaRefetch,
  } = useGetVisaListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showLegal || false,
    pageNo: campState.visaListPageNo,
    pageSize: campState.visaListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (visaLoading) {
      dispatch(setVisaListLoading(true));
    }
    if (visaError) {
      dispatch(setVisaListError("Something went wrong"));
      dispatch(setVisaListLoading(false));
    }
    if (allVisa) {
      dispatch(setVisaList(allVisa.data.visa || []));
      dispatch(setVisaListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allVisa,
    profileData,
    campState.visaListPageNo,
    campState.visaListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    visaRefetch();
  }, [visaChange]);
  // **************
  // **************
  // Get All Bill Ids
  // **************
  // **************
  const {
    data: allBillIds,
    isLoading: billIdsListLoading,
    isError: billIdsListError,
    refetch: billIdsListRefetch,
  } = useGetBillingIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showPettyCash || false,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (billIdsListLoading) {
      dispatch(setBillIdsLoading(true));
    }
    if (billIdsListError) {
      dispatch(setBillIdsError("Something went wrong"));
      dispatch(setBillIdsLoading(false));
    }
    if (allBillIds) {
      dispatch(setBillIds(allBillIds.data.billIds || []));
      dispatch(setBillIdsLoading(false));
    }
  }, [accessToken, userID, allBillIds, profileData]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    billIdsListRefetch();
  }, [billChange]);
  return <></>;
};
export default Content;
