import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { SidebarIcons } from "../../assets/images";

interface Props {
  eventKey: string;
  title: string;
  collapsedImage: any;
  image: any;
  children: React.ReactNode;
  height: string;
  width: string;
  className?: string;
}

const AccordionItem = ({
  eventKey,
  title,
  children,
  collapsedImage,
  image,
  height,
  width,
  className,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleAccordion = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <Accordion.Item eventKey={eventKey} className={className}>
      <Accordion.Header onClick={toggleAccordion}>
        <img
          src={isCollapsed ? collapsedImage : image}
          width={width}
          height={height}
          className="me-2"
          alt="sidebar-icon"
        />
        {title}
      </Accordion.Header>
      <Accordion.Body className="pt-0">{children}</Accordion.Body>
    </Accordion.Item>
  );
};
export default AccordionItem;
