import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Common } from "../../assets/images";
import AttachmentSet from "../Employees/AddEmployee/attachments";
import CampAssetsSet from "./camp-assets";
import { Camp } from "../../interfaces/employee";
// Redux
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setAddCampLoading } from "../../store/slices/campsSlice";
import { setCampsChange } from "../../store/slices/commonSlice";
// API
import { useEditCampMutation } from "../../services/Camps";
import { ToastErrorComponent } from "../Toasts";
import { LoaderWhite } from "../Lotties";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";

export interface AddCamp {
  campName: string;
  campSupervisor: string;
  campCity: string;
  campAddress: string;
  campCapacity: string;
  campOwnerShip: string;
  campAssets: {
    uId: string;
    item: string;
    count: number;
    estimatedValue: number;
  }[];
  campContractFrom: string;
  campContractTo: string;
  campAttachements: {
    uId: string;
    title: string;
    link: string;
    date: string;
    empId: number;
    empName: string;
  }[];
  campMonthlyRent: string;
}
const EditCamp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const campData: Camp = location.state.data;
  const [show, setShow] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const state = useAppSelector((state) => state.camps);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [showAttachment, setShowAttachment] = useState(false);
  const campsChange = useAppSelector((state) => state.common.campsChange);
  const allUsers = useAppSelector((state) => state.settings.allUsers);

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      title: string;
      link: string;
      date: string;
      empId: number;
      empName: string;
    }[]
  >(campData.campAttachements || []);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        title: "",
        link: "",
        date: new Date().toLocaleDateString(),
        empId: parseInt(profileData.employeeId) || 0,
        empName: profileData.firstName + " " + profileData.lastName || "",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      title: string;
      link: string;
      date: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <AttachmentSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  // Experience Sets
  const [experienceSets, setExperienceSets] = useState<React.ReactNode[]>([]);
  const [experienceData, setExperienceData] = useState<
    {
      uId: string;
      item: string;
      count: number;
      estimatedValue: number;
    }[]
  >(campData.campAssets || []);

  const handleAddExperienceSet = () => {
    setExperienceData([
      ...experienceData,
      {
        uId: Math.random().toString(36).substring(7),
        item: "",
        count: 0,
        estimatedValue: 0,
      },
    ]);
  };

  const handleRemoveExperienceSet = (index: string) => {
    const updatedInputData = experienceData.filter(
      (item, i) => item.uId !== index,
    );
    setExperienceData(updatedInputData);
  };

  const handleExperienceInputChange = (
    index: string,
    data: {
      item: string;
      count: number;
      estimatedValue: number;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = experienceData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setExperienceData(updatedInput);
  };

  useEffect(() => {
    const updatedExperienceSets = experienceData.map((data, index) => (
      <CampAssetsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveExperienceSet(key)}
        onInputChange={(inputData) =>
          handleExperienceInputChange(data.uId, inputData)
        }
      />
    ));
    setExperienceSets(updatedExperienceSets);
  }, [experienceData]);
  // Initial Values
  const initialValues: AddCamp = {
    campName: campData.campName || "",
    campSupervisor: campData.campSupervisor || "",
    campCity: campData.campCity || "",
    campAddress: campData.campAddress || "",
    campCapacity: campData.campCapacity.toString() || "",
    campOwnerShip: campData.campOwnerShip ? "Yes" : "No",
    campAssets: campData.campAssets || [],
    campContractFrom: campData.campContractFrom || "",
    campContractTo: campData.campContractTo || "",
    campAttachements: campData.campAttachements || [],
    campMonthlyRent: campData.campMonthlyRent
      ? campData.campMonthlyRent.toString()
      : "",
  };

  const [editCamp] = useEditCampMutation();
  const handleSubmit = async (values: AddCamp) => {
    dispatch(setAddCampLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.updateEmployee || false,
        payload: {
          campId: campData.campId,
          link: `/camps`,
          campName: values.campName,
          campSupervisor: values.campSupervisor,
          campCity: values.campCity,
          campAddress: values.campAddress,
          campCapacity: parseInt(values.campCapacity),
          campOwnerShip: values.campOwnerShip === "Yes" ? true : false,
          campAssets: experienceData,
          campContractFrom:
            values.campOwnerShip === "No" ? values.campContractFrom : "",
          campContractTo:
            values.campOwnerShip === "No" ? values.campContractTo : "",
          campAttachements: languageData,
          campMonthlyRent:
            values.campOwnerShip === "No"
              ? parseInt(values.campMonthlyRent)
              : 0,
        },
      };
      await editCamp(data).unwrap();
      dispatch(setCampsChange(!campsChange));
      navigate("/camps");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddCampLoading(false));
    }
  };

  // Options
  const supervisors = allUsers.filter((item) => item.role === "Supervisor");
  const supervisorOptions = supervisors.map((employee) => ({
    name: employee.firstName
      ? employee.firstName + " " + employee.lastName
      : "Not Signed Up", // Use the actual property name for the project name
    value: employee.employeeId,
  }));

  return (
    <div className="user-list mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading mb-4">
            <h1 className="heading mb-0">Edit Camp </h1>
          </div>
        </Col>
        <Col sm="12">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {!showAttachment && (
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp Name *
                        </h4>
                        <Form.Control
                          type="text"
                          name="campName"
                          onChange={handleChange}
                          placeholder="Enter camp name"
                          value={values.campName}
                          required={true}
                          className="custom-input border-fill"
                        />
                        {touched.campName && errors.campName ? (
                          <div className="invalid-feedback">
                            {errors.campName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp Supervisor
                        </h4>
                        <Form.Group className="position-relative input-design d-flex align-items-center">
                          <SelectSearch
                            name="campSupervisor"
                            options={supervisorOptions}
                            value={values.campSupervisor}
                            onChange={(value: any) => {
                              handleChange({
                                target: {
                                  name: "campSupervisor",
                                  value: value,
                                },
                              });
                            }}
                            search={true}
                            placeholder="Select Camp Supervisor"
                          />
                          <img src={Common.ArrowDown} alt="message" />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp City *
                        </h4>
                        <Form.Control
                          type="text"
                          name="campCity"
                          placeholder="Enter camp city"
                          onChange={handleChange}
                          value={values.campCity}
                          className="custom-input border-fill"
                        />
                        {touched.campCity && errors.campCity ? (
                          <div className="invalid-feedback">
                            {errors.campCity}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp Capacity *
                        </h4>
                        <Form.Control
                          type="text"
                          name="campCapacity"
                          placeholder="Enter camp capacity"
                          onChange={(e) => {
                            //     accept only numbers
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values.campCapacity}
                          className="custom-input border-fill"
                        />
                        {touched.campCapacity && errors.campCapacity ? (
                          <div className="invalid-feedback">
                            {errors.campCapacity}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp Ownership
                        </h4>
                        <Form.Select
                          name="campOwnerShip"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.campOwnerShip}
                          className="custom-input border-fill"
                        >
                          <option value="">Select Camp Ownership</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {values.campOwnerShip === "No" && (
                      <>
                        <Col sm="4">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Camp Monthly Rent
                            </h4>
                            <Form.Control
                              type="text"
                              name="campMonthlyRent"
                              placeholder="Enter camp monthly rent"
                              onChange={(e) => {
                                //     accept only numbers
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  handleChange(e);
                                }
                              }}
                              value={values.campMonthlyRent}
                              required={true}
                              className="custom-input border-fill"
                            />
                            {touched.campMonthlyRent &&
                            errors.campMonthlyRent ? (
                              <div className="invalid-feedback">
                                {errors.campMonthlyRent}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Camp Contract From
                            </h4>
                            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                              <DatePicker
                                name="campContractFrom"
                                selected={
                                  values.campContractFrom
                                    ? new Date(values.campContractFrom)
                                    : null
                                }
                                onChange={(date: any) =>
                                  handleChange({
                                    target: {
                                      name: "campContractFrom",
                                      value: date,
                                    },
                                  })
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                placeholderText="Contract From Date"
                                className="w-100 custom-input"
                                dateFormat="yyyy-MM-d"
                              />
                              <img
                                src={Common.Calendar}
                                className="calendar-date-recur"
                                alt="calendarblack"
                              />
                            </Form.Group>
                          </Form.Group>
                        </Col>
                        <Col sm="4">
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <h4
                              className="sub-heading normal-font-weight textBlack"
                              style={{ fontSize: "18px" }}
                            >
                              Camp Contract To
                            </h4>
                            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
                              <DatePicker
                                name="campContractTo"
                                selected={
                                  values.campContractTo
                                    ? new Date(values.campContractTo)
                                    : null
                                }
                                onChange={(date: any) =>
                                  handleChange({
                                    target: {
                                      name: "campContractTo",
                                      value: date,
                                    },
                                  })
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                placeholderText="Contract To Date"
                                className="w-100 custom-input"
                                dateFormat="yyyy-MM-d"
                              />
                              <img
                                src={Common.Calendar}
                                className="calendar-date-recur"
                                alt="calendarblack"
                              />
                            </Form.Group>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col sm="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <h4
                          className="sub-heading normal-font-weight textBlack"
                          style={{ fontSize: "18px" }}
                        >
                          Camp Address *
                        </h4>
                        <textarea
                          name="campAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.campAddress}
                          required={true}
                          placeholder="Add address 1"
                          className={
                            touched.campAddress && errors.campAddress
                              ? "is-invalid border-fill w-100"
                              : "border-fill w-100"
                          }
                          rows={6}
                          style={{ resize: "none" }}
                        />
                        {touched.campAddress && errors.campAddress ? (
                          <div className="invalid-feedback">
                            {errors.campAddress}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn primary-button w-25"
                          onClick={() => {
                            if (values.campName === "") {
                              setErrorContent("Camp Name is required");
                              setShow(true);
                              return;
                            }
                            if (values.campCity === "") {
                              setErrorContent("Camp City is required");
                              setShow(true);
                              return;
                            }
                            if (values.campCapacity === "") {
                              setErrorContent("Camp Capacity is required");
                              setShow(true);
                              return;
                            }
                            if (values.campAddress === "") {
                              setErrorContent("Camp address is required");
                              setShow(true);
                              return;
                            }
                            setShowAttachment(true);
                          }}
                        >
                          Next
                          <img src={Common.ArrowRightWhite} alt="arrow" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
                {showAttachment && (
                  <Row>
                    <Col sm="12">
                      <div className="text-end mt-4 d-flex align-items-center gap-4 mb-4">
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddExperienceSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Camp Asset
                        </button>
                        <button
                          className="btn primary-button normal-font-weight"
                          type="button"
                          onClick={handleAddLanguageSet}
                          style={{ width: "15%" }}
                        >
                          <img
                            src={Common.Plus}
                            width="20px"
                            height="20px"
                            className="me-2"
                            alt="next"
                          />{" "}
                          Attachments
                        </button>
                      </div>
                    </Col>
                    <Col sm="12">
                      {experienceSets.length > 0 && (
                        <h4 className="sub-heading normal-font-weight mb-4">
                          Camp Assets
                        </h4>
                      )}
                      <div className="extra-detail my-4">{experienceSets}</div>
                      {languageSets.length > 0 && (
                        <h4 className="sub-heading normal-font-weight mb-4">
                          Attachments
                        </h4>
                      )}
                      <div className="extra-detail my-4">{languageSets}</div>
                    </Col>
                    <Col sm="12">
                      <div className="d-flex justify-content-between mt-4">
                        <button
                          className="btn secondary-button w-25"
                          type="button"
                          onClick={() => {
                            setShowAttachment(false);
                          }}
                        >
                          <img
                            src={Common.ArrowLeftBlue}
                            className="me-1"
                            alt="next"
                          />{" "}
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn primary-button w-25"
                        >
                          {state.addCampLoading ? (
                            <LoaderWhite height={30} width={30} />
                          ) : (
                            "Edit Camp"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default EditCamp;
