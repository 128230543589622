import React from "react";
import InternalLayout from "../components/InternalLayout";
import EmployeeComponent from "../components/Employees";
import withAuth from "../config/private-route";

const Employees = () => {
  return (
    <InternalLayout>
      <EmployeeComponent />
    </InternalLayout>
  );
};
export default withAuth(Employees);
