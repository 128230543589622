import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const AbscondingComponent = () => {
  return (
    <div className="absconding-component">
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
};
export default AbscondingComponent;
