import React, { useRef, useEffect } from "react";
import { Table } from "react-bootstrap";
import LetterFormatA from "./letter-format-a";
import { useReactToPrint } from "react-to-print";
import { SalaryIncrement as SalaryProps } from "../../interfaces/letters";

interface Props {
  salaryIncrementData: SalaryProps;
}

const SalaryIncrement = ({ salaryIncrementData }: Props) => {
  const salaryIncrementRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => salaryIncrementRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div
      className="letter-container position-relative"
      ref={salaryIncrementRef}
    >
      <LetterFormatA heading="Salary Increment">
        <div className="my-2">
          <h4 className="sub-heading normal-font-weight text-black">
            Dear {salaryIncrementData.empName},
          </h4>
          <p
            className="para-medium text-black mb-0"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            This is to bring to your notice the fact that based on your recent
            assessment & your manager & client feedback, we have concluded that
            your salaryIncrement notice period is for days from today. <br />
            <br /> Based on these <b> days</b> we will decide whether to
            increase your salaryIncrement period or not.
          </p>
        </div>
        <div>
          <h4 className="sub-heading text-black">Employee Detail</h4>
          <Table striped bordered responsive className="text-center">
            <thead>
              <tr>
                <th style={{ fontWeight: "600" }}>Emp Id</th>
                <th style={{ fontWeight: "600" }}>Emp Name</th>
                <th style={{ fontWeight: "600" }}>Signature</th>
                <th style={{ fontWeight: "600" }}>Thumb</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{salaryIncrementData.empId}</td>
                <td>{salaryIncrementData.empName}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <h4 className="sub-heading normal-font-weight text-black mb-1">
          Approved By:
        </h4>
        <div className="signature-authority d-flex justify-content-between">
          <div className="authority">
            <h4 className="sub-heading text-black">Supervisor</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
          <div className="authority">
            <h4 className="sub-heading text-black">Supervisor Coordinator</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
          <div className="authority">
            <h4 className="sub-heading text-black">HR Manager</h4>
            <p
              className="para-medium text-black mb-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              23, May 2022
            </p>
          </div>
        </div>
      </LetterFormatA>
    </div>
  );
};

export default SalaryIncrement;
