import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import {
  useGetTransactionListQuery,
  useGetTransactionIdsQuery,
} from "../../services/Clients";
import {
  setPaymentList,
  setPaymentListLoading,
  setPaymentListError,
  setPaymentListTotalPages,
  setPaymentIdsError,
  setPaymentIdsLoading,
  setPaymentIds,
} from "../../store/slices/clientsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  // States
  const state = useAppSelector((state) => state.clients);

  // **************
  // **************
  // Get All Payment Ids
  // **************
  // **************
  const {
    data: allPaymentIds,
    isLoading: paymentIdsLoading,
    isError: paymentIdsError,
    refetch: paymentIdsRefetch,
  } = useGetTransactionIdsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    clientID: state.clientIdNumber,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (paymentIdsLoading) {
      dispatch(setPaymentIdsLoading(true));
    }
    if (paymentIdsError) {
      dispatch(setPaymentIdsError("Something went wrong"));
      dispatch(setPaymentIdsLoading(false));
    }
    if (allPaymentIds) {
      dispatch(setPaymentIds(allPaymentIds.data.payments || []));
      dispatch(setPaymentIdsLoading(false));
    }
  }, [accessToken, userID, allPaymentIds, profileData, state.clientIdNumber]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    paymentIdsRefetch();
  }, [clientsChange, state.clientIdNumber]);

  // **************
  // **************
  // Get All Payments
  // **************
  // **************
  const {
    data: allPaymentss,
    isLoading: paymentListLoading,
    isError: paymentListError,
    refetch: paymentListRefetch,
  } = useGetTransactionListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    pageNo: state.paymentListPageNo,
    pageSize: state.paymentListPageSize,
    clientID: state.clientIdNumber,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (paymentListLoading) {
      dispatch(setPaymentListLoading(true));
    }
    if (paymentListError) {
      dispatch(setPaymentListError("Something went wrong"));
      dispatch(setPaymentListLoading(false));
    }
    if (allPaymentss) {
      dispatch(setPaymentList(allPaymentss.data.payments[0].data || []));
      dispatch(
        setPaymentListTotalPages(
          allPaymentss.data.payments[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setPaymentListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allPaymentss,
    profileData,
    state.paymentListPageNo,
    state.paymentListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    paymentListRefetch();
  }, [clientsChange, state.clientIdNumber]);
  return <></>;
};
export default Content;
