import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const ProjectComponent = () => {
  return (
    <div className="attendance">
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
};
export default ProjectComponent;
