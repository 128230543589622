import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Pagination, Row, Table } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { AttendanceIcons, Common, EmployeeIcons } from "../../assets/images";
// Redux imports
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setAddTransferEmployeeLoading } from "../../store/slices/lettersSlice";
import {
  setEmployeeChange,
  setProjectChange,
  setTransferEmployeeChange,
} from "../../store/slices/commonSlice";
// API imports
import { useAddTransferRequestMutation } from "../../services/Letters";
import { LoaderWhite } from "../Lotties";
import { ToastErrorComponent } from "../Toasts";
import ApprovalsSet from "../Employees/approvals-set";
import { AllEmployees } from "../../interfaces/employee";
import DatePicker from "react-datepicker";
import { setActualSheetDate } from "../../store/slices/projectSlice";

const TransferNewJoiner = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const dispatch = useAppDispatch();
  const [employeesToBeAdded, setEmployeesToBeAdded] = useState<number[]>([]); // Array of employee ids to be added to project
  const [categorySelected, setCategorySelected] = useState<{
    [key: string]: string;
  }>({});
  const [show, setShow] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const state = useAppSelector((state) => state.letters);
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const [processType, setProcessType] = React.useState("");
  const [transferDate, setTransferDate] = React.useState("");
  const [reason, setReason] = React.useState("");
  const transferEmployeeChange = useAppSelector(
    (state) => state.common.transferEmployeeChange,
  );
  const projectChange = useAppSelector((state) => state.common.projectChange);
  const employeeChange = useAppSelector((state) => state.common.employeeChange);
  const idolEmployees = useAppSelector((state) => state.project.idolEmployees);
  const campIds = useAppSelector((state) => state.camps.campsIds);
  const allemployees = useAppSelector(
    (state) => state.employee.allEmployeesList,
  );
  const allEmployeess = allemployees.filter(
    (employee) =>
      employee.empPlace !== "Management" && employee.empWorking === "Busy",
  );
  const employees = processType
    ? processType === "Transfer" || processType === "Transfer To Camp"
      ? allEmployeess
      : idolEmployees
    : [];
  const projects = useAppSelector((state) => state.project.projectIds);

  const [newProjectId, setNewProjectId] = React.useState("");
  const [reasonForRemoval, setReasonForRemoval] = React.useState("");
  const [filteredEmployees, setFilteredEmployees] = useState<AllEmployees[]>(
    [],
  );
  const [query, setQuery] = useState("");
  const twoWords = (name: string) => {
    const words = name.split(" ");
    return words[0] + " " + words[1];
  };

  const options = employees.map((item) => ({
    name: item.empId + " - " + twoWords(item.empName),
    value: item.empId,
  }));

  // project detail
  const projectDetail = projects.find(
    (project) => project.projectId === newProjectId,
  );
  //   New Project Detail
  const projectOptions =
    processType === "Transfer To Camp"
      ? campIds.map((project) => ({
          name: project.campId + " - " + project.campName, // Use the actual property name for the project name
          value: project.campId,
        }))
      : projects.map((project) => ({
          name: project.projectId + " - " + project.projectName, // Use the actual property name for the project name
          value: project.projectId,
        }));

  const allUsers = useAppSelector((state) => state.settings.allUsers);
  const superadmin = allUsers.find((user) => user.role === "Super Admin");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredEmployees.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredEmployees.length / pageSize);

  // show 5 pages at a time in pagination bar and rest will be shown in next and previous
  const showPages = 5;
  const startPage = Math.floor((currentPage - 1) / showPages) * showPages;
  const endPage = startPage + showPages;
  const pages = [...Array(totalPages).keys()].slice(startPage, endPage);
  // handle go to page number that is after the 4 pages of current page
  const handleGoToNextPages = () => {
    //   if it is in the last 5 pages then dont do anything
    if (currentPage > totalPages - showPages) {
      return;
    }
    setCurrentPage(currentPage + showPages);
  };

  const handleGoToPreviousPages = () => {
    //   if it is in the first 5 pages then dont do anything
    if (currentPage <= 5) {
      return;
    }
    setCurrentPage(currentPage - showPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: any) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse the value to a number
    setPageSize(selectedPageSize);
  };

  const selectedEmployees = employees.filter((employee) =>
    employeesToBeAdded.includes(employee.empId),
  );
  // sort paginated data by employeesToBeAdded
  // const sortedPaginatedData = paginatedData.sort((a, b) => {
  //   if (employeesToBeAdded.includes(a.empId)) {
  //     return -1;
  //   } else {
  //     return 1;
  //   }
  // });

  const tableData = {
    tableHeading: [
      "Sr. No.",
      "Emp Code",
      "Name",
      "Phone",
      "Iqama No.",
      "Emp Category",
      "Nationality",
      "Place",
      "Project",
      "Working Status",
      "Select Category",
      "Select",
    ],
    tableData: paginatedData,
  };

  // Languages Sets
  const [languageSets, setLanguageSets] = useState<React.ReactNode[]>([]);
  const [languageData, setLanguageData] = useState<
    {
      uId: string;
      userId: string;
      date: string;
      name: string;
      role: string;
      status: string;
    }[]
  >([]);

  const handleAddLanguageSet = () => {
    setLanguageData([
      ...languageData,
      {
        uId: Math.random().toString(36).substring(7),
        userId: "",
        date: "",
        name: "",
        role: "",
        status: "Pending",
      },
    ]);
  };

  const handleRemoveLanguageSet = (index: string) => {
    const updatedInputData = languageData.filter(
      (item, i) => item.uId !== index,
    );
    setLanguageData(updatedInputData);
  };
  const handleLanguageInputChange = (
    index: string,
    data: {
      userId: string;
      name: string;
      role: string;
    },
  ) => {
    //  search by uniqueId and update the data
    const updatedInput = languageData.map((item) => {
      if (item.uId === index) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setLanguageData(updatedInput);
  };

  useEffect(() => {
    const updatedLanguageSets = languageData.map((data, index) => (
      <ApprovalsSet
        key={data.uId}
        data={data}
        uniqueId={data.uId}
        onRemove={(key: string) => handleRemoveLanguageSet(key)}
        onInputChange={(inputData) =>
          handleLanguageInputChange(data.uId, inputData)
        }
      />
    ));
    setLanguageSets(updatedLanguageSets);
  }, [languageData]);

  const campDetail =
    processType === "Transfer To Camp"
      ? campIds.find((item) => item.campId === newProjectId)
      : undefined;

  // get all userId from languageData array
  const userIds =
    languageData.length > 0 ? languageData.map((data) => data.userId) : [];

  const selectNewEmployees = employees.filter((employee) =>
    employeesToBeAdded.includes(employee.empId),
  );
  // Merging based on category
  const mergedData = selectNewEmployees.map((required) => {
    const employee = projectDetail?.employeeRequired.find((e) =>
      e.nationality === ""
        ? e.category + " " + e.nationality ===
          categorySelected[required.empId.toString()]
        : e.category + " " + e.nationality ===
            categorySelected[required.empId.toString()] &&
          e.nationality === required.empNationality,
    );

    if (processType !== "Transfer To Camp" && employee) {
      return {
        empId: required.empId,
        empName: required.empName,
        projectId: processType === "New Joiner" ? "" : required.empProjectId,
        newProjectId:
          processType === "Transfer To Camp" ? "" : projectDetail?.projectId,
        projectName:
          processType === "Transfer To Camp" ? "" : projectDetail?.projectName,
        projectSupervisor:
          processType === "Transfer To Camp"
            ? ""
            : projectDetail?.projectSupervisor,
        empProjectCategory:
          processType === "Transfer To Camp" ? "" : employee.category,
        empProjectPrice:
          processType === "Transfer To Camp" ? 0 : employee.price,
        empProjectWorkingHours:
          processType === "Transfer To Camp" ? 0 : employee.workingHours,
        transferCategory:
          required.empWorking === "Idol" ? "New Joiner" : "Transfer",
        campId: processType === "Transfer To Camp" ? newProjectId : "",
        campName:
          processType === "Transfer To Camp" ? campDetail?.campName : "",
        overTime: processType === "Transfer To Camp" ? 0 : employee.overTime,
        overTimePrice:
          processType === "Transfer To Camp" ? 0 : employee.overTimePrice,
        transferFrom: new Date(transferDate).toLocaleDateString(),
        status: "Approvals Pending",
        approvals: [
          {
            uId: Math.random().toString(36).substring(7),
            userId: superadmin?._id || "",
            date: "",
            name: superadmin?.firstName + " " + superadmin?.lastName || "",
            role: superadmin?.role || "",
            status: "Pending",
          },
          ...languageData,
        ],
        submittedBy: {
          empId: profileData.employeeId,
          empName: profileData.firstName + " " + profileData.lastName,
          remarks: reason,
        },
      };
    } else {
      return {
        empId: required.empId,
        empName: required.empName,
        projectId: processType === "New Joiner" ? "" : required.empProjectId,
        newProjectId:
          processType === "Transfer To Camp" ? "" : projectDetail?.projectId,
        projectName:
          processType === "Transfer To Camp" ? "" : projectDetail?.projectName,
        projectSupervisor:
          processType === "Transfer To Camp"
            ? ""
            : projectDetail?.projectSupervisor,
        empProjectCategory: "",
        empProjectPrice: 0,
        empProjectWorkingHours: 0,
        transferCategory: "Transfer To Camp",
        campId: processType === "Transfer To Camp" ? newProjectId : "",
        campName:
          processType === "Transfer To Camp" ? campDetail?.campName : "",
        overTime: 0,
        overTimePrice: 0,
        transferFrom: new Date(transferDate).toLocaleDateString(),
        status: "Approvals Pending",
        approvals: [
          {
            uId: Math.random().toString(36).substring(7),
            userId: superadmin?._id || "",
            date: "",
            name: superadmin?.firstName + " " + superadmin?.lastName || "",
            role: superadmin?.role || "",
            status: "Pending",
          },
          ...languageData,
        ],
        submittedBy: {
          empId: profileData.employeeId,
          empName: profileData.firstName + " " + profileData.lastName,
          remarks: reason,
        },
      };
    }

    return required;
  });

  // Handle Submit
  const [addTransferRequest] = useAddTransferRequestMutation();

  const handleSubmit = async () => {
    dispatch(setAddTransferEmployeeLoading(true));
    try {
      const data = {
        userID: userID || "",
        accessToken: accessToken || "",
        permission: profileData.permissions.addEmployee || false,
        payload: {
          employees: mergedData,
          link: `/projects/transfer-employee-requests`,
          ids: [superadmin?._id || "", ...userIds],
        },
      };
      await addTransferRequest(data).unwrap();
      dispatch(setTransferEmployeeChange(!transferEmployeeChange));
      dispatch(setEmployeeChange(!employeeChange));
      dispatch(setProjectChange(!projectChange));
      navigate("/projects/transfer-employee-requests");
    } catch (e: any) {
      if (e) {
        window.scrollTo(0, 0);
        setErrorContent(e.data.data.message);
        setShow(true);
      }
    } finally {
      dispatch(setAddTransferEmployeeLoading(false));
    }
  };

  const processTypeOptions =
    allemployees.length > 0
      ? [
          { name: "New Joiner", value: "New Joiner" },
          { name: "Transfer", value: "Transfer" },
          { name: "Transfer To Camp", value: "Transfer To Camp" },
        ]
      : [
          {
            name: "Loading Employees Please wait...",
            value: "",
          },
        ];

  const handleEmployeeSelection = (empId: number) => {
    if (processType !== "Transfer To Camp") {
      const category = projectDetail?.employeeRequired.find(
        (item) =>
          item.category + " " + item.nationality ===
          categorySelected[empId.toString()],
      );
      const employee = employees.find((item) => item.empId === empId);
      if (categorySelected[empId.toString()] === undefined) {
        window.scrollTo(0, 0);
        setErrorContent("Please select category for the employee");
        setShow(true);
        return;
      }
      if (category?.nationality === "") {
        setEmployeesToBeAdded((prevEmployees) => {
          if (prevEmployees.includes(empId)) {
            return prevEmployees.filter((id) => id !== empId);
          } else {
            return [...prevEmployees, empId];
          }
        });
        return;
      }
      if (category?.nationality !== employee?.empNationality) {
        window.scrollTo(0, 0);
        setErrorContent(
          "Employee Nationality does not match with selected category",
        );
        setShow(true);
        return;
      }
      setEmployeesToBeAdded((prevEmployees) => {
        if (prevEmployees.includes(empId)) {
          return prevEmployees.filter((id) => id !== empId);
        } else {
          return [...prevEmployees, empId];
        }
      });
    } else {
      setEmployeesToBeAdded((prevEmployees) => {
        if (prevEmployees.includes(empId)) {
          return prevEmployees.filter((id) => id !== empId);
        } else {
          return [...prevEmployees, empId];
        }
      });
    }
  };

  const dummyData = [
    {
      _id: "Dummy",
      empId: 0,
      empName: "Dummy",
      empProjectId: "Dummy",
      empWorking: "Dummy",
      empBasicSalary: 0,
      empSupervisorName: "Dummy",
      empCategory: "Dummy",
      empPlace: "Dummy",
      empNationality: "Dummy",
      empReligion: "Dummy",
      empPhone: "Dummy",
      empStatus: "Dummy",
      empIqamaNo: "Dummy",
      empIqamaExpiry: "Dummy",
      empProfessionOnIqama: "Dummy",
      empSponsorName: "Dummy",
      empBorderNo: 0,
      empPassportNo: "Dummy",
      empVisaNo: 0,
      empFoodAllowance: 0,
      empMobileAllowance: 0,
      empHra: 0,
      empTotalSalary: 0,
      entryStatus: true,
      exitStatus: true,
      empProjectCategory: "Dummy",
      empProjectPrice: 0,
      empProjectWorkingHours: 0,
      empIdNo: "Dummy",
      basicSalary: 0,
      dutyHours: 0,
      salaryTimeline: [],
      transferTimeline: [],
      empProjectJoiningDate: "",
      empIncrementDate: "",
      overTimePrice: 0,
      allowances: [],
    },
  ];

  useEffect(() => {
    if (query) {
      setFilteredEmployees(
        employees.filter((employee) =>
          employee.empId.toString().includes(query),
        ),
      );
    } else {
      setFilteredEmployees([...dummyData, ...employees]);
    }
  }, [query, processType]);
  return (
    <div className="offer-letter mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <Row>
        <Col sm="12">
          <div className="section-heading ">
            <h1 className="heading mb-0">Transfer/New Joiner Request</h1>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="ref-no d-flex align-items-center gap-2">
                <h4 className="sub-heading normal-font-weight textBlack mb-0">
                  Date:
                </h4>
                <p
                  className="sub-heading normal-font-weight mb-0"
                  style={{ fontSize: "16px", color: "#29406C" }}
                >
                  {currentDate}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Select Process Type
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={processTypeOptions}
                value={processType}
                onChange={(e: any) => setProcessType(e)}
                search={true}
                placeholder="Select Process Type"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="4">
          <div className="search-emp">
            <h4 className="sub-heading normal-font-weight textBlack mb-2 mt-4">
              Select{" "}
              {processType === "Transfer To Camp" ? "Camp" : "New Project"} ID
            </h4>
            <Form.Group className="position-relative input-design d-flex align-items-center">
              <SelectSearch
                options={projectOptions}
                value={newProjectId}
                onChange={(e: any) => setNewProjectId(e)}
                search={true}
                placeholder="Select new project id"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        <Col sm="4">
          <Form.Group className="mt-4" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Select Transfer Date
            </h4>
            <Form.Group className="d-flex align-items-center input-design position-relative w-100">
              <DatePicker
                name="month"
                selected={transferDate ? new Date(transferDate) : null}
                onChange={(e: any) => setTransferDate(e)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                placeholderText="Select start date"
                className="w-100 custom-input"
                dateFormat="dd/MM/yyyy"
              />
              <img
                src={Common.Calendar}
                className="calendar-date-recur"
                alt="calendarblack"
              />
            </Form.Group>
          </Form.Group>
        </Col>
        {processType === "Transfer To Camp" ? (
          <Col sm="12">
            <div className="emp-detail mt-5">
              <h4 className="sub-heading mb-4">Camp Detail</h4>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Camp ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {campDetail?.campId || "Select Camp Id"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {campDetail?.campName || "Select Camp Id"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Camp City:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {campDetail?.campCity || "Select Camp Id"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        ) : (
          <Col sm="12">
            <div className="emp-detail mt-5">
              <h4 className="sub-heading mb-4">New Project Detail</h4>
              <Row>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Project ID:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {projectDetail?.projectId || "Select Project Id"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Project Name:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {projectDetail?.projectName || "Select Project Id"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Project Location:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {projectDetail?.projectLocation || "Select Project Id"}
                    </p>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="emp-detail-item">
                    <h4
                      className="sub-heading normal-font-weight textBlack mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Supervisor:
                    </h4>
                    <p
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {projectDetail?.projectSupervisor || "Select Project Id"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
        <Col sm="6">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4
              className="sub-heading normal-font-weight textBlack"
              style={{ fontSize: "18px" }}
            >
              Reason To transfer
            </h4>
            <Form.Control
              type="text"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter reason to transfer"
              className="custom-input border-fill"
            />
          </Form.Group>
        </Col>
        <Col sm="12">
          <div className="select-employee">
            <h4 className="sub-heading">
              Selected Trasnfer/New Joiner Employees
            </h4>
          </div>
        </Col>
        <Col sm="12">
          {selectedEmployees.length > 0 ? (
            <Table striped bordered responsive className=" text-center mt-5">
              <thead>
                <tr className="border-0">
                  {tableData.tableHeading.map((item, index) => (
                    <th className="border-0" key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedEmployees.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderColor: "rgba(32, 32, 32, 0.20)",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empId}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empName}
                    </td>
                    <td>{item.empPhone}</td>
                    <td>{item.empIqamaNo}</td>
                    <td>{item.empCategory}</td>
                    <td>{item.empNationality}</td>
                    <td>{item.empPlace}</td>
                    <td>{item.empProjectId || "N/A"}</td>
                    <td>{item.empWorking}</td>
                    <td>
                      <Form.Select
                        name="category"
                        onChange={(e) => {
                          setCategorySelected((prev) => ({
                            ...prev,
                            [item.empId.toString()]: e.target.value,
                          }));
                        }}
                        value={categorySelected[item.empId.toString()]}
                        disabled={
                          employeesToBeAdded.includes(item.empId) ? true : false
                        }
                        className={
                          employeesToBeAdded.includes(item.empId)
                            ? "custom-input border-fill disabled-input text-white"
                            : "custom-input border-fill"
                        }
                      >
                        <option value="">Select Category</option>
                        {projectDetail?.employeeRequired.map((item, index) => (
                          <option
                            key={index}
                            value={item.category + " " + item.nationality}
                          >
                            {item.category + " - " + item.nationality}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td>
                      <button
                        className="btn p-0 border-0 me-2"
                        type="button"
                        onClick={() => {
                          handleEmployeeSelection(item.empId);
                          setQuery("");
                        }}
                      >
                        {employeesToBeAdded.includes(item.empId) ? (
                          <img
                            src={AttendanceIcons.FilledCircle}
                            width="22px"
                            height="22px"
                            alt="edit-user"
                          />
                        ) : (
                          <img
                            src={AttendanceIcons.EmptyCircle}
                            width="22px"
                            height="22px"
                            alt="edit-user"
                          />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">
                No Transfer/New Joiner Employees Selected
              </h1>
            </div>
          )}
        </Col>
        <Col sm="12">
          <div className="select-employee">
            <h4 className="sub-heading">Select Employees</h4>
          </div>
        </Col>
        <Col sm="4">
          <div className="d-flex align-items-center gap-3 mt-4">
            <Form.Group className="position-relative input-design d-flex align-items-center w-100">
              <SelectSearch
                name="emp"
                options={options}
                value={query}
                onChange={(value: any) => {
                  setQuery(value);
                  setCategorySelected((prev) => ({
                    ...prev,
                    [query]: "",
                  }));
                }}
                search={true}
                placeholder="Select Employee"
              />
              <img src={Common.ArrowDown} alt="message" />
            </Form.Group>
          </div>
        </Col>
        {query && (
          <Col sm="4">
            <div className="mt-4">
              <button
                className="btn primary-button"
                onClick={() => setQuery("")}
              >
                Reset
              </button>
            </div>
          </Col>
        )}
        <Col sm="12">
          {paginatedData.length > 0 ? (
            <Table striped bordered responsive className=" text-center mt-5">
              <thead>
                <tr className="border-0">
                  {tableData.tableHeading.map((item, index) => (
                    <th className="border-0" key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.tableData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderColor: "rgba(32, 32, 32, 0.20)",
                    }}
                  >
                    <td>
                      {employees.length > 0
                        ? (currentPage - 1) * pageSize + index + 1
                        : index + 1}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empId}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/employees/employee-detail/${item.empId}`, {
                          state: { empId: item.empId },
                        })
                      }
                    >
                      {item.empName}
                    </td>
                    <td>{item.empPhone}</td>
                    <td>{item.empIqamaNo}</td>
                    <td>{item.empCategory}</td>
                    <td>{item.empNationality}</td>
                    <td>{item.empPlace}</td>
                    <td>{item.empProjectId || "N/A"}</td>
                    <td>{item.empWorking}</td>
                    <td>
                      {item._id === "Dummy" ? (
                        "Dummy"
                      ) : (
                        <Form.Select
                          name="category"
                          onChange={(e) => {
                            setCategorySelected((prev) => ({
                              ...prev,
                              [item.empId.toString()]: e.target.value,
                            }));
                          }}
                          value={categorySelected[item.empId.toString()]}
                          disabled={
                            employeesToBeAdded.includes(item.empId)
                              ? true
                              : false
                          }
                          className={
                            employeesToBeAdded.includes(item.empId)
                              ? "custom-input border-fill disabled-input text-white"
                              : "custom-input border-fill"
                          }
                        >
                          <option value="">Select Category</option>
                          {projectDetail?.employeeRequired.map(
                            (item, index) => (
                              <option
                                key={index}
                                value={item.category + " " + item.nationality}
                              >
                                {item.category + " - " + item.nationality}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      )}
                    </td>
                    <td>
                      {item._id === "Dummy" ? (
                        "Dummy"
                      ) : (
                        <button
                          className="btn p-0 border-0 me-2"
                          type="button"
                          onClick={() => {
                            handleEmployeeSelection(item.empId);
                            setQuery("");
                          }}
                        >
                          {employeesToBeAdded.includes(item.empId) ? (
                            <img
                              src={AttendanceIcons.FilledCircle}
                              width="22px"
                              height="22px"
                              alt="edit-user"
                            />
                          ) : (
                            <img
                              src={AttendanceIcons.EmptyCircle}
                              width="22px"
                              height="22px"
                              alt="edit-user"
                            />
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center mt-5">
              <h1 className="heading">No Employees Found</h1>
            </div>
          )}
        </Col>
        {paginatedData.length > 0 && (
          <Col sm="12">
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <Form.Group
                className="d-flex align-items-center gap-2"
                controlId="formBasicEmail"
              >
                <h4
                  className="sub-heading normal-font-weight textBlack"
                  style={{ fontSize: "14px", width: "230px" }}
                >
                  Entries per page
                </h4>
                <Form.Select
                  name="pageSize"
                  onChange={handlePageSizeChange}
                  value={pageSize.toString()}
                  className="custom-input border-fill custom-height"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
              <div className="pagination-custom">
                <Pagination className="justify-content-end">
                  <Pagination.Item onClick={() => handleGoToPreviousPages()}>
                    <img src={Common.DoubleArrowLeft} alt="arrow" />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-left"
                      alt="arrow"
                    />
                  </Pagination.Item>

                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => handlePageChange(page + 1)}
                      className={page + 1 === currentPage ? "active-page" : ""}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Item
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages,
                      )
                    }
                  >
                    <img
                      src={EmployeeIcons.ArrowDownBlue}
                      className="rotate-right"
                      alt="arrow"
                    />
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      handleGoToNextPages();
                    }}
                  >
                    <img src={Common.DoubleArrowRight} alt="arrow" />
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </Col>
        )}
        <Col sm="12">
          <div className="text-end mt-4 d-flex align-items-center gap-4">
            <button
              className="btn primary-button normal-font-weight"
              type="button"
              onClick={handleAddLanguageSet}
              style={{ width: "15%" }}
            >
              <img
                src={Common.Plus}
                width="20px"
                height="20px"
                className="me-2"
                alt="next"
              />{" "}
              Approval user
            </button>
          </div>
        </Col>
        <Col sm="12">
          <div className="extra-detail my-4">
            {languageSets.length > 0 && (
              <h4 className="sub-heading normal-font-weight mb-4">
                Approval will be sent to these users:
              </h4>
            )}
            {languageSets}
          </div>
        </Col>
        {processType && newProjectId && employeesToBeAdded.length > 0 && (
          <Col sm="12">
            <div className="emp-detail-print text-end mt-5">
              <button
                className="btn primary-button w-25"
                onClick={handleSubmit}
              >
                {state.addTransferEmployeeLoading ? (
                  <LoaderWhite height={30} width={30} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default TransferNewJoiner;
