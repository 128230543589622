import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Attendance
import { useGetTodaysAttendanceMutation } from "../../services/Attendance";
import {
  setAttendanceLogLoading,
  setAttendances,
  setTotalRecords as attendanceTotalRecords,
} from "../../store/slices/attendanceSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const attendanceChange = useAppSelector(
    (state) => state.common.attendanceChange,
  );
  // States
  const state = useAppSelector((state) => state.attendance);

  // **************
  // **************
  // Get Todays Attendance
  // **************
  // **************
  const [getTodaysAttendance] = useGetTodaysAttendanceMutation();
  const getTodaysAttendanceLog = async () => {
    dispatch(setAttendanceLogLoading(true));
    const data = {
      userID: userID || "",
      accessToken: accessToken || "",
      permission: profileData.permissions.showAttendance || false,
      page: state.pageNo,
      limit: state.pageSize,
      payload: {
        date: new Date().toLocaleDateString(),
      },
    };
    try {
      const result = await getTodaysAttendance(data).unwrap();
      if (result) {
        dispatch(setAttendances(result.data.attendances[0].data || []));
        dispatch(
          attendanceTotalRecords(
            result.data.attendances[0].metadata[0]?.total || 0,
          ),
        );
      }
    } catch (e) {
    } finally {
      dispatch(setAttendanceLogLoading(false));
    }
  };
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    getTodaysAttendanceLog();
  }, [
    accessToken,
    userID,
    attendanceChange,
    profileData,
    state.pageNo,
    state.pageSize,
  ]);
  return <></>;
};
export default Content;
