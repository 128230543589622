import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetCampListQuery } from "../../services/Camps";
import {
  setCampList,
  setCampListLoading,
  setCampListError,
  setCampListTotalPages,
} from "../../store/slices/campsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const campsChange = useAppSelector((state) => state.common.campsChange);
  // States
  const state = useAppSelector((state) => state.camps);

  // **************
  // **************
  // Get All Camps
  // **************
  // **************
  const {
    data: allCamps,
    isLoading: campListLoading,
    isError: campListError,
    refetch: campListRefetch,
  } = useGetCampListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    pageNo: state.campsListPageNo,
    pageSize: state.campsListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (campListLoading) {
      dispatch(setCampListLoading(true));
    }
    if (campListError) {
      dispatch(setCampListError("Something went wrong"));
      dispatch(setCampListLoading(false));
    }
    if (allCamps) {
      dispatch(setCampList(allCamps.data.camps[0].data || []));
      dispatch(
        setCampListTotalPages(allCamps.data.camps[0].metadata[0]?.total || 0),
      );
      dispatch(setCampListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allCamps,
    profileData,
    state.campsListPageNo,
    state.campsListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    campListRefetch();
  }, [campsChange]);
  return <></>;
};
export default Content;
