import { createSlice } from "@reduxjs/toolkit";
import {
  Client,
  ClientId,
  Invoice,
  InvoiceId,
  Payment,
  PaymentId,
  Statement,
} from "../../interfaces/project";

interface Index {
  // Tabs
  activeTab: string;
  tabList: string[];
  showClientDetails: boolean;
  showInvoiceDetails: boolean;
  showTransactionDetails: boolean;
  showAttachmentDetails: boolean;
  showStatementDetails: boolean;
  // Clients
  clientList: Client[];
  clientListLoading: boolean;
  clientListError: string;
  clientListPageNo: number;
  clientListPageSize: number;
  clientListTotalPages: number;
  filterClientPageNo: number;
  filterClientPageSize: number;
  filterClientTotalPages: number;
  showClientExportDropdown: boolean;
  addClientLoading: boolean;
  clientIds: ClientId[];
  clientIdsLoading: boolean;
  clientIdsError: string;
  //   Invoices
  invoiceList: Invoice[];
  invoiceListLoading: boolean;
  invoiceListError: string;
  invoiceListPageNo: number;
  invoiceListPageSize: number;
  invoiceListTotalPages: number;
  filterInvoicePageNo: number;
  filterInvoicePageSize: number;
  filterInvoiceTotalPages: number;
  showInvoiceExportDropdown: boolean;
  addInvoiceLoading: boolean;
  invoiceIds: InvoiceId[];
  invoiceIdsLoading: boolean;
  invoiceIdsError: string;
  clientIdNumber: string;
  //   Payments
  paymentList: Payment[];
  paymentListLoading: boolean;
  paymentListError: string;
  paymentListPageNo: number;
  paymentListPageSize: number;
  paymentListTotalPages: number;
  filterPaymentPageNo: number;
  filterPaymentPageSize: number;
  filterPaymentTotalPages: number;
  showPaymentExportDropdown: boolean;
  addPaymentLoading: boolean;
  paymentIds: PaymentId[];
  paymentIdsLoading: boolean;
  paymentIdsError: string;
  //   Statement
  statementIds: Statement;
  statementIdsLoading: boolean;
  statementIdsError: string;
  statementFrom: string;
  statementTo: string;
}

const getFirstDateOfYear = () => {
  const year = new Date().getFullYear(); // Get the year of the provided date
  const firstDateOfYear = new Date(year, 0, 1); // Create a new date object with the first day of the year

  return firstDateOfYear;
};

const returnLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const initialState: Index = {
  // Tabs
  activeTab: "Client Detail",
  tabList: [
    "Client Detail",
    "Invoices",
    "Payments",
    "Attachments",
    "Statement",
  ],
  showClientDetails: true,
  showInvoiceDetails: false,
  showTransactionDetails: false,
  showAttachmentDetails: false,
  showStatementDetails: false,
  // Clients
  clientList: [],
  clientListLoading: false,
  clientListError: "",
  clientListPageNo: 1,
  clientListPageSize: 10,
  clientListTotalPages: 0,
  filterClientPageNo: 1,
  filterClientPageSize: 10,
  filterClientTotalPages: 0,
  showClientExportDropdown: false,
  addClientLoading: false,
  clientIds: [],
  clientIdsLoading: false,
  clientIdsError: "",
  //   Invoices
  invoiceList: [],
  invoiceListLoading: false,
  invoiceListError: "",
  invoiceListPageNo: 1,
  invoiceListPageSize: 10,
  invoiceListTotalPages: 0,
  filterInvoicePageNo: 1,
  filterInvoicePageSize: 10,
  filterInvoiceTotalPages: 0,
  showInvoiceExportDropdown: false,
  addInvoiceLoading: false,
  invoiceIds: [],
  invoiceIdsLoading: false,
  invoiceIdsError: "",
  clientIdNumber: "",
  //   Payments
  paymentList: [],
  paymentListLoading: false,
  paymentListError: "",
  paymentListPageNo: 1,
  paymentListPageSize: 10,
  paymentListTotalPages: 0,
  filterPaymentPageNo: 1,
  filterPaymentPageSize: 10,
  filterPaymentTotalPages: 0,
  showPaymentExportDropdown: false,
  addPaymentLoading: false,
  paymentIds: [],
  paymentIdsLoading: false,
  paymentIdsError: "",
  //   Statement
  statementIds: {
    statements: [],
    openingBalance: 0,
    invoicedAmount: 0,
    amountReceived: 0,
    balanceDue: 0,
  },
  statementIdsLoading: false,
  statementIdsError: "",
  statementFrom: getFirstDateOfYear().toLocaleDateString(),
  statementTo: returnLastDateOfMonth().toLocaleDateString(),
};

const clientsSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    // Tabs
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setShowClientDetails: (state, action) => {
      state.showClientDetails = action.payload;
    },
    setShowInvoiceDetails: (state, action) => {
      state.showInvoiceDetails = action.payload;
    },
    setShowTransactionDetails: (state, action) => {
      state.showTransactionDetails = action.payload;
    },
    setShowAttachmentDetails: (state, action) => {
      state.showAttachmentDetails = action.payload;
    },
    setShowStatementDetails: (state, action) => {
      state.showStatementDetails = action.payload;
    },
    // Clients
    setClientListLoading: (state, action) => {
      state.clientListLoading = action.payload;
    },
    setClientListError: (state, action) => {
      state.clientListError = action.payload;
    },
    setClientList: (state, action) => {
      state.clientList = action.payload;
    },
    setClientListPageNo: (state, action) => {
      state.clientListPageNo = action.payload;
    },
    setClientListPageSize: (state, action) => {
      state.clientListPageSize = action.payload;
    },
    setClientListTotalPages: (state, action) => {
      state.clientListTotalPages = action.payload;
    },
    setFilterClientPageNo: (state, action) => {
      state.filterClientPageNo = action.payload;
    },
    setFilterClientPageSize: (state, action) => {
      state.filterClientPageSize = action.payload;
    },
    setFilterClientTotalPages: (state, action) => {
      state.filterClientTotalPages = action.payload;
    },
    setShowClientExportDropdown: (state, action) => {
      state.showClientExportDropdown = action.payload;
    },
    setAddClientLoading: (state, action) => {
      state.addClientLoading = action.payload;
    },
    setClientIds: (state, action) => {
      state.clientIds = action.payload;
    },
    setClientIdsLoading: (state, action) => {
      state.clientIdsLoading = action.payload;
    },
    setClientIdsError: (state, action) => {
      state.clientIdsError = action.payload;
    },
    //     Invoices
    setInvoiceListLoading: (state, action) => {
      state.invoiceListLoading = action.payload;
    },
    setInvoiceListError: (state, action) => {
      state.invoiceListError = action.payload;
    },
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
    setInvoiceListPageNo: (state, action) => {
      state.invoiceListPageNo = action.payload;
    },
    setInvoiceListPageSize: (state, action) => {
      state.invoiceListPageSize = action.payload;
    },
    setInvoiceListTotalPages: (state, action) => {
      state.invoiceListTotalPages = action.payload;
    },
    setFilterInvoicePageNo: (state, action) => {
      state.filterInvoicePageNo = action.payload;
    },
    setFilterInvoicePageSize: (state, action) => {
      state.filterInvoicePageSize = action.payload;
    },
    setFilterInvoiceTotalPages: (state, action) => {
      state.filterInvoiceTotalPages = action.payload;
    },
    setShowInvoiceExportDropdown: (state, action) => {
      state.showInvoiceExportDropdown = action.payload;
    },
    setAddInvoiceLoading: (state, action) => {
      state.addInvoiceLoading = action.payload;
    },
    setInvoiceIds: (state, action) => {
      state.invoiceIds = action.payload;
    },
    setInvoiceIdsLoading: (state, action) => {
      state.invoiceIdsLoading = action.payload;
    },
    setInvoiceIdsError: (state, action) => {
      state.invoiceIdsError = action.payload;
    },
    setClientIdNumber: (state, action) => {
      state.clientIdNumber = action.payload;
    },
    //   Payments
    setPaymentListLoading: (state, action) => {
      state.paymentListLoading = action.payload;
    },
    setPaymentListError: (state, action) => {
      state.paymentListError = action.payload;
    },
    setPaymentList: (state, action) => {
      state.paymentList = action.payload;
    },
    setPaymentListPageNo: (state, action) => {
      state.paymentListPageNo = action.payload;
    },
    setPaymentListPageSize: (state, action) => {
      state.paymentListPageSize = action.payload;
    },
    setPaymentListTotalPages: (state, action) => {
      state.paymentListTotalPages = action.payload;
    },
    setFilterPaymentPageNo: (state, action) => {
      state.filterPaymentPageNo = action.payload;
    },
    setFilterPaymentPageSize: (state, action) => {
      state.filterPaymentPageSize = action.payload;
    },
    setFilterPaymentTotalPages: (state, action) => {
      state.filterPaymentTotalPages = action.payload;
    },
    setShowPaymentExportDropdown: (state, action) => {
      state.showPaymentExportDropdown = action.payload;
    },
    setAddPaymentLoading: (state, action) => {
      state.addPaymentLoading = action.payload;
    },
    setPaymentIds: (state, action) => {
      state.paymentIds = action.payload;
    },
    setPaymentIdsLoading: (state, action) => {
      state.paymentIdsLoading = action.payload;
    },
    setPaymentIdsError: (state, action) => {
      state.paymentIdsError = action.payload;
    },
    //   Statement
    setStatementIds: (state, action) => {
      state.statementIds = action.payload;
    },
    setStatementIdsLoading: (state, action) => {
      state.statementIdsLoading = action.payload;
    },
    setStatementIdsError: (state, action) => {
      state.statementIdsError = action.payload;
    },
    setStatementFrom: (state, action) => {
      state.statementFrom = action.payload;
    },
    setStatementTo: (state, action) => {
      state.statementTo = action.payload;
    },
  },
});

export const {
  setActiveTab,
  setShowClientDetails,
  setShowInvoiceDetails,
  setShowTransactionDetails,
  setShowAttachmentDetails,
  setShowStatementDetails,
  setClientListLoading,
  setClientListError,
  setClientList,
  setClientListPageNo,
  setClientListPageSize,
  setClientListTotalPages,
  setFilterClientPageNo,
  setFilterClientPageSize,
  setFilterClientTotalPages,
  setShowClientExportDropdown,
  setAddClientLoading,
  setClientIds,
  setClientIdsLoading,
  setClientIdsError,
  setInvoiceListLoading,
  setInvoiceListError,
  setInvoiceList,
  setInvoiceListPageNo,
  setInvoiceListPageSize,
  setInvoiceListTotalPages,
  setFilterInvoicePageNo,
  setFilterInvoicePageSize,
  setFilterInvoiceTotalPages,
  setShowInvoiceExportDropdown,
  setAddInvoiceLoading,
  setInvoiceIds,
  setInvoiceIdsLoading,
  setInvoiceIdsError,
  setClientIdNumber,
  setPaymentListLoading,
  setPaymentListError,
  setPaymentList,
  setPaymentListPageNo,
  setPaymentListPageSize,
  setPaymentListTotalPages,
  setFilterPaymentPageNo,
  setFilterPaymentPageSize,
  setFilterPaymentTotalPages,
  setShowPaymentExportDropdown,
  setAddPaymentLoading,
  setPaymentIds,
  setPaymentIdsLoading,
  setPaymentIdsError,
  setStatementIds,
  setStatementIdsLoading,
  setStatementIdsError,
  setStatementFrom,
  setStatementTo,
} = clientsSlice.actions;
export default clientsSlice.reducer;
