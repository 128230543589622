import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Common, SettingsIcons } from "../../assets/images";
import { useAppSelector } from "../../store/hooks";
import { EmployeePayroll } from "../../interfaces/employee";
import { LoaderWhite } from "../Lotties";
import ConfirmCreatePayslipsModal from "../Modals/create-payslips-modal";
import { ToastErrorComponent, ToastSuccessComponent } from "../Toasts";
import EmployeePayslip from "../Letters/employee-payslip";

const ViewCreatePayroll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCreatePayrollModal, setShowCreatePayrollModal] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [show, setShow] = useState(false);
  const payrollChange = useAppSelector((state) => state.common.payrollChange);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState("");
  const state = useAppSelector((state) => state.employeePayroll);
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };

  const [payrollDate, setPayrollDate] = useState("");
  const payslipData: EmployeePayroll = location.state.data;
  const [hideLetterHead, setHideLetterHead] = useState(false);

  // Earnings
  const allowancesArray = payslipData.salaryTimeline.map((allowance) => {
    return {
      title: allowance.title,
      description: "Allowance",
      type: "allowance",
      hours: 0,
      rate: 0,
      amount: allowance.amount,
    };
  });
  const basicSalaryDataArray = payslipData.salaryData.map((salary) => {
    return {
      title: "Basic Salary",
      description: "",
      type: "basic",
      hours: salary.workedHours,
      rate: salary.perHourCost,
      amount: salary.totalSalary,
    };
  });
  const overtimeDataArray = payslipData.salaryData.map((overtime) => {
    return {
      title: "Overtime",
      description: "",
      type: "overtime",
      hours: overtime.overtimeHours,
      rate: overtime.perHourCost,
      amount: overtime.totalOvertimeAmount,
    };
  });
  const expenseAdditionDataArray = payslipData.matchingExpenses
    .filter((item) => item.expenseCategory === "Addition")
    .map((expense) => {
      const finalObject =
        expense.installments.length > 0
          ? {
              title: expense.expenseType,
              description: expense.expenseDetail,
              type: "expense",
              hours: 0,
              rate: 0,
              amount: expense.installments.reduce(
                (acc, item) => acc + item.amount,
                0,
              ),
            }
          : {
              title: expense.expenseType,
              description: expense.expenseDetail,
              type: "expense",
              hours: 0,
              rate: 0,
              amount: expense.expenseAmount,
            };
      return finalObject;
    });

  const [earnings, setEarnings] = useState([
    ...basicSalaryDataArray,
    ...overtimeDataArray,
    ...allowancesArray,
    ...expenseAdditionDataArray,
  ]);

  // Deductions

  const expenseDeductionDataArrays = payslipData.matchingExpenses
    .filter((item) => item.expenseCategory === "Deduction")
    .map((expense) => {
      const finalObject =
        expense.installments.length > 0
          ? {
              title: expense.expenseType,
              description: expense.expenseDetail,
              amount: expense.installments.reduce(
                (acc, item) => acc + item.amount,
                0,
              ),
            }
          : {
              title: expense.expenseType,
              description: expense.expenseDetail,
              amount: expense.expenseAmount,
            };
      return finalObject;
    });

  const [expenseDeductionDataArray, setExpenseDeductionDataArray] = useState(
    expenseDeductionDataArrays,
  );

  const grossSalary = earnings.reduce((acc, item) => acc + item.amount, 0);

  const totalDeductions = expenseDeductionDataArray.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  const netSalary = grossSalary - totalDeductions;

  const basicSalary = basicSalaryDataArray.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  const overtimeSalary = overtimeDataArray.reduce(
    (acc, item) => acc + item.amount,
    0,
  );

  const salaryIds = payslipData.salaryData.map((salary) => salary._id);
  const installmentIds = payslipData.matchingExpenses.map((expense) =>
    expense.installments.map((item) => item._id),
  );

  const expenseIds = payslipData.matchingExpenses.map((expense) => expense._id);

  const mixedInstallmentIds = installmentIds.flat();
  const mixedExpenseIds = expenseIds.concat(mixedInstallmentIds);

  const payload = {
    payrollId: "",
    payrollDate: new Date(payrollDate).toLocaleDateString(),
    projectId: payslipData.salaryData[0].projectId,
    empId: payslipData.empId,
    empName: payslipData.empName,
    empPhone: payslipData.empPhone,
    empNationality: payslipData.empNationality,
    empIqamaNo: payslipData.empIqamaNo,
    status: "Pending",
    grossSalary: grossSalary,
    totalDeductions: totalDeductions,
    netSalary: netSalary,
    workedSalary: basicSalary + overtimeSalary,
    earnings: earnings,
    deductions: expenseDeductionDataArray,
    salaryIds: salaryIds,
    expenseIds: mixedExpenseIds,
  };

  const handleReset = () => {
    setEarnings([
      ...basicSalaryDataArray,
      ...overtimeDataArray,
      ...allowancesArray,
      ...expenseAdditionDataArray,
    ]);
    setExpenseDeductionDataArray(expenseDeductionDataArrays);
  };

  return (
    <div className=" mt-2 section px-4 py-4">
      <ToastErrorComponent
        show={show}
        setShow={setShow}
        content={errorContent}
      />
      <ToastSuccessComponent
        show={showSuccess}
        setShow={setShowSuccess}
        content={successContent}
      />
      <ConfirmCreatePayslipsModal
        show={showCreatePayrollModal}
        setShow={setShowCreatePayrollModal}
        payload={[payload]}
        setPayrollDate={setPayrollDate}
        payrollDate={payrollDate}
        createPayslipLoading={state.addEmployeePayrollLoading}
        setSuccessContent={setSuccessContent}
        setShowSuccess={setShowSuccess}
        payslipChange={payrollChange}
      />
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <EmployeePayslip
            removeLetterHead={hideLetterHead}
            payload={payload}
          />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-4">
                <button
                  className="btn border-0 p-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                >
                  {" "}
                  <img src={Common.ArrowLeftBlue} alt="next" />
                </button>
                <h4 className="sub-heading mb-0" style={{ fontSize: "22px" }}>
                  Payslip Detail
                </h4>
              </div>
              <div className="d-flex align-items-center gap-4">
                <Form.Check
                  type="checkbox"
                  id="hideLetterHeadCheckbox" // Add an id to target the checkbox
                  label="Hide Senyar Letter Head"
                  className="hide-letter-head"
                  name="hideLetterHead"
                  checked={hideLetterHead}
                  onChange={(e) => {
                    setHideLetterHead(e.target.checked);
                  }}
                />
                <button
                  className="btn primary-button"
                  onClick={handlePrint}
                  type="button"
                >
                  Preview
                </button>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Date
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.payrollDate
                      ? new Date(payload.payrollDate).toLocaleDateString()
                      : ""}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.empId}
                  </h5>
                </Form.Group>
              </Col>

              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Phone
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.empPhone}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Status
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.status}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Gross Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.grossSalary.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Total Deductions
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.totalDeductions.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Net Salary
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {payload.netSalary.toFixed(2)}
                  </h5>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="sub-heading mb-0">Earnings</h1>
            </div>
          </Col>
          <Col sm="12">
            {earnings.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Hours</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earnings.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.hours}</td>
                        <td>{item.rate.toFixed(2)}</td>
                        <td>{item.amount.toFixed(2)}</td>
                        <td>
                          {item.type === "expense" ||
                          item.type === "allowance" ? (
                            <button
                              className="btn p-0 border-0"
                              onClick={() => {
                                const newEarnings = earnings.filter(
                                  (earning) => earning.title !== item.title,
                                );
                                setEarnings(newEarnings);
                              }}
                            >
                              <img
                                src={SettingsIcons.Delete}
                                width="24px"
                                height="24px"
                                alt="edit-user"
                              />
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No earnings found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="sub-heading mb-0">Deductions</h1>
            </div>
          </Col>
          <Col sm="12">
            {expenseDeductionDataArray.length > 0 ? (
              <div className="table-responsive mb-3">
                <table className="table text-center table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseDeductionDataArray.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.amount.toFixed(2)}</td>
                        <td>
                          <button
                            className="btn p-0 border-0"
                            onClick={() => {
                              const newDeductions =
                                expenseDeductionDataArray.filter(
                                  (earning) => earning.title !== item.title,
                                );
                              setExpenseDeductionDataArray(newDeductions);
                            }}
                          >
                            <img
                              src={SettingsIcons.Delete}
                              width="24px"
                              height="24px"
                              alt="edit-user"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col sm="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <h4
                    className="sub-heading textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    No deductions found
                  </h4>
                </Form.Group>
              </Col>
            )}
          </Col>
          <Col sm="12">
            <div className="text-end">
              <button className="btn primary-button me-3" onClick={handleReset}>
                Reset Data
              </button>
              <button
                className="btn primary-button w-25"
                onClick={() =>
                  setShowCreatePayrollModal(!showCreatePayrollModal)
                }
              >
                Create Payslip
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewCreatePayroll;
