import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { TransferEmployee } from "../../interfaces/letters";
import TransferNewJoinerLetter from "../Letters/transfer-new-joiner-letter";

const TransferRequestDetail = () => {
  const [print, setPrint] = useState(false);
  const handlePrint = () => {
    setPrint(true);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const statementData: TransferEmployee = location.state.data;

  const transferCategory = location.state.data.transferCategory;

  // check if all status are approved in approvals array
  const isApproved = statementData.approvals.every(
    (item) =>
      item.status === "Approved" || item.status === "Approved by Super Admin",
  );

  return (
    <div className=" mt-2 section px-4 py-4">
      {print ? (
        <div>
          <button
            className="btn primary-button"
            onClick={() => setPrint(false)}
          >
            Back
          </button>
          <TransferNewJoinerLetter item={statementData} />
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="section-heading mb-4">
              <h1 className="heading mb-0">
                {transferCategory === "Transfer" ? "Transfer" : "New Joiner"}{" "}
                Employee Request Detail
              </h1>
            </div>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.empName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {transferCategory === "Transfer"
                      ? statementData.projectId
                      : statementData.newProjectId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.projectName}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Project Supervisor
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.projectSupervisor}
                  </h5>
                </Form.Group>
              </Col>
              {transferCategory === "Transfer" && (
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      New Project/Camp Id
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData?.campId
                        ? statementData?.campId
                        : statementData?.newProjectId}
                    </h5>
                  </Form.Group>
                </Col>
              )}
              {statementData?.campId !== "" && (
                <Col sm="4">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Camp Name
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData?.campName}
                    </h5>
                  </Form.Group>
                </Col>
              )}
              <Col sm="4">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Transfer Category
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.transferCategory}
                  </h5>
                </Form.Group>
              </Col>
              {transferCategory === "Transfer" && (
                <>
                  <Col sm="4">
                    <Form.Group className="mb-5">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Attended Days
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData?.attendedDays}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col sm="4">
                    <Form.Group className="mb-5" controlId="formBasicEmail">
                      <h4
                        className="sub-heading normal-font-weight textBlack"
                        style={{ fontSize: "18px" }}
                      >
                        Transfer Status
                      </h4>
                      <h5
                        className="sub-heading normal-font-weight"
                        style={{ fontSize: "16px" }}
                      >
                        {statementData?.status}
                      </h5>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="12">
                <Form.Group className="mb-5">
                  <h4 className="sub-heading" style={{ fontSize: "20px" }}>
                    Submitted By:
                  </h4>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Id
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.submittedBy.empId}
                  </h5>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="mb-5">
                  <h4
                    className="sub-heading normal-font-weight textBlack"
                    style={{ fontSize: "18px" }}
                  >
                    Emp Name
                  </h4>
                  <h5
                    className="sub-heading normal-font-weight"
                    style={{ fontSize: "16px" }}
                  >
                    {statementData.submittedBy.empName}
                  </h5>
                </Form.Group>
              </Col>
              {statementData.submittedBy.remarks && (
                <Col sm="12">
                  <Form.Group className="mb-5">
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      Remarks
                    </h4>
                    <h5
                      className="sub-heading normal-font-weight"
                      style={{ fontSize: "16px" }}
                    >
                      {statementData.submittedBy.remarks}
                    </h5>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Col>
          <Col sm="12">
            <div>
              <h2 className="sub-heading mb-4">
                {transferCategory === "Transfer"
                  ? "Transfer Employee"
                  : "New joiners"}{" "}
                Approvals:
              </h2>
              <Row>
                {statementData.approvals.length > 0 ? (
                  <>
                    {statementData.approvals.map((item, index) => (
                      <div className="col-sm-4" key={index}>
                        <div className="emp-detail-item mb-4">
                          <h4
                            className="sub-heading normal-font-weight textBlack"
                            style={{ fontSize: "18px" }}
                          >
                            {item.name} ({item.role})
                          </h4>
                          <p
                            className="sub-heading normal-font-weight"
                            style={{ fontSize: "16px" }}
                          >
                            {item.status}{" "}
                            {item.status !== "Pending"
                              ? `on ${item.date ? new Date(item.date).toLocaleDateString() : ""}`
                              : null}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>
                    <h4
                      className="sub-heading normal-font-weight textBlack"
                      style={{ fontSize: "18px" }}
                    >
                      No Approvals
                    </h4>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
              {isApproved && (
                <button
                  className="btn primary-button w-25"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
              <button
                className="btn primary-button w-25"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default TransferRequestDetail;
