import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// Camps
import { useGetClientListQuery } from "../../services/Clients";
import {
  setClientList,
  setClientListLoading,
  setClientListError,
  setClientListTotalPages,
} from "../../store/slices/clientsSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const clientsChange = useAppSelector((state) => state.common.clientsChange);
  // States
  const state = useAppSelector((state) => state.clients);

  // **************
  // **************
  // Get All Clients
  // **************
  // **************
  const {
    data: allClientss,
    isLoading: clientListLoading,
    isError: clientListError,
    refetch: clientListRefetch,
  } = useGetClientListQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showProject || false,
    pageNo: state.clientListPageNo,
    pageSize: state.clientListPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (clientListLoading) {
      dispatch(setClientListLoading(true));
    }
    if (clientListError) {
      dispatch(setClientListError("Something went wrong"));
      dispatch(setClientListLoading(false));
    }
    if (allClientss) {
      dispatch(setClientList(allClientss.data.clients[0].data || []));
      dispatch(
        setClientListTotalPages(
          allClientss.data.clients[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setClientListLoading(false));
    }
  }, [
    accessToken,
    userID,
    allClientss,
    profileData,
    state.clientListPageNo,
    state.clientListPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    clientListRefetch();
  }, [clientsChange]);
  return <></>;
};
export default Content;
