import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// Letters
import { useGetBehaviourStatementsQuery } from "../../../services/Letters";
import {
  setBehaviourStatementsLoading,
  setBehaviourStatementsError,
  setBehaviourStatements,
  setBsTotalPages,
} from "../../../store/slices/lettersSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.common.accessToken);
  const userID = useAppSelector((state) => state.common.userID);
  const profileData = useAppSelector((state) => state.settings.profileData);
  const behaviourStatementChange = useAppSelector(
    (state) => state.common.behaviourStatementChange,
  );
  // States
  const state = useAppSelector((state) => state.letters);

  // **************
  // **************
  // Get All Behaviour Statements
  // **************
  // **************
  const {
    data: allBStatements,
    isLoading: bsLoading,
    isError: bsError,
    refetch: bsRefetch,
  } = useGetBehaviourStatementsQuery({
    accessToken: accessToken || "",
    userID: userID || "",
    permission: profileData.permissions.showEmployee || false,
    page: state.bsPageNo,
    limit: state.bsPageSize,
  });
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    if (bsLoading) {
      dispatch(setBehaviourStatementsLoading(true));
    }
    if (bsError) {
      dispatch(setBehaviourStatementsError("Something went wrong"));
      dispatch(setBehaviourStatementsLoading(false));
    }
    if (allBStatements) {
      dispatch(
        setBehaviourStatements(allBStatements.data.statements[0].data || []),
      );
      dispatch(
        setBsTotalPages(
          allBStatements.data.statements[0].metadata[0]?.total || 0,
        ),
      );
      dispatch(setBehaviourStatementsLoading(false));
    }
  }, [
    accessToken,
    userID,
    allBStatements,
    profileData,
    state.bsPageNo,
    state.bsPageSize,
  ]);
  React.useEffect(() => {
    if (!accessToken && !userID) {
      return;
    }
    if (!profileData) {
      return;
    }
    bsRefetch();
  }, [behaviourStatementChange]);
  return <></>;
};
export default Content;
